import { API, graphqlOperation } from 'aws-amplify'
import store from '@/store/index.js'
import { premiumStatusHistoryByGroup } from './queries/queries'

/**
 * This function is used to load the history of the tenant's plans, if they ever bought a premium plan and then sets the readOnlyAccess object in the store to true or false for each plan that group has had in the past. With this they are allowed to see some component, it is currently being used for dashboards.
 * 
 * @param group - String
 */

export default async function loadHistoryPlanTenant(group){
  let readOnlyList = []
  let readOnlyAccess = {
    performanceCoaching: false,
    dailyRostering: false,
    staff: false,
    standard: false,
    vehicles: false
  }

  const allPremiumPlans = ["performance", "rostering", "staff", "standard", "vehicles", "bundle"]
  if(!group) store.commit('setReadOnlyAccess', readOnlyAccess)

  const input = {
    group,
    sortDirection: 'DESC'
  }

  try {
    const { data } = await API.graphql(graphqlOperation(premiumStatusHistoryByGroup, input))
    const result = data.premiumStatusHistoryByGroup.items
    readOnlyList = [...new Set(result.reduce((acc, cur) => [...acc, ...cur.accountPremiumStatus], []))].filter(plan => allPremiumPlans.includes(plan))
    if(readOnlyList.includes("bundle")){
      readOnlyAccess = {
        performanceCoaching: true,
        dailyRostering: true,
        staff: true,
        standard: true,
        vehicles: true
      }
    }else{
      readOnlyAccess = {
        performanceCoaching: readOnlyList.includes("performance"),
        dailyRostering: readOnlyList.includes("rostering"),
        staff: readOnlyList.includes("staff"),
        standard: readOnlyList.includes("standard"),
        vehicles: readOnlyList.includes("vehicles")
      }
    }
  } catch (error) {
    console.error(error)
  } finally {
    store.commit('setReadOnlyAccess', readOnlyAccess)
  }
}