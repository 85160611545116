module.exports.updateShortenUrl = /* GraphQL */ `
  mutation UpdateShortenUrl(
    $input: UpdateShortenUrlInput!
    $condition: ModelShortenUrlConditionInput
  ) {
    updateShortenUrl(input: $input, condition: $condition) {
      id
      isOpenLink
    }
  }
`;