export const invoicesByGroup = /* GraphQL */ `
  query InvoicesByGroup(
    $group: String
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelInvoiceFilterInput
    $limit: Int
    $nextToken: String
  ) {
    invoicesByGroup(
      group: $group
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        createdAt
        group
        month
        year
        invoiceTotal
        variableTotal
        flatMonthlyBillingAmount
        averageActiveDriverCount
        updatedAt
        discountFixed
        discountPercent
        status
      }
      nextToken
    }
  }
`;

export const getInvoice = /* GraphQL */ `
  query GetInvoice($id: ID!) {
    getInvoice(id: $id) {
      id
      createdAt
      group
      month
      year
      invoiceTotal
      averageActiveDriverCount
      discountPercent
      discountFixed
      discountPercentLabel
      discountFixedLabel
      flatMonthlyBillingAmount
      flatMonthlyBillingLabel
      paidByCustomerAmount
      cardLastFourCharged
      updatedAt
      status
      invoiceLineItems {
        items {
          id
          createdAt
          group
          date
          month
          year
          day
          isTrial
          activeStaff
          standardCost
          standardCostExt
          bundleCost
          bundleCostExt
          performanceCost
          performanceCostExt
          rosteringCost
          rosteringCostExt
          staffCost
          staffCostExt
          vehiclesCost
          vehiclesCostExt
          messagingCost
          messagingCostExt
          updatedAt
        }
        nextToken
      }
    }
  }
`;

export const getTenant = /* GraphQL */ `
  query GetTenant($id: ID!) {
    getTenant(id: $id) {
      id
      companyName
      addressLine1
      addressLine2
      addressCity
      addressState
      addressZip
      discountPercent
      discountFixed
      discountPercentLabel
      discountFixedLabel
      stripeBillingEmail
      shortCode
      accountPremiumStatus
      trialExpDate
      timeZone
    }
  }
`;