<template>
  <div class="mx-2">
    <el-dropdown @command="selectFilter" :disabled="isDisabled">
      <span class="el-dropdown-link flex items-baseline">
        <span :class="{'text-sm font-bold': isFilterActive }">{{ title }}</span>
        <span class="ml-1 py-0.5 px-1 bg-gray-100 rounded-lg">{{ getFilterLabel( currentFilter ) }}</span>
        <i class="el-icon-arrow-down el-icon--right"></i>
      </span>
      <el-dropdown-menu slot="dropdown" :class="popperClass">
        <el-input
          v-if="isFilterable"
          v-model="searchInput"
          size="mini"
          placeholder="Search"
          suffix-icon="el-icon-search"
          clearable
        />
        <el-dropdown-item 
          :class="{ 'active-date font-medium': selected }" 
          v-for="{ value, label, selected } in filteredOptions" 
          :key="value" 
          :command="value"
          :divided="value === 'current week'"
        >
          {{ label }}
        </el-dropdown-item>
      </el-dropdown-menu>
    </el-dropdown>
  </div>
</template>

<script>
export default {
  name: "SingleSelectFilter",
  
  props: {
    title: {
      type: String,
      required: true,
      default: 'Filtro'
    },
    type: {
      type: String,
      required: true,
      default: "default"
    },
    options: {
      type: Array,
      require: true,
      default: () => []
    },

    currentFilter: {
      type: String,
      require: true
    },

    isFilterable: {
      type: Boolean,
      default: false
    },
    popperClass: {
      type: String,
      default: ''
    },
    isDisabled: {
      type: Boolean,
      required: false,
      default: false
    }
  },

  data () {
    return {
      filterDate: [],
      newOptions: [],
      filtersNotApplied: ['all', 'all dates', 'Either Email or SMS Off'],
      optionsLoaded: false,
      searchInput:'',
    }
  },
  computed: {
    isFilterActive() {
      return !this.filtersNotApplied.includes( this.currentFilter ) ;
    },
    filteredOptions() { 
      if (this.searchInput)  return this.options.filter(option => option.label.toLowerCase().includes(this.searchInput.toLowerCase()))
      else {
        this.options.forEach(item => {
          if (item.label.toLowerCase() === this.currentFilter.toLowerCase()) {
            item.selected = true;
          } else {
            item.selected = false;
          }
        });
        return this.options;
      }
    },
    isAllOptionExist(){
      const optionValuesList = this.options.map(item => item.value);
      const allOptionExist = this.filtersNotApplied.some(itemA => optionValuesList.indexOf(itemA) !== -1);
      return allOptionExist;
    },
  },
  methods: {
    getFilterLabel(currentFilter) {
      if (!currentFilter) return currentFilter;
      const foundOption = this.options.find(o => o.value === currentFilter);
      const selectedLabel = foundOption?.label || currentFilter;
      const toCapital = word => word[0].toUpperCase() + word.substr(1);
      return selectedLabel.trim().replace(/\s+/g, ' ').split(' ').map(toCapital).join(' ');
    },

    selectFilter( command ) {
      this.unselectFilterDate();
      const [index, option] = this.getOptionSelected( command );
      this.updateOptionSelected( index, option );
      this.updateOptions();
    },

    unselectFilterDate() {
      const options = this.options.map(option => {
        return {
          ...option,
          selected: false
        }
      })
      this.newOptions = options;
    },

    updateOptionSelected( index, option ) {
      const optionUpdated = {
        ...option,
        selected: true
      };
      const options = [ ...this.newOptions ];
      options[index] = optionUpdated;
      this.newOptions = options;
    },

    getOptionSelected( filter ) {
      const index = this.newOptions.findIndex( option => option.value === filter );
      const option = this.newOptions.find( option => option.value === filter );
      this.filterDate = [ option.value ];
      return [index, option];
    },

    updateOptions() {
      let type = this.type;
      const options = [ ...this.newOptions ];
      const [ currentFilter ] = this.filterDate; 
      
      let indexes = []
      const optionValuesList = this.options.map(item => item.value);
      if (this.isAllOptionExist == true) indexes = [currentFilter].map(item => optionValuesList.indexOf(item) - 1);
      else indexes = [currentFilter].map(item => optionValuesList.indexOf(item));
      const isSameRoute = this.$route.query[type] === indexes.join(',');
      if (!isSameRoute) {
        this.$router.replace({
          params: {
            ...this.$route.params
          },
          query: {
            ...this.$route.query,
            [type]: indexes.join(','),
          },
        });

        this.$emit( 'update-options', { 
          options,
          currentFilter,
          type
        });
        
      }
    }

  }
}
</script>

<style>

</style>

<style lang="scss" scoped>
.active-date {
  color: #0268BC;
}
.el-input{
  margin: 0 10px 6px 10px;
  width: -webkit-fill-available;
}
</style>