export const getTenant = /* GraphQL */ `
  query GetTenant($id: ID!) {
    getTenant(id: $id) {
      id
      companyName
      group
      trialExpDate
      zohoCrmAccountRecordId
      accountPremiumStatus
      bundleDeactivationAllowedAt
      performanceDeactivationAllowedAt
      rosteringDeactivationAllowedAt
      staffDeactivationAllowedAt
      vehiclesDeactivationAllowedAt
      dispatchNumber
      addressLine1
      addressLine2
      addressCity
      addressState
      addressZip
      costStandard
      costBundle
      costPerformance
      costRostering
      costStaff
      costVehicles
      costMessaging
      messageServiceProvider
      nameLog
      stripeCustomerId
      stripeBillingEmail
      shortCode
      updatedAt
      users {
        items {
          id
          group
          cognitoSub
          firstName
          lastName
          email
        }
        nextToken
      }
    }
  }
`;

export const staffsByGroup = /* GraphQL */ `
query StaffsByGroup(
  $group: String
  $firstName: ModelStringKeyConditionInput
  $sortDirection: ModelSortDirection
  $filter: ModelStaffFilterInput
  $limit: Int
  $nextToken: String
) {
  staffsByGroup(
    group: $group
    firstName: $firstName
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      group
      status
    }
    nextToken
  }
}
`;

export const cardsByGroup = /* GraphQL */ `
  query CardsByGroup(
    $group: String
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelCardFilterInput
    $limit: Int
    $nextToken: String
  ) {
    cardsByGroup(
      group: $group
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        group
        stripeSetupIntent
        stripeCustomerId
        stripePaymentMethodId
        chargeError
        active
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;

export const listSystems = /* GraphQL */ `
  query ListSystems(
    $filter: ModelSystemFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listSystems(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        defaultCostStandard
        defaultCostBundle
        defaultCostPerformance
        defaultCostRostering
        defaultCostStaff
        defaultCostVehicles
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;

export const invoicesByGroupAndStatus = /* GraphQL */ `
  query InvoicesByGroupAndStatus(
    $group: String
    $status: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelInvoiceFilterInput
    $limit: Int
    $nextToken: String
  ) {
    invoicesByGroupAndStatus(
      group: $group
      status: $status
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        invoiceTotal
        month
        year
        status
        discountPercent
        discountFixed
        discountPercentLabel
        discountFixedLabel
      }
      nextToken
    }
  }
`;

export const invoicesByGroup = /* GraphQL */ `
  query InvoicesByGroup(
    $group: String
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelInvoiceFilterInput
    $limit: Int
    $nextToken: String
  ) {
    invoicesByGroup(
      group: $group
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        createdAt
        group
        month
        year
        invoiceTotal
        discountPercent
        discountFixed
        discountPercentLabel
        discountFixedLabel
        averageActiveDriverCount
        status
        html
        cardLastFourCharged
        updatedAt
      }
      nextToken
    }
  }
`;


export const updateCard = /* GraphQL */ `
  mutation UpdateCard(
    $input: UpdateCardInput!
    $condition: ModelCardConditionInput
  ) {
    updateCard(input: $input, condition: $condition) {
      id
      group
      stripeSetupIntent
      stripeCustomerId
      stripePaymentMethodId
      active
      createdAt
      chargeError
      updatedAt
    }
  }
`;