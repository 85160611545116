import { HeraPathClass } from "./heraPathClass"
import { HeraQueriesClass } from "./heraQueriesClass"
import { HeraUtils } from "./heraUtils"

export class HeraManagementRouteClass {
    to = null
    next = null
    heraPath = null
    heraQueries = null

    constructor(to, next) {
        this.to = to
        this.next = next
        this.heraPath = new HeraPathClass(to)
        this.heraQueries = new HeraQueriesClass(to)
    }

    management() {
        if (!this.isRouteCorrect()) {
            this.route()
        } else {
            this.next()
        }
    }

    isRouteCorrect() {
        console.log(this.hasStaffId(), this.heraPath.isPathCorrect(), this.heraQueries.areQueriesCorrect())
        console.log(this.to)
        if (this.hasStaffId()) {
            return this.heraPath.isPathCorrect()
        }

        return this.heraPath.isPathCorrect() && this.heraQueries.areQueriesCorrect()
    }

    route() {
        if (this.hasStaffId()) {
            this.next({
                path: this.heraPath.getValidatedPath(),
                query: {}
            })
        }
        if (this.to.path === this.heraPath.getValidatedPath()) {
            this.next();
        } else {
            this.next({
                path: this.heraPath.getValidatedPath(),
                query: this.heraQueries.getValidatedQueries()
            })
        }
    }

    hasStaffId() {
        return HeraUtils.hasProperty(this.to.params, 'staffId') && !!this.to.params.staffId
    }
}