<template>
    <el-dialog :title="title" :show-close="true" :visible.sync="show" width="710px" top="10%" v-loading="loading">
        <el-form id="tenant-form" label-position="top" :rules="rules" :model="formFields" ref="form">
        
        <div class="grid grid-cols-1 gap-2">
            <el-form-item label="Cancelation Reason" prop="accountCanceledReason">
                <el-select v-model="formFields.accountCanceledReason" placeholder="Select a reason" class="w-full" clearable>
                    <el-option
                        v-for="(item, key) in getListCancelationReason"
                        :key="key"
                        :label="item"
                        :value="item"/>
                </el-select>
            </el-form-item>
        </div>

        <el-form-item label="Cancelation Notes" prop="accountCanceledNotes">
            <el-input v-model="formFields.accountCanceledNotes"></el-input>
        </el-form-item>

        <el-checkbox v-model="balanceChangePlan" v-if="tableData && tableData.length > 0" @change="onCheckChargeOutstanding">Charge outstanding balance (${{totalOutstanding}})</el-checkbox>
        <table-card tablecard-class="rounded-lg" v-if="tableData && tableData.length > 0">
            <custom-table
                v-loading="loading"
                :key="renderKey"
                ref="table"
                inTable
                withSelect
                emptyText="No Invoices"
                footerTable="Total Invoices"
                :showFooterText="false"     
                :selectionEntityLabels="['Invoice', 'Invoices']"
                :selectionCustomActions=[]
                :selectAllHandler="selectAllHandler"
                :columns="cols"
                :data="filteredData"
                :totalRecords="totalInvoices"
                :optionsRows="optionsContextRow"
                @selection-change="onSelectionChange"
                :defaultAllSelectedRows = "selectedRowPolicy"
            >
                <template #month="row">
                <span>{{ row.invoiceMonth | text }}</span>
                </template>
                <template #status="row">
                <span>{{ row.invoiceStatus | text }}</span>
                </template>
                <template #subtotal="row">
                <span>{{ row.invoiceSubtotal | text }}</span>
                </template>
                <template #balance="row">
                <span style="text-align: right;">{{ row.invoiceBalance | text }}</span>
                </template>
            </custom-table>
            <div class="block md:flex">
                <span class="footer-class">Total Discount From Percentage: ${{ totalDiscountPercent }}</span>
            </div>
            <div class="block md:flex">
                <span class="footer-class">Total Discount Fixed: ${{ totalDiscountFixed }}</span>
            </div>
            <div class="block md:flex">
                <span class="footer-class">Total Outstanding: ${{ totalOutstanding }}</span>
            </div>
        </table-card>
    </el-form>
        <div slot="footer" class="dialog-footer">
            <el-button @click="closeModal" :disabled="loading">Cancel</el-button>
            <el-button type="primary" @click="changePlan()" :disabled="!hasInvoicesToPay || loading">Continue</el-button>
        </div>
    
    </el-dialog>
</template>
<script>
import { changePlanToNone } from '../tenant'
import TableCard from "@/components/TableCard/TableCard";
import CustomTable from "@/components/TableCard/CustomTable";
import { invoicesByGroup } from '@/scripts/queries/queries'
import moment from 'moment';
import { ACCOUNT_CANCELED_REASON } from '../constants';

export default {
    name: 'ChangePlanModal',
    components: {
        TableCard,
        CustomTable
    },
    props: {
        title: {
            type: String,
            required: true,
            default: ''
        },
        show: {
            type: Boolean,
            required: true,
            default: false
        },
        tenant: {
            type: Object,
            required: true,
            default: {}
        }
    },
    data() {
        return {
            balanceChangePlan: true,
            selectedRowPolicy: 'select_all',
            loading: false,
            search: '',
            selectedInvoices: [],
            unselectedInvoices: [],
            totalOutstanding:0,
            tableData: undefined,
            cols: [
                    { name: "Month", col: "invoiceMonth", fixed: false, width: "100"},
                    { name: "Status", col: "invoiceState", fixed: false, width: "80"},
                    { name: "Subtotal", col: "invoiceSubtotal", fixed: false, width: "90"},
                    { name: "Discount", col: "invoiceDiscount", fixed: false, width: "120"},
                    { name: "Balance", col: "invoiceBalance", fixed: false, width: "90"},
                ],
            optionsContextRow: [],
            totalInvoices: 0,
            formFields:{
                accountCanceledNotes:'',
                accountCanceledReason: ''
            },
            rules: {
            },
            totalDiscountFixed: 0,
            totalDiscountPercent: 0,
            renderKey: 0
        }
    },
    computed:{
        filteredData() {
            return this.tableData;
        },
        hasInvoicesToPay() {
            return this.tableData && this.tableData.length > 0;
        },
        getListCancelationReason(){
            return ACCOUNT_CANCELED_REASON || []
        },
    },
    methods: {
        loadData: async function(){
            this.loading = true;
            try{  
                if(!!this.tenant.group){
                    var input = {
                        group: this.tenant.group,
                        limit: 20,
                        filter:{
                            or: [{
                                    status: {
                                        eq: "Pending"
                                    }
                                },
                                {
                                    status: {
                                        eq: "Finalized"
                                    }
                                },
                                {
                                    status: {
                                        eq: "Payment Error"
                                    }
                                }
                            ]
                        },
                        sortDirection: "DESC"
                    }
                
                    let invoices = await this.api(invoicesByGroup, input)
                    if(!!invoices?.data?.invoicesByGroup?.items)
                        invoices = invoices?.data?.invoicesByGroup?.items;
                    invoices.map((i) => {
                        const d = new Date(i.year, i.month);
                        i['invoiceMonth'] = moment(d).format('MMM, YYYY');
                        i['invoiceState'] = i.status;

                        const fixedDiscount = i.discountFixed ? i.discountFixed : 0
                        const percentDiscount = i.discountPercent ? i.discountPercent / 100 * (i.invoiceTotal - fixedDiscount) : 0
                        this.totalDiscountPercent += parseFloat(percentDiscount.toFixed(2));
                        const balance = i.invoiceTotal - (fixedDiscount + percentDiscount);
                        i['invoiceSubtotal'] = `$${i.invoiceTotal.toFixed(2)}`;
                        i['invoiceDiscount'] = this.calculateInvoiceDiscount(i, fixedDiscount, percentDiscount);
                        i['balance'] = balance.toFixed(2);
                        i['invoiceBalance'] = `$${i.balance}`;                        
                    })
                    // invoices = invoices.filter((i)=>{ return i.balance > 0 });
                    this.totalDiscountFixed = invoices.reduce((accumulator, currentValue) => accumulator + currentValue.discountFixed, 0);
                    this.tableData = invoices;
                }
            }catch(e){
                this.tableData = []
                this.displayUserError(e)
            }finally{
                this.loading = false
            }
        },
        onCheckChargeOutstanding(checked){
            if(checked)
                this.selectedRowPolicy = 'select_all';
            else
                this.selectedRowPolicy = 'select_none';
        },
        onSelectionChange(selectedRows){
            this.selectedInvoices = this.findCommonInvoices(this.tableData,selectedRows);
            this.unselectedInvoices = this.findUncommonInvoices(this.tableData,selectedRows);
            if(this.tableData?.length>0){
                if(this.selectedInvoices.length===0 && this.balanceChangePlan){
                    this.balanceChangePlan = false;
                    this.selectedRowPolicy = 'select_none'
                }
                if(this.selectedInvoices.length===1 && !this.balanceChangePlan){
                    this.balanceChangePlan = true;
                    this.selectedRowPolicy = ''
                }
                if(this.selectedInvoices.length===this.tableData.length && !this.balanceChangePlan){
                    this.balanceChangePlan = true;
                    this.selectedRowPolicy = 'select_all'
                }
                this.totalOutstanding = this.selectedInvoices.reduce((accumulatedBalance, item) => parseFloat(accumulatedBalance) + parseFloat(item.balance), 0.0).toFixed(2);
            }
                       
        },
        selectAllHandler: async function(){
            return;
        },
        // Function to find common invoices b/w two arrays based on their IDs
        findCommonInvoices(invoices, selectedInvoices) {
            const commonInvoices = [];
            if(selectedInvoices.length==0) return commonInvoices;

            // Iterate through the invoices array
            for (const inv1 of invoices) {
                // Check if the ID exists in the selectedInvoices array
                const inv2 = selectedInvoices.find(id => id === inv1.id);
                if (inv2) {
                    commonInvoices.push(inv1);
                }
            }

            return commonInvoices;
        },
        findUncommonInvoices(invoices, selectedInvoices) {
            const uncommonInvoices = [];
            if(selectedInvoices.length==0) return invoices;
            // Iterate through the invoices array
            for (const inv1 of invoices) {
                // Check if the ID does not exist in the selectedInvoices array
                const inv2 = selectedInvoices.find(id => id === inv1.id);
                if (!inv2) {
                    uncommonInvoices.push(inv1);
                }
            }

            return uncommonInvoices;
        },
        async changePlan(){            
            try{
                this.loading = true;
                const result = await changePlanToNone(this.formFields, this.selectedInvoices,this.unselectedInvoices, this.tenant, !this.$store.getters.isZohoCrmDisabled);
                if(result.status=='Success'){
                    this.displayUserNotification({
                            title: "Success",
                            type: "success",
                            message: result.message
                        })
                    this.$emit('resultChangePlan', result);
                    this.closeModal();
                }
                else{
                    throw new Error(result.message)
                }
            }catch(e){
                this.displayUserError(e)
            }finally{
                this.loading = false
            }
        },

        confirmCharge(){
            if(this.formFields.accountCanceledNotes==''){
                this.$refs.form.validateField('accountCanceledNotes');
                return false;
            }
            
            var message = `Are you sure you want to change the tenant plan to None?`
            var confirmCaption = `Change Plan`
            if(this.selectedInvoices.length>0){
                message = `Are you sure you want to charge the selected invoices and change the tenant plan to None?`;
                confirmCaption = `Charge $${this.totalOutstanding} & Change Plan`
            }
            if(this.tableData?.length>0 && this.selectedInvoices.length==0){
                message = `Are you sure you want to change the tenant plan to None without charging their outstanding balance?`;
            }

            this.$msgbox({
                title: 'Warning',
                message: message,
                showCancelButton: true,
                confirmButtonText: confirmCaption,
                cancelButtonText: 'Cancel',
            }).then(() =>{
                this.changePlan()
            }).catch(() =>{
                this.displayUserNotification({title: 'Change Plan', message: 'Canceled change of plan', type: 'info'})
            });
        },

        closeModal(){
            this.$emit('close');
            this.formFields.reasonChangePlan = '';
            this.balanceChangePlan = true
            this.selectedRowPolicy = 'select_all'
            this.totalDiscountPercent = 0;
        },

        calculateInvoiceDiscount(invoice, fixedDiscount, percentDiscount) {
            let discountFinal = "";
            if (fixedDiscount && percentDiscount) {
                discountFinal = `$${parseFloat(parseFloat(fixedDiscount) + parseFloat(percentDiscount)).toFixed(2)} ($${invoice.discountFixed} + ${invoice.discountPercent}%)`
            } else if (fixedDiscount) {
                discountFinal = `$${fixedDiscount}`
            } else if(percentDiscount){
                discountFinal = `$${parseFloat(percentDiscount).toFixed(2)} (${invoice.discountPercent}%)`
            } else {
                discountFinal = "$0"
            }
            return discountFinal;
        },
    },
    watch:{
        show:{
            handler(newValue, oldValue){
                if(newValue){
                    this.loadData().then((result) => {
                        this.balanceChangePlan = true;
                        this.renderKey++
                    });
                }
            }

        }
    }
}
</script>

<style lang="scss">
.selected-checkbox {
  font-size: 12px !important;
  background-color: rgb(2, 104, 188);
  border-color: rgb(2, 104, 188);
  color: white;
}

.invoiceBalance-class {
    display: inline-block !important;
    text-align: right !important;
    width: 100%;
}

.footer-class {
    display: inline-block !important;
    text-align: right !important;
    width: 100%;
    padding-right: 62px;
    padding-bottom:10px;
}

input[type="checkbox"] {
  accent-color: rgb(2, 104, 188);
}

.counseling-index-class .el-dropdown-menu__item.is-disabled {
    color: black !important;
}

.counseling-index-class .el-table th.el-table__cell > .cell {
  display: flex !important;
  align-items: center;
  vertical-align: middle;
  text-align: center;
  padding: 0.46rem
}

</style>