const setTenantStatus = (accountPremiumStatus, trialExpDate) => {
	let plans = []
	let status = 'Unknown'

	if (!accountPremiumStatus || accountPremiumStatus == [] || !trialExpDate) return status

	for (let plan of accountPremiumStatus) {
	  plans.push(plan.toLowerCase());
	}

	if (plans.includes('none')) {
		status = 'Churned'
		return status
	}
	
	const date = new Date()
	if (plans.includes('trial') && !trialExpDate) {
		status = 'Trial' // trial without trialExpDate
	} else if(plans.includes('trial') && new Date(trialExpDate) < date) {
		status = 'Lapsed Trial' // expired trial
	}else{
		if(trialExpDate && new Date(trialExpDate) > date){			
			if(plans.includes('bundle') || plans.includes('standard')){
				status = 'Trial (Plan Selected)'
			}
			else{
				status = 'Trial'
			}
			return status			
		}
	}	

	if (plans.includes('bundle')) {
	  status = 'Active - Bundle'
	  return status
	}

	if (plans.includes('standard')) {
	  if (plans.length > 1) {
		status = 'Active - Premium'
	  }
	  else{
		status = 'Active - Standard'
	  }
	}

	return status
}
 
module.exports = {
	setTenantStatus
}