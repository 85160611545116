<template>
    <el-form label-position="top" ref="form" :rules="rules" :model="formFields">
        <el-form-item prop="companyName">
            <template #label>
                <span>Company Name</span>
                <el-tooltip v-if="!isSystemAdmin" class="item" effect="dark" placement="top-start" >
                    <div slot="content" class="w-52">
                        To edit this field, please reach out to Hera Support.
                    </div>
                    <i class="uil uil-question-circle text-base"></i>
                </el-tooltip>
            </template>
            <el-input :disabled="!isSystemAdmin" data-cy="cd-company-name-in" v-model="formFields.companyName"></el-input>
            
        </el-form-item>

        <el-form-item>
            <template #label>
                <span>Short Code</span>
                <el-tooltip v-if="!isSystemAdmin" class="item" effect="dark" placement="top-start" >
                    <div slot="content" class="w-52">
                        To edit this field, please reach out to Hera Support.
                    </div>
                    <i class="uil uil-question-circle text-base"></i>
                </el-tooltip>
            </template>
            <el-input :disabled="!isSystemAdmin" data-cy="cd-short-code-in" v-model="formFields.shortCode"></el-input>
            
        </el-form-item>

        <el-form-item label="Dispatch Phone Number">
            <el-input data-cy="cd-dispatch-number-in" v-model="formFields.dispatchNumber" v-mask="'(###) ###-####'" placeholder="(123) 456-7890"></el-input>
        </el-form-item>

        <el-form-item prop="stripeBillingEmail" label="Billing Email">
            <el-input data-cy="cd-stripe-billing-email-in" v-model="formFields.stripeBillingEmail" placeholder="athena@herasolutions.app"></el-input>
        </el-form-item>

        <el-form-item>
            <template slot="label">
              Station Address
              <el-tooltip v-if="!isSystemAdmin" class="item" effect="dark" content="Please contact Hera Support to change your station address." :placement="isDeviceMobileOnly() ? 'bottom' : 'top-start'">
                <i class="text-gray-700 uil uil-question-circle text-base"></i>
              </el-tooltip>
            </template>
            <div class="mb-1">
                <el-input :disabled="!isSystemAdmin" data-cy="cd-address-line-1-in" v-model="formFields.addressLine1" placeholder="123 Main St"></el-input>
            </div>
            <div class="mb-1">
                <el-input :disabled="!isSystemAdmin" data-cy="cd-address-line-2-in" v-model="formFields.addressLine2" placeholder="Suite #123"></el-input>
            </div>
            <div class="mb-1 flex space-x-1">
                <el-input :disabled="!isSystemAdmin" data-cy="cd-address-city-in" v-model="formFields.addressCity" placeholder="Los Angeles"></el-input>
                <el-input :disabled="!isSystemAdmin" data-cy="cd-address-state-in" v-model="formFields.addressState" placeholder="CA"></el-input>
                <el-input :disabled="!isSystemAdmin" data-cy="cd-address-zip-in" v-model="formFields.addressZip" placeholder="90056"></el-input>
            </div>
        </el-form-item>
    </el-form>
</template>

<script>
export default {
    
    props:['formFields', 'rules'],
    computed: {
        isSystemAdmin() {
            return this.$store.getters.isSystemAdmin
        }
    },

}
</script>