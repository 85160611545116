import { API } from 'aws-amplify';

async function getAllTelnyxTenantEnviroment() {
    try {
        const apiName = 'heraPublicApi'
        const path = '/getTelnyxData'
        const result = await API.get(apiName, path)
        return result
    } catch (error) {
        console.error(error)
        return error
    }
}

export { getAllTelnyxTenantEnviroment }