<template>
    <div>
        <div class="fixed bg-gray-100 border rounded-lg shadow-xl right-0 mr-24 bottom-0 -mb-1 w-96 z-40">
            <button class="flex py-2 px-4 justify-between w-full text-left" @click="isOpen = !isOpen">
                {{title}}
                <div class="">
                    <i v-if="isOpen" class="el-icon-arrow-down"></i>
                    <i v-else class="el-icon-arrow-up"></i>
                </div>
            </button>
            <div v-show="isOpen" class="pb-2 py-2 px-4 h-auto mb-4 overflow-y-auto">
                <JobStatusIndicator v-for="job in jobs" :key="job.id" :job="job"/>
            </div>
        </div>
    </div>
</template>

<script>
import {mapState} from 'vuex'
import JobStatusIndicator from '@/components/parse/JobStatusIndicator'

export default {
    components: {JobStatusIndicator},
    data(){
        return{
            isOpen: true
        }
    },
    computed:{
        ...mapState(['jobs']),
        title(){
            if( this.jobs.length === 1 ){
                return this.jobs.length + " Job in progress"
            }else{
                return this.jobs.length + " Jobs in progress"
            }
        }
    }
}
</script>