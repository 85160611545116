import router from '@/router/index'
import { formatters } from '@/components/DateFilter/formatters'

/**
 * This function applies a filter to an option list based on the provided type and default filter.
 * 
 * @param {string} type - The type of filter to apply.
 * @param {Array} defaultFilter - The default filter to apply if no filter is specified in the route query.
 * @param {Array} optionList - The list of options to filter.
 * @param {boolean} allSelected - A flag indicating whether all options should be selected by default.
 * @returns {Array} - The filtered list of options.
 */
export const initialSingleDefaultFilter = function (type, defaultFilter, optionList, allSelected = false) {
    if (router.currentRoute.query[type] != undefined){
        let filterItem = router.currentRoute.query[type]
        
        if (allSelected == true) filterItem = parseInt(filterItem) + 1
        const newFilter = optionList[filterItem].value
        const newOptions = optionList.map(option => {
            return {
              ...option,
              selected: false
            }
          })
        newOptions[filterItem].selected = true
        return {newFilter, newOptions}
    } else {
        const newOptions = optionList.map(option => ({
            ...option,
            selected: option.value == defaultFilter
            }));
        return {newFilter: defaultFilter, newOptions: newOptions}
    }
}

/**
 * This function applies a filter to an option list based on the provided query and default filter.
 * 
 * @param {Object} query - The query object from the route.
 * @param {Array} defaultFilter - The default filter to apply if no filter is specified in the query.
 * @param {Array} optionList - The list of options to filter.
 * @returns {Array} - The filtered list of options.
 */
export const multipleDefaultFilter = function (query, defaultFilter, optionList) {
    if (query != undefined){
        if (query == -1) {
            return optionList
        }
        else return query.split(',').map(index => optionList[index])
    } else {
        return defaultFilter
    }
}

export const defaultDateFilter = function (queryParam, formatterName, cbValidator = null) {
    const queryValue = router.currentRoute.query[queryParam];
    
    if(queryValue === undefined) return null;
    
    const formatter = formatters[formatterName];
    if(!formatter || !formatter.isValid(queryValue, cbValidator)) {
        const query = { ...router.currentRoute.query };
        delete query[queryParam]
        router.replace({ query })
        return null;
    }
    return formatter.formatToDate(queryValue)
}
