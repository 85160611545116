import {
    onCreateKudoByGroup,
    onUpdateKudoByGroup,
    onDeleteKudoByGroup
} from '../../subscriptions';
import { recalculateDaPerformanceData } from '@/views/DaPerformanceReport/calculateDaPerformanceReport'
import store from '@/store'
import { initializeSubscription, disconnectSubscription, updateElement } from '../helper'

"use strict";
let subscriptionKudos = null

export const kudoSubscribe = function(instance){
    const queries = {
        onCreateKudoByGroup,
        onUpdateKudoByGroup,
        onDeleteKudoByGroup
    }
    if(!store.state.userInfo?.tenant?.group) return
    const input = {
        group: store.state.userInfo.tenant.group
    }
    initializeSubscription(subscriptionKudos, queries, input, "subscriptionStore/handleKudo", instance)
}

export const config = function(subscriptions){
    const subscription = {}
    subscriptionKudos = subscriptions['kudo'] = {
        loadList: null,
        subscribe: kudoSubscribe,
        unsubscribe: (instance) => disconnectSubscription(subscription, instance),
        callback: kudoEventCallback,
        subscription
    }
}

export const kudoEventCallback = async function(state, { data: element, eventName }){
    
    const eventNames = {
        'onCreate': () => {
            const exists = state.kudoList.some(item => item.id === element.id)
            if(!exists){
                state.kudoList.push(element)
            }
        },

        'onUpdate': () => {
            const match = state.kudoList.find(item => item.id === element.id)
            if(match){
                updateElement(match, element)
            }else{
                state.kudoList.push(element)
            }
        },

        'onDelete': () => {
            const index = state.kudoList.findIndex(item => item.id === element.id)
            if(index > -1){
                state.kudoList.splice(index, 1)
            }
        }
    }
    eventNames[eventName]()
    await recalculateDaPerformanceData(false)
}