export const rosteredRoutesCardTitle = 'COLUMNS DISPLAYED FOR ROUTES'

export const rosteredRoutesCardDescription = 'Here you can customize which columns will be displayed on the ROUTES table on the Daily Roster. Regardless of which columns are shown, you will be able to access all of these fields by viewing the details of an individual Route. These settings apply to all users at your company.'

export const rosteredVehiclesCardTitle = 'COLUMNS DISPLAYED FOR VEHICLES'

export const rosteredVehiclesCardDescription = 'Here you can customize which columns will be displayed on the VEHICLES table on the Daily Roster. Regardless of which columns are shown, you will be able to access all of these fields by viewing the details of an individual Rostered Vehicle. These settings apply to all users at your company.'

export const rosteredAssociatesCardTitle = 'COLUMNS DISPLAYED FOR ASSOCIATES'

export const rosteredAssociatesCardDescription = 'Here you can customize which columns will be displayed on the ASSOCIATES table on the Daily Roster. Regardless of which columns are shown, you will be able to access all of these fields by viewing the details of an individual Rostered Associate. These settings apply to all users at your company.'