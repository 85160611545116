<template>  
<div v-if="this.$store.getters.hasStaffManagement">
    <router-link  :to="{name: 'Messenger'}" tag="button" class="hidden md:block md:border-l pl-4 text-center text-gray-600 hover:text-blue-500" :class="{'text-brand': notificationCount}">
            <i class="uil uil-comment-alt-dots text-2xl"></i>
            <span v-if="notificationCount" class="notification-bubble">{{notificationCount}}</span>
    </router-link>     
    <router-link 
        :to="{name: 'MobileMessengerList'}" 
        tag="button" 
        class="block md:hidden md:border-l pl-4 text-center text-gray-600 hover:text-blue-500" 
        :class="{'text-brand': notificationCount}"
        @click.native="closeMobileMenu">
            <i class="uil uil-comment-alt-dots text-2xl"></i>
            <span v-if="notificationCount" class="notification-bubble">{{notificationCount}}</span>
    </router-link>     

</div>
    
        <el-popover
        v-else
        placement="bottom"
        title="Premium Required"
        width="215"
        trigger="hover"
        content=""
        > 
        <i slot="reference" class="uil uil-comment-alt-dots text-2xl border-l pl-4 text-center text-gray-600 hover:text-brand"></i>
        <PremiumWarning
            action="use Hera messenger"
            premiumFeature="Associate Management">
        </PremiumWarning>
        </el-popover>
</template>

<script>
import {mapState} from 'vuex'
import PremiumWarning from '@/components/PremiumWarning'

export default {
    components: {
        PremiumWarning
    },
    computed:{
        ...mapState(['messengerNotifications']),
        notificationCount(){
            return this.messengerNotifications
        }
    },
    methods:{
        closeMobileMenu(){
            this.$emit('close-mobile-menu')
        }
    }
}
</script>