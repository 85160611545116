export const getAccidentUpdatedTimestamp = /* GraphQL */ `
  query GetAccident($id: ID!) {
    getAccident(id: $id) {
      id
      updatedAt
    }
  }
`;

export const getCounselingUpdatedTimestamp = /* GraphQL */ `
  query GetCounseling($id: ID!) {
    getCounseling(id: $id) {
      id
      updatedAt
    }
  }
`;

export const getDailyRosterUpdatedTimestamp = /* GraphQL */ `
  query GetDailyRoster($id: ID!) {
    getDailyRoster(id: $id) {
      id
      updatedAt
    }
  }
`;

export const getDeviceUpdatedTimestamp = /* GraphQL */ `
  query GetDevice($id: ID!) {
    getDevice(id: $id) {
      id
      updatedAt
    }
  }
`;

export const getDocumentUpdatedTimestamp = /* GraphQL */ `
  query GetDocument($id: ID!) {
    getDocument(id: $id) {
      id
      updatedAt
    }
  }
`;

export const getDrugTestUpdatedTimestamp = /* GraphQL */ `
  query GetDrugTest($id: ID!) {
    getDrugTest(id: $id) {
      id
      updatedAt
    }
  }
`;

export const getInfractionUpdatedTimestamp = /* GraphQL */ `
  query GetInfraction($id: ID!) {
    getInfraction(id: $id) {
      id
      updatedAt
    }
  }
`;

export const getInjuryUpdatedTimestamp = /* GraphQL */ `
  query GetInjury($id: ID!) {
    getInjury(id: $id) {
      id
      updatedAt
    }
  }
`;

export const getKudoUpdatedTimestamp = /* GraphQL */ `
  query GetKudo($id: ID!) {
    getKudo(id: $id) {
      id
      updatedAt
    }
  }
`;

export const getOnBoardUpdatedTimestamp = /* GraphQL */ `
  query GetOnBoard($id: ID!) {
    getOnBoard(id: $id) {
      id
      updatedAt
    }
  }
`;

export const getPendingMessageUpdatedTimestamp = /* GraphQL */ `
  query GetPendingMessage($id: ID!) {
    getPendingMessage(id: $id) {
      id
      updatedAt
    }
  }
`;

export const getPhysicalUpdatedTimestamp = /* GraphQL */ `
  query GetPhysical($id: ID!) {
    getPhysical(id: $id) {
      id
      updatedAt
    }
  }
`;

export const getPodQualityUpdatedTimestamp = /* GraphQL */ `
  query GetPodQuality($id: ID!) {
    getPodQuality(id: $id) {
      id
      updatedAt
    }
  }
`;

export const getReplaceByRouteUpdatedTimestamp = /* GraphQL */ `
  query GetReplaceByRoute($id: ID!) {
    getReplaceByRoute(id: $id) {
      id
      updatedAt
    }
  }
`;

export const getRouteUpdatedTimestamp = /* GraphQL */ `
  query GetRoute($id: ID!) {
    getRoute(id: $id) {
      id
      updatedAt
    }
  }
`;

export const getStaffUpdatedTimestamp = /* GraphQL */ `
  query GetStaff($id: ID!) {
    getStaff(id: $id) {
      id
      updatedAt
    }
  }
`;

export const getStaffCxFeedbackUpdatedTimestamp = /* GraphQL */ `
  query GetStaffCxFeedback($id: ID!) {
    getStaffCxFeedback(id: $id) {
      id
      updatedAt
    }
  }
`;

export const getStaffMentorUpdatedTimestamp = /* GraphQL */ `
  query GetStaffMentor($id: ID!) {
    getStaffMentor(id: $id) {
      id
      updatedAt
    }
  }
`;

export const getStaffScoreCardUpdatedTimestamp = /* GraphQL */ `
  query GetStaffScoreCard($id: ID!) {
    getStaffScoreCard(id: $id) {
      id
      updatedAt
    }
  }
`;

export const getStaffStatusUpdatedTimestamp = /* GraphQL */ `
  query GetStaffStatus($id: ID!) {
    getStaffStatus(id: $id) {
      id
      updatedAt
    }
  }
`;

export const getTaskUpdatedTimestamp = /* GraphQL */ `
  query GetTask($id: ID!) {
    getTask(id: $id) {
      id
      updatedAt
    }
  }
`;

export const getTenantUpdatedTimestamp = /* GraphQL */ `
  query GetTenant($id: ID!) {
    getTenant(id: $id) {
      id
      updatedAt
    }
  }
`;

export const getUniformUpdatedTimestamp = /* GraphQL */ `
  query GetUniform($id: ID!) {
    getUniform(id: $id) {
      id
      updatedAt
    }
  }
`;

export const getUserUpdatedTimestamp = /* GraphQL */ `
  query GetUser($id: ID!) {
    getUser(id: $id) {
      id
      updatedAt
    }
  }
`;

export const getValueListItemUpdatedTimestamp = /* GraphQL */ `
  query GetValueListItem($id: ID!) {
    getValueListItem(id: $id) {
      id
      updatedAt
    }
  }
`;

export const getVehicleUpdatedTimestamp = /* GraphQL */ `
  query GetVehicle($id: ID!) {
    getVehicle(id: $id) {
      id
      updatedAt
    }
  }
`;

export const getVehicleMaintenanceReminderUpdatedTimestamp = /* GraphQL */ `
  query GetVehicleMaintenanceReminder($id: ID!) {
    getVehicleMaintenanceReminder(id: $id) {
      id
      updatedAt
    }
  }
`;

export const getStaffNetradyneAlertUpdatedTimestamp = /* GraphQL */ `
  query GetStaffNetradyneAlert($id: ID!) {
    getStaffNetradyneAlert(id: $id) {
      id
      updatedAt
    }
  }
`;

export const getOptionsCustomListsUpdatedTimestamp = /* GraphQL */ `
  query GetOptionsCustomLists($id: ID!) {
    getOptionsCustomLists(id: $id) {
      id
      updatedAt
    }
  }
`;

export const getNoteUpdatedTimestamp = /* GraphQL */ `
  query GetNote($id: ID!) {
    getNote(id: $id) {
      id
      updatedAt
    }
  }
`;