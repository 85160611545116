import { PARKING_SPACE_CREATE_VEHICLE,
  VEHICLE_TYPE_CREATE_VEHICLE,
  AUTHORIZED_TO_DRIVE_CREATE_ASSOCIATE,
  COUNSELING_SEVERITY_CREATE_COUNSELING,
  INCIDENT_TYPE_CREATE_INCIDENT,
  ASSOCIATE_ROSTER_STATUS_CREATE_ROUTE,
  PHOTO_LOG_TYPE_CREATE_VEHICLE_PHOTO_LOG
} from '@/utilities/constants/defaultSectionsByCustomList';

import { HIDDEN_ISSUE_TYPE_CREATE_ISSUE, HIDDEN_KUDO_TYPE_CREATE_KUDO } from '@/utilities/constants/hiddenSecctionForCustomList';
export const vehicleRoleForRoutesOptions = [
  {
    order: 1,
    option: "Primary",
    default: true,
    isEnabled: true,
    isCustom: false,
    usedFor: 0,
    daysCount: 0,
    canBeEdited: false,
    canBeDeleted: false,
    canBeReorder: false,
  },
  {
    order: 2,
    option: "Split With",
    default: false,
    isEnabled: true,
    isCustom: false,
    usedFor: 0,
    daysCount: 0,
    canBeEdited: true,
    canBeDeleted: true,
    canBeReorder: true,
  },
  {
    order: 3,
    option: "Sweeper",
    default: false,
    isEnabled: true,
    isCustom: false,
    usedFor: 0,
    daysCount: 0,
    canBeEdited: true,
    canBeDeleted: true,
    canBeReorder: true,
  },
];

export const routeStatusOptions = [
  {
    order: 1,
    option: "Scheduled",
    default: true,
    isEnabled: true,
    isCustom: false,
    usedFor: 0,
    daysCount: 0,
    canBeEdited: false,
    canBeDeleted: false,
    canBeReorder: false,
  },
  {
    order: 2,
    option: "Completed",
    default: false,
    isEnabled: true,
    isCustom: false,
    usedFor: 0,
    daysCount: 0,
    canBeEdited: false,
    canBeDeleted: false,
    canBeReorder: false,
  },
  {
    order: 3,
    option: "Cancelled",
    default: false,
    isEnabled: true,
    isCustom: false,
    usedFor: 0,
    daysCount: 0,
    canBeEdited: false,
    canBeDeleted: false,
    canBeReorder: false,
  },
];

export const routeTypeOptions = [
  {
    order: 1,
    option: "Regular",
    default: true,
    isEnabled: true,
    isCustom: false,
    usedFor: 0,
    daysCount: 0,
    canBeEdited: true,
    canBeDeleted: true,
    canBeReorder: true,
  },
  {
    order: 2,
    option: "Ad Hoc/Recycled",
    default: false,
    isEnabled: true,
    isCustom: false,
    usedFor: 0,
    daysCount: 0,
    canBeEdited: true,
    canBeDeleted: true,
    canBeReorder: true,
  },
  {
    order: 3,
    option: "Milk Run",
    isEnabled: true,
    isCustom: false,
    default: false,
    usedFor: 0,
    daysCount: 0,
    canBeEdited: true,
    canBeDeleted: true,
    canBeReorder: true,
  },
  {
    order: 4,
    option: "Pick Up",
    isEnabled: true,
    isCustom: false,
    default: false,
    usedFor: 0,
    daysCount: 0,
    canBeEdited: true,
    canBeDeleted: true,
    canBeReorder: true,
  },
];

export const incidentTypeOptions = [
  {
    order: 1,
    option: "General Incident",
    default: true,
    isEnabled: true,
    isCustom: false,
    isDefaultForSections: [INCIDENT_TYPE_CREATE_INCIDENT],
    usedFor: 0,
    daysCount: 0,
    canBeEdited: false,
    canBeDeleted: false,
    canBeReorder: true,
  },
  {
    order: 2,
    option: "Accident",
    default: false,
    isEnabled: true,
    isCustom: false,
    usedFor: 0,
    daysCount: 0,
    canBeEdited: false,
    canBeDeleted: false,
    canBeReorder: true,
  },
];

export const vehicleTypeOptions = [
  {
      order: 1,
      option: '10,000lbs Van',
      default: false,
      isEnabled: true,
      isCustom: false,
      usedFor: 0,
      daysCount: 0,
      canBeEdited: true,
      canBeDeleted: true,
      canBeReorder: true
  },
  {
      order: 2,
      option: 'Custom Delivery Van',
      default: false,
      isEnabled: true,
      isCustom: false,
      usedFor: 0,
      daysCount: 0,
      canBeEdited: true,
      canBeDeleted: true,
      canBeReorder: true
  },
  {
      order: 3,
      option: 'Standard Parcel Small',
      default: false,
      isEnabled: true,
      isCustom: false,
      isDefaultForSections: [AUTHORIZED_TO_DRIVE_CREATE_ASSOCIATE],
      usedFor: 0,
      daysCount: 0,
      canBeEdited: true,
      canBeDeleted: true,
      canBeReorder: true
  },
  {
      order: 4,
      option: 'Standard Parcel',
      default: false,
      isEnabled: true,
      isCustom: false,
      isDefaultForSections: [AUTHORIZED_TO_DRIVE_CREATE_ASSOCIATE],
      usedFor: 0,
      daysCount: 0,
      canBeEdited: true,
      canBeDeleted: true,
      canBeReorder: true
  },
  {
      order: 5,
      option: 'Standard Parcel Large',
      default: false,
      isEnabled: true,
      isCustom: false,
      isDefaultForSections: [AUTHORIZED_TO_DRIVE_CREATE_ASSOCIATE],
      usedFor: 0,
      daysCount: 0,
      canBeEdited: true,
      canBeDeleted: true,
      canBeReorder: true
  },
  {
      order: 6,
      option: 'Standard Parcel XL',
      default: false,
      isEnabled: true,
      isCustom: false,
      isDefaultForSections: [AUTHORIZED_TO_DRIVE_CREATE_ASSOCIATE],
      usedFor: 0,
      daysCount: 0,
      canBeEdited: true,
      canBeDeleted: true,
      canBeReorder: true
  },
]

export const counselingSeverityOptions = [
  {
      order: 1,
      option: 'Informal Warning',
      default: true,
      isEnabled: true,
      isCustom: false,
      isDefaultForSections: [COUNSELING_SEVERITY_CREATE_COUNSELING],
      usedFor: 0,
      daysCount: 0,
      canBeEdited: true,
      canBeDeleted: true,
      canBeReorder: true
  },
  {
      order: 2,
      option: 'Formal Verbal Warning',
      default: false,
      isEnabled: true,
      isCustom: false,
      usedFor: 0,
      daysCount: 0,
      canBeEdited: true,
      canBeDeleted: true,
      canBeReorder: true
  },
  {
      order: 3,
      option: 'Final Warning',
      default: false,
      isEnabled: true,
      isCustom: false,
      usedFor: 0,
      daysCount: 0,
      canBeEdited: true,
      canBeDeleted: true,
      canBeReorder: true
  },
  {
      order: 4,
      option: 'Dismissal',
      default: false,
      isEnabled: true,
      isCustom: false,
      usedFor: 0,
      daysCount: 0,
      canBeEdited: true,
      canBeDeleted: true,
      canBeReorder: true
  }
]


export const issueTypeOptions = [
  {
    option: 'Called Out',
    default: false,
    isEnabled: true,
    isCustom: false,
    usedFor: 0,
    daysCount: 0,
    canBeEdited: false,
    canBeDeleted: false,
    canBeReorder: true,
  },
  {
    option: 'Late, With Call',
    default: false,
    isEnabled: true,
    isCustom: false,
    usedFor: 0,
    daysCount: 0,
    canBeEdited: false,
    canBeDeleted: false,
    canBeReorder: true,
  },
  {
    option: 'Late, No Call',
    default: false,
    isEnabled: true,
    isCustom: false,
    usedFor: 0,
    daysCount: 0,
    canBeEdited: false,
    canBeDeleted: false,
    canBeReorder: true,
  }, 
  {
    option: 'No Show, No Call',
    default: false,
    isEnabled: true,
    isCustom: false,
    usedFor: 0,
    daysCount: 0,
    canBeEdited: false,
    canBeDeleted: false,
    canBeReorder: true,
  },
  {
    option: 'Vehicle Damage',
    default: false,
    isEnabled: true,
    isCustom: false,
    usedFor: 0,
    daysCount: 0,
    canBeEdited: false,
    canBeDeleted: false,
    canBeReorder: true,
  },
  {
    option: 'Rescued DA',
    default: false,
    isEnabled: true,
    isCustom: false,
    usedFor: 0,
    daysCount: 0,
    canBeEdited: false,
    canBeDeleted: false,
    canBeReorder: true,
  },
  {
    option: 'Amazon Violation/Defect',
    default: false,
    isEnabled: true,
    isCustom: false,
    usedFor: 0,
    daysCount: 0,
    canBeEdited: false,
    canBeDeleted: false,
    canBeReorder: true,
  },
  {
    option: 'Scorecard FICO® Score',
    default: false,
    isEnabled: true,
    isCustom: false,
    usedFor: 0,
    daysCount: 0,
    canBeEdited: false,
    canBeDeleted: false,
    canBeReorder: true,
    isHiddenForSections: [ HIDDEN_ISSUE_TYPE_CREATE_ISSUE ]
  },
  {
    option: 'Scorecard DCR',
    default: false,
    isEnabled: true,
    isCustom: false,
    usedFor: 0,
    daysCount: 0,
    canBeEdited: false,
    canBeDeleted: false,
    canBeReorder: true,
    isHiddenForSections: [ HIDDEN_ISSUE_TYPE_CREATE_ISSUE ]
  },
  {
    option: 'Scorecard DAR',
    default: false,
    isEnabled: true,
    isCustom: false,
    usedFor: 0,
    daysCount: 0,
    canBeEdited: false,
    canBeDeleted: false,
    canBeReorder: true,
    isHiddenForSections: [ HIDDEN_ISSUE_TYPE_CREATE_ISSUE ]
  },
  {
    option: 'Scorecard DNR',
    default: false,
    isEnabled: true,
    isCustom: false,
    usedFor: 0,
    daysCount: 0,
    canBeEdited: false,
    canBeDeleted: false,
    canBeReorder: true,
    isHiddenForSections: [ HIDDEN_ISSUE_TYPE_CREATE_ISSUE ]
  },
  {
    option: 'Scorecard DSB',
    default: false,
    isEnabled: true,
    isCustom: false,
    usedFor: 0,
    daysCount: 0,
    canBeEdited: false,
    canBeDeleted: false,
    canBeReorder: true,
    isHiddenForSections: [ HIDDEN_ISSUE_TYPE_CREATE_ISSUE ]
  },
  {
    option: 'Scorecard SWC-POD',
    default: false,
    isEnabled: true,
    isCustom: false,
    usedFor: 0,
    daysCount: 0,
    canBeEdited: false,
    canBeDeleted: false,
    canBeReorder: true,
    isHiddenForSections: [ HIDDEN_ISSUE_TYPE_CREATE_ISSUE ]
  },
  {
    option: 'Scorecard SWC-CC',
    default: false,
    isEnabled: true,
    isCustom: false,
    usedFor: 0,
    daysCount: 0,
    canBeEdited: false,
    canBeDeleted: false,
    canBeReorder: true,
    isHiddenForSections: [ HIDDEN_ISSUE_TYPE_CREATE_ISSUE ]
  },
  {
    option: 'Scorecard SWC-SC',
    default: false,
    isEnabled: true,
    isCustom: false,
    usedFor: 0,
    daysCount: 0,
    canBeEdited: false,
    canBeDeleted: false,
    canBeReorder: true,
    isHiddenForSections: [ HIDDEN_ISSUE_TYPE_CREATE_ISSUE ]
  },
  {
    option: 'Scorecard SWC-AD',
    default: false,
    isEnabled: true,
    isCustom: false,
    usedFor: 0,
    daysCount: 0,
    canBeEdited: false,
    canBeDeleted: false,
    canBeReorder: true,
    isHiddenForSections: [ HIDDEN_ISSUE_TYPE_CREATE_ISSUE ]
  },
  {
    option: 'Scorecard Seatbelt-Off Rate',
    default: false,
    isEnabled: true,
    isCustom: false,
    usedFor: 0,
    daysCount: 0,
    canBeEdited: false,
    canBeDeleted: false,
    canBeReorder: true,
    isHiddenForSections: [ HIDDEN_ISSUE_TYPE_CREATE_ISSUE ]
  },
  {
    option: 'Scorecard Speeding Event Rate',
    default: false,
    isEnabled: true,
    isCustom: false,
    usedFor: 0,
    daysCount: 0,
    canBeEdited: false,
    canBeDeleted: false,
    canBeReorder: true,
    isHiddenForSections: [ HIDDEN_ISSUE_TYPE_CREATE_ISSUE ]
  },
  {
    option: 'Scorecard Harsh Braking Rate',
    default: false,
    isEnabled: true,
    isCustom: false,
    usedFor: 0,
    daysCount: 0,
    canBeEdited: false,
    canBeDeleted: false,
    canBeReorder: true,
    isHiddenForSections: [ HIDDEN_ISSUE_TYPE_CREATE_ISSUE ]
  },
  {
    option: 'Scorecard Harsh Cornering Rate',
    default: false,
    isEnabled: true,
    isCustom: false,
    usedFor: 0,
    daysCount: 0,
    canBeEdited: false,
    canBeDeleted: false,
    canBeReorder: true,
    isHiddenForSections: [ HIDDEN_ISSUE_TYPE_CREATE_ISSUE ]
  },
  {
    option: 'Scorecard Distractions Rate',
    default: false,
    isEnabled: true,
    isCustom: false,
    usedFor: 0,
    daysCount: 0,
    canBeEdited: false,
    canBeDeleted: false,
    canBeReorder: true,
    isHiddenForSections: [ HIDDEN_ISSUE_TYPE_CREATE_ISSUE ]
  },
  {
    option: 'Scorecard Following Distance Rate',
    default: false,
    isEnabled: true,
    isCustom: false,
    usedFor: 0,
    daysCount: 0,
    canBeEdited: false,
    canBeDeleted: false,
    canBeReorder: true,
    isHiddenForSections: [ HIDDEN_ISSUE_TYPE_CREATE_ISSUE ]
  },
  {
    option: 'Scorecard Sign/Signal Violation Rate',
    default: false,
    isEnabled: true,
    isCustom: false,
    usedFor: 0,
    daysCount: 0,
    canBeEdited: false,
    canBeDeleted: false,
    canBeReorder: true,
    isHiddenForSections: [ HIDDEN_ISSUE_TYPE_CREATE_ISSUE ]
  },
  {
    option: 'Scorecard Overall Tier',
    default: false,
    isEnabled: true,
    isCustom: false,
    usedFor: 0,
    daysCount: 0,
    canBeEdited: false,
    canBeDeleted: false,
    canBeReorder: true,
    isHiddenForSections: [ HIDDEN_ISSUE_TYPE_CREATE_ISSUE ]
  },
  {
    option: 'Scorecard Consecutive Tier',
    default: false,
    isEnabled: true,
    isCustom: false,
    usedFor: 0,
    daysCount: 0,
    canBeEdited: false,
    canBeDeleted: false,
    canBeReorder: true,
    isHiddenForSections: [ HIDDEN_ISSUE_TYPE_CREATE_ISSUE ]
  },
  {
    option: 'CX Positive Feedback',
    default: false,
    isEnabled: true,
    isCustom: false,
    usedFor: 0,
    daysCount: 0,
    canBeEdited: false,
    canBeDeleted: false,
    canBeReorder: true,
    isHiddenForSections: [ HIDDEN_ISSUE_TYPE_CREATE_ISSUE ]
  },
  {
    option: 'CX Da Tier',
    default: false,
    isEnabled: true,
    isCustom: false,
    usedFor: 0,
    daysCount: 0,
    canBeEdited: false,
    canBeDeleted: false,
    canBeReorder: true,
    isHiddenForSections: [ HIDDEN_ISSUE_TYPE_CREATE_ISSUE ]
  },
  {
    option: 'CX Cdf Score',
    default: false,
    isEnabled: true,
    isCustom: false,
    usedFor: 0,
    daysCount: 0,
    canBeEdited: false,
    canBeDeleted: false,
    canBeReorder: true,
    isHiddenForSections: [ HIDDEN_ISSUE_TYPE_CREATE_ISSUE ]
  },
  {
    option: 'Mentor Daily FICO®',
    default: false,
    isEnabled: true,
    isCustom: false,
    usedFor: 0,
    daysCount: 0,
    canBeEdited: false,
    canBeDeleted: false,
    canBeReorder: true,
    isHiddenForSections: [ HIDDEN_ISSUE_TYPE_CREATE_ISSUE ]
  },
  {
    option: 'Mentor Seatbelt Rate',
    default: false,
    isEnabled: true,
    isCustom: false,
    usedFor: 0,
    daysCount: 0,
    canBeEdited: false,
    canBeDeleted: false,
    canBeReorder: true,
    isHiddenForSections: [ HIDDEN_ISSUE_TYPE_CREATE_ISSUE ]
  },
  {
    option: 'Mentor Sse',
    default: false,
    isEnabled: true,
    isCustom: false,
    usedFor: 0,
    daysCount: 0,
    canBeEdited: false,
    canBeDeleted: false,
    canBeReorder: true,
    isHiddenForSections: [ HIDDEN_ISSUE_TYPE_CREATE_ISSUE ]
  },
  {
    option: 'Mentor Trainings Remaining',
    default: false,
    isEnabled: true,
    isCustom: false,
    usedFor: 0,
    daysCount: 0,
    canBeEdited: false,
    canBeDeleted: false,
    canBeReorder: true,
    isHiddenForSections: [ HIDDEN_ISSUE_TYPE_CREATE_ISSUE ]
  },
  {
    option: 'Netradyne Camera Obstruction',
    default: false,
    isEnabled: true,
    isCustom: false,
    usedFor: 0,
    daysCount: 0,
    canBeEdited: false,
    canBeDeleted: false,
    canBeReorder: true,
    isHiddenForSections: [ HIDDEN_ISSUE_TYPE_CREATE_ISSUE ]
  },
  {
    option: 'Netradyne Driver Distraction',
    default: false,
    isEnabled: true,
    isCustom: false,
    usedFor: 0,
    daysCount: 0,
    canBeEdited: false,
    canBeDeleted: false,
    canBeReorder: true,
    isHiddenForSections: [ HIDDEN_ISSUE_TYPE_CREATE_ISSUE ]
  },
  {
    option: 'Netradyne Driver Drowsiness',
    default: false,
    isEnabled: true,
    isCustom: false,
    usedFor: 0,
    daysCount: 0,
    canBeEdited: false,
    canBeDeleted: false,
    canBeReorder: true,
    isHiddenForSections: [ HIDDEN_ISSUE_TYPE_CREATE_ISSUE ]
  },
  {
    option: 'Netradyne Driver Initiated',
    default: false,
    isEnabled: true,
    isCustom: false,
    usedFor: 0,
    daysCount: 0,
    canBeEdited: false,
    canBeDeleted: false,
    canBeReorder: true,
    isHiddenForSections: [ HIDDEN_ISSUE_TYPE_CREATE_ISSUE ]
  },
  {
    option: 'Netradyne Face Mask Compliance',
    default: false,
    isEnabled: true,
    isCustom: false,
    usedFor: 0,
    daysCount: 0,
    canBeEdited: false,
    canBeDeleted: false,
    canBeReorder: true,
    isHiddenForSections: [ HIDDEN_ISSUE_TYPE_CREATE_ISSUE ]
  },
  {
    option: 'Netradyne Following Distance',
    default: false,
    isEnabled: true,
    isCustom: false,
    usedFor: 0,
    daysCount: 0,
    canBeEdited: false,
    canBeDeleted: false,
    canBeReorder: true,
    isHiddenForSections: [ HIDDEN_ISSUE_TYPE_CREATE_ISSUE ]
  },
  {
    option: 'Netradyne Hard Acceleration',
    default: false,
    isEnabled: true,
    isCustom: false,
    usedFor: 0,
    daysCount: 0,
    canBeEdited: false,
    canBeDeleted: false,
    canBeReorder: true,
    isHiddenForSections: [ HIDDEN_ISSUE_TYPE_CREATE_ISSUE ]
  },
  {
    option: 'Netradyne Hard Braking',
    default: false,
    isEnabled: true,
    isCustom: false,
    usedFor: 0,
    daysCount: 0,
    canBeEdited: false,
    canBeDeleted: false,
    canBeReorder: true,
    isHiddenForSections: [ HIDDEN_ISSUE_TYPE_CREATE_ISSUE ]
  },
  {
    option: 'Netradyne Hard Turn',
    default: false,
    isEnabled: true,
    isCustom: false,
    usedFor: 0,
    daysCount: 0,
    canBeEdited: false,
    canBeDeleted: false,
    canBeReorder: true,
    isHiddenForSections: [ HIDDEN_ISSUE_TYPE_CREATE_ISSUE ]
  },
  {
    option: 'Netradyne High G',
    default: false,
    isEnabled: true,
    isCustom: false,
    usedFor: 0,
    daysCount: 0,
    canBeEdited: false,
    canBeDeleted: false,
    canBeReorder: true,
    isHiddenForSections: [ HIDDEN_ISSUE_TYPE_CREATE_ISSUE ]
  },
  {
    option: 'Netradyne Low Impact',
    default: false,
    isEnabled: true,
    isCustom: false,
    usedFor: 0,
    daysCount: 0,
    canBeEdited: false,
    canBeDeleted: false,
    canBeReorder: true,
    isHiddenForSections: [ HIDDEN_ISSUE_TYPE_CREATE_ISSUE ]
  },
  {
    option: 'Netradyne Seatbelt Compliance',
    default: false,
    isEnabled: true,
    isCustom: false,
    usedFor: 0,
    daysCount: 0,
    canBeEdited: false,
    canBeDeleted: false,
    canBeReorder: true,
    isHiddenForSections: [ HIDDEN_ISSUE_TYPE_CREATE_ISSUE ]
  },
  {
    option: 'Netradyne Sign Violations',
    default: false,
    isEnabled: true,
    isCustom: false,
    usedFor: 0,
    daysCount: 0,
    canBeEdited: false,
    canBeDeleted: false,
    canBeReorder: true,
    isHiddenForSections: [ HIDDEN_ISSUE_TYPE_CREATE_ISSUE ]
  },
  {
    option: 'Netradyne Speeding Violations',
    default: false,
    isEnabled: true,
    isCustom: false,
    usedFor: 0,
    daysCount: 0,
    canBeEdited: false,
    canBeDeleted: false,
    canBeReorder: true,
    isHiddenForSections: [ HIDDEN_ISSUE_TYPE_CREATE_ISSUE ]
  },
  {
    option: 'Netradyne Traffic Light Violation',
    default: false,
    isEnabled: true,
    isCustom: false,
    usedFor: 0,
    daysCount: 0,
    canBeEdited: false,
    canBeDeleted: false,
    canBeReorder: true,
    isHiddenForSections: [ HIDDEN_ISSUE_TYPE_CREATE_ISSUE ]
  },
  {
    option: 'Netradyne U-turn',
    default: false,
    isEnabled: true,
    isCustom: false,
    usedFor: 0,
    daysCount: 0,
    canBeEdited: false,
    canBeDeleted: false,
    canBeReorder: true,
    isHiddenForSections: [ HIDDEN_ISSUE_TYPE_CREATE_ISSUE ]
  },
  {
    option: 'Netradyne Weaving',
    default: false,
    isEnabled: true,
    isCustom: false,
    usedFor: 0,
    daysCount: 0,
    canBeEdited: false,
    canBeDeleted: false,
    canBeReorder: true,
    isHiddenForSections: [ HIDDEN_ISSUE_TYPE_CREATE_ISSUE ]
  },
  {
    option: 'EOC Daily Compliance Rate',
    default: false,
    isEnabled: true,
    isCustom: false,
    usedFor: 0,
    daysCount: 0,
    canBeEdited: false,
    canBeDeleted: false,
    canBeReorder: true,
    isHiddenForSections: [ HIDDEN_ISSUE_TYPE_CREATE_ISSUE ]
  }
]


export const kudoTypeOptions = [
  {
    option: 'Rescuer',
    default: false,
    isEnabled: true,
    isCustom: false,
    usedFor: 0,
    daysCount: 0,
    canBeEdited: false,
    canBeDeleted: false,
    canBeReorder: true,
  },
  {
    option: 'Scorecard Consecutive Tier',
    default: false,
    isEnabled: true,
    isCustom: false,
    usedFor: 0,
    daysCount: 0,
    canBeEdited: false,
    canBeDeleted: false,
    canBeReorder: true,
    isHiddenForSections: [ HIDDEN_KUDO_TYPE_CREATE_KUDO ]
  },
  {
    option: 'Scorecard Overall Tier',
    default: false,
    isEnabled: true,
    isCustom: false,
    usedFor: 0,
    daysCount: 0,
    canBeEdited: false,
    canBeDeleted: false,
    canBeReorder: true,
    isHiddenForSections: [ HIDDEN_KUDO_TYPE_CREATE_KUDO ]
  },
  {
    option: 'Scorecard FICO® Score',
    default: false,
    isEnabled: true,
    isCustom: false,
    usedFor: 0,
    daysCount: 0,
    canBeEdited: false,
    canBeDeleted: false,
    canBeReorder: true,
    isHiddenForSections: [ HIDDEN_KUDO_TYPE_CREATE_KUDO ]
  },
  {
    option: 'Scorecard DCR',
    default: false,
    isEnabled: true,
    isCustom: false,
    usedFor: 0,
    daysCount: 0,
    canBeEdited: false,
    canBeDeleted: false,
    canBeReorder: true,
    isHiddenForSections: [ HIDDEN_KUDO_TYPE_CREATE_KUDO ]
  },
  {
    option: 'Scorecard DAR',
    default: false,
    isEnabled: true,
    isCustom: false,
    usedFor: 0,
    daysCount: 0,
    canBeEdited: false,
    canBeDeleted: false,
    canBeReorder: true,
    isHiddenForSections: [ HIDDEN_KUDO_TYPE_CREATE_KUDO ]
  },
  {
    option: 'Scorecard DNR',
    default: false,
    isEnabled: true,
    isCustom: false,
    usedFor: 0,
    daysCount: 0,
    canBeEdited: false,
    canBeDeleted: false,
    canBeReorder: true,
    isHiddenForSections: [ HIDDEN_KUDO_TYPE_CREATE_KUDO ]
  },
  {
    option: 'Scorecard DSB',
    default: false,
    isEnabled: true,
    isCustom: false,
    usedFor: 0,
    daysCount: 0,
    canBeEdited: false,
    canBeDeleted: false,
    canBeReorder: true,
    isHiddenForSections: [ HIDDEN_KUDO_TYPE_CREATE_KUDO ]
  },
  {
    option: 'Scorecard SWC-POD',
    default: false,
    isEnabled: true,
    isCustom: false,
    usedFor: 0,
    daysCount: 0,
    canBeEdited: false,
    canBeDeleted: false,
    canBeReorder: true,
    isHiddenForSections: [ HIDDEN_KUDO_TYPE_CREATE_KUDO ]
  },
  {
    option: 'Scorecard SWC-CC',
    default: false,
    isEnabled: true,
    isCustom: false,
    usedFor: 0,
    daysCount: 0,
    canBeEdited: false,
    canBeDeleted: false,
    canBeReorder: true,
    isHiddenForSections: [ HIDDEN_KUDO_TYPE_CREATE_KUDO ]
  },
  {
    option: 'Scorecard SWC-SC',
    default: false,
    isEnabled: true,
    isCustom: false,
    usedFor: 0,
    daysCount: 0,
    canBeEdited: false,
    canBeDeleted: false,
    canBeReorder: true,
    isHiddenForSections: [ HIDDEN_KUDO_TYPE_CREATE_KUDO ]
  },
  {
    option: 'Scorecard SWC-AD',
    default: false,
    isEnabled: true,
    isCustom: false,
    usedFor: 0,
    daysCount: 0,
    canBeEdited: false,
    canBeDeleted: false,
    canBeReorder: true,
    isHiddenForSections: [ HIDDEN_KUDO_TYPE_CREATE_KUDO ]
  },
  {
    option: 'Scorecard Seatbelt-Off Rate',
    default: false,
    isEnabled: true,
    isCustom: false,
    usedFor: 0,
    daysCount: 0,
    canBeEdited: false,
    canBeDeleted: false,
    canBeReorder: true,
    isHiddenForSections: [ HIDDEN_KUDO_TYPE_CREATE_KUDO ]
  },
  {
    option: 'Scorecard Speeding Event Rate',
    default: false,
    isEnabled: true,
    isCustom: false,
    usedFor: 0,
    daysCount: 0,
    canBeEdited: false,
    canBeDeleted: false,
    canBeReorder: true,
    isHiddenForSections: [ HIDDEN_KUDO_TYPE_CREATE_KUDO ]
  },
  {
    option: 'Scorecard Harsh Braking Rate',
    default: false,
    isEnabled: true,
    isCustom: false,
    usedFor: 0,
    daysCount: 0,
    canBeEdited: false,
    canBeDeleted: false,
    canBeReorder: true,
    isHiddenForSections: [ HIDDEN_KUDO_TYPE_CREATE_KUDO ]
  },
  {
    option: 'Scorecard Harsh Cornering Rate',
    default: false,
    isEnabled: true,isCustom: false,
    usedFor: 0,
    daysCount: 0,
    canBeEdited: false,
    canBeDeleted: false,
    canBeReorder: true,
    isHiddenForSections: [ HIDDEN_KUDO_TYPE_CREATE_KUDO ]
  },
  {
    option: 'Scorecard Distractions Rate',
    default: false,
    isEnabled: true,
    isCustom: false,
    usedFor: 0,
    daysCount: 0,
    canBeEdited: false,
    canBeDeleted: false,
    canBeReorder: true,
    isHiddenForSections: [ HIDDEN_KUDO_TYPE_CREATE_KUDO ]
  },
  {
    option: 'Scorecard Following Distance Rate',
    default: false,
    isEnabled: true,
    isCustom: false,
    usedFor: 0,
    daysCount: 0,
    canBeEdited: false,
    canBeDeleted: false,
    canBeReorder: true,
    isHiddenForSections: [ HIDDEN_KUDO_TYPE_CREATE_KUDO ]
  },
  {
    option: 'Scorecard Sign/Signal Violation Rate',
    default: false,
    isEnabled: true,
    isCustom: false,
    usedFor: 0,
    daysCount: 0,
    canBeEdited: false,
    canBeDeleted: false,
    canBeReorder: true,
    isHiddenForSections: [ HIDDEN_KUDO_TYPE_CREATE_KUDO ]
  },
  {
    option: 'CX Positive Feedback',
    default: false,
    isEnabled: true,
    isCustom: false,
    usedFor: 0,
    daysCount: 0,
    canBeEdited: false,
    canBeDeleted: false,
    canBeReorder: true,
    isHiddenForSections: [ HIDDEN_KUDO_TYPE_CREATE_KUDO ]
  },
  {
    option: 'CX Da Tier',
    default: false,
    isEnabled: true,
    isCustom: false,
    usedFor: 0,
    daysCount: 0,
    canBeEdited: false,
    canBeDeleted: false,
    canBeReorder: true,
    isHiddenForSections: [ HIDDEN_KUDO_TYPE_CREATE_KUDO ]
  },
  {
    option: 'CX Cdf Score',
    default: false,
    isEnabled: true,
    isCustom: false,
    usedFor: 0,
    daysCount: 0,
    canBeEdited: false,
    canBeDeleted: false,
    canBeReorder: true,
    isHiddenForSections: [ HIDDEN_KUDO_TYPE_CREATE_KUDO ]
  },
  {
    option: 'Mentor Daily FICO®',
    default: false,
    isEnabled: true,
    isCustom: false,
    usedFor: 0,
    daysCount: 0,
    canBeEdited: false,
    canBeDeleted: false,
    canBeReorder: true,
    isHiddenForSections: [ HIDDEN_KUDO_TYPE_CREATE_KUDO ]
  },
  {
    option: 'Mentor Seatbelt Rate',
    default: false,
    isEnabled: true,
    isCustom: false,
    usedFor: 0,
    daysCount: 0,
    canBeEdited: false,
    canBeDeleted: false,
    canBeReorder: true,
    isHiddenForSections: [ HIDDEN_KUDO_TYPE_CREATE_KUDO ]
  },
  {
    option: 'Mentor Sse',
    default: false,
    isEnabled: true,
    isCustom: false,
    usedFor: 0,
    daysCount: 0,
    canBeEdited: false,
    canBeDeleted: false,
    canBeReorder: true,
    isHiddenForSections: [ HIDDEN_KUDO_TYPE_CREATE_KUDO ]
  },
  {
    option: 'Netradyne Driver Star',
    default: false,
    isEnabled: true,
    isCustom: false,
    usedFor: 0,
    daysCount: 0,
    canBeEdited: false,
    canBeDeleted: false,
    canBeReorder: true,
    isHiddenForSections: [ HIDDEN_KUDO_TYPE_CREATE_KUDO ]
  },
  {
    option: 'EOC Daily Compliance Rate',
    default: false,
    isEnabled: true,
    isCustom: false,
    usedFor: 0,
    daysCount: 0,
    canBeEdited: false,
    canBeDeleted: false,
    canBeReorder: true,
    isHiddenForSections: [ HIDDEN_KUDO_TYPE_CREATE_KUDO ]
  }
]

export const vehicleCompanyOptions = [
  {
    option: 'Alamo',
    default: false,
    isEnabled: true,
    isCustom: false,
    usedFor: 0,
    daysCount: 0,
    canBeEdited: false,
    canBeDeleted: false,
    canBeReorder: true,
  },
  {
    option: 'Avis',
    default: false,
    isEnabled: true,
    isCustom: false,
    usedFor: 0,
    daysCount: 0,
    canBeEdited: false,
    canBeDeleted: false,
    canBeReorder: true,
  },
  {
    option: 'Budget',
    default: false,
    isEnabled: true,
    isCustom: false,
    usedFor: 0,
    daysCount: 0,
    canBeEdited: false,
    canBeDeleted: false,
    canBeReorder: true,
  },
  {
    option: 'Dollar',
    default: false,
    isEnabled: true,
    isCustom: false,
    usedFor: 0,
    daysCount: 0,
    canBeEdited: false,
    canBeDeleted: false,
    canBeReorder: true,
  },
  {
    option: 'Element',
    default: false,
    isEnabled: true,
    isCustom: false,
    usedFor: 0,
    daysCount: 0,
    canBeEdited: false,
    canBeDeleted: false,
    canBeReorder: true,
  },
  {
    option: 'Enterprise',
    default: false,
    isEnabled: true,
    isCustom: false,
    usedFor: 0,
    daysCount: 0,
    canBeEdited: false,
    canBeDeleted: false,
    canBeReorder: true,
  },
  {
    option: 'Fluid Truck',
    default: false,
    isEnabled: true,
    isCustom: false,
    usedFor: 0,
    daysCount: 0,
    canBeEdited: false,
    canBeDeleted: false,
    canBeReorder: true,
  },
  {
    option: 'Hertz',
    default: false,
    isEnabled: true,
    isCustom: false,
    usedFor: 0,
    daysCount: 0,
    canBeEdited: false,
    canBeDeleted: false,
    canBeReorder: true,
  },
  {
    option: 'Lease Plan',
    default: false,
    isEnabled: true,
    isCustom: false,
    usedFor: 0,
    daysCount: 0,
    canBeEdited: false,
    canBeDeleted: false,
    canBeReorder: true,
  },
  {
    option: 'Merchants Fleet',
    default: false,
    isEnabled: true,
    isCustom: false,
    usedFor: 0,
    daysCount: 0,
    canBeEdited: false,
    canBeDeleted: false,
    canBeReorder: true,
  },
  {
    option: 'National',
    default: false,
    isEnabled: true,
    isCustom: false,
    usedFor: 0,
    daysCount: 0,
    canBeEdited: false,
    canBeDeleted: false,
    canBeReorder: true,
  },
  {
    option: 'Thrifty',
    default: false,
    isEnabled: true,
    isCustom: false,
    usedFor: 0,
    daysCount: 0,
    canBeEdited: false,
    canBeDeleted: false,
    canBeReorder: true,
  },
  {
    option: 'Zeeba',
    default: false,
    isEnabled: true,
    isCustom: false,
    usedFor: 0,
    daysCount: 0,
    canBeEdited: false,
    canBeDeleted: false,
    canBeReorder: true,
  },
]

export const associateRosterStatusOptions = [
  {
      order: 1,
      option: "Rostered",
      default: true,
      isDefaultForSections: [ASSOCIATE_ROSTER_STATUS_CREATE_ROUTE],
      usedFor: 0,
      daysCount: 0,
      canBeEdited: false,
      canBeDeleted: false,
      canBeReorder: true,
      isEnabled: true,
      pillColor: 'blue',
      statusType: 'Active (Neutral)'
  },
  {
      order: 2,
      option: "On Time",
      default: false,
      usedFor: 0,
      daysCount: 0,
      canBeEdited: false,
      canBeDeleted: false,
      canBeReorder: true,
      isEnabled: true,
      pillColor: 'green',
      statusType: 'Active (Positive)'
  },
  {
      order: 3,
      option: "Late, With Call",
      default: false,
      usedFor: 0,
      daysCount: 0,
      canBeEdited: false,
      canBeDeleted: false,
      canBeReorder: true,
      isEnabled: true,
      pillColor: 'yellow',
      statusType: 'Active (Neutral)'
  },
  {
      order: 4,
      option: "Late, No Call",
      default: false,
      usedFor: 0,
      daysCount: 0,
      canBeEdited: false,
      canBeDeleted: false,
      canBeReorder: true,
      isEnabled: true,
      pillColor: 'yellow',
      statusType: 'Active (Negative)'
  },
  {
      order: 5,
      option: "VTO",
      default: false,
      usedFor: 0,
      daysCount: 0,
      canBeEdited: false,
      canBeDeleted: false,
      canBeReorder: true,
      isEnabled: true,
      pillColor: 'orange',
      statusType: 'Inactive (Neutral)'
    },
    {
      order: 6,
      option: "Called Out, Excused",
      default: false,
      usedFor: 0,
      daysCount: 0,
      canBeEdited: false,
      canBeDeleted: false,
      canBeReorder: true,
      isEnabled: true,
      pillColor: 'red',
      statusType: 'Inactive (Neutral)'
    },
    {
      order: 7,
      option: "Called Out, Not Excused",
      default: false,
      usedFor: 0,
      daysCount: 0,
      canBeEdited: false,
      canBeDeleted: false,
      canBeReorder: true,
      isEnabled: true,
      pillColor: 'red',
      statusType: 'Inactive (Negative)'
    },
    {
      order: 8,
      option: "No Show, No Call",
      default: false,
      usedFor: 0,
      daysCount: 0,
      canBeEdited: false,
      canBeDeleted: false,
      canBeReorder: true,
      isEnabled: true,
      pillColor: 'red',
      statusType: 'Inactive (Negative)'
    },
]

export const vehiclePhotoLogOptions = [
  {
    order: 1,
    option: "Front of Vehicle",
    default: true,
    isDefaultForSections: [PHOTO_LOG_TYPE_CREATE_VEHICLE_PHOTO_LOG],
    usedFor: 0,
    daysCount: 0,
    canBeEdited: true,
    canBeDeleted: true,
    canBeReorder: true,
    isEnabled: true
  },
  {
    order: 2,
    option: "Back of Vehicle",
    default: true,
    isDefaultForSections: [PHOTO_LOG_TYPE_CREATE_VEHICLE_PHOTO_LOG],
    usedFor: 0,
    daysCount: 0,
    canBeEdited: true,
    canBeDeleted: true,
    canBeReorder: true,
    isEnabled: true
  },
  {
    order: 3,
    option: "Left Side of Vehicle",
    default: true,
    isDefaultForSections: [PHOTO_LOG_TYPE_CREATE_VEHICLE_PHOTO_LOG],
    usedFor: 0,
    daysCount: 0,
    canBeEdited: true,
    canBeDeleted: true,
    canBeReorder: true,
    isEnabled: true
  },
  {
    order: 4,
    option: "Right Side of Vehicle",
    default: true,
    isDefaultForSections: [PHOTO_LOG_TYPE_CREATE_VEHICLE_PHOTO_LOG],
    usedFor: 0,
    daysCount: 0,
    canBeEdited: true,
    canBeDeleted: true,
    canBeReorder: true,
    isEnabled: true
  },
  {
    order: 5,
    option: "Engine",
    default: false,
    usedFor: 0,
    daysCount: 0,
    canBeEdited: true,
    canBeDeleted: true,
    canBeReorder: true,
    isEnabled: true
  },
  {
    order: 6,
    option: "Dipstick",
    default: false,
    usedFor: 0,
    daysCount: 0,
    canBeEdited: true,
    canBeDeleted: true,
    canBeReorder: true,
    isEnabled: true
  },
  {
    order: 7,
    option: "Odometer",
    default: true,
    isDefaultForSections: [PHOTO_LOG_TYPE_CREATE_VEHICLE_PHOTO_LOG],
    usedFor: 0,
    daysCount: 0,
    canBeEdited: true,
    canBeDeleted: true,
    canBeReorder: true,
    isEnabled: true
  },
  {
    order: 8,
    option: "Interior Front-Left",
    default: false,
    usedFor: 0,
    daysCount: 0,
    canBeEdited: true,
    canBeDeleted: true,
    canBeReorder: true,
    isEnabled: true
  },
  {
    order: 9,
    option: "Interior Front-Right",
    default: false,
    usedFor: 0,
    daysCount: 0,
    canBeEdited: true,
    canBeDeleted: true,
    canBeReorder: true
  },
  {
    order: 10,
    option: "Interior Cargo Bay",
    default: false,
    usedFor: 0,
    daysCount: 0,
    canBeEdited: true,
    canBeDeleted: true,
    canBeReorder: true,
    isEnabled: true
  },
  {
    order: 11,
    option: "Tire Front-Left",
    default: false,
    usedFor: 0,
    daysCount: 0,
    canBeEdited: true,
    canBeDeleted: true,
    canBeReorder: true,
    isEnabled: true
  },
  {
    order: 12,
    option: "Tire Front-Right",
    default: false,
    usedFor: 0,
    daysCount: 0,
    canBeEdited: true,
    canBeDeleted: true,
    canBeReorder: true,
    isEnabled: true
  },
  {
    order: 13,
    option: "Tire Back-Left",
    default: false,
    usedFor: 0,
    daysCount: 0,
    canBeEdited: true,
    canBeDeleted: true,
    canBeReorder: true,
    isEnabled: true
  },
  {
    order: 14,
    option: "Tire Back-Right",
    default: false,
    usedFor: 0,
    daysCount: 0,
    canBeEdited: true,
    canBeDeleted: true,
    canBeReorder: true,
    isEnabled: true
  },
  {
    order: 15,
    option: "License Plate",
    default: false,
    usedFor: 0,
    daysCount: 0,
    canBeEdited: true,
    canBeDeleted: true,
    canBeReorder: true,
    isEnabled: true
  },
]