export const messagesByGroup = /* GraphQL */ `
  query MessagesByGroup(
    $group: String
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelMessageFilterInput
    $limit: Int
    $nextToken: String
  ) {
    messagesByGroup(
        group: $group
        createdAt: $createdAt
        sortDirection: $sortDirection
        filter: $filter
        limit: $limit
        nextToken: $nextToken
    ) {
        items {
            id
            staff {
                id
                firstName
                lastName
                status
            }
            attachment{
              id
              s3Key
              contentType
            }
            channelType
            destinationNumber
            destinationEmail
            subject
            bodyText
            bodyHtml
            smsStatus
            smsSendInformation
            emailStatus
            emailSendInformation
            createdAt
            updatedAt
            messageType
            hasParts
        }
        nextToken
    }
  }
`;

export const messagesByStaffId = /* GraphQL */ `
  query MessagesByStaffId(
    $staffId: ID
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelMessageFilterInput
    $limit: Int
    $nextToken: String
  ) {
    messagesByStaffId(
      staffId: $staffId
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        staff {
          id
          firstName
          lastName
          status
        }
        createdAt
        channelType
        group
        staffId
        subject
        bodyText
        senderName
        smsStatus
        smsSendInformation
        isReadS
        sender {
          id
        }
        messageType
        attachmentLink
        contentType
        attachment{
          id
          s3Key
          fileSize
          contentType
          expirationDate
          shortenUrl {
            items {
              id
              shortenId
            }
            nextToken
          }
        }
        hasParts
      }
      nextToken
    }
  }
`;