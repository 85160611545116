export const getTenant = /* GraphQL */ `
  query GetTenant($id: ID!) {
    getTenant(id: $id) {
      id
      timeZone
      automatedCoachingSendTime
      companyName
      dispatchNumber
      addressLine1
      addressLine2
      addressCity
      addressState
      addressZip
      accidentReport
      nameLog
      stripeBillingEmail
      stripeCustomerId
      shortCode
      workmansComp
      originationNumber
      logo
      updatedAt
    }
  }
`;