<template>
  <div>
    <div class="flex items-center justify-between mb-4">
      <h1>Performance Imports</h1>
    </div>
    <p class="text-gray-600 text-base font-bold">Daily</p>
    <div class="grid grid-flow-row grid-cols-12 w-full py-3 gap-4">
      <div class="col-span-12 md:col-span-6 lg:col-span-3">
        <div>Disable Netradyne Imports</div>
        <el-switch
          v-model="systemData.importNetradyneDisabled"
          active-color="#13ce66"
          @change="updateSystem('Netradyne')"
        >
        </el-switch>
      </div>
      <div class="col-span-12 md:col-span-6 lg:col-span-3">
        <div>Disable Mentor Imports</div>
        <el-switch
          v-model="systemData.importMentorDisabled"
          active-color="#13ce66"
          @change="updateSystem('Mentor')"
        >
        </el-switch>
      </div>
      <div class="col-span-12 md:col-span-6 lg:col-span-3">
        <div>Disable EOC Imports</div>
        <el-switch
          v-model="systemData.importEocDisabled"
          active-color="#13ce66"
          @change="updateSystem('Eoc')"
        >
        </el-switch>
      </div>
    </div>
    <p class="text-gray-600 text-base font-bold">Weekly</p>
    <div class="grid grid-flow-row grid-cols-12 w-full py-3 gap-4">
      <div class="col-span-12 md:col-span-6 lg:col-span-3">
        <div>Disable Scorecard Imports</div>
        <el-switch
          v-model="systemData.importDisabled"
          active-color="#13ce66"
          @change="updateSystem('')"
        >
        </el-switch>
      </div>
      <div class="col-span-12 md:col-span-6 lg:col-span-3">
        <div>Disable POD Imports</div>
        <el-switch
          v-model="systemData.importPodDisabled"
          active-color="#13ce66"
          @change="updateSystem('Pod')"
        >
        </el-switch>
      </div>
      <div class="col-span-12 md:col-span-6 lg:col-span-3">
        <div>Disable CX Imports</div>
        <el-switch
          v-model="systemData.importCxDisabled"
          active-color="#13ce66"
          @change="updateSystem('Cx')"
        >
        </el-switch>
      </div>
      <div class="col-span-12 md:col-span-6 lg:col-span-3">
        <div>Disable PPS Imports</div>
        <el-switch
          v-model="systemData.importPpsDisabled"
          active-color="#13ce66"
          @change="updateSystem('Pps')"
        >
        </el-switch>
      </div>
    </div>
  </div>
</template>

<script>
import { listSystems } from "./queries";
import { updateSystem } from "@/graphql/mutations";

export default {
  name: "PerformanceImports",
  data() {
    return {
      loading: false,
      systemData: [],
      systemDataLoaded: false,
    };
  },
  async mounted() {
    await this.loadData();
  },
  methods: {
    /**
     * Load data using the listSystems custom GraphQL query
     */
    async loadData() {
      this.loading = true;
      try {
        const system = await this.gLoadListAll(listSystems, {}, "listSystems");
        this.systemData = system[0];
        this.systemDataLoaded = true;
      } catch (e) {
        console.error(e);
        this.displayUserError(e);
      } finally {
        this.loading = false;
      }
    },
    async updateSystem(type) {
      this.loading = true;
      const status = this.systemData["import" + type + "Disabled"]
        ? "Disabled"
        : "Enabled";

      try {
        const input = {
          ...this.systemData,
        };
        this.api(updateSystem, { input: input });
        if (!type) type = "Scorecard";
        else type = type.toUpperCase();
        //success
        this.$message({
          type: "success",
          message: `${type} Imports ${status}`,
        });
      } catch (e) {
        console.error(e);
        this.displayUserNotification({
          title: "Error",
          type: "error",
          message: e.errors[0].message,
        });
      } finally {
        this.loading = false;
      }
    },
  },
};
</script>

<style lang="scss" scoped></style>
