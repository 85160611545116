<template>
  <div>
    <div v-if="isBanner" class="bg-red-600 text-white px-2 py-1" role="alert">
      <div class="flex">
        <div class="flex-1 text-center" name="hera-banner">
          <p class="font-bold">Please fix your device's clock.</p>
          <p class="text-sm">
            Your device's clock is not in sync with the current time. Due to
            Amazon Web Services' security safeguards, you cannot use Hera until
            you set your clock to the current time.
          </p>
          <p class="text-sm">
            <strong
              >Once you have fixed your clock,
              <a @click="newTabLogin" class="underline cursor-pointer"
                >please open Hera in a new tab</a
              >
              to log in.</strong
            >
          </p>
          <p class="text-sm">
            <a
              href="https://herasolutionsinc.zohodesk.com/portal/en/kb/articles/title-setting-your-device-s-clock"
              target="_blank"
              class="underline"
              >To learn how to update your clock, please see our support
              article.</a
            >
          </p>
        </div>
      </div>
    </div>
    <div v-else class="max-w-xs mx-auto mb-2">
      <div class="text-center">
        <!-- Title: Please fix your device's clock -->
        <p class="text-red-600 text-xl font-bold mb-2">
          Please fix your device's clock
        </p>
      </div>
      <div class="text-justify">
        <!-- Message: Your device's clock is not in sync with the current time... -->
        <p class="mb-2 text-sm">
          Your device's clock is not in sync with the current time. Due to
          Amazon Web Services' security safeguards, you cannot use Hera until
          you set your clock to the current time.
        </p>
        <!-- Bold Text: Once you have fixed your clock, please refresh this page to log in. -->
        <p class="mb-2 text-sm">
          <strong
            >Once you have fixed your clock,
            <a
              @click="newTabLogin"
              class="text-blue-600 underline cursor-pointer"
              >please open Hera in a new tab</a
            >
            to log in.</strong
          >
        </p>
        <!-- Link: To learn how to update your clock, please see our support article. -->
        <p class="text-sm">
          <a
            href="https://herasolutionsinc.zohodesk.com/portal/en/kb/articles/title-setting-your-device-s-clock"
            target="_blank"
            class="text-blue-600 underline"
            >To learn how to update your clock, please see our support
            article.</a
          >
        </p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "ClockSyncMessage",
  props: {
    isBanner:{
      type: Boolean,
      required: false,
      default: false
    }
  },
  methods: {
    // Method to refresh the page
    newTabLogin() {
      window.open(window.location.href, "_blank");
    },
  },
};
</script>
