export const vehicleSelectOptionsDefault = [
  {filter: 'status',value: ['Active']},
  {filter: 'vehicleType',value: []},
  {filter: 'labels',value: []},
  {filter: 'company',value: []}
];
  
export const associateSelectOptionsDefault = [
  {filter: 'status',value: ['Active']},
  {filter: 'labels',value: []},
  {filter: 'prefferedDaysOff',value: []}
];
