export const getOptionsCustomLists = /* GraphQL */ `
  query GetOptionsCustomLists($id: ID!) {
    getOptionsCustomLists(id: $id) {
      id
      accidents { items { id } }
      vehicles { items { id } }
      parkingSpace { items { id } }
      associates { items { id } }
      routeParkingSpace { items { id } }
      replaceByRouteParkingSpace { items { id } }
      counselings { items { id } }
      issues { items { id } }
      kudos { items { id } }
      companies { items { id } }
      routes { items { id } }
      routeHelperStatus { items { id } }
      replaceByRoutes { items { id } }
    }
  }
`;