import store from '@/store/index.js'

import System from '@/views/System/System'
import SystemNoSelection from '@/views/System/SystemNoSelection'
import TenantIndex from '@/views/System/Tenants/TenantIndex'
import TenantDetail from '@/views/System/Tenants/TenantDetail'
import PerformanceImports from '@/views/System/PerformanceImports/PerformanceImports'
import Integrations from '@/views/System/Integrations/Integrations'
import ExpiringTrials from '@/views/System/ExpiringTrials/ExpiringTrials'
import Billing from '@/views/System/Billing/Billing'
import PremiumStatus from '@/views/System/PremiumStatus/PremiumStatus'
import Telnyx from '@/views/System/Telnyx/Telnyx'

export default [
    {
        path: '',
        name: 'SystemNoSelection',
        components: {
            default: System,
            settings: SystemNoSelection
        }
    },
    {
        path: 'tenants',
        name: 'SystemTenantIndex',
        meta: { crumbTitle: 'Tenants'},
        beforeEnter: onlyAllowSystemAdmin,
        components: {
          default: System,
          settings: TenantIndex
        }
      },
      {
        path: 'performance-imports',
        name: 'PerformanceImports',
        meta: { crumbTitle: 'Performance Imports'},
        beforeEnter: onlyAllowSystemAdmin,
        components: {
          default: System,
          settings: PerformanceImports
        }
      },
      {
        path: 'integrations',
        name: 'Integrations',
        meta: { crumbTitle: 'Integrations'},
        beforeEnter: onlyAllowSystemAdmin,
        components: {
          default: System,
          settings: Integrations
        }
      },
      {
        path: 'tenants/:id',
        name: 'SystemTenantDetail',
        props: { default: true, settings: true },
        meta: { crumbTitle: 'Tenant Details'},
        beforeEnter: onlyAllowSystemAdmin,
        components: {
          default: System,
          settings: TenantDetail
        }
      },
      {
        path: 'expiring-trials',
        name: 'SystemExpiringTrials',
        props: { default: true, settings: true },
        meta: { crumbTitle: 'Expiring Trials'},
        beforeEnter: onlyAllowSystemAdmin,
        components: {
          default: System,
          settings: ExpiringTrials
        }
      },
      {
        path: 'billing',
        name: 'SystemBilling',
        props: { default: true, settings: true },
        meta: { crumbTitle: 'Billing'},
        beforeEnter: onlyAllowSystemAdmin,
        components: {
          default: System,
          settings: Billing
        }
      },
      {
        path: 'premium-status',
        name: 'SystemPremiumStatus',
        props: { default: true, settings: true },
        meta: { crumbTitle: 'Premium Status'},
        beforeEnter: onlyAllowSystemAdmin,
        components: {
          default: System,
          settings: PremiumStatus
        }
      },
      {
        path: 'telnyx',
        name: 'SystemTelnyx',
        props: { default: true, settings: true },
        meta: { crumbTitle: 'Telnyx'},
        beforeEnter: onlyAllowSystemAdmin,
        components: {
          default: System,
          settings: Telnyx
        }
      },
]

async function onlyAllowSystemAdmin(to, from, next){
    window.scrollTo(0, 0);
    const groups = await getGroups()
    if(!groups.includes("system_admin")){
      next({
        path: from.path,
        query: { error: "You Don't Have Access To This Area" }
      })
    }else{
      next()
    }
  }

  function getGroups () {
    return new Promise((resolve, reject) => {
      if (store.getters.groups === undefined) {
        const unwatch = store.watch(
          () => store.getters.groups,
          (value) => {
            unwatch()
            resolve(value)
          }
        )
      } else {
        resolve(store.getters.groups)
      }
    })
  }