import { mixinMethods } from '@/main'
import {
    onCreateUserByTenant,
    onUpdateUserByTenant,
    onDeleteUserByTenant
} from '../../subscriptions';
import store from '@/store'
import { usersByTenant } from '../../queries'
import { initializeSubscription, disconnectSubscription } from '../helper'

"use strict";
let subscriptionUsers = null

export const loadUsers = async function(){
    if(!store.state.userInfo?.tenant?.id) return
    const input = {
        userTenantId: store.state.userInfo.tenant.id
    }
    const users = await mixinMethods.gLoadListAll(usersByTenant, input, "usersByTenant")
    store.dispatch("subscriptionStore/setUserList", users)
}

export const userSubscribe = function(instance){
    
    const queries = {
        onCreateUserByTenant,
        onUpdateUserByTenant,
        onDeleteUserByTenant
    }
    if(!store.state.userInfo?.tenant?.id) return
    const input = {
        userTenantId: store.state.userInfo.tenant.id
    }
    initializeSubscription(subscriptionUsers, queries, input, "subscriptionStore/mutateUser", instance)
}

export const config = function(subscriptions){
    const subscription = {}
    subscriptionUsers = subscriptions['user'] = {
        loadList: loadUsers,
        subscribe: userSubscribe,
        unsubscribe: (instance) => disconnectSubscription(subscription, instance),
        callback: userEventCallback,
        subscription
    }
}

export const userEventCallback = function(subscriptionState, payload){
    //
}