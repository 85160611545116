var generateTemplate = function(unpaidInvoices){
    // sort
    unpaidInvoices.sort(function (a,b){
        return new Date(a.createdAt) - new Date(b.createdAt);
    })

    var invoicesHtml = ''
    const months = ['Jan','Feb','Mar','Apr','May','Jun','Jul','Aug','Sep','Oct','Nov','Dec']
    // gen html string
    unpaidInvoices.forEach(invoice =>{
        // set up totals
        var taxRate = 0
        var fixedDiscount = invoice.discountFixed ? invoice.discountFixed : 0
        var percentDiscount = invoice.discountPercent ? invoice.discountPercent / 100 * (invoice.invoiceTotal - fixedDiscount) : 0
        var subtotalLessDiscount = invoice.invoiceTotal - fixedDiscount - percentDiscount
        var subtotalPlusTaxes = (subtotalLessDiscount + subtotalLessDiscount * taxRate).toFixed(2)

        invoicesHtml += `
        <tr>
            <td class="text-left w-1/3">${invoice.year} ${months[invoice.month]}</td>
            <td class="text-left w-1/3">${invoice.status}</td>
            <td class="text-right w-1/3">$${subtotalPlusTaxes}</td>
        </tr>
        `
       
    })

    if(unpaidInvoices.length > 0) {
        invoicesHtml = `
        <div class="mb-2">
            This will immediately charge your new active card with the following invoices.
        </div>
        <table class="w-full">
            <tr>
                <th class="text-left w-1/3">Month</th>
                <th class="text-left w-1/3">Status</th>
                <th style="text-align: right; width: 33%;">Total</th>
            </tr>
            ${invoicesHtml}
        </table>`;
    }
   

    return invoicesHtml
}

export default generateTemplate;