export default (userTable) => {

  return {
    cols: [
      {
        name: "First Name",
        col: "firstName",
        fixed: false,
        width: "110",
        sortable: true,
        sortMethod: userTable.sortFirstName
      },
      {
        name: "Last Name",
        col: "lastName",
        fixed: false,
        width: "110",
        sortable: true,
        sortMethod: userTable.sortLastName
      },
      {
        name: "Phone",
        col: "phone",
        fixed: false,
        width: "120",
        sortable: true,
        sortMethod: (a, b) => userTable.sortByString(a.phone, b.phone)
      },
      {
        name: "Email",
        col: "email",
        fixed: false,
        width: "190",
        sortable: true,
        sortMethod: (a, b) => userTable.sortByString(a.email, b.email)
      },
      {
        name: "Last Login",
        col: "lastLogin",
        fixed: false,
        width: "120",
        sortable: true,
        sortMethod: (a, b) => userTable.sortByString(b.lastLogin, a.lastLogin)
      },
      {
        name: "Permissions",
        col: "permissions",
        fixed: false,
        width: "140",
        sortable: true,
        sortMethod: (a, b) => a.permissionLogin - b.permissionLogin
      }
    ],

    optionsContextHeader: [
      { label: "Add User", action: 'add', divided: false },
    ],

    optionsContextRow: [
      {
        label: "Mark as Owner",
        action: "markAsOwner",
        divided: false,
        condition: (row) => userTable.$store.getters.isAdmin && !row.isOwner
      },
      {
        label: "Edit User",
        action: 'edit',
        divided: false
      },
      {
        label: "ResetPassword",
        action: "reset-password",
        divided: true,
        dynamicLabel: (row) => row.lastLogin === 'NOT_YET_LOGGED' ? "Resend Temporary Password" : "Email password reset link",
        dynamicAction: (row) => row.lastLogin === 'NOT_YET_LOGGED' ? "resend-password" : "reset-password"
      },
      {
        label: "Deactivate/Reactivate",
        action: 'deactivate',
        divided: false,
        dynamicLabel: (row) => row.permissionLogin ? 'Deactivate User' : 'Reactivate User'
      },
      {
        label: "Delete User",
        action: "delete",
        divided: false,
        condition: (row) => row.lastLogin === 'NOT_YET_LOGGED'
      }
    ]
  }
}