<template>
  <el-form 
  :model="labelForm" 
  status-icon 
  :rules="rules" 
  ref="labelForm" 
  class="p-4"
  size="small">
    <el-form-item label="Label Name" prop="labelName">
      <template slot="label" v-if="openMode == 'update'">
        <div class="flex items-center">
            <span>Label Name</span>
            <el-tooltip class="item leading-10 z-40" effect="dark" placement="bottom" >
            <div slot="content"> 
                <p class="font-bold mb-1 text-center">Be careful: editing the name of this Label will change it everywhere in<br>Hera - including in all the places it has already been applied.</p>
            </div>
            <p class="text-gray-600"><i class="uil uil-question-circle text-base"></i></p>
            </el-tooltip>
        </div>          
      </template>
      <el-input type="text" v-model="labelForm.labelName" placeholder="Insert a new Label Name" autocomplete="off"></el-input>
    </el-form-item>
    <el-form-item label="Can Be Used For" prop="usedForSelection">
      <template slot="label">
          <div class="flex items-center">
              <span>Can Be Used For </span>
              <el-tooltip class="item leading-10 z-40" effect="dark" placement="top" >
              <div slot="content"> 
                  <p class="font-bold mb-1 text-center">This is where your Label can be used.<br>You must allow an active Label to be used for at least one of these.</p>
              </div>
              <p class="text-gray-600"><i class="uil uil-question-circle text-base"></i></p>
              </el-tooltip>
          </div>          
      </template>
      <!-- selection of labels type -->
      <el-select v-model="labelForm.usedForSelection" class="w-full" multiple placeholder="Select an option">
          <el-option
          v-for="item in labelForm.labelTypeOptions"
          :key="item.id"
          :label="item.name"
          :value="item.name">
          </el-option>
      </el-select>
      <!-- <el-input type="usedForSelection" v-model="labelForm.usedForSelection"></el-input> -->
    </el-form-item>
    <!-- <el-form-item>
      <el-button type="primary" @click="submitForm('labelForm')">Submit</el-button>
      <el-button @click="resetForm('labelForm')">Reset</el-button>
    </el-form-item> -->
  </el-form>
</template>
<script>
import { mapGetters } from 'vuex'

  export default {
    name: "LabelDrawerForm",
    props: ['openMode','defaultLabelType','updateLabelName'],
    data() {
      var validateName = (rule, value, callback) => {
        if (value === '' || value.includes(';')) {
          const errorMessage = value === '' ? 'Please write a label name' : 'The label name must not contain a semicolon';
          callback(new Error(errorMessage));
        } 
      };
      var validateUsedFor = (rule, value, callback) => {
        if (value.length === 0) {
          callback(new Error('Please select one option again'));
        }
      };
      return {
        labelForm: {
          labelName: '',
          usedForSelection: [],
          labelTypeOptions: [],
        },
        value: '',
        rules: {
          labelName: [
            { validator: validateName, trigger: 'change' },  
          ],
          usedForSelection: [
            { validator: validateUsedFor, trigger: 'change' }
          ]
        }
      };
    },
    beforeMount() {
      this.availableLabels()
      this.assignDefaultValues()
    },
    computed: {
      ...mapGetters([
        'getLabelTypes',
        'hasStaffManagement',
        'hasVehicleManagement'
      ])
    },
    methods: {
      async availableLabels(){
        if (this.hasStaffManagement && this.hasVehicleManagement) this.labelForm.labelTypeOptions = this.getLabelTypes
        else if (this.hasStaffManagement && (this.hasVehicleManagement == false)) this.labelForm.labelTypeOptions = [this.getLabelTypes.find(type => type.name === "Associates")]
        else if (this.hasVehicleManagement && (this.hasStaffManagement == false)) this.labelForm.labelTypeOptions = [this.getLabelTypes.find(type => type.name === "Vehicles")]
      },
      async assignDefaultValues(){
        // default values depends also for permissions
        let allowedOptions = this.labelForm.labelTypeOptions.map(item => item.name)
        let defaultFinalOption = []
        if (this.defaultLabelType && allowedOptions.includes(this.defaultLabelType)) defaultFinalOption.push(this.defaultLabelType)
        else defaultFinalOption = allowedOptions
        //for now update allows to update a label with their type of labels
        if( this.openMode == 'update') this.labelForm.usedForSelection = this.defaultLabelType
        else this.labelForm.usedForSelection = defaultFinalOption
        if (this.updateLabelName) this.labelForm.labelName = this.updateLabelName
      },
    }
  }
</script>
