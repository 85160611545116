<template>
    <div>
        <div class="z-10 absolute">
            <span class="context-menu bg-gray-100">
                <i class="uil uil-ellipsis-h" :class="getVerticalAdjustment"></i>
            </span>
        </div>
        
        <div class="z-0 mx-1">
            <span class="context-menu"></span>
        </div>
    </div>
</template>

<script>
    export default {
        props:{
            verticalAdjustment:{
                type: Boolean,
                required: false,
                default: false
            }
        },
        name: 'ContextMenuHeaderIcon',
        computed: {
            getVerticalAdjustment(){
                return this.verticalAdjustment ? '-mt-0.75' : ''
            }
        }
    }
</script>

<style scoped>

</style>