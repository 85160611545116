import { mixinMethods } from '@/main';
import {
    onCreateResourceUsageByGroup,
    onUpdateResourceUsageByGroup,
    onDeleteResourceUsageByGroup
} from '../../subscriptions';
import store from '@/store'
import { resourceUsageByGroup } from '@/graphql/queries';
import {
    initializeSubscription,
    disconnectSubscription,
} from '../helper'
import EventBus from '@/eventBus'
import { API } from 'aws-amplify';

"use strict";
let subscriptionResourceUsage = null

export const loadResourcesUsage = async function(){
    if(!store.state.userInfo?.tenant?.group) return
    const input = {
        group: store.state.userInfo.tenant.group
    }
    const resourcesUsage = await mixinMethods.gLoadListAll(resourceUsageByGroup, input, 'resourceUsageByGroup')
    store.dispatch("subscriptionStore/setResourceUsageList", resourcesUsage)
}

export const resourceUsageSubscribe = function(instance){
    const queries = {
        onCreateResourceUsageByGroup,
        onUpdateResourceUsageByGroup,
        onDeleteResourceUsageByGroup
    }
    if(!store.state.userInfo?.tenant?.group) return
    const input = {
        group: store.state.userInfo.tenant.group
    }
    initializeSubscription(subscriptionResourceUsage, queries, input, "subscriptionStore/mutateResourceUsage", instance)
}

export const config = function(subscriptions){
    const subscription = {}
    subscriptionResourceUsage = subscriptions['resourceUsage'] = {
        loadList: loadResourcesUsage,
        subscribe: resourceUsageSubscribe,
        unsubscribe: (instance) => disconnectSubscription(subscription, instance),
        callback: resourceUsageEventCallback,
        subscription
    }
}

export const resourceUsageEventCallback = function(subscriptionState, {data: resourceUsage, eventName}) {
    const eventNames = {
        'onCreate': () => {
            const existsAnotherUsages = subscriptionState.resourceUsageList.filter(item => item.resourceIdentifier === resourceUsage.resourceIdentifier && item.id !== resourceUsage.id)
            if (existsAnotherUsages.length) {
                try {
                    let apiName = 'heraPublicApi';
                    let path = '/executeMutation';
                    let post = {
                      body:{
                        "type": 'resource-usage',
                        "mutation": 'delete',
                        "group": store.state.userInfo.tenant.group,
                        "owner": store.state.userInfo.cognitoSub,
                        "items": {...resourceUsage}
                      }
                    }
          
                    safeFunction(API.post)(apiName, path, post);
                  } catch (e) {
                    console.log("Error: ", e);
                  }
            } else {
                EventBus.$emit('using-note', resourceUsage)
            }
        },
        'onUpdate': () => {
            EventBus.$emit('using-note', resourceUsage)
        },
        'onDelete': () => {
            EventBus.$emit('using-note', resourceUsage)
        }
    }
    eventNames[eventName]()
}