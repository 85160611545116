//Newer versions of template should be at top
export default{
    versionLookup: [
        {
            key: "week",
            instance: 1,
            relativePos: 0,  
            regex: "(Week) (\\d\\d?)$",   
            regexGroup: 1,     
            pattern: "Week",
            transform: "patternMatches[2]", 
        }
    ],
    versions:[
        {
            start: 202144,        
            fields: [
                {
                    key: "week",
                    instance: 1,
                    relativePos: 0,  
                    regex: "(Week) (\\d\\d?)$",   
                    regexGroup: 1,     
                    pattern: "Week",
                    transform: "patternMatches[2]", 
                }               
            ],
            tables: [
                {
                    table_pattern:[
                        "transporter_id", 
                        "driver_name",
                        "%_positive",
                        "feedback",
                        "%_negative",
                        "feedback",
                        "delivery",
                        "was",
                        "great",
                    ],
                    table_match_percent: .1,
                    table_data:[],
                    min_data: 15,
                    columns: [
                        { 
                            slug: "transporter_id",
                            left: 0,
                            right: 0.092
                        },
                        { 
                            slug: "driver_name",
                            left: 0.092,
                            right: 0.192
                        },
                        { 
                            slug: "%_positive_feedback",
                            left: 0.192,
                            right: 0.246
                        },
                        { 
                            slug: "$_negative_feedback",
                            left: 0.246,
                            right: 0.293
                        },
                        { 
                            slug: "delivery_was_great",
                            left: 0.330,
                            right: 0.373
                        },
                        { 
                            slug: "delivery_was_not_so_great",
                            left: 0.595,
                            right: 0.640
                        },
                        { 
                            slug: "respectful_of_property",
                            left: 0.373,
                            right: 0.423
                        },
                        { 
                            slug: "followed_instructions",
                            left: 0.423,
                            right: 0.474
                        },
                        { 
                            slug: "friendly",
                            left: 0.474,
                            right: 0.515
                        },
                        { 
                            slug: "went_above_and_beyond",
                            left: 0.515,
                            right: 0.554
                        },
                        { 
                            slug: "delivered_with_care",
                            left: 0.554,
                            right: 0.595
                        },
                        { 
                            slug: "mishandled_package",
                            left: 0.640,
                            right: 0.686
                        },
                        { 
                            slug: "driver_unprofessional",
                            left: 0.686,
                            right: 0.737
                        },
                        { 
                            slug: "not_delivered_to_preferred_location",
                            left: 0.737,
                            right: 0.798
                        }
                    ]
                }
            ]
        },
        {
            start: 202121,        
            fields: [
                {
                    key: "week",
                    instance: 1,
                    relativePos: 0,  
                    regex: "(Week) (\\d\\d?)$",   
                    regexGroup: 1,     
                    pattern: "Week",
                    transform: "patternMatches[2]", 
                }               
            ],
            tables: [
                {
                    table_pattern:[
                        "transporter_id", 
                        "driver_name",
                        "%_positive",
                        "feedback",
                        "%_negative",
                        "feedback",
                        "delivery",
                        "was",
                        "great",
                    ],
                    table_match_percent: .8,
                    table_data:[],
                    min_data: 15,
                    columns: [
                        { 
                            slug: "transporter_id",
                            left: 0,
                            right: 0.101
                        },
                        { 
                            slug: "driver_name",
                            left: 0.101,
                            right: 0.200
                        },
                        { 
                            slug: "%_positive_feedback",
                            left: 0.200,
                            right: 0.261
                        },
                        { 
                            slug: "$_negative_feedback",
                            left: 0.261,
                            right: 0.318
                        },
                        { 
                            slug: "delivery_was_great",
                            left: 0.318,
                            right: 0.360
                        },
                        { 
                            slug: "delivery_was_not_so_great",
                            left: 0.360,
                            right: 0.421
                        },
                        { 
                            slug: "total_deliveries_with_customer_feedback",
                            left: 0.421,
                            right: 0.505
                        },
                        { 
                            slug: "respectful_of_property",
                            left: 0.505,
                            right: 0.564
                        },
                        { 
                            slug: "followed_instructions",
                            left: 0.564,
                            right: 0.629
                        },
                        { 
                            slug: "friendly",
                            left: 0.629,
                            right: 0.667
                        },
                        { 
                            slug: "went_above_and_beyond",
                            left: 0.667,
                            right: 0.716
                        },
                        { 
                            slug: "delivered_with_care",
                            left: 0.716,
                            right: 0.774
                        },
                        { 
                            slug: "mishandled_package",
                            left: 0.774,
                            right: 0.840
                        },
                        { 
                            slug: "driver_unprofessional",
                            left: 0.840,
                            right: 0.913
                        },
                        { 
                            slug: "not_delivered_to_preferred_location",
                            left: 0.913,
                            right: 1.000
                        }
                    ]
                }
            ]
        },
        {
            start: 202029,        
            fields: [
                {
                    key: "week",
                    instance: 1,
                    relativePos: 0,  
                    regex: "(Week) (\\d\\d?)$",   
                    regexGroup: 1,     
                    pattern: "Week",
                    transform: "patternMatches[2]", 
                }               
            ],
            tables: [
                {
                    table_pattern:[
                        "transporter_id", 
                        "driver_name",
                        "%_positive",
                        "feedback",
                        "%_negative",
                        "feedback",
                        "delivery",
                        "was",
                        "great",
                    ],
                    table_match_percent: .8,
                    table_data:[],
                    min_data: 16,
                    columns: [
                        { 
                            slug: "transporter_id",
                            left: 0,
                            right: 0.086730414
                        },
                        { 
                            slug: "driver_name",
                            left: 0.086730414,
                            right: 0.173488788
                        },
                        { 
                            slug: "%_positive_feedback",
                            left: 0.173488788,
                            right: 0.225661243
                        },
                        { 
                            slug: "$_negative_feedback",
                            left: 0.225661243,
                            right: 0.278001454
                        },
                        { 
                            slug: "delivery_was_great",
                            left: 0.278001454,
                            right: 0.315243527
                        },
                        { 
                            slug: "delivery_was_not_so_great",
                            left: 0.315243527,
                            right: 0.373497176
                        },
                        { 
                            slug: "total_deliveries_with_customer_feedback",
                            left: 0.373497176,
                            right: 0.439761226
                        },
                        { 
                            slug: "respectful_of_property",
                            left: 0.439761226,
                            right: 0.49595985
                        },
                        { 
                            slug: "followed_instructions",
                            left: 0.49595985,
                            right: 0.547153721
                        },
                        { 
                            slug: "friendly",
                            left: 0.547153721,
                            right: 0.580872896
                        },
                        { 
                            slug: "went_above_and_beyond",
                            left: 0.580872896,
                            right: 0.629354694
                        },
                        { 
                            slug: "delivered_with_care",
                            left: 0.629354694,
                            right: 0.682365934
                        },
                        {
                            slug: "care_for_others",
                            left: 0.682365934,
                            right: 0.729547615
                        },
                        { 
                            slug: "mishandled_package",
                            left: 0.729547615,
                            right: 0.787256053
                        },
                        { 
                            slug: "driving_unsafely",
                            left: 0.787256053,
                            right: 0.831879439
                        },
                        { 
                            slug: "driver_unprofessional",
                            left: 0.831879439,
                            right: 0.898479002
                        },
                        { 
                            slug: "not_delivered_to_preferred_location",
                            left: 0.898479002,
                            right: 0.968629425
                        },
                    ]
                }
            ]
        },
        {
            start: 200001,        
            fields: [
                {
                    key: "week",
                    instance: 1,
                    relativePos: 0,  
                    regex: "(Week) (\\d\\d?)$",   
                    regexGroup: 1,     
                    pattern: "Week",
                    transform: "patternMatches[2]", 
                }               
            ],
            tables: [
                {
                    table_pattern:[
                        "transporter_id", 
                        "driver_name",
                        "%_positive",
                        "feedback",
                        "%_negative",
                        "feedback",
                        "delivery",
                        "was",
                        "great"
                    ],
                    table_match_percent: .8,
                    table_data:[],
                    min_data: 15,
                    columns: [
                        { 
                            slug: "transporter_id",
                            left: 0,
                            right: 0.095144871
                        },
                        { 
                            slug: "driver_name",
                            left: 0.095144871,
                            right: 0.190317709
                        },
                        { 
                            slug: "%_positive_feedback",
                            left: 0.190317709,
                            right: 0.247538875
                        },
                        { 
                            slug: "$_negative_feedback",
                            left: 0.247538875,
                            right: 0.304927844
                        },
                        { 
                            slug: "delivery_was_great",
                            left: 0.304927844,
                            right: 0.345913972
                        },
                        { 
                            slug: "delivery_was_not_so_great",
                            left: 0.345913972,
                            right: 0.402631726
                        },
                        { 
                            slug: "total_deliveries_with_customer_feedback",
                            left: 0.402631726,
                            right: 0.482674236
                        },
                        { 
                            slug: "respectful_of_property",
                            left: 0.482674236,
                            right: 0.54915259
                        },
                        { 
                            slug: "followed_instructions",
                            left: 0.54915259,
                            right: 0.600332811
                        },
                        { 
                            slug: "friendly",
                            left: 0.600332811,
                            right: 0.637249692
                        },
                        { 
                            slug: "went_above_and_beyond",
                            left: 0.637249692,
                            right: 0.690275758
                        },
                        { 
                            slug: "delivered_with_care",
                            left: 0.690275758,
                            right: 0.745021815
                        },
                        { 
                            slug: "mishandled_package",
                            left: 0.745021815,
                            right: 0.80811612
                        },
                        { 
                            slug: "driving_unsafely",
                            left: 0.80811612,
                            right: 0.85711489
                        },
                        { 
                            slug: "driver_unprofessional",
                            left: 0.85711489,
                            right: 0.926781519
                        },
                        { 
                            slug: "not_delivered_to_preferred_location",
                            left: 0.926781519,
                            right: 1
                        },
                    ]
                }
            ]
        }
    ]
}