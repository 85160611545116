import { mixinMethods } from '@/main'
import {
    onCreateDeviceByGroup,
    onUpdateDeviceByGroup,
    onDeleteDeviceByGroup
} from '../../subscriptions';
import store from '@/store'
import { devicesByGroup } from '../../queries'
import { initializeSubscription, disconnectSubscription } from '../helper'

"use strict";
let subscriptionDevices = null
export const loadDevices = async function(){
    if(!store.state.userInfo?.tenant?.group) return
    const input = {
        group: store.state.userInfo.tenant.group
    }
    const devices = await mixinMethods.gLoadListAll(devicesByGroup, input, 'devicesByGroup')
    store.dispatch("subscriptionStore/setDeviceList", devices)
}

export const deviceSubscribe = function(instance){
    const queries = {
        onCreateDeviceByGroup,
        onUpdateDeviceByGroup,
        onDeleteDeviceByGroup
    }
    if(!store.state.userInfo?.tenant?.group) return
    const input = {
        group: store.state.userInfo.tenant.group
    }
    initializeSubscription(subscriptionDevices, queries, input, "subscriptionStore/mutateDevice", instance)
}

export const config = function(subscriptions){
    const subscription = {}
    subscriptionDevices = subscriptions['device'] = {
        loadList: loadDevices,
        subscribe: deviceSubscribe,
        unsubscribe: (instance) => disconnectSubscription(subscription, instance),
        callback: deviceEventCallback,
        subscription
    }
}

export const deviceEventCallback = function(subscriptionState, payload){
    //
}