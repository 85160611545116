import { valueMapping } from '@/utilities/constants/oldIssueOrKudoValues'

/**
 * The function `parseType` takes an input `infractionOrKudoType` and returns its corresponding value
 * from the `valueMapping` object, or the input itself if no corresponding value is found.
 * @param [infractionOrKudoType] - The parameter "infractionOrKudoType" is a string that represents the
 * type of infraction or kudo.
 * @returns the value of `valueMapping[infractionOrKudoType]` if it exists, otherwise it returns the
 * value of `infractionOrKudoType`.
 */
export function parseType(infractionOrKudoType=''){
    return valueMapping[infractionOrKudoType] || infractionOrKudoType;
}