<template>
  
  <div class="indicator-filter flex items-baseline">
    <el-badge class="item font-app text-sm font-normal"> <i class="el-icon-set-up"></i> 
      <span class="pl-1" :class="{'font-bold': counter > 0}">{{ counter + ' '+ pluralize(counter,'filter','filters') + ' applied' }}</span>
    </el-badge>
  </div>

</template>

<script>
export default {
  name: "CounterFilters",

  props: {
    counter: {
      type: Number,
      default: 0
    }
  }
}
</script>

<style lang="scss" scoped>
.pipe {
  color: #606266;
}

.font-app {
  color: #606266;
}

@media (max-width: 767px) {
  .pipe {
    display: none;
  }
}
</style>