import { mapState, mapGetters } from 'vuex'
import { staffsByGroupStatus } from '@/api/queries'

export default {
    data(){

    },

    computed: {
        ...mapState([
            'userInfo',
        ]),
        ...mapGetters('subscriptionStore', [
            'getAssociates',
            'getActiveAssociates',
            'getInactiveAssociates'
        ])
    },

    methods: {
        async loadAssociatesByGroupStatus(input){
            let associates = []
            try {
                associates = await this.gLoadListAll(staffsByGroupStatus, input, 'staffsByGroupStatus')
            } catch (error) {
                console.error(error)
            } finally {
                return associates
            }
        },

        /**
         * Returns a list of active, no active or all associates, each associate will have id, firstName, lastName and status
         * @param {*} status 'allActives', 'allInactives' or 'all'
         * @returns list of associates to be used in dropdowns
         */
        async getAssociateListByStatus(status){
            let input = {
                group: this.userInfo.tenant.group,
            }
            let associates = []
            const statuses = {
                'allActives': async () => {
                    input.status = { eq: "Active" }
                    associates = this.getActiveAssociates
                    if(!associates.length){
                        associates = await this.loadAssociatesByGroupStatus(input)
                    }
                },
                'allInactives': async () => {
                    input.status = { ne: "Active" }
                    associates = this.getInactiveAssociates
                    if(!associates.length){
                        associates = await this.loadAssociatesByGroupStatus(input)
                    }
                },
                'allAssociates': async () => {
                    associates = this.getAssociates
                    if(!associates.length){
                        associates = await this.loadAssociatesByGroupStatus(input)
                    }
                }
            }
            if(!statuses[status]) return []
            await statuses[status]()
            return associates.map(({ id, firstName, lastName, status }) => ({id,
                firstName, lastName, status}))
        }
    }   
}