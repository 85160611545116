<template>
  <div>
    <div class="mt-4 grid grid-cols-8 grid-flow-row gap-4">
      <div class="col-span-4 p-4 border-2 rounded-lg shadow">
        <div>
          <p class="inline-block text-gray-600 text-base font-bold">ADD NEW CARD</p>
        </div>
        <NewCard
          :loadTrigger="loadTrigger"
          :checkout="true"
          :tenant="tenant"
          @complete-order="completeOrder"/>
      </div>
       <div class="col-span-4 p-4 border-2 rounded-lg shadow relative">
        <p class="inline-block text-gray-600 text-base font-bold">SAVED CARDS</p>
        <SavedCards
          :checkout="true"
          :loadTrigger="loadTrigger"
          @complete-order="completeOrder"
          @show-order-summary="showOrderSummary"/>
       </div>
    </div>
  </div>
</template>

<script>
import { Auth, API } from 'aws-amplify';
import { createCard, updateCard } from '@/graphql/mutations'
import { cardsByGroup } from './queries'
import NewCard from './components/NewCard'
import SavedCards from './components/SavedCards'


export default {
  
  name: 'Checkout',
  props: ['loadTrigger', 'tenant'],
  components:{
    NewCard,
    SavedCards
  },
  data(){
    return{
   
    }
  },

  methods:{
    showOrderSummary(){
      this.$emit('show-order-summary')
    },

    completeOrder(){
      this.$emit('complete-order')
    }
  }
}
</script>