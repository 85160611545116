
import {
  updateOptionsCustomLists,
  deleteOptionsCustomLists,
  updateVehicle,
  updateOptionsCustomListsStaff,
  updateAccident,
  updateRoute,
  updateReplaceByRoute,
  updateRosteredVehicle,
  updateCounseling,
  updateKudo,
  updateInfraction,
  updateCustomLists
} from "@/graphql/mutations";

export default {

	data() {
		return {
      mutationList: {
        vehicles: updateVehicle,
        associates: updateOptionsCustomListsStaff,
        parkingSpace: updateVehicle,
        counselings: updateCounseling,
        kudos: updateKudo,
        issues: updateInfraction,
        routeParkingSpace: updateRoute,
        replaceByRouteParkingSpace: updateReplaceByRoute,
        accidents: updateAccident,
        companies: updateVehicle,
        routes: updateRoute,
        routeHelperStatus: updateRoute,
        replaceByRoutes: updateReplaceByRoute,
      }
		}
	},
	created() {
	},
	computed: {
	},
	methods: {

    async reAssignCustomListOptions(list) {
      if(!list) return

      await Promise.all(Object.keys(list).map(async key => {
        await Promise.all(list[key].map(async input => {
          try {
            await this.api(this.mutationList[key], { input })
          } catch (e) {
            this.printUserError(e)
            this.displayUserError(e)
          }
        }))
      }))
    },

    async updateCustomListOptions(items) {
      if(!items || !items.length) return

      await Promise.all(items.map(async item => {
        item = this.cleanOptionCustomListInput(item)
        try {
          const input = {
            ...item
          }
          await this.api(updateOptionsCustomLists, { input });
        }catch (e) {
          this.printUserError(e)
          this.displayUserError(e)
        }
      }))
    },

    async deleteCustomListOptions(items){
      if(!items || !items.length) return

      await Promise.all(items.map(async (option) => {
        try {
          const input = {
            id: option.id,
          };
          await this.api(deleteOptionsCustomLists, { input });
        } catch (e) {
          this.printUserError(e)
          this.displayUserError(e)
        }
      }));
    },

    async deleteCustomListOptionsAlert(items, optionList) {

      if(!items.length) return true
      if(items.length == optionList.length) throw new Error('You can not delete all options from this Custom List')

      let totalOptionsToDelete = items.length;

      return await this.$confirm(
          `You are about to delete ${totalOptionsToDelete} ${this.pluralize(totalOptionsToDelete, 'option', 'options')} from this Custom List. This cannot be undone.`,
          "Are you sure?",
        {
          confirmButtonText: "Yes, Save",
          cancelButtonText: "No, Cancel",
          type: "warning",
        }).then(() => {
          return true
        }).catch(() => {
          return false
        });
    },
    
    async updateCustomList(customList) {
			try {
        const input = {
          id: customList.id,
          listDisplay: customList.listDisplay
        }
        await this.api(updateCustomLists, { input });
      }catch (e) {
        this.printUserError(e)
        this.displayUserError(e)
      }
		},
	}
}
