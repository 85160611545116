import { API, graphqlOperation } from 'aws-amplify';
import { createInfraction, updateInfraction, createKudo, updateKudo } from '@/views/Performance&Coaching/Coaching/mutations'

// removes hyphens and spaces and camel cases value
function formatNetradyneType(value){
    return value.replace('-', ' ').replace('-',' ').split(' ').map(word => word[0].toUpperCase() + word.substr(1).toLowerCase()).join(' ').replace(/\s+/g, '')
}

function toTitleCase(value){
    return value.replace('-', ' ').replace('-',' ').split(' ').map(word => word.charAt(0).toUpperCase() + word.substr(1).toLowerCase()).join(' ')
}

async function createRecord(createMutation, updateMutation, input){
    try{
        await API.graphql(graphqlOperation(createMutation, {input}));
    }
    catch(e){
        try{
            await API.graphql(graphqlOperation(updateMutation, {input}));
        }catch(e){
            console.error(e)
        }
    }
}

function weeksToMilliseconds(weeks) {
    return 1000 * 60 * 60 * 24 * 7 * (weeks - 1);
}

/**
 * Sets the given date as the first day of week of the first week of year.
 */
 function firstWeekday(firstOfJanuaryDate) {
    // 0 correspond au dimanche et 6 correspond au samedi.
    var FIRST_DAY_OF_WEEK = 1; // Monday, according to iso8601
    var WEEK_LENGTH = 7; // 7 days per week
    var day = firstOfJanuaryDate.getDay();
    day = (day === 0) ? 7 : day; // make the days monday-sunday equals to 1-7 instead of 0-6
    var dayOffset=-day+FIRST_DAY_OF_WEEK; // dayOffset will correct the date in order to get a Monday
    if (WEEK_LENGTH-day+1<4) {
        // the current week has not the minimum 4 days required by iso 8601 => add one week
        dayOffset += WEEK_LENGTH;
    }
    return new Date(firstOfJanuaryDate.getTime()+dayOffset*24*60*60*1000);
}


function firstDayOfYear(date, year) {
    date.setYear(year);
    date.setDate(1);
    date.setMonth(0);
    date.setHours(0);
    date.setMinutes(0);
    date.setSeconds(0);
    date.setMilliseconds(0);
    return date;
}

function firstWeekOfYear(year) {
    var date = new Date();
    date = firstDayOfYear(date,year);
    date = firstWeekday(date);
    return date;
}

function getYearWeekDate(yearIn, weekIn, withTimeZone = false ){
    var date       = firstWeekOfYear(yearIn),
        weekTime   = weeksToMilliseconds(weekIn),
        targetTime = date.getTime() + weekTime;

    let dateFormatted = new Date( date.setTime(targetTime) + ( 1000 * 60 * 60 * 24 * 5)); 
    return (withTimeZone) ? dateFormatted.toISOString() : dateFormatted.toISOString().split('T')[0]
}

/**
 * 
 */
async function createIssuesAndKudos(scorecard, cx, mentor, tenant, netradyne){
    var staffId = null

    if(mentor) staffId = mentor.input.staffMentorStaffId
    else if(scorecard) staffId = scorecard.input.staffScoreCardStaffId
    else if(cx) staffId = cx.staffCxFeedbackStaffId
    else if(netradyne) staffId = netradyne.staff.id
    else{
        return
    }

    // ------------ CUSTOMER FEEDBACK ------------
    if(cx){
        
        let metrics = [
            { polarity: 1, name: 'PositiveFeedback', fieldName: 'positiveFeedback', type: 'CX Positive Feedback', code: 'pf'}
        ]

        metrics.forEach(metric => {
            // --- Issues ---
            if((tenant['coaching' + metric.name + 'Issue']) && 
                (
                    ( metric.polarity  && parseFloat(cx[metric.fieldName]) <  parseFloat(tenant['coaching' + metric.name + 'ThresholdIssue'])) ||
                    ( !metric.polarity && parseFloat(cx[metric.fieldName]) > parseFloat(tenant['coaching' + metric.name + 'ThresholdIssue']))
                ) 
            ){
                let input = {
                    id: cx.year + '-' + cx.week + '-cx-' + metric.code + '-' + staffId,
                    infractionStaffId: staffId, 
                    staffId: staffId,
                    date: getYearWeekDate(cx.year, cx.week),
                    infractionType: metric.type,
                    comment: 'Received ' + cx[metric.fieldName]+ ' (Created from CX import)',
                    group: tenant.group
                }
                createRecord(createInfraction, updateInfraction, input)
            }

            // --- Kudos ---
            if((tenant['coaching' + metric.name + 'Kudo']) && 
                (
                    ( metric.polarity  && parseFloat(cx[metric.fieldName]) >= parseFloat(tenant['coaching' + metric.name + 'ThresholdKudo'])) ||
                    ( !metric.polarity && parseFloat(cx[metric.fieldName]) <= parseFloat(tenant['coaching' + metric.name + 'ThresholdKudo']))
                )
            ){
                let input = {
                    id: cx.year + '-' + cx.week + '-cx-' + metric.code + '-' + staffId,
                    kudoStaffId: staffId, 
                    staffId: staffId,
                    date: getYearWeekDate(cx.year, cx.week),
                    kudoType: metric.type,
                    notes: 'Received ' + cx[metric.fieldName]+ ' (Created from CX import)',
                    group: tenant.group
                }
                createRecord(createKudo, updateKudo, input)
            }
        })
 
    }

    
    // ------------ SCORECARD ------------
    if(scorecard && scorecard.input){ 
        // load staffs scorecards based on threhsold of consecutives + 1
        let weeksIssue = parseInt(tenant.coachingConsecutiveTierThresholdIssue)
        let weeksKudo = parseInt(tenant.coachingConsecutiveTierThresholdKudo)
        let numWeeksToLoad = weeksIssue > weeksKudo ? weeksIssue : weeksKudo
        let getStaff = `
        query GetStaff($id: ID!) {
            getStaff(id: $id) {
                id
                group
                scoreCards(limit: ${numWeeksToLoad + 1}) {
                    items {
                        id
                        week
                        year
                        overallTier
                    }
                        nextToken
                }
            }
        }
        `;
        let input = {
            id: staffId
        }
        let da = await API.graphql(graphqlOperation(getStaff, input));
        da = da.data.getStaff
        
        // sort scorecards
        let scoreCardsSorted = []
        if(da.scoreCards){
            scoreCardsSorted = da.scoreCards.items.sort((a,b) => {
                return parseInt(b.year+b.week.padStart(2, 0)) - parseInt(a.year+a.week.padStart(2, 0))
            })
        }

        // calculate consecutive tiers for issues and kudos
        let numberConsecutiveKudo = 0
        let numberConsecutiveIssue = 0
        
        if(scoreCardsSorted.length){
            var accumulator = 0
            for(const scoreCard of scoreCardsSorted){
              if(scoreCard.overallTier == tenant.coachingConsecutiveTierRatingIssue) accumulator++
              else break
            }
            numberConsecutiveIssue = accumulator

            var accumulator = 0
            for(const scoreCard of scoreCardsSorted){
              if(scoreCard.overallTier == tenant.coachingConsecutiveTierRatingKudo) accumulator++
              else break
            }
            numberConsecutiveKudo = accumulator
        }

        let metrics = [
            { code: 'f', polarity: 1, name: 'Fico', fieldName: 'ficoScore', type: 'Scorecard FICO® Score'},
            { code: 'dcr', polarity: 1, name: 'Dcr', fieldName: 'dcr', type: 'Scorecard DCR'},
            { code: 'dar', polarity: 1, name: 'Dar', fieldName: 'dar', type: 'Scorecard DAR'},
            { code: 'dnr', polarity: 0, name: 'Dnr', fieldName: 'dnrs', type: 'Scorecard DNR'},
            { code: 'pod', polarity: 1, name: 'SwcPod', fieldName: 'swcPod', type: 'Scorecard SWC-POD'},
            { code: 'cc', polarity: 1, name: 'SwcCc', fieldName: 'swcCc', type: 'Scorecard SWC-CC'},
            { code: 'sc', polarity: 1, name: 'SwcSc', fieldName: 'swcSc', type: 'Scorecard SWC-SC'},
            { code: 'ad', polarity: 1, name: 'SwcAd', fieldName: 'swcAd', type: 'Scorecard SWC-AD'},
            { code: 'so', polarity: 0, name: 'SeatbeltOff', fieldName: 'seatbeltOffRate', type: 'Scorecard Seatbelt-Off Rate'},
            { code: 'se', polarity: 0, name: 'SpeedingEvent', fieldName: 'speedingEventRate', type: 'Scorecard Speeding Event Rate'},
            { code: 'hb', polarity: 0, name: 'HarshBraking', fieldName: 'harshBrakingRate', type: 'Scorecard Harsh Braking Rate'},
            { code: 'hc', polarity: 0, name: 'HarshConering', fieldName: 'harshConeringRate', type: 'Scorecard Harsh Conering Rate'},
            { code: 'dr', polarity: 0, name: 'DistractionsRate', fieldName: 'distractionsRate', type: 'Scorecard Distractions Rate'},
            { code: 'fd', polarity: 0, name: 'FollowingDistanceRate', fieldName: 'followingDistanceRate', type: 'Scorecard Following Distance Rate'},
            { code: 'ssv', polarity: 0, name: 'SignSignalViolationsRate', fieldName: 'signSignalViolationsRate', type: 'Scorecard Sign/Signal Violation Rate'},
            { code: 'ot', polarity: 0, name: 'OverallTier', fieldName: 'overallTier', type: 'Overall Tier'},
            { code: 'ct', polarity: 0, name: 'ConsecutiveTier', type: 'Consecutive Tier'},
        ]

        metrics.forEach(metric => {
            // --- Issues ---
            if((tenant['coaching' + metric.name + 'Issue']) && 
                (
                    ( metric.code === 'ot' && scorecard.input[metric.fieldName] === tenant['coaching' + metric.name + 'RatingIssue']) ||
                    ( metric.code === 'ct' && numberConsecutiveIssue >= tenant['coaching' + metric.name + 'ThresholdIssue']) ||
                    ( metric.polarity  && parseFloat(scorecard.input[metric.fieldName]) < parseFloat(tenant['coaching' + metric.name + 'ThresholdIssue'])) ||
                    ( !metric.polarity && parseFloat(scorecard.input[metric.fieldName]) > parseFloat(tenant['coaching' + metric.name + 'ThresholdIssue'])) 
                )
            ){
                let comment = 'Received ' + scorecard.input[metric.fieldName]+ ' (Created from scorecard import)'
                if(metric.code === 'ct') comment = `Received more than ${tenant.coachingConsecutiveTierThresholdIssue} consecutive ${tenant.coachingConsecutiveTierRatingIssue}${tenant.coachingConsecutiveTierThresholdIssue > 1 ? 's' : ''} on Scorecards (Created from scorecard import)`

                let input = {
                    id: scorecard.input.year + '-' + scorecard.input.week + '-s-' + metric.code + '-' + staffId,
                    infractionStaffId: staffId, 
                    staffId: staffId,
                    date: getYearWeekDate(scorecard.input.year, scorecard.input.week),
                    infractionType: metric.type,
                    comment: comment,
                    group: tenant.group
                }
                createRecord(createInfraction, updateInfraction, input)
            }

            // --- Kudos ---
            if((metric.code != 'ad' && tenant['coaching' + metric.name + 'Kudo']) && 
                (
                    ( metric.code === 'ot' && scorecard.input[metric.fieldName] === tenant['coaching' + metric.name + 'RatingKudo']) ||
                    ( metric.code === 'ct' && numberConsecutiveKudo >= tenant['coaching' + metric.name + 'ThresholdKudo']) ||
                    ( metric.polarity  && parseFloat(scorecard.input[metric.fieldName]) >= parseFloat(tenant['coaching' + metric.name + 'ThresholdKudo'])) ||
                    ( !metric.polarity && parseFloat(scorecard.input[metric.fieldName]) <= parseFloat(tenant['coaching' + metric.name + 'ThresholdKudo']))
                )
            ){
                let notes = 'Received ' + scorecard.input[metric.fieldName]+ ' (Created from scorecard import)'
                if(metric.code === 'ct') notes = `Received more than ${tenant.coachingConsecutiveTierThresholdKudo} consecutive ${tenant.coachingConsecutiveTierRatingKudo}${tenant.coachingConsecutiveTierThresholdKudo > 1 ? 's' : ''} on Scorecards (Created from scorecard import)`

                let input = {
                    id: scorecard.input.year + '-' + scorecard.input.week + '-s-' + metric.code + '-' + staffId,
                    kudoStaffId: staffId, 
                    staffId: staffId,
                    date: getYearWeekDate(scorecard.input.year, scorecard.input.week),
                    kudoType: metric.type,
                    notes: notes,
                    group: tenant.group
                }
                createRecord(createKudo, updateKudo, input)
            }
        })
       
        
    }

    //FORMATTED NEW DATE NO TZ //
    let formattedDateNoTz = new Date(date);
    formattedDateNoTz = formattedDateNoTz.toISOString().split('T')[0];


    // ------------ MENTOR ------------
    if(mentor){
        let metrics = [
            { code: 'f', polarity: 1, name: 'DailyFico', fieldName: 'fico', type: 'Mentor Daily FICO®'},
            { code: 's', polarity: 0, name: 'Seatbelt', fieldName: 'seatbelt', type: 'Mentor Seatbelt Rate'},
            { code: 'sse', polarity: 0, name: 'Sse', fieldName: 'sse', type: 'Mentor Sse'},
            { code: 'speeding', polarity: 0, name: 'Sse', fieldName: 'speeding', type: 'Mentor Sse'},
            { code: 'tr', polarity: 1, name: 'TraningRemaining', fieldName: 'trainings', type: 'Mentor Trainings Remaining'}
        ]
        let trainingsRemaining = parseFloat(mentor.input.trainingAssigned) - parseFloat(mentor.input.trainingCompleted)

        metrics.forEach(metric => {
            let compareVal = metric.code === 'tr' ? trainingsRemaining : parseFloat(mentor.input[metric.fieldName])
            let date = mentor.input.date
           
            let day = String(date.getDate()).padStart(2, '0')
            let month = String(date.getMonth() + 1).padStart(2, '0')
            let year = date.getFullYear()
            date = `${year}-${month}-${day}`

            // --- Issues ---
            if((tenant['coaching' + metric.name + 'Issue']) && 
                (
                    ( metric.polarity  && compareVal < parseFloat(tenant['coaching' + metric.name + 'ThresholdIssue'])) ||
                    ( !metric.polarity && compareVal > parseFloat(tenant['coaching' + metric.name + 'ThresholdIssue'])) ||
                    ( metric.code === 'tr' && compareVal > 0)
                )
            ){
                let input = {
                    id: date + '-m-' + metric.code + '-' + staffId,
                    infractionStaffId: staffId, 
                    staffId: staffId,
                    date: formattedDateNoTz,
                    infractionType: metric.type,
                    comment: 'Received ' + compareVal + ' (Created from Mentor import)',
                    group: tenant.group
                }
                createRecord(createInfraction, updateInfraction, input)
            }

            // --- Kudos ---
            if(metric.code != 'tr' && (tenant['coaching' + metric.name + 'Kudo']) && 
                (
                    ( metric.polarity  && compareVal >= parseFloat(tenant['coaching' + metric.name + 'ThresholdKudo'])) ||
                    ( !metric.polarity && compareVal <= parseFloat(tenant['coaching' + metric.name + 'ThresholdKudo']))
                )
            ){
                let input = {
                    id: date + '-m-' + metric.code + '-' + staffId,
                    kudoStaffId: staffId, 
                    staffId: staffId,
                    date: formattedDateNoTz,
                    kudoType: metric.type,
                    notes: 'Received ' + compareVal + ' (Created from Mentor import)',
                    group: tenant.group
                }
                createRecord(createKudo, updateKudo, input)
            }
        })
    }

    // ------------ NETRADYNE ------------
    if(netradyne){         
        let date = netradyne.alerts[0].timestamp
        date = date.split(' ')[0]

        // loop through alerts and compare to thresholds 
        var alertCounts = {}
        let driverStarOnly = true
        for(const alert of netradyne.alerts){
            let type = alert.alertType
            let severity = alert.alertSeverity
            if(type != "DRIVER-STAR") driverStarOnly = false
            if(!alertCounts[type]) alertCounts[type] = {}
            if(!alertCounts[type][severity]) alertCounts[type][severity] = 1
            else alertCounts[type][severity]++
        }

        for(const type of Object.keys(alertCounts)) {

            let fieldPrefix = 'coaching' + formatNetradyneType(type) 
            let totalAlertCount = Object.keys(alertCounts[type]).reduce((sum,key)=>sum+parseFloat(alertCounts[type][key]||0),0);
            
            if(tenant[fieldPrefix + 'Issue'] && (totalAlertCount > parseFloat(tenant[fieldPrefix + 'ThresholdIssue'])) ){
                let input = {
                    id: date + '-n-' + type + '-' + staffId,
                    infractionStaffId: staffId, 
                    staffId: staffId,
                    date: formattedDateNoTz,
                    infractionType: 'Netradyne ' + toTitleCase(type),
                    comment: 'Received ' + totalAlertCount + ' (Created from Netradyne import)',
                    group: tenant.group
                }
                createRecord(createInfraction, updateInfraction, input)
            }
            else if(type == 'DRIVER-STAR' && tenant.coachingDriverStarKudo && (totalAlertCount >= parseFloat(tenant.coachingDriverStarThresholdKudo))){
                let input = {
                    id: date + '-n-' + type + '-' + staffId,
                    kudoStaffId: staffId, 
                    staffId: staffId,
                    date: formattedDateNoTz,
                    kudoType: 'Netradyne ' + toTitleCase(type),
                    notes: 'Received ' + totalAlertCount + ' (Created from Netradyne import)',
                    group: tenant.group
                }
                createRecord(createKudo, updateKudo, input)
            }
        }
        
    }
}





export default createIssuesAndKudos;