import {
    onCreateDailyLogByGroup,
    onUpdateDailyLogByGroup,
    onDeleteDailyLogByGroup
} from '../../subscriptions';
import store from '@/store'
import {
    initializeSubscription,
    disconnectSubscription,
    updateElement,
    updateRenderKey
} from '../helper'
import EventBus from '@/eventBus'
import moment from 'moment-timezone';

"use strict";
let subscriptionDailyLog = null

export const dailyLogSubscribe = function(instance){
    const queries = {
        onCreateDailyLogByGroup,
        onUpdateDailyLogByGroup,
        onDeleteDailyLogByGroup
    }
    if(!store.state.userInfo?.tenant?.group) return
    const input = {
        group: store.state.userInfo.tenant.group
    }
    initializeSubscription(subscriptionDailyLog, queries, input, "subscriptionStore/handleDailyLog", instance)
}

export const config = function(subscriptions){
    const subscription = {}
    subscriptionDailyLog = subscriptions['dailyLog'] = {
        loadList: null,
        subscribe: dailyLogSubscribe,
        unsubscribe: (instance) => disconnectSubscription(subscription, instance),
        callback: dailyLogEventCallback,
        subscription
    }
}

const updateDailyLogs = function(eventName, dailyLog, dateLimitsInTenantTimeZone, dailyLogCache, useStartDate, betweenDateTime){
    const [ start, end ] = dateLimitsInTenantTimeZone
    const isWithin = (useStartDate? dailyLog.date === start : dailyLog.date === end) || betweenDateTime(dailyLog.date, start, end)
    if(!isWithin) return
    const keyDate = dateLimitsInTenantTimeZone.join('|')
    const matchDailyLogs = dailyLogCache[keyDate]
    if(!matchDailyLogs) return
    switch(eventName){
        case 'onCreate':{
            const exists = matchDailyLogs.some(dl => dl.id === dailyLog.id)
            if(!exists){
                matchDailyLogs.push(dailyLog)
            }
            EventBus.$emit('update-route-render', {
                target: "table-data-dailyLog",
                dailyLog
            })
            break
        }
        case 'onUpdate':{
            const matchR = matchDailyLogs.find(dl => dl.id === dailyLog.id)
            updateElement(matchR, dailyLog, true)
            EventBus.$emit('update-route-render', {
                target: "table-data-dailyLog",
                dailyLog: matchR
            })
            EventBus.$emit('update-route-render', {
                target: "drawer-dailyLog",
                dailyLog: matchR,
                dailyLogs: matchDailyLogs
            })
            break
        }
        case 'onDelete':{
            const index = matchDailyLogs.findIndex(dl => dl.id === dailyLog.id)
            if(index > -1){
                const deleted = matchDailyLogs.splice(index, 1)
            }
            EventBus.$emit('update-route-render', {
                target: "table-data-dailyLog",
                dailyLog
            })
            EventBus.$emit('update-route-render', {
                target: "drawer-dailyLog",
                dailyLog,
                markForDelete: true,
                dailyLogs: matchDailyLogs
            })
            break
        }
    }
    updateRenderKey(matchDailyLogs)
}


export const dailyLogEventCallback = function(subscriptionState, {data: dailyLog, eventName}){
    
    if(!dailyLog?.id) return
    const { getDateLimitsInTenantTimeZone, betweenDateTime } = require('@/utilities/getDate')

    const currentDate = moment(dailyLog.date.split('T')[0]).toDate()
    const previousDate = new Date(currentDate.getTime())
    previousDate.setDate(currentDate.getDate() - 1)
    
    const currentDateLimitsInTenantTimeZone = getDateLimitsInTenantTimeZone(currentDate)
    const previousDateLimitsInTenantTimeZone = getDateLimitsInTenantTimeZone(previousDate)

    updateDailyLogs(eventName, dailyLog, currentDateLimitsInTenantTimeZone, subscriptionState.dailyLogCache, true, betweenDateTime)
    updateDailyLogs(eventName, dailyLog, previousDateLimitsInTenantTimeZone, subscriptionState.dailyLogCache, false, betweenDateTime)

}