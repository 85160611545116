<template>
  <div >
    <div class="flex items-center justify-between mb-2">
      <h1>Expiring Trials</h1> 
    </div>

    <table-card>
      <template v-slot:table-filters>
          <div>
            <container-filter inTable>
              <template v-slot:title>
                <div class="text-center">
                    <span class="font-app uppercase font-title">Expiring Trials</span>
                  </div>
              </template>

            </container-filter>
          </div>
      </template>
      <template v-slot:table-searchbox>
        <search-box inTable @search-data="searchData"></search-box>
      </template>
      <custom-table
        inTable
        v-loading="loading"
        :columns="cols"
        :data="filteredData"
        emptyText="No Data"
        footerTable="total Expiring Trials"
        :totalRecords="tableCount"
        :optionsRows="optionsContextRow"
      >

      <template #companyName="row">
        <span @click="handleRowClick(row)">
          {{ row.companyName }}
        </span>
      </template>
      <template #trialExpDate="row" >
        <span @click="handleRowClick(row)">
          {{ row.trialExpDate | moment("M/D/YYYY") }}
        </span>
      </template>
      <template #status="row">
        <div class="flex" @click="handleRowClick(row)">
          <p class="font-bold rounded-full bg-opacity-10 px-3 text-xs max-w-full flex-initial bg-red-500 text-red-600" 
          v-if="row.trialExpDate < currentDateFormatted">Expired</p>
          <p class="font-bold rounded-full bg-opacity-10 px-3 text-xs max-w-full flex-initial bg-orange-600 text-orange-400" v-else>Expiring Soon</p>
        </div>
        
      </template>

      </custom-table>
     
    </table-card>

    
  <div v-if="nextToken" class="flex justify-center mt-4">
    <el-button round @click="loadData()">Load More</el-button>
  </div>


  </div>
</template>

<script>
import TableCard from "@/components/TableCard/TableCard";
import CustomTable from "@/components/TableCard/CustomTable";
import ContainerFilter from "@/components/TableCard/ContainerFilter";
import SearchBox from "@/components/TableCard/SearchBox";

import { listExpiringTrials } from './queries'

  export default {
    components: {
      TableCard,
      CustomTable,
      ContainerFilter,
      SearchBox
    },

    data() {
      return {
        loading: false,
        modalOpen: false,
        tableData: [],
        search: '',
        nextToken: null,
        limit: 100,
        cols: [
          { name: "Company Name", col: "companyName", fixed: false, width: "180", sortable: true, sortMethod: this.sortCompanyName },
          { name: "Trial Expiration Date", col: "trialExpDate", fixed: false, width: "225", sortable: true, sortMethod: this.sortTrialExpirationDate},
          { name: "Status", col: "status", fixed: false, width: "150", sortable: true, sortMethod: this.sortTrialExpirationDate },
        ],
        optionsContextRow: [],
        tableCount: 0
      }
    },
    mounted(){
      this.loadData()
    },

    computed:{
      filteredData() {
        return this.filterArray(this.tableData, this.search);
      },

      currentDateFormatted: function(){
        return this.$moment().format('YYYY-MM-DD');
      }
    },


    methods: {
      /**
       * Load data using the listTenants custom GraphQL query
       */
      async loadData(){
        this.loading = true

        //Grab one month ahead and behind to get expiration date range
        //Uses Moment.js to format dates so they can be used in query
        var currentDate = this.$moment();
        var futureMonth = this.$moment(currentDate).add(1, 'M').format('YYYY-MM-DD');
        var pastMonth = this.$moment(currentDate).subtract(1, 'M').format('YYYY-MM-DD');

        try{
          var filter = {
            trialExpDate: {between: [ pastMonth , futureMonth ]}
          }
          var input = {
            filter: filter,
            limit: this.limit,
            nextToken: this.nextToken,
          }
          this.tableData = await this.gLoadListAll(listExpiringTrials, input, 'listTenants')
          // const tenants = await this.api(listTenants, input)
          // this.nextToken = tenants.data.listTenants.nextToken
          // this.tableData = [...this.tableData, ...tenants.data.listTenants.items]
          this.tableCount = this.tableData.length

          this.loading = false

        }catch(e){
          console.error(e)
          this.loading = false
          this.displayUserError(e)
        }
        
      },
      searchData(e) {
        this.search = e;
      },

      sortCompanyName(a, b){
        //sortMethod for companyName column
        return a.companyName.toLowerCase().localeCompare(b.companyName.toLowerCase());
      },
      sortTrialExpirationDate(a, b){
        //sortMethod for trialExpirationDate column
        return a.trialExpDate?.toLowerCase().localeCompare(b.trialExpDate?.toLowerCase());
      },
      

      /**
       * On row click go to the StaffDetail route.
       * @param {object} row
       * @param {object} column
       * @param {object} event
       */
      handleRowClick(row, column, event){
        this.$router.push({name: 'SystemTenantDetail', params: { id: row.id }})    
      },
    },
  }
</script>
<style scoped>

</style>