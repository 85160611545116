<template>
    <div>
    <div class="container-table">
        <table-card>
            <template v-slot:table-filters>
                <div>
                <container-filter>
                    <template v-slot:title>
                    <div class="text-center">
                        <span class="font-app uppercase font-title px-2">Active Labels</span>
                    </div>
                    </template>
                    <template v-slot:filters>
                    <div class="container-filter flex items-baseline">
                        <MultiSelectFilter
                        :type="labelsFilter" 
                        title="Label Status" 
                        filterAllTitle="Label Statuses"
                        :selected="appliedFilters" 
                        :options="filterOptions" 
                        @update-filters="updateFilters" />                     
                    </div>
                    </template>
                </container-filter>
                </div>
            </template>

            <template v-slot:table-searchbox>
                <search-box @search-data="searchData"></search-box>
            </template>

            <custom-table
                v-loading="loading"
                header-cell-class-name="table-header-cell"
                ref="table"
                style="width: 100%"
                row-class-name="table-row"
                class="data-table"
                empty-text="No Labels"
                sort-change="appliedTo"
                :data="filterArray( tableFiltered, search, filter )"
                :columns="cols"
                :totalRecords="userLabels.length"
                emptyText="No labels"
                footerTable="Total labels"
                withTableMenu
                @click-menu="handleCommand"
                :optionsRows="optionsContextRow"
                :optionsHeader="optionsContextHeader"
            >
                <template #usedFor="row">
                    <span v-if="!row.usedFor.length">• No type selection</span>
                    <ul v-else>
                        <li v-for="item in row.usedFor" :key="item">• {{item}}</li>
                    </ul>
                </template>
                <template #appliedTo="row">
                    <ul v-for="entity in row.usedFor">
                        <li v-if="entity == `Associates` && row.associateItems.length == 0" class="text-gray-500" :key="entity">• 0 Associates</li>
                        <li v-else-if="entity == `Associates`" :key="entity">• {{formatTextAppliedTo( row.associateItems, entity )}}</li>
                        <li v-else-if="entity == `Vehicles` && row.vehicleItems.length == 0" class="text-gray-500" key="Vehicless">• 0 Vehicles</li>
                        <li v-else-if="entity == `Vehicles`" :key="entity">• {{formatTextAppliedTo( row.vehicleItems, entity )}}</li>
                    </ul>
                </template>
                <template #status="row">
                    <el-tag
                        :type="getTagClass(row.status)">
                        {{row.status ? 'Active':'Inactive'}}
                    </el-tag>
                </template>
                <template #custom-dropdown="row">
                    <div class="flex align-middle p-1 flex-row-reverse">
                        <el-dropdown
                        data-cy="three_dot_button_row"
                        class="flex-initial mt-1"
                        trigger="click"
                        @command="handleCommand">
                            <i class="context-menu uil uil-ellipsis-h"></i>
                            <el-dropdown-menu >
                            <el-dropdown-item :command="{action: 'edit', row}">Edit Label</el-dropdown-item>

                            <template v-if="row.status">
                                <el-dropdown-item 
                                :command="{action: 'view', scope: {...row, entity: item.type}}"
                                v-for="item in row.appliedTo"
                                :key="item.id">
                                    View all {{item.type}} with this Label
                                </el-dropdown-item>
                            </template>

                            <el-dropdown-item
                            :command="{action: 'delete', scope: {...row, entity:item.type} }"
                            v-for="item in row.appliedTo"
                            :key="item.id">
                                Remove this Label from all {{item.type}}
                            </el-dropdown-item>

                            <el-dropdown-item
                            :command="{action: 'changeStatus', row }">
                                {{row.status ? 'Deactivate Label':'Reactivate Label'}}
                            </el-dropdown-item>
                        </el-dropdown-menu>
                    </el-dropdown>
                </div>
            </template>
        </custom-table>
        </table-card>
    </div>

    <LabelDrawer
    v-if="visible === true"
    :openDrawer="visible"
    :openMode="typeDraw"
    :labelData="labelData"
    @cbk-visible="drawCallback"
    @cbk-finally="cbkFinally"
    >
    </LabelDrawer>
</div>
</template>

<script>
import { mapActions, mapState, mapGetters } from 'vuex'
import ContextMenuHeaderIcon from '@/components/ContextMenuHeaderIcon'
import LabelDrawer from '@/components/shared/Drawer/Labels/LabelDrawer'
import TabCard from "@/components/TableCard/TabCard";
import TableCard from "@/components/TableCard/TableCard";
import CustomTable from "@/components/TableCard/CustomTable";
import ContainerFilter from "@/components/TableCard/ContainerFilter";
import SearchBox from "@/components/TableCard/SearchBox";
import MultiSelectFilter from "@/components/MultiSelectFilter";
import CounterFilter from "@/components/CounterFilters";
import labelsMixin from "@/mixins/labelsMixin";
import store from "@/store/index";
import { updateLabel } from '@/graphql/mutations'
import { multipleDefaultFilter } from '@/components/filtersFunctions';

export default {
    components: { 
        ContextMenuHeaderIcon, 
        MultiSelectFilter,
        LabelDrawer,
        TabCard,
        TableCard,
        CustomTable,
        ContainerFilter,
        SearchBox,
        CounterFilter
    },
    mixins: [
        labelsMixin,
    ],
    data(){
        return {
            labelsFilter: "labels",
            userLabels: [],
            userSelectedOptions: [],
            tableData: [],
            tableFiltered: [],
            filter: [],
            loading: false,
            orginalLabelName: "",
            search: "",
            filterOptions: [
                "Active",
                "Inactive"
            ],
            deleteDialog: {
                visible: false,
                form: {}
            }, 
            visible: false,
            labelData: {},
            typeDraw: "create",
            cols: [
                { 
                    name: "Label Name", 
                    col: "labelName", 
                    fixed: false, 
                    width: "auto", 
                    sortable: true,  
                    sortMethod: (a, b) => this.sortByString(a.labelName, b.labelName) 
                },
                { 
                    name: "Used For", 
                    col: "usedFor", 
                    fixed: false, 
                    width: "auto", 
                    sortable: true,  
                    sortMethod: (a, b) => this.sortUsedFor(a.usedFor, b.usedFor)  
                },
                {
                    name: "Applied To", 
                    col: "appliedTo", 
                    fixed: false,
                    width: "auto",
                    sortable: true, 
                    sortMethod: (a, b) => this.sortAppliedTo(a.appliedTo, b.appliedTo) 
                },
                { 
                    name: "Status", 
                    col: "status", 
                    fixed: false, 
                    width: "auto", 
                    sortable: true, 
                    sortMethod: (a, b) => this.sortStatus(a.status, b.status) 
                },

            ],
            optionsContextHeader:[
                { label: "Create new Label", action: "add"},
            ],
            optionsContextRow: []
        }
    },
    computed:{
        ...mapState(['valuelists', 'vehicleTab']),
        ...mapGetters([
            'getActiveAssociateLabels',
            'getActiveVehicleLabels'
            ]),
        appliedFilters: {
            get(){
                const query = this.$route.query[this.labelsFilter]
                let defaultFilter = this.filterOptions
                return multipleDefaultFilter(query, defaultFilter, this.filterOptions)
            },
            set(items){}
        }
    },
    mounted(){
        this.loadData()

    },
    methods:{
        ...mapActions([
			'setVehicleListFilters',
            'setFilterAssociateList'
		]),
        async loadData(){
            this.loading = true
            try{
                await this.buildTable()
                this.filterTableByStatus()            
            }
            catch(e){
                console.error(e)
                this.displayUserError(e)
            }
            finally{
                this.loading = false
            }
            
        },
        async reloadData( reloadLabels ){
            this.loading = true
            try{
                if(reloadLabels){
                    await this.buildTable()
                }

                this.filterTableByStatus()
            } catch(e){
                console.error(e)
                this.displayUserError(e)
            } finally {
                this.loading = false
            }

        },

        async buildTable(){
            try{
                let table = []
                this.userLabels = await this.getLabels()

                if( !this.userLabels.length ) return
                
                this.userLabels.forEach( label => {
                    let usedFor = []
                    let appliedTo = []
                    let associateItems = []
                    let vehicleItems = []

                    const labelTypeList = label.typeList.items
                    const labelSubscriptions = label.items.items


                    if( labelTypeList.length ){
                        labelTypeList.forEach( type => {
                            usedFor.push( type.type.name )
                        })
                    }

                    if( labelSubscriptions.length ){
                        associateItems = labelSubscriptions.filter( subscription => subscription.staff )
                        const countStaffs = associateItems.length

                        vehicleItems = labelSubscriptions.filter( subscription => subscription.vehicle )
                        const countVehicles = vehicleItems.length
                        
                        if( countStaffs ){
                            appliedTo.push({
                                type: `${this.$t('label.associates')}`,
                                count: countStaffs
                            })
                        }

                        if( countVehicles ){
                            appliedTo.push({
                                type: "Vehicles",
                                count: countVehicles
                            })
                        }

                        /**** if more types are added to use, you have to add it manually for now. */
                        
                    }
                    table.push({
                        id: label.id,
                        labelName: label.name,
                        status: label.status,
                        usedFor,
                        appliedTo,
                        associateItems,
                        vehicleItems
                    })
                })

                this.tableData = table
                this.tableFiltered = this.tableData
            } catch(e){
                console.error(e)
                this.displayUserError(e)
            } finally {
                this.loading = false;
            }
            
        },
        
        async changeStatus( scope ){
            try{
                const input = { 
                    id: scope.id,
                    name: scope.labelName,
                    status: !scope.status
                }
                await this.api(updateLabel, {input})
            }catch(e){
                console.error(e)
                this.displayUserError(e)
            }finally{
                await store.dispatch("loadLabelTypes")
                await this.reloadData( true )
            }
        },

        async removeLabelForEntities(scope){
            try{
                let subscriptions = []
                if ( scope.entity == "Associates") subscriptions = scope.associateItems
                else if (scope.entity == "Vehicles") subscriptions = scope.vehicleItems

                for(const subs of subscriptions){
                    await this.labelsModule_deleteLabelSubscription(subs.id)
                }
                await this.reloadData( true )
            }catch(e){
                console.error(e)
                this.displayUserError(e)
            }
        },
        async getLabels(){
            let response = []
            try{
                response = await this.labelsModule_loadLabels()
            }catch(e){
                console.error(e)
                this.displayUserError(e)
            } finally {
                return response
            }

        },

        async handleCommand(payload){
            const { row, action, scope } = payload;

            const actions = {
                add: () => {
                    this.typeDraw = 'create'
                    this.visible = true
                },
                edit: () => {
                    this.typeDraw = 'update'
                    this.orginalLabelName = row.labelName
                    this.userSelectedOptions = row.usedFor

                    this.labelData = {
                        originalName: this.orginalLabelName,
                        listLabels: this.userLabels,
                        id: row.id,
                        labelName: row.labelName,
                        status: row.status,
                        usedFor: row.usedFor,
                        appliedTo: row.appliedTo,
                        associateItems: row.associateItems,
                        vehicleItems: row.associateItems
                        }
                    this.visible = true
                    
                },
                view: () => {
                    let actualFilter = [scope.labelName],
                        previousRouteName = this.$route.name;
                    if( scope.entity == 'Vehicles'){
                        let optionsVehicleLabels = this.getActiveVehicleLabels.map(vehicleLabel => vehicleLabel.name)
                        const labelFilter = actualFilter.map(item => optionsVehicleLabels.indexOf(item));

                        this.$router.push({
                        name: 'vehicle-list',
                        query: { 
                            store: false,
                            status: -1,
                            labels: labelFilter.join(','),
                            company: -1,
                            vehicleType: -1,
                            previousRouteName
                        },
                        })

                        return
                    }
                    if( scope.entity == 'Associates'){   
                        // for Associates
                        let optionsAssociateLabels = this.getActiveAssociateLabels.map(label => label.name)
                        const labelFilter = actualFilter.map(item => optionsAssociateLabels.indexOf(item));

                        this.$router.push({
                            name: "associate-list",
                            query: { 
                                store: false,
                                status: -1,
                                labels: labelFilter.join(','),
                                preferredDaysOff: -1,
                                previousRouteName
                            },
                        });
                    }
                },
                delete: async() => {
                    const message = `Are you sure you want to remove the Label "${scope.labelName}" from all ${scope.entity}? This cannot be undone.`
                    await this.$confirm(message, 'Warning', {
                        confirmButtonText: 'Yes, continue',
                        cancelButtonText: 'No, cancel',
                        type: 'warning'
                    }).then(() => {
                        this.loading= true
                        this.removeLabelForEntities(scope)
                    }).catch(() => {
                        return
                    });
                    
                },
                changeStatus: () => {
                    this.changeStatus( row )
                },               
            };
            return actions[action]();
        },

        filterTableByStatus(){
            if( this.appliedFilters.includes('Active') && this.appliedFilters.includes('Inactive') || !this.appliedFilters)
                this.tableFiltered = this.tableData

            else if( this.appliedFilters.includes('Inactive') )
                this.tableFiltered = this.tableData.filter( data => !data.status )

            else if( this.appliedFilters.includes('Active') )
                this.tableFiltered = this.tableData.filter( data => data.status )
        },

        async updateFilters( e ) {
            const { type, filters } = e;

            if (type === 'labels') {
                this.appliedFilters = filters;
                await this.reloadData( false )
            }
        },
        sortUsedFor(a = [], b = []){
            /**
             * Entities where the tag is used are sorted in ASC order.
                First the array of entities that has the label is ordered, 
                then it is compared with B to order it
            */
            const sortA = a.sort( (c,d) => c.toLowerCase().localeCompare(d.toLowerCase()))
            const sortB = b.sort( (c,d) => c.toLowerCase().localeCompare(d.toLowerCase()))
            return sortA[0].toLowerCase().localeCompare(sortB[0].toLowerCase())
        },
        sortAppliedTo(a, b){
            /**
             * adds all the entities (Vehicles and Associates) 
             * that the label has and compares it with the next one (B).
             * Order the labels by the highest number.
             */
            let totalA = 0
            let totalB = 0
            
            a.forEach( item => {
                totalA += item.count
            })

            b.forEach( item => {
                totalB += item.count
            })

            return totalA - totalB
        },

        sortByString (a, b) {    
            return a.toLowerCase().localeCompare(b.toLowerCase(), undefined, {numeric: true, sensitivity: 'base'})
        },

        sortStatus(a,b){
            return a - b
        },

        getTagClass( status ){
            return status ? 'success':'danger'
        },

        formatTextAppliedTo(item, entity){
            return `${item.length} ${item.length > 1 ? entity:entity.slice(0, -1)}`
        },

        searchData(e) {
            this.search = e;
        },
        
        drawCallback(newValue){
            this.visible = newValue 
        
        },
        async cbkFinally(){
            
            try{
                await this.reloadData( true ) 
            }catch(e){
                console.error(e)
                this.displayUserError(e)
            }
        },
    }
}
</script>

<style lang="scss">

.report-vehicle-maintenance {

.filter-btn {
    width: 100% !important;
    height: 32.5px;
    border-left: 0;
    padding: 0px 12px 0px 12px;
    border-radius: 0 .5rem .5rem 0 !important;
    box-shadow: none !important;
}

.el-table .caret-wrapper {
    left: -7px !important;
}
.export-dialog-style .el-dialog__title{
    font-size: 17px !important;
    margin-left: -7px !important;
    margin-right: 7px !important;
}



.el-table th > .cell {
    padding: 0 17px;
}
}

.el-picker-panel *[slot=sidebar], .el-picker-panel__sidebar {
width: 120px;
}

.header-personalized {
display: flex;
flex-direction: row;
justify-content: space-between;
align-items: center;

.container-filter,
.maintenance-filter {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}
}

.font-app {
font-weight: 400;
color: #606266;
font-size: 14px;
}

.font-title {
font-weight: 700;
}

.input-filter .el-input__inner {
border: none;
}

.container-table {
border-radius: 0.5rem;
border: 1px solid rgba(226, 232, 240, 1);
}

.maintenance-table {
border: none;
border-radius: none;
}

.header-personalized {
border-bottom:1px solid rgba(226, 232, 240, 1);
}

.icon-more {
border-radius: 50% !important;
border: 1px solid transparent;
box-shadow: none !important;
}

.badge-filter {
border-radius: .5rem;
}

.filter-title {
color: #606266;
font-size: 14px;
}

.active-date {
color: #0268BC;
font-weight: 500;
}

.fix-height {
height: 25px;
}

.select-custom {
text-decoration: underline;
color: #0268BC;
font-weight: 500;
}

.unselect-custom {
text-decoration: none;
color: inherit;
font-weight: normal;
}

.font-bold {
font-weight: 500;
}

.ml-14 {
margin-left: 14px;
}

</style>