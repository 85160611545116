import { API,graphqlOperation } from 'aws-amplify'
import { updateShortenUrl } from './shared/mutation'
import { shortenUrlsByShortenId } from './shared/queries'

export async function ShortenIdMiddleware(to, from, next) {

  const shortenId = to.params.shortenId;

  const apiName = 'heraPublicApi'
  const path = '/u/shorten-url/' + shortenId
  const result = await API.get(apiName, path)
  const type = result.type
  const id = result.id

  if(type === 'ATTACHMENT' && id) next({ name: 'RedirectMessageAttachment', params: { attachmentId:id } })
  if(type === 'COUNSELING' && id) next({ name: 'RedirectCounselingSignature', params: { counselingId:id } })
  if(type === 'DAILYLOG' && id) next({ name: 'VehicleDailyLogPublic', params: { token:id } })
  if(type === 'ERROR') next({ name: 'Error404', params: { type:'shorten-url' } })

}

export async function ValidateAndUpdateStatusShortenUrl(shortenId) {

    try {

      const {data} = await API.graphql(graphqlOperation(shortenUrlsByShortenId, { shortenId }))
      const items = data.shortenUrlsByShortenId.items;

      if(!items || !items.length) throw 'The short url does not exist!'
      
      const isOpen = items[0].isOpenLink
  
      if(!isOpen){
  
        const input = {
          id: items[0].id,
          isOpenLink: true
        }
  
        await API.graphql(graphqlOperation(updateShortenUrl, { input }));
  
      }

    } catch (error) {
      console.error(error)
    }

}