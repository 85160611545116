<template>
  <div data-cy="users-container">
	
  <table-card tablecard-class="rounded-lg">
	  <template v-slot:table-filters>
		<container-filter inTable>
		  <template v-slot:title>
			<span class="uppercase">USERS</span>
		  </template>
		  <template v-slot:filter-count>
			<counter-filter :counter="activeFilters.length === filterLogin.length ? 0 : 1" />
		  </template>
		  <template v-slot:filters>
			<MultiSelectFilter
			  type="heraLogin"
			  title="Hera Login"
			  filterAllTitle="Hera Logins"
			  :options="filterLogin"
			  :selected="filterLoginDefault"
			  @update-filters="updateFilters"
			/>            
		  </template>
		</container-filter>
	  </template>

	  <template v-slot:table-searchbox>
		  <search-box data-cy="filter" inTable @search-data="searchData"></search-box>
	  </template>

	  <custom-table 
		v-if="dataReady"
		:columns="cols"
		:data="tableData"
		:totalRecords="users.length"
		emptyText="No Users"
		footerTable="Users"
		withTableMenu
		@click-menu="handleCommand"		
		:optionsRows="optionsContextRow"
		:optionsHeader="optionsContextHeader"
		inTable
	  >
		  <template #lastLogin="row">
        <div class="flex" v-if="row.lastLogin === 'NOT_YET_LOGGED'">
          <span class="font-bold rounded-full bg-yellow-500 bg-opacity-10 px-3 text-xs  max-w-full flex-initial text-yellow-600">Hasn't logged in</span>
        </div>        
			  <template v-else-if="$store.getters.isSystemAdmin && !hasLogguedSupportAdministrator">
				<span>{{ row.lastLogin && row.lastLogin.includes('1970') ? "" : formatDate(row.lastLogin, 'YYYY-MM-DD H:mm') }}</span>
			  </template>
        <template v-else>
			<span>{{ formatDate(row.lastLogin, 'YYYY-MM-DD H:mm') }}</span>
			  </template>
      </template>
      <template #permissions="row">
        <div class="text-xs leading-5">
          <div v-if="row.permissionLogin" class="inline-block">
            <span class="font-bold rounded-full bg-opacity-10 py-1 px-3 text-xs max-w-full flex-initial text-green-600 bg-green-500">Can login</span>
          </div>
          <div v-else class="flex">
            <span class="font-bold rounded-full bg-opacity-10 px-3 text-xs max-w-full flex-initial text-red-600 bg-red-500">Can't login</span>
          </div>
          <el-tooltip v-if="row.permissionLogin && !row.permissionFullAccess" class="relative ml-1 item" effect="dark" placement="top" :content="displayPermissions(row)" popper-class="custom-tooltip-higher-z-index">
				    <i class="uil uil-info-circle text-xl"></i>
          </el-tooltip>
          <span v-if="row.isOwner">
            <i class="text-blue-700 text-2xl uil-user-check"></i>
          </span>
          <span v-else></span>
        </div>
      </template>      
	  </custom-table>
	</table-card>

	<!--NEW USER MODAL-->
	<el-dialog
	  title="Add User"
	  :visible.sync="userModalOpen"
	  width="50%"
	  :close-on-click-modal="false"
	  custom-class="responsive-dialog"
	  >
	  <UserForm :formFields="newUserData" ref="newUserForm"  v-loading="loading"/>

	  <span slot="footer" class="dialog-footer">
		<el-button @click="userModalOpen = false" :disabled="loading" >Cancel</el-button>
		<el-button type="primary" @click="createUser" :disabled="loading" >Create</el-button>
	  </span>
    </el-dialog>

    <!--EDIT USER MODAL-->
    <el-dialog
      title="Edit User"
      :visible.sync="editUserModalOpen"
      width="50%"
      :close-on-click-modal="false"
      >

      <UserForm :formFields="editUserData" ref="editUserForm" v-loading="loading"/>

      <span slot="footer" class="dialog-footer">
      <el-button v-if="!USER_PERMISSIONS" class="float-left" type="danger" @click="confirmDeleteUser" :disabled="loading">Delete</el-button>
      <el-button 
        class="float-left" 
        @click="resetResendPassword" 
        :disabled="loading" 
        v-if="$store.getters.isSystemAdmin && !USER_PERMISSIONS">Resend Password
      </el-button>
      <el-button @click="editUserModalOpen = false" :disabled="loading" >Cancel</el-button>
      <el-button type="primary" @click="updateUser" :disabled="loading" >Update</el-button>
      </span>
    </el-dialog>
  </div>
  
</template>

<script>

import TabCard from "@/components/TableCard/TabCard";
import TableCard from "@/components/TableCard/TableCard";
import CustomTable from "@/components/TableCard/CustomTable";
import ContainerFilter from "@/components/TableCard/ContainerFilter";
import SearchBox from "@/components/TableCard/SearchBox";
import CounterFilter from "@/components/CounterFilters";
import MultiSelectFilter from "@/components/MultiSelectFilter";

import UserForm from './UserForm'
import { createUser, updateUser, deleteUser } from '@/graphql/mutations'
import { getUserUpdatedTimestamp } from '@/utilities/timestampQueries'
import { phoneNumberMask } from '@/utilities/phoneNumberFormat'
import { updateZohoAccountTenant, updateZohoAccountUser } from '../../../../components/Zoho/zoho';
import Validator from '@/utilities/FormValidator'
import { Auth, API } from 'aws-amplify';
import { mapState, mapGetters, mapMutations } from 'vuex'
import ContextMenuHeaderIcon from '@/components/ContextMenuHeaderIcon'
import { createNotification } from '@/graphql/mutations'
import { getTenant } from '../query'
import moment from 'moment-timezone';

import userTableData from './userTableData'
import { multipleDefaultFilter } from "@/components/filtersFunctions";

  export default {
	components: { 
	  UserForm, 
	  ContextMenuHeaderIcon,
	  TableCard,
	  CounterFilter,
	  MultiSelectFilter,
	  CustomTable,
	  ContainerFilter,
	  SearchBox,
	  TabCard,
	},
	props: ['users', 'tenantId', 'group'],
	data() {
	  return {
		allUsers: this.users,
		filterLogin: ['Can log into Hera', 'Cannot log into Hera'],
		activeFilters: [],
		cols: userTableData(this).cols,
		optionsContextHeader: userTableData(this).optionsContextHeader,
		optionsContextRow: userTableData(this).optionsContextRow,		
		userModalOpen: false,
		search: '',
		loading: false,
		dataReady: true,
		newUserData:{},
		editPrevRole: '',
		editPrevEmail: '',
		editUserModalOpen: false,
		editUserData:{},
		editUserDataPrev:{},
		USER_PERMISSIONS: process.env.VUE_APP_USER_PERMISSIONS === "1"
	  }
	},
	mounted(){
		this.activeFilters = this.filterLoginDefault
	},
	computed: {
		filterLoginDefault: {
			get(){
				const type = "heraLogin"
				let defaultFilter = ['Can log into Hera']
				const query = this.$route.query[type]

				return multipleDefaultFilter(query, defaultFilter, this.filterLogin)				
			},
			set(items){}
		},
		tableData() {
			let filteredUsers = this.filterUsers()
			return this.users
				.filter(item => !item.email.includes('sysadmin'))
				.filter(item => {
					if (this.activeFilters.length > 1) return true
					if (this.activeFilters.includes('Can log into Hera')) return item.permissionLogin
					if (this.activeFilters.includes('Cannot log into Hera')) return !item.permissionLogin
				})
				.filter(item => filteredUsers.some(filteredItem => filteredItem.id == item.id))
				.map(function (user) {
					user.phone = phoneNumberMask(user.phone)
					return user
				})
				.sort((a, b) => b.permissionLogin - a.permissionLogin)
		},
		...mapGetters(['hasLogguedSupportAdministrator','isSystemAdmin']),
	},

	methods: {
    
		updateFilters(e) {
		  const { filters } = e;
		  this.activeFilters = filters
		},
		getResumeFilters() {   
		  return this.filterLogin.length > 1 || this.filterLogin.length === 0
			? `${this.filterLogin.length} Filters Applied`
			: `${this.filterLogin.length} Filter Applied`;
		},
		searchData(e){
		  this.search = e
		},
		sortByString (a, b) {          
		  return a.toLowerCase().localeCompare(b.toLowerCase(), undefined, {numeric: true, sensitivity: 'base'})
		},
    sortP(a,b){ return a.permissionLogin - b.permissionLogin },
    sortFirstName(a,b) { return this.sortByString(a.firstName, b.firstName) },
		sortLastName(a,b) { return this.sortByString(a.lastName, b.lastName) },
		


		formatDate(date, parse) {
		  return moment(date).format(parse)
		},

		...mapMutations([
			'setSessionInfo', 'setUserInfo'
		]),  

		handleCommand(payload){
				var row = payload.row
				var action = payload.action
				console.log('---Payload---', JSON.stringify(payload))

				if(action == "edit"){
					this.openEditUserModal(row)
					return
				}
				else if(action == 'add'){
					this.openNewUserModal()
				}
				else if(action == 'delete'){
					this.editUserData = row
					this.confirmDeleteUser()
				}
				else if(action == 'deactivate') {
				  this.editUserData = row
				  this.deactivateUser(row)
				}
				else if(action == 'reset-password') {
					 this.editUserData = row
					 this.resetUserPassword()
				}
				else if(action == 'resend-password'){
					this.editUserData = row
					this.resetResendPassword()
				}
				else if(action == 'markAsOwner') {
				  this.editUserData = row
				  this.markAsOwner()
				}
			},

			displayPermissions(row){
				let list = [
					row.permissionLogin ? 'Login' : null, 
					row.permissionFullAccess ? 'Full Access' : null, 
					row.permissionDocuments ? 'DA Documents' : null, 
					row.permissionCounselings ? 'Company Settings: Manage Counseling Templates & Settings' : null, 
					row.permissionCustomLists ? 'Company Settings: Custom Lists' : null, 
					row.permissionManageLabels ? 'Company Settings: Manage Labels' : null, 
					row.permissionAccidents ? 'Accident Records' : null, 
					row.permissionInjuries ? 'Injury Records' : null, 
					row.permissionDrugTests ? 'Drug Tests' : null,
					(row.permissionDailyRostering ?? true) ? 'Company Settings: Manage Daily Rostering Settings' : null,
					(row.permissionMessenger ?? true) ? 'Messenger' : null,
					(row.permissionMessageTemplate ?? true) ? 'Message Template' : null,
					(row.permissionVehiclePhotoLogs ?? true) ? 'Company Settings: Manage Vehicle Photo Log Settings' : null,
				].filter(Boolean).join(", ")
				return list
			},

	  /** 
	   * Reset Password cognito user
	  * */
		
	  async resetUserPassword() {
		this.loading = true
		let lowerEmail = this.editUserData.email.toLowerCase()
		let owner = this.editUserData.cognitoSub
		let passWordResetResponse  = null
		let signOutResponse = null
		try {
			passWordResetResponse = await this.passwordResetCognitoUser(lowerEmail)
			signOutResponse = await this.singOutCognitoUser(lowerEmail)
			let notificationInput = {
			  title: 'DEACTIVATE USER',
			  description: 'User password was reset.',
			  isReadS: "false",
			  clickAction: 'LOGOUT',
			  owner: owner,
			  group: (this.isSystemAdmin) ? 'system_admin' : this.group,
				expirationTTL: Math.floor((new Date().getTime() + 90 * 24 * 60 * 60 * 1000) / 1000)        
			}
			await this.api(createNotification, {input: notificationInput} )

			this.displayUserNotification({
				type: 'success',
				message: 'Password Reset',
				title: 'Success'
			})
		} catch (e) {
			if(!passWordResetResponse) {
			  e.message = "Could not send a code"
			} else if(passWordResetResponse?.message?.includes('Success reseting password') && !signOutResponse){
			  e.message = "Could not sign user out"
			}
			this.displayUserError(e)
		} finally {
			this.loading = false
		}
	  },

	  async passwordResetCognitoUser(username){
		  let apiName = 'AdminQueries';
		  let path = '/resetPassword';
		  let post = {
			  body: {
				"username": username
			  }, 
			  headers: {
				'Content-Type' : 'application/json',
				Authorization: `${(await Auth.currentSession()).getAccessToken().getJwtToken()}`
			  } 
		  }

		  var result = await API.post(apiName, path, post);
		  return result
	  },

	  async singOutCognitoUser(username){
		  let apiName = 'AdminQueries';
		  let path = '/signUserOut';
		  let post = {
			  body: {
				"username": username
			  }, 
			  headers: {
				'Content-Type' : 'application/json',
				Authorization: `${(await Auth.currentSession()).getAccessToken().getJwtToken()}`
			  } 
		  }

		  var result = await API.post(apiName, path, post);
		  return result
	  },

	  async resetResendPassword(){
		  this.loading = true
		// resend cognito user
		let lowerEmail = this.editUserData.email.toLowerCase()
		let userId = this.editUserData.id
		try{
		  var cognitoUser = await this.createCognitoUser(lowerEmail, userId, true)
		  this.displayUserNotification({
			type: 'success',
			message: 'Password Resent',
			title: 'Success'
		  });
		}catch(e){
			if(e?.response?.data?.message.includes('Resend not possible')){
				e.response = 'Resend not possible. User has already changed their temporary password.'
			}
		  console.error(e.response)
		  this.displayUserError(e.response)
		}
		finally{
			this.loading = false
		}
	  },

	  /** 
	   * Create creates cognito user, adds user to tenant
	   * group, adds user to DB */
	  async createUser(){
		this.loading = true
		// validate form
		try {
		  this.$refs.newUserForm.$refs.form.validate((valid) => {
			if (!valid) {
			  throw{
				  errors: [
					{ message: "Please fill out all required fields."}
				  ]
			  }
			}
		  })
		}
		catch(e){
		  console.error(e)
		  this.loading = false
		  this.displayUserError(e)
		  return
		}

		// check for existing user
		try{
		  var formattedEmail = this.cleanEmail(this.newUserData.email)
		  var cognitoUser = await this.getCognitoUser(formattedEmail)
		  if(cognitoUser.statusCode && cognitoUser.statusCode != 200 && cognitoUser.code != 'UserNotFoundException' ){
			var error = {
			  code: cognitoUser.code,
			  message: cognitoUser.message
			}
			throw(error)
		  }
		  var enabled = cognitoUser.Enabled
		  if(enabled){
			throw{
			  errors: [
				{ message: "User already exists with provided email"}
				  ]
			  }
		  }
		}
		catch(e) {
		  console.error(e)
		  
		  // ignore user doesn't exist error in response
		  if(e.errors){
			this.displayUserError(e)
			this.loading = false
			return // only return if user exists
		  }       
		}
		
		try{
		  // Create DB record
		  var input = {
			...this.newUserData,
			userTenantId: this.tenantId,
			group: [
			  this.group,
			  'system_admin'
			]
		  }          
		  input.email = formattedEmail
		  var result = await this.api(createUser, {input: input})

		  var userId = result.data.createUser.id


		  try{
			// create cognito user
			cognitoUser = await this.createCognitoUser(formattedEmail, userId)
  
			// Add user to group
			var addToGroup = await this.addUserToCognitoGroup(formattedEmail, this.group)
			if(this.newUserData.role.toLowerCase() == 'admin' && !this.USER_PERMISSIONS){
			  addToGroup = await this.addUserToCognitoGroup(formattedEmail, 'admin')
			}
  
			// get cognito user's sub
			cognitoUser = await this.getCognitoUser(formattedEmail)
			if(cognitoUser.statusCode && cognitoUser.statusCode != 200){
			  var error = {
				code: cognitoUser.code,
				message: cognitoUser.message
			  }
			  throw(error)
			}
			var sub = cognitoUser.UserAttributes.find((attr) => {
			  return attr.Name == "sub"
			}).Value
		  }
		  catch(e){
			if(userId){
			  var result = await this.api(deleteUser, {input: {id: userId}})  
			}
			console.error(e)
			this.loading = false
			this.displayUserNotification({
			  title: "Error",
			  type: "error",
			  message: e
			})
			return
		  }

		  // update db record with cognito sub
		  input = {
			id: userId,
			cognitoSub: sub,
			group: [
			  this.group,
			  'system_admin'
			]
		  }    
		  result = await this.api(updateUser, {input: input})

		  //Clean up app state
		  this.userModalOpen = false
		  this.$emit('loadData')
		  this.$message({
			type: 'success',
			message: 'User Created'
		  });
		  this.loading = false
		} 
		catch(e) {
		  console.error(e)
		  this.loading = false
		  this.displayUserError(e)        
		}
	  },


	  /** 
	   * Create creates cognito user, adds user to tenant
	   * group, adds user to DB */
	  async updateUserAttributes(username, attributes){
		let apiName = 'AdminQueries';
		let path = '/updateUser';
		let post = {
			body: {
			  "username": username,
			  "attributes": attributes 
			}, 
			headers: {
			  'Content-Type' : 'application/json',
			  Authorization: `${(await Auth.currentSession()).getAccessToken().getJwtToken()}`
			} 
		}
		var result = await API.post(apiName, path, post);
		return result
	  },


	  /** 
	   * Creates a new cognito user
	   * @param {string} username 
	   * */
	  async createCognitoUser(username, userId, resend = false){
		let apiName = 'AdminQueries';
		let path = '/createUser';
		let post = {
			body: {
			  "username": username,
			  "userId": userId,
			  "resend": resend,
			  "tenantName": this.$store.state.userInfo.tenant.companyName
			}, 
			headers: {
			  'Content-Type' : 'application/json',
			  Authorization: `${(await Auth.currentSession()).getAccessToken().getJwtToken()}`
			} 
		}
		var result = await API.post(apiName, path, post);
		return result
	  },

	  /** 
	   * Disables a cognito user
	   * @param {string} username 
	   * */
	  async disableCognitoUser(username){
		let apiName = 'AdminQueries';
		let path = '/disableUser';
		let post = {
			body: {
			  "username": username,
			}, 
			headers: {
			  'Content-Type' : 'application/json',
			  Authorization: `${(await Auth.currentSession()).getAccessToken().getJwtToken()}`
			} 
		}
		var result = await API.post(apiName, path, post);
		return result
	  },

	  /** 
	   * Deletes a cognito user
	   * @param {string} username 
	   * */
	  async deleteCognitoUser(username){
		let apiName = 'AdminQueries';
		let path = '/deleteUser';
		let post = {
			body: {
			  "username": username,
			}, 
			headers: {
			  'Content-Type' : 'application/json',
			  Authorization: `${(await Auth.currentSession()).getAccessToken().getJwtToken()}`
			} 
		}
		var result = await API.post(apiName, path, post);
		return result
	  },

	   /** 
	   * Gets a single cognito user
	   * @param {string} username 
	   * */
	  async getCognitoUser(username){
		let apiName = 'AdminQueries';
		let path = '/getUser';
		let myInit = {
			queryStringParameters: {
			  "username": username,
			}, 
			headers: {
			  'Content-Type' : 'application/json',
			  Authorization: `${(await Auth.currentSession()).getAccessToken().getJwtToken()}`
			} 
		}
		var result = await API.get(apiName, path, myInit);
		return result
	  },

	  openNewUserModal(){
		this.newUserData = {
		  permissionLogin: true,
		  role: 'Admin',
		  permissionLogin: true,
		  permissionFullAccess: true,
		  permissionDocuments: true,
		  permissionCounselings: true,
		  permissionAccidents: true,
		  permissionInjuries: true,
		  permissionDrugTests: true,
		  receiveSmsTaskReminders: true,
		  receiveEmailTaskReminders: true,
		  receiveSmsTaskAssignments: true,
		  receiveEmailTaskAssignments: true
		}
		if(this.USER_PERMISSIONS){
			this.$router.push({name: 'SettingsUserDetail', params:{tenantId: this.tenantId, group: this.group}})
		}
		else{
			this.userModalOpen = true
		}
	  },


	  /**
	   * Opens the edit user modal
	   * @param {object} user - Complete user object used for UserForm
	   */
	  openEditUserModal(user){
		this.editUserData = {...user}
		this.editUserDataPrev = {...user}
		this.editPrevRole = user.role
		this.editPrevEmail = user.email

		if(this.USER_PERMISSIONS){
			this.$router.push({name: 'SettingsUserDetail', params:{id: user.id, tenantId: this.tenantId, group: this.group}})
		}
		else{
			this.editUserModalOpen = true
		}

	  },

	  async markAsOwner() {
		this.loading = true
		try {
		  let owners = this.users.filter(user => user.isOwner);
		  let _index = 0;
		  while(_index < owners.length )
			{
			  owners[_index].isOwner = false
			  owners[_index].phone = owners[_index].phone === "" ? null : owners[_index].phone
			  var inputCurrentOwner = {
				...owners[_index],
				group: [
				  this.group,
				  'system_admin'
				]
			  }
			  inputCurrentOwner = this.cleanUserInput(inputCurrentOwner)
			  await this.api(updateUser, {input: inputCurrentOwner})
			  _index++
			}
		  
		    this.editUserData.isOwner = true
		    if(this.editUserData.phone === "")
		    {
			this.editUserData.phone = null
		    }		  
		    var newOwnerInput = {
			...this.editUserData,
			group: [
			  this.group,
			  'system_admin'
			]
		  }
		  newOwnerInput = this.cleanUserInput(newOwnerInput)
		  const result = await this.api(updateUser, {input: newOwnerInput})
		  if(!this.$store.getters.isZohoCrmDisabled && result?.data?.updateUser?.zohoCrmContactRecordId && result?.data?.updateUser?.tenant?.zohoCrmAccountRecordId) {
			if (result?.data?.updateUser?.isOwner) {
                let _payloadTenant = {
                    id: result.data.updateUser.tenant.zohoCrmAccountRecordId,
                    DSP_Owner: {
                        id: result.data.updateUser.zohoCrmContactRecordId,
                    }
                }
                try {
                    await updateZohoAccountTenant('update', _payloadTenant, this.displayUserError)
                } 
                catch (error) {
                    console.log('error updating zoho account', error)
                }
            }
		  }
		  this.loading = false 
		} catch (e) {
		  console.error(e)
		  this.loading = false
		  this.displayUserError(e)  
		}
	  },
		

	  /** Update a users information */
	  async updateUser(){
		this.loading = true
		try {

		  // validate form
		  this.$refs.editUserForm.$refs.form.validate((valid) => {
			if (!valid) {
			  throw{
				  errors: [
					{ message: "Please fill out all required fields."}
				  ]
			  }
			}
		  })

		  //check timestamp
		  var timestampResult = await this.api(getUserUpdatedTimestamp, {id: this.editUserData.id})
		  if(!timestampResult.data.getUser){
			throw "User is missing or deleted by another user."
		  }
		  var timestamp = timestampResult.data.getUser.updatedAt

		  if(timestamp != this.editUserData.updatedAt){
			throw "Record was updated by another user. Please copy any changes and refresh the page."
		  }

		  var formattedEmail = this.cleanEmail(this.editUserData.email)

		  // add user from cognito admin group
		  if(this.editUserData.role.toLowerCase() == 'admin' && (this.editPrevRole.toLowerCase() != 'admin')){
			var addToGroup = await this.addUserToCognitoGroup(this.editPrevEmail.toLowerCase(), 'admin')
		  }
		  // remove user from cognito admin group
		  else if(this.editUserData.role.toLowerCase() != 'admin' && (this.editPrevRole.toLowerCase() == 'admin')){
			var removeFromGroup = await this.removeUserFromCognitoGroup(this.editPrevEmail.toLowerCase(), 'admin')
		  }

		  // edit email in cognito 
		  var attributes = [
			{
			  Name: 'email',
			  Value: formattedEmail
			},
			{
			  Name: 'email_verified',
			  Value: 'true'
			},
			{
                Name: 'custom:tenant_name',
                Value: this.$store.state.userInfo.tenant.companyName
            }
		  ]
		  this.updateUserAttributes(this.editPrevEmail.toLowerCase(), attributes)

		  var input = {
			...this.editUserData,
			group: [
			  this.group,
			  'system_admin'
			]
		  }
		  
		  input.email = formattedEmail
		  const result = await this.api(updateUser, {input: input}, getUserUpdatedTimestamp, 'getUser')
		  this.editUserModalOpen = false
		  this.$emit('loadData')
		  this.displayUserNotification({
			type: 'success',
			title: 'Success',
			message: 'User Updated'
		  });
		  this.loading = false
	
		} catch(e) {
		  console.error(e)
		  this.loading = false
		  this.displayUserError(e)         
		}
	  },


	  /** Prompt if user should really be deleted*/
	  async confirmDeleteUser(){
		this.$confirm('This will permanently delete the user. Continue?', 'Warning', {
		  confirmButtonText: 'Delete',
		  cancelButtonText: 'Cancel',
		  type: 'warning'
		}).then(() => {
		  this.commitDeleteUser()          
		}).catch(() => {
		  console.error("canceled delete")         
		});
	  },

	  async deactivateUser(row){
		this.loading = true
		try {
		  var input = {
			...this.editUserData,
			group: [
			  this.group
			]
		  }
      console.log('deactivate user', input)
		  
		  this.$confirm('You are about to update the account access or permissions for another user. If they are currently logged in, they will be logged out to apply the changes. Continue?', 'Warning', {
			confirmButtonText: 'Update',
			cancelButtonText: 'Cancel',
			type: 'warning'
		  }).then(async () => {
			if(input.permissionLogin) {
			  input.permissionLogin = false
        // also set all other permissions to false
        input.permissionFullAccess = false
        input.permissionDocuments = false
        input.permissionCounselings = false
        input.permissionAccidents = false
        input.permissionInjuries = false
        input.permissionDrugTests = false
        input.permissionDailyRostering = false
        input.permissionMessenger = false
        input.permissionPerformanceCoaching = false
        input.permissionDAManagement = false
        input.permissionCustomLists = false
        input.permissionManageLabels = false
        input.permissionMessageTemplate = false
		input.permissionVehiclePhotoLogs = false

			  let lowerEmail = this.editUserData.email.toLowerCase()
			  await this.singOutCognitoUser(lowerEmail)
			} else {
			  input.permissionLogin = true
			}
			input = this.cleanUserInput(input)
      if(!input.phone) delete input.phone
			const result = await this.api(updateUser, {input: input})
			// disable zoho contact  
			if(!this.$store.getters.isZohoCrmDisabled && result.data.updateUser && result.data.updateUser.zohoCrmContactRecordId){
				const contactStatus = input.permissionLogin ? "Active" : "Inactive"
				await this.disableZohoContact(result.data.updateUser, contactStatus)
			}
			this.$emit('loadData')
			this.displayUserNotification({
			  type: 'success',
			  title: 'Success',
			  message: 'User Updated'
			});
			let notificationInput = {
			  title: "DEACTIVATE USER",
			  description: 'User has been deactivated.',
			  isReadS: "false",
			  clickAction: 'LOGOUT',
			  owner: row.cognitoSub,
			  group: 'system_admin',
				expirationTTL: Math.floor((new Date().getTime() + 90 * 24 * 60 * 60 * 1000) / 1000)                
			}
			await this.api(createNotification, {input: notificationInput} )
			this.loading = false
		  }).catch((e) => {
			console.error("canceled update")         
			return
		  });    
		} catch(e) {
		  console.error(e)
		  this.loading = false
		  this.displayUserError(e)         
		}
	  },


	  /** Delete user from DB */
	  async commitDeleteUser( ){
		this.loading = true
		try{
		  //check timestamp
		  var timestampResult = await this.api(getUserUpdatedTimestamp, {id: this.editUserData.id})
		  if(!timestampResult.data.getUser){
			throw "User is missing or deleted by another user."
		  }
		  var timestamp = timestampResult.data.getUser.updatedAt

		  if(timestamp != this.editUserData.updatedAt){
			throw "Record was updated by another user. Please copy any changes and refresh the page."
		  }

		  // get cognito user information
		  var formattedEmail = this.cleanEmail(this.editUserData.email)
		  var cognitoUser = await this.getCognitoUser(formattedEmail)

		  //throw error if cognito returns one that isn't UserNotFoundException
		  if(cognitoUser.statusCode && cognitoUser.statusCode != 200 && cognitoUser.code != 'UserNotFoundException' ){
			var error = {
			  code: cognitoUser.code,
			  message: cognitoUser.message
			}
			  throw(error)
		  }
		  //delete DyanmoDB record if recieved UserNotFoundException
		  else if(cognitoUser.code == 'UserNotFoundException'){
			try{
			  // delete from dynamo
			  const result = await this.api(deleteUser, {input:{ id: this.editUserData.id }} )

			  // clean up app state
			  this.editUserData = {}
			  this.editUserModalOpen = false
			  this.$emit('loadData')
			  this.displayUserNotification({
				type: 'success',
				title: 'Success',
				message: 'User Deleted'
			  });
			  this.loading = false
			  return
			}
			catch(e){
			  console.error(e);
			  this.displayUserNotification({
				title: "Error",
				type: "error",
				message: e.message
			  });
			  this.loading = false;
			  return
			}
		  }

		  //don't let use delete themselves
		  var cognitoUserSub = cognitoUser.UserAttributes.find((attr) => {
			return attr.Name == "sub"
		  }).Value

		  if(cognitoUserSub == this.$store.state.userInfo.cognitoSub){
			throw{
				  message: "Cannot delete your own user account." 
			  }
		  }

		  // delete from dynamo
		  const result = await this.api(deleteUser, {input:{ id: this.editUserData.id }} )
		  
		  // delete user in cognito
		  this.deleteCognitoUser(this.editUserData.email.toLowerCase())
		// disable zoho contact  
          try{
			if(!this.$store.getters.isZohoCrmDisabled && result.data.deleteUser && result.data.deleteUser.zohoCrmContactRecordId){
            	await this.disableZohoContact(result.data.deleteUser , "Deleted From Hera", "delete")
			}
          }catch(e){
            this.displayUserNotification({
                title: "Error",
                type: "error",
                message: e
            })
          }
		  // clean up app state
		  this.editUserData = {}
		  this.editUserModalOpen = false
		  this.$emit('loadData')
		  this.$message({
			type: 'success',
			message: 'User Deleted'
		  });
		  this.loading = false
		}catch(e){
		  console.error(e.message)
		  this.loading = false
		  console.error(e)
		  this.displayUserError(e)
		}
	  },


	  /**
	   * Adds the user to a group
	   * @param {string} username
	   * @param {string} groupname
	   */
	  async addUserToCognitoGroup(username, groupname) { 
		let apiName = 'AdminQueries';
		let path = '/addUserToGroup';
		let myInit = {
			body: {
			  "username" : username,
			  "groupname": groupname
			}, 
			headers: {
			  'Content-Type' : 'application/json',
			  Authorization: `${(await Auth.currentSession()).getAccessToken().getJwtToken()}`
			} 
		}
		var result = await API.post(apiName, path, myInit);
	  },


	  /**
	   * Removes the user to a group
	   * @param {string} username
	   * @param {string} groupname
	   */
	  async removeUserFromCognitoGroup(username, groupname) { 
		let apiName = 'AdminQueries';
		let path = '/removeUserFromGroup';
		let myInit = {
			body: {
			  "username" : username,
			  "groupname": groupname
			}, 
			headers: {
			  'Content-Type' : 'application/json',
			  Authorization: `${(await Auth.currentSession()).getAccessToken().getJwtToken()}`
			} 
		}
		var result = await API.post(apiName, path, myInit);
	  },

	  /** Clears all filters on table */
	  clearFilter() {
		this.$refs.filterTable.clearFilter();
	  },

	  /** 
	   * Handles filtering table by status
	   * @param {string} value - The value of the checkbox selection
	   * @param {object} row - The row object */
	  filterIsActive(value, row) {
		return row.permissionLogin.toString() == value;
	  },

		sortPermissions(a,b){
			let alist = [
				a.permissionLogin ? 'Login' : null, 
				a.permissionFullAccess ? 'Full Access' : null, 
				a.permissionDocuments ? 'DA Documents' : null, 
				a.permissionCounselings ? 'Counselings' : null, 
				a.permissionAccidents ? 'Accident Records' : null, 
				a.permissionInjuries ? 'Injury Records' : null, 
				a.permissionDrugTest ? 'Drug Tests' : null, 
			].filter(Boolean).join(", ")

			let blist = [
				b.permissionLogin ? 'Login' : null, 
				b.permissionFullAccess ? 'Full Access' : null, 
				b.permissionDocuments ? 'DA Documents' : null, 
				b.permissionCounselings ? 'Counselings' : null, 
				b.permissionAccidents ? 'Accident Records' : null, 
				b.permissionInjuries ? 'Injury Records' : null, 
				b.permissionDrugTest ? 'Drug Tests' : null, 
			].filter(Boolean).join(", ")

			let acount = (alist.match(/,/g) || []).length
			let bcount = (blist.match(/,/g) || []).length
			return acount - bcount
		}, 
		
		filterUsers(){
			return [...this.filterArray(this.users.map((user) => {
				let { id, firstName, lastName, phone, email, lastLogin, permissionLogin } = user;
				return {
					id, firstName, lastName, phone, email, lastLogin,
					permissionLogin: permissionLogin ? "Can login" : "Can't login", 
					permissionsList: this.displayPermissions(user)
				}
			}), this.search)];
		},
		async disableZohoContact(user, contactStatus = 'Inactive', operationType = 'update'){
			let _payload = {
				Hera_User_ID: user.id, 
				id: user.zohoCrmContactRecordId,
			    Email: user.email,
				Contact_Status: contactStatus
			}
			try {
             await updateZohoAccountUser(operationType, _payload, this.displayUserError)
             } catch (error) {
             console.error('error updating zoho user', error)
            } 
      	},
	},
  }
</script>
<style lang="scss">
	@media (min-width: 1280px){ // xl
		.responsive-dialog{
			width: 50%;
		}    
	}
	@media (min-width: 1024px) { // lg
		.responsive-dialog{
			width: 65%;
		}
	}
	@media (min-width: 768px) { // md 
		.responsive-dialog{
			width: 75%;
		}
	}
	@media (min-width: 640px) { // sm 
		.responsive-dialog{
			width: 80%;
		}
	}
  .el-tooltip__popper {
    max-width: 350px;
    line-height: 200% !important;
  }
  
</style>