//Newer versions of template should be at top
export default {
    versionLookup: [
        {
            key: "week",
            instance: 1,
            relativePos: 0,  
            regex: "(^Week) (\\d\\d?)$",   
            regexGroup: 1,     
            pattern: "Week",
            transform: "patternMatches[2]", 
        },
        {
            key: "year",
            instance: 1,
            relativePos: 1,
            regex: "(^Week) (\\d\\d?)$",   
            regexGroup: 1,       
            pattern: "Week",
        }
    ],
    versions:[
        {
            start: 202139,
            vehicleType: "XL",   
            startParsingAfter: ['Driver Swag', 'Questions?'],   
            fields: [
                {
                    key: "week",
                    instance: 1,
                    relativePos: 0,
                    regex: "(^Week) (\\d\\d?)$",   
                    regexGroup: 1,       
                    pattern: "Week",
                    transform: "patternMatches[2]",           
                },
                {
                    key: "year",
                    instance: 1,
                    relativePos: 1,
                    regex: "(^Week) (\\d\\d?)$",  
                    regexGroup: 1,      
                    pattern: "Week",
                },
                {
                    key: "overall",
                    instance: 1,
                    relativePos: 1,
                    pattern: "Overall Standing:"
                },
                {
                    key: "safetyAndCompliance",
                    instance: 1,
                    relativePos: 1,
                    pattern: "Safety and Compliance:"            
                },
                {
                    key: "comprehensiveAudit",
                    instance: 1,
                    relativePos: 1,
                    pattern: "Comprehensive Audit:"            
                },
                {
                    key: "safetyScore",
                    instance: 1,
                    relativePos: 1,
                    minPage: 2,
                    pattern: "Safety Score*"
                },
                {
                    key: "safeDriving",
                    instance: 1,
                    relativePos: 0,        
                    regex: "(\\d.\\d\\d) \\((\\d\\d\\d) (FICO)\\) \\| (\\w+)", 
                    regexGroup: 3,          
                    pattern: "FICO",
                    transform: "patternMatches[1]"
                },
                {
                    key: "safeDrivingText",
                    instance: 1,
                    relativePos: 0,        
                    regex: "(\\d.\\d\\d) \\((\\d\\d\\d) (FICO)\\) \\| (\\w+)", 
                    regexGroup: 3,          
                    pattern: "FICO",
                    transform: "patternMatches[4]"
                },
                {
                    key: "fico",
                    instance: 1,
                    relativePos: 0,
                    regex: "\((\\d\\d\\d) (FICO)\)",
                    regexGroup: 2,
                    pattern: "FICO",
                    transform: "parseInt(value.replace(/[^0-9]/g, ''))"
                },
                {
                    key: "seatbeltOff",
                    instance: 1,
                    relativePos: 1,
                    minPage: 2,                    
                    pattern: "Seatbelt-Off Rate",
                    transform: "value.split(' | ')[0]"
                },
                {
                    key: "seatbeltOffText",
                    instance: 1,
                    relativePos: 1,
                    minPage: 2,
                    pattern: "Seatbelt-Off Rate",
                    transform: "value.split(' | ')[1]"
                },
                {
                    key: "speedingEvent",
                    instance: 1,
                    relativePos: 1,
                    minPage: 2,                    
                    pattern: "Speeding Event Rate",
                    transform: "value.split(' | ')[0]"
                },
                {
                    key: "harshBrakingEvent",
                    instance: 1,
                    relativePos: 1,
                    minPage: 2,
                    pattern: "Harsh Braking Event",
                    transform: "value.split(' | ')[0]"
                },
                {
                    key: "harshCorneringEvent",
                    instance: 1,
                    relativePos: 1,
                    minPage: 2,
                    pattern: "Harsh Cornering Event",
                    transform: "value.split(' | ')[0]"
                },
                {
                    key: "speedingEventText",
                    instance: 1,
                    relativePos: 1,
                    minPage: 2,
                    pattern: "Speeding Event Rate",
                    transform: "value.split(' | ')[1]"
                },
                {
                    key: "dvcrCompliance",
                    instance: 1,
                    relativePos: 1,                   
                    pattern: "DVCR Compliance",
                    transform: "value.split(' | ')[0]"
                },
                {
                    key: "dvcrComplianceText",
                    instance: 1,
                    relativePos: 1,
                    pattern: "DVCR Compliance",
                    transform: "value.split(' | ')[1]"
                },
                {
                    key: "onTimeCompliance",
                    instance: 1,
                    relativePos: 1,                    
                    pattern: "On-Time PM Compliance",
                    transform: "value.split(' | ')[0]"
                },
                {
                    key: "onTimeComplianceText",
                    instance: 1,
                    relativePos: 1,
                    pattern: "On-Time PM Compliance",
                    transform: "value.split(' | ')[1]"
                },
                {
                    key: "complianceScoreText",
                    instance: 1,
                    relativePos: 1,
                    pattern: "Compliance Score"
                },
                {
                    key: "workingHoursCompliance",
                    instance: 1,
                    relativePos: 1,                    
                    pattern: "Working Hours Compliance (WHC)",
                    transform: "value.split(' | ')[0]"
                },
                {
                    key: "workingHoursComplianceText",
                    instance: 1,
                    relativePos: 1,
                    pattern: "Working Hours Compliance (WHC)",
                    transform: "value.split(' | ')[1]"
                },
                {
                    key: "customerEscalationDefectDPMO",
                    instance: 1,
                    relativePos: 1,                    
                    pattern: "Customer Escalation Defect DPMO",
                    transform: "value.split(' | ')[0]"
                },
                {
                    key: "customerEscalationDefectDPMOText",
                    instance: 1,
                    relativePos: 1,
                    pattern: "Customer Escalation Defect DPMO",
                    transform: "value.split(' | ')[1]"
                },
                {
                    key: "team",
                    instance: 1,
                    relativePos: 14,
                    pattern: "Team:"
                },
                {
                    key: "highPerformersShare",
                    instance: 1,
                    relativePos: 1,                   
                    pattern: "High Performers Share",
                    transform: "value.split(' | ')[0]"
                },
                {
                    key: "highPerformersShareText",
                    instance: 1,
                    relativePos: 1,
                    pattern: "High Performers Share",
                    transform: "value.split(' | ')[1]"
                },
                {
                    key: "lowPerformersShare",
                    instance: 1,
                    relativePos: 1,                   
                    pattern: "Low Performers Share",
                    transform: "value.split(' | ')[0]"
                },
                {
                    key: "lowPerformersShareText",
                    instance: 1,
                    relativePos: 1,
                    pattern: "Low Performers Share",
                    transform: "value.split(' | ')[1]"
                },
                {
                    key: "attritionRate",
                    instance: 1,
                    relativePos: 1,                    
                    pattern: "Attrition Rate [4 week trailing]",
                    transform: "value.split(' | ')[0]"
                },
                {
                    key: "attritionRateText",
                    instance: 1,
                    relativePos: 1,
                    pattern: "Attrition Rate [4 week trailing]",
                    transform: "value.split(' | ')[1]"
                },              
                {
                    key: "quality",
                    instance: 1,
                    relativePos: 1,
                    pattern: "Quality:"               
                },
                {
                    key: "deliveryCompletion",
                    instance: 1,
                    relativePos: 1,                    
                    pattern: "Delivery Completion Rate",
                    transform: "value.split(' | ')[0]"
                },
                {
                    key: "deliveryCompletionText",
                    instance: 1,
                    relativePos: 1,
                    pattern: "Delivery Completion Rate",
                    transform: "value.split(' | ')[1]"
                },  
                {
                    key: "deliveredAndReceived",
                    instance: 1,
                    relativePos: 1,                    
                    pattern: "Delivered and Received",
                    transform: "value.split(' | ')[0]"
                },
                {
                    key: "deliveredAndReceivedText",
                    instance: 1,
                    relativePos: 1,
                    pattern: "Delivered and Received",
                    transform: "value.split(' | ')[1]"
                },
                {
                    key: "standardWorkComplianceText",
                    instance: 1,
                    relativePos: 1,
                    pattern: "Standard Work Compliance"
                },
                {
                    key: "photoOnDelivery",
                    instance: 1,
                    relativePos: 1,
                    pattern: "Photo-On-Delivery",
                    transform: "value.split(' | ')[0]"
                },
                {
                    key: "photoOnDeliveryText",
                    instance: 1,
                    relativePos: 1,
                    pattern: "Photo-On-Delivery",
                    transform: "value.split(' | ')[1]"
                },
                {
                    key: "contactCompliance",
                    instance: 1,
                    relativePos: 1,                    
                    pattern: "Contact Compliance",
                    transform: "value.split(' | ')[0]"
                },
                {
                    key: "contactComplianceText",
                    instance: 1,
                    relativePos: 1,
                    pattern: "Contact Compliance",
                    transform: "value.split(' | ')[1]"
                },
                {
                    key: "scanCompliance",
                    instance: 1,
                    relativePos: 1,
                    pattern: "Scan Compliance",
                    transform: "value.split(' | ')[0]"
                },
                {
                    key: "scanComplianceText",
                    instance: 1,
                    relativePos: 1,
                    pattern: "Scan Compliance",
                    transform: "value.split(' | ')[1]"
                },
                {
                    key: "attendedDeliveryAccuracy",
                    instance: 1,
                    relativePos: 1,                    
                    pattern: "Attended Delivery Accuracy",
                    transform: "value.split(' | ')[0]"
                },
                {
                    key: "attendedDeliveryAccuracyText",
                    instance: 1,
                    relativePos: 1,
                    pattern: "Attended Delivery Accuracy",
                    transform: "value.split(' | ')[1]"
                },
                {
                    key: 'signSignalViolationsRate',
                    instance: 1, 
                    relativePos: 1, 
                    pattern: "Sign/Signal Violations Rate",
                    transform: "value.split( ' | ')[0]"
                },        
                {
                    key: 'signSignalViolationsRateText',
                    instance: 1, 
                    relativePos: 1, 
                    pattern: "Sign/Signal Violations Rate",
                    transform: "value.split( ' | ')[1]"
                },        
                {
                    key: 'distractionsRate',
                    instance: 1, 
                    relativePos: 1, 
                    pattern: "Distractions Rate",
                    transform: "value.split( ' | ')[0]"
                },        
                {
                    key: 'distractionsRateText',
                    instance: 1, 
                    relativePos: 1, 
                    pattern: "Distractions Rate",
                    transform: "value.split( ' | ')[1]"
                },        
                {
                    key: 'followingDistanceRate',
                    instance: 1, 
                    relativePos: 1, 
                    pattern: "Following Distance Rate",
                    transform: "value.split( ' | ')[0]"
                },        
                {
                    key: 'followingDistanceRateText',
                    instance: 1, 
                    relativePos: 1, 
                    pattern: "Following Distance Rate",
                    transform: "value.split( ' | ')[1]"
                },
                {
                    key: "deliverySlotPerformance",
                    instance: 1,
                    relativePos: 1,                    
                    pattern: "Delivery Slot Performance",
                    transform: "value.split(' | ')[0]"
                },
                {
                    key: "deliverySlotPerformanceText",
                    instance: 1,
                    relativePos: 1,                    
                    pattern: "Delivery Slot Performance",
                    transform: "value.split(' | ')[1]"
                }
            ],
            tables: [
                {
                    table_pattern:[
                        "drivers_ranked_by_overall_score__descending",
                        "#"
                     ],
                    table_match_percent: 1,
                    table_data:[],
                    table_end_string: "Drivers ranked by overall score, descending.",
                    min_data: 8,
                    columns: [
                        { 
                            slug: "name",
                            left: 0.018,
                            right: 0.101
                        },
                        { 
                            slug: "transporter_id",
                            left: 0.101,
                            right: 0.185
                        },
                        { 
                            slug: "overal_tier",
                            left: 0.185,
                            right: 0.226
                        },
                        { 
                            slug: "delivered",
                            left: 0.226,
                            right: 0.264,
                        },
                        { 
                            slug: "key_focus_area",
                            left: 0.264,
                            right: 0.298
                        },
                        { 
                            slug: "speeding_event_rate",
                            left: 0.305,
                            right: 0.356,
                        },
                        {
                            slug: "harsh_braking",
                            left: 0.356,
                            right: 0.409,
                        },
                        {
                            slug: "harsh_cornering",
                            left: 0.409,
                            right: 0.460
                        },
                        {
                            slug: "seatbelt_off_rate",
                            left: 0.460,
                            right: 0.513
                        },
                        {
                            slug: "distractions_rate",
                            left: 0.513,
                            right: 0.564

                        },
                        {
                            slug: "following_distance_rate",
                            left: 0.564,
                            right: 0.617

                        },
                        {
                            slug: "sign_signal_violations_rate",
                            left: 0.617,
                            right: 0.672

                        },
                        { 
                            slug: "dcr",
                            left: 0.672,
                            right: 0.705,
                        },
                        { 
                            slug: "dar",
                            left: 0.705,
                            right: 0.729,
                        },
                        { 
                            slug: "swc_pod",
                            left: 0.729,
                            right: 0.756,
                        },
                        { 
                            slug: "swc_cc",
                            left: 0.756,
                            right: 0.788,
                        },
                        { 
                            slug: "star_rating",
                            left: 0.788,
                            right: 0.840,
                        },
                        { 
                            slug: "dslp",
                            left: 0.840,
                            right: 0.891,
                        },
                        { 
                            slug: "dnrs",
                            left: 0.898,
                            right: 0.931,
                        },
                        { 
                            slug: "pod_opps",
                            left: 0.931,
                            right: 0.965,
                        },
                        { 
                            slug: "cc_opps",
                            left: 0.965,
                            right: 1.0,
                        }
                    ]
                },
                {
                    table_pattern:[
                            "drivers_ranked_by_overall_score__descending",
                            "fant"
                    ],
                    table_match_percent: 1,
                    table_data:[],
                    min_data: 12,
                    columns: [
                        {
                            slug: "name",
                            left: 0.026,
                            right:0.151
                        },
                        {
                            slug: "transporter_id",
                            left: 0.151,
                            right: 0.265
                        },
                        {
                            slug: "average_tier",
                            left: 0.265,
                            right: 0.317
                        },
                        { 
                            slug: "speeding_event_rate",
                            left: 0.325,
                            right: 0.396,
                        },
                        {
                            slug: "harsh_braking",
                            left: 0.396,
                            right: 0.468,
                        },
                        {
                            slug: "harsh_cornering",
                            left: 0.468,
                            right: 0.539
                        },
                        {
                            slug: "dcr",
                            left: 0.539,
                            right: 0.596
                        },
                        {
                            slug: "dar",
                            left: 0.596,
                            right: 0.647
                        },
                        {
                            slug: "swc_pod",
                            left: 0.647,
                            right: 0.704
                        },
                        {
                            slug: "swc_cc",
                            left: 0.704,
                            right: 0.763
                        },
                        {
                            slug: "high_low_performer",
                            left: 0.763,
                            right: 0.858
                        },
                        {
                            slug: "fant",
                            left: 0.867,
                            right: 0.899
                        },
                        {
                            slug: "great",
                            left: 0.899,
                            right: 0.933
                        },
                        {
                            slug: "fair",
                            left: 0.933,
                            right: 0.966
                        },
                        {
                            slug: "poor",
                            left: 0.966,
                            right: 1
                        },
                    ]
                }
            ]
        },
        {
            start: 202130,
            vehicleType: "XL",   
            startParsingAfter: ['Driver Swag', 'Questions?'],   
            fields: [
                {
                    key: "week",
                    instance: 1,
                    relativePos: 0,
                    regex: "(^Week) (\\d\\d?)$",   
                    regexGroup: 1,       
                    pattern: "Week",
                    transform: "patternMatches[2]",           
                },
                {
                    key: "year",
                    instance: 1,
                    relativePos: 1,
                    regex: "(^Week) (\\d\\d?)$",  
                    regexGroup: 1,      
                    pattern: "Week",
                },
                {
                    key: "overall",
                    instance: 1,
                    relativePos: 1,
                    pattern: "Overall Standing:"
                },
                {
                    key: "safetyAndCompliance",
                    instance: 1,
                    relativePos: 1,
                    pattern: "Safety and Compliance:"            
                },
                {
                    key: "comprehensiveAudit",
                    instance: 1,
                    relativePos: 1,
                    pattern: "Comprehensive Audit:"            
                },
                {
                    key: "safetyScore",
                    instance: 1,
                    relativePos: 1,
                    minPage: 2,
                    pattern: "Safety Score*"
                },
                {
                    key: "safeDriving",
                    instance: 1,
                    relativePos: 0,        
                    regex: "(\\d.\\d\\d) \\((\\d\\d\\d) (FICO)\\) \\| (\\w+)", 
                    regexGroup: 3,          
                    pattern: "FICO",
                    transform: "patternMatches[1]"
                },
                {
                    key: "safeDrivingText",
                    instance: 1,
                    relativePos: 0,        
                    regex: "(\\d.\\d\\d) \\((\\d\\d\\d) (FICO)\\) \\| (\\w+)", 
                    regexGroup: 3,          
                    pattern: "FICO",
                    transform: "patternMatches[4]"
                },
                {
                    key: "fico",
                    instance: 1,
                    relativePos: 0,
                    regex: "\((\\d\\d\\d) (FICO)\)",
                    regexGroup: 2,
                    pattern: "FICO",
                    transform: "parseInt(value.replace(/[^0-9]/g, ''))"
                },
                {
                    key: "seatbeltOff",
                    instance: 1,
                    relativePos: 1,
                    minPage: 2,                    
                    pattern: "Seatbelt-Off Rate",
                    transform: "value.split(' | ')[0]"
                },
                {
                    key: "seatbeltOffText",
                    instance: 1,
                    relativePos: 1,
                    minPage: 2,
                    pattern: "Seatbelt-Off Rate",
                    transform: "value.split(' | ')[1]"
                },
                {
                    key: "speedingEvent",
                    instance: 1,
                    relativePos: 1,
                    minPage: 2,                    
                    pattern: "Speeding Event Rate",
                    transform: "value.split(' | ')[0]"
                },
                {
                    key: "harshBrakingEvent",
                    instance: 1,
                    relativePos: 1,
                    minPage: 2,
                    pattern: "Harsh Braking Event",
                    transform: "value.split(' | ')[0]"
                },
                {
                    key: "harshCorneringEvent",
                    instance: 1,
                    relativePos: 1,
                    minPage: 2,
                    pattern: "Harsh Cornering Event",
                    transform: "value.split(' | ')[0]"
                },
                {
                    key: "speedingEventText",
                    instance: 1,
                    relativePos: 1,
                    minPage: 2,
                    pattern: "Speeding Event Rate",
                    transform: "value.split(' | ')[1]"
                },
                {
                    key: "dvcrCompliance",
                    instance: 1,
                    relativePos: 1,                   
                    pattern: "DVCR Compliance",
                    transform: "value.split(' | ')[0]"
                },
                {
                    key: "dvcrComplianceText",
                    instance: 1,
                    relativePos: 1,
                    pattern: "DVCR Compliance",
                    transform: "value.split(' | ')[1]"
                },
                {
                    key: "onTimeCompliance",
                    instance: 1,
                    relativePos: 1,                    
                    pattern: "On-Time PM Compliance",
                    transform: "value.split(' | ')[0]"
                },
                {
                    key: "onTimeComplianceText",
                    instance: 1,
                    relativePos: 1,
                    pattern: "On-Time PM Compliance",
                    transform: "value.split(' | ')[1]"
                },
                {
                    key: "complianceScoreText",
                    instance: 1,
                    relativePos: 1,
                    pattern: "Compliance Score"
                },
                {
                    key: "workingHoursCompliance",
                    instance: 1,
                    relativePos: 1,                    
                    pattern: "Working Hours Compliance (WHC)",
                    transform: "value.split(' | ')[0]"
                },
                {
                    key: "workingHoursComplianceText",
                    instance: 1,
                    relativePos: 1,
                    pattern: "Working Hours Compliance (WHC)",
                    transform: "value.split(' | ')[1]"
                },
                {
                    key: "customerEscalationDefectDPMO",
                    instance: 1,
                    relativePos: 1,                    
                    pattern: "Customer Escalation Defect DPMO",
                    transform: "value.split(' | ')[0]"
                },
                {
                    key: "customerEscalationDefectDPMOText",
                    instance: 1,
                    relativePos: 1,
                    pattern: "Customer Escalation Defect DPMO",
                    transform: "value.split(' | ')[1]"
                },
                {
                    key: "team",
                    instance: 1,
                    relativePos: 1,
                    pattern: "Team:"
                },
                {
                    key: "highPerformersShare",
                    instance: 1,
                    relativePos: 1,                   
                    pattern: "High Performers Share",
                    transform: "value.split(' | ')[0]"
                },
                {
                    key: "highPerformersShareText",
                    instance: 1,
                    relativePos: 1,
                    pattern: "High Performers Share",
                    transform: "value.split(' | ')[1]"
                },
                {
                    key: "lowPerformersShare",
                    instance: 1,
                    relativePos: 1,                   
                    pattern: "Low Performers Share",
                    transform: "value.split(' | ')[0]"
                },
                {
                    key: "lowPerformersShareText",
                    instance: 1,
                    relativePos: 1,
                    pattern: "Low Performers Share",
                    transform: "value.split(' | ')[1]"
                },
                {
                    key: "attritionRate",
                    instance: 1,
                    relativePos: 1,                    
                    pattern: "Attrition Rate [4 week trailing]",
                    transform: "value.split(' | ')[0]"
                },
                {
                    key: "attritionRateText",
                    instance: 1,
                    relativePos: 1,
                    pattern: "Attrition Rate [4 week trailing]",
                    transform: "value.split(' | ')[1]"
                },              
                {
                    key: "quality",
                    instance: 1,
                    relativePos: 1,
                    pattern: "Quality:"               
                },
                {
                    key: "deliveryCompletion",
                    instance: 1,
                    relativePos: 1,                    
                    pattern: "Delivery Completion Rate",
                    transform: "value.split(' | ')[0]"
                },
                {
                    key: "deliveryCompletionText",
                    instance: 1,
                    relativePos: 1,
                    pattern: "Delivery Completion Rate",
                    transform: "value.split(' | ')[1]"
                },  
                {
                    key: "deliveredAndReceived",
                    instance: 1,
                    relativePos: 1,                    
                    pattern: "Delivered and Received",
                    transform: "value.split(' | ')[0]"
                },
                {
                    key: "deliveredAndReceivedText",
                    instance: 1,
                    relativePos: 1,
                    pattern: "Delivered and Received",
                    transform: "value.split(' | ')[1]"
                },
                {
                    key: "standardWorkComplianceText",
                    instance: 1,
                    relativePos: 1,
                    pattern: "Standard Work Compliance"
                },
                {
                    key: "photoOnDelivery",
                    instance: 1,
                    relativePos: 1,
                    pattern: "Photo-On-Delivery",
                    transform: "value.split(' | ')[0]"
                },
                {
                    key: "photoOnDeliveryText",
                    instance: 1,
                    relativePos: 1,
                    pattern: "Photo-On-Delivery",
                    transform: "value.split(' | ')[1]"
                },
                {
                    key: "contactCompliance",
                    instance: 1,
                    relativePos: 1,                    
                    pattern: "Contact Compliance",
                    transform: "value.split(' | ')[0]"
                },
                {
                    key: "contactComplianceText",
                    instance: 1,
                    relativePos: 1,
                    pattern: "Contact Compliance",
                    transform: "value.split(' | ')[1]"
                },
                {
                    key: "scanCompliance",
                    instance: 1,
                    relativePos: 1,
                    pattern: "Scan Compliance",
                    transform: "value.split(' | ')[0]"
                },
                {
                    key: "scanComplianceText",
                    instance: 1,
                    relativePos: 1,
                    pattern: "Scan Compliance",
                    transform: "value.split(' | ')[1]"
                },
                {
                    key: "attendedDeliveryAccuracy",
                    instance: 1,
                    relativePos: 1,                    
                    pattern: "Attended Delivery Accuracy",
                    transform: "value.split(' | ')[0]"
                },
                {
                    key: "attendedDeliveryAccuracyText",
                    instance: 1,
                    relativePos: 1,
                    pattern: "Attended Delivery Accuracy",
                    transform: "value.split(' | ')[1]"
                },
                {
                    key: 'signSignalViolationsRate',
                    instance: 1, 
                    relativePos: 1, 
                    pattern: "Sign/Signal Violations Rate",
                    transform: "value.split( ' | ')[0]"
                },        
                {
                    key: 'signSignalViolationsRateText',
                    instance: 1, 
                    relativePos: 1, 
                    pattern: "Sign/Signal Violations Rate",
                    transform: "value.split( ' | ')[1]"
                },        
                {
                    key: 'distractionsRate',
                    instance: 1, 
                    relativePos: 1, 
                    pattern: "Distractions Rate",
                    transform: "value.split( ' | ')[0]"
                },        
                {
                    key: 'distractionsRateText',
                    instance: 1, 
                    relativePos: 1, 
                    pattern: "Distractions Rate",
                    transform: "value.split( ' | ')[1]"
                },        
                {
                    key: 'followingDistanceRate',
                    instance: 1, 
                    relativePos: 1, 
                    pattern: "Following Distance Rate",
                    transform: "value.split( ' | ')[0]"
                },        
                {
                    key: 'followingDistanceRateText',
                    instance: 1, 
                    relativePos: 1, 
                    pattern: "Following Distance Rate",
                    transform: "value.split( ' | ')[1]"
                }        
    
            ],
            tables: [
                {
                    table_pattern:[
                        "drivers_ranked_by_overall_score__descending",
                        "#"
                     ],
                    table_match_percent: 1,
                    table_data:[],
                    table_end_string: "Drivers ranked by overall score, descending.",
                    min_data: 8,
                    columns: [
                        { 
                            slug: "name",
                            left: 0.021,
                            right: 0.106
                        },
                        { 
                            slug: "transporter_id",
                            left: 0.106,
                            right: 0.193
                        },
                        { 
                            slug: "overal_tier",
                            left: 0.193,
                            right: 0.237
                        },
                        { 
                            slug: "delivered",
                            left: 0.237,
                            right: 0.277,
                        },
                        { 
                            slug: "key_focus_area",
                            left: 0.277,
                            right: 0.311
                        },
                        { 
                            slug: "speeding_event_rate",
                            left: 0.319,
                            right: 0.372,
                        },
                        {
                            slug: "harsh_braking",
                            left: 0.372,
                            right: 0.427,
                        },
                        {
                            slug: "harsh_cornering",
                            left: 0.427,
                            right: 0.481
                        },
                        {
                            slug: "seatbelt_off_rate",
                            left: 0.481,
                            right: 0.536
                        },
                        {
                            slug: "distractions_rate",
                            left: 0.536,
                            right: 0.590

                        },
                        {
                            slug: "following_distance_rate",
                            left: 0.590,
                            right: 0.644

                        },
                        {
                            slug: "sign_signal_violations_rate",
                            left: 0.644,
                            right: 0.702

                        },
                        { 
                            slug: "dcr",
                            left: 0.702,
                            right: 0.746,
                        },
                        { 
                            slug: "dar",
                            left: 0.746,
                            right: 0.790,
                        },
                        { 
                            slug: "swc_pod",
                            left: 0.790,
                            right: 0.840,
                        },
                        { 
                            slug: "swc_cc",
                            left: 0.840,
                            right: 0.887,
                        },
                        { 
                            slug: "dnrs",
                            left: 0.893,
                            right: 0.928,
                        },
                        { 
                            slug: "pod_opps",
                            left: 0.928,
                            right: 0.964,
                        },
                        { 
                            slug: "cc_opps",
                            left: 0.964,
                            right: 1.0,
                        }
                    ]
                },
                {
                    table_pattern:[
                            "drivers_ranked_by_overall_score__descending",
                            "fant"
                    ],
                    table_match_percent: 1,
                    table_data:[],
                    min_data: 12,
                    columns: [
                        {
                            slug: "name",
                            left: 0.026,
                            right:0.151
                        },
                        {
                            slug: "transporter_id",
                            left: 0.151,
                            right: 0.265
                        },
                        {
                            slug: "average_tier",
                            left: 0.265,
                            right: 0.317
                        },
                        { 
                            slug: "speeding_event_rate",
                            left: 0.325,
                            right: 0.396,
                        },
                        {
                            slug: "harsh_braking",
                            left: 0.396,
                            right: 0.468,
                        },
                        {
                            slug: "harsh_cornering",
                            left: 0.468,
                            right: 0.539
                        },
                        {
                            slug: "dcr",
                            left: 0.539,
                            right: 0.596
                        },
                        {
                            slug: "dar",
                            left: 0.596,
                            right: 0.647
                        },
                        {
                            slug: "swc_pod",
                            left: 0.647,
                            right: 0.704
                        },
                        {
                            slug: "swc_cc",
                            left: 0.704,
                            right: 0.763
                        },
                        {
                            slug: "high_low_performer",
                            left: 0.763,
                            right: 0.858
                        },
                        {
                            slug: "fant",
                            left: 0.867,
                            right: 0.899
                        },
                        {
                            slug: "great",
                            left: 0.899,
                            right: 0.933
                        },
                        {
                            slug: "fair",
                            left: 0.933,
                            right: 0.966
                        },
                        {
                            slug: "poor",
                            left: 0.966,
                            right: 1
                        },
                    ]
                }
            ]
        },
        {
            start: 202126,
            vehicleType: "XL",   
            startParsingAfter: ['Driver Swag', 'Questions?'],   
            fields: [
                {
                    key: "week",
                    instance: 1,
                    relativePos: 0,
                    regex: "(^Week) (\\d\\d?)$",   
                    regexGroup: 1,       
                    pattern: "Week",
                    transform: "patternMatches[2]",           
                },
                {
                    key: "year",
                    instance: 1,
                    relativePos: 1,
                    regex: "(^Week) (\\d\\d?)$",  
                    regexGroup: 1,      
                    pattern: "Week",
                },
                {
                    key: "overall",
                    instance: 1,
                    relativePos: 1,
                    pattern: "Overall Standing:"
                },
                {
                    key: "safetyAndCompliance",
                    instance: 1,
                    relativePos: 1,
                    pattern: "Safety and Compliance:"            
                },
                {
                    key: "comprehensiveAudit",
                    instance: 1,
                    relativePos: 1,
                    pattern: "Comprehensive Audit:"            
                },
                {
                    key: "safetyScore",
                    instance: 1,
                    relativePos: 1,
                    minPage: 2,
                    pattern: "Safety Score*"
                },
                {
                    key: "safeDriving",
                    instance: 1,
                    relativePos: 0,        
                    regex: "(\\d.\\d\\d) \\((\\d\\d\\d) (FICO)\\) \\| (\\w+)", 
                    regexGroup: 3,          
                    pattern: "FICO",
                    transform: "patternMatches[1]"
                },
                {
                    key: "safeDrivingText",
                    instance: 1,
                    relativePos: 0,        
                    regex: "(\\d.\\d\\d) \\((\\d\\d\\d) (FICO)\\) \\| (\\w+)", 
                    regexGroup: 3,          
                    pattern: "FICO",
                    transform: "patternMatches[4]"
                },
                {
                    key: "fico",
                    instance: 1,
                    relativePos: 0,
                    regex: "\((\\d\\d\\d) (FICO)\)",
                    regexGroup: 2,
                    pattern: "FICO",
                    transform: "parseInt(value.replace(/[^0-9]/g, ''))"
                },
                {
                    key: "seatbeltOff",
                    instance: 1,
                    relativePos: 1,
                    minPage: 2,                    
                    pattern: "Seatbelt-Off Rate",
                    transform: "value.split(' | ')[0]"
                },
                {
                    key: "seatbeltOffText",
                    instance: 1,
                    relativePos: 1,
                    minPage: 2,
                    pattern: "Seatbelt-Off Rate",
                    transform: "value.split(' | ')[1]"
                },
                {
                    key: "speedingEvent",
                    instance: 1,
                    relativePos: 1,
                    minPage: 2,                    
                    pattern: "Speeding Event Rate",
                    transform: "value.split(' | ')[0]"
                },
                {
                    key: "harshBrakingEvent",
                    instance: 1,
                    relativePos: 1,
                    minPage: 2,
                    pattern: "Harsh Braking Event",
                    transform: "value.split(' | ')[0]"
                },
                {
                    key: "harshCorneringEvent",
                    instance: 1,
                    relativePos: 1,
                    minPage: 2,
                    pattern: "Harsh Cornering Event",
                    transform: "value.split(' | ')[0]"
                },
                {
                    key: "speedingEventText",
                    instance: 1,
                    relativePos: 1,
                    minPage: 2,
                    pattern: "Speeding Event Rate",
                    transform: "value.split(' | ')[1]"
                },
                {
                    key: "dvcrCompliance",
                    instance: 1,
                    relativePos: 1,                   
                    pattern: "DVCR Compliance",
                    transform: "value.split(' | ')[0]"
                },
                {
                    key: "dvcrComplianceText",
                    instance: 1,
                    relativePos: 1,
                    pattern: "DVCR Compliance",
                    transform: "value.split(' | ')[1]"
                },
                {
                    key: "onTimeCompliance",
                    instance: 1,
                    relativePos: 1,                    
                    pattern: "On-Time PM Compliance",
                    transform: "value.split(' | ')[0]"
                },
                {
                    key: "onTimeComplianceText",
                    instance: 1,
                    relativePos: 1,
                    pattern: "On-Time PM Compliance",
                    transform: "value.split(' | ')[1]"
                },
                {
                    key: "complianceScoreText",
                    instance: 1,
                    relativePos: 1,
                    pattern: "Compliance Score"
                },
                {
                    key: "workingHoursCompliance",
                    instance: 1,
                    relativePos: 1,                    
                    pattern: "Working Hours Compliance (WHC)",
                    transform: "value.split(' | ')[0]"
                },
                {
                    key: "workingHoursComplianceText",
                    instance: 1,
                    relativePos: 1,
                    pattern: "Working Hours Compliance (WHC)",
                    transform: "value.split(' | ')[1]"
                },
                {
                    key: "customerEscalationDefectDPMO",
                    instance: 1,
                    relativePos: 1,                    
                    pattern: "Customer Escalation Defect DPMO",
                    transform: "value.split(' | ')[0]"
                },
                {
                    key: "customerEscalationDefectDPMOText",
                    instance: 1,
                    relativePos: 1,
                    pattern: "Customer Escalation Defect DPMO",
                    transform: "value.split(' | ')[1]"
                },
                {
                    key: "team",
                    instance: 1,
                    relativePos: 1,
                    pattern: "Team:"
                },
                {
                    key: "highPerformersShare",
                    instance: 1,
                    relativePos: 1,                   
                    pattern: "High Performers Share",
                    transform: "value.split(' | ')[0]"
                },
                {
                    key: "highPerformersShareText",
                    instance: 1,
                    relativePos: 1,
                    pattern: "High Performers Share",
                    transform: "value.split(' | ')[1]"
                },
                {
                    key: "lowPerformersShare",
                    instance: 1,
                    relativePos: 1,                   
                    pattern: "Low Performers Share",
                    transform: "value.split(' | ')[0]"
                },
                {
                    key: "lowPerformersShareText",
                    instance: 1,
                    relativePos: 1,
                    pattern: "Low Performers Share",
                    transform: "value.split(' | ')[1]"
                },
                {
                    key: "attritionRate",
                    instance: 1,
                    relativePos: 1,                    
                    pattern: "Attrition Rate [4 week trailing]",
                    transform: "value.split(' | ')[0]"
                },
                {
                    key: "attritionRateText",
                    instance: 1,
                    relativePos: 1,
                    pattern: "Attrition Rate [4 week trailing]",
                    transform: "value.split(' | ')[1]"
                },              
                {
                    key: "quality",
                    instance: 1,
                    relativePos: 1,
                    pattern: "Quality:"               
                },
                {
                    key: "deliveryCompletion",
                    instance: 1,
                    relativePos: 1,                    
                    pattern: "Delivery Completion Rate",
                    transform: "value.split(' | ')[0]"
                },
                {
                    key: "deliveryCompletionText",
                    instance: 1,
                    relativePos: 1,
                    pattern: "Delivery Completion Rate",
                    transform: "value.split(' | ')[1]"
                },  
                {
                    key: "deliveredAndReceived",
                    instance: 1,
                    relativePos: 1,                    
                    pattern: "Delivered and Received",
                    transform: "value.split(' | ')[0]"
                },
                {
                    key: "deliveredAndReceivedText",
                    instance: 1,
                    relativePos: 1,
                    pattern: "Delivered and Received",
                    transform: "value.split(' | ')[1]"
                },
                {
                    key: "standardWorkComplianceText",
                    instance: 1,
                    relativePos: 1,
                    pattern: "Standard Work Compliance"
                },
                {
                    key: "photoOnDelivery",
                    instance: 1,
                    relativePos: 1,
                    pattern: "Photo-On-Delivery",
                    transform: "value.split(' | ')[0]"
                },
                {
                    key: "photoOnDeliveryText",
                    instance: 1,
                    relativePos: 1,
                    pattern: "Photo-On-Delivery",
                    transform: "value.split(' | ')[1]"
                },
                {
                    key: "contactCompliance",
                    instance: 1,
                    relativePos: 1,                    
                    pattern: "Contact Compliance",
                    transform: "value.split(' | ')[0]"
                },
                {
                    key: "contactComplianceText",
                    instance: 1,
                    relativePos: 1,
                    pattern: "Contact Compliance",
                    transform: "value.split(' | ')[1]"
                },
                {
                    key: "scanCompliance",
                    instance: 1,
                    relativePos: 1,
                    pattern: "Scan Compliance",
                    transform: "value.split(' | ')[0]"
                },
                {
                    key: "scanComplianceText",
                    instance: 1,
                    relativePos: 1,
                    pattern: "Scan Compliance",
                    transform: "value.split(' | ')[1]"
                },
                {
                    key: "attendedDeliveryAccuracy",
                    instance: 1,
                    relativePos: 1,                    
                    pattern: "Attended Delivery Accuracy",
                    transform: "value.split(' | ')[0]"
                },
                {
                    key: "attendedDeliveryAccuracyText",
                    instance: 1,
                    relativePos: 1,
                    pattern: "Attended Delivery Accuracy",
                    transform: "value.split(' | ')[1]"
                },
                {
                    key: 'signSignalViolationsRate',
                    instance: 1, 
                    relativePos: 1, 
                    pattern: "Sign/Signal Violations Rate",
                    transform: "value.split( ' | ')[0]"
                },        
                {
                    key: 'signSignalViolationsRateText',
                    instance: 1, 
                    relativePos: 1, 
                    pattern: "Sign/Signal Violations Rate",
                    transform: "value.split( ' | ')[1]"
                },        
                {
                    key: 'distractionsRate',
                    instance: 1, 
                    relativePos: 1, 
                    pattern: "Distractions Rate",
                    transform: "value.split( ' | ')[0]"
                },        
                {
                    key: 'distractionsRateText',
                    instance: 1, 
                    relativePos: 1, 
                    pattern: "Distractions Rate",
                    transform: "value.split( ' | ')[1]"
                },        
                {
                    key: 'followingDistanceRate',
                    instance: 1, 
                    relativePos: 1, 
                    pattern: "Following Distance Rate",
                    transform: "value.split( ' | ')[0]"
                },        
                {
                    key: 'followingDistanceRateText',
                    instance: 1, 
                    relativePos: 1, 
                    pattern: "Following Distance Rate",
                    transform: "value.split( ' | ')[1]"
                }        
    
            ],
            tables: [
                {
                    table_pattern:[
                        "drivers_ranked_by_overall_score__descending",
                        "#"
                     ],
                    table_match_percent: 1,
                    table_data:[],
                    table_end_string: "Drivers ranked by overall score, descending.",
                    min_data: 8,
                    columns: [
                        { 
                            slug: "name",
                            left: 0.018,
                            right: 0.098
                        },
                        { 
                            slug: "transporter_id",
                            left: 0.098,
                            right: 0.179
                        },
                        { 
                            slug: "overal_tier",
                            left: 0.179,
                            right: 0.219
                        },
                        { 
                            slug: "delivered",
                            left: 0.219,
                            right: 0.256,
                        },
                        { 
                            slug: "key_focus_area",
                            left: 0.256,
                            right: 0.289
                        },
                        { 
                            slug: "speeding_event_rate",
                            left: 0.295,
                            right: 0.345,
                        },
                        {
                            slug: "harsh_braking",
                            left: 0.345,
                            right: 0.395,
                        },
                        {
                            slug: "harsh_cornering",
                            left: 0.395,
                            right: 0.446

                        },
                        {
                            slug: "seatbelt_off_rate",
                            left: 0.446,
                            right: 0.496

                        },
                        {
                            slug: "distractions_rate",
                            left: 0.496,
                            right: 0.546

                        },
                        {
                            slug: "following_distance_rate",
                            left: 0.546,
                            right: 0.597

                        },
                        {
                            slug: "sign_signal_violations_rate",
                            left: 0.597,
                            right: 0.650

                        },
                        { 
                            slug: "dcr",
                            left: 0.650,
                            right: 0.691,
                        },
                        { 
                            slug: "dar",
                            left: 0.691,
                            right: 0.731,
                        },
                        { 
                            slug: "swc_pod",
                            left: 0.731,
                            right: 0.772,
                        },
                        { 
                            slug: "swc_cc",
                            left: 0.772,
                            right: 0.812,
                        },
                        { 
                            slug: "swc_sc",
                            left: 0.812,
                            right: 0.854,
                        },
                        { 
                            slug: "swc_ad",
                            left: 0.854,
                            right: 0.895,
                        },
                        { 
                            slug: "dnrs",
                            left: 0.901,
                            right: 0.934,
                        },
                        { 
                            slug: "pod_opps",
                            left: 0.934,
                            right: 0.967,
                        },
                        { 
                            slug: "cc_opps",
                            left: 0.967,
                            right: 1.0,
                        }
                    ]
                },
                {
                    table_pattern:[
                            "drivers_ranked_by_overall_score__descending",
                            "fant"
                    ],
                    table_match_percent: 1,
                    table_data:[],
                    min_data: 12,
                    columns: [
                        {
                            slug: "name",
                            left: 0.05198065936565399,
                            right:0.17115876078605652
                        },
                        {
                            slug: "transporter_id",
                            left: 0.17115876078605652,
                            right: 0.29660940170288086
                        },
                        {
                            slug: "average_tier",
                            left: 0.29660940170288086,
                            right: 0.34888049960136414
                        },
                        {
                            slug: "dcr",
                            left: 0.34888049960136414,
                            right: 0.40742412209510803
                        },
                        {
                            slug: "dar",
                            left: 0.40742412209510803,
                            right: 0.46596774458885193
                        },
                        {
                            slug: "swc_pod",
                            left: 0.46596774458885193,
                            right: 0.5224205255508423
                        },
                        {
                            slug: "swc_cc",
                            left: 0.5224205255508423,
                            right: 0.5809641480445862
                        },
                        {
                            slug: "swc_sc",
                            left: 0.5809641480445862,
                            right: 0.6395078301429749
                        },
                        {
                            slug: "swc_ad",
                            left: 0.6395078301429749,
                            right: 0.6959605813026428
                        },
                        {
                            slug: "high_low_performer",
                            left: 0.6959605813026428,
                            right: 0.7984119653701782
                        },
                        {
                            slug: "fant",
                            left: 0.798411965370178,
                            right: 0.8318654298782349
                        },
                        {
                            slug: "great",
                            left: 0.8318654298782349,
                            right: 0.8674097657203674
                        },
                        {
                            slug: "fair",
                            left: 0.8674097657203674,
                            right: 0.8987724781036377
                        },
                        {
                            slug: "poor",
                            left: 0.8987724781036377,
                            right: 0.9426801800727844
                        },
                    ]
                }
            ]
        },
        {
            start: 202107,   
            startParsingAfter: ['Driver Swag', 'Questions?'],   
            fields: [
                {
                    key: "week",
                    instance: 1,
                    relativePos: 0,
                    regex: "(^Week) (\\d\\d?)$",   
                    regexGroup: 1,       
                    pattern: "Week",
                    transform: "patternMatches[2]",           
                },
                {
                    key: "year",
                    instance: 1,
                    relativePos: 1,
                    regex: "(^Week) (\\d\\d?)$",  
                    regexGroup: 1,      
                    pattern: "Week",
                },
                {
                    key: "overall",
                    instance: 1,
                    relativePos: 1,
                    pattern: "Overall Standing:"
                },
                {
                    key: "safetyAndCompliance",
                    instance: 1,
                    relativePos: 1,
                    pattern: "Safety and Compliance:"            
                },
                {
                    key: "comprehensiveAudit",
                    instance: 1,
                    relativePos: 1,
                    pattern: "Comprehensive Audit:"            
                },
                {
                    key: "safetyScore",
                    instance: 1,
                    relativePos: 1,
                    minPage: 2,
                    pattern: "Safety Score*"
                },
                {
                    key: "safeDriving",
                    instance: 1,
                    relativePos: 0,        
                    regex: "(\\d.\\d\\d) \\((\\d\\d\\d) (FICO)\\) \\| (\\w+)", 
                    regexGroup: 3,          
                    pattern: "FICO",
                    transform: "patternMatches[1]"
                },
                {
                    key: "safeDrivingText",
                    instance: 1,
                    relativePos: 0,        
                    regex: "(\\d.\\d\\d) \\((\\d\\d\\d) (FICO)\\) \\| (\\w+)", 
                    regexGroup: 3,          
                    pattern: "FICO",
                    transform: "patternMatches[4]"
                },
                {
                    key: "fico",
                    instance: 1,
                    relativePos: 0,
                    regex: "\((\\d\\d\\d) (FICO)\)",
                    regexGroup: 2,
                    pattern: "FICO",
                    transform: "parseInt(value.replace(/[^0-9]/g, ''))"
                },
                {
                    key: "seatbeltOff",
                    instance: 1,
                    relativePos: 1,
                    minPage: 2,                    
                    pattern: "Seatbelt-Off Rate",
                    transform: "value.split(' | ')[0]"
                },
                {
                    key: "seatbeltOffText",
                    instance: 1,
                    relativePos: 1,
                    minPage: 2,
                    pattern: "Seatbelt-Off Rate",
                    transform: "value.split(' | ')[1]"
                },
                {
                    key: "speedingEvent",
                    instance: 1,
                    relativePos: 1,
                    minPage: 2,                    
                    pattern: "Speeding Event Rate",
                    transform: "value.split(' | ')[0]"
                },
                {
                    key: "speedingEventText",
                    instance: 1,
                    relativePos: 1,
                    minPage: 2,
                    pattern: "Speeding Event Rate",
                    transform: "value.split(' | ')[1]"
                },
                {
                    key: "dvcrCompliance",
                    instance: 1,
                    relativePos: 1,                   
                    pattern: "DVCR Compliance",
                    transform: "value.split(' | ')[0]"
                },
                {
                    key: "dvcrComplianceText",
                    instance: 1,
                    relativePos: 1,
                    pattern: "DVCR Compliance",
                    transform: "value.split(' | ')[1]"
                },
                {
                    key: "onTimeCompliance",
                    instance: 1,
                    relativePos: 1,                    
                    pattern: "On-Time PM Compliance",
                    transform: "value.split(' | ')[0]"
                },
                {
                    key: "onTimeComplianceText",
                    instance: 1,
                    relativePos: 1,
                    pattern: "On-Time PM Compliance",
                    transform: "value.split(' | ')[1]"
                },
                {
                    key: "complianceScoreText",
                    instance: 1,
                    relativePos: 1,
                    pattern: "Compliance Score"
                },
                {
                    key: "workingHoursCompliance",
                    instance: 1,
                    relativePos: 1,                    
                    pattern: "Working Hours Compliance (WHC)",
                    transform: "value.split(' | ')[0]"
                },
                {
                    key: "workingHoursComplianceText",
                    instance: 1,
                    relativePos: 1,
                    pattern: "Working Hours Compliance (WHC)",
                    transform: "value.split(' | ')[1]"
                },
                {
                    key: "customerEscalationDefectDPMO",
                    instance: 1,
                    relativePos: 1,                    
                    pattern: "Customer Escalation Defect DPMO",
                    transform: "value.split(' | ')[0]"
                },
                {
                    key: "customerEscalationDefectDPMOText",
                    instance: 1,
                    relativePos: 1,
                    pattern: "Customer Escalation Defect DPMO",
                    transform: "value.split(' | ')[1]"
                },
                {
                    key: "team",
                    instance: 1,
                    relativePos: 1,
                    pattern: "Team:"
                },
                {
                    key: "highPerformersShare",
                    instance: 1,
                    relativePos: 1,                   
                    pattern: "High Performers Share",
                    transform: "value.split(' | ')[0]"
                },
                {
                    key: "highPerformersShareText",
                    instance: 1,
                    relativePos: 1,
                    pattern: "High Performers Share",
                    transform: "value.split(' | ')[1]"
                },
                {
                    key: "lowPerformersShare",
                    instance: 1,
                    relativePos: 1,                   
                    pattern: "Low Performers Share",
                    transform: "value.split(' | ')[0]"
                },
                {
                    key: "lowPerformersShareText",
                    instance: 1,
                    relativePos: 1,
                    pattern: "Low Performers Share",
                    transform: "value.split(' | ')[1]"
                },
                {
                    key: "attritionRate",
                    instance: 1,
                    relativePos: 1,                    
                    pattern: "Attrition Rate [4 week trailing]",
                    transform: "value.split(' | ')[0]"
                },
                {
                    key: "attritionRateText",
                    instance: 1,
                    relativePos: 1,
                    pattern: "Attrition Rate [4 week trailing]",
                    transform: "value.split(' | ')[1]"
                },              
                {
                    key: "quality",
                    instance: 1,
                    relativePos: 1,
                    pattern: "Quality:"               
                },
                {
                    key: "deliveryCompletion",
                    instance: 1,
                    relativePos: 1,                    
                    pattern: "Delivery Completion Rate",
                    transform: "value.split(' | ')[0]"
                },
                {
                    key: "deliveryCompletionText",
                    instance: 1,
                    relativePos: 1,
                    pattern: "Delivery Completion Rate",
                    transform: "value.split(' | ')[1]"
                },  
                {
                    key: "deliveredAndReceived",
                    instance: 1,
                    relativePos: 1,                    
                    pattern: "Delivered and Received",
                    transform: "value.split(' | ')[0]"
                },
                {
                    key: "deliveredAndReceivedText",
                    instance: 1,
                    relativePos: 1,
                    pattern: "Delivered and Received",
                    transform: "value.split(' | ')[1]"
                },
                {
                    key: "standardWorkComplianceText",
                    instance: 1,
                    relativePos: 1,
                    pattern: "Standard Work Compliance"
                },
                {
                    key: "photoOnDelivery",
                    instance: 1,
                    relativePos: 1,
                    pattern: "Photo-On-Delivery",
                    transform: "value.split(' | ')[0]"
                },
                {
                    key: "photoOnDeliveryText",
                    instance: 1,
                    relativePos: 1,
                    pattern: "Photo-On-Delivery",
                    transform: "value.split(' | ')[1]"
                },
                {
                    key: "contactCompliance",
                    instance: 1,
                    relativePos: 1,                    
                    pattern: "Contact Compliance",
                    transform: "value.split(' | ')[0]"
                },
                {
                    key: "contactComplianceText",
                    instance: 1,
                    relativePos: 1,
                    pattern: "Contact Compliance",
                    transform: "value.split(' | ')[1]"
                },
                {
                    key: "scanCompliance",
                    instance: 1,
                    relativePos: 1,
                    pattern: "Scan Compliance",
                    transform: "value.split(' | ')[0]"
                },
                {
                    key: "scanComplianceText",
                    instance: 1,
                    relativePos: 1,
                    pattern: "Scan Compliance",
                    transform: "value.split(' | ')[1]"
                },
                {
                    key: "attendedDeliveryAccuracy",
                    instance: 1,
                    relativePos: 1,                    
                    pattern: "Attended Delivery Accuracy",
                    transform: "value.split(' | ')[0]"
                },
                {
                    key: "attendedDeliveryAccuracyText",
                    instance: 1,
                    relativePos: 1,
                    pattern: "Attended Delivery Accuracy",
                    transform: "value.split(' | ')[1]"
                },
                {
                    key: 'signSignalViolationsRate',
                    instance: 1, 
                    relativePos: 1, 
                    pattern: "Sign/Signal Violations Rate",
                    transform: "value.split( ' | ')[0]"
                },        
                {
                    key: 'signSignalViolationsRateText',
                    instance: 1, 
                    relativePos: 1, 
                    pattern: "Sign/Signal Violations Rate",
                    transform: "value.split( ' | ')[1]"
                },        
                {
                    key: 'distractionsRate',
                    instance: 1, 
                    relativePos: 1, 
                    pattern: "Distractions Rate",
                    transform: "value.split( ' | ')[0]"
                },        
                {
                    key: 'distractionsRateText',
                    instance: 1, 
                    relativePos: 1, 
                    pattern: "Distractions Rate",
                    transform: "value.split( ' | ')[1]"
                },        
                {
                    key: 'followingDistanceRate',
                    instance: 1, 
                    relativePos: 1, 
                    pattern: "Following Distance Rate",
                    transform: "value.split( ' | ')[0]"
                },        
                {
                    key: 'followingDistanceRateText',
                    instance: 1, 
                    relativePos: 1, 
                    pattern: "Following Distance Rate",
                    transform: "value.split( ' | ')[1]"
                }        
    
            ],
            tables: [
                {
                    table_pattern:[
                        "drivers_ranked_by_overall_score__descending",
                        "#"
                     ],
                    table_match_percent: 1,
                    table_data:[],
                    table_end_string: "Drivers ranked by overall score, descending.",
                    min_data: 8,
                    columns: [
                        { 
                            slug: "name",
                            left: 0.022,
                            right: 0.117
                        },
                        { 
                            slug: "transporter_id",
                            left: 0.117,
                            right: 0.213
                        },
                        { 
                            slug: "overal_tier",
                            left: 0.213,
                            right: 0.261
                        },
                        { 
                            slug: "delivered",
                            left: 0.261,
                            right: 0.304,
                        },
                        { 
                            slug: "key_focus_area",
                            left: 0.304,
                            right: 0.339
                        },
                        { 
                            slug: "fico_score",
                            left: 0.339,
                            right: 0.389,
                        },
                        { 
                            slug: "seatbelt_off_rate",
                            left: 0.389,
                            right: 0.438,
                        },
                        { 
                            slug: "speeding_event_rate",
                            left: 0.438,
                            right: 0.486,
                        },
                        { 
                            slug: "distractions_rate",
                            left: 0.486,
                            right: 0.540,
                        },
                        { 
                            slug: "following_distance_rate",
                            left: 0.540,
                            right: 0.593,
                        },
                        { 
                            slug: "sign_signal_violations_rate",
                            left: 0.593,
                            right: 0.656,
                        },
                        { 
                            slug: "dcr",
                            left: 0.656,
                            right:0.696,
                        },
                        { 
                            slug: "dar",
                            left: 0.696,
                            right: 0.736,
                        },
                        { 
                            slug: "swc_pod",
                            left: 0.736,
                            right: 0.775,
                        },
                        { 
                            slug: "swc_cc",
                            left: 0.775,
                            right: 0.811,
                        },
                        { 
                            slug: "swc_sc",
                            left: 0.811,
                            right: 0.848,
                        },
                        { 
                            slug: "swc_ad",
                            left: 0.848,
                            right: 0.880,
                        },
                        { 
                            slug: "dnrs",
                            left: 0.880,
                            right: 0.923,
                        },
                        { 
                            slug: "pod_opps",
                            left: 0.923,
                            right: 0.959,
                        },
                        { 
                            slug: "cc_opps",
                            left: 0.959,
                            right: 1.0,
                        }
                    ]
                },
                {
                    table_pattern:[
                            "drivers_ranked_by_overall_score__descending",
                            "fant"
                    ],
                    table_match_percent: 1,
                    table_data:[],
                    min_data: 12,
                    columns: [
                        {
                            slug: "name",
                            left: 0.05198065936565399,
                            right:0.17115876078605652
                        },
                        {
                            slug: "transporter_id",
                            left: 0.17115876078605652,
                            right: 0.29660940170288086
                        },
                        {
                            slug: "average_tier",
                            left: 0.29660940170288086,
                            right: 0.34888049960136414
                        },
                        {
                            slug: "dcr",
                            left: 0.34888049960136414,
                            right: 0.40742412209510803
                        },
                        {
                            slug: "dar",
                            left: 0.40742412209510803,
                            right: 0.46596774458885193
                        },
                        {
                            slug: "swc_pod",
                            left: 0.46596774458885193,
                            right: 0.5224205255508423
                        },
                        {
                            slug: "swc_cc",
                            left: 0.5224205255508423,
                            right: 0.5809641480445862
                        },
                        {
                            slug: "swc_sc",
                            left: 0.5809641480445862,
                            right: 0.6395078301429749
                        },
                        {
                            slug: "swc_ad",
                            left: 0.6395078301429749,
                            right: 0.6959605813026428
                        },
                        {
                            slug: "high_low_performer",
                            left: 0.6959605813026428,
                            right: 0.7984119653701782
                        },
                        {
                            slug: "fant",
                            left: 0.798411965370178,
                            right: 0.8318654298782349
                        },
                        {
                            slug: "great",
                            left: 0.8318654298782349,
                            right: 0.8674097657203674
                        },
                        {
                            slug: "fair",
                            left: 0.8674097657203674,
                            right: 0.8987724781036377
                        },
                        {
                            slug: "poor",
                            left: 0.8987724781036377,
                            right: 0.9426801800727844
                        },
                    ]
                }
            ]
        },
        {
            start: 202105,   
            startParsingAfter: ['Driver Swag', 'Questions?'],   
            fields: [
                {
                    key: "week",
                    instance: 1,
                    relativePos: 0,
                    regex: "(^Week) (\\d\\d?)$",   
                    regexGroup: 1,       
                    pattern: "Week",
                    transform: "patternMatches[2]",           
                },
                {
                    key: "year",
                    instance: 1,
                    relativePos: 1,
                    regex: "(^Week) (\\d\\d?)$",  
                    regexGroup: 1,      
                    pattern: "Week",
                },
                {
                    key: "overall",
                    instance: 1,
                    relativePos: 1,
                    pattern: "Overall Standing:"
                },
                {
                    key: "safetyAndCompliance",
                    instance: 1,
                    relativePos: 1,
                    pattern: "Safety and Compliance:"            
                },
                {
                    key: "comprehensiveAudit",
                    instance: 1,
                    relativePos: 1,
                    pattern: "Comprehensive Audit:"            
                },
                {
                    key: "safetyScore",
                    instance: 1,
                    relativePos: 1,
                    minPage: 2,
                    pattern: "Safety Score*"
                },
                {
                    key: "safeDriving",
                    instance: 1,
                    relativePos: 0,        
                    regex: "(\\d.\\d\\d) \\((\\d\\d\\d) (FICO)\\) \\| (\\w+)", 
                    regexGroup: 3,          
                    pattern: "FICO",
                    transform: "patternMatches[1]"
                },
                {
                    key: "safeDrivingText",
                    instance: 1,
                    relativePos: 0,        
                    regex: "(\\d.\\d\\d) \\((\\d\\d\\d) (FICO)\\) \\| (\\w+)", 
                    regexGroup: 3,          
                    pattern: "FICO",
                    transform: "patternMatches[4]"
                },
                {
                    key: "fico",
                    instance: 1,
                    relativePos: 0,
                    regex: "\((\\d\\d\\d) (FICO)\)",
                    regexGroup: 2,
                    pattern: "FICO",
                    transform: "parseInt(value.replace(/[^0-9]/g, ''))"
                },
                {
                    key: "seatbeltOff",
                    instance: 1,
                    relativePos: 1,
                    minPage: 2,                    
                    pattern: "Seatbelt-Off Rate",
                    transform: "value.split(' | ')[0]"
                },
                {
                    key: "seatbeltOffText",
                    instance: 1,
                    relativePos: 1,
                    minPage: 2,
                    pattern: "Seatbelt-Off Rate",
                    transform: "value.split(' | ')[1]"
                },
                {
                    key: "speedingEvent",
                    instance: 1,
                    relativePos: 1,
                    minPage: 2,                    
                    pattern: "Speeding Event Rate",
                    transform: "value.split(' | ')[0]"
                },
                {
                    key: "speedingEventText",
                    instance: 1,
                    relativePos: 1,
                    minPage: 2,
                    pattern: "Speeding Event Rate",
                    transform: "value.split(' | ')[1]"
                },
                {
                    key: "dvcrCompliance",
                    instance: 1,
                    relativePos: 1,                   
                    pattern: "DVCR Compliance",
                    transform: "value.split(' | ')[0]"
                },
                {
                    key: "dvcrComplianceText",
                    instance: 1,
                    relativePos: 1,
                    pattern: "DVCR Compliance",
                    transform: "value.split(' | ')[1]"
                },
                {
                    key: "onTimeCompliance",
                    instance: 1,
                    relativePos: 1,                    
                    pattern: "On-Time PM Compliance",
                    transform: "value.split(' | ')[0]"
                },
                {
                    key: "onTimeComplianceText",
                    instance: 1,
                    relativePos: 1,
                    pattern: "On-Time PM Compliance",
                    transform: "value.split(' | ')[1]"
                },
                {
                    key: "complianceScoreText",
                    instance: 1,
                    relativePos: 1,
                    pattern: "Compliance Score"
                },
                {
                    key: "workingHoursCompliance",
                    instance: 1,
                    relativePos: 1,                    
                    pattern: "Working Hours Compliance (WHC)",
                    transform: "value.split(' | ')[0]"
                },
                {
                    key: "workingHoursComplianceText",
                    instance: 1,
                    relativePos: 1,
                    pattern: "Working Hours Compliance (WHC)",
                    transform: "value.split(' | ')[1]"
                },
                {
                    key: "customerEscalationDefectDPMO",
                    instance: 1,
                    relativePos: 1,                    
                    pattern: "Customer Escalation Defect DPMO",
                    transform: "value.split(' | ')[0]"
                },
                {
                    key: "customerEscalationDefectDPMOText",
                    instance: 1,
                    relativePos: 1,
                    pattern: "Customer Escalation Defect DPMO",
                    transform: "value.split(' | ')[1]"
                },
                {
                    key: "team",
                    instance: 1,
                    relativePos: 1,
                    pattern: "Team:"
                },
                {
                    key: "highPerformersShare",
                    instance: 1,
                    relativePos: 1,                   
                    pattern: "High Performers Share",
                    transform: "value.split(' | ')[0]"
                },
                {
                    key: "highPerformersShareText",
                    instance: 1,
                    relativePos: 1,
                    pattern: "High Performers Share",
                    transform: "value.split(' | ')[1]"
                },
                {
                    key: "lowPerformersShare",
                    instance: 1,
                    relativePos: 1,                   
                    pattern: "Low Performers Share",
                    transform: "value.split(' | ')[0]"
                },
                {
                    key: "lowPerformersShareText",
                    instance: 1,
                    relativePos: 1,
                    pattern: "Low Performers Share",
                    transform: "value.split(' | ')[1]"
                },
                {
                    key: "attritionRate",
                    instance: 1,
                    relativePos: 1,                    
                    pattern: "Attrition Rate [4 week trailing]",
                    transform: "value.split(' | ')[0]"
                },
                {
                    key: "attritionRateText",
                    instance: 1,
                    relativePos: 1,
                    pattern: "Attrition Rate [4 week trailing]",
                    transform: "value.split(' | ')[1]"
                },              
                {
                    key: "quality",
                    instance: 1,
                    relativePos: 1,
                    pattern: "Quality:"               
                },
                {
                    key: "deliveryCompletion",
                    instance: 1,
                    relativePos: 1,                    
                    pattern: "Delivery Completion Rate",
                    transform: "value.split(' | ')[0]"
                },
                {
                    key: "deliveryCompletionText",
                    instance: 1,
                    relativePos: 1,
                    pattern: "Delivery Completion Rate",
                    transform: "value.split(' | ')[1]"
                },  
                {
                    key: "deliveredAndReceived",
                    instance: 1,
                    relativePos: 1,                    
                    pattern: "Delivered and Received",
                    transform: "value.split(' | ')[0]"
                },
                {
                    key: "deliveredAndReceivedText",
                    instance: 1,
                    relativePos: 1,
                    pattern: "Delivered and Received",
                    transform: "value.split(' | ')[1]"
                },
                {
                    key: "standardWorkComplianceText",
                    instance: 1,
                    relativePos: 1,
                    pattern: "Standard Work Compliance"
                },
                {
                    key: "photoOnDelivery",
                    instance: 1,
                    relativePos: 1,
                    pattern: "Photo-On-Delivery",
                    transform: "value.split(' | ')[0]"
                },
                {
                    key: "photoOnDeliveryText",
                    instance: 1,
                    relativePos: 1,
                    pattern: "Photo-On-Delivery",
                    transform: "value.split(' | ')[1]"
                },
                {
                    key: "contactCompliance",
                    instance: 1,
                    relativePos: 1,                    
                    pattern: "Contact Compliance",
                    transform: "value.split(' | ')[0]"
                },
                {
                    key: "contactComplianceText",
                    instance: 1,
                    relativePos: 1,
                    pattern: "Contact Compliance",
                    transform: "value.split(' | ')[1]"
                },
                {
                    key: "scanCompliance",
                    instance: 1,
                    relativePos: 1,
                    pattern: "Scan Compliance",
                    transform: "value.split(' | ')[0]"
                },
                {
                    key: "scanComplianceText",
                    instance: 1,
                    relativePos: 1,
                    pattern: "Scan Compliance",
                    transform: "value.split(' | ')[1]"
                },
                {
                    key: "attendedDeliveryAccuracy",
                    instance: 1,
                    relativePos: 1,                    
                    pattern: "Attended Delivery Accuracy",
                    transform: "value.split(' | ')[0]"
                },
                {
                    key: "attendedDeliveryAccuracyText",
                    instance: 1,
                    relativePos: 1,
                    pattern: "Attended Delivery Accuracy",
                    transform: "value.split(' | ')[1]"
                }            
    
            ],
            tables: [
                {
                    table_pattern:[
                        "drivers_ranked_by_overall_score__descending",
                        "#"
                     ],
                    table_match_percent: 1,
                    table_data:[],
                    table_end_string: "Drivers ranked by overall score, descending.",
                    min_data: 12,
                    columns: [
                        { 
                            slug: "name",
                            left: 0.022,
                            right: 0.115
                        },
                        { 
                            slug: "transporter_id",
                            left: 0.115,
                            right: 0.208
                        },
                        { 
                            slug: "overal_tier",
                            left: 0.208,
                            right: 0.254
                        },
                        { 
                            slug: "delivered",
                            left: 0.254,
                            right: 0.297,
                        },
                        { 
                            slug: "key_focus_area",
                            left: 0.297,
                            right: 0.405
                        },
                        { 
                            slug: "fico_score",
                            left: 0.405,
                            right: 0.472,
                        },
                        { 
                            slug: "seatbelt_off_rate",
                            left: 0.472,
                            right: 0.533,
                        },
                        { 
                            slug: "speeding_event_rate",
                            left: 0.533,
                            right: 0.593,
                        },
                        { 
                            slug: "dcr",
                            left: 0.593,
                            right:0.640,
                        },
                        { 
                            slug: "dar",
                            left: 0.640,
                            right: 0.687,
                        },
                        { 
                            slug: "swc_pod",
                            left: 0.687,
                            right: 0.734,
                        },
                        { 
                            slug: "swc_cc",
                            left: 0.734,
                            right: 0.781,
                        },
                        { 
                            slug: "swc_sc",
                            left: 0.781,
                            right: 0.828,
                        },
                        { 
                            slug: "swc_ad",
                            left: 0.828,
                            right: 0.875,
                        },
                        { 
                            slug: "dnrs",
                            left: 0.875,
                            right: 0.919,
                        },
                        { 
                            slug: "pod_opps",
                            left: 0.919,
                            right: 0.957,
                        },
                        { 
                            slug: "cc_opps",
                            left: 0.957,
                            right: 1.0,
                        }
                    ]
                },
                {
                    table_pattern:[
                            "drivers_ranked_by_overall_score__descending",
                            "fant"
                    ],
                    table_match_percent: 1,
                    table_data:[],
                    min_data: 12,
                    columns: [
                        {
                            slug: "name",
                            left: 0.05198065936565399,
                            right:0.17115876078605652
                        },
                        {
                            slug: "transporter_id",
                            left: 0.17115876078605652,
                            right: 0.29660940170288086
                        },
                        {
                            slug: "average_tier",
                            left: 0.29660940170288086,
                            right: 0.34888049960136414
                        },
                        {
                            slug: "dcr",
                            left: 0.34888049960136414,
                            right: 0.40742412209510803
                        },
                        {
                            slug: "dar",
                            left: 0.40742412209510803,
                            right: 0.46596774458885193
                        },
                        {
                            slug: "swc_pod",
                            left: 0.46596774458885193,
                            right: 0.5224205255508423
                        },
                        {
                            slug: "swc_cc",
                            left: 0.5224205255508423,
                            right: 0.5809641480445862
                        },
                        {
                            slug: "swc_sc",
                            left: 0.5809641480445862,
                            right: 0.6395078301429749
                        },
                        {
                            slug: "swc_ad",
                            left: 0.6395078301429749,
                            right: 0.6959605813026428
                        },
                        {
                            slug: "high_low_performer",
                            left: 0.6959605813026428,
                            right: 0.7984119653701782
                        },
                        {
                            slug: "fant",
                            left: 0.798411965370178,
                            right: 0.8318654298782349
                        },
                        {
                            slug: "great",
                            left: 0.8318654298782349,
                            right: 0.8674097657203674
                        },
                        {
                            slug: "fair",
                            left: 0.8674097657203674,
                            right: 0.8987724781036377
                        },
                        {
                            slug: "poor",
                            left: 0.8987724781036377,
                            right: 0.9426801800727844
                        },
                    ]
                }
            ]
        },
        {
            start: 202044,   
            startParsingAfter: "Questions?",   
            fields: [
                {
                    key: "week",
                    instance: 1,
                    relativePos: 0,
                    regex: "(^Week) (\\d\\d?)$",   
                    regexGroup: 1,       
                    pattern: "Week",
                    transform: "patternMatches[2]",           
                },
                {
                    key: "year",
                    instance: 1,
                    relativePos: 1,
                    regex: "(^Week) (\\d\\d?)$",  
                    regexGroup: 1,      
                    pattern: "Week",
                },
                {
                    key: "overall",
                    instance: 1,
                    relativePos: 1,
                    pattern: "Overall Standing:"
                },
                {
                    key: "safetyAndCompliance",
                    instance: 1,
                    relativePos: 1,
                    pattern: "Safety and Compliance:"            
                },
                {
                    key: "comprehensiveAudit",
                    instance: 1,
                    relativePos: 1,
                    pattern: "Comprehensive Audit:"            
                },
                {
                    key: "safetyScore",
                    instance: 1,
                    relativePos: 1,
                    minPage: 2,
                    pattern: "Safety Score*"
                },
                {
                    key: "safeDriving",
                    instance: 1,
                    relativePos: 0,        
                    regex: "(\\d.\\d\\d) \\((\\d\\d\\d) (FICO)\\) \\| (\\w+)", 
                    regexGroup: 3,          
                    pattern: "FICO",
                    transform: "patternMatches[1]"
                },
                {
                    key: "safeDrivingText",
                    instance: 1,
                    relativePos: 0,        
                    regex: "(\\d.\\d\\d) \\((\\d\\d\\d) (FICO)\\) \\| (\\w+)", 
                    regexGroup: 3,          
                    pattern: "FICO",
                    transform: "patternMatches[4]"
                },
                {
                    key: "fico",
                    instance: 1,
                    relativePos: 0,
                    regex: "\((\\d\\d\\d) (FICO)\)",
                    regexGroup: 2,
                    pattern: "FICO",
                    transform: "parseInt(value.replace(/[^0-9]/g, ''))"
                },
                {
                    key: "seatbeltOff",
                    instance: 1,
                    relativePos: 1,
                    minPage: 2,                    
                    pattern: "Seatbelt-Off Rate",
                    transform: "value.split(' | ')[0]"
                },
                {
                    key: "seatbeltOffText",
                    instance: 1,
                    relativePos: 1,
                    minPage: 2,
                    pattern: "Seatbelt-Off Rate",
                    transform: "value.split(' | ')[1]"
                },
                {
                    key: "speedingEvent",
                    instance: 1,
                    relativePos: 1,
                    minPage: 2,                    
                    pattern: "Speeding Event Rate",
                    transform: "value.split(' | ')[0]"
                },
                {
                    key: "speedingEventText",
                    instance: 1,
                    relativePos: 1,
                    minPage: 2,
                    pattern: "Speeding Event Rate",
                    transform: "value.split(' | ')[1]"
                },
                {
                    key: "dvcrCompliance",
                    instance: 1,
                    relativePos: 1,                   
                    pattern: "DVCR Compliance",
                    transform: "value.split(' | ')[0]"
                },
                {
                    key: "dvcrComplianceText",
                    instance: 1,
                    relativePos: 1,
                    pattern: "DVCR Compliance",
                    transform: "value.split(' | ')[1]"
                },
                {
                    key: "onTimeCompliance",
                    instance: 1,
                    relativePos: 1,                    
                    pattern: "On-Time PM Compliance",
                    transform: "value.split(' | ')[0]"
                },
                {
                    key: "onTimeComplianceText",
                    instance: 1,
                    relativePos: 1,
                    pattern: "On-Time PM Compliance",
                    transform: "value.split(' | ')[1]"
                },
                {
                    key: "complianceScoreText",
                    instance: 1,
                    relativePos: 1,
                    pattern: "Compliance Score"
                },
                {
                    key: "workingHoursCompliance",
                    instance: 1,
                    relativePos: 1,                    
                    pattern: "Working Hours Compliance (WHC)",
                    transform: "value.split(' | ')[0]"
                },
                {
                    key: "workingHoursComplianceText",
                    instance: 1,
                    relativePos: 1,
                    pattern: "Working Hours Compliance (WHC)",
                    transform: "value.split(' | ')[1]"
                },
                {
                    key: "customerEscalationDefectDPMO",
                    instance: 1,
                    relativePos: 1,                    
                    pattern: "Customer Escalation Defect DPMO",
                    transform: "value.split(' | ')[0]"
                },
                {
                    key: "customerEscalationDefectDPMOText",
                    instance: 1,
                    relativePos: 1,
                    pattern: "Customer Escalation Defect DPMO",
                    transform: "value.split(' | ')[1]"
                },
                {
                    key: "team",
                    instance: 1,
                    relativePos: 1,
                    pattern: "Team:"
                },
                {
                    key: "highPerformersShare",
                    instance: 1,
                    relativePos: 1,                   
                    pattern: "High Performers Share",
                    transform: "value.split(' | ')[0]"
                },
                {
                    key: "highPerformersShareText",
                    instance: 1,
                    relativePos: 1,
                    pattern: "High Performers Share",
                    transform: "value.split(' | ')[1]"
                },
                {
                    key: "lowPerformersShare",
                    instance: 1,
                    relativePos: 1,                   
                    pattern: "Low Performers Share",
                    transform: "value.split(' | ')[0]"
                },
                {
                    key: "lowPerformersShareText",
                    instance: 1,
                    relativePos: 1,
                    pattern: "Low Performers Share",
                    transform: "value.split(' | ')[1]"
                },
                {
                    key: "attritionRate",
                    instance: 1,
                    relativePos: 1,                    
                    pattern: "Attrition Rate [4 week trailing]",
                    transform: "value.split(' | ')[0]"
                },
                {
                    key: "attritionRateText",
                    instance: 1,
                    relativePos: 1,
                    pattern: "Attrition Rate [4 week trailing]",
                    transform: "value.split(' | ')[1]"
                },              
                {
                    key: "quality",
                    instance: 1,
                    relativePos: 1,
                    pattern: "Quality:"               
                },
                {
                    key: "deliveryCompletion",
                    instance: 1,
                    relativePos: 1,                    
                    pattern: "Delivery Completion Rate",
                    transform: "value.split(' | ')[0]"
                },
                {
                    key: "deliveryCompletionText",
                    instance: 1,
                    relativePos: 1,
                    pattern: "Delivery Completion Rate",
                    transform: "value.split(' | ')[1]"
                },  
                {
                    key: "deliveredAndReceived",
                    instance: 1,
                    relativePos: 1,                    
                    pattern: "Delivered and Received",
                    transform: "value.split(' | ')[0]"
                },
                {
                    key: "deliveredAndReceivedText",
                    instance: 1,
                    relativePos: 1,
                    pattern: "Delivered and Received",
                    transform: "value.split(' | ')[1]"
                },
                {
                    key: "standardWorkComplianceText",
                    instance: 1,
                    relativePos: 1,
                    pattern: "Standard Work Compliance"
                },
                {
                    key: "photoOnDelivery",
                    instance: 1,
                    relativePos: 1,
                    pattern: "Photo-On-Delivery",
                    transform: "value.split(' | ')[0]"
                },
                {
                    key: "photoOnDeliveryText",
                    instance: 1,
                    relativePos: 1,
                    pattern: "Photo-On-Delivery",
                    transform: "value.split(' | ')[1]"
                },
                {
                    key: "contactCompliance",
                    instance: 1,
                    relativePos: 1,                    
                    pattern: "Contact Compliance",
                    transform: "value.split(' | ')[0]"
                },
                {
                    key: "contactComplianceText",
                    instance: 1,
                    relativePos: 1,
                    pattern: "Contact Compliance",
                    transform: "value.split(' | ')[1]"
                },
                {
                    key: "scanCompliance",
                    instance: 1,
                    relativePos: 1,
                    pattern: "Scan Compliance",
                    transform: "value.split(' | ')[0]"
                },
                {
                    key: "scanComplianceText",
                    instance: 1,
                    relativePos: 1,
                    pattern: "Scan Compliance",
                    transform: "value.split(' | ')[1]"
                },
                {
                    key: "attendedDeliveryAccuracy",
                    instance: 1,
                    relativePos: 1,                    
                    pattern: "Attended Delivery Accuracy",
                    transform: "value.split(' | ')[0]"
                },
                {
                    key: "attendedDeliveryAccuracyText",
                    instance: 1,
                    relativePos: 1,
                    pattern: "Attended Delivery Accuracy",
                    transform: "value.split(' | ')[1]"
                }            
    
            ],
            tables: [
                {
                    table_pattern:[
                        "drivers_ranked_by_overall_score__descending",
                        "#"
                     ],
                    table_match_percent: 1,
                    table_data:[],
                    table_end_string: "Drivers ranked by overall score, descending.",
                    min_data: 12,
                    columns: [
                        { 
                            slug: "name",
                            left: 0.022,
                            right: 0.115
                        },
                        { 
                            slug: "transporter_id",
                            left: 0.115,
                            right: 0.208
                        },
                        { 
                            slug: "overal_tier",
                            left: 0.208,
                            right: 0.254
                        },
                        { 
                            slug: "delivered",
                            left: 0.254,
                            right: 0.297,
                        },
                        { 
                            slug: "key_focus_area",
                            left: 0.297,
                            right: 0.405
                        },
                        { 
                            slug: "fico_score",
                            left: 0.405,
                            right: 0.472,
                        },
                        { 
                            slug: "seatbelt_off_rate",
                            left: 0.472,
                            right: 0.533,
                        },
                        { 
                            slug: "speeding_event_rate",
                            left: 0.533,
                            right: 0.593,
                        },
                        { 
                            slug: "dcr",
                            left: 0.593,
                            right:0.640,
                        },
                        { 
                            slug: "dar",
                            left: 0.640,
                            right: 0.687,
                        },
                        { 
                            slug: "swc_pod",
                            left: 0.687,
                            right: 0.734,
                        },
                        { 
                            slug: "swc_cc",
                            left: 0.734,
                            right: 0.781,
                        },
                        { 
                            slug: "swc_sc",
                            left: 0.781,
                            right: 0.828,
                        },
                        { 
                            slug: "swc_ad",
                            left: 0.828,
                            right: 0.875,
                        },
                        { 
                            slug: "dnrs",
                            left: 0.875,
                            right: 0.919,
                        },
                        { 
                            slug: "pod_opps",
                            left: 0.919,
                            right: 0.957,
                        },
                        { 
                            slug: "cc_opps",
                            left: 0.957,
                            right: 1.0,
                        }
                    ]
                },
                {
                    table_pattern:[
                            "drivers_ranked_by_overall_score__descending",
                            "fant"
                    ],
                    table_match_percent: 1,
                    table_data:[],
                    min_data: 12,
                    columns: [
                        {
                            slug: "name",
                            left: 0.05198065936565399,
                            right:0.17115876078605652
                        },
                        {
                            slug: "transporter_id",
                            left: 0.17115876078605652,
                            right: 0.29660940170288086
                        },
                        {
                            slug: "average_tier",
                            left: 0.29660940170288086,
                            right: 0.34888049960136414
                        },
                        {
                            slug: "dcr",
                            left: 0.34888049960136414,
                            right: 0.40742412209510803
                        },
                        {
                            slug: "dar",
                            left: 0.40742412209510803,
                            right: 0.46596774458885193
                        },
                        {
                            slug: "swc_pod",
                            left: 0.46596774458885193,
                            right: 0.5224205255508423
                        },
                        {
                            slug: "swc_cc",
                            left: 0.5224205255508423,
                            right: 0.5809641480445862
                        },
                        {
                            slug: "swc_sc",
                            left: 0.5809641480445862,
                            right: 0.6395078301429749
                        },
                        {
                            slug: "swc_ad",
                            left: 0.6395078301429749,
                            right: 0.6959605813026428
                        },
                        {
                            slug: "high_low_performer",
                            left: 0.6959605813026428,
                            right: 0.7984119653701782
                        },
                        {
                            slug: "fant",
                            left: 0.798411965370178,
                            right: 0.8318654298782349
                        },
                        {
                            slug: "great",
                            left: 0.8318654298782349,
                            right: 0.8674097657203674
                        },
                        {
                            slug: "fair",
                            left: 0.8674097657203674,
                            right: 0.8987724781036377
                        },
                        {
                            slug: "poor",
                            left: 0.8987724781036377,
                            right: 0.9426801800727844
                        },
                    ]
                }
            ]
        },
        {
            start: 202043,   
            startParsingAfter: "Questions?",   
            fields: [
                {
                    key: "week",
                    instance: 1,
                    relativePos: 0,
                    regex: "(^Week) (\\d\\d?)$",   
                    regexGroup: 1,       
                    pattern: "Week",
                    transform: "patternMatches[2]",           
                },
                {
                    key: "year",
                    instance: 1,
                    relativePos: 1,
                    regex: "(^Week) (\\d\\d?)$",  
                    regexGroup: 1,      
                    pattern: "Week",
                },
                {
                    key: "overall",
                    instance: 1,
                    relativePos: 1,
                    pattern: "Overall Standing:"
                },
                {
                    key: "safetyAndCompliance",
                    instance: 1,
                    relativePos: 1,
                    pattern: "Safety and Compliance:"            
                },
                {
                    key: "comprehensiveAudit",
                    instance: 1,
                    relativePos: 1,
                    pattern: "Comprehensive Audit:"            
                },
                {
                    key: "safetyScore",
                    instance: 1,
                    relativePos: 1,
                    minPage: 2,
                    pattern: "Safety Score*"
                },
                {
                    key: "safeDriving",
                    instance: 1,
                    relativePos: 0,        
                    regex: "(\\d.\\d\\d) \\((\\d\\d\\d) (FICO)\\) \\| (\\w+)", 
                    regexGroup: 3,          
                    pattern: "FICO",
                    transform: "patternMatches[1]"
                },
                {
                    key: "safeDrivingText",
                    instance: 1,
                    relativePos: 0,        
                    regex: "(\\d.\\d\\d) \\((\\d\\d\\d) (FICO)\\) \\| (\\w+)", 
                    regexGroup: 3,          
                    pattern: "FICO",
                    transform: "patternMatches[4]"
                },
                {
                    key: "fico",
                    instance: 1,
                    relativePos: 0,
                    regex: "\((\\d\\d\\d) (FICO)\)",
                    regexGroup: 2,
                    pattern: "FICO",
                    transform: "parseInt(value.replace(/[^0-9]/g, ''))"
                },
                {
                    key: "seatbeltOff",
                    instance: 1,
                    relativePos: 1,
                    minPage: 2,                    
                    pattern: "Seatbelt-Off Rate",
                    transform: "value.split(' | ')[0]"
                },
                {
                    key: "seatbeltOffText",
                    instance: 1,
                    relativePos: 1,
                    minPage: 2,
                    pattern: "Seatbelt-Off Rate",
                    transform: "value.split(' | ')[1]"
                },
                {
                    key: "speedingEvent",
                    instance: 1,
                    relativePos: 1,
                    minPage: 2,                    
                    pattern: "Speeding Event Rate",
                    transform: "value.split(' | ')[0]"
                },
                {
                    key: "speedingEventText",
                    instance: 1,
                    relativePos: 1,
                    minPage: 2,
                    pattern: "Speeding Event Rate",
                    transform: "value.split(' | ')[1]"
                },
                {
                    key: "dvcrCompliance",
                    instance: 1,
                    relativePos: 1,                   
                    pattern: "DVCR Compliance",
                    transform: "value.split(' | ')[0]"
                },
                {
                    key: "dvcrComplianceText",
                    instance: 1,
                    relativePos: 1,
                    pattern: "DVCR Compliance",
                    transform: "value.split(' | ')[1]"
                },
                {
                    key: "onTimeCompliance",
                    instance: 1,
                    relativePos: 1,                    
                    pattern: "On-Time PM Compliance",
                    transform: "value.split(' | ')[0]"
                },
                {
                    key: "onTimeComplianceText",
                    instance: 1,
                    relativePos: 1,
                    pattern: "On-Time PM Compliance",
                    transform: "value.split(' | ')[1]"
                },
                {
                    key: "complianceScoreText",
                    instance: 1,
                    relativePos: 1,
                    pattern: "Compliance Score"
                },
                {
                    key: "workingHoursCompliance",
                    instance: 1,
                    relativePos: 1,                    
                    pattern: "Working Hours Compliance (WHC)",
                    transform: "value.split(' | ')[0]"
                },
                {
                    key: "workingHoursComplianceText",
                    instance: 1,
                    relativePos: 1,
                    pattern: "Working Hours Compliance (WHC)",
                    transform: "value.split(' | ')[1]"
                },
                {
                    key: "customerEscalationDefectDPMO",
                    instance: 1,
                    relativePos: 1,                    
                    pattern: "Customer Escalation Defect DPMO",
                    transform: "value.split(' | ')[0]"
                },
                {
                    key: "customerEscalationDefectDPMOText",
                    instance: 1,
                    relativePos: 1,
                    pattern: "Customer Escalation Defect DPMO",
                    transform: "value.split(' | ')[1]"
                },
                {
                    key: "team",
                    instance: 1,
                    relativePos: 1,
                    pattern: "Team:"
                },
                {
                    key: "highPerformersShare",
                    instance: 1,
                    relativePos: 1,                   
                    pattern: "High Performers Share",
                    transform: "value.split(' | ')[0]"
                },
                {
                    key: "highPerformersShareText",
                    instance: 1,
                    relativePos: 1,
                    pattern: "High Performers Share",
                    transform: "value.split(' | ')[1]"
                },
                {
                    key: "lowPerformersShare",
                    instance: 1,
                    relativePos: 1,                   
                    pattern: "Low Performers Share",
                    transform: "value.split(' | ')[0]"
                },
                {
                    key: "lowPerformersShareText",
                    instance: 1,
                    relativePos: 1,
                    pattern: "Low Performers Share",
                    transform: "value.split(' | ')[1]"
                },
                {
                    key: "attritionRate",
                    instance: 1,
                    relativePos: 1,                    
                    pattern: "Attrition Rate",
                    transform: "value.split(' | ')[0]"
                },
                {
                    key: "attritionRateText",
                    instance: 1,
                    relativePos: 1,
                    pattern: "Attrition Rate",
                    transform: "value.split(' | ')[1]"
                },              
                {
                    key: "quality",
                    instance: 1,
                    relativePos: 1,
                    pattern: "Quality:"               
                },
                {
                    key: "deliveryCompletion",
                    instance: 1,
                    relativePos: 1,                    
                    pattern: "Delivery Completion Rate",
                    transform: "value.split(' | ')[0]"
                },
                {
                    key: "deliveryCompletionText",
                    instance: 1,
                    relativePos: 1,
                    pattern: "Delivery Completion Rate",
                    transform: "value.split(' | ')[1]"
                },  
                {
                    key: "deliveredAndReceived",
                    instance: 1,
                    relativePos: 1,                    
                    pattern: "Delivered and Received",
                    transform: "value.split(' | ')[0]"
                },
                {
                    key: "deliveredAndReceivedText",
                    instance: 1,
                    relativePos: 1,
                    pattern: "Delivered and Received",
                    transform: "value.split(' | ')[1]"
                },
                {
                    key: "standardWorkComplianceText",
                    instance: 1,
                    relativePos: 1,
                    pattern: "Standard Work Compliance"
                },
                {
                    key: "photoOnDelivery",
                    instance: 1,
                    relativePos: 1,
                    pattern: "Photo-On-Delivery",
                    transform: "value.split(' | ')[0]"
                },
                {
                    key: "photoOnDeliveryText",
                    instance: 1,
                    relativePos: 1,
                    pattern: "Photo-On-Delivery",
                    transform: "value.split(' | ')[1]"
                },
                {
                    key: "contactCompliance",
                    instance: 1,
                    relativePos: 1,                    
                    pattern: "Contact Compliance",
                    transform: "value.split(' | ')[0]"
                },
                {
                    key: "contactComplianceText",
                    instance: 1,
                    relativePos: 1,
                    pattern: "Contact Compliance",
                    transform: "value.split(' | ')[1]"
                },
                {
                    key: "scanCompliance",
                    instance: 1,
                    relativePos: 1,
                    pattern: "Scan Compliance",
                    transform: "value.split(' | ')[0]"
                },
                {
                    key: "scanComplianceText",
                    instance: 1,
                    relativePos: 1,
                    pattern: "Scan Compliance",
                    transform: "value.split(' | ')[1]"
                },
                {
                    key: "attendedDeliveryAccuracy",
                    instance: 1,
                    relativePos: 1,                    
                    pattern: "Attended Delivery Accuracy",
                    transform: "value.split(' | ')[0]"
                },
                {
                    key: "attendedDeliveryAccuracyText",
                    instance: 1,
                    relativePos: 1,
                    pattern: "Attended Delivery Accuracy",
                    transform: "value.split(' | ')[1]"
                }            
   
            ],
            tables: [
                {
                    table_pattern:[
                        "drivers_ranked_by_overall_score__descending",
                        "#"
                     ],
                    table_match_percent: 1,
                    table_data:[],
                    table_end_string: "Drivers ranked by overall score, descending.",
                    min_data: 12,
                    columns: [
                        { 
                            slug: "name",
                            left: 0.020,
                            right: 0.106
                        },
                        { 
                            slug: "transporter_id",
                            left: 0.106,
                            right: 0.193
                        },
                        { 
                            slug: "overal_tier",
                            left: 0.193,
                            right: 0.236
                        },
                        { 
                            slug: "delivered",
                            left: 0.236,
                            right: 0.276,
                        },
                        { 
                            slug: "key_focus_area",
                            left: 0.276,
                            right: 0.377
                        },
                        { 
                            slug: "fico_score",
                            left: 0.377,
                            right: 0.439,
                        },
                        { 
                            slug: "seatbelt_off_rate",
                            left: 0.439,
                            right: 0.495,
                        },
                        { 
                            slug: "speeding_event_rate",
                            left: 0.495,
                            right: 0.551,
                        },
                        { 
                            slug: "dcr",
                            left: 0.551,
                            right:0.595,
                        },
                        { 
                            slug: "dar",
                            left: 0.595,
                            right: 0.639,
                        },
                        { 
                            slug: "swc_pod",
                            left: 0.639,
                            right: 0.682,
                        },
                        { 
                            slug: "swc_cc",
                            left: 0.682,
                            right: 0.726,
                        },
                        { 
                            slug: "swc_sc",
                            left: 0.726,
                            right: 0.770,
                        },
                        { 
                            slug: "swc_ad",
                            left: 0.770,
                            right: 0.814,
                        },
                        { 
                            slug: "dnrs",
                            left: 0.814,
                            right: 0.855,
                        },
                        { 
                            slug: "pod_opps",
                            left: 0.855,
                            right: 0.890,
                        },
                        { 
                            slug: "cc_opps",
                            left: 0.890,
                            right: 0.925,
                        },
                        { 
                            slug: "overal_tier_considering_speeding_event_rate",
                            left: 0.925,
                            right: 1.000,
                        }
                    ]
                },
                {
                    table_pattern:[
                            "drivers_ranked_by_overall_score__descending",
                            "fant"
                    ],
                    table_match_percent: 1,
                    table_data:[],
                    min_data: 12,
                    columns: [
                        {
                            slug: "name",
                            left: 0.05198065936565399,
                            right:0.17115876078605652
                        },
                        {
                            slug: "transporter_id",
                            left: 0.17115876078605652,
                            right: 0.29660940170288086
                        },
                        {
                            slug: "average_tier",
                            left: 0.29660940170288086,
                            right: 0.34888049960136414
                        },
                        {
                            slug: "dcr",
                            left: 0.34888049960136414,
                            right: 0.40742412209510803
                        },
                        {
                            slug: "dar",
                            left: 0.40742412209510803,
                            right: 0.46596774458885193
                        },
                        {
                            slug: "swc_pod",
                            left: 0.46596774458885193,
                            right: 0.5224205255508423
                        },
                        {
                            slug: "swc_cc",
                            left: 0.5224205255508423,
                            right: 0.5809641480445862
                        },
                        {
                            slug: "swc_sc",
                            left: 0.5809641480445862,
                            right: 0.6395078301429749
                        },
                        {
                            slug: "swc_ad",
                            left: 0.6395078301429749,
                            right: 0.6959605813026428
                        },
                        {
                            slug: "high_low_performer",
                            left: 0.6959605813026428,
                            right: 0.7984119653701782
                        },
                        {
                            slug: "fant",
                            left: 0.798411965370178,
                            right: 0.8318654298782349
                        },
                        {
                            slug: "great",
                            left: 0.8318654298782349,
                            right: 0.8674097657203674
                        },
                        {
                            slug: "fair",
                            left: 0.8674097657203674,
                            right: 0.8987724781036377
                        },
                        {
                            slug: "poor",
                            left: 0.8987724781036377,
                            right: 0.9426801800727844
                        },
                    ]
                }
            ]
        },
        {
            start: 202041,   
            startParsingAfter: "Questions?",   
            fields: [
                {
                    key: "week",
                    instance: 1,
                    relativePos: 0,
                    regex: "(^Week) (\\d\\d?)$",   
                    regexGroup: 1,       
                    pattern: "Week",
                    transform: "patternMatches[2]",           
                },
                {
                    key: "year",
                    instance: 1,
                    relativePos: 1,
                    regex: "(^Week) (\\d\\d?)$",  
                    regexGroup: 1,      
                    pattern: "Week",
                },
                {
                    key: "overall",
                    instance: 1,
                    relativePos: 1,
                    pattern: "Overall Standing:"
                },
                {
                    key: "safetyAndCompliance",
                    instance: 1,
                    relativePos: 1,
                    pattern: "Safety and Compliance:"            
                },
                {
                    key: "comprehensiveAudit",
                    instance: 1,
                    relativePos: 1,
                    pattern: "Comprehensive Audit:"            
                },
                {
                    key: "safetyScore",
                    instance: 1,
                    relativePos: 1,
                    minPage: 2,
                    pattern: "Safety Score*"
                },
                {
                    key: "safeDriving",
                    instance: 1,
                    relativePos: 0,        
                    regex: "(\\d.\\d\\d) \\((\\d\\d\\d) (FICO)\\) \\| (\\w+)", 
                    regexGroup: 3,          
                    pattern: "FICO",
                    transform: "patternMatches[1]"
                },
                {
                    key: "safeDrivingText",
                    instance: 1,
                    relativePos: 0,        
                    regex: "(\\d.\\d\\d) \\((\\d\\d\\d) (FICO)\\) \\| (\\w+)", 
                    regexGroup: 3,          
                    pattern: "FICO",
                    transform: "patternMatches[4]"
                },
                {
                    key: "fico",
                    instance: 1,
                    relativePos: 0,
                    regex: "\((\\d\\d\\d) (FICO)\)",
                    regexGroup: 2,
                    pattern: "FICO",
                    transform: "parseInt(value.replace(/[^0-9]/g, ''))"
                },
                {
                    key: "seatbeltOff",
                    instance: 1,
                    relativePos: 1,
                    minPage: 2,                    
                    pattern: "Seatbelt-Off Rate",
                    transform: "value.split(' | ')[0]"
                },
                {
                    key: "seatbeltOffText",
                    instance: 1,
                    relativePos: 1,
                    minPage: 2,
                    pattern: "Seatbelt-Off Rate",
                    transform: "value.split(' | ')[1]"
                },
                {
                    key: "speedingEvent",
                    instance: 1,
                    relativePos: 1,
                    minPage: 2,                    
                    pattern: "Speeding Event Rate",
                    transform: "value.split(' | ')[0]"
                },
                {
                    key: "speedingEventText",
                    instance: 1,
                    relativePos: 1,
                    minPage: 2,
                    pattern: "Speeding Event Rate",
                    transform: "value.split(' | ')[1]"
                },
                {
                    key: "dvcrCompliance",
                    instance: 1,
                    relativePos: 1,                   
                    pattern: "DVCR Compliance",
                    transform: "value.split(' | ')[0]"
                },
                {
                    key: "dvcrComplianceText",
                    instance: 1,
                    relativePos: 1,
                    pattern: "DVCR Compliance",
                    transform: "value.split(' | ')[1]"
                },
                {
                    key: "onTimeCompliance",
                    instance: 1,
                    relativePos: 1,                    
                    pattern: "On-Time PM Compliance",
                    transform: "value.split(' | ')[0]"
                },
                {
                    key: "onTimeComplianceText",
                    instance: 1,
                    relativePos: 1,
                    pattern: "On-Time PM Compliance",
                    transform: "value.split(' | ')[1]"
                },
                {
                    key: "complianceScoreText",
                    instance: 1,
                    relativePos: 1,
                    pattern: "Compliance Score"
                },
                {
                    key: "workingHoursCompliance",
                    instance: 1,
                    relativePos: 1,                    
                    pattern: "Working Hours Compliance (WHC)",
                    transform: "value.split(' | ')[0]"
                },
                {
                    key: "workingHoursComplianceText",
                    instance: 1,
                    relativePos: 1,
                    pattern: "Working Hours Compliance (WHC)",
                    transform: "value.split(' | ')[1]"
                },
                {
                    key: "customerEscalationDefectDPMO",
                    instance: 1,
                    relativePos: 1,                    
                    pattern: "Customer Escalation Defect DPMO",
                    transform: "value.split(' | ')[0]"
                },
                {
                    key: "customerEscalationDefectDPMOText",
                    instance: 1,
                    relativePos: 1,
                    pattern: "Customer Escalation Defect DPMO",
                    transform: "value.split(' | ')[1]"
                },
                {
                    key: "team",
                    instance: 1,
                    relativePos: 1,
                    pattern: "Team:"
                },
                {
                    key: "highPerformersShare",
                    instance: 1,
                    relativePos: 1,                   
                    pattern: "High Performers Share",
                    transform: "value.split(' | ')[0]"
                },
                {
                    key: "highPerformersShareText",
                    instance: 1,
                    relativePos: 1,
                    pattern: "High Performers Share",
                    transform: "value.split(' | ')[1]"
                },
                {
                    key: "lowPerformersShare",
                    instance: 1,
                    relativePos: 1,                   
                    pattern: "Low Performers Share",
                    transform: "value.split(' | ')[0]"
                },
                {
                    key: "lowPerformersShareText",
                    instance: 1,
                    relativePos: 1,
                    pattern: "Low Performers Share",
                    transform: "value.split(' | ')[1]"
                },
                {
                    key: "attritionRate",
                    instance: 1,
                    relativePos: 1,                    
                    pattern: "Attrition Rate",
                    transform: "value.split(' | ')[0]"
                },
                {
                    key: "attritionRateText",
                    instance: 1,
                    relativePos: 1,
                    pattern: "Attrition Rate",
                    transform: "value.split(' | ')[1]"
                },              
                {
                    key: "quality",
                    instance: 1,
                    relativePos: 1,
                    pattern: "Quality:"               
                },
                {
                    key: "deliveryCompletion",
                    instance: 1,
                    relativePos: 1,                    
                    pattern: "Delivery Completion Rate",
                    transform: "value.split(' | ')[0]"
                },
                {
                    key: "deliveryCompletionText",
                    instance: 1,
                    relativePos: 1,
                    pattern: "Delivery Completion Rate",
                    transform: "value.split(' | ')[1]"
                },  
                {
                    key: "deliveredAndReceived",
                    instance: 1,
                    relativePos: 1,                    
                    pattern: "Delivered and Received",
                    transform: "value.split(' | ')[0]"
                },
                {
                    key: "deliveredAndReceivedText",
                    instance: 1,
                    relativePos: 1,
                    pattern: "Delivered and Received",
                    transform: "value.split(' | ')[1]"
                },
                {
                    key: "standardWorkComplianceText",
                    instance: 1,
                    relativePos: 1,
                    pattern: "Standard Work Compliance"
                },
                {
                    key: "photoOnDelivery",
                    instance: 1,
                    relativePos: 1,
                    pattern: "Photo-On-Delivery",
                    transform: "value.split(' | ')[0]"
                },
                {
                    key: "photoOnDeliveryText",
                    instance: 1,
                    relativePos: 1,
                    pattern: "Photo-On-Delivery",
                    transform: "value.split(' | ')[1]"
                },
                {
                    key: "contactCompliance",
                    instance: 1,
                    relativePos: 1,                    
                    pattern: "Contact Compliance",
                    transform: "value.split(' | ')[0]"
                },
                {
                    key: "contactComplianceText",
                    instance: 1,
                    relativePos: 1,
                    pattern: "Contact Compliance",
                    transform: "value.split(' | ')[1]"
                },
                {
                    key: "scanCompliance",
                    instance: 1,
                    relativePos: 1,
                    pattern: "Scan Compliance",
                    transform: "value.split(' | ')[0]"
                },
                {
                    key: "scanComplianceText",
                    instance: 1,
                    relativePos: 1,
                    pattern: "Scan Compliance",
                    transform: "value.split(' | ')[1]"
                },
                {
                    key: "attendedDeliveryAccuracy",
                    instance: 1,
                    relativePos: 1,                    
                    pattern: "Attended Delivery Accuracy",
                    transform: "value.split(' | ')[0]"
                },
                {
                    key: "attendedDeliveryAccuracyText",
                    instance: 1,
                    relativePos: 1,
                    pattern: "Attended Delivery Accuracy",
                    transform: "value.split(' | ')[1]"
                }            
   
            ],
            tables: [
                {
                    table_pattern:[
                        "drivers_ranked_by_overall_score__descending",
                        "#"
                     ],
                    table_match_percent: 1,
                    table_data:[],
                    table_end_string: "Drivers ranked by overall score, descending.",
                    min_data: 12,
                    columns: [
                        { 
                            slug: "name",
                            left: 0.022,
                            right: 0.115
                        },
                        { 
                            slug: "transporter_id",
                            left: 0.115,
                            right: 0.208
                        },
                        { 
                            slug: "overal_tier",
                            left: 0.208,
                            right: 0.254
                        },
                        { 
                            slug: "delivered",
                            left: 0.254,
                            right: 0.297,
                        },
                        { 
                            slug: "key_focus_area",
                            left: 0.297,
                            right: 0.405
                        },
                        { 
                            slug: "fico_score",
                            left: 0.405,
                            right: 0.472,
                        },
                        { 
                            slug: "seatbelt_off_rate",
                            left: 0.472,
                            right: 0.533,
                        },
                        { 
                            slug: "speeding_event_rate",
                            left: 0.533,
                            right: 0.593,
                        },
                        { 
                            slug: "dcr",
                            left: 0.593,
                            right:0.640,
                        },
                        { 
                            slug: "dar",
                            left: 0.640,
                            right: 0.687,
                        },
                        { 
                            slug: "swc_pod",
                            left: 0.687,
                            right: 0.734,
                        },
                        { 
                            slug: "swc_cc",
                            left: 0.734,
                            right: 0.781,
                        },
                        { 
                            slug: "swc_sc",
                            left: 0.781,
                            right: 0.828,
                        },
                        { 
                            slug: "swc_ad",
                            left: 0.828,
                            right: 0.875,
                        },
                        { 
                            slug: "dnrs",
                            left: 0.875,
                            right: 0.919,
                        },
                        { 
                            slug: "pod_opps",
                            left: 0.919,
                            right: 0.957,
                        },
                        { 
                            slug: "cc_opps",
                            left: 0.957,
                            right: 1.0,
                        }
                    ]
                },
                {
                    table_pattern:[
                            "drivers_ranked_by_overall_score__descending",
                            "fant"
                    ],
                    table_match_percent: 1,
                    table_data:[],
                    min_data: 12,
                    columns: [
                        {
                            slug: "name",
                            left: 0.05198065936565399,
                            right:0.17115876078605652
                        },
                        {
                            slug: "transporter_id",
                            left: 0.17115876078605652,
                            right: 0.29660940170288086
                        },
                        {
                            slug: "average_tier",
                            left: 0.29660940170288086,
                            right: 0.34888049960136414
                        },
                        {
                            slug: "dcr",
                            left: 0.34888049960136414,
                            right: 0.40742412209510803
                        },
                        {
                            slug: "dar",
                            left: 0.40742412209510803,
                            right: 0.46596774458885193
                        },
                        {
                            slug: "swc_pod",
                            left: 0.46596774458885193,
                            right: 0.5224205255508423
                        },
                        {
                            slug: "swc_cc",
                            left: 0.5224205255508423,
                            right: 0.5809641480445862
                        },
                        {
                            slug: "swc_sc",
                            left: 0.5809641480445862,
                            right: 0.6395078301429749
                        },
                        {
                            slug: "swc_ad",
                            left: 0.6395078301429749,
                            right: 0.6959605813026428
                        },
                        {
                            slug: "high_low_performer",
                            left: 0.6959605813026428,
                            right: 0.7984119653701782
                        },
                        {
                            slug: "fant",
                            left: 0.798411965370178,
                            right: 0.8318654298782349
                        },
                        {
                            slug: "great",
                            left: 0.8318654298782349,
                            right: 0.8674097657203674
                        },
                        {
                            slug: "fair",
                            left: 0.8674097657203674,
                            right: 0.8987724781036377
                        },
                        {
                            slug: "poor",
                            left: 0.8987724781036377,
                            right: 0.9426801800727844
                        },
                    ]
                }
            ]
        },
        {
            start: 202046,
            vehicleType: "XL",   
            startParsingAfter: ['Driver Swag', 'Questions?'],   
            fields: [
                {
                    key: "week",
                    instance: 1,
                    relativePos: 0,
                    regex: "(^Week) (\\d\\d?)$",   
                    regexGroup: 1,       
                    pattern: "Week",
                    transform: "patternMatches[2]",           
                },
                {
                    key: "year",
                    instance: 1,
                    relativePos: 1,
                    regex: "(^Week) (\\d\\d?)$",  
                    regexGroup: 1,      
                    pattern: "Week",
                },
                {
                    key: "overall",
                    instance: 1,
                    relativePos: 1,
                    pattern: "Overall Standing:"
                },
                {
                    key: "safetyAndCompliance",
                    instance: 1,
                    relativePos: 1,
                    pattern: "Safety and Compliance:"            
                },
                {
                    key: "comprehensiveAudit",
                    instance: 1,
                    relativePos: 1,
                    pattern: "Comprehensive Audit:"            
                },
                {
                    key: "safetyScore",
                    instance: 1,
                    relativePos: 1,
                    minPage: 2,
                    pattern: "Safety Score*"
                },
                {
                    key: "safeDriving",
                    instance: 1,
                    relativePos: 0,        
                    regex: "(\\d.\\d\\d) \\((\\d\\d\\d) (FICO)\\) \\| (\\w+)", 
                    regexGroup: 3,          
                    pattern: "FICO",
                    transform: "patternMatches[1]"
                },
                {
                    key: "safeDrivingText",
                    instance: 1,
                    relativePos: 0,        
                    regex: "(\\d.\\d\\d) \\((\\d\\d\\d) (FICO)\\) \\| (\\w+)", 
                    regexGroup: 3,          
                    pattern: "FICO",
                    transform: "patternMatches[4]"
                },
                {
                    key: "fico",
                    instance: 1,
                    relativePos: 0,
                    regex: "\((\\d\\d\\d) (FICO)\)",
                    regexGroup: 2,
                    pattern: "FICO",
                    transform: "parseInt(value.replace(/[^0-9]/g, ''))"
                },
                {
                    key: "seatbeltOff",
                    instance: 1,
                    relativePos: 1,
                    minPage: 2,                    
                    pattern: "Seatbelt-Off Rate",
                    transform: "value.split(' | ')[0]"
                },
                {
                    key: "seatbeltOffText",
                    instance: 1,
                    relativePos: 1,
                    minPage: 2,
                    pattern: "Seatbelt-Off Rate",
                    transform: "value.split(' | ')[1]"
                },
                {
                    key: "speedingEvent",
                    instance: 1,
                    relativePos: 1,
                    minPage: 2,                    
                    pattern: "Speeding Event Rate",
                    transform: "value.split(' | ')[0]"
                },
                {
                    key: "harshBrakingEvent",
                    instance: 1,
                    relativePos: 1,
                    minPage: 2,
                    pattern: "Harsh Braking Event",
                    transform: "value.split(' | ')[0]"
                },
                {
                    key: "harshCorneringEvent",
                    instance: 1,
                    relativePos: 1,
                    minPage: 2,
                    pattern: "Harsh Cornering Event",
                    transform: "value.split(' | ')[0]"
                },
                {
                    key: "speedingEventText",
                    instance: 1,
                    relativePos: 1,
                    minPage: 2,
                    pattern: "Speeding Event Rate",
                    transform: "value.split(' | ')[1]"
                },
                {
                    key: "dvcrCompliance",
                    instance: 1,
                    relativePos: 1,                   
                    pattern: "DVCR Compliance",
                    transform: "value.split(' | ')[0]"
                },
                {
                    key: "dvcrComplianceText",
                    instance: 1,
                    relativePos: 1,
                    pattern: "DVCR Compliance",
                    transform: "value.split(' | ')[1]"
                },
                {
                    key: "onTimeCompliance",
                    instance: 1,
                    relativePos: 1,                    
                    pattern: "On-Time PM Compliance",
                    transform: "value.split(' | ')[0]"
                },
                {
                    key: "onTimeComplianceText",
                    instance: 1,
                    relativePos: 1,
                    pattern: "On-Time PM Compliance",
                    transform: "value.split(' | ')[1]"
                },
                {
                    key: "complianceScoreText",
                    instance: 1,
                    relativePos: 1,
                    pattern: "Compliance Score"
                },
                {
                    key: "workingHoursCompliance",
                    instance: 1,
                    relativePos: 1,                    
                    pattern: "Working Hours Compliance (WHC)",
                    transform: "value.split(' | ')[0]"
                },
                {
                    key: "workingHoursComplianceText",
                    instance: 1,
                    relativePos: 1,
                    pattern: "Working Hours Compliance (WHC)",
                    transform: "value.split(' | ')[1]"
                },
                {
                    key: "customerEscalationDefectDPMO",
                    instance: 1,
                    relativePos: 1,                    
                    pattern: "Customer Escalation Defect DPMO",
                    transform: "value.split(' | ')[0]"
                },
                {
                    key: "customerEscalationDefectDPMOText",
                    instance: 1,
                    relativePos: 1,
                    pattern: "Customer Escalation Defect DPMO",
                    transform: "value.split(' | ')[1]"
                },
                {
                    key: "team",
                    instance: 1,
                    relativePos: 1,
                    pattern: "Team:"
                },
                {
                    key: "highPerformersShare",
                    instance: 1,
                    relativePos: 1,                   
                    pattern: "High Performers Share",
                    transform: "value.split(' | ')[0]"
                },
                {
                    key: "highPerformersShareText",
                    instance: 1,
                    relativePos: 1,
                    pattern: "High Performers Share",
                    transform: "value.split(' | ')[1]"
                },
                {
                    key: "lowPerformersShare",
                    instance: 1,
                    relativePos: 1,                   
                    pattern: "Low Performers Share",
                    transform: "value.split(' | ')[0]"
                },
                {
                    key: "lowPerformersShareText",
                    instance: 1,
                    relativePos: 1,
                    pattern: "Low Performers Share",
                    transform: "value.split(' | ')[1]"
                },
                {
                    key: "attritionRate",
                    instance: 1,
                    relativePos: 1,                    
                    pattern: "Attrition Rate [4 week trailing]",
                    transform: "value.split(' | ')[0]"
                },
                {
                    key: "attritionRateText",
                    instance: 1,
                    relativePos: 1,
                    pattern: "Attrition Rate [4 week trailing]",
                    transform: "value.split(' | ')[1]"
                },              
                {
                    key: "quality",
                    instance: 1,
                    relativePos: 1,
                    pattern: "Quality:"               
                },
                {
                    key: "deliveryCompletion",
                    instance: 1,
                    relativePos: 1,                    
                    pattern: "Delivery Completion Rate",
                    transform: "value.split(' | ')[0]"
                },
                {
                    key: "deliveryCompletionText",
                    instance: 1,
                    relativePos: 1,
                    pattern: "Delivery Completion Rate",
                    transform: "value.split(' | ')[1]"
                },  
                {
                    key: "deliveredAndReceived",
                    instance: 1,
                    relativePos: 1,                    
                    pattern: "Delivered and Received",
                    transform: "value.split(' | ')[0]"
                },
                {
                    key: "deliveredAndReceivedText",
                    instance: 1,
                    relativePos: 1,
                    pattern: "Delivered and Received",
                    transform: "value.split(' | ')[1]"
                },
                {
                    key: "standardWorkComplianceText",
                    instance: 1,
                    relativePos: 1,
                    pattern: "Standard Work Compliance"
                },
                {
                    key: "photoOnDelivery",
                    instance: 1,
                    relativePos: 1,
                    pattern: "Photo-On-Delivery",
                    transform: "value.split(' | ')[0]"
                },
                {
                    key: "photoOnDeliveryText",
                    instance: 1,
                    relativePos: 1,
                    pattern: "Photo-On-Delivery",
                    transform: "value.split(' | ')[1]"
                },
                {
                    key: "contactCompliance",
                    instance: 1,
                    relativePos: 1,                    
                    pattern: "Contact Compliance",
                    transform: "value.split(' | ')[0]"
                },
                {
                    key: "contactComplianceText",
                    instance: 1,
                    relativePos: 1,
                    pattern: "Contact Compliance",
                    transform: "value.split(' | ')[1]"
                },
                {
                    key: "scanCompliance",
                    instance: 1,
                    relativePos: 1,
                    pattern: "Scan Compliance",
                    transform: "value.split(' | ')[0]"
                },
                {
                    key: "scanComplianceText",
                    instance: 1,
                    relativePos: 1,
                    pattern: "Scan Compliance",
                    transform: "value.split(' | ')[1]"
                },
                {
                    key: "attendedDeliveryAccuracy",
                    instance: 1,
                    relativePos: 1,                    
                    pattern: "Attended Delivery Accuracy",
                    transform: "value.split(' | ')[0]"
                },
                {
                    key: "attendedDeliveryAccuracyText",
                    instance: 1,
                    relativePos: 1,
                    pattern: "Attended Delivery Accuracy",
                    transform: "value.split(' | ')[1]"
                },
                {
                    key: 'signSignalViolationsRate',
                    instance: 1, 
                    relativePos: 1, 
                    pattern: "Sign/Signal Violations Rate",
                    transform: "value.split( ' | ')[0]"
                },        
                {
                    key: 'signSignalViolationsRateText',
                    instance: 1, 
                    relativePos: 1, 
                    pattern: "Sign/Signal Violations Rate",
                    transform: "value.split( ' | ')[1]"
                },        
                {
                    key: 'distractionsRate',
                    instance: 1, 
                    relativePos: 1, 
                    pattern: "Distractions Rate",
                    transform: "value.split( ' | ')[0]"
                },        
                {
                    key: 'distractionsRateText',
                    instance: 1, 
                    relativePos: 1, 
                    pattern: "Distractions Rate",
                    transform: "value.split( ' | ')[1]"
                },        
                {
                    key: 'followingDistanceRate',
                    instance: 1, 
                    relativePos: 1, 
                    pattern: "Following Distance Rate",
                    transform: "value.split( ' | ')[0]"
                },        
                {
                    key: 'followingDistanceRateText',
                    instance: 1, 
                    relativePos: 1, 
                    pattern: "Following Distance Rate",
                    transform: "value.split( ' | ')[1]"
                }        
    
            ],
            tables: [
                {
                    table_pattern:[
                        "drivers_ranked_by_overall_score__descending",
                        "#"
                     ],
                    table_match_percent: 1,
                    table_data:[],
                    table_end_string: "Drivers ranked by overall score, descending.",
                    min_data: 8,
                    columns: [
                        { 
                            slug: "name",
                            left: 0.022,
                            right: 0.115
                        },
                        { 
                            slug: "transporter_id",
                            left: 0.115,
                            right: 0.209
                        },
                        { 
                            slug: "overal_tier",
                            left: 0.209,
                            right: 0.256
                        },
                        { 
                            slug: "delivered",
                            left: 0.256,
                            right: 0.299,
                        },
                        { 
                            slug: "key_focus_area",
                            left: 0.299,
                            right: 0.409
                        },
                        { 
                            slug: "speeding_event_rate",
                            left: 0.416,
                            right: 0.475,
                        },
                        {
                            slug: "harsh_braking",
                            left: 0.475,
                            right: 0.533,
                        },
                        {
                            slug: "harsh_cornering",
                            left: 0.533,
                            right: 0.593

                        },
                        { 
                            slug: "dcr",
                            left: 0.593,
                            right: 0.640,
                        },
                        { 
                            slug: "dar",
                            left: 0.640,
                            right: 0.688,
                        },
                        { 
                            slug: "swc_pod",
                            left: 0.688,
                            right: 0.735,
                        },
                        { 
                            slug: "swc_cc",
                            left: 0.735,
                            right: 0.782,
                        },
                        { 
                            slug: "swc_sc",
                            left: 0.782,
                            right: 0.831,
                        },
                        { 
                            slug: "swc_ad",
                            left: 0.831,
                            right: 0.878,
                        },
                        { 
                            slug: "dnrs",
                            left: 0.884,
                            right: 0.923,
                        },
                        { 
                            slug: "pod_opps",
                            left: 0.923,
                            right: 0.959,
                        },
                        { 
                            slug: "cc_opps",
                            left: 0.959,
                            right: 1.0,
                        }
                    ]
                },
                {
                    table_pattern:[
                            "drivers_ranked_by_overall_score__descending",
                            "fant"
                    ],
                    table_match_percent: 1,
                    table_data:[],
                    min_data: 12,
                    columns: [
                        {
                            slug: "name",
                            left: 0.05198065936565399,
                            right:0.17115876078605652
                        },
                        {
                            slug: "transporter_id",
                            left: 0.17115876078605652,
                            right: 0.29660940170288086
                        },
                        {
                            slug: "average_tier",
                            left: 0.29660940170288086,
                            right: 0.34888049960136414
                        },
                        {
                            slug: "dcr",
                            left: 0.34888049960136414,
                            right: 0.40742412209510803
                        },
                        {
                            slug: "dar",
                            left: 0.40742412209510803,
                            right: 0.46596774458885193
                        },
                        {
                            slug: "swc_pod",
                            left: 0.46596774458885193,
                            right: 0.5224205255508423
                        },
                        {
                            slug: "swc_cc",
                            left: 0.5224205255508423,
                            right: 0.5809641480445862
                        },
                        {
                            slug: "swc_sc",
                            left: 0.5809641480445862,
                            right: 0.6395078301429749
                        },
                        {
                            slug: "swc_ad",
                            left: 0.6395078301429749,
                            right: 0.6959605813026428
                        },
                        {
                            slug: "high_low_performer",
                            left: 0.6959605813026428,
                            right: 0.7984119653701782
                        },
                        {
                            slug: "fant",
                            left: 0.798411965370178,
                            right: 0.8318654298782349
                        },
                        {
                            slug: "great",
                            left: 0.8318654298782349,
                            right: 0.8674097657203674
                        },
                        {
                            slug: "fair",
                            left: 0.8674097657203674,
                            right: 0.8987724781036377
                        },
                        {
                            slug: "poor",
                            left: 0.8987724781036377,
                            right: 0.9426801800727844
                        },
                    ]
                }
            ]
        },
        {
            start: 202020,   
            startParsingAfter: "Questions?",   
            fields: [
                {
                    key: "week",
                    instance: 1,
                    relativePos: 0,
                    regex: "(^Week) (\\d\\d?)$",   
                    regexGroup: 1,       
                    pattern: "Week",
                    transform: "patternMatches[2]",           
                },
                {
                    key: "year",
                    instance: 1,
                    relativePos: 1,
                    regex: "(^Week) (\\d\\d?)$",  
                    regexGroup: 1,      
                    pattern: "Week",
                },
                {
                    key: "overall",
                    instance: 1,
                    relativePos: 1,
                    pattern: "Overall Standing:"
                },
                {
                    key: "safetyAndCompliance",
                    instance: 1,
                    relativePos: 1,
                    pattern: "Safety and Compliance:"            
                },
                {
                    key: "comprehensiveAudit",
                    instance: 1,
                    relativePos: 1,
                    pattern: "Comprehensive Audit:"            
                },
                {
                    key: "safetyScore",
                    instance: 1,
                    relativePos: 1,
                    minPage: 2,
                    pattern: "Safety Score*"
                },
                {
                    key: "safeDriving",
                    instance: 1,
                    relativePos: 0,        
                    regex: "(\\d.\\d\\d) \\((\\d\\d\\d) (FICO)\\) \\| (\\w+)", 
                    regexGroup: 3,          
                    pattern: "FICO",
                    transform: "patternMatches[1]"
                },
                {
                    key: "safeDrivingText",
                    instance: 1,
                    relativePos: 0,        
                    regex: "(\\d.\\d\\d) \\((\\d\\d\\d) (FICO)\\) \\| (\\w+)", 
                    regexGroup: 3,          
                    pattern: "FICO",
                    transform: "patternMatches[4]"
                },
                {
                    key: "fico",
                    instance: 1,
                    relativePos: 0,
                    regex: "\((\\d\\d\\d) (FICO)\)",
                    regexGroup: 2,
                    pattern: "FICO",
                    transform: "parseInt(value.replace(/[^0-9]/g, ''))"
                },
                {
                    key: "seatbeltOff",
                    instance: 1,
                    relativePos: 1,
                    minPage: 2,                    
                    pattern: "Seatbelt-Off Rate",
                    transform: "value.split(' | ')[0]"
                },
                {
                    key: "seatbeltOffText",
                    instance: 1,
                    relativePos: 1,
                    minPage: 2,
                    pattern: "Seatbelt-Off Rate",
                    transform: "value.split(' | ')[1]"
                },
                {
                    key: "speedingEvent",
                    instance: 1,
                    relativePos: 1,
                    minPage: 2,                    
                    pattern: "Speeding Event Rate",
                    transform: "value.split(' | ')[0]"
                },
                {
                    key: "speedingEventText",
                    instance: 1,
                    relativePos: 1,
                    minPage: 2,
                    pattern: "Speeding Event Rate",
                    transform: "value.split(' | ')[1]"
                },
                {
                    key: "dvcrCompliance",
                    instance: 1,
                    relativePos: 1,                   
                    pattern: "DVCR Compliance",
                    transform: "value.split(' | ')[0]"
                },
                {
                    key: "dvcrComplianceText",
                    instance: 1,
                    relativePos: 1,
                    pattern: "DVCR Compliance",
                    transform: "value.split(' | ')[1]"
                },
                {
                    key: "onTimeCompliance",
                    instance: 1,
                    relativePos: 1,                    
                    pattern: "On-Time PM Compliance",
                    transform: "value.split(' | ')[0]"
                },
                {
                    key: "onTimeComplianceText",
                    instance: 1,
                    relativePos: 1,
                    pattern: "On-Time PM Compliance",
                    transform: "value.split(' | ')[1]"
                },
                {
                    key: "complianceScoreText",
                    instance: 1,
                    relativePos: 1,
                    pattern: "Compliance Score"
                },
                {
                    key: "workingHoursCompliance",
                    instance: 1,
                    relativePos: 1,                    
                    pattern: "Working Hours Compliance (WHC)",
                    transform: "value.split(' | ')[0]"
                },
                {
                    key: "workingHoursComplianceText",
                    instance: 1,
                    relativePos: 1,
                    pattern: "Working Hours Compliance (WHC)",
                    transform: "value.split(' | ')[1]"
                },
                {
                    key: "customerEscalationDefectDPMO",
                    instance: 1,
                    relativePos: 1,                    
                    pattern: "Customer Escalation Defect DPMO",
                    transform: "value.split(' | ')[0]"
                },
                {
                    key: "customerEscalationDefectDPMOText",
                    instance: 1,
                    relativePos: 1,
                    pattern: "Customer Escalation Defect DPMO",
                    transform: "value.split(' | ')[1]"
                },
                {
                    key: "team",
                    instance: 1,
                    relativePos: 1,
                    pattern: "Team:"
                },
                {
                    key: "highPerformersShare",
                    instance: 1,
                    relativePos: 1,                   
                    pattern: "High Performers Share",
                    transform: "value.split(' | ')[0]"
                },
                {
                    key: "highPerformersShareText",
                    instance: 1,
                    relativePos: 1,
                    pattern: "High Performers Share",
                    transform: "value.split(' | ')[1]"
                },
                {
                    key: "lowPerformersShare",
                    instance: 1,
                    relativePos: 1,                   
                    pattern: "Low Performers Share",
                    transform: "value.split(' | ')[0]"
                },
                {
                    key: "lowPerformersShareText",
                    instance: 1,
                    relativePos: 1,
                    pattern: "Low Performers Share",
                    transform: "value.split(' | ')[1]"
                },
                {
                    key: "attritionRate",
                    instance: 1,
                    relativePos: 1,                    
                    pattern: "Attrition Rate",
                    transform: "value.split(' | ')[0]"
                },
                {
                    key: "attritionRateText",
                    instance: 1,
                    relativePos: 1,
                    pattern: "Attrition Rate",
                    transform: "value.split(' | ')[1]"
                },              
                {
                    key: "quality",
                    instance: 1,
                    relativePos: 1,
                    pattern: "Quality:"               
                },
                {
                    key: "deliveryCompletion",
                    instance: 1,
                    relativePos: 1,                    
                    pattern: "Delivery Completion Rate",
                    transform: "value.split(' | ')[0]"
                },
                {
                    key: "deliveryCompletionText",
                    instance: 1,
                    relativePos: 1,
                    pattern: "Delivery Completion Rate",
                    transform: "value.split(' | ')[1]"
                },  
                {
                    key: "deliveredAndReceived",
                    instance: 1,
                    relativePos: 1,                    
                    pattern: "Delivered and Received",
                    transform: "value.split(' | ')[0]"
                },
                {
                    key: "deliveredAndReceivedText",
                    instance: 1,
                    relativePos: 1,
                    pattern: "Delivered and Received",
                    transform: "value.split(' | ')[1]"
                },
                {
                    key: "standardWorkComplianceText",
                    instance: 1,
                    relativePos: 1,
                    pattern: "Standard Work Compliance"
                },
                {
                    key: "photoOnDelivery",
                    instance: 1,
                    relativePos: 1,
                    pattern: "Photo-On-Delivery",
                    transform: "value.split(' | ')[0]"
                },
                {
                    key: "photoOnDeliveryText",
                    instance: 1,
                    relativePos: 1,
                    pattern: "Photo-On-Delivery",
                    transform: "value.split(' | ')[1]"
                },
                {
                    key: "contactCompliance",
                    instance: 1,
                    relativePos: 1,                    
                    pattern: "Contact Compliance",
                    transform: "value.split(' | ')[0]"
                },
                {
                    key: "contactComplianceText",
                    instance: 1,
                    relativePos: 1,
                    pattern: "Contact Compliance",
                    transform: "value.split(' | ')[1]"
                },
                {
                    key: "scanCompliance",
                    instance: 1,
                    relativePos: 1,
                    pattern: "Scan Compliance",
                    transform: "value.split(' | ')[0]"
                },
                {
                    key: "scanComplianceText",
                    instance: 1,
                    relativePos: 1,
                    pattern: "Scan Compliance",
                    transform: "value.split(' | ')[1]"
                },
                {
                    key: "attendedDeliveryAccuracy",
                    instance: 1,
                    relativePos: 1,                    
                    pattern: "Attended Delivery Accuracy",
                    transform: "value.split(' | ')[0]"
                },
                {
                    key: "attendedDeliveryAccuracyText",
                    instance: 1,
                    relativePos: 1,
                    pattern: "Attended Delivery Accuracy",
                    transform: "value.split(' | ')[1]"
                }            
   
            ],
            tables: [
                {
                    table_pattern:[
                        "drivers_ranked_by_overall_score__descending",
                        "#"
                     ],
                    table_match_percent: 1,
                    table_data:[],
                    table_end_string: "Drivers ranked by overall score, descending.",
                    min_data: 12,
                    columns: [
                        { 
                            slug: "name",
                            left: 0.022795894,
                            right: 0.121692367
                        },
                        { 
                            slug: "transporter_id",
                            left: 0.121692367,
                            right: 0.219930978
                        },
                        { 
                            slug: "overal_tier",
                            left: 0.219930978,
                            right: 0.271458078
                        },
                        { 
                            slug: "delivered",
                            left: 0.271458078,
                            right: 0.318314096,
                        },
                        { 
                            slug: "key_focus_area",
                            left: 0.318314096,
                            right: 0.436881626
                        },
                        { 
                            slug: "fico_score",
                            left: 0.436881626,
                            right: 0.505609018,
                        },
                        { 
                            slug: "seatbelt_off_rate",
                            left: 0.505609018,
                            right: 0.571139758,
                        },
                        { 
                            slug: "dcr",
                            left: 0.571139758,
                            right:0.620699517,
                        },
                        { 
                            slug: "dar",
                            left: 0.620699517,
                            right: 0.672264687,
                        },
                        { 
                            slug: "swc_pod",
                            left: 0.672264687,
                            right: 0.723920012,
                        },
                        { 
                            slug: "swc_cc",
                            left: 0.723920012,
                            right: 0.774846553,
                        },
                        { 
                            slug: "swc_sc",
                            left: 0.774846553,
                            right: 0.825605468,
                        },
                        { 
                            slug: "swc_ad",
                            left: 0.825605468,
                            right: 0.882594998,
                        },
                        { 
                            slug: "dnrs",
                            left: 0.882594998,
                            right: 0.922340616,
                        },
                        { 
                            slug: "pod_opps",
                            left: 0.922340616,
                            right: 0.96312188,
                        },
                        { 
                            slug: "cc_opps",
                            left: 0.96312188,
                            right: 1.0,
                        }
                    ]
                },
                {
                    table_pattern:[
                            "drivers_ranked_by_overall_score__descending",
                            "fant"
                    ],
                    table_match_percent: 1,
                    table_data:[],
                    min_data: 12,
                    columns: [
                        {
                            slug: "name",
                            left: 0.05198065936565399,
                            right:0.17115876078605652
                        },
                        {
                            slug: "transporter_id",
                            left: 0.17115876078605652,
                            right: 0.29660940170288086
                        },
                        {
                            slug: "average_tier",
                            left: 0.29660940170288086,
                            right: 0.34888049960136414
                        },
                        {
                            slug: "dcr",
                            left: 0.34888049960136414,
                            right: 0.40742412209510803
                        },
                        {
                            slug: "dar",
                            left: 0.40742412209510803,
                            right: 0.46596774458885193
                        },
                        {
                            slug: "swc_pod",
                            left: 0.46596774458885193,
                            right: 0.5224205255508423
                        },
                        {
                            slug: "swc_cc",
                            left: 0.5224205255508423,
                            right: 0.5809641480445862
                        },
                        {
                            slug: "swc_sc",
                            left: 0.5809641480445862,
                            right: 0.6395078301429749
                        },
                        {
                            slug: "swc_ad",
                            left: 0.6395078301429749,
                            right: 0.6959605813026428
                        },
                        {
                            slug: "high_low_performer",
                            left: 0.6959605813026428,
                            right: 0.7984119653701782
                        },
                        {
                            slug: "fant",
                            left: 0.798411965370178,
                            right: 0.8318654298782349
                        },
                        {
                            slug: "great",
                            left: 0.8318654298782349,
                            right: 0.8674097657203674
                        },
                        {
                            slug: "fair",
                            left: 0.8674097657203674,
                            right: 0.8987724781036377
                        },
                        {
                            slug: "poor",
                            left: 0.8987724781036377,
                            right: 0.9426801800727844
                        },
                    ]
                }
            ]
        },
        {
            start: 200001,
            startParsingAfter: null, 
            fields: [
                {
                    key: "week",
                    instance: 1,
                    relativePos: 0,
                    regex: "(^Week) (\\d\\d?)$",   
                    regexGroup: 1,       
                    pattern: "Week",
                    transform: "patternMatches[2]",           
                },
                {
                    key: "year",
                    instance: 1,
                    relativePos: 1,
                    regex: "(^Week) (\\d\\d?)$",  
                    regexGroup: 1,      
                    pattern: "Week",
                },
                {
                    key: "overall",
                    instance: 1,
                    relativePos: 1,
                    pattern: "Overall Standing:"
                },
                {
                    key: "safetyAndCompliance",
                    instance: 1,
                    relativePos: 1,
                    pattern: "Safety and Compliance:"            
                },
                {
                    key: "safetyScore",
                    instance: 1,
                    relativePos: 1,
                    pattern: "Safety Score*"
                },
                {
                    key: "safeDriving",
                    instance: 1,
                    relativePos: -1,
                    pattern: "Seatbelt-Off Rate",
                    transform: "value.split(' | ')[0]"
                },
                {
                    key: "safeDrivingText",
                    instance: 1,
                    relativePos: -1,
                    pattern: "Seatbelt-Off Rate",
                    transform: "value.split(' | ')[1]"
                },
                {
                    key: "fico",
                    instance: 1,
                    relativePos: 0,
                    regex: "\((\\d\\d\\d) (FICO)\)",
                    regexGroup: 2,
                    pattern: "FICO",
                    transform: "parseInt(value.replace(/[^0-9]/g, ''))"
                },
                {
                    key: "seatbeltOff",
                    instance: 1,
                    relativePos: 1,
                    pattern: "Seatbelt-Off Rate",
                    transform: "value.split(' | ')[0]"
                },
                {
                    key: "seatbeltOffText",
                    instance: 1,
                    relativePos: 1,
                    pattern: "Seatbelt-Off Rate",
                    transform: "value.split(' | ')[1]"
                },
                {
                    key: "dvcrCompliance",
                    instance: 1,
                    relativePos: 1,
                    pattern: "DVCR Compliance",
                    transform: "value.split(' | ')[0]"
                },
                {
                    key: "dvcrComplianceText",
                    instance: 1,
                    relativePos: 2,
                    pattern: "DVCR Compliance",
                    transform: "value.split(' | ')[1]"
                },
                {
                    key: "complianceScoreText",
                    instance: 1,
                    relativePos: 1,
                    pattern: "Compliance Score"
                },
                {
                    key: "workingHoursCompliance",
                    instance: 1,
                    relativePos: 1,
                    pattern: "Working Hours Compliance (WHC)",
                    transform: "value.split(' | ')[0]"
                },
                {
                    key: "workingHoursComplianceText",
                    instance: 1,
                    relativePos: 1,
                    pattern: "Working Hours Compliance (WHC)",
                    transform: "value.split(' | ')[1]"
                },
                {
                    key: "dspAudit",
                    instance: 1,
                    relativePos: 1,
                    pattern: "DSP Audit (CAS)",
                    transform: "value.split(' | ')[0]"
                },
                {
                    key: "dspAuditText",
                    instance: 1,
                    relativePos: 1,
                    pattern: "DSP Audit (CAS)",
                    transform: "value.split(' | ')[1]"
                },
                {
                    key: "thirtyDaysNoticeText",
                    instance: 1,
                    relativePos: 1,
                    pattern: "30 Days Notice (CAS)"
                },
                {
                    key: "customerEscalationDefectDPMO",
                    instance: 1,
                    relativePos: 1,
                    pattern: "Customer Escalation Defect DPMO",
                    transform: "value.split(' | ')[0]"
                },
                {
                    key: "customerEscalationDefectDPMOText",
                    instance: 1,
                    relativePos: 1,
                    pattern: "Customer Escalation Defect DPMO",
                    transform: "value.split(' | ')[1]"
                },
                {
                    key: "team",
                    instance: 1,
                    relativePos: 1,
                    pattern: "Team:"
                },
                {
                    key: "highPerformersShare",
                    instance: 1,
                    relativePos: 1,
                    pattern: "High Performers Share",
                    transform: "value.split(' | ')[0]"
                },
                {
                    key: "highPerformersShareText",
                    instance: 1,
                    relativePos: 1,
                    pattern: "High Performers Share",
                    transform: "value.split(' | ')[1]"
                },
                {
                    key: "lowPerformersShare",
                    instance: 1,
                    relativePos: 1,                    
                    pattern: "Low Performers Share",
                    transform: "value.split(' | ')[0]"
                },
                {
                    key: "lowPerformersShareText",
                    instance: 1,
                    relativePos: 1,
                    pattern: "Low Performers Share",
                    transform: "value.split(' | ')[1]"
                },
                {
                    key: "attritionRate",
                    instance: 1,
                    relativePos: 1,
                    pattern: "Attrition Rate",
                    transform: "value.split(' | ')[0]"
                },
                {
                    key: "attritionRateText",
                    instance: 1,
                    relativePos: 1,
                    pattern: "Attrition Rate",
                    transform: "value.split(' | ')[1]"
                },              
                {
                    key: "quality",
                    instance: 1,
                    relativePos: 1,
                    pattern: "Quality:"               
                },
                {
                    key: "deliveryCompletion",
                    instance: 1,
                    relativePos: 1,
                    pattern: "Delivery Completion Rate",
                    transform: "value.split(' | ')[0]"
                },
                {
                    key: "deliveryCompletionText",
                    instance: 1,
                    relativePos: 1,
                    pattern: "Delivery Completion Rate",
                    transform: "value.split(' | ')[1]"
                },  
                {
                    key: "deliveredAndReceived",
                    instance: 1,
                    relativePos: 1,
                    pattern: "Delivered and Received",
                    transform: "value.split(' | ')[0]"
                },
                {
                    key: "deliveredAndReceivedText",
                    instance: 1,
                    relativePos: 1,
                    pattern: "Delivered and Received",
                    transform: "value.split(' | ')[1]"
                },
                {
                    key: "standardWorkComplianceText",
                    instance: 1,
                    relativePos: 1,
                    pattern: "Standard Work Compliance"
                },
                {
                    key: "photoOnDelivery",
                    instance: 1,
                    relativePos: 1,
                    pattern: "Photo-On-Delivery",
                    transform: "value.split(' | ')[0]"
                },
                {
                    key: "photoOnDeliveryText",
                    instance: 1,
                    relativePos: 1,
                    pattern: "Photo-On-Delivery",
                    transform: "value.split(' | ')[1]"
                },
                {
                    key: "contactCompliance",
                    instance: 1,
                    relativePos: 1,
                    pattern: "Contact Compliance",
                    transform: "value.split(' | ')[0]"
                },
                {
                    key: "contactComplianceText",
                    instance: 1,
                    relativePos: 1,
                    pattern: "Contact Compliance",
                    transform: "value.split(' | ')[1]"
                },
                {
                    key: "scanCompliance",
                    instance: 1,
                    relativePos: 1,
                    pattern: "Scan Compliance",
                    transform: "value.split(' | ')[0]"
                },
                {
                    key: "scanComplianceText",
                    instance: 1,
                    relativePos: 1,
                    pattern: "Scan Compliance",
                    transform: "value.split(' | ')[1]"
                },
                {
                    key: "attendedDeliveryAccuracy",
                    instance: 1,
                    relativePos: 1,
                    pattern: "Attended Delivery Accuracy",
                    transform: "value.split(' | ')[0]"
                },
                {
                    key: "attendedDeliveryAccuracyText",
                    instance: 1,
                    relativePos: 1,
                    pattern: "Attended Delivery Accuracy",
                    transform: "value.split(' | ')[1]"
                } 
                
            ],
            tables: [
                {
                    table_pattern:[
                        "drivers_ranked_by_overall_score__descending",
                        "#"
                    ],
                    table_match_percent: 1,
                    table_data:[],
                    table_end_string: "Drivers ranked by overall score, descending.",
                    min_data: 12,
                    columns: [
                        { 
                            slug: "name",
                            left: 0.025949881,
                            right: 0.138524649
                        },
                        { 
                            slug: "transporter_id",
                            left: 0.138524649,
                            right: 0.255365384
                        },
                        { 
                            slug: "overal_tier",
                            left: 0.255365384,
                            right: 0.312272327
                        },
                        { 
                            slug: "delivered",
                            left: 0.312272327,
                            right: 0.356937725,
                        },
                        { 
                            slug: "key_focus_area",
                            left: 0.356937725,
                            right: 0.513427138
                        },
                        { 
                            slug: "dcr",
                            left: 0.513427138,
                            right: 0.569820157,
                        },
                        { 
                            slug: "dar",
                            left: 0.569820157,
                            right: 0.629863405,
                        },
                        { 
                            slug: "swc_pod",
                            left: 0.629863405,
                            right: 0.686923883,
                        },
                        { 
                            slug: "swc_cc",
                            left: 0.686923883,
                            right: 0.744306666,
                        },
                        { 
                            slug: "swc_sc",
                            left: 0.744306666,
                            right: 0.800770782,                            
                        },
                        { 
                            slug: "swc_ad",
                            left: 0.800770782,
                            right: 0.870840487,
                        },
                        { 
                            slug: "dnrs",
                            left: 0.870840487,
                            right: 0.911805856,
                        },
                        { 
                            slug: "pod_opps",
                            left: 0.911805856,
                            right: 0.963064138,
                        },
                        { 
                            slug: "cc_opps",
                            left: 0.963064138,
                            right: 1.0,
                        }
                    ]
                },
                {
                    table_pattern:[
                        "drivers_ranked_by_overall_score__descending",
                        "fant"
                    ],
                    table_data:[],
                    table_match_percent: 1,
                    min_data: 12,
                    columns: [

                        {
                            slug: "name",
                            left: 0.05198065936565399,
                            right:0.17115876078605652
                        },
                        {
                            slug: "transporter_id",
                            left: 0.17115876078605652,
                            right: 0.29660940170288086
                        },
                        {
                            slug: "average_tier",
                            left: 0.29660940170288086,
                            right: 0.34888049960136414
                        },
                        {
                            slug: "dcr",
                            left: 0.34888049960136414,
                            right: 0.40742412209510803
                        },
                        {
                            slug: "dar",
                            left: 0.40742412209510803,
                            right: 0.46596774458885193
                        },
                        {
                            slug: "swc_pod",
                            left: 0.46596774458885193,
                            right: 0.5224205255508423
                        },
                        {
                            slug: "swc_cc",
                            left: 0.5224205255508423,
                            right: 0.5809641480445862
                        },
                        {
                            slug: "swc_sc",
                            left: 0.5809641480445862,
                            right: 0.6395078301429749
                        },
                        {
                            slug: "swc_ad",
                            left: 0.6395078301429749,
                            right: 0.6959605813026428
                        },
                        {
                            slug: "high_low_performer",
                            left: 0.6959605813026428,
                            right: 0.7984119653701782
                        },
                        {
                            slug: "fant",
                            left: 0.798411965370178,
                            right: 0.8318654298782349
                        },
                        {
                            slug: "great",
                            left: 0.8318654298782349,
                            right: 0.8674097657203674
                        },
                        {
                            slug: "fair",
                            left: 0.8674097657203674,
                            right: 0.8987724781036377
                        },
                        {
                            slug: "poor",
                            left: 0.8987724781036377,
                            right: 0.9426801800727844
                        },
                    ]
                }
            ]
        },
        {
            start: 202001,
            vehicleType: "XL",   
            startParsingAfter: ['Driver Swag', 'Questions?'],   
            fields: [
                {
                    key: "week",
                    instance: 1,
                    relativePos: 0,
                    regex: "(^Week) (\\d\\d?)$",   
                    regexGroup: 1,       
                    pattern: "Week",
                    transform: "patternMatches[2]",           
                },
                {
                    key: "year",
                    instance: 1,
                    relativePos: 1,
                    regex: "(^Week) (\\d\\d?)$",  
                    regexGroup: 1,      
                    pattern: "Week",
                },
                {
                    key: "overall",
                    instance: 1,
                    relativePos: 1,
                    pattern: "Overall Standing:"
                },
                {
                    key: "safetyAndCompliance",
                    instance: 1,
                    relativePos: 1,
                    pattern: "Safety and Compliance:"            
                },
                {
                    key: "safetyScore",
                    instance: 1,
                    relativePos: 1,
                    minPage: 2,
                    pattern: "Safety Score*"
                },
                {
                    key: "fico",
                    instance: 1,
                    relativePos: 0,
                    regex: "\((\\d\\d\\d) (FICO)\)",
                    regexGroup: 2,
                    pattern: "FICO",
                    transform: "parseInt(value.replace(/[^0-9]/g, ''))"
                },
                {
                    key: "seatbeltOff",
                    instance: 1,
                    relativePos: 1,
                    minPage: 2,                    
                    pattern: "Seatbelt-Off Rate",
                    transform: "value.split(' | ')[0]"
                },
                {
                    key: "seatbeltOffText",
                    instance: 1,
                    relativePos: 1,
                    minPage: 2,
                    pattern: "Seatbelt-Off Rate",
                    transform: "value.split(' | ')[1]"
                },
                {
                    key: "speedingEvent",
                    instance: 1,
                    relativePos: 1,
                    minPage: 2,                    
                    pattern: "Speeding Event Rate",
                    transform: "value.split(' | ')[0]"
                },
                {
                    key: "harshBrakingEvent",
                    instance: 1,
                    relativePos: 1,
                    minPage: 2,
                    pattern: "Harsh Braking Event",
                    transform: "value.split(' | ')[0]"
                },
                {
                    key: "harshCorneringEvent",
                    instance: 1,
                    relativePos: 1,
                    minPage: 2,
                    pattern: "Harsh Cornering Event",
                    transform: "value.split(' | ')[0]"
                },
                {
                    key: "speedingEventText",
                    instance: 1,
                    relativePos: 1,
                    minPage: 2,
                    pattern: "Speeding Event Rate",
                    transform: "value.split(' | ')[1]"
                },
                {
                    key: "dvcrCompliance",
                    instance: 1,
                    relativePos: 1,                   
                    pattern: "DVCR Compliance",
                    transform: "value.split(' | ')[0]"
                },
                {
                    key: "dvcrComplianceText",
                    instance: 1,
                    relativePos: 1,
                    pattern: "DVCR Compliance",
                    transform: "value.split(' | ')[1]"
                },
                {
                    key: "onTimeCompliance",
                    instance: 1,
                    relativePos: 1,                    
                    pattern: "On-Time PM Compliance",
                    transform: "value.split(' | ')[0]"
                },
                {
                    key: "onTimeComplianceText",
                    instance: 1,
                    relativePos: 1,
                    pattern: "On-Time PM Compliance",
                    transform: "value.split(' | ')[1]"
                },
                {
                    key: "complianceScoreText",
                    instance: 1,
                    relativePos: 1,
                    pattern: "Compliance Score"
                },
                {
                    key: "workingHoursCompliance",
                    instance: 1,
                    relativePos: 1,                    
                    pattern: "Working Hours Compliance (WHC)",
                    transform: "value.split(' | ')[0]"
                },
                {
                    key: "workingHoursComplianceText",
                    instance: 1,
                    relativePos: 1,
                    pattern: "Working Hours Compliance (WHC)",
                    transform: "value.split(' | ')[1]"
                },
                {
                    key: "customerEscalationDefectDPMO",
                    instance: 1,
                    relativePos: 1,                    
                    pattern: "Customer Escalation Defect DPMO",
                    transform: "value.split(' | ')[0]"
                },
                {
                    key: "customerEscalationDefectDPMOText",
                    instance: 1,
                    relativePos: 1,
                    pattern: "Customer Escalation Defect DPMO",
                    transform: "value.split(' | ')[1]"
                },
                {
                    key: "team",
                    instance: 1,
                    relativePos: 1,
                    pattern: "Team:"
                },
                {
                    key: "highPerformersShare",
                    instance: 1,
                    relativePos: 1,                   
                    pattern: "High Performers Share",
                    transform: "value.split(' | ')[0]"
                },
                {
                    key: "highPerformersShareText",
                    instance: 1,
                    relativePos: 1,
                    pattern: "High Performers Share",
                    transform: "value.split(' | ')[1]"
                },
                {
                    key: "lowPerformersShare",
                    instance: 1,
                    relativePos: 1,                   
                    pattern: "Low Performers Share",
                    transform: "value.split(' | ')[0]"
                },
                {
                    key: "lowPerformersShareText",
                    instance: 1,
                    relativePos: 1,
                    pattern: "Low Performers Share",
                    transform: "value.split(' | ')[1]"
                },
                {
                    key: "attritionRate",
                    instance: 1,
                    relativePos: 1,                    
                    pattern: "Attrition Rate [4 week trailing]",
                    transform: "value.split(' | ')[0]"
                },
                {
                    key: "attritionRateText",
                    instance: 1,
                    relativePos: 1,
                    pattern: "Attrition Rate [4 week trailing]",
                    transform: "value.split(' | ')[1]"
                },              
                {
                    key: "quality",
                    instance: 1,
                    relativePos: 1,
                    pattern: "Quality:"               
                },
                {
                    key: "deliveryCompletion",
                    instance: 1,
                    relativePos: 1,                    
                    pattern: "Delivery Completion Rate",
                    transform: "value.split(' | ')[0]"
                },
                {
                    key: "deliveryCompletionText",
                    instance: 1,
                    relativePos: 1,
                    pattern: "Delivery Completion Rate",
                    transform: "value.split(' | ')[1]"
                },  
                {
                    key: "deliveredAndReceived",
                    instance: 1,
                    relativePos: 1,                    
                    pattern: "Delivered and Received",
                    transform: "value.split(' | ')[0]"
                },
                {
                    key: "deliveredAndReceivedText",
                    instance: 1,
                    relativePos: 1,
                    pattern: "Delivered and Received",
                    transform: "value.split(' | ')[1]"
                },
                {
                    key: "standardWorkComplianceText",
                    instance: 1,
                    relativePos: 1,
                    pattern: "Standard Work Compliance"
                },
                {
                    key: "photoOnDelivery",
                    instance: 1,
                    relativePos: 1,
                    pattern: "Photo-On-Delivery",
                    transform: "value.split(' | ')[0]"
                },
                {
                    key: "photoOnDeliveryText",
                    instance: 1,
                    relativePos: 1,
                    pattern: "Photo-On-Delivery",
                    transform: "value.split(' | ')[1]"
                },
                {
                    key: "contactCompliance",
                    instance: 1,
                    relativePos: 1,                    
                    pattern: "Contact Compliance",
                    transform: "value.split(' | ')[0]"
                },
                {
                    key: "contactComplianceText",
                    instance: 1,
                    relativePos: 1,
                    pattern: "Contact Compliance",
                    transform: "value.split(' | ')[1]"
                },
                {
                    key: "scanCompliance",
                    instance: 1,
                    relativePos: 1,
                    pattern: "Scan Compliance",
                    transform: "value.split(' | ')[0]"
                },
                {
                    key: "scanComplianceText",
                    instance: 1,
                    relativePos: 1,
                    pattern: "Scan Compliance",
                    transform: "value.split(' | ')[1]"
                },
                {
                    key: "attendedDeliveryAccuracy",
                    instance: 1,
                    relativePos: 1,                    
                    pattern: "Attended Delivery Accuracy",
                    transform: "value.split(' | ')[0]"
                },
                {
                    key: "attendedDeliveryAccuracyText",
                    instance: 1,
                    relativePos: 1,
                    pattern: "Attended Delivery Accuracy",
                    transform: "value.split(' | ')[1]"
                },
                {
                    key: 'signSignalViolationsRate',
                    instance: 1, 
                    relativePos: 1, 
                    pattern: "Sign/Signal Violations Rate",
                    transform: "value.split( ' | ')[0]"
                },        
                {
                    key: 'signSignalViolationsRateText',
                    instance: 1, 
                    relativePos: 1, 
                    pattern: "Sign/Signal Violations Rate",
                    transform: "value.split( ' | ')[1]"
                },        
                {
                    key: 'distractionsRate',
                    instance: 1, 
                    relativePos: 1, 
                    pattern: "Distractions Rate",
                    transform: "value.split( ' | ')[0]"
                },        
                {
                    key: 'distractionsRateText',
                    instance: 1, 
                    relativePos: 1, 
                    pattern: "Distractions Rate",
                    transform: "value.split( ' | ')[1]"
                },        
                {
                    key: 'followingDistanceRate',
                    instance: 1, 
                    relativePos: 1, 
                    pattern: "Following Distance Rate",
                    transform: "value.split( ' | ')[0]"
                },        
                {
                    key: 'followingDistanceRateText',
                    instance: 1, 
                    relativePos: 1, 
                    pattern: "Following Distance Rate",
                    transform: "value.split( ' | ')[1]"
                }        
    
            ],
            tables: [
                {
                    table_pattern:[
                        "drivers_ranked_by_overall_score__descending",
                        "#"
                     ],
                    table_match_percent: 1,
                    table_data:[],
                    table_end_string: "Drivers ranked by overall score, descending.",
                    min_data: 8,
                    columns: [
                        { 
                            slug: "name",
                            left: 0.026,
                            right: 0.139
                        },
                        { 
                            slug: "transporter_id",
                            left: 0.139,
                            right: 0.254
                        },
                        { 
                            slug: "overal_tier",
                            left: 0.254,
                            right: 0.31
                        },
                        { 
                            slug: "delivered",
                            left: 0.31,
                            right: 0.363,
                        },
                        { 
                            slug: "key_focus_area",
                            left: 0.363,
                            right: 0.496
                        },
                        { 
                            slug: "dcr",
                            left: 0.504,
                            right:0.562,
                        },
                        { 
                            slug: "dar",
                            left: 0.562,
                            right: 0.62,
                        },
                        { 
                            slug: "swc_pod",
                            left: 0.62,
                            right: 0.678,
                        },
                        { 
                            slug: "swc_cc",
                            left: 0.678,
                            right: 0.736,
                        },
                        { 
                            slug: "swc_sc",
                            left: 0.736,
                            right: 0.794,
                        },
                        { 
                            slug: "swc_ad",
                            left: 0.794,
                            right: 0.852,
                        },
                        { 
                            slug: "dnrs",
                            left: 0.86,
                            right: 0.906,
                        },
                        { 
                            slug: "pod_opps",
                            left: 0.906,
                            right: 0.953,
                        },
                        { 
                            slug: "cc_opps",
                            left: 0.953,
                            right: 1.0,
                        }
                    ]
                },
                {
                    table_pattern:[
                            "drivers_ranked_by_overall_score__descending",
                            "fant"
                    ],
                    table_match_percent: 1,
                    table_data:[],
                    min_data: 12,
                    columns: [
                        {
                            slug: "name",
                            left: 0.05198065936565399,
                            right:0.17115876078605652
                        },
                        {
                            slug: "transporter_id",
                            left: 0.17115876078605652,
                            right: 0.29660940170288086
                        },
                        {
                            slug: "average_tier",
                            left: 0.29660940170288086,
                            right: 0.34888049960136414
                        },
                        {
                            slug: "dcr",
                            left: 0.34888049960136414,
                            right: 0.40742412209510803
                        },
                        {
                            slug: "dar",
                            left: 0.40742412209510803,
                            right: 0.46596774458885193
                        },
                        {
                            slug: "swc_pod",
                            left: 0.46596774458885193,
                            right: 0.5224205255508423
                        },
                        {
                            slug: "swc_cc",
                            left: 0.5224205255508423,
                            right: 0.5809641480445862
                        },
                        {
                            slug: "swc_sc",
                            left: 0.5809641480445862,
                            right: 0.6395078301429749
                        },
                        {
                            slug: "swc_ad",
                            left: 0.6395078301429749,
                            right: 0.6959605813026428
                        },
                        {
                            slug: "high_low_performer",
                            left: 0.6959605813026428,
                            right: 0.7984119653701782
                        },
                        {
                            slug: "fant",
                            left: 0.798411965370178,
                            right: 0.8318654298782349
                        },
                        {
                            slug: "great",
                            left: 0.8318654298782349,
                            right: 0.8674097657203674
                        },
                        {
                            slug: "fair",
                            left: 0.8674097657203674,
                            right: 0.8987724781036377
                        },
                        {
                            slug: "poor",
                            left: 0.8987724781036377,
                            right: 0.9426801800727844
                        },
                    ]
                }
            ]
        },
    ]
}