<template>
  <div v-loading="loading" class="settings-message-templates" data-cy="message-templates">
    <!-- MESSAGE TEMPLATES -->
    <template v-if="hasMessageTemplatePermission">
      <TableCard>
        <template v-slot:table-filters>
          <ContainerFilter inTable>
            <template v-slot:title>
              MESSAGE TEMPLATES
              <span class="text-xs ml-1" data-cy="messenger-tooltip">
              <el-tooltip class="item" effect="dark" :placement="isDeviceMobileOnly() ? 'bottom' : 'right'" popper-class="custom-tooltip-lowest-z-index">
                <div slot="content" class=" max-w-64">
                  <div>You can use Message Templates to quickly pre-fill messages when messaging Associates.</div>
                </div>
                <i class="uil uil-question-circle text-base"></i>
              </el-tooltip>
            </span>
            </template>
          </ContainerFilter>
        </template>

        <template v-slot:table-searchbox>
          <SearchBox inTable @search-data="handleSearch" />
        </template>

        <CustomTable
            v-if="!loading"
            :columns="columns"
            :data="filteredData"
            :total-records="tableData.length"
            :options-rows="optionsRow"
            :options-header="optionsHeader"
            :withTableMenu="true"
            empty-text="No Messages Templates"
            @click-menu="handleCommand"
            inTable
            footer-table=""
            :expandCollapseColumn="true"
        />
      </TableCard>

      <div class="flex space-x-2 items-center" v-if="drawerData.visible">
        <Drawer
        v-if="drawerData.visible"
        :title="drawerData.title" 
        :visibility="drawerData.visible" 
        @close-drawer="cleanForm"
        >
            <template v-slot:drawerContent>
                <div class="p-5">
                  <el-form
                      :model="messageTemplateForm"
                      size="small"
                      ref="messageTemplateForm"
                      :rules="messageTemplateFormRules"
                  >
                    <el-form-item label="Template Name" prop="name">
                      <el-input v-model="messageTemplateForm.name" :maxlength="50" type="text" placeholder="Aa" data-cy="template-name-in"/>
                    </el-form-item>
                  </el-form>

                  <form class="compose-message w-full">
                    <div class="compose-message">
                      <EmojiTextArea
                        ref="newMessageRef"
                        placeholder="New message"
                        @content-changed='updateNewMessage'
                      ></EmojiTextArea>
                    </div>
                    <div>
                      <div class="text-xs text-right p-3 flex items-center justify-end">
                        <span :class="charsColor" class="font-bold">{{ chars }}/{{ maxChars }}</span>
                        <span class="text-xs ml-1" data-cy="messenger-tooltip">
                            <el-tooltip class="item" effect="dark" :placement="isDeviceMobileOnly() ? 'bottom' : 'left-start'">
                                <div slot="content">Unfortunately, cell phone carriers limit the length of text messages.<br><br>If you need to send a long message, there are two options: <br> 1. You can break up the message and send it in multiple parts, and <br> 2. if it is very long, you may want to turn it into a newsletter, save it <br>as a PDF and attach it to the message. <br><br> A PDF newsletter will be easier for the {{
                                    $t('label.associate')
                                  }} to read and digest all of <br>the information.</div>
                                <i class="uil uil-question-circle icon-button text-base"></i>
                            </el-tooltip>
                        </span>
                      </div>
                    </div>
                  </form>
                </div>
          </template>
          <template v-slot:drawerfooter-end>
            <ButtonGroupWrapper>
              <template slot-scope="{disabled, setDisabled}">
                <el-button
                  data-cy="drawer-cancel-btn"
                  :disabled="disabled"
                  @click="cleanForm"
                >
                  Cancel
                </el-button>
                <ButtonWrapper
                  type="primary"
                  data-cy="drawer-save-btn"
                  :clickHandler="handleSave"
                  :disabled="disabled || !messageValidator || !messageTemplateForm.name.length || messageTemplateNameExists || loading"
                  @cb-start="setDisabled(true)"
                  @cb-end="setDisabled(false)"
                >
                  <template v-if="drawerData.type === 'create'">Create</template>
                  <template v-else-if="drawerData.type === 'edit'">Update</template>
                </ButtonWrapper>
              </template>
            </ButtonGroupWrapper>
          </template>
        </Drawer>
      </div>
    </template>

    <br>

    <RecurringMessages v-if="openRecurringMessage" :activePermission="actionActive"/>

    <br>

    <template v-if="hasMessengerPermission">
      <Messages />
    </template>
  </div>
</template>

<script>
import Messages from "@/views/Settings/Messages/Messages";
import TableCard from "@/components/TableCard/TableCard";
import ContainerFilter from "@/components/TableCard/ContainerFilter";
import CustomTable from "@/components/TableCard/CustomTable";
import SearchBox from "@/components/TableCard/SearchBox";
import RecurringMessages from "@/views/Settings/Messenger/RecurringMessages/RecurringMessages";
import {messageTemplatesByGroup} from "@/graphql/queries";
import {createMessageTemplate, deleteMessageTemplate, updateMessageTemplate} from "@/graphql/mutations";
import {mapGetters,mapState} from "vuex";
import Drawer from '@/components/shared/Drawer/Drawer'
import {validateIsPremium,validateHadPremium} from '@/utilities/premiumValidator'
import ButtonWrapper from '@/components/shared/ButtonWrapper/ButtonWrapper';
import ButtonGroupWrapper from '@/components/shared/ButtonGroupWrapper/ButtonGroupWrapper';
import EmojiTextArea from '@/components/shared/Messenger/EmojiTextArea'
import { calculateBytes } from "@/utilities/countCharacters";

export default {
  name: 'Messenger',
  components: {
    SearchBox,
    CustomTable,
    ContainerFilter,
    TableCard,
    Messages,
    RecurringMessages,
    Drawer,
    ButtonWrapper,
    ButtonGroupWrapper,
    EmojiTextArea,
  },
  props: ['group'],
  data() {
    return {
      loading: false,
      tableData: [],
      hasMessageTemplatePermission: '',
      hasMessengerPermission: '',
      messageTemplates: [],
      search: '',
      columns: [
        { name: 'Template Name', col: 'name', fixed: false, sortable: false, },
        { name: 'Date Created', col: 'createdAt', fixed: false, sortable: false, },
        { name: 'Date Updated', col: 'updatedAt', fixed: false, sortable: false, },
      ],
      optionsRow: [
        { label: 'Edit', action: 'edit' },
        { label: 'Delete', action: 'delete' }
      ],
      optionsHeader: [
        { label: 'Create Template', action: 'create' },
      ],
      drawerData: {},
      messageValidator: false,
      messageTemplateForm: {
        name: '',
        message: ''
      },
      messageTemplateFormRules: {
        name: [
          {required: true, trigger: 'change', message: 'This filed is required'},
          {max: 50, trigger:' change', message: 'The length is between 2 and 50 characters'},
          {
            required: true,
            validator: (rule, _, callback) => {
              if (this.messageTemplateNameExists)
                callback(new Error('Template name already exists'))
              else
                callback()
            },
            trigger: 'change'
          }
        ],
      },
      chars: 0,
      maxChars: 918,
      actionActive: {
        all:false,
        onlyRead:false
      },
      openRecurringMessage:false,
    }
  },

  beforeMount() {
    this.hasMessageTemplatePermission = this.hasMessageTemplateManagement
    this.hasMessengerPermission = this.hasMessengerManagement
  },

  mounted() {
    this.loadData()
  },

  computed: {
    ...mapState(['userInfo']),
    ...mapGetters(['hasMessageTemplateManagement', 'hasMessengerManagement']),

    filteredData() {
      return this.filterArray(this.tableData, this.search);
    },

    messageTemplateNameExists () {
      const id = this.messageTemplateForm?.id
      const messageTemplateName = this.messageTemplateForm?.name
      if (!messageTemplateName) {
        return false
      }
      let predicate
      if (this.drawerData.type === 'create')
        predicate = (row) => row.name.toLowerCase().trim() === messageTemplateName.toLowerCase().trim()
      if (this.drawerData.type === 'edit')
          predicate = (row) => row.name.toLowerCase().trim() === messageTemplateName.toLowerCase().trim() && row.id !== id
      return this.tableData.some(predicate)
    },
    charsColor () {
      if (this.chars >= this.maxChars) {
        return 'text-red-600'
      }
      return 'text-black-600'
    },
  },

  methods: {
    async loadData() {
      try {
        this.loading = true
        let input = {
          group: this.$store.state.userInfo.tenant.group
        }

        this.tableData = await this.gLoadListAll(messageTemplatesByGroup, input, 'messageTemplatesByGroup')
        this.tableData = this.tableData.map(obj => {
          return {
            id: obj.id,
            group: obj.group,
            name: obj.name,
            message: obj.message,
            createdAt: this.$moment(obj.createdAt).format('M/D/YYYY h:mm A'),
            updatedAt: this.$moment(obj.updatedAt).format('M/D/YYYY h:mm A')
          }
        })

        this.tableData = this.tableData.sort((a, b) => {
          if (a.name.toLowerCase() > b.name.toLowerCase()) {
            return 1
          }
          if (b.name.toLowerCase() > a.name.toLowerCase()) {
            return -1
          }
          return 0
        })

      } catch (error) {
        this.displayUserError(error)
      } finally {
        this.loading = false;
      }
      this.permissionStatus()
    },
    async permissionStatus(){
      const tenant = this.userInfo.tenant
      const suscriptionCaseOne = ['rostering','trial','bundle']
      const suscriptionCaseTwo = ['rostering','bundle']
      this.actionActive.all = validateIsPremium(tenant,suscriptionCaseOne)
      this.actionActive.onlyRead = await validateHadPremium(tenant,suscriptionCaseTwo)
      this.openRecurringMessage = true
    },
    handleCommand({action, row}) {
      switch (action) {
        case 'create':
          this.drawerData = {
            type: 'create',
            visible: true,
            title:'Create a new Message Template'
          }
          this.messageValidator = false
          break;
        case 'edit':
          this.drawerData = {
            type: 'edit',
            visible: true,
            title:'Edit Message Template'
          }
          this.chars = calculateBytes(row.message.trim())
          this.messageValidator = true
          this.messageTemplateForm = {...row}
          this.loadMessageInTemplateForm()
          break;
        case 'delete':
          this.$confirm('Would you like to delete this message template?', 'Warning', {
            confirmButtonText: 'Delete',
            cancelButtonText: 'Cancel',
            type: 'warning'
          }).then(() => {
            this.messageTemplateForm = {...row}
            this.handleDelete()
          })
          break;
      }
    },
    loadMessageInTemplateForm(){
      this.$nextTick(()=>{
        this.$refs.newMessageRef.addText(this.messageTemplateForm.message)
      });
    },
    async handleSearch(word) {
      this.search = word
    },

    async handleSave() {
      this.loading = true
      try {
        await this.$refs.messageTemplateForm.validate();
        try {
          if (!this.messageValidator) {
            throw new Error('Error to create/update Message Template.');
          }

          const input = {
            ...this.messageTemplateForm,
          }

          switch (this.drawerData.type) {
            case 'create':
              delete input.id
              await this.api(createMessageTemplate, {input})
              this.$message({
                type: 'success',
                message: 'Message Template Created.'
              })
              break;
            case 'edit':
              delete input.createdAt
              delete input.updatedAt
              await this.api(updateMessageTemplate, {input})
              this.$message({
                type: 'success',
                message: 'Message Template Updated.'
              })
              break;
          }
        } catch (error) {
          this.displayUserError(error)
        } finally {
          this.cleanForm()
          this.loadData()
        }
      }
      catch (error) {
        this.displayUserError(error);
      }
    },
    async handleDelete() {
      this.loading = true
      try {
        const input = {
          id: this.messageTemplateForm.id
        }
        await this.api(deleteMessageTemplate, {input})
        this.$message({
          type: 'success',
          message: 'Message Template Deleted'
        })
      } catch (error) {
        this.displayUserError(error)
      } finally {
        this.cleanForm()
        this.loadData()
      }
    },
    /**
     * Keeps message in sync with emoji picker
     */
    async updateNewMessage(text) {
      if (text.trim().includes('<br>')) {
        text = text.replace('<br>', '');
      }
      this.chars = calculateBytes(text.trim());
      (this.chars > 918) ? document.getElementById("twemoji-textarea").style.color = "red" : document.getElementById("twemoji-textarea").style.color = "black";

      if (this.chars === 0) {
        this.messageTemplateForm.message = text;
        this.messageValidator = false
      } else if (this.chars > this.maxChars){
        this.messageTemplateForm.message = text;
        this.messageValidator = false
      } else {
        this.messageTemplateForm.message = text;
        this.messageValidator = true
      }
    },
    cleanForm() {
      this.drawerData.visible = false;
      this.messageTemplateForm.name = ''
      this.messageTemplateForm.message = ''
      this.chars = 0
    },
  },
}
</script>

<style lang="scss">

.el-drawer__header {
  @apply bg-brand;
  padding: 16px !important;
  margin-bottom: 0 !important;
  color: #fff !important;
}

.compose-message {
  background-color: rgb(247, 247, 247);
}

.mobile-messenger-header {
  background-color: rgba(255, 255, 255, 1);
  z-index: 15;
  top: 100px;
}

.custom-drawer__content #twemoji-textarea {
  height: 150px;
  width: 100% !important;
  max-width: 400px !important;
  @apply overflow-y-scroll;
  @apply max-h-20;
}

.flexbox {
  align-items: center;
  display: flex;
  justify-content: right;
}

/*@media (max-width: 375px) {
  .custom-drawer__content #twemoji-textarea {
    width: 204px !important;
  }
}*/

@media (max-width: 1400px) {
  .el-drawer {
    width: 50% !important;
  }

  .custom-drawer__content #twemoji-textarea {
    max-width: 450px !important;
  }
}

@media (max-width: 1000px) {
  .el-drawer {
    width: 60% !important;
  }

  .custom-drawer__content #twemoji-textarea {
    max-width: 350px !important;
  }
}

@media (max-width: 767px) {
  .el-drawer {
    width: 80% !important;
  }
}
</style>