import {
    onUpdateTenantById,
} from '../../subscriptions';
import { recalculateDaPerformanceData } from '@/views/DaPerformanceReport/calculateDaPerformanceReport'
import store from '@/store'
import { initializeSubscription, disconnectSubscription, updateElement, updateRenderKey } from '../helper'

"use strict";
let subscriptionTenant = null

export const tenantSubscribe = function(instance){
    const queries = {
        onUpdateTenantById,
    }
    if(!store.state.userInfo?.tenant?.id) return
    const input = {
        id: store.state.userInfo.tenant.id
    }
    initializeSubscription(subscriptionTenant, queries, input, "subscriptionStore/handleTenant", instance)
}

export const config = function(subscriptions){
    const subscription = {}
    subscriptionTenant = subscriptions['tenant'] = {
        loadList: null,
        subscribe: tenantSubscribe,
        unsubscribe: (instance) => disconnectSubscription(subscription, instance),
        callback: tenantEventCallback,
        subscription
    }
}

export const tenantEventCallback = async function(subscriptionState, { data: tenant }){
    const currentTenant = store.state.userInfo.tenant
    const tenantUpdated = updateElement(currentTenant, tenant, true)
    if(tenantUpdated.__ob__){
        updateRenderKey(tenantUpdated.__ob__)
    }
    store.commit('setTenantCoachingInfo', tenant)
    await recalculateDaPerformanceData(true)
}