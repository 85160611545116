export function mapFeatureTenant(TenantData) {

    const featureFlags = [
        {
            accessKey: 'featureAccessVehiclePhotoLogsHeraAi',
            enabledKey: 'featureEnabledVehiclePhotoLogsHeraAi',
            accessName: 'Vehicle Management: Vehicle Photo Logs Hera AI',
            enabledName: 'Vehicle Management: Vehicle Photo Logs Hera AI'
        },
        {
            accessKey: 'featureAccessInventoryManagement',
            enabledKey: 'featureEnabledInventoryManagement',
            accessName: 'Inventory Management',
            enabledName: 'Inventory Management'
        }
    ];

    featureFlags.forEach(({ accessKey, enabledKey, accessName }) => {
        TenantData[accessKey] = TenantData.featureAccess.includes(accessName);
        TenantData[enabledKey] = TenantData.featureEnabled.includes(accessName);
    });

    return TenantData;
}