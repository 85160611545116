export const exportCSV = ({ arrData, fileName, extraData = null }) => {
  let prefix = "data:text/csv;charset=utf-8,\uFEFF";
  let header = `${Object.keys(arrData[0]).join(",")}`;
  let csvContent = header + "\r\n";
  let transformArrayToString = (row) => {
    let item = []
    Object.values(row).forEach(value => {
      const columnData = Array.isArray(value) ? `"${value.filter(v => !!v).join('; ')}"` : `"${String(value ?? "").replace(/"/g, '""')}"`;
      item.push(columnData)
    })
    return item
  }

  arrData.forEach(row => {
    let item = transformArrayToString(row)
    const newItem = Object.values(item).join(",")
    csvContent += newItem + "\r\n";
  });

  if (extraData) {
    csvContent += "\r\n";
    extraData.forEach(row => {
      const newHeaders = `${Object.keys(extraData[0]).join(",")}`;
      csvContent += newHeaders + "\r\n";
      let item = transformArrayToString(row)
      const newItem = Object.values(item).join(",");
      csvContent += newItem + "\r\n";
    });
  }

  const encodeURI = prefix + encodeURIComponent(csvContent);

  const link = document.createElement("a");
  link.setAttribute("href", encodeURI);
  link.setAttribute("download", fileName);
  link.click();
};

export const generateCSV = ({records: arrData, fileName, extraData = null}) => {
  let prefix = "data:text/csv;charset=utf-8,\uFEFF";
  let headers = `NAME,VEHICLE STATUS,ACCIDENT DATE,LOCATION,MAINTENANCE STATUS,SERVICES`;
  let csvContent = headers + "\r\n";
  arrData.forEach((item) => {
    const { name, statusVehicle, records } = item;
    records.forEach( record => {
      const { accidentDate, location, maintenanceStatus, services } = record;
      const listServices = services.join(", ");
      const newItem = `${name},${statusVehicle},${accidentDate},${location},${maintenanceStatus},"${listServices}"`;
      csvContent += newItem + "\r\n";
    })
  });

  const encodeURI = prefix + encodeURIComponent(csvContent);

  const link = document.createElement("a");
  link.setAttribute("href", encodeURI);
  link.setAttribute("download", fileName);
  link.click();
}