<template>
  <div class="search-wrap" data-cy="search-container">
    
    <table-card tablecard-class="rounded-lg">
      <template v-slot:table-filters>
        <container-filter v-if="!!hasVehicleManagementPermission && !!hasDAManagement" inTable>
          <template v-slot:title>
            <span>SEARCH</span>
          </template>
          <template v-slot:filter-count>
            <counter-filter :counter="getResumeFilters" />
          </template>          
          <template v-slot:filters>              
              <MultiSelectFilter  
                :type="searchTypeFilter"
                title="Search Type"
                filterAllTitle="Search Types"
                :options="searchTypeOptions"
                :selected="searchTypeOptionsSelected"
                @update-filters="updateFilters"
              />
          </template>
        </container-filter>
      </template>
      <template v-slot:table-searchbox>
        <search-box :searchString="search" data-cy="filter" inTable @search-data="searchData" :placeholder="placeholder"/>
      </template>

    <!--Table-->
      <custom-table
      v-loading="loading"
      ref="table"
      inTable
      emptyText="No Results"
      footerTable="Total Results"
      :showFooterText="false"
      class="search-index"
      :columns="cols"
      :data="tableData"
      :filtersTotal="getResumeFilters"
      :totalRecords="tableTotal"
      :pageSize="pageSize"
      :expandCollapseColumn="false"
      :optionsRows="[]"
      @click-menu="handleCommand"
      @sort-change="sortColumn"
      pagination
      :paginationCurrentPage="currentPage"
      :paginationPageSize="pageSize"
      @pagination-page-size-change="handleSizeChange"
      @pagination-page-change="pageChange"
      >
        <template #type="row">
          <i class="uil mr-2" v-bind:class="{ 'uil-truck text-green-400': isVehicle(row.type), 'uil-user text-blue-400': !isVehicle(row.type) }"></i>
          <span>{{ row.type }}</span>

        </template>
        <template #fields="row">
          <ul v-if="renderFields.length">
            <li v-for="(item,index) in renderFields(row.fields)" :key="index">
              {{ item  }}: {{ row[mapField[item]] }}
            </li>
          </ul>
        </template>
        <template #row-action-menu="row">
            <el-tooltip class="item flex-initial" effect="dark" :content="`View Record`" placement="bottom">
                <i data-cy="view-record" @click="handleCommand({row, action: 'view'})" class="icon-button uil uil-file-landscape-alt text-2xl"></i>
            </el-tooltip>
        </template>

        
      </custom-table>
    </table-card>
  </div>
</template>
  
<script>
import { mapState, mapGetters, mapMutations } from "vuex";
import ContextMenuHeaderIcon from '@/components/ContextMenuHeaderIcon'
import TableCard from "@/components/TableCard/TableCard";
import CustomTable from "@/components/TableCard/CustomTable";
import ContainerFilter from "@/components/TableCard/ContainerFilter";
import SearchBox from "@/components/TableCard/SearchBox";
import MultiSelectFilter from "@/components/MultiSelectFilter";
import CounterFilter from "@/components/CounterFilters";
import { API } from "aws-amplify";

export default {
  name: "SearchIndex",
  components: {
    ContextMenuHeaderIcon,
    TableCard,
    CustomTable,
    ContainerFilter,
    SearchBox,
    MultiSelectFilter,
    CounterFilter,
  },
  data() {
    return {
        search: '',
        placeholder: '',
        loading: false,
        tableData: [],

        searchTypeOptions: ['Vehicle', 'Associate'],
        searchTypeOptionsSelected: ['Vehicle', 'Associate'],

        cols: [
            { name: "Name", col: "name", fixed: false, width: "220", sortable: "custom"},
            { name: "Record Type", col: "type", fixed: false, width: "140", sortable: "custom"},
            { name: "Status", col: "status", fixed: false, width: "140", sortable: "custom"},
            { name: "Matching Fields", col: "fields", fixed: false, width: "220", sortable: false}
        ],

        fieldMap: { "id": "ID", "firstName": "First Name", "lastName": "Last Name", "status": "Status", "transporterId": "Transporter ID", "email": "Email", "name": "Name", "vin": "VIN", "licensePlate": "License Plate", "phone": "Phone" },
        mapField: { "ID":"id", "First Name": "firstName", "Last Name":"lastName", "Status": "status", "Transporter ID":"transporterId", "Email": "email", "Name": "name", "VIN": "vin", "License Plate": "licensePlate", "Phone": "phone" },

        searchTypeFilter: "searchType",
        searchTypes: ["Vehicle", "Staff"],

        tableTotal: 0,
        currentPage: 1,
        pageSize: 25,
        sort: { 
          sortField: "rank", 
          sortDirection: "asc"
        },
        sortFieldMap: { 
          "name": "name", "type": "type", "staff.status": "s.status"  
        },

    }
  },

  created: function () {
    
    // set placeholder and searchable types based on permissions.
        // this component won't load if they don't have either of the two
        if(!this.hasDAManagement ){ 
            this.placeholder = 'Type to search Vehicles...'
            this.searchTypes = this.searchTypes.filter(t=>t!='Staff')
        }
        if(!this.hasVehicleManagementPermission ){ 
            this.placeholder = 'Type to search Associates...'
            this.searchTypes = this.searchTypes.filter(t=>t!='Vehicle')
        }

    if(this.$route.query?.q){
      this.search = this.$route.query['q']
      this.loadData()
    }

  },

  watch: {   
    $route (to, from){
        this.search = to.query['q']
        setTimeout(()=>this.search = to.query['q'],100)
        this.loadData()
    }
  },

  computed: {
    ...mapState(["userInfo", "valuelists"]),
    ...mapGetters([
      'getFiltersSearchType',
      'hasDAManagement',
      'hasVehicleManagementPermission'
    ]),

    getResumeFilters() {
      const filtersSearchType = this.searchTypeOptionsSelected.length === this.searchTypeOptions.length ? 0 : 1;      
      return filtersSearchType; 
    }

  },

  methods: {
    ...mapMutations([
     
    ]),

    isVehicle(type){ 
      return type === 'Vehicle' 
    }, 

    renderFields(fields) {
      if(!fields) return 
      return fields.split(',').filter(f=>!!f).map(field=>this.fieldMap[field])
    },

    updateFilters(e) {
      const { type, filters } = e;
      this.searchTypeOptionsSelected = filters;
      this.loadData()       
    },

    async handleCommand(payload) {
      const { row, action } = payload;
      if(action == "view") {
          let url = row.type == 'Associate' ? `/da-management/${row.id}` : `/vehicles/${row.id}`
          this.$router.push(url).catch(e => {})
      }
    },
    
    sortColumn(e){ 
      this.sort = { 
        sortField: e.prop, 
        sortDirection: e.order == "descending" ? "desc" : "asc"
      } 
      return this.loadData()                          
    },

    searchData(e) {
      this.search = e;
      //if(this.search.length > 2 || this.search.length===0) {
        this.loadData(true)
      //}
    },
    
    showPagination(){
      return !this.loading && this.tableTotal > this.pageSize
    },
    
    pageChange(e){
      this.currentPage = e
      this.loadData()        
    },
    
    handleSizeChange(e){
      this.currentPage = 1
      this.pageSize = e
      this.loadData()
    },

    async loadData(searchOrFilter){
      
      //if(!this.search) return
      this.loading = true
      
      let types = [...this.searchTypeOptionsSelected]
      if(this.searchTypes.length == 1) types = this.searchTypes

      let staffIndex = types.indexOf('Associate')
      if(staffIndex>-1) types[staffIndex] = 'Staff'
      console.log('search or filter', searchOrFilter)
      if(searchOrFilter){ 
        this.currentPage = 1
      }

      let 
      results,
      start = (this.currentPage-1) * this.pageSize,
      body = { 
        group: this.$store.state.userInfo.tenant.group, 
        search: this.search.replace(/\s+/g, ' '),
        types: types,
        matchingFields: true,
        sortField: this.sort.sortField,
        sortDirection: this.sort.sortDirection,
        offset: start < 0 ? 0 : start,
        limit: this.pageSize
      }
         
      try {
          results = await API.post('rds', '/query/Search', { body })
          this.tableData = results.data
          this.tableTotal = results.total
          this.loading = false 
      } catch (e) {
          this.tableData = []
          this.tableTotal = 0
          this.loading = false
      }
    }
  }
};
</script>

<style lang="scss">
.search-index .el-table--enable-row-transition .el-table__body td.el-table__cell {
    vertical-align: top;
}

@media only screen and (max-width: 768px) {                   
  .search-index .el-table .el-table__cell {
      padding: 4px 0;
  }
  .w-full.no-results {
      white-space: normal;
      line-height: 20px;
      padding: 15px 30px;
  }
}
</style>
