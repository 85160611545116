import {
    onCreateRouteByGroup,
    onUpdateRouteByGroup,
    onDeleteRouteByGroup
} from '../../subscriptions';
import store from '@/store'
import {
    initializeSubscription,
    disconnectSubscription,
    updateElement,
    updateRenderKey
} from '../helper'
import EventBus from '@/eventBus'

"use strict";
let subscriptionRoute = null

export const routeSubscribe = function(instance){
    const queries = {
        onCreateRouteByGroup,
        onUpdateRouteByGroup,
        onDeleteRouteByGroup
    }
    if(!store.state.userInfo?.tenant?.group) return
    const input = {
        group: store.state.userInfo.tenant.group
    }
    initializeSubscription(subscriptionRoute, queries, input, "subscriptionStore/handleRoute", instance)
}

export const config = function(subscriptions){
    const subscription = {}
    subscriptionRoute = subscriptions['route'] = {
        loadList: null,
        subscribe: routeSubscribe,
        unsubscribe: (instance) => disconnectSubscription(subscription, instance),
        callback: routeEventCallback,
        subscription
    }
}

export const routeEventCallback = function(subscriptionState, {data: route, eventName}){
    if(!route?.id) return
    const matchDr = subscriptionState.dailyRosterCache.find(dr => dr.id === route.routeDailyRosterId)
    if(!matchDr) return
    //temporarily disable subscription updates when editing route
    if(store.getters.getUpdatingDailyRosterRoute && eventName === 'onUpdate'){
       if(!subscriptionState.temporalRouteByDailyRosterId[route.routeDailyRosterId]){
        subscriptionState.temporalRouteByDailyRosterId[route.routeDailyRosterId] = []
       }    
       subscriptionState.temporalRouteByDailyRosterId[route.routeDailyRosterId].push(route)
       return
    }

    const routes =  matchDr.route.items
    switch(eventName){
        case 'onCreate':{
            routes.push(route)
            break
        }
        case 'onUpdate':{
            const matchR = routes.find(r => r.id === route.id)
            updateElement(matchR, route, true)
            break
        }
        case 'onDelete':{
            const index = routes.findIndex(r => r.id === route.id)
            if(index > -1){
                const deleted = routes.splice(index, 1)
            }
            break
        }
    }
    updateRenderKey(routes)
    EventBus.$emit('update-route-render', {
        target: "table-data-routes",
        dailyRoster: matchDr
    })
}