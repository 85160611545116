import {
    onCreateNoteByGroup,
    onUpdateNoteByGroup
} from '../../subscriptions';
import store from '@/store'
import {
    initializeSubscription,
    disconnectSubscription,
    updateElement,
    updateRenderKey
} from '../helper'
import EventBus from '@/eventBus'
import { API } from 'aws-amplify';

"use strict";
let subscriptionNote = null

export const noteSubscribe = function(instance){
    const queries = {
        onCreateNoteByGroup,
        onUpdateNoteByGroup,
    }
    if(!store.state.userInfo?.tenant?.group) return
    const input = {
        group: store.state.userInfo.tenant.group
    }
    initializeSubscription(subscriptionNote, queries, input, "subscriptionStore/handleNote", instance)
}

export const config = function(subscriptions){
    const subscription = {}
    subscriptionNote = subscriptions['note'] = {
        loadList: null,
        subscribe: noteSubscribe,
        unsubscribe: (instance) => disconnectSubscription(subscription, instance),
        callback: noteEventCallback,
        subscription
    }
}

export const noteEventCallback = function(subscriptionState, {data: note, eventName}) {
    if(!note?.id) return
    const notes = subscriptionState.notesCache
    if(!notes) return

    switch(eventName){
        case 'onCreate':{
            const matchR = notes[note.date]?.find(n => n.type === note.type)
            if (!matchR) {
                notes[note.date].push(note)
            } else {
                try {
                    let apiName = "heraPublicApi";
                    let path = "/executeMutation";
                    let post = {
                      body: {
                        type: "note",
                        mutation: "delete",
                        group: store.state.userInfo.tenant.group,
                        owner: store.state.userInfo.cognitoSub,
                        items: {
                            deleteNoteInput: note
                        },
                      },
                    };
                    safeFunction(API.post)(apiName, path, post);
                } catch (e) {
                    console.log("Error: ", e);
                }
            }
            break
        }
        case 'onUpdate':{
            const matchR = notes[note.date]?.find(n => n.id === note.id)
            updateElement(matchR, note, true)
            EventBus.$emit('reload-daily-rostering', {
                action: "update-note",
                noteType: note.type,
                note: matchR
            })

            EventBus.$emit('update-note', note)
            break
        }
    }
    updateRenderKey(notes)
}