<template>
    <div v-loading="loading">

        <div class="flex space-x-4 mb-4 items-center" >
            <i v-if="!systemData.importDisabled" :class="scorecard.class" class="uil uil-check-circle text-3xl -mx-2"></i>
            <label v-if="!systemData.importDisabled"  class="fileContainer flex-none text-center w-56" :class="{'opacity-25': loading, 'bg-gray-600': systemData.importDisabled}" >
                Select Scorecard PDF
                <input id="pdf-document-input-scorecard" :class="{'cursor-not-allowed': systemData.importDisabled}" :disabled="systemData.importDisabled" accept=".pdf" type="file" @change="(e)=>parseDoc(e,'scorecard')">
            </label>  
            <div class="flex-1 text-xs break-normal">
            <!-- Scorecard import will be back soon as we update for the latest Amazon changes. -->
            <p v-if="systemData.importDisabled" class="text-red-500 text-xs  font-bold mb-4">Scorecard imports are temporarily disabled and will be back soon as we update for the latest Amazon changes.</p>
            Please confirm the year is correct before continuing with POD and Customer Feedback imports. The POD and CX documents do <span class="font-bold">not</span> include the year, so it must be entered below.
            <!--<el-input size="mini" placeholder="Year"></el-input>-->
            </div>
        </div>

        <div class="flex space-x-4 mb-4 items-center" >
            <i :class="podQualityFeedback.class" class="uil uil-check-circle text-3xl -mx-2"></i>
            <label class="fileContainer flex-none text-center w-56" :class="{'opacity-25': loading, 'bg-gray-600': !podYear}" >
                Select POD Quality Report PDF
                <input id="pdf-document-input-pod" accept=".pdf" :class="{'cursor-not-allowed': !podYear}" :disabled="!podYear" type="file" @click="checkPremiumStatus('pdf-document-input-pod')" @change="(e)=>parseDoc(e,'podQualityFeedback')">
            </label>  
            <div class="flex-1">
   
                <el-select class="w-full" size="mini" v-model="podYear" placeholder="Year">
                    <el-option
                        v-for="(year) in years"
                        :key="year"
                        :label="year"
                        :value="year">
                    </el-option>
                </el-select>
 
                <!-- <el-input size="mini" v-model="podYear" placeholder="Year" v-mask="'####'"><template slot="prepend" >Year</template></el-input> -->
            </div>
            <div class="flex-initial">
                <el-tooltip class="item" effect="dark" content="The year is not listed on this PDF, so you are able to set it here." placement="top">
                    <i class="uil uil-question-circle text-base"></i>
                </el-tooltip>
            </div>
        </div>

        <div class="flex space-x-4 items-center" >
            <i :class="customerFeedback.class" class="uil uil-check-circle text-3xl -mx-2"></i>
            <label class="fileContainer flex-none text-center w-56" :class="{'opacity-25': loading, 'bg-gray-600': !cxYear}">
                Select Customer Feedback PDF
                <input id="pdf-document-input-cx" accept=".pdf" :class="{'cursor-not-allowed': !cxYear}" :disabled="!cxYear" type="file" @click="checkPremiumStatus('pdf-document-input-cx')" @change="(e)=>parseDoc(e,'customerFeedback')">
            </label>  
            <div class="flex-1">
                <el-select class="w-full" size="mini" v-model="cxYear" placeholder="Year">
                    <el-option
                        v-for="(year) in years"
                        :key="year"
                        :label="year"
                        :value="year">
                    </el-option>
                </el-select>
                <!-- <el-input size="mini" v-model="cxYear" placeholder="Year" v-mask="'####'"><template slot="prepend">Year</template></el-input> -->
            </div>
            <div class="flex-initial">
                <el-tooltip class="item" effect="dark" content="The year is not listed on this PDF, so you are able to set it here." placement="top">
                    <i class="uil uil-question-circle text-base"></i>
                </el-tooltip>
            </div>
        </div>
        
        
    </div>
</template>

<script>
import {mapState} from 'vuex'
import {Storage} from 'aws-amplify'
import { createTextractJob } from '@/graphql/mutations'
import { listSystems } from '@/graphql/queries'
import { getTenantData } from '@/utilities/getTenantData'

//Import Templates
import podQualityTemplate from './pod-quality-feedback-template'
import scorecardTemplate from './scorecard-template'
import customerFeedbackTemplate from './customer-feedback-template'

export default {
    // props:['type'],
    data(){
        return{
            loading: false,
            timer: null,
            scorecard: {
                class: "text-gray-600",
                loading: false
            },
            podQualityFeedback:{
                class: "text-gray-600",
                loading: false
            },
            customerFeedback:{
                class: "text-gray-600",
                loading: false
            },
            job: {},
            podYear: '',
            cxYear: '',
            years: [],
            systemData: {},
        }
    },
    mounted(){     
        this.loadSystem()
        this.createYears()   
        // this.podYear = this.$moment().format('YYYY')
        // this.cxYear = this.$moment().format('YYYY')
    },
    computed:{
        ...mapState(['userInfo']),
    },    
    methods:{
        // comment for merge
        /**
         * Uploads image to S3 and updates DB
         * @param {object} e - native js event object
         */
        async parseDoc(e, type){
            try{
                this[type].loading = true
                this.loading = true
                //Get file from event
                var files = e.target.files || e.dataTransfer.files;
                var file = files[0]  
                var lowerCaseFileName = file.name.toLowerCase()

                //Confirm a PDF was selected
                var pdfMatch = lowerCaseFileName.match(/pdf/)
                if(pdfMatch === null){
                    this.$alert('You must select a PDF', 'Error')
                    this.loading = false
                    return
                }


                //Set template info by pdf type and make they selected the correct document
                if(type == 'scorecard') {
                    var typeMatch = lowerCaseFileName.match(/dspscorecard/)
                    if(typeMatch === null){
                        await this.$confirm(file.name + " doesn't look like a Scorecard PDF. Do you wish to continue?", 'Correct File Type?', {
                        confirmButtonText: 'OK',
                        cancelButtonText: 'Cancel',
                        })
                    }
                    template = scorecardTemplate
                    year = ''
                }
                else if( type == 'podQualityFeedback') {
                    var typeMatch = lowerCaseFileName.match(/pod-quality/)
                    if(typeMatch === null){
                        await this.$confirm( file.name + " doesn't look like a POD Quality PDF. Do you wish to continue?", 'Correct File Type?', {
                        confirmButtonText: 'OK',
                        cancelButtonText: 'Cancel',
                        })
                    }
                    template = podQualityTemplate
                    year = this.podYear
                }
                else if( type == 'customerFeedback') {
                    var typeMatch = lowerCaseFileName.match(/customer_feedback/)
                    if(typeMatch === null){
                        await this.$confirm( file.name + " doesn't look like a Customer Feedback PDF. Do you wish to continue?", 'Correct File Type?', {
                        confirmButtonText: 'OK',
                        cancelButtonText: 'Cancel',
                        })
                    }
                    template = customerFeedbackTemplate
                    year = this.cxYear
                }
                
                //Try to determine the week.
                var weekMatch = lowerCaseFileName.match(/week(\d?\d)/);

                //If match is undefined, set a 0 week so user is prompted to fill in week.
                if( weekMatch === undefined || weekMatch === null){
                    weekMatch = [0,0] //Bullshit data to prompt user for week
                }

                //Get week out of the match groups
                var week = weekMatch[1]

                //Check for valid week number
                if( week < 1 || week > 53){
                    var prompt = await this.$prompt("We could not automatically determine the week from the filename. Please enter in the correct week number.")
                    week = prompt.value
                }

                // validate year
                if( type == 'podQualityFeedback' || type == 'customerFeedback') {
                    var docYear
                    if( type == 'podQualityFeedback') docYear = this.podYear
                    else docYear = this.cxYear

    
                    await this.$confirm( 'Correct Year?', {
                        message: `Please confirm the year is correct before continuing. <div class="mt-2">Selected year: <div class="font-bold">${docYear}</div></div> <div class="mt-2">Selected file: <div class="font-bold overflow-hidden">${file.name}</div></div>`,
                        dangerouslyUseHTMLString: true,
                        confirmButtonText: 'Continue',
                        cancelButtonText: 'Cancel & Edit Year',
                        type: 'warning'
                    })
                }


                //Upload to s3 bucket
                var object = await Storage.put(this.userInfo.tenant.group + '/' + type + '/'+ file.name, file, {
                    contentType: 'application/pdf'
                })
                var template
                var year
                let customerSubType = this.userInfo.tenant?.customerSubType;
                if(!customerSubType){
                    const data = await getTenantData(this.userInfo.tenant)
                    customerSubType = data?.customerSubType || null
                }

                //Create textract job
                let input = {
                    key: object.key,
                    jobStatus: "NOT_STARTED",
                    isProcessed: false,
                    isProcessedS: 'false', // need the string version since booleans can't be sort fields
                    type: type,
                    year: year,
                    week: week,
                    template: JSON.stringify(template),
                    textractJobTenantId: this.userInfo.tenant.id,
                    group: this.userInfo.tenant.group,
                    owner: this.userInfo.cognitoSub,
                    customerSubType
                }
                var response = await this.api(createTextractJob, {input})
                console.log(response)

                // spin up job monitor if it's not running
                // if(!this.$store.state.monitoringJobs) this.$store.dispatch('monitorJobsStatus')

                this[type].class = "text-green-500"
                this[type].loading = false
                this.loading = false
                //Wait for Complete
                // this.job = response.data.createTextractJob
                // var job = await this.checkDocStatus()
                // var download = await Storage.get(job.results, { download: true })
                // var json = await download.Body.text()
                // console.log(json)                
                //this.$emit("close")

            }catch(e){
                if(e == 'cancel'){
                    // clear file uploads
                    document.getElementById('pdf-document-input-scorecard').value = ""
                    document.getElementById('pdf-document-input-cx').value = ""
                    document.getElementById('pdf-document-input-pod').value = ""
                }
                else{
                    this.displayUserError(e)
                }
                this[type].loading = false
                this.loading = false
            }
            // finally{
            //     window.location.reload(false);
            // }
        },

        /**
         * Checks premium status before allowing the user to import any documents
         */
        checkPremiumStatus: function(elementID){
            if(!this.$store.getters.hasPerformanceCoaching){
                var input = document.getElementById(elementID);
                input.disabled = true;
                this.$emit('show-warning-dialog')
                return
            }
        },

        createYears(){
            this.years = []
            var startYear = 2020
            var endYear = parseInt(this.$moment().format('YYYY'))
            var i = 1

            while (startYear <= endYear && i <= 10){
                this.years.push(endYear)
                endYear--
                i++
            }
        },

        async loadSystem(){
            try{
                var system = await this.gLoadListAll(listSystems, {}, 'listSystems')
                this.systemData = system[0]
            }catch(e){
                this.$message("Error")
            }
        }
    }
    
}
</script>
<style>
    .el-input-group__prepend{
        @apply px-2 py-0 w-12 text-center !important;
    }
    
</style>
<style scoped>
.cursor-not-allowed{
        cursor: not-allowed !important;
    }
</style>