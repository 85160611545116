<template>
  <div class="py-2 px-2" :class="inTable ? 'header-search-table' : 'header-search'">
    <div class="grid grid-flow-row grid-cols-12 gap-4">
      <div class="col-span-12 md:col-span-12">
        <slot>
          <el-input
            class="w-full input-filter"
            v-model="search"
            @input="filterData"
            size="medium"
            prefix-icon="uil uil-search"
            clearable
            :placeholder="placeholder" 
            data-cy="search-in"
            debounce
          />
        </slot>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "SearchBox",

  props: {
    inTable: {
      type: Boolean,
      required: false,
      default: false      
    },
    placeholder: {
      type: String,
      default: "Type to search this list...",
      required: false
    },
    searchString: {
      type: String, 
      default: '',
      required: false
    },
    debounce: {
      type: Number,
      default: 700,
      required: false
    },
  },

  data() {
    return {
      search: this.searchString
    }
  },

  watch: {
    searchString(providedTerm, oldTerm) {
      this.search = providedTerm
    }
  },

  created () {
    if (this.searchString) this.search = this.searchString
    this.debouncedUpdate = this.debounceFn(this.updateDebouncedValue, this.debounce);
  },

  methods: {
    filterData( value ) {
      this.debouncedUpdate(value);
    },
    updateDebouncedValue(value) {
      this.$emit("search-data", value);
    },
    debounceFn(func, wait) {
      let timeout;
      return function (...args) {
        const context = this;
        clearTimeout(timeout);
        timeout = setTimeout(() => func.apply(context, args), wait);
      };
    },
  }
}
</script>

<style lang="scss" scoped>

.input-filter {
  font-size: 16px;
}

</style>

<style>

.input-filter > .el-input__inner {
  border: none !important;
}

.header-search-table {
  border-bottom: 1px solid #e2e8f0;
}

.header-search {
  border: 1px solid #e2e8f0;
}

</style>