export const createStaffNetradyneAlert = /* GraphQL */ `
  mutation CreateStaffNetradyneAlert(
    $input: CreateStaffNetradyneAlertInput!
    $condition: ModelStaffNetradyneAlertConditionInput
  ) {
    createStaffNetradyneAlert(input: $input, condition: $condition) {
      id
      group
    }
  }
`;

export const updateStaffNetradyneAlert = /* GraphQL */ `
  mutation UpdateStaffNetradyneAlert(
    $input: UpdateStaffNetradyneAlertInput!
    $condition: ModelStaffNetradyneAlertConditionInput
  ) {
    updateStaffNetradyneAlert(input: $input, condition: $condition) {
      id
      group
    }
  }
`;

export const createCompanyScoreCard = /* GraphQL */ `
  mutation CreateCompanyScoreCard(
    $input: CreateCompanyScoreCardInput!
    $condition: ModelCompanyScoreCardConditionInput
  ) {
    createCompanyScoreCard(input: $input, condition: $condition) {
      id
      group
      week
      year
      yearWeek
      tenantId
      createdAt
      updatedAt
    }
  }
`;

export const updateCompanyScoreCard = /* GraphQL */ `
  mutation UpdateCompanyScoreCard(
    $input: UpdateCompanyScoreCardInput!
    $condition: ModelCompanyScoreCardConditionInput
  ) {
    updateCompanyScoreCard(input: $input, condition: $condition) {
      id
      group
      week
      year
      yearWeek
      tenantId
      createdAt
      updatedAt
    }
  }
`;

export const createPodQuality = /* GraphQL */ `
  mutation CreatePodQuality(
    $input: CreatePodQualityInput!
    $condition: ModelPodQualityConditionInput
  ) {
    createPodQuality(input: $input, condition: $condition) {
      id
      createdAt
      updatedAt
    }
  }
`;
export const updatePodQuality = /* GraphQL */ `
  mutation UpdatePodQuality(
    $input: UpdatePodQualityInput!
    $condition: ModelPodQualityConditionInput
  ) {
    updatePodQuality(input: $input, condition: $condition) {
      id
      createdAt
      updatedAt
    }
  }
`;

export const createStaffCxFeedback = /* GraphQL */ `
  mutation CreateStaffCxFeedback(
    $input: CreateStaffCxFeedbackInput!
    $condition: ModelStaffCxFeedbackConditionInput
  ) {
    createStaffCxFeedback(input: $input, condition: $condition) {
      id      
      createdAt
      updatedAt
    }
  }
`;

export const updateStaffCxFeedback = /* GraphQL */ `
  mutation UpdateStaffCxFeedback(
    $input: UpdateStaffCxFeedbackInput!
    $condition: ModelStaffCxFeedbackConditionInput
  ) {
    updateStaffCxFeedback(input: $input, condition: $condition) {
      id
      createdAt
      updatedAt
    }
  }
`;

export const createStaff = /* GraphQL */ `
  mutation CreateStaff(
    $input: CreateStaffInput!
    $condition: ModelStaffConditionInput
  ) {
    createStaff(input: $input, condition: $condition) {
      id
      firstName
      lastName
      alternateNames
      transporterId
      keyFocusArea
      latestScorecard
      netradyneDriverId
      status
    }
  }
`;

export const updateStaff = /* GraphQL */ `
  mutation UpdateStaff(
    $input: UpdateStaffInput!
    $condition: ModelStaffConditionInput
  ) {
    updateStaff(input: $input, condition: $condition) {
      id
      group
      transporterId
      firstName
      lastName
      alternateNames
      keyFocusArea
      keyFocusAreaCompleted
      latestScorecard
      authorizedToDrive {
        items {
          id
          group
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;

export const updateStaffVehicleType = /* GraphQL */ `
  mutation updateStaff(
    $input: UpdateStaffInput!
    $condition: ModelStaffConditionInput
  ) {
    updateStaff(input: $input, condition: $condition) {
      id
      vehicleType
      updatedAt
    }
  }
`;

export const createPendingMessage = /* GraphQL */ `
    mutation CreatePendingMessage(
        $input: CreatePendingMessageInput!
        $condition: ModelPendingMessageConditionInput
    ) {
        createPendingMessage(input: $input, condition: $condition) {
            id
            group
            createdAt        
        }
    }
`;

export const updatePendingMessage = /* GraphQL */ `
    mutation UpdatePendingMessage(
        $input: UpdatePendingMessageInput!
        $condition: ModelPendingMessageConditionInput
    ) {
        updatePendingMessage(input: $input, condition: $condition) {
            id
            group
            updatedAt
        }
    }
`;

export const createInfraction = /* GraphQL */ `
  mutation CreateInfraction(
    $input: CreateInfractionInput!
    $condition: ModelInfractionConditionInput
  ) {
    createInfraction(input: $input, condition: $condition) {
      id
      group
    }
  }
`;

export const updateInfraction = /* GraphQL */ `
  mutation UpdateInfraction(
    $input: UpdateInfractionInput!
    $condition: ModelInfractionConditionInput
  ) {
    updateInfraction(input: $input, condition: $condition) {
      id
      group
    }
  }
`;

export const createKudo = /* GraphQL */ `
  mutation CreateKudo(
    $input: CreateKudoInput!
    $condition: ModelKudoConditionInput
  ) {
    createKudo(input: $input, condition: $condition) {
      id
      group
    }
  }
`;

export const updateKudo = /* GraphQL */ `
  mutation UpdateKudo(
    $input: UpdateKudoInput!
    $condition: ModelKudoConditionInput
  ) {
    updateKudo(input: $input, condition: $condition) {
      id
      group
    }
  }
`;

export const createStaffScoreCard = /* GraphQL */ `
  mutation CreateStaffScoreCard(
    $input: CreateStaffScoreCardInput!
    $condition: ModelStaffScoreCardConditionInput
  ) {
    createStaffScoreCard(input: $input, condition: $condition) {
      id
      createdAt
      updatedAt
    }
  }
`;

export const updateStaffScoreCard = /* GraphQL */ `
  mutation UpdateStaffScoreCard(
    $input: UpdateStaffScoreCardInput!
    $condition: ModelStaffScoreCardConditionInput
  ) {
    updateStaffScoreCard(input: $input, condition: $condition) {
      id
      createdAt
      updatedAt
    }
  }
`;