import { mixinMethods } from '@/main'
import {
    onCreateCompanyNotificationByGroup,
} from '../../subscriptions';
import store from '@/store'
import { companyNotificationsByGroup } from '../../queries'
import { initializeSubscription, disconnectSubscription } from '../helper'

let subscriptionCompanyNotification = null

export const loadCompanyNotifications = async function(){
    if(!store.state.userInfo?.tenant?.group) return
    const input = {
        group: store.state.userInfo?.tenant?.group
    }
    const companyNotifications = await mixinMethods.gLoadListAll(companyNotificationsByGroup, input, "companyNotificationsByGroup")
    store.dispatch("subscriptionStore/setCompanyNotificationList", companyNotifications)
}

export const companyNotificationSubscribe = function(instance){
    const queries = {
        onCreateCompanyNotificationByGroup
    }
    if(!store.state.userInfo?.tenant?.group) return
    const input = {
        group: store.state.userInfo?.tenant?.group
    }
    initializeSubscription(subscriptionCompanyNotification, queries, input, "subscriptionStore/mutateCompanyNotification", instance)
}

export const config = function(subscriptions){
    const subscription = {}
    subscriptionCompanyNotification = subscriptions['companyNotification'] = {
        loadList: loadCompanyNotifications,
        subscribe: companyNotificationSubscribe,
        unsubscribe: (instance) => disconnectSubscription(subscription, instance),
        callback: companyNotificationEventCallback,
        subscription
    }
}

export const companyNotificationEventCallback = function(subscriptionState, payload){
    //
}