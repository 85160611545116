import {subscriptionSetup} from '@/store/subscriptionModule'
import { API, graphqlOperation } from 'aws-amplify'

var online = true
const maxAttemptsToReApply = 7 // When the backend does not respond to the requests
const maxAttemptsToReconnect = 1000 // When the frontend has lost internet connection
const interval = 3000 // Number of milliseconds to wait before trying to resume

const connectionHandler = function(event){
    if(event.type === 'online'){
        online = true
        console.info('Hera is now: %conline','color:green;')
        subscriptionSetup.subscribe()
    }
    if(event.type === 'offline'){
        online = false
        console.info('Hera is now: %coffline','color:red;')
    }
}

const amplifyFunction  = function(func){
    const apiFunctions = [
        {text: '_graphqlApi.graphql', method: 'graphql'},
        {text: '_restApi.get', method: 'get'},
        {text: '_restApi.post', method: 'post'},
        {text: '_restApi.put', method: 'put'},
        {text: '_restApi.del', method: 'del'},
        {text: '_restApi.patch', method: 'patch'}
    ]
    const content = func.toString()
    return apiFunctions.find(f=>content.includes(f.text))
}

const performRequest = function(func, args, attempts, timeout){
    if(!attempts){
        attempts = {
            online: 0,
            offline: 0
        }
    }
    const perform = function(resolve, reject){
        if(online){
            const apiFunction = amplifyFunction(func)
            if(apiFunction){
                resolve(API[apiFunction.method](...args))
            }else{
                try {
                    resolve(func(...args))
                } catch (error) {
                    reject({
                        data: {},
                        errors:[error]
                    })
                }
            }
        }else{
            reject({
                data: {},
                errors:[{from: "connectionHelper", message: 'Network Error'}]
            })
        }
    }
    return new Promise((resolve, reject)=>{
        if(timeout){
            const timer = setTimeout(()=>{
                clearTimeout(timer)
                perform(resolve, reject)
            }, interval);
        }else{
            perform(resolve, reject)
        }
    }).catch(e => {
        if(online)
            attempts.online++
        else
            attempts.offline++
        return networkErrorExceptionHandler(e, func, args, attempts)
    })
}

const networkErrorExceptionHandler = function(error, func, args, attempts){
    if(
        (online ? attempts.online <= maxAttemptsToReApply : attempts.offline <= maxAttemptsToReconnect ) &&
        (error.message === 'Network Error' || error.errors?.some(e=>e.message === 'Network Error') || error.message === 'Failed to fetch')
    ){
        console.log("%cTry to resume: attempt #"+(attempts.online+attempts.offline)+' ','color:grey;font-size:11px')
        if(online){
            subscriptionSetup.subscribe()
        }
        return performRequest(func, args, attempts, true)
    }else{
        throw error
    }
}

const addConnectionEvent = function(main){
    window.ononline = connectionHandler
    window.onoffline = connectionHandler
    window.safeFunction = function (func){
        return function (){
            const args = arguments
            try{
                return performRequest(func, args)
            }catch(e){
                return networkErrorExceptionHandler(e, func, args)
            }
        }
    }
    window.onbeforeunload = function (e) {
        removeConnectionEvent()
    };
}
const removeConnectionEvent = function(){
    window.ononline = null
    window.onoffline = null
}

const isOnline = function(){
    return online
}

export default {addConnectionEvent, removeConnectionEvent, isOnline}