import moment from 'moment-timezone';
import store from '@/store/index.js'

const timeZone = store.state?.userInfo?.tenant?.timeZone || 'America/Los_Angeles'

/**
 * If the date is between the start date and the end date, return true, otherwise return false.
 * @param {Date} start - the start date of the range
 * @param {Date} end - the end date of the range
 * @param {Date} date - the date to check
 * @returns A boolean value.
 */
export function isInRange(start, end, date) {
    const isoFormat = 'YYYY-MM-DD[T]HH:mm:ss.SSS[Z]';

    const formattedStartDate = moment(start).startOf('day').format(isoFormat);
    const formattedEndDate = moment(end).endOf('day').format(isoFormat);

    return date >= formattedStartDate && date <= formattedEndDate;
}

/**
 * It takes a label, and returns a range of dates based on that label.
 * 
 * @param {string} label - the label of the range you want to calculate (label = current week, last week, last 2 weeks, last 3 weeks, last 30 days, last 60 days, last 90 days, previous month, last month, last 2 months, last 3 months, current year, last year)
 * @returns An array of two dates.
 */
export function calculateRange(label){
    let start = new Date();
    let end = new Date();

    const range = {
      "current week": function () {
        start = moment().tz(timeZone).utc().startOf('week').toDate();
        end = moment().tz(timeZone).utc().endOf("week").toDate();
      },
      "last week": function () {
        start = moment().tz(timeZone).subtract(1, 'weeks').utc().startOf('week').toDate();
        end = moment().tz(timeZone).subtract(1, 'weeks').utc().endOf('week').toDate();
      },
      "current & previous week": function () {
        start = moment().tz(timeZone).subtract(1, 'weeks').utc().startOf('week').toDate();
        end = moment().tz(timeZone).utc().endOf("week").toDate();
      },
      "last 7 days": function () {
        start = moment().tz(timeZone).subtract(6, 'days').utc().startOf('day').toDate();
        end = moment().tz(timeZone).utc().endOf("day").toDate();
      },
      "last 14 days": function () {
        start = moment().tz(timeZone).subtract(13, 'days').utc().startOf('day').toDate();
        end = moment().tz(timeZone).utc().endOf("day").toDate();
      },
      "last 21 days": function () {
        start = moment().tz(timeZone).subtract(20, 'days').utc().startOf('day').toDate();
        end = moment().tz(timeZone).utc().endOf("day").toDate();
      },
      "last 2 weeks": function () {
        start = moment().tz(timeZone).subtract(1, 'weeks').utc().startOf('week').toDate();
        end = moment().tz(timeZone).utc().endOf("week").toDate();
      },
      "last 3 weeks": function () {
        start = moment().tz(timeZone).subtract(2, 'weeks').utc().startOf('week').toDate();
        end = moment().tz(timeZone).utc().endOf("week").toDate();
      },
      "last 30 days": function () {
        start = moment().tz(timeZone).subtract(29, 'days').utc().startOf('day').toDate();
        end =  moment().tz(timeZone).utc().endOf("day").toDate();
      },
      "last 60 days": function () {
        start = moment().tz(timeZone).subtract(59, 'days').utc().startOf('day').toDate();
        end =  moment().tz(timeZone).utc().endOf("day").toDate();
      },
      "last 90 days": function () {
        start = moment().tz(timeZone).subtract(89, 'days').utc().startOf('day').toDate();
        end =  moment().tz(timeZone).utc().endOf("day").toDate();
      },
      "current month": function () {
        start = moment().tz(timeZone).utc().startOf('month'); 
        end = moment().tz(timeZone).utc().endOf('month');
      },
      "previous month": function () {
        start = moment().tz(timeZone).subtract(1, 'months').utc().startOf('month').toDate();
        end = moment().tz(timeZone).subtract(1, 'months').utc().endOf('month').toDate();
      },
      "current & previous month": function () {
        start = moment().tz(timeZone).subtract(1, 'months').utc().startOf('month');
        end = moment().tz(timeZone).utc().endOf('month');
      },
      "current year": function () {
        start = moment().tz(timeZone).utc().startOf('year').toDate();
        end = moment().tz(timeZone).utc().endOf("year").toDate();
      },
      "last year": function () {
        start = moment().tz(timeZone).subtract(1, 'years').utc().startOf('year').toDate();
        end = moment().tz(timeZone).subtract(1, 'years').utc().endOf('year').toDate();
      },
      "current & previous year": function () {
        start = moment().tz(timeZone).subtract(1, 'years').utc().startOf('year').toDate();
        end = moment().tz(timeZone).utc().endOf("year").toDate();
      },
    };

    if(range[label]===undefined) return
    range[label]();

    return [start, end];
}
