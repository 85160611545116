import {
    onCreateMessageReadStatusByGroup,
    onUpdateMessageReadStatusByGroup,
} from '../../subscriptions';
import store from '@/store'
import {
    initializeSubscription,
    disconnectSubscription
} from '../helper'

"use strict";
let subscriptionMessageReadStatus = null

export const messasgeReadStatusSubscribe = function(instance){
    const queries = {
        onCreateMessageReadStatusByGroup,
        onUpdateMessageReadStatusByGroup,
    }
    if(!store.state.userInfo?.tenant?.group) return
    const input = {
        group: store.state.userInfo.tenant.group
    }
    initializeSubscription(subscriptionMessageReadStatus, queries, input, "subscriptionStore/handleMessageReadStatus", instance)
}

export const config = function(subscriptions){
    const subscription = {}
    subscriptionMessageReadStatus = subscriptions['messageReadStatus'] = {
        loadList: null,
        subscribe: messasgeReadStatusSubscribe,
        unsubscribe: (instance) => disconnectSubscription(subscription, instance),
        callback: messageReadStatusEventCallback,
        subscription
    }
}

export const messageReadStatusEventCallback = function(subscriptionState, {data: messageReadStatus, eventName}){
    if(!messageReadStatus || messageReadStatus.userID !== store.state.userInfo.id) return
    const messengerList = store.state?.subscriptionStore?.messengerList || []
    const chat = messengerList.find(item => item.staffId === messageReadStatus.staffID)
    if(chat){
        const messageUnread = chat.messageUnread
        chat.readChat = true
        chat.messageUnread = 0
        store.dispatch( "markMessagesAsRead", { messageUnread })
    }
}
