<template>
  <el-button v-bind="$attrs" :disabled="loading" :loading="loading" @click.prevent="handleClick">
    <slot></slot>
  </el-button>
</template>

<script>
export default {
  name: 'ButtonWrapper',

  props: {
    clickHandler: {
      type: Function,
      required: true,
    },
    handlerArgs: {
      type: Array,
      required: false,
      default: () => ([])
    }
  },

  data() {
    return {
      loading: false,
    };
  },

  methods: {
    async handleClick() {
      try {
        this.$emit('cb-start');
        this.loading = true;
        await this.clickHandler(...this.handlerArgs);
      }
      catch(error) {
        this.displayUserError(error);
        this.printUserError(error);
      }
      finally {
        await this.$nextTick(async () => {
          await this.sleep(500); // Make sure the changes are ready before enabling it again
          this.loading = false;
          this.$emit('cb-end');
        })
      }
    },

    async sleep(ms) {
      await (new Promise(resolve => setTimeout(resolve, ms)));
    }
  },
};
</script>
