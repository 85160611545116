import { phoneNumberMask } from '@/utilities/phoneNumberFormat'

export const MESSAGE_TYPE = {
    'coaching': 'Automated Coaching',
    'broadcast': 'Associate List',
    'roster': 'Roster Details',
    'standUpAnnouncements': 'Roster Announcement',
    'messenger': 'Hera Messenger',
    'label':'Label',
    'recurring': 'Recurring Message'
}

/**
 * The function combines messages and sorts them based on their part order.
 * @param [messages] - An array of message objects to be combined.
 * @param [fromMessageLogs=false] - A boolean flag indicating the function will perform additional * operations on the messages to format them for display in the message log.
 * @returns The function `joinMessages` returns an array of combined messages. The messages are
 * combined based on their `hasParts` property, which indicates if a message is part of a larger
 * message that has been split into multiple parts. If a message has parts, the function combines all
 * parts into a single message and returns an array of all combined messages. If a message does not
 * have parts, it is added
 */
export function joinMessages(messages=[], fromMessageLogs=false){
    let combinedMessages  = {}
    let finalCombinedMessages = {}
 
    for(const message of messages){
        if(fromMessageLogs){
            message.destinationNumber = phoneNumberMask(message.destinationNumber)
            //messageTypeMapped is used for filtering in message log
            message.messageTypeMapped = MESSAGE_TYPE[message.messageType] || 'Other'
            message.smsStatus = message.smsStatus || 'Other'
            message.emailStatus = message.emailStatus || 'Other'
        }
        if(message.hasParts && message.hasParts.includes('-part-')){
            let [partId, parts] = message.hasParts.split('-part-')
            let [partOrden, totalParts] = parts.split('/')
            message.totalParts = totalParts
            message.partOrden = partOrden
            if(combinedMessages.hasOwnProperty(partId)) {
              combinedMessages[partId].push(message)
            }else{
              combinedMessages[partId] = [message]
            }
        }else{
            finalCombinedMessages[message.id] = message
        }
    }
    for(const [partId, messages] of Object.entries(combinedMessages)){
        messages.sort((a,b) => a.partOrden - b.partOrden)
        let bodyText = ''
        for(const message of messages){
          bodyText += message.bodyText
        }
        finalCombinedMessages[partId] = messages[0]
        finalCombinedMessages[partId].bodyText = bodyText
    }
    const combinedMessagesArray = Object.values(finalCombinedMessages)
    return combinedMessagesArray
}