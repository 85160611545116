import { API, graphqlOperation } from 'aws-amplify';
import {getStaff} from '@/views/Performance&Coaching/Coaching/queries'
import { createPendingMessage, updatePendingMessage } from '@/views/Performance&Coaching/Coaching/mutations'
import {getPodQualityCoachingMessages, getStaffCxFeedbackCoachingMessages, getPendingMessage, staffScorecardsGroupUnmatched} from '@/api/queries'

// removes hyphens and spaces and camel cases value
function formatNetradyneType(value){
    return value.replace('-', ' ').replace('-',' ').split(' ').map(word => word[0].toUpperCase() + word.substr(1).toLowerCase()).join(' ').replace(/\s+/g, '')
}

/**
 * 
 */
async function createPendingMessageRecord(scorecard, cx, mentor, tenant, netradyne, importId){
    var messageStringWeeklyCO = '';
    var messageStringWeeklyPR = '';
    var messageStringDailyCO = '';
    var messageStringMentorCO = '';
    var messageStringNetradyneCO = '';
    var messageStringDailyPR = '';
    var messageStringMentorPR = '';
    var messageStringNetradynePR = '';
    var numberOfWeeklyCO = 0
    var numberOfWeeklyPR = 0
    var numberOfDailyCO = 0
    var numberOfMentorCO = 0
    var numberOfNetradyneCO = 0
    var numberOfDailyPR = 0
    var numberOfMentorPR = 0
    var numberOfNetradynePR = 0
    var yesterday = new Date()

    var staffId = null
    if(mentor) staffId = mentor.input.staffMentorStaffId
    else if(scorecard) staffId = scorecard.input.staffScoreCardStaffId
    else if(cx) staffId = cx.staffCxFeedbackStaffId
    else if(netradyne) staffId = netradyne.staff.id
    else{
        return {status: 'noop', id: 'noop'}
    }

    var cxFeedback = null
    yesterday.setDate(yesterday.getDate() - 1)
    yesterday = yesterday.toISOString().split('T')[0]


    //FIND EXISTING MESSAGE
    var pendingMessageId = tenant.id + staffId
    try{
        // get existing message
        input = {
            id: pendingMessageId
        }

        var existingMessage = await API.graphql(graphqlOperation(getPendingMessage, input))
        existingMessage = existingMessage.data.getPendingMessage
    }
    catch(e){
        console.error(e);
        return {status: 'noop', id: 'noop'}
    }


    // determine which phrases to include in message based on scorecard, CX, and Mentor data

    // ------------ CUSTOMER FEEDBACK ------------
    // will only fire if the CX feedback is the same week or newer as the latest scorecard for a staff member
    if(cx){
        // exit if all cx messages disabled
        if(!tenant.coachingPositiveFeedbackCO && !tenant.coachingPositiveFeedbackPR){
            return {status: 'noop', id: 'noop'}
        }

        // if the cx document is newer than the latest scorecard, create a new pending message
        if(cx.isNewerThanLatestScoreCard){
            // --- Coaching Opportunities ---
            // Negative Feedback
            if((tenant.coachingPositiveFeedbackCO) && (parseFloat(cx.positiveFeedback) < tenant.coachingPositiveFeedbackThreshold )){
                messageStringWeeklyCO = messageStringWeeklyCO + `\n- You had ${cx.negativeFeedback.charAt(0) == '8' ? 'an' : 'a' } ${cx.negativeFeedback} negative feedback rate last week:\n`;
                if(cx.deliveryWasntGreat > 0) messageStringWeeklyCO = messageStringWeeklyCO + `${cx.deliveryWasntGreat} delivery was not so great.\n`;
                if(cx.mishandledPackage > 0) messageStringWeeklyCO = messageStringWeeklyCO + `${cx.mishandledPackage} mishandled package(s).\n`;  
                if(cx.drivingUnsafely > 0) messageStringWeeklyCO = messageStringWeeklyCO + `${cx.drivingUnsafely} driving unsafely.\n`; 
                if(cx.driverUnprofessional > 0) messageStringWeeklyCO = messageStringWeeklyCO + `${cx.driverUnprofessional} driver unprofessional.\n`
                if(cx.notDeliveredToPreferredLocation > 0) messageStringWeeklyCO = messageStringWeeklyCO + `${cx.notDeliveredToPreferredLocation} not delivered to preferred location.\n`
                numberOfWeeklyCO++
            }
            
            // --- Positive reinforcement ---
            // Positive Feedback
            if((tenant.coachingPositiveFeedbackPR) && (parseFloat(cx.positiveFeedback) >= tenant.coachingPositiveFeedbackThreshold)){
                messageStringWeeklyPR = messageStringWeeklyPR + `\n- Congratulations, you received ${cx.positiveFeedback} positive feedback from customers last week.\n`
                numberOfWeeklyPR++
            }
        }
        // if the cx document is the same week as the latest scorecard, update the existing pending message
        else{
            // get the latest scorecard so the messages can be updated
            var input = {
                group: cx.group, 
                limit: 300, 
                filter: {
                    transporterId: {
                        eq: cx.transporterId
                    }
                },
                matchedSYearWeek: {
                    eq: {
                        week: cx.week, 
                        year: cx.year, 
                        matchedS: 'true'
                    }
                }
            }
            var existingScorecard =  await API.graphql(graphqlOperation(staffScorecardsGroupUnmatched, input ))
            scorecard = {input: existingScorecard.data.staffScorecardsGroupUnmatched.items[0]}

            // if that fails, try single digit week - this should only have to be temporary after all weeks are padded starting week 7 2021
            if(!scorecard.input){
                input.matchedSYearWeek.eq.week = String(parseInt(cx.week))
                existingScorecard =  await API.graphql(graphqlOperation(staffScorecardsGroupUnmatched, input ))
                scorecard = {input: existingScorecard.data.staffScorecardsGroupUnmatched.items[0]}
            }

            cxFeedback = cx
        }
    }

    
    // ------------ SCORECARD ------------
    if(scorecard && scorecard.input){
        // exit if all scorecard messages disabled
        if( !tenant.coachingFicoCO && !tenant.coachingDcrCO && !tenant.coachingDarCO &&
            !tenant.coachingFicoPR && !tenant.coachingDcrPR && !tenant.coachingDarPR&&
            !tenant.coachingSwcPodCO && !tenant.coachingSwcCcCO && !tenant.coachingSwcScCO &&
            !tenant.coachingSwcPodPR && !tenant.coachingSwcCcPR && !tenant.coachingSwcScPR &&
            !tenant.coachingSwcAdCO && !tenant.coachingSeatbeltOffCO && !tenant.coachingSpeedingEventCO &&
            !tenant.coachingSwcAdPR && !tenant.coachingSeatbeltOffPR && !tenant.coachingSpeedingEventPR &&
            !tenant.coachingSeatbeltCO && !tenant.coachingSseCO && !tenant.coachingPositiveFeedbackCO &&
            !tenant.coachingSeatbeltPR && !tenant.coachingSsePR && !tenant.coachingPositiveFeedbackPR && 
            !tenant.coachingDistractionsRateCO && !tenant.coachingDistractionsRatePR && !tenant.coachingFollowingDistanceRateCO &&
            !tenant.coachingFollowingDistanceRatePR && !tenant.coachingSignSignalViolationsRateCO && !tenant.coachingSignSignalViolationsRatePR &&
            !tenant.coachingDnrCO && !tenant.coachingDnrPR){
            return {status: 'noop', id: 'noop'}
        }

        // --- Coaching Opportunities ---
        // FICO
        if((tenant.coachingFicoCO) && (parseFloat(scorecard.input.ficoScore) < parseFloat(tenant.coachingFicoThreshold) )){
            messageStringWeeklyCO = messageStringWeeklyCO + `\n- Your FICO score was ${scorecard.input.ficoScore} last week. ${tenant.coachingFicoThreshold} is the minimum score for "Fantastic".\n`
            numberOfWeeklyCO++
        }
        // DCR
        if((tenant.coachingDcrCO) && (parseFloat(scorecard.input.dcr) < parseFloat(tenant.coachingDcrThreshold))){
            messageStringWeeklyCO = messageStringWeeklyCO + `\n- Your Delivery Completion Rate didn't make it to "Fantastic" last week. You can fix this by delivering all of your packages.\n`
            numberOfWeeklyCO++
        }
        // DAR
        if((tenant.coachingDarCO) && (parseFloat(scorecard.input.dar) < parseFloat(tenant.coachingDarThreshold))){
            messageStringWeeklyCO = messageStringWeeklyCO + `\n- Too many customers did not receive their packages last week. To fix this, try to hide packages behind a planter or under a doormat.\n`
            numberOfWeeklyCO++
        }

        // DNR${scorecard.input.dnrs} 
        if((tenant.coachingDnrCO) && (parseFloat(scorecard.input.dnrs) > parseFloat(tenant.coachingDnrThreshold))){
            messageStringWeeklyCO = messageStringWeeklyCO + `\n- You had ${scorecard.input.dnrs} DNR(s) - it is critical to follow delivery notes. Do your best to ensure packages aren’t visible to passers by & contact CX & Amazon Support if there are any delivery problems.\n`
            numberOfWeeklyCO++
        }

        // SWC-POD
        if((tenant.coachingSwcPodCO) && (parseFloat(scorecard.input.swcPod) < parseFloat(tenant.coachingSwcPodThreshold))){
            // get POD stats needed in message
            try{
                let podQualityID = tenant.id + scorecard.input.transporterId + scorecard.input.year + scorecard.input.week
                let input = {
                    id: podQualityID
                }
                var pod = await API.graphql(graphqlOperation(getPodQualityCoachingMessages, input ))
                pod = pod.data.getPodQuality
            }
            catch(e){
                console.error(e)
            }
            messageStringWeeklyCO = messageStringWeeklyCO + `\n- You had ${(100 - parseFloat(scorecard.input.swcPod)).toFixed(2)}% total delivery photo rejects.\n`
            if(pod && (pod.blurry || pod.personInPhoto || pod.noPackage || pod.takenFromCar || pod.packageInHand || pod.notClearlyVisible || pod.packageTooClose || pod.photoTooDark || pod.other)){
                if(parseFloat(pod.blurry) > 0) messageStringWeeklyCO = messageStringWeeklyCO + ` ${pod.blurry} blurry.\n`
                if(parseFloat(pod.personInPhoto) > 0) messageStringWeeklyCO = messageStringWeeklyCO + ` ${pod.personInPhoto} human in the picture.\n`
                if(parseFloat(pod.noPackage) > 0) messageStringWeeklyCO = messageStringWeeklyCO + ` ${pod.noPackage} no package detected.\n`
                if(parseFloat(pod.takenFromCar) > 0) messageStringWeeklyCO = messageStringWeeklyCO + ` ${pod.takenFromCar} package in car.\n`
                if(parseFloat(pod.packageInHand) > 0) messageStringWeeklyCO = messageStringWeeklyCO + ` ${pod.packageInHand} package in hand.\n`
                if(parseFloat(pod.notClearlyVisible) > 0) messageStringWeeklyCO = messageStringWeeklyCO + ` ${pod.notClearlyVisible} package not clearly visible - locker/other concealment.\n`
                if(parseFloat(pod.packageTooClose) > 0) messageStringWeeklyCO = messageStringWeeklyCO + ` ${pod.packageTooClose} package too close.\n`
                if(parseFloat(pod.photoTooDark) > 0 ) messageStringWeeklyCO = messageStringWeeklyCO + ` ${pod.photoTooDark} photo too dark.\n`
                if(parseFloat(pod.other) > 0) messageStringWeeklyCO = messageStringWeeklyCO + ` ${pod.other} other.\n`
            }
            messageStringWeeklyCO = messageStringWeeklyCO + `Your high reject metric resulted from skipping a workflow opportunity when taking photos. Please make sure you retake photos when prompted to avoid any rejects.\n`
            numberOfWeeklyCO++
        }
        // SWC-CC
        if((tenant.coachingSwcCcCO) && (parseFloat(scorecard.input.swcCc) < parseFloat(tenant.coachingSwcCcThreshold)) && parseFloat(scorecard.input.ccOpps) > 0){
            messageStringWeeklyCO = messageStringWeeklyCO + `\n- Your Contact Compliance score was below "Fantastic". You can fix this by first marking the package "undeliverable" then the Flex App will prompt you to call or text the customer.\n`
            numberOfWeeklyCO++
        }
        // SWC-SC
        if((tenant.coachingSwcScCO) && (parseFloat(scorecard.input.swcSc) < parseFloat(tenant.coachingSwcScThreshold))){
            messageStringWeeklyCO = messageStringWeeklyCO + `\n- Your Scan Compliance percentage was below "Fantastic". Please make sure you scan every package at the customer destination.\n`
            numberOfWeeklyCO++
        }
        // SWC-AD
        if((tenant.coachingSwcAdCO) && (parseFloat(scorecard.input.swcAd) > parseFloat(tenant.coachingSwcAdThreshold))){
            messageStringWeeklyCO = messageStringWeeklyCO + `\n- Amazon believes you marked "delivered to customer" too many times. Please be sure to only use this option when the customer actually takes the package in their hands.\n`
            numberOfWeeklyCO++
        }
        // // Seatbelt-Off Rate
        //originally compared to: parseFloat(tenant.coachingSeatbeltOffThreshold)
        if((tenant.coachingSeatbeltOffCO) && (parseFloat(scorecard.input.seatbeltOffRate) > parseFloat(tenant.coachingSeatbeltOffThreshold))){
            messageStringWeeklyCO = messageStringWeeklyCO + `\n- WARNING: You averaged ${scorecard.input.seatbeltOffRate} instances per route of not wearing your seatbelt while traveling last week. Please remember to buckle your seatbelt before putting the vehicle in drive.\n`
            numberOfWeeklyCO++
        }
        // // Speeding Event Rate
        //originally compared to: parseFloat(tenant.coachingSpeedingEventThreshold)
        if((tenant.coachingSpeedingEventCO) && (parseFloat(scorecard.input.speedingEventRate) > parseFloat(tenant.coachingSpeedingEventThreshold))){

            //Check for existing message for daily positive reinforcement, removes it if it exists
            if(existingMessage != null && existingMessage.bodyTextDailyPR.includes(`\n- You practiced safe driving and followed all posted speed limits.\n`)){
                const regex = /\n- You practiced safe driving and had a "Fantastic" week by following all posted speed limits.\n/i;
                existingMessage.bodyTextDailyPR = existingMessage.bodyTextDailyPR.replace(regex, '') 
            }

            messageStringWeeklyCO = messageStringWeeklyCO + `\n- WARNING: You averaged ${scorecard.input.speedingEventRate} instances of speeding per route last week. It is required to follow all posted speed limits.\n`
            numberOfWeeklyCO++
        }
        
        //Distractions Rate
        if((tenant.coachingTraningRemainingCO) && (parseFloat(scorecard.input.distractionsRate) > parseFloat(tenant.coachingDistractionsRateThreshold))){
            messageStringWeeklyCO = messageStringWeeklyCO + `\n- WARNING: You had distraction violations last week which lead to you not earning fantastic.\n`
            numberOfWeeklyCO++
        }

        //Following Distance Rate
        if((tenant.coachingFollowingDistanceRateCO) && (parseFloat(scorecard.input.followingDistanceRate) > parseFloat(tenant.coachingFollowingDistanceRateThreshold))){
            messageStringWeeklyCO = messageStringWeeklyCO + `\n- WARNING: You had following distance violations last week which lead to you not earning fantastic.\n`
            numberOfWeeklyCO++
        }

        //Sign Signal Violations Rate
        if((tenant.coachingSignSignalViolationsRateCO) && (parseFloat(scorecard.input.signSignalViolationsRate) > parseFloat(tenant.coachingSignSignalViolationsRateThreshold))){
            messageStringWeeklyCO = messageStringWeeklyCO + `\n- WARNING: You had sign/signal violations last week which lead to you not earning fantastic.\n`
            numberOfWeeklyCO++
        }
        
        // --- Positive reinforcement ---
        // FICO
        if((tenant.coachingFicoPR) && (parseFloat(scorecard.input.ficoScore) >= parseFloat(tenant.coachingFicoThreshold))){
            messageStringWeeklyPR = messageStringWeeklyPR + `\n- You have a FICO score of ${scorecard.input.ficoScore} last week, which is "Fantastic"!\n`
            numberOfWeeklyPR++
        }
        // DCR
        if((tenant.coachingDcrPR) && (parseFloat(scorecard.input.dcr) >= parseFloat(tenant.coachingDcrThreshold))){
            messageStringWeeklyPR = messageStringWeeklyPR + `\n- Your Delivery Completion Rate is "Fantastic" last week! Congratulations on delivering so many smiles.\n`
            numberOfWeeklyPR++
        }
        // DAR
        if((tenant.coachingDarPR) && (parseFloat(scorecard.input.dar) >= parseFloat(tenant.coachingDarThreshold))){
            messageStringWeeklyPR = messageStringWeeklyPR + `\n- ${scorecard.input.dar == '100' ? 'All' : 'Most'} customers have indicated that they received their packages last week, and your DAR is "Fantastic"!\n`
            numberOfWeeklyPR++
        }
        // DNR
        if((tenant.coachingDnrPR) && (parseFloat(scorecard.input.dnrs) <= 0)){
            messageStringWeeklyPR = messageStringWeeklyPR + `\n- Congratulations on not getting any DNRs! \n`
            numberOfWeeklyPR++
        }
        // SWC-POD
        if((tenant.coachingSwcPodPR) && (parseFloat(scorecard.input.swcPod) >= parseFloat(tenant.coachingSwcPodThreshold))){
            messageStringWeeklyPR = messageStringWeeklyPR + `\n- You took excellent pictures, and your POD is "Fantastic"!\n`
            numberOfWeeklyPR++
        }
        // SWC-CC
        if((tenant.coachingSwcCcPR) && (parseFloat(scorecard.input.swcCc) >= tenant.coachingSwcCcThreshold)){
            messageStringWeeklyPR = messageStringWeeklyPR + `\n- Your Contact Compliance score was "Fantastic" last week.\n`
            numberOfWeeklyPR++
        }
        // SWC-SC
        if((tenant.coachingSwcScPR) && (parseFloat(scorecard.input.swcSc) >= parseFloat(tenant.coachingSwcScThreshold))){
            messageStringWeeklyPR = messageStringWeeklyPR + `\n- Your Scan Compliance was "Fantastic".\n`
            numberOfWeeklyPR++
        }
        // // Seatbelt-Off Rate
        //Orignally compared to parseFloat(tenant.coachingSeatbeltOffThreshold)
        if((tenant.coachingSeatbeltOffPR) && (parseFloat(scorecard.input.seatbeltOffRate) <= 0)){
            messageStringWeeklyPR = messageStringWeeklyPR + `\n- You earned a "Fantastic" score for wearing your seatbelt last week.\n`
            numberOfWeeklyPR++
        }
        // // Speeding Event Rate
        //Originally compared to parseFloat(tenant.coachingSpeedingEventThreshold)
        if((tenant.coachingSpeedingEventPR) && (parseFloat(scorecard.input.speedingEventRate) <= 0)){

            //Checks if existing message has a daily coaching, does not add speeding reinforcement
            if(existingMessage == null || (!existingMessage.bodyTextDailyCO.includes( `of speeding during your last trip. It is required to follow all posted speed limits.\n`) && !existingMessage.bodyTextDailyCO.includes( `of Scorecard Speeding Event during your last trip. It is required to follow all posted speed limits.\n`))){
                messageStringWeeklyPR = messageStringWeeklyPR + `\n- You practiced safe driving and had a "Fantastic" week by following all posted speed limits.\n`
                numberOfWeeklyPR++
            }
        }

        //Distractions Rate
        if((tenant.coachingDistractionsRatePR) && (parseFloat(scorecard.input.distractionsRate) <= parseFloat(tenant.coachingDistractionsRateThreshold))){
            messageStringWeeklyPR = messageStringWeeklyPR + `\n- You earned a "Fantastic" score for your distraction rate.\n`
            numberOfWeeklyPR++
        }

        //Following Distance Rate
        if((tenant.coachingFollowingDistanceRatePR) && (parseFloat(scorecard.input.followingDistanceRate) <= parseFloat(tenant.coachingFollowingDistanceRateThreshold))){
            messageStringWeeklyPR = messageStringWeeklyPR + `\n- You earned a "Fantastic" score for your following distance rate.\n`
            numberOfWeeklyPR++
        }

        //Sign Signal Violations Rate
        if((tenant.coachingSignSignalViolationsRatePR) && (parseFloat(scorecard.input.signSignalViolationsRate) <= parseFloat(tenant.coachingSignSignalViolationsRateThreshold))){
            messageStringWeeklyPR = messageStringWeeklyPR + `\n- You earned a "Fantastic" score for your sign/signal violation rate.\n`
            numberOfWeeklyPR++
        }

        // check for CX stats for same week as scorecard
        // if pending message creation was triggered by CX import, cxFeedback will already be defined
        if(!cxFeedback){
            try{
                let customerFeedbackID = tenant.id + scorecard.input.transporterId + scorecard.input.year + scorecard.input.week
                let input = {
                    id: customerFeedbackID
                }
                cxFeedback = await API.graphql(graphqlOperation(getStaffCxFeedbackCoachingMessages, input ))
                cxFeedback = cxFeedback.data.getStaffCxFeedback
            }
            catch(e){
                console.error(e)
            }
        }

        if(cxFeedback){
            // --- Coaching Opportunities ---
            // Negative Feedback
            if((tenant.coachingPositiveFeedbackCO) && (parseFloat(cxFeedback.positiveFeedback) < tenant.coachingPositiveFeedbackThreshold )){
                messageStringWeeklyCO = messageStringWeeklyCO + `\n- You had ${cxFeedback.negativeFeedback.charAt(0) == '8' ? 'an' : 'a' } ${cxFeedback.negativeFeedback} negative feedback rate last week:\n`;
                if(cxFeedback.deliveryWasntGreat > 0) messageStringWeeklyCO = messageStringWeeklyCO + ` ${cxFeedback.deliveryWasntGreat} delivery was not so great.\n`;
                if(cxFeedback.mishandledPackage > 0) messageStringWeeklyCO = messageStringWeeklyCO + ` ${cxFeedback.mishandledPackage} mishandled package(s).\n`;  
                if(cxFeedback.drivingUnsafely > 0) messageStringWeeklyCO = messageStringWeeklyCO + ` ${cxFeedback.drivingUnsafely} driving unsafely.\n`; 
                if(cxFeedback.driverUnprofessional > 0) messageStringWeeklyCO = messageStringWeeklyCO + ` ${cxFeedback.driverUnprofessional} driver unprofessional.\n`
                if(cxFeedback.notDeliveredToPreferredLocation > 0) messageStringWeeklyCO = messageStringWeeklyCO + ` ${cxFeedback.notDeliveredToPreferredLocation} not delivered to preferred location.\n`
                numberOfWeeklyCO++
            }
            
            // --- Positive reinforcement ---
            // Positive Feedback
            if((tenant.coachingPositiveFeedbackPR) && (parseFloat(cxFeedback.positiveFeedback) >= tenant.coachingPositiveFeedbackThreshold)){
                messageStringWeeklyPR = messageStringWeeklyPR + `\n- Congratulations, you received ${cxFeedback.positiveFeedback} positive feedback from customers last week.\n`
                numberOfWeeklyPR++
            }
            
        }
    }


    // ------------ MENTOR ------------
    if(mentor){
        // exit if all mentor messages disabled
        if( !tenant.coachingDailyFicoCO && !tenant.coachingSeatbeltCO && !tenant.coachingSseCO &&
            !tenant.coachingDailyFicoPR && !tenant.coachingSeatbeltPR && !tenant.coachingSsePR &&
            !tenant.coachingTraningRemainingCO ){
            return {status: 'noop', id: 'noop'}
        }

        // --- Coaching Opportunities ---
        // FICO
        if((tenant.coachingDailyFicoCO) && (parseFloat(mentor.input.fico) < parseFloat(tenant.coachingDailyFicoThreshold ))){
            let accel = parseFloat(mentor.input.accel)
            let braking = parseFloat(mentor.input.braking)
            let cornering = parseFloat(mentor.input.cornering)
            let speeding = parseFloat(mentor.input.speeding) > parseFloat(mentor.input.sse) ? parseFloat(mentor.input.speeding) : parseFloat(mentor.input.sse)
            let distraction = parseFloat(mentor.input.distraction)
            let seatbelt = parseFloat(mentor.input.seatbelt)
            let backUp = parseFloat(mentor.input.backUp)
            let breakdown = false
            messageStringMentorCO = messageStringMentorCO + `\n- Your Amazon FICO score was ${mentor.input.fico} for your last trip.`
            if(accel && mentor.input.accel != "N/A" || 
                braking && mentor.input.braking != "N/A" || 
                cornering && mentor.input.cornering != "N/A" || 
                speeding && mentor.input.speeding != "N/A" || 
                speeding && mentor.input.sse != "N/A" ||
                distraction && mentor.input.distraction != "N/A" || 
                seatbelt && mentor.input.seatbelt != "N/A" || 
                backUp && mentor.input.backUp != "N/A"){
                    messageStringMentorCO = messageStringMentorCO + ` You had: `
                    breakdown = true
            }
            if(breakdown) {
                if(accel && mentor.input.accel != "N/A") messageStringMentorCO = messageStringMentorCO + ` ${accel} instance${accel > 1 ? 's' : ''} of accelerating too quickly,` 
                if(braking && mentor.input.braking != "N/A") messageStringMentorCO = messageStringMentorCO + ` ${braking} instance${braking > 1 ? 's' : ''} of braking too harshly,`
                if(cornering && mentor.input.cornering != "N/A") messageStringMentorCO = messageStringMentorCO + ` ${cornering} instance${cornering > 1 ? 's' : ''} of taking a turn too quickly,`
                if(speeding && (mentor.input.speeding != "N/A" ||mentor.input.sse != "N/A")) messageStringMentorCO = messageStringMentorCO + ` ${speeding} instance${speeding > 1 ? 's' : ''} of speeding,` 
                if(distraction && mentor.input.distraction != "N/A") messageStringMentorCO = messageStringMentorCO + ` ${distraction} instance${distraction > 1 ? 's' : ''} of using your phone while driving,`
                if(seatbelt && mentor.input.seatbelt != "N/A") messageStringMentorCO = messageStringMentorCO + ` ${seatbelt} instance${seatbelt > 1 ? 's' : ''} of not wearing your seatbelt while driving,` 
                if(backUp && mentor.input.backUp != "N/A") messageStringMentorCO = messageStringMentorCO + ` ${backUp} instance${backUp > 1 ? 's' : ''} of backing up. If you have no choice but to back up, do not exceed 5 mph in reverse.`
                messageStringMentorCO = messageStringMentorCO.substring(0, messageStringMentorCO.length - 1) + '. To improve your FICO score, reduce the number of instances listed above.\n'
            }
            numberOfMentorCO++
        }
        // Seatbelt
        if((tenant.coachingSeatbeltCO) && (parseFloat(mentor.input.seatbelt) > parseFloat(tenant.coachingSeatbeltThreshold))){
            messageStringMentorCO = messageStringMentorCO + `\n- WARNING: You had ${parseFloat(mentor.input.seatbelt)}${parseFloat(mentor.input.seatbelt) > 1 ? ' instances': ' instance'} of not wearing your seatbelt while traveling during your last trip. Please remember to buckle your seatbelt before putting the vehicle in drive.\n`
            numberOfMentorCO++
        }
        // Sse
        if((tenant.coachingSseCO) && (parseFloat(mentor.input.sse) > parseFloat(tenant.coachingSseThreshold) || parseFloat(mentor.input.speeding) > parseFloat(tenant.coachingSseThreshold))){

            //Checks if existing message had a weekly positive reinforcement for speeding, if so remove it.
            if(existingMessage != null && existingMessage.bodyTextWeeklyPR.includes(`\n- You practiced safe driving and had a "Fantastic" week by following all posted speed limits.\n`)){
                const regex = /\n- You practiced safe driving and had a "Fantastic" week by following all posted speed limits.\n/i;
                existingMessage.bodyTextWeeklyPR = existingMessage.bodyTextWeeklyPR.replace(regex, '') 
            }

            //Set up number of instances for message, added in if-else statment to prevent message being on one really long line.
            var instanceMessage = null
            if(parseFloat(mentor.input.sse) > parseFloat(tenant.coachingSseThreshold) && parseFloat(mentor.input.speeding) > parseFloat(tenant.coachingSseThreshold)){
                instanceMessage = `${parseFloat(mentor.input.speeding)} ${parseFloat(mentor.input.speeding) > 1 ? 'instances': 'instance'} of speeding and ${parseFloat(mentor.input.sse)} ${parseFloat(mentor.input.sse) > 1 ? 'instances' : 'instance'} of Scorecard Speeding Event`
            }
            else if(!(parseFloat(mentor.input.sse) > parseFloat(tenant.coachingSseThreshold)) && parseFloat(mentor.input.speeding) > parseFloat(tenant.coachingSseThreshold)){
                instanceMessage = `${parseFloat(mentor.input.speeding)} ${parseFloat(mentor.input.speeding) > 1 ? 'instances': 'instance'} of speeding`
            }
            else if(parseFloat(mentor.input.sse) > parseFloat(tenant.coachingSseThreshold) && !(parseFloat(mentor.input.speeding) > parseFloat(tenant.coachingSseThreshold))){
                instanceMessage = `${parseFloat(mentor.input.sse)} ${parseFloat(mentor.input.sse) > 0 ? 'instances' : 'instance'} of Scorecard Speeding Event`
            }
            messageStringMentorCO = messageStringMentorCO + `\n- WARNING: You had ${instanceMessage} during your last trip. It is required to follow all posted speed limits.\n`
            numberOfMentorCO++
        }
        // DVCRs
        // if((tenant.coachingDvcrsCO) && (parseFloat(mentor.input.preDvcr) < 1 || parseFloat(mentor.input.postDvcr) < 1)){
        //     messageStringMentorCO = messageStringMentorCO + `\n- WARNING: You missed one or more of your vehicle inspections. Two are required every day.\n`
        //     numberOfMentorCO++
        // }
        // Trainings
        var trainingsRemaining = parseFloat(mentor.input.trainingAssigned) - parseFloat(mentor.input.trainingCompleted)
        if((tenant.coachingTraningRemainingCO) && (trainingsRemaining > 0)){
            messageStringMentorCO = messageStringMentorCO + `\n- You have ${trainingsRemaining} Mentor training video${trainingsRemaining > 1 ? 's' : ''} left to watch.\n`
            numberOfMentorCO++
        }
        
        
        // --- Positive reinforcement ---
        // FICO
        if((tenant.coachingDailyFicoPR) && (parseFloat(mentor.input.fico) >= parseFloat(tenant.coachingDailyFicoThreshold))){
            let accel = parseFloat(mentor.input.accel)
            let braking = parseFloat(mentor.input.braking)
            let cornering = parseFloat(mentor.input.cornering)
            let speeding = parseFloat(mentor.input.speeding) > parseFloat(mentor.input.sse) ? parseFloat(mentor.input.speeding) : parseFloat(mentor.input.sse)
            let distraction = parseFloat(mentor.input.distraction)
            let seatbelt = parseFloat(mentor.input.seatbelt)
            let backUp = parseFloat(mentor.input.backUp)
            let breakdown = false
            messageStringMentorPR = messageStringMentorPR + `\n- Your FICO was ${mentor.input.fico} for your last trip, which is over the threshold for "Fantastic"!`
            if(parseFloat(mentor.input.fico) != 850){
                messageStringMentorPR += ' Keep striving for 850!'
            
                if(accel && mentor.input.accel != "N/A" || 
                    braking && mentor.input.braking != "N/A" || 
                    cornering && mentor.input.cornering != "N/A" || 
                    speeding && mentor.input.speeding != "N/A" || 
                    speeding && mentor.input.sse != "N/A" || 
                    distraction && mentor.input.distraction != "N/A" || 
                    seatbelt && mentor.input.seatbelt != "N/A" || 
                    backUp && mentor.input.backUp != "N/A"){
                        messageStringMentorPR = messageStringMentorPR + ` You had: `
                        breakdown = true
                }
                if(breakdown){
                    if(accel && mentor.input.accel != "N/A") messageStringMentorPR = messageStringMentorPR + ` ${accel} instance${accel > 1 ? 's' : ''} of accelerating too quickly,` 
                    if(braking && mentor.input.braking != "N/A") messageStringMentorPR = messageStringMentorPR + ` ${braking} instance${braking > 1 ? 's' : ''} of braking too harshly,`
                    if(cornering && mentor.input.cornering != "N/A") messageStringMentorPR = messageStringMentorPR + ` ${cornering} instance${cornering > 1 ? 's' : ''} of taking a turn too quickly,`
                    if(speeding && (mentor.input.speeding != "N/A" ||  mentor.input.sse != "N/A")) messageStringMentorPR = messageStringMentorPR + ` ${speeding} instance${speeding > 1 ? 's' : ''} of speeding,` 
                    if(distraction && mentor.input.distraction != "N/A") messageStringMentorPR = messageStringMentorPR + ` ${distraction} instance${distraction > 1 ? 's' : ''} of using your phone while driving,`
                    if(seatbelt && mentor.input.seatbelt != "N/A") messageStringMentorPR = messageStringMentorPR + ` ${seatbelt} instance${seatbelt > 1 ? 's' : ''} of not wearing your seatbelt while driving,` 
                    if(backUp && mentor.input.backUp != "N/A") messageStringMentorPR = messageStringMentorPR + ` ${backUp} instance${backUp > 1 ? 's' : ''} of backing up. If you have no choice but to back up, do not exceed 5 mph in reverse.`
                    messageStringMentorPR = messageStringMentorPR.substring(0, messageStringMentorPR.length - 1) + '. To improve your FICO score, reduce the number of instances listed above.\n'
                }
                else messageStringMentorPR += '\n'
            }

            else messageStringMentorPR += '\n'
            numberOfMentorPR++
        }
        // Seatbelt
        if((tenant.coachingSeatbeltCO) && (parseFloat(mentor.input.seatbelt) <= parseFloat(tenant.coachingSeatbeltThreshold))){
            messageStringMentorPR = messageStringMentorPR + `\n- You kept yourself safe and wore your seatbelt during travel.\n`
            numberOfMentorPR++
        }
        // Sse
        if((tenant.coachingSseCO) && (parseFloat(mentor.input.sse) <= parseFloat(tenant.coachingSseThreshold)) && (parseFloat(mentor.input.speeding) <= parseFloat(tenant.coachingSseThreshold))){
            //Checks if existing message has a weekly coaching for speed, doesn't add postive daily for speed.
            if(existingMessage == null || !existingMessage.bodyTextWeeklyCO.includes( `instances of speeding per route last week. It is required to follow all posted speed limits.\n`)){
                messageStringMentorPR = messageStringMentorPR + `\n- You practiced safe driving and followed all posted speed limits.\n`
                numberOfMentorPR++
            }
        }
    }

    // ------------ NETRADYNE ------------
    if(netradyne){
        // exit if all Netradyne messages disabled
        if( !tenant.coachingCameraObstructionCO && !tenant.coachingDriverDistractionCO && !tenant.coachingDriverDrowsinessCO &&
            !tenant.coachingDriverStarPR && !tenant.coachingFollowingDistanceCO &&
            !tenant.coachingHardAccelerationCO && !tenant.coachingHardBrakingCO && !tenant.coachingHardTurnCO &&
            !tenant.coachingHighGCO && !tenant.coachingLowImpactCO && !tenant.coachingSeatbeltComplianceCO &&
            !tenant.coachingSignViolationsCO && !tenant.coachingSpeedingViolationsCO && !tenant.coachingTrafficLightViolationCO && 
            !tenant.coachingUTurnCO && !tenant.coachingWeavingCO ){
            return {status: 'noop', id: 'noop'}
        }
        const netradyneCoachingMessages = {
            coachingCameraObstruction: ` %value% instance(s) of %severity% camera obstructions,`,
            coachingDriverDistraction: ` %value% instance(s) of %severity% driver distractions,`,
            coachingDriverDrowsiness: ` %value% instance(s) of %severity% driver drowsiness,`,
            coachingDriverStar: ` %value% instance(s) of %severity% driver stars,`,
            coachingFollowingDistance: ` %value% instance(s) of %severity% following distance,`,
            coachingHardAcceleration: ` %value% instance(s) of %severity% hard acceleration,`,
            coachingHardBraking: ` %value% instance(s) of %severity% hard braking,`,
            coachingHardTurn: ` %value% instance(s) of %severity% hard turning,`,
            coachingHighG: ` %value% instance(s) of %severity% high G,`,
            coachingLowImpact: ` %value% instance(s) of %severity% low impact,`,
            coachingSeatbeltCompliance: ` %value% instance(s) of %severity% seatbelt compliance,`,
            coachingSignViolations: ` %value% instance(s) of %severity% sign violations,`,
            coachingSpeedingViolations: ` %value% instance(s) of %severity% speeding violations,`,
            coachingTrafficLightViolation: ` %value% instance(s) of %severity% traffic light violations,`,
            coachingUTurn: ` %value% instance(s) of %severity% u-turns,`,
            coachingWeaving: ` %value% instance(s) of %severity% weaving`
        }
        
        // loop through alerts and compare to thresholds 
        var alertCounts = {}
        let driverStarOnly = true
        let disabledOnly = true
        for(const alert of netradyne.alerts){
            let type = alert.alertType
            let severity = alert.alertSeverity
            if(type != "DRIVER-STAR") driverStarOnly = false
            if(!alertCounts[type]) alertCounts[type] = {}
            if(!alertCounts[type][severity]) alertCounts[type][severity] = 1
            else alertCounts[type][severity]++

            let fieldPrefix = 'coaching' + formatNetradyneType(type) 
            let totalAlertCount = Object.keys(alertCounts[type]).reduce((sum,key)=>sum+parseFloat(alertCounts[type][key]||0),0)
            if(disabledOnly && tenant[fieldPrefix + 'CO'] && (totalAlertCount > parseFloat(tenant[fieldPrefix + 'Threshold'])) ){
                disabledOnly = false
            }
        }
        if(!driverStarOnly && !disabledOnly) messageStringNetradyneCO = messageStringNetradyneCO + '\n- You had the following Netradyne alerts for your last trip: '
        let messagePart = ''
        for(const type of Object.keys(alertCounts)) {
            let fieldPrefix = 'coaching' + formatNetradyneType(type) 
            if (fieldPrefix  == 'coachingDriverInitiated') continue
            let totalAlertCount = Object.keys(alertCounts[type]).reduce((sum,key)=>sum+parseFloat(alertCounts[type][key]||0),0);
            if(tenant[fieldPrefix + 'CO'] && (totalAlertCount > parseFloat(tenant[fieldPrefix + 'Threshold'])) ){
                for(const severity of Object.keys(alertCounts[type])){
                    messagePart += netradyneCoachingMessages[fieldPrefix].replace('%value%', alertCounts[type][severity]).replace('%severity%', severity.toLowerCase())
                }
                numberOfNetradyneCO++
            }
            else if(type == 'DRIVER-STAR' && tenant.coachingDriverStarPR && (totalAlertCount >= parseFloat(tenant.coachingDriverStarThresholdPR))){
                messageStringNetradynePR = messageStringNetradynePR + `\n- You earned ${totalAlertCount} Driver Star${totalAlertCount > 1 ? 's': ''} during your last trip!`
                numberOfNetradynePR++
            }
        }
        if(!driverStarOnly && !disabledOnly)  messageStringNetradyneCO += `${messagePart.slice(0, -1)}. To improve your scorecard ranking, reduce the number of Netradyne alerts you are receiving.`
        
    }
    
    // get staff data
    var input = {
        id: staffId
    }
    var staff = await API.graphql(graphqlOperation(getStaff, input ))
    staff = staff.data.getStaff

    // determine sending method
    var channelType;
    if( staff.receiveTextMessages && staff.receiveEmailMessages) channelType = 'SMS/EMAIL';
    else if( staff.receiveTextMessages ) channelType = 'SMS';
    else if( staff.receiveEmailMessages ) channelType = 'EMAIL';
    else channelType = null

    // if(channelType){
        // try to update existing message in Dynamo
        var header = "Your Daily Performance Feedback"

        input = {
            id: pendingMessageId,
            staffId: staffId
        }
        
        
        try{
            // update weekly message fields
            var newMessageCO = ''
            var newMessagePR = ''
            if(scorecard || cx){
                if(messageStringWeeklyCO) newMessageCO = "Here are the areas that need improvement from your latest scorecard:\n" + messageStringWeeklyCO + "\n"
                if(messageStringWeeklyPR) newMessagePR = "Here are the things you excelled at on your latest scorecard:\n" + messageStringWeeklyPR + "\n"
                if(existingMessage != null && existingMessage.bodyTextDailyCO) newMessageCO += "Here are the areas that need improvement from your last trip:\n" + existingMessage.bodyTextDailyCO
                if(existingMessage != null && existingMessage.bodyTextDailyPR) newMessagePR += "Here are the things you excelled at on your last trip:\n" + existingMessage.bodyTextDailyPR

                input.bodyTextWeeklyCO = messageStringWeeklyCO,
                input.bodyTextWeeklyPR = messageStringWeeklyPR,
                input.bodyTextCO = newMessageCO
                input.bodyTextPR = newMessagePR
                input.numberOfWeeklyCO = numberOfWeeklyCO,
                input.numberOfWeeklyPR = numberOfWeeklyPR,
                input.numberOfCO = parseInt(existingMessage ? existingMessage.numberOfDailyCO : '0') + numberOfWeeklyCO
                input.numberOfPR = parseInt(existingMessage ? existingMessage.numberOfDailyPR : '0') + numberOfWeeklyPR
                input.channelType = channelType
            }

            // update daily message fields
            if(mentor || netradyne){
                // copy weekly message parts from existing message
                if(existingMessage != null && existingMessage.bodyTextWeeklyCO){
                    newMessageCO = "Here are the areas that need improvement from your latest scorecard:\n" + existingMessage.bodyTextWeeklyCO + "\n"
                    numberOfWeeklyCO = parseInt(existingMessage.numberOfWeeklyCO)
                }
                if(existingMessage != null && existingMessage.bodyTextWeeklyPR){
                    newMessagePR = "Here are the things you excelled at on your latest scorecard:\n" + existingMessage.bodyTextWeeklyPR + "\n"
                    numberOfWeeklyPR = parseInt(existingMessage.numberOfWeeklyPR)
                }
                
                // copy or overwrite daily message parts from existing message
                if(existingMessage != null){
                    // use past mentor coaching message if none generated by this import
                    if(existingMessage.bodyTextMentorCO && !messageStringMentorCO){
                        messageStringMentorCO += existingMessage.bodyTextMentorCO
                        numberOfMentorCO = existingMessage.numberOfMentorCO
                    } 
                    // use past mentor reinforcement message if none generated by this import
                    if(existingMessage.bodyTextMentorPR && !messageStringMentorPR){
                        messageStringMentorPR += existingMessage.bodyTextMentorPR
                        numberOfMentorPR = existingMessage.numberOfMentorPR
                    }
                    // use past netradyne coaching message if none generated by this import
                    if(existingMessage.bodyTextNetradyneCO && !messageStringNetradyneCO){
                        messageStringNetradyneCO += existingMessage.bodyTextNetradyneCO
                        numberOfNetradyneCO = existingMessage.numberOfNetradyneCO
                    }   
                    // use past netradyne reinforcement message if none generated by this import
                    if(existingMessage.bodyTextNetradynePR && !messageStringNetradynePR){
                        messageStringNetradynePR += existingMessage.bodyTextNetradynePR
                        numberOfNetradynePR = existingMessage.numberOfNetradynePR
                    } 
                }
                
                
                // add message parts together to form daily messages
                messageStringDailyCO += `${messageStringMentorCO}${messageStringNetradyneCO}`
                messageStringDailyPR += `${messageStringMentorPR}${messageStringNetradynePR}`
                if(messageStringDailyCO) newMessageCO += "Here are the areas that need improvement from your last trip:\n" + messageStringDailyCO
                if(messageStringDailyPR) newMessagePR += "Here are the things you excelled at on your last trip:\n" + messageStringDailyPR

                input.bodyTextDailyCO = messageStringDailyCO
                input.bodyTextMentorCO = messageStringMentorCO
                input.bodyTextNetradyneCO = messageStringNetradyneCO
                input.bodyTextCO = newMessageCO

                input.bodyTextDailyPR = messageStringDailyPR
                input.bodyTextMentorPR = messageStringMentorPR
                input.bodyTextNetradynePR = messageStringNetradynePR
                input.bodyTextPR = newMessagePR

                numberOfDailyCO = parseInt(numberOfMentorCO) + parseInt(numberOfNetradyneCO)
                numberOfDailyPR = parseInt(numberOfMentorPR) + parseInt(numberOfNetradynePR)

                input.numberOfDailyCO = numberOfDailyCO
                input.numberOfMentorCO = numberOfMentorCO
                input.numberOfNetradyneCO = numberOfNetradyneCO
                input.numberOfDailyPR = numberOfDailyPR
                input.numberOfMentorPR = numberOfMentorPR
                input.numberOfNetradynePR = numberOfNetradynePR

                input.numberOfCO = numberOfDailyCO + numberOfWeeklyCO
                input.numberOfPR = numberOfDailyPR + numberOfWeeklyPR

                input.channelType = channelType
                input.importId = importId
            }
            var result = await API.graphql(graphqlOperation(updatePendingMessage, {input} ));
            return {status: 'updated', id: pendingMessageId}
        }
        catch(e){
            // if update fails, create new pending message
            console.error(e)
            try{
    
                input = {
                    id: pendingMessageId,
                    staffId: staffId,
                    pendingMessageTenantId: tenant.id,
                    group: tenant.group,
                    numberOfWeeklyCO: numberOfWeeklyCO,
                    numberOfWeeklyPR: numberOfWeeklyPR,
                    numberOfDailyCO: numberOfDailyCO,
                    numberOfMentorCO: numberOfMentorCO,
                    numberOfNetradyneCO: numberOfNetradyneCO,
                    numberOfDailyPR: numberOfDailyPR,
                    numberOfMentorPR: numberOfMentorPR,
                    numberOfNetradynePR: numberOfNetradynePR,
                    numberOfCO: numberOfWeeklyCO + numberOfDailyCO,
                    numberOfPR: numberOfWeeklyPR + numberOfDailyPR,
                    bodyTextWeeklyCO: messageStringWeeklyCO,
                    bodyTextWeeklyPR: messageStringWeeklyPR,
                    bodyTextDailyCO: messageStringDailyCO,
                    bodyTextMentorCO: messageStringMentorCO,
                    bodyTextNetradyneCO: messageStringNetradyneCO,
                    bodyTextDailyPR: messageStringDailyPR,
                    bodyTextMentorPR: messageStringMentorPR,
                    bodyTextNetradynePR: messageStringNetradynePR,
                    channelType: channelType
                }
                input.bodyTextCO = ''
                input.bodyTextPR = ''
                if(importId){
                    input.importId = importId
                }
                if(!messageStringWeeklyCO && !messageStringWeeklyPR && !messageStringDailyCO && !messageStringDailyPR){
                    return {status: 'noop', id: 'noop'}
                }
                else{
                    if(messageStringWeeklyCO) input.bodyTextCO = "Here are the areas that need improvement from your latest scorecard:\n" + messageStringWeeklyCO + "\n"
                    if(messageStringWeeklyPR) input.bodyTextPR = "Here are the things you excelled at on your latest scorecard:\n" + messageStringWeeklyPR + "\n"
                    if(messageStringDailyCO) input.bodyTextCO += "Here are the areas that need improvement from your last trip:\n" + messageStringDailyCO
                    if(messageStringDailyPR) input.bodyTextPR += "Here are the things you excelled at on your last trip:\n" + messageStringDailyPR
                    var pendingMessage = await API.graphql(graphqlOperation(createPendingMessage, {input}))
                    return {status: 'created', id: pendingMessageId}
                }
            }
            catch(e){
                console.error(e)
                return {status: 'errors', id: pendingMessageId}
            }
        }
    // }
}

export default createPendingMessageRecord;