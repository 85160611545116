export const vehiclesByGroup = /* GraphQL */ `
  query VehiclesByGroup(
    $group: String
    $name: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelVehicleFilterInput
    $filter2: ModelAccidentFilterInput
    $limit: Int
    $nextToken: String
  ) {
    vehiclesByGroup(
      group: $group
      name: $name
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        group
        name
        status
        mileage
        vin
        lastOdometerReadingDate
        licensePlate
        licensePlateExp
        gasCard
        company {
          id
          option
        }
        make
        model
        year
        state
        dateStart
        notes
        dateEnd
        ownership
        vehicleType {
          id
          group
          order
          option
        }
        labels {
          items {
            id
            labelId
            label {
              id
              name
            }
          }
        }
        type
        rentalAgreementNumber
        tollPassId
        parkingSpace {
          id
          group
          order
          option
        }
        updatedAt
        maintenanceRecords(
          filter: $filter2
        ){
          items{
            id
            vehicleHistoryType
            services
            location
            maintenanceStatus
            accidentDate
            accidentType
          }
        }
        device {
          deviceName
        }
        vehicleRecordHistory {
          items {
            id
            changes
            createdAt
            date
            userFirstName
            userLastName
          }
          nextToken
        }
        vehicleStatusHistory {
          items {
            id
            reason
            date
            previousStatus
          }
          nextToken
        }
        defaultStaff {
          items {
            id
            group
            transporterId
            firstName
            lastName
            alternateNames
            authorizedToDrive {
              items {
                id
                optionCustomList {
                  id
                  option
                }
              }
              nextToken
            }
            defaultVehicle {
              id
            }
            defaultVehicle2 {
              id
            }
            defaultVehicle3 {
              id
            }
            phone
            email
            keyFocusArea
            keyFocusAreaCompleted
            coachingOpportunity
            status
            dlExpiration
            vehicleReport
            gasCardPin
            dob
            gender
            pronouns
            assignedLead
            isALead
            hourlyStatus
            hireDate
            terminationDate
            finalCheckIssueDate
            returnedUniform
            latestScorecard
            smsLastMessageTimestamp
            smsLastMessage
            vehicleType
            photo
            onboardingPinned
            netradyneDriverId
            heraScore
            heraRank
            prevHeraRank
            receiveTextMessages
            receiveEmailMessages
            notes
            onboardingNotes
            preferredDaysOff
            createdAt
            updatedAt
          }
          nextToken
        }
        defaultStaff2 {
          items {
            id
            group
            transporterId
            firstName
            lastName
            alternateNames
            authorizedToDrive {
              items {
                id
                optionCustomList {
                  id
                  option
                }
              }
              nextToken
            }
            defaultVehicle {
              id
            }
            defaultVehicle2 {
              id
            }
            defaultVehicle3 {
              id
            }
            phone
            email
            keyFocusArea
            keyFocusAreaCompleted
            coachingOpportunity
            status
            dlExpiration
            vehicleReport
            gasCardPin
            dob
            gender
            pronouns
            assignedLead
            isALead
            hourlyStatus
            hireDate
            terminationDate
            finalCheckIssueDate
            returnedUniform
            latestScorecard
            smsLastMessageTimestamp
            smsLastMessage
            vehicleType
            photo
            onboardingPinned
            netradyneDriverId
            heraScore
            heraRank
            prevHeraRank
            receiveTextMessages
            receiveEmailMessages
            notes
            onboardingNotes
            preferredDaysOff
            createdAt
            updatedAt
          }
          nextToken
        }
        defaultStaff3 {
          items {
            id
            group
            transporterId
            firstName
            lastName
            alternateNames
            authorizedToDrive {
              items {
                id
                optionCustomList {
                  id
                  option
                }
              }
              nextToken
            }
            defaultVehicle {
              id
            }
            defaultVehicle2 {
              id
            }
            defaultVehicle3 {
              id
            }
            phone
            email
            keyFocusArea
            keyFocusAreaCompleted
            coachingOpportunity
            status
            dlExpiration
            vehicleReport
            gasCardPin
            dob
            gender
            pronouns
            assignedLead
            isALead
            hourlyStatus
            hireDate
            terminationDate
            finalCheckIssueDate
            returnedUniform
            latestScorecard
            smsLastMessageTimestamp
            smsLastMessage
            vehicleType
            photo
            onboardingPinned
            netradyneDriverId
            heraScore
            heraRank
            prevHeraRank
            receiveTextMessages
            receiveEmailMessages
            notes
            onboardingNotes
            preferredDaysOff
            createdAt
            updatedAt
          }
          nextToken
        }
      }
      nextToken
    }
  }
`;

export const vehiclesByStatus = /* GraphQL */ `
  query VehiclesByStatus(
    $status: String
    $name: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelVehicleFilterInput
    $limit: Int
    $nextToken: String
  ) {
    vehiclesByStatus(
      status: $status
      name: $name
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        group
        name
        status
        mileage
        vin
        lastOdometerReadingDate
        licensePlate
        licensePlateExp
        gasCard
        company {
          id
          option
        }
        make
        model
        year
        state
        dateStart
        notes
        dateEnd
        ownership
        type
        vehicleType {
          id
          group
          order
          option
        }
        rentalAgreementNumber
        tollPassId
        parkingSpace {
          id
          group
          order
          option
        }
        updatedAt
        device {
          id
          deviceName
        }
        device2 {
          id
          deviceName
        }
      }
      nextToken
    }
  }
`;

export const devicesByGroup = /* GraphQL */ `
  query DevicesByGroup(
    $group: String
    $deviceName: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelDeviceFilterInput
    $limit: Int
    $nextToken: String
  ) {
    devicesByGroup(
      group: $group
      deviceName: $deviceName
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        group
        deviceName
        status
        imei
        vehicle {
            items{
                id
            }
          nextToken
        }
      }
      nextToken
    }
  }
`;

export const vehicleMaintenanceRemindersByVehicle = /* GraphQL */ `
  query VehicleMaintenanceRemindersByVehicle(
    $vehicleId: String
    $dueBySort: ModelFloatKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelVehicleMaintenanceReminderFilterInput
    $limit: Int
    $nextToken: String
  ) {
    vehicleMaintenanceRemindersByVehicle(
      vehicleId: $vehicleId
      dueBySort: $dueBySort
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        group
        dueByDate
        dueByMileage
        dueBySort
        services
        status
      }
      nextToken
    }
  }
`;

export const vehicleMaintanceReminderByVehicleAndStatus = /* GraphQL */ `
  query VehicleMaintanceReminderByVehicleAndStatus(
    $vehicleId: String
    $statusDueBySort: ModelVehicleMaintenanceReminderByVehicleByStatusCompositeKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelVehicleMaintenanceReminderFilterInput
    $limit: Int
    $nextToken: String
  ) {
    vehicleMaintanceReminderByVehicleAndStatus(
      vehicleId: $vehicleId
      statusDueBySort: $statusDueBySort
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        group
        dueByDate
        dueByMileage
        dueBySort
        services
        status
        updatedAt
      }
      nextToken
    }
  }
`;

export const accidentsByVehicle = /* GraphQL */ `
  query AccidentsByVehicle(
    $vehicleId: String
    $accidentDate: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelAccidentFilterInput
    $limit: Int
    $nextToken: String
  ) {
    accidentsByVehicle(
      vehicleId: $vehicleId
      accidentDate: $accidentDate
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        address
        addressCity
        addressState
        addressZip
        accidentDate
        notes
        vehicleHistoryType
        services
        location
        maintenanceStatus
        maintenanceDateCompleted
        mileageAsOfMaintenance
        mileage
        time
        damage
        damageSeverity
        vehicleDamageDate
        odometerDate
        odometerType
        createdAt
        updatedAt
        staff {
          firstName
          lastName
        }
        verifiedBy {
          firstName
          lastName
        }
        createdByUser {
          firstName
          lastName
        }
        optionCustomList {
          id
          option
        }
        maintenanceDocuments {
          items {
            id
            group
            name
            key
            type
            uploadDate
            notes
            createdAt
            updatedAt
          }
          nextToken
        }
        vehicleDamageImages {
          items {
            id
            group
            name
            key
            type
            uploadDate
            notes
            createdAt
            updatedAt
          }
          nextToken
        }
      }
      nextToken
    }
  }
`;

export const accidentsByVehicleAndHistoryType = /* GraphQL */ `
  query AccidentsByVehicleAndHistoryType(
    $vehicleId: String
    $vehicleHistoryTypeAccidentDate: ModelAccidentByVehicelByHistoryTypeCompositeKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelAccidentFilterInput
    $limit: Int
    $nextToken: String
  ) {
    accidentsByVehicleAndHistoryType(
      vehicleId: $vehicleId
      vehicleHistoryTypeAccidentDate: $vehicleHistoryTypeAccidentDate
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    )  {
      items {
        id
        address
        addressCity
        addressState
        addressZip
        accidentDate
        notes
        vehicleHistoryType
        services
        location
        maintenanceStatus
        maintenanceDateCompleted
        mileageAsOfMaintenance
        mileage
        time
        damage
        damageSeverity
        vehicleDamageDate
        odometerDate
        odometerType
        createdAt
        updatedAt
        staff {
          firstName
          lastName
        }
      }
      nextToken
    }
  }
`;

export const getAccident = /* GraphQL */ `
  query GetAccident($id: ID!) {
    getAccident(id: $id) {
      id
      group
      accidentDate
      notes
      staffId
      vehicleId
      vehicleHistoryType
      services
      location
      maintenanceStatus
      maintenanceDateCompleted
      mileageAsOfMaintenance
      mileage
      time
      damage
      damageSeverity
      vehicleDamageDate
      odometerDate
      odometerType
      createdAt
      updatedAt
      incidentDocuments {
        items {
          id
          group
          name
          key
        }
      }
      vehicleDamageImages {
        items {
          id
          group
          name
          key
          type
          uploadDate
          notes
          createdAt
          updatedAt
        }
        nextToken
      }
      maintenanceDocuments {
        items {
          id
          group
          name
          key
          type
          uploadDate
          notes
          createdAt
          updatedAt
        }
        nextToken
      }
      staff {
        id
        group
        transporterId
        firstName
        lastName
        alternateNames
      }
      vehicle {
        id
        group
        name
      }
    }
  }
`;

export const vehicleMaintanceReminderByGroupAndStatus = /* GraphQL */ `
  query VehicleMaintanceReminderByGroupAndStatus(
    $group: String
    $statusDueBySort: ModelVehicleMaintenanceReminderByGroupByStatusCompositeKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelVehicleMaintenanceReminderFilterInput
    $limit: Int
    $nextToken: String
  ) {
    vehicleMaintanceReminderByGroupAndStatus(
      group: $group
      statusDueBySort: $statusDueBySort
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        group
        dueByDate
        dueByMileage
        services
        status
        vehicleId
        dueBySort
        dateCompleted
        updatedAt
        completedBy {
          id
          group
          firstName
          lastName
        }
        vehicle {
          id
          group
          name
          mileage
          vin
        }
      }
      nextToken
    }
  }
`;

export const vehicleMaintenanceRemindersByGroup = /* GraphQL */ `
  query VehicleMaintenanceRemindersByGroup(
    $group: String
    $id: ModelIDKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelVehicleMaintenanceReminderFilterInput
    $limit: Int
    $nextToken: String
  ) {
    vehicleMaintenanceRemindersByGroup(
      group: $group
      id: $id
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        group
        dueByDate
        dueByMileage
        services
        status
        vehicleId
        dueBySort
        dateCompleted
        userId
        createdAt
        updatedAt
        completedBy {
          id
          group
          firstName
          lastName
        }
        vehicle {
          id
          vin
          status
          group
          name
          mileage
        }
      }
      nextToken
    }
  }
`;

export const accidentsByGroupAndHistoryType = /* GraphQL */ `
  query AccidentsByGroupAndHistoryType(
    $group: String
    $vehicleHistoryTypeAccidentDate: ModelAccidentByGroupByHistoryTypeCompositeKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelAccidentFilterInput
    $limit: Int
    $nextToken: String
  ) {
    accidentsByGroupAndHistoryType(
      group: $group
      vehicleHistoryTypeAccidentDate: $vehicleHistoryTypeAccidentDate
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        group
        accidentDate
        accidentType
        address
        addressCity
        addressState
        addressZip
        staffId
        vehicleId
        vehicleHistoryType
        damage
        damageSeverity
        createdAt
        updatedAt
        staff {
          id
          group
          transporterId
          firstName
          lastName
        }
        vehicle {
          id
          group
          name
          status
        }
        damageVehicle {
          id
          group
          name
        }
        services
        location
        maintenanceStatus
        optionCustomList {
          id
          option
        }
      }
      nextToken
    }
  }
`;
export const accidentsByGroupAndAccidentDate = /* GraphQL */ `
  query AccidentsByGroupAndAccidentDate(
    $group: String
    $accidentDate: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelAccidentFilterInput
    $limit: Int
    $nextToken: String
  ) {
    accidentsByGroupAndAccidentDate(
      group: $group
      accidentDate: $accidentDate
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ){
      items {
        id
        group
        accidentDate
        accidentType
        address
        addressCity
        addressState
        addressZip
        staffId
        vehicleId
        vehicleHistoryType
        damage
        damageSeverity
        createdAt
        updatedAt
        staff {
          id
          group
          transporterId
          firstName
          lastName
        }
        vehicle {
          id
          group
          name
        }
        damageVehicle {
          id
          group
          name
        }
      }
      nextToken
    }
  }
`;
export const staffsByGroup = /* GraphQL */ `
  query StaffsByGroup(
    $group: String
    $firstName: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelStaffFilterInput
    $limit: Int
    $nextToken: String
  ) {
    staffsByGroup(
      group: $group
      firstName: $firstName
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        group
        transporterId
        firstName
        lastName
        alternateNames
      }
      nextToken
    }
  }
`;
export const vehiclesByGroupAndLicensePlateExp = /* GraphQL */ `
  query VehiclesByGroupAndLicensePlateExp(
    $group: String
    $licensePlateExp: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelVehicleFilterInput
    $limit: Int
    $nextToken: String
  ) {
    vehiclesByGroupAndLicensePlateExp(
      group: $group
      licensePlateExp: $licensePlateExp
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        group
        name
        licensePlateExp
        licensePlate
        mileage
        status
        vin
        gasCard
        company {
          id
          option
        }
        defaultStaff{
          items{
            id
            group
            firstName
            lastName
            authorizedToDrive{
              items{
                id
                optionCustomList{
                  id
                  option
                }
              }
            }
          }
          nextToken
        }
        defaultStaff2{
          items{
            id
            group
            authorizedToDrive{
              items{
                id
                optionCustomList{
                  id
                  option
                }
              }
            }
          }
          nextToken
        }
        defaultStaff3{
          items{
            id
            group
            authorizedToDrive{
              items{
                id
                optionCustomList{
                  id
                  option
                }
              }
            }
          }
          nextToken
        }
        vehicleType {
          id
          group
          order
          option
        }
        updatedAt
        device {
          id
        }
      }
      nextToken
    }
  }
`;

export const vehiclesByGroupAndLastOdometerReadingDate = /* GraphQL */ `
  query VehiclesByGroupAndLastOdometerReadingDate(
    $group: String
    $lastOdometerReadingDate: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelVehicleFilterInput
    $limit: Int
    $nextToken: String
  ) {
    vehiclesByGroupAndLastOdometerReadingDate(
      group: $group
      lastOdometerReadingDate: $lastOdometerReadingDate
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        group
        name
        mileage
        lastOdometerReadingDate
        licensePlateExp
        licensePlate
        status
        vin
        gasCard
        defaultStaff{
          items{
            id
            group
            firstName
            lastName
            authorizedToDrive{
              items{
                id
                optionCustomList{
                  id
                  option
                }
              }
            }
          }
          nextToken
        }
        defaultStaff2{
          items{
            id
            group
            firstName
            lastName
            authorizedToDrive{
              items{
                id
                optionCustomList{
                  id
                  option
                }
              }
            }
          }
          nextToken
        }
        defaultStaff3{
          items{
            id
            group
            firstName
            lastName
            authorizedToDrive{
              items{
                id
                optionCustomList{
                  id
                  option
                }
              }
            }
          }
          nextToken
        }
        vehicleType {
          id
          group
          order
          option
        }
        company {
          id
          option
        }
        createdAt
        updatedAt
        device {
          id
        }
        device2 {
          id
        }
        vehicleRecordHistory {
          items {
            id
            changes
            createdAt
            date
            userFirstName
            userLastName
          }
          nextToken
        }
        vehicleStatusHistory {
          items {
            id
            reason
            date
            previousStatus
          }
          nextToken
        }
      }
      nextToken
    }
  }
`;

export const getVehicle = /* GraphQL */ `
  query GetVehicle($id: ID!) {
    getVehicle(id: $id) {
      id
      name
      vin
      group
      vehicle
      type
      labels {
        items {
          id
          labelId
          label {
            id
            name
          }
        }
      }
      vehicleType {
        id
        group
        customLists {
          id
          group
          type
          listCategory
          listName
          listDisplay
          canDeleteAllOptions
          createdAt
          updatedAt
        }
        order
        option
        pillColor
        default
        usedFor
        daysCount
        canBeEdited
        canBeDeleted
        canBeReorder
        accidents {
          nextToken
        }
        replaceByRouteParkingSpace {
          nextToken
        }
        createdAt
        updatedAt
        routeParkingSpace {
          nextToken
          scannedCount
        }
        vehicles {
          nextToken
        }
        parkingSpace {
          nextToken
        }
      }
      mentionedInNotes {
        items {
          id
          group
          date
          note {
            id
            type
            text
            dailyRoster {
              id
              notesDate
            }
          }
          createdAt
          updatedAt
        }
        nextToken
      }
      state
      licensePlateExp
      dateStart
      dateEnd
      ownership
      provider
      otherProvider
      category
      make
      model
      year
      rentalAgreementNumber
      images
      rentalContracts
      accidentReports
      licensePlate
      mileage
      gasCard
      status
      company {
        id
        option
        pillColor
      }
      notes
      parkingSpace {
        id
        group
        customLists {
          id
          group
          type
          listCategory
          listName
          listDisplay
          canDeleteAllOptions
          createdAt
          updatedAt
        }
        order
        option
        pillColor
        default
        usedFor
        daysCount
        canBeEdited
        canBeDeleted
        canBeReorder
        accidents {
          nextToken
        }
        associates {
          nextToken
        }
        replaceByRouteParkingSpace {
          nextToken
        }
        createdAt
        updatedAt
        routeParkingSpace {
          nextToken
          scannedCount
        }
        vehicles {
          nextToken
        }
        parkingSpace {
          nextToken
        }
      }
      lastOdometerReadingDate
      accidents {
        items {
          id
          group
          atFault
          drugTestDate
          drugTestResult
          accidentDate
          accidentType
          address
          addressCity
          addressState
          addressZip
          fileName
          verifiedDate
          notes
          insuranceClaimNumber
          policeDepartment
          policeOfficerName
          policeReportNumber
          staffId
          vehicleId
          vehicleHistoryType
          services
          location
          maintenanceStatus
          maintenanceDateCompleted
          mileageAsOfMaintenance
          mileage
          time
          damage
          damageSeverity
          vehicleDamageDate
          odometerDate
          odometerType
          createdAt
          updatedAt
        }
        nextToken
      }
      maintenanceRecords {
        items {
          id
          group
          atFault
          drugTestDate
          drugTestResult
          accidentDate
          accidentType
          address
          addressCity
          addressState
          addressZip
          fileName
          verifiedDate
          notes
          insuranceClaimNumber
          policeDepartment
          policeOfficerName
          policeReportNumber
          staffId
          vehicleId
          vehicleHistoryType
          services
          location
          maintenanceStatus
          maintenanceDateCompleted
          mileageAsOfMaintenance
          mileage
          time
          damage
          damageSeverity
          vehicleDamageDate
          odometerDate
          odometerType
          createdAt
          updatedAt
        }
        nextToken
      }
      vehicleDamage {
        items {
          id
          group
          atFault
          drugTestDate
          drugTestResult
          accidentDate
          accidentType
          address
          addressCity
          addressState
          addressZip
          fileName
          verifiedDate
          notes
          insuranceClaimNumber
          policeDepartment
          policeOfficerName
          policeReportNumber
          staffId
          vehicleId
          vehicleHistoryType
          services
          location
          maintenanceStatus
          maintenanceDateCompleted
          mileageAsOfMaintenance
          mileage
          time
          damage
          damageSeverity
          vehicleDamageDate
          odometerDate
          odometerType
          createdAt
          updatedAt
        }
        nextToken
      }
      odometerReadings {
        items {
          id
          group
          atFault
          drugTestDate
          drugTestResult
          accidentDate
          accidentType
          address
          addressCity
          addressState
          addressZip
          fileName
          verifiedDate
          notes
          insuranceClaimNumber
          policeDepartment
          policeOfficerName
          policeReportNumber
          staffId
          vehicleId
          vehicleHistoryType
          services
          location
          maintenanceStatus
          maintenanceDateCompleted
          mileageAsOfMaintenance
          mileage
          time
          damage
          damageSeverity
          vehicleDamageDate
          odometerDate
          odometerType
          createdAt
          updatedAt
        }
        nextToken
      }
      maintenance {
        items {
          id
          group
          dateScheduled
          dateComleted
          description
          receipt
          odometer
          createdAt
          updatedAt
        }
        nextToken
      }
      documents {
        items {
          id
          group
          name
          key
          type
          uploadDate
          notes
          documentDate
          createdAt
          updatedAt
        }
        nextToken
      }
      reminders {
        items {
          id
          group
          dueByDate
          dueByMileage
          services
          status
          vehicleId
          dueBySort
          dateCompleted
          userId
          notes
          createdAt
          updatedAt
        }
        nextToken
      }
      vehicleRecordHistory {
        items {
          id
          changes
          createdAt
          date
          userFirstName
          userLastName
        }
        nextToken
      }
      vehicleStatusHistory {
        items {
          id
          group
          reason
          date
          previousStatus
          currentStatus
          createdAt
          updatedAt
        }
        nextToken
      }
      dailyLogs {
        items {
          id
          group
          type
          date
          notes
          vehicleId
          createdAt
          updatedAt
        }
        nextToken
      }
      tollPassId
      createdAt
      updatedAt
      device {
        id
        group
        deviceName
        phoneNumber
        carrier
        status
        notes
        replaceByRoute {
          nextToken
        }
        createdAt
        updatedAt
        route {
          nextToken
          scannedCount
        }
        route2 {
          nextToken
          scannedCount
        }
        vehicle {
          nextToken
        }
        vehicle2 {
          nextToken
        }
      }
      device2 {
        id
        group
        deviceName
        phoneNumber
        carrier
        status
        notes
        replaceByRoute {
          nextToken
        }
        createdAt
        updatedAt
        route {
          nextToken
          scannedCount
        }
        route2 {
          nextToken
          scannedCount
        }
        vehicle {
          nextToken
        }
        vehicle2 {
          nextToken
        }
      }
      route {
        items {
          id
          group
          notes
          routeNumber
          staging
          previousStatus
          statusChangedTime
          status {
            id
            option
          }
          helperStatus {
            id
            option
          }
          standby
          time
          isNotActive
          messageSentTime
          messageSentError
          receivedAnnouncement
          rescued
          routeStaffId
          totalStops
          totalPackages
          completedStops
          completedPackages
          undeliveredPackagesTotal
          undeliveredPackagesBusinessClose
          undeliveredPackagesUnableToAccess
          undeliveredPackagesOtherAWS
          pickUpReturnPackage
          additionalPackagesFromRescue
          splitWithRosterAssignment
          additionalPackagesFromSplit
          firstStopTime
          lastStopTime
          daWorkStartTime
          daWorkEndTime
          daRouteStartTime
          daRouteEndTime
          rtsTime
          lunchStartTime
          lunchEndTime
          inspectionFueled
          inspectionCleaned
          inspectionFlashers
          inspectionCreditCard
          inspectionKeys
          inspectionDeviceCharger
          inspectionDeviceBattery
          inspectionNotes
          createdAt
          updatedAt
        }
        nextToken
        scannedCount
      }
      defaultStaff {
        items {
          id
          group
          transporterId
          firstName
          lastName
          alternateNames
          authorizedToDrive {
            items {
              id
              optionCustomList {
                id
                option
              }
            }
            nextToken
          }
          defaultVehicle {
            id
          }
          defaultVehicle2 {
            id
          }
          defaultVehicle3 {
            id
          }
          phone
          email
          keyFocusArea
          keyFocusAreaCompleted
          coachingOpportunity
          status
          dlExpiration
          vehicleReport
          gasCardPin
          dob
          gender
          pronouns
          assignedLead
          isALead
          hourlyStatus
          hireDate
          terminationDate
          finalCheckIssueDate
          returnedUniform
          latestScorecard
          smsLastMessageTimestamp
          smsLastMessage
          vehicleType
          photo
          onboardingPinned
          netradyneDriverId
          heraScore
          heraRank
          prevHeraRank
          receiveTextMessages
          receiveEmailMessages
          notes
          onboardingNotes
          preferredDaysOff
          createdAt
          updatedAt
        }
        nextToken
      }
      defaultStaff2 {
        items {
          id
          group
          transporterId
          firstName
          lastName
          alternateNames
          authorizedToDrive {
            items {
              id
              optionCustomList {
                id
                option
              }
            }
            nextToken
          }
          defaultVehicle {
            id
          }
          defaultVehicle2 {
            id
          }
          defaultVehicle3 {
            id
          }
          phone
          email
          keyFocusArea
          keyFocusAreaCompleted
          coachingOpportunity
          status
          dlExpiration
          vehicleReport
          gasCardPin
          dob
          gender
          pronouns
          assignedLead
          isALead
          hourlyStatus
          hireDate
          terminationDate
          finalCheckIssueDate
          returnedUniform
          latestScorecard
          smsLastMessageTimestamp
          smsLastMessage
          vehicleType
          photo
          onboardingPinned
          netradyneDriverId
          heraScore
          heraRank
          prevHeraRank
          receiveTextMessages
          receiveEmailMessages
          notes
          onboardingNotes
          preferredDaysOff
          createdAt
          updatedAt
        }
        nextToken
      }
      defaultStaff3 {
        items {
          id
          group
          transporterId
          firstName
          lastName
          alternateNames
          authorizedToDrive {
            items {
              id
              optionCustomList {
                id
                option
              }
            }
            nextToken
          }
          defaultVehicle {
            id
          }
          defaultVehicle2 {
            id
          }
          defaultVehicle3 {
            id
          }
          phone
          email
          keyFocusArea
          keyFocusAreaCompleted
          coachingOpportunity
          status
          dlExpiration
          vehicleReport
          gasCardPin
          
          dob
          gender
          pronouns
          assignedLead
          isALead
          hourlyStatus
          hireDate
          terminationDate
          finalCheckIssueDate
          returnedUniform
          latestScorecard
          smsLastMessageTimestamp
          smsLastMessage
          vehicleType
          photo
          onboardingPinned
          netradyneDriverId
          heraScore
          heraRank
          prevHeraRank
          receiveTextMessages
          receiveEmailMessages
          notes
          onboardingNotes
          preferredDaysOff
          createdAt
          updatedAt
        }
        nextToken
      }
    }
  }
`;

export const getDefaultVehicleStaffs = /* GraphQL */ `
  query GetVehicle($id: ID!) {
    getVehicle(id: $id) {
      id
      name
      group
      defaultStaff {
        items {
          id
          group
          firstName
          lastName
        }
        nextToken
      }
      defaultStaff2 {
        items {
          id
          group
          firstName
          lastName
        }
        nextToken
      }
      defaultStaff3 {
        items {
          id
          group
          firstName
          lastName
        }
        nextToken
      }
    }
  }
`;

export const vehiclesByVin = /* GraphQL */ `
  query VehiclesByVin(
    $group: ModelStringKeyConditionInput
    $vin: String
    $sortDirection: ModelSortDirection
    $filter: ModelVehicleFilterInput
    $limit: Int
    $nextToken: String
  ) {
    vehiclesByVin(
      group: $group
      vin: $vin
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        updatedAt
        group
      }
      nextToken
    }
  }
`;

export const dailyLogsByVehicleAndDate = /* GraphQL */ `
  query DailyLogsByVehicleAndDate(
    $vehicleId: String
    $date: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelDailyLogFilterInput
    $limit: Int
    $nextToken: String
  ) {
    dailyLogsByVehicleAndDate(
      vehicleId: $vehicleId
      date: $date
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        group
        type
        date
        notes
        vehicleId
        takenByUser {
          id
          firstName
          lastName
        }
        takenByAssociate {
          id
          firstName
          lastName
        }
        createdByUser {
          firstName
          lastName
        }
        documents {
          items {
            id
          }
        }
        vehicle {
          id
        }
        creationLinkSentByUser {
          id
          firstName
          lastName
        }
      }
      nextToken
    }
  }
`;

export const getDailyLog = /* GraphQL */ `
  query GetDailyLog($id: ID!) {
    getDailyLog(id: $id) {
      id
      group
      type
      date
      notes
      vehicleId
      creationLinkSentDateTime
      createdAt
      updatedAt
      documents {
        items {
          id
          group
          name
          key
          type
          uploadDate
          notes
          documentDate
          createdAt
          updatedAt
        }
        nextToken
      }
    }
  }
`;

export const vehiclesByGroupForTypeReport = /* GraphQL */ `
  query VehiclesByGroup(
    $group: String
    $name: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelVehicleFilterInput
    $limit: Int
    $nextToken: String
  ) {
    vehiclesByGroup(
      group: $group
      name: $name
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        group
        name
        status
        vehicleType {
          id
          group
          order
          option
        }
      }
      nextToken
    }
  }
`;

export const logsByMutatedRecordIdAndCreatedAt = /* GraphQL */ `
  query LogsByMutatedRecordIdAndCreatedAt(
    $mutatedRecordId: String
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelAuditLogFilterInput
    $limit: Int
    $nextToken: String
  ) {
    logsByMutatedRecordIdAndCreatedAt(
      mutatedRecordId: $mutatedRecordId
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        firstName
        lastName
        mutationName
        mutationNameText
        mutatedDataDiff
        createdAt
      }
      nextToken
    }
  }
`;

export const vehiclesByGroupMaintenance = /* GraphQL */ `
  query VehiclesByGroup(
    $group: String
    $name: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelVehicleFilterInput
    $limit: Int
    $nextToken: String
  ) {
    vehiclesByGroup(
      group: $group
      name: $name
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        group
        name
        status
        updatedAt
        maintenanceRecords{
          items {
            id
            vehicleHistoryType
            services
            location
            maintenanceStatus
            accidentDate
            accidentType
          }
          nextToken
        }
      }
      nextToken
    }
  }
`;