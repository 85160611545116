export const queriesData = [
    {
        key: 'associate',
        default: 'Active',
        values: ['OtherStatuses', 'Active']
    },
    {
        key: 'message',
        default: 'All',
        values: ['All', 'Unread']
    }
]

export const defaultAssociate = queriesData[0].default
export const defaultMessage = queriesData[1].default