export const staffsByGroup = /* GraphQL */ `
  query StaffsByGroup(
    $group: String
    $firstName: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelStaffFilterInput
    $limit: Int
    $nextToken: String
  ) {
    staffsByGroup(
      group: $group
      firstName: $firstName
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        firstName
        lastName
        alternateNames
      }
      nextToken
    }
  }
`;

export const staffsByGroupStatus = /* GraphQL */ `
  query StaffsByGroupStatus(
    $group: String
    $status: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelStaffFilterInput
    $limit: Int
    $nextToken: String
  ) {
    staffsByGroupStatus(
      group: $group
      status: $status
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        firstName
        lastName
        alternateNames
        transporterId
        keyFocusArea
        latestScorecard
        netradyneDriverId
        status
      }
      nextToken
    }
  }
`;

export const getTenant = /* GraphQL */ `
    query GetTenant($id: ID!) {
        getTenant(id: $id) {
            id
            group
            automatedCoachingSendTime
            timeZone
            companyName

            coachingDailyFicoIssue
            coachingDailyFicoCO
            coachingDailyFicoPR
            coachingDailyFicoKudo
            coachingDailyFicoThresholdIssue
            coachingDailyFicoThreshold
            coachingDailyFicoThresholdPR
            coachingDailyFicoThresholdKudo

            coachingDvcrsCO

            coachingTraningRemainingIssue
            coachingTraningRemainingCO

            coachingSeatbeltIssue
            coachingSeatbeltCO
            coachingSeatbeltPR
            coachingSeatbeltKudo
            coachingSeatbeltThresholdIssue
            coachingSeatbeltThreshold
            coachingSeatbeltThresholdPR
            coachingSeatbeltThresholdKudo

            coachingSseIssue
            coachingSseCO
            coachingSsePR
            coachingSseKudo
            coachingSseThresholdIssue
            coachingSseThreshold
            coachingSseThresholdPR
            coachingSseThresholdKudo

            coachingDriverStarPR
            coachingDriverStarKudo
            coachingDriverStarThresholdPR
            coachingDriverStarThresholdKudo

            coachingCameraObstructionCO
            coachingCameraObstructionIssue
            coachingCameraObstructionThresholdIssue
            coachingCameraObstructionThreshold
            coachingDriverDistractionCO
            coachingDriverDistractionIssue
            coachingDriverDistractionThresholdIssue
            coachingDriverDistractionThreshold
            coachingDriverDrowsinessCO
            coachingDriverDrowsinessIssue
            coachingDriverDrowsinessThresholdIssue
            coachingDriverDrowsinessThreshold
            coachingDriverInitiatedCO
            coachingDriverInitiatedIssue
            coachingDriverInitiatedThresholdIssue
            coachingDriverInitiatedThreshold
            coachingFollowingDistanceCO
            coachingFollowingDistanceIssue
            coachingFollowingDistanceThresholdIssue
            coachingFollowingDistanceThreshold
            coachingHardAccelerationCO
            coachingHardAccelerationIssue
            coachingHardAccelerationThresholdIssue
            coachingHardAccelerationThreshold
            coachingHardBrakingCO
            coachingHardBrakingIssue
            coachingHardBrakingThresholdIssue
            coachingHardBrakingThreshold
            coachingHardTurnCO
            coachingHardTurnIssue
            coachingHardTurnThresholdIssue
            coachingHardTurnThreshold
            coachingHighGCO
            coachingHighGIssue
            coachingHighGThresholdIssue
            coachingHighGThreshold
            coachingLowImpactCO
            coachingLowImpactIssue
            coachingLowImpactThresholdIssue
            coachingLowImpactThreshold
            coachingSeatbeltComplianceCO
            coachingSeatbeltComplianceIssue
            coachingSeatbeltComplianceThresholdIssue
            coachingSeatbeltComplianceThreshold
            coachingSignViolationsCO
            coachingSignViolationsIssue
            coachingSignViolationsThresholdIssue
            coachingSignViolationsThreshold
            coachingSpeedingViolationsCO
            coachingSpeedingViolationsIssue
            coachingSpeedingViolationsThresholdIssue
            coachingSpeedingViolationsThreshold
            coachingTrafficLightViolationCO
            coachingTrafficLightViolationIssue
            coachingTrafficLightViolationThresholdIssue
            coachingTrafficLightViolationThreshold
            coachingUTurnCO
            coachingUTurnIssue
            coachingUTurnThresholdIssue
            coachingUTurnThreshold
            coachingWeavingCO
            coachingWeavingIssue
            coachingWeavingThresholdIssue
            coachingWeavingThreshold
        }
    }
`;

export const getTenantSendTime = /* GraphQL */ `
  query GetTenant($id: ID!) {
    getTenant(id: $id) {
      automatedCoachingSendTime
      timeZone
    }
  }
`;

export const pendingMessagesByGroup = /* GraphQL */ `
  query PendingMessagesByGroup(
    $group: String
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelPendingMessageFilterInput
    $limit: Int
    $nextToken: String
  ) {
    pendingMessagesByGroup(
      group: $group
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        group
        createdAt
        channelType
        destinationNumber
        destinationEmail
        bodyTextCO
        bodyTextPR
        isRead
        numberOfCO
        numberOfPR
        updatedAt
        staff {
          id
          firstName
          lastName
          status
          phone
          email
          photo
          receiveTextMessages
          receiveEmailMessages
        }
      }
      nextToken
    }
  }
`;



// new below

export const getStaff = /* GraphQL */ `
  query GetStaff($id: ID!) {
    getStaff(id: $id) {
      id
      firstName
      lastName
      email
      receiveTextMessages
      receiveEmailMessages
      phone
      latestScorecard
    }
  }
`;


export const messagesByStaffId = /* GraphQL */ `
  query MessagesByStaffId(
    $staffId: ID
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelMessageFilterInput
    $limit: Int
    $nextToken: String
  ) {
    messagesByStaffId(
      staffId: $staffId
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        createdAt
        channelType
        bodyText
        smsStatus
        smsSendInformation
        isReadS
      }
      nextToken
    }
  }
`;


export const getStaffCoachingHistory = /* GraphQL */ `
  query GetStaff($id: ID!) {
    getStaff(id: $id) {
      id
      messages {
        items {
          id
          group
          createdAt
          channelType
          destinationNumber
          destinationEmail
          subject
          bodyText
          bodyHtml
          smsStatus
          smsSendInformation
          emailStatus
          emailSendInformation
          updatedAt
        }
        nextToken
      }
    }
  }
`;

export const messagesByStaffAndMessageType = /* GraphQL */ `
  query MessagesByStaffAndMessageType(
    $staffId: ID
    $messageTypeCreatedAt: ModelMessageByStaffAndMessageTypeCompositeKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelMessageFilterInput
    $limit: Int
    $nextToken: String
  ) {
    messagesByStaffAndMessageType(
      staffId: $staffId
      messageTypeCreatedAt: $messageTypeCreatedAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        group
        createdAt
        staffId
        messageType
        channelType
        destinationNumber
        destinationEmail
        subject
        bodyText
        bodyHtml
        smsStatus
        smsSendInformation
        emailStatus
        emailSendInformation
        updatedAt
        
      }
      nextToken
    }
  }
`;

export const infractionsByGroupAndDate = /* GraphQL */ `
  query InfractionsByGroupAndDate(
    $group: String
    $date: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelInfractionFilterInput
    $limit: Int
    $nextToken: String
  ) {
    infractionsByGroupAndDate(
      group: $group
      date: $date
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        group
        infractionType
        comment
        date
        time
        infractionTier
        infractionDescription
        counseling {
          id
          status
        }
        staff {
          firstName
          lastName
        }
      }
      nextToken
    }
  }
`;

export const kudosByGroupAndDate = /* GraphQL */ `
  query KudosByGroupAndDate(
    $group: String
    $date: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelKudoFilterInput
    $limit: Int
    $nextToken: String
  ) {
    kudosByGroupAndDate(
      group: $group
      date: $date
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        kudoType
        date
        notes
        updatedAt
        staff {
          id
          firstName
          lastName
        }
        type {
          id
          option
        }
        createdFrom
      }
      nextToken
    }
  }
`;

export const kudosByStaff = /* GraphQL */ `
  query KudosByStaff(
    $staffId: String
    $date: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelKudoFilterInput
    $limit: Int
    $nextToken: String
  ) {
    kudosByStaff(
      staffId: $staffId
      date: $date
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        kudoType
        notes
        date
        updatedAt
        staff {
          id
          firstName
          lastName
        }
        type {
          id
          option
        }
        createdFrom
      }
      nextToken
    }
  }
`;

export const dailyRostersByGroupAndNotesDate = /* GraphQL */ `
  query DailyRostersByGroupAndNotesDate(
    $group: String
    $notesDate: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelDailyRosterFilterInput
    $limit: Int
    $nextToken: String
  ) {
    dailyRostersByGroupAndNotesDate(
      group: $group
      notesDate: $notesDate
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        group
        notesDate
        route {
            items {
                staff {
                    id
                }
                replaceByRoute {
                  items {
                    staff {
                        id
                    } 
                    status {
                      id
                      option
                    }
                  } 
                }
                status {
                  id
                  option
                }
            }
        }
      }
      nextToken
    }
  }
`;

export const textractJobsByOwner = /* GraphQL */ `
  query TextractJobsByOwner(
    $owner: String
    $jobStatus: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelTextractJobFilterInput
    $limit: Int
    $nextToken: String
  ) {
    textractJobsByOwner(
      owner: $owner
      jobStatus: $jobStatus
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        group
        owner
        key
        week
        year
        jobId
        jobStatus
        type
        isProcessed
        isProcessedS
        results
      }
      nextToken
    }
  }
`;

export const textractJobsByJobId = /* GraphQL */ `
  query TextractJobsByJobId(
    $jobId: String
    $jobStatus: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelTextractJobFilterInput
    $limit: Int
    $nextToken: String
  ) {
    textractJobsByJobId(
      jobId: $jobId
      jobStatus: $jobStatus
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        group
        owner
        key
        fileName
        week
        year
        jobId
        jobStatus
        type
        isProcessed
        isProcessedS
        results
        customerSubType
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;

export const getTextractJob = /* GraphQL */ `
  query GetTextractJob($id: ID!) {
    getTextractJob(id: $id) {
      id
      group
      owner
      key
      fileName
      week
      year
      template
      jobId
      jobStatus
      type
      isProcessed
      isProcessedS
      results
      summaryTableResults
      timeZone
      customerSubType
      metadataCreationDate
      metadataModificationDate
      metadataYear
      metadataWeek
      scoreCardFailedValidators
      date
      tenant {
        id
        companyName
      }
      createdAt
      updatedAt
    }
  }
`;

export const coachingHistoryByimportId = /* GraphQL */ `
  query CoachingHistoryByimportId(
    $group: String
    $importId: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelCoachingHistoryFilterInput
    $limit: Int
    $nextToken: String
  ) {
    coachingHistoryByimportId(
      group: $group
      importId: $importId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        group
        type
        importId
        results
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;

export const coachingRecordsByimportId = /* GraphQL */ `
  query CoachingRecordsByimportId(
    $group: String
    $importId: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelCoachingRecordsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    coachingRecordsByimportId(
      group: $group
      importId: $importId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        group
        importId
        type
        results
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;