<template>
  <div class="relative">
    <div>
        <TableFixed
          v-if="positionTableFixed === 'top'"
          :type="data.type"
          @visibilityTableFixed="visibility"
          :data="dataTableFixed"
          :showHeader="true"
        />
        
        <TableDraggable
          v-loading="loading"
          :tableData="tableData"
          :customList="data"
          :listName="data.listName"
          :type="data.type"
          :listDisplay="data.listDisplay"
          @visibilityTableDraggable="visibility"
          :showHeader="showHeaderTable"
          :reassignedOptions="reassignedOptions"
          @removeOptions="removeOptions"
          @dataDialog="dataDialog"
          @cannotBeDisabled="cannotBeDisabled"
        />

        <el-row
          v-if="visibilityForm"
          class="newOption-row"
          :align="middle"
          :type="type"
          :gutter="20">
          <el-col :span="9" :offset="1">
            <el-input
              class="newOption-input"
              v-model="newOption"
              @input="validateNewOption"
              ref="refInput"
              placeholder="New Option"
              data-cy="new-option-input"
              @blur="handleInputConfirm">
            </el-input>
          </el-col>
          <el-col :span="2" :offset="1">
            <el-button
              type="primary"
              @click="addNewOption"
              data-cy="done-btn"
              :disabled="requiredLabel">
              Done
            </el-button>
          </el-col>
          <el-col :span="8" :offset="2">
            <span v-if="requiredLabel" class="text-red-600 text-xs font-bold">
              {{ alertLabel }}
            </span>
          </el-col>
        </el-row>

        <TableFixed
          v-if="positionTableFixed === 'bottom'"
          :type="data.type"
          @visibilityTableFixed="visibility"
          :data="dataTableFixed"/>

        <el-row
          v-if="showNewOptionBtn"
          class="option-row"
          :align="middle"
          :type="type"
          :gutter="20">
          <div class="flex flex-wrap ml-7 sm:ml-4">
            <el-button v-if="visibilityBottom" @click="newOptionButton" data-cy="new-option-btn"
            class="btn-custom-list-class">
              <i class="uil uil-plus self-center" small></i> New Option
            </el-button>
            
            <el-button v-if="visibilityBottom" @click="sortOptionsAlphabetically" class="btn-custom-list-class">
              <i class="uil uil-sort-amount-down" small></i> Sort Options Alphabetically
            </el-button>
          </div>
        </el-row>

        <div v-if="tableData.length">
          <p class="mt-2 ml-7 sm:ml-4 text-sm text-red-600" v-if="textCannotBeDisabled">The default option cannot be disabled.</p>
          <div class="mt-2 ml-7 sm:ml-4" v-for="defaultItem in defaultSectionList" 
          :key="defaultItem.option">
            <p class="mb-1 text-sm">{{ defaultItem.text }}</p>
            <el-select :multiple="defaultItem.isMultipleSelect"
              v-model="defaultItem.value"
              class="custom-list-select-width"
              clearable
              filterable @change="changeDefaultValue(defaultItem)">
              <el-option
                  v-for="item in optionsEnabled" :key="item.id"
                  :label="item.option"
                  :value="item.id">
                </el-option>
            </el-select>
          </div>
       

          <div class="mt-2 ml-7 sm:ml-4" v-for="hiddenItem in hiddenSectionList" 
          :key="hiddenItem.option">
            <p class="mb-1 text-sm">{{ hiddenItem.text }}</p>
            <el-select :multiple="hiddenItem.isMultipleSelect"
              v-model="hiddenItem.value"
              class="custom-list-select-width"
              filterable @change="changeHiddentValue(hiddenItem)">
              <el-option
                  v-for="item in optionsEnabled" :key="item.id"
                  :label="item.option"
                  :value="item.id">
                </el-option>
            </el-select>
          </div>
        </div>
      

        <ReassignDialog
          v-if="dialogVisible"
          :title="title"
          :rowData="rowData"
          :type="data.type"
          :dialogVisible="dialogVisible"
          :optionsToRemove="optionsToRemove"
          @dialogClose="dialogClose"
          @reAssignContentItems="reAssignContentItems"/>
    </div>
    <div class="premium-warning-container" v-if="showPremiumRequired">
        <div class="premium-warning-triangle">
          <p class="transform-text text-sm text-white">Requires Premium</p>
        </div>
    </div>
  </div>
</template>

<script>
import TableDraggable from "./TableDraggable";
import TableFixed from "./TableFixed";
import ReassignDialog from "./ReassignDialog";
import { mapGetters } from 'vuex';
import { sortByStringProp } from '@/utilities/sortHelpers';
import { PARKING_SPACE_CREATE_VEHICLE,
  VEHICLE_TYPE_CREATE_VEHICLE,
  AUTHORIZED_TO_DRIVE_CREATE_ASSOCIATE,
  COUNSELING_SEVERITY_CREATE_COUNSELING,
  INCIDENT_TYPE_CREATE_INCIDENT,
  ISSUE_TYPE_CREATE_ISSUE,
  KUDO_TYPE_CREATE_KUDO,
  COMPANY_CREATE_VEHICLE,
  ASSOCIATE_ROSTER_STATUS_CREATE_ROUTE,
  PHOTO_LOG_TYPE_CREATE_VEHICLE_PHOTO_LOG
} from '@/utilities/constants/defaultSectionsByCustomList';
import { 
  HIDDEN_ISSUE_TYPE_CREATE_ISSUE, 
  HIDDEN_KUDO_TYPE_CREATE_KUDO,
  HIDDEN_COMPANY_CREATE_VEHICLE,
  HIDDEN_ASSOCIATE_ROSTER_STATUS_CREATE_ROUTE,
  HIDDEN_PHOTO_LOG_TYPE_CREATE_VEHICLE_PHOTO_LOG
} from '@/utilities/constants/hiddenSecctionForCustomList';

export default {
  name:'DrawerContent',
  components: {
    TableDraggable,
    TableFixed,
    ReassignDialog,
  },
  props: {
    data: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      type: 'flex',
      middle: 'middle',
      dialogVisible: false,
      newOption: "",
      visibilityForm: false,
      visibilityBottom: true,
      requiredLabel: false,
      alertLabel: "",
      optionsToRemove: [],
      rowData: {},
      optionsUpdate: {},
      optionsToReassign: {},
      loading: false,
      sortOrder: 'descending',
      textCannotBeDisabled: false,
      defaultSectionList: [
        {
          option: 1,
          type: 'parking-space',
          text: `Default for "${this.data.listName}" when creating a Vehicle`,
          isMultipleSelect: false,
          sectionName: PARKING_SPACE_CREATE_VEHICLE,
          value: null
        },
        {
          option: 2,
          type: 'vehicle-type',
          text: `Default for "${this.data.listName}" when creating a Vehicle`,
          isMultipleSelect: false,
          sectionName: VEHICLE_TYPE_CREATE_VEHICLE,
          value: null
        },
        {
          option: 3,
          type: 'vehicle-type',
          text: `Defaults for "Authorized to Drive" when creating an Associate`,
          isMultipleSelect: true,
          sectionName: AUTHORIZED_TO_DRIVE_CREATE_ASSOCIATE,
          value: []
        },
        {
          option: 4,
          type: 'counseling-severity',
          text: `Default for "${this.data.listName}" when creating a Counseling`,
          isMultipleSelect: false,
          sectionName: COUNSELING_SEVERITY_CREATE_COUNSELING,
          value: null
        },
        {
          option: 5,
          type: 'incident-type',
          text: `Default for "${this.data.listName}" when creating a Incident`,
          isMultipleSelect: false,
          sectionName: INCIDENT_TYPE_CREATE_INCIDENT,
          value: null
        },
        {
          option: 6,
          type: 'issue-type',
          text: `Default for "${this.data.listName}" when creating an Associate Issue`,
          isMultipleSelect: false,
          sectionName: ISSUE_TYPE_CREATE_ISSUE,
          value: null
        },
        {
          option: 7,
          type: 'kudo-type',
          text: `Default for "${this.data.listName}" when creating an Associate Kudo`,
          isMultipleSelect: false,
          sectionName: KUDO_TYPE_CREATE_KUDO,
          value: null
        },
        {
          option: 10,
          type: 'vehicle-company',
          text: `Default "${this.data.listName}" when creating a new Vehicle`,
          isMultipleSelect: false,
          sectionName: COMPANY_CREATE_VEHICLE,
          value: null
        },
        {
          option: 12,
          type: 'roster-status',
          text: `Default for "${this.data.listName}" when rostering or editing an Associate or Helper/Trainer on the Daily Roster`,
          isMultipleSelect: false,
          sectionName: ASSOCIATE_ROSTER_STATUS_CREATE_ROUTE,
          value: null
        },
        {
          option: 14,
          type: 'photo-log',
          text: `Default for "Required Photo" when creating a new Vehicle Photo Log Associate Link`,
          isMultipleSelect: true,
          sectionName: PHOTO_LOG_TYPE_CREATE_VEHICLE_PHOTO_LOG,
          value: null
        }
      ],
      hiddenSectionList: [
        {
          option: 8,
          type: 'issue-type',
          text: `Hidden options for "${this.data.listName}" when creating an Associate Issue`,
          isMultipleSelect: true,
          sectionName: HIDDEN_ISSUE_TYPE_CREATE_ISSUE,
          value: null
        },
        {
          option: 9,
          type: 'kudo-type',
          text: `Hidden options for "${this.data.listName}" when creating an Associate Kudo`,
          isMultipleSelect: true,
          sectionName: HIDDEN_KUDO_TYPE_CREATE_KUDO,
          value: null
        },
        {
          option: 11,
          type: 'vehicle-company',
          text: `Hidden options for "${this.data.listName}" when creating a new Vehicle`,
          isMultipleSelect: true,
          sectionName: HIDDEN_COMPANY_CREATE_VEHICLE,
          value: null
        },
        {
          option: 13,
          type: 'roster-status',
          text: `Hidden options for "${this.data.listName}" when rostering or editing an Associate or Helper/Trainer on the Daily Roster`,
          isMultipleSelect: true,
          sectionName: HIDDEN_ASSOCIATE_ROSTER_STATUS_CREATE_ROUTE,
          value: null
        },
        {
          option: 15,
          type: 'photo-log',
          text: `Hidden options for "Required Photo" when creating a new Vehicle Photo Log Associate Link`,
          isMultipleSelect: true,
          sectionName: HIDDEN_PHOTO_LOG_TYPE_CREATE_VEHICLE_PHOTO_LOG,
          value: null
        },
      ],
      reassignedOptions: []
    };
  },

  mounted(){
    const forbiddenTypesToSetDefaultsTo = {
      'kudo-type': true,
      'issue-type': true,
      'parking-space': true,
    };
    this.defaultSectionList = this.defaultSectionList.filter(item => !forbiddenTypesToSetDefaultsTo[item.type]);
    this.defaultSectionList = this.defaultSectionList.filter(item => {
      if(item.isMultipleSelect){
        const filteredDefaultSectionList = this.tableData.filter(tableDataItem => tableDataItem.isDefaultForSections?.includes(item.sectionName))
        item.value = filteredDefaultSectionList.map(i=> i.id)
      }else{
        item.value = this.tableData.find(tableDataItem => tableDataItem.isDefaultForSections?.includes(item.sectionName))?.id
      }
      return item.type === this.data.type
    })


    this.hiddenSectionList = this.hiddenSectionList.filter(item => {
      const filteredDefaultSectionList = this.tableData.filter(tableDataItem => tableDataItem.isHiddenForSections?.includes(item.sectionName))
      item.value = filteredDefaultSectionList.map(i=> i.id)
      return item.type === this.data.type
    })

  },

  computed: {
    ...mapGetters([
      'existsInStore',
      'parkingSpaceList',
      'changeStatusOptionCustomList',
      'hasVehicleManagement'
    ]),

    title() {
      return `Re-Assign '${this.data.listName}' Dropdown Option`;
    },

    showHeaderTable() {
      return this.data.tableDataFixedPosition == "top" ? false : true;
    },

    tableData() {
      return this.data ? this.changeHash(this.data.options) : {};
    },

    optionsEnabled(){
      const options = this.tableData?.filter(option => !this.optionsToRemove.map(optn => optn.id).includes(option.id))
      return this.customListEnabled(options)
    },

    dataTableFixed(){
      let result = [
        {
          option: `Routes Without a ${this.data.listName}`,
          usedFor: 0,
          daysCount: 0,
          canBeEdited: true,
          canBeDeleted: false,
        }
      ]
      return (this.data.type == "dock-door" || this.data.type == "route-type") ? result : [];
    },
    positionTableFixed() {
      let position
      if (this.data.type == "dock-door" || this.data.type == "route-type") {
        position = "bottom"
      } 
      return position 
    },
    showNewOptionBtn() {
      if (this.data.type === 'route-status') {
        return false
      }
      return true
    },
    showPremiumRequired(){
      return this.data.type === 'vehicle-type' && !this.hasVehicleManagement
    },
  },

  methods: {
    validateNewOption() {
      const duplicatedOptionName = this.tableData.some(item => item.option.toUpperCase() == this.newOption.toUpperCase());

      if (this.newOption.trim().length == 0) {
        this.requiredLabel = true
      } else if(duplicatedOptionName) {
        this.requiredLabel = true;
        this.alertLabel = "The option name is already used."
      } else if (this.newOption.trim().length > 30) {
        this.requiredLabel = true;
        this.alertLabel = "The option name can have a maximum of 30 characters."
      } else if(this.newOption.includes(';')){
        this.requiredLabel = true;
        this.alertLabel = "The option name must not contain semicolon"
      } else{
        this.requiredLabel = false;
        this.alertLabel = "";
      }
    },
    visibility() {
      this.dialogVisible = true;
    },
    dialogClose() {
      this.dialogVisible = false;
    },

    reAssignContentItems(option) {
      try {
        this.loading = true;
        if (this.data.type === 'vehicle-type') {
          this.reassignVehicleType(option)  
        } else if (this.data.type === 'incident-type') {
          this.reassignIncidentType(option)  
        } else if (this.data.type === 'parking-space') {
          this.reassignParkingSpace(option)  
        } else if (this.data.type === 'counseling-severity') {
          this.reassignCounselingSeverity(option)  
        } else if (this.data.type === 'counseling-type') {
          this.reassignCounselingType(option)  
        } else if (this.data.type === 'issue-type') {
          this.reassignIssueType(option)  
        } else if (this.data.type === 'kudo-type') {
          this.reassignKudoType(option)  
        } else if (this.data.type === 'vehicle-company') {
          this.reassignVehicleCompany(option)  
        } else if (this.data.type === 'roster-status') {
          this.reassignRosterStatus(option)  
        } else if(this.data.type === 'photo-log') {
          this.reassignPhotoLog(option)
        }
        this.reassignedOptions.push(option.oldValue)
      } catch (error) {
        this.displayUserError(error)
      } finally {
        this.$emit("setOptionsToReassign", this.optionsToReassign);
        this.loading = false;
        this.dialogVisible = false
      }   
    },

    reassignVehicleType(options) {

      // Current option data
      const currentOption = this.tableData.find(item => item.id === options.oldValue)

      // List of items by field
      const currentVehicleItems = currentOption.vehicles?.items
      const currentAssociateItems = currentOption.associates?.items

      // Reassignment
      this.reAssignContent(options, currentVehicleItems, 'vehicles', 'vehicleVehicleTypeId')
      this.reAssignContent(options, currentAssociateItems, 'associates', 'optionsCustomListsStaffOptionCustomListId')

    },

    reassignIncidentType(options) {

      // Current option data
      const currentOption = this.tableData.find(item => item.id === options.oldValue)

      // List of items by field
      const currentAccidentsItems = currentOption.accidents?.items

      // Reassignment
      this.reAssignContent(options, currentAccidentsItems, 'accidents', 'accidentOptionCustomListId')

    },

    reassignParkingSpace(options) {
      // Current option data
      const currentOption = this.tableData.find(item => item.id === options.oldValue)

      // List of items by field
      const currentVehicleItems = currentOption.parkingSpace?.items
      const currentRouteItems = currentOption.routeParkingSpace?.items
      const currentReplaceByRouteItems = currentOption.replaceByRouteParkingSpace?.items
      // Daily Roster v3
      // const currentRosteredVehicleItems = currentOption.rosteredVehicleParkingSpace?.items

      // Reassignment
      this.reAssignContent(options, currentVehicleItems, 'parkingSpace', 'vehicleParkingSpaceId')
      this.reAssignContent(options, currentRouteItems, 'routeParkingSpace', 'routeParkingSpaceId')
      this.reAssignContent(options, currentReplaceByRouteItems, 'replaceByRouteParkingSpace', 'replaceByRouteParkingSpaceId')
      // Daily Roster v3
      // this.reAssignContent(options, currentRosteredVehicleItems, 'rosteredVehicleParkingSpace')
    },

    reassignCounselingSeverity(options) {
      // Current option data
      const currentOption = this.tableData.find(item => item.id === options.oldValue)

      // List of items by field
      const currentCounselingsItems = currentOption.counselings?.items

      // Reassignment
      this.reAssignContent(options, currentCounselingsItems, 'counselings', 'counselingSeverityId')
    },

    reassignCounselingType(options) {
      // Current option data
      const currentOption = this.tableData.find(item => item.id === options.oldValue)

      // List of items by field
      const currentCounselingsByTypeItems = currentOption.counselingsByType?.items

      // Reassignment
      this.reAssignContent(options, currentCounselingsByTypeItems, 'counselingsByType', 'counselingTypeId')
    },

    reassignIssueType(options) {
      // Current option data
      const currentOption = this.tableData.find(item => item.id === options.oldValue)

      // List of items by field
      const currentIssuesItems = currentOption.issues?.items

      // Reassignment
      this.reAssignContent(options, currentIssuesItems, 'issues', 'infractionTypeId')
    },

    reassignKudoType(options) {
      // Current option data
      const currentOption = this.tableData.find(item => item.id === options.oldValue)

      // List of items by field
      const currentKudosItems = currentOption.kudos?.items

      // Reassignment
      this.reAssignContent(options, currentKudosItems, 'kudos', 'kudoTypeId')
    },

    reassignVehicleCompany(options) {
      // Current option data
      const currentOption = this.tableData.find(item => item.id === options.oldValue)

      // List of items by field
      const currentCompaniesItems = currentOption.companies?.items

      // Reassignment
      this.reAssignContent(options, currentCompaniesItems, 'companies', 'vehicleCompanyId')
    },

    reassignRosterStatus(options) {
      // Current option data
      const currentOption = this.tableData.find(item => item.id === options.oldValue)

      // List of items by field
      const currentRouteItems = currentOption.routes?.items
      const currentRouteHelperStatusItems = currentOption.routeHelperStatus?.items
      const currentReplaceByRoutesItems = currentOption.replaceByRoutes?.items

      // Reassignment
      this.reAssignContent(options, currentRouteItems, 'routes', 'routeStatusId')
      this.reAssignContent(options, currentRouteHelperStatusItems, 'routeHelperStatus', 'routeHelperStatusId')
      this.reAssignContent(options, currentReplaceByRoutesItems, 'replaceByRoutes', 'replaceByRouteStatusId')
    },

    reassignPhotoLog(options) {
      // Current option data
      const currentOption = this.tableData.find(item => item.id === options.oldValue)

      // List of items by field
      const currentDocumentesItems = currentOption.documents?.items

      // Reassignment
      this.reAssignContent(options, currentDocumentesItems, 'documents', 'documentOptionCustomListsId')
    },

    reAssignContent(options, list, field, fieldId) {
      const { oldValue, newValue } = options

      this.tableData.find(item => item.id == newValue)[field].items.push(...list)
      this.tableData.find(item => item.id == oldValue)[field].items = []

      if(!list.length) return

      list.forEach(item => {
        const input = {
          id: item.id,
          [`${fieldId}`]: newValue
        }
        if(!this.optionsToReassign[field]) return this.optionsToReassign[field] = [input]

        const existOption = this.optionsToReassign[field].find(option => option.id === item.id)

        if(!existOption) return this.optionsToReassign[field].push(input)

        this.optionsToReassign[field].map(option => {
          if(option.id == item.id) option[fieldId] = newValue
        })
      })
    },

    addNewOption() {
      try {
        // Add Temp Custom List Option
        const option = this.existsInStore(this.newOption, this.data.type, true)

        if (option) {
          this.data.options.push(option)
        }

        this.visibilityForm = false;
        this.visibilityBottom = true;
        this.newOption = "";
        this.alertLabel = ""
        this.requiredLabel = false;
      } catch (error) {
        this.requiredLabel = true;
        this.alertLabel = error;
      }
    },

    reloadTable() {
      this.$emit("reloadTable");
    },

    newOptionButton() {
      this.visibilityForm = true;
      this.visibilityBottom = false;
      this.$nextTick(_ => {
        this.$refs.refInput.$refs.input.focus();
      });
    },

    changeHash(obj) {
      return obj.map(item => {
        return {
          ...item,
        };
      });
    },
    removeOptions(e) {
      this.optionsToRemove = e;
    },
    dataDialog(option) {
      this.rowData = option;
    },
    async handleInputConfirm() {
      if (this.visibilityForm && !this.newOption.length) {
        this.visibilityForm = false;
        this.visibilityBottom = true;
        this.alertLabel = ""
        this.requiredLabel = false
        this.newOption = "";
      }
    },

    sortOptionsAlphabetically(){
      this.sortOrder = this.sortOrder === 'descending' ? 'ascending' : 'descending';
      this.tableData.sort(sortByStringProp('option', this.sortOrder));
    },

    changeDefaultValue(defaultItem){ 
      this.changeValueByField(defaultItem, 'isDefaultForSections')
    },

    changeHiddentValue(hiddenItem){
      this.changeValueByField(hiddenItem, 'isHiddenForSections')
    },

    changeValueByField(item, field){
      for(let i = 0; i < this.tableData.length; i++){
        //multi select or single select
        const valueToCompare = item.isMultipleSelect ? item.value.includes(this.tableData[i].id) : this.tableData[i].id === item.value;

        if(valueToCompare){
          if(!this.tableData[i][field]){
            this.tableData[i][field] = [];
          }
          if(!this.tableData[i][field].includes(item.sectionName)) {
            this.tableData[i][field].push(item.sectionName);
          }
        }else{
          if(this.tableData[i][field]){
            const index = this.tableData[i][field].indexOf(item.sectionName);
            if(index !== -1){
              this.tableData[i][field].splice(index, 1);
            }
          }
        }
      }
    },

    async cannotBeDisabled(value, row){
      this.changeStatusOptionCustomList(row, this.data.type)
      this.textCannotBeDisabled = !!value;
      await this.sleepTimeOut(4000);
      if(!!this.textCannotBeDisabled){
        this.textCannotBeDisabled = !this.textCannotBeDisabled;
      }
    },


  },
};
</script>
<style>
.newOption-row {
  @apply mt-4 pb-4 mb-0;
  max-width: auto;
  width: 100%;
  border-bottom: solid 1px #ebeef5 !important
}
.option-row {
  @apply mt-4;
  max-width: auto;
  width: 100%;
}
.newOption-input {
  width: 100%;
}

.btn-custom-list-class {
  @apply ml-0 sm:ml-2 mt-2 sm:mt-0 !important;
}
.custom-list-select-width {
  width: 100% !important;
}
.custom-list-select-width .el-input {
  width: 75% !important;
}

</style>