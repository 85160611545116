function isCodificationGSM7(message='') {
  const regex = /^[0-9A-Za-z\r\n !"#\$%&'â€™()*+,\-.\/:;<=>?@\[\\\]^_{}|~]+$/;
  return regex.test(message);
}
 
function calculateBytesGSM7(message='') {
  const specialCharacters = {
    '\u000C': true,  //line break
    '\u005E': true,  //Circumflex
    '\u007B': true,  // "{"
    '\u007D': true,  // "{"
    '\u005C': true,  // "\"
    '\u005B': true,  // "["
    '\u007E': true,  // Â´
    '\u005D': true,  // "]"
    '\u007C': true,  // |
    '\u20AC': true   // Euro
  };
 
  let bytes = 0;
  for (const char of message) {
    bytes += specialCharacters[char] ? 2 : 1;
  }
  return bytes;
}

function calculateBytesUTF16(message = '') {
  let totalBytes = 0;

  for (const char of message) {
    const codePoint = char.codePointAt(0);

    if (codePoint >= 0x10000 && codePoint <= 0x10FFFF) {
      // Characters that require more than 2 bytes in UTF-16
      totalBytes += 4;
    } else {
      totalBytes += 2;
    }
  }

  return totalBytes;
}
 
export function calculateBytes(message){
  if(isCodificationGSM7(message)){
    return calculateBytesGSM7(message);
  }
  const totalBytes = calculateBytesUTF16(message);
  return totalBytes;
}