<template>
  <div
    class="flex flex-col md:flex-row justify-center md:justify-between custom-pagination"
  >
    <span class="text-center md:text-right"
      >Total results: {{ totalRecords }}</span
    >
    <el-pagination
      class="text-center md:text-right pagination-container"
      background
      :hide-on-single-page="false"
      :current-page.sync="currentPage"
      @size-change="handleSizeChange"
      @current-change="pageChange"
      :page-sizes="[25, 50, 100]"
      :page-size="pageSize"
      :pager-count="5"
      layout="sizes, prev, pager, next"
      :total="totalRecords"
    >
    </el-pagination>
  </div>
</template>

<script>
import {PAGE_SIZE_DEFAULT} from './Constants'
export default {
  name: "CustomPagination",
  props: {
    records: {
      type: Number,
      required: false,
      default: 0,
    },
  },
  data() {
    return {
      currentPage: 1,
      pageSize: PAGE_SIZE_DEFAULT,
    };
  },
  computed: {
    totalRecords() {
      return this.records;
    },
  },
  methods: {
    pageChange(currentPage) {
      this.currentPage = currentPage;
      this.indexPagination();
    },

    handleSizeChange(size) {
      this.pageSize = size;
      this.currentPage = 1;
      this.indexPagination();
    },
    indexPagination() {
      const startIndex = (this.currentPage - 1) * this.pageSize;
      const endIndex = startIndex + this.pageSize;
      this.$emit("index-pagination", { startIndex, endIndex });
    },
  },
};
</script>
