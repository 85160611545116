<template>
  <div>

    <!--HEADER-->
    <div class="flex justify-between">
      <div>
        <div class="text-gray-600 text-lg font-bold">MONTHLY INVOICES & ACTIVE {{$t('label.associates')}}</div>
      </div>
    </div>  

    <div class="flex justify-end" v-if="showChargeButton && $store.getters.isSystemAdmin">
        <el-button @click="chargeFailedInvoices">Charge Outstanding Invoices</el-button>
    </div>

    <!--INVOICE TABLE-->
    <el-table
      ref="table"
      empty-text="No Invoices"
      row-key="id"
      :default-sort = "{prop: 'month', order: 'descending'}"
      lazy
      style="width: 100%"
      :load="loadInvoiceLineItems"
      :tree-props="{children: 'children', hasChildren: 'hasChildren'}"
      :data="tableData">
        
        <el-table-column
          label="Month"
          sortable
          width="110"
          :sort-method="dateSort"
          prop="month">
          <template  slot-scope="scope">
            <span class=" inline-block">{{ scope.row.year }} {{scope.row.month | month}}</span>
          </template>
        </el-table-column>

        <el-table-column
          label="Date"
          width="80"
          align="center"
          prop="date">
            <template  slot-scope="scope">
              <div>{{ scope.row.date | dateDay }}</div>
            </template>
        </el-table-column>

        <el-table-column
          :label="`Active ${$t('label.associates')}`"
          width="140"
          align="center"
          prop="activeStaff">
            <template  slot-scope="scope">
              <div>{{ scope.row.activeStaff }}</div>
            </template>
        </el-table-column>

        <el-table-column
          label="Standard"
          width="90"
          align="center"
          prop="standardChargeExt">
            <template  slot-scope="scope">
              <div :class="{'placeholder': !scope.row.standardCostExt }">{{ scope.row.standardCostExt | currencyUS }}</div>
            </template>
        </el-table-column>

        <el-table-column
          label="Bundle"
          width="90"
          align="center"
          prop="bundleChargeExt">
            <template  slot-scope="scope">
              <div :class="{'placeholder': !scope.row.bundleCostExt }">{{ scope.row.bundleCostExt | currencyUS }}</div>
            </template>
        </el-table-column>

        <el-table-column
          label="Performance"
          width="105"
          align="center"
          prop="performanceChargeExt">
            <template  slot-scope="scope">
              <div :class="{'placeholder': !scope.row.performanceCostExt }">{{ scope.row.performanceCostExt | currencyUS }}</div>
            </template>
        </el-table-column>

        <el-table-column
          label="Rostering"
          width="85"
          align="center"
          prop="rosteringChargeExt">
            <template  slot-scope="scope">
              <div :class="{'placeholder': !scope.row.rosteringCostExt }">{{ scope.row.rosteringCostExt | currencyUS }}</div>
            </template>
        </el-table-column>

        <el-table-column
          :label="$t('label.associates')"
          width="90"
          align="center"
          prop="staffChargeExt">
            <template  slot-scope="scope">
              <div :class="{'placeholder': !scope.row.staffCostExt }">{{ scope.row.staffCostExt | currencyUS }}</div>
            </template>
        </el-table-column>

        <el-table-column
          label="Vehicles"
          width="80"
          align="center"
          prop="vehiclesChargeExt">
            <template  slot-scope="scope">
              <div :class="{'placeholder': !scope.row.vehiclesCostExt }">{{ scope.row.vehiclesCostExt | currencyUS }}</div>
            </template>
        </el-table-column>

        <el-table-column
          width="90"
          align="center"
          prop="isTrial">
            <template  slot-scope="scope">
              <div v-if="scope.row.status" class=" font-bold text-center">{{scope.row.status}}</div>
              <div v-if="scope.row.isTrial" class="text-brand text-center">Trial</div>
            </template>
        </el-table-column>

        <el-table-column
          label="Total"
          align="right"
          prop="total">
            <template  slot-scope="scope">
              <div class="font-bold">{{ scope.row.invoiceTotal | currencyUS }}</div>
            </template>
        </el-table-column>


        <!-- <el-table-column
          label="Total Daily Charge"
          prop="totalMonthlyCharge">
        </el-table-column> -->

        <el-table-column align="center" width="60" v-if="$store.getters.isSystemAdmin">
				<template slot-scope="scope" v-if="scope.row.status=='Payment Error'"> 
					<el-dropdown class="mt-1 custom-relative" @command="writeOffInvoice" :trigger="isMobileDevice() ? 'click' : 'hover'">
						<i class="context-menu uil uil-ellipsis-h"></i>
						<el-dropdown-menu slot="dropdown" class="custom-absolute">
							<el-dropdown-item :command="{ row: scope.row }"> Write Off </el-dropdown-item>
						</el-dropdown-menu>
					</el-dropdown>
				</template>
			</el-table-column>

    </el-table>

    <!-- <p class="mt-4">{{ users.length }} Total Users</p> -->

  
  </div>

</template>

<script>
import { getInvoice} from '../queries'
import { getShortMonthName } from '../../../../utilities/getDate'

import { Auth, API } from 'aws-amplify';

  export default {
    name: 'ActiveStaff',
    props: ['tenantId', 'group', 'invoices'],
    data() {
      return {
        loading: false
      }
    },

    computed: {
      tableData(){
        return this.invoices.map(invoice =>{
          invoice.hasChildren = true
          return invoice
        })
      },
      showChargeButton(){
          return this.invoices.some(invoice =>{
              return invoice.status == 'Payment Error' || invoice.status == 'Finalized' 
          })
      }
    },

    methods: {
        async chargeFailedInvoices(){
            console.log(this.tenantId)
            this.$confirm('This will charge all outstanding invoices with statuses "Payment Error" and "Finalized". Continue?', 'Warning', {
                confirmButtonText: 'Charge Invoices',
                cancelButtonText: 'Cancel',
                type: 'warning'
            }).then( async () => {
                console.log(this.tenantId)       
                let apiName = 'stripeSetup'
                let path = '/payment-intent/' + this.tenantId
                let post = {body: {}}
                var result = await API.post(apiName, path, post)
                let allInvoicesPaid = result.allInvoicesPaid
                // check if all invoices were charged successfully
                if(allInvoicesPaid){
                    location.reload()
                    this.displayUserNotification({
                        title: 'Success',
                        type: "success",
                        message: 'All unpaid invoices charged successfully.',
                        duration: 5000,
                    })
                }
                else{
                    // display error that not all invoices were charged successfully
                    location.reload()
                    this.displayUserError('All outstanding invoices were not charged successfully.')
                    
                }
            }).catch((e) => {
                console.error(e)
                console.error("canceled charge")         
            });
        }, 

        async writeOffInvoice( command ) {
          console.info(command)
          if(command !== undefined)
          {
            const r = command.row
            console.log(this.tenantId)
            this.$confirm('Are you sure you want to fully write off the $'+ (Math.round(r.invoiceTotal * (10**2))/(10**2)) + ' invoice for '+ getShortMonthName(r.month) + ' ' + r.year  +' with a "Payment Error" status?', 'Warning', {
                confirmButtonText: 'Write-Off',
                cancelButtonText: 'Cancel',
                type: 'warning'
            }).then( async () => {
                console.log(this.tenantId)       
                let apiName = 'invoice'
                let path = '/write-off'
                let post = {body: {tenantId:this.tenantId, invoiceId: r.id}}
                var result = await API.post(apiName, path, post)
                // check if invoice successfully written off
                if(result.status=='Written Off'){
                    location.reload()
                    this.displayUserNotification({
                        title: 'Success',
                        type: "success",
                        message: 'Invoice successfully written off',
                        duration: 5000,
                    })
                }
                else{
                    // display error that invoice is not successfully written off
                    location.reload()
                    this.displayUserError('Failed to write off invoice')
                    console.log(result.e)                    
                }
            }).catch((e) => {
                console.error(e)
                console.error("Canceled Write-Off")         
            });
          }
        },
      dateSort(a,b){
        return new Date(a.createdAt) - new Date(b.createdAt);
      },
      async loadInvoiceLineItems(tree, treeNode, resolve){
        var input = {
          id: tree.id
        }
        const invoice = await this.api(getInvoice, input)
        var invoiceLineItems = invoice.data.getInvoice.invoiceLineItems.items
        if(invoiceLineItems.length){
          invoiceLineItems = invoiceLineItems.map(item =>{
            var total = item.standardCostExt +
            item.bundleCostExt +
            item.performanceCostExt +
            item.rosteringCostExt +
            item.staffCostExt +
            item.vehiclesCostExt +
            item.messagingCostExt;
            item.invoiceTotal = total
            return item
          })

          // var invoiceTotal = invoiceLineItems.reduce((accumulator, item) => ({total: accumulator.total + item.total})) 
          // tree.total = invoiceTotal.total

          // sort line items by date
          invoiceLineItems.sort(function (a,b){
            return new Date(a.date) - new Date(b.date);
          })
        }
        else{
          invoiceLineItems = [
            {date: 'No Data', id: tree.year + tree.month}
          ]
        }
      
        resolve(invoiceLineItems)
      }

    }
  }
</script>