import { mixinMethods } from '@/main'
import { getTenant, infractionsByGroupAndDate, kudosByGroupAndDate, staffsByGroupStatus } from './queries'
import store from '@/store/index'
import moment from 'moment-timezone'
import EventBus from '@/eventBus'
import { parseType } from '@/utilities/parseType'

const sampleData = [
    { firstName: 'Rafael', lastName: 'Aparicio', issues: [1,], kudos: [1,1,1,1,1,1,1,1,1,1,1,1,1,1,1], heraScore: 314, heraScorePrev: 0, heraRank: 1,},
    { firstName: 'Juan', lastName: 'Gonzalez', issues: [1,1,1], kudos: [1,11,1,1,1,1], heraScore: 270, heraScorePrev: 0, heraRank: 2,},
    { firstName: 'Dajuan', lastName: 'Mcgrill', issues: [1,1], kudos: [1,1,1,1,,1], heraScore: 220, heraScorePrev: 0, heraRank: 3,},
    { firstName: 'Cinque', lastName: 'Vitela', issues: [1,1,1,1,1,1], kudos: [1,1,1,1,1,1,1], heraScore: 140, heraScorePrev: 0, heraRank: 4,},
    { firstName: 'Amand', lastName: 'Mcfrazier', issues: [1,1,1], kudos: [1,1,1,], heraScore: 75, heraScorePrev: 0, heraRank: 5,},
    { firstName: 'Omar', lastName: 'Arredondo', issues: [1,1,1,1,1,1], kudos: [1,1,1,1,1,1,1,1], heraScore:44, heraScorePrev: 0, heraRank: 6,},
    { firstName: 'Kevvon', lastName: 'Vargas', issues: [1,1,,1,1,1], kudos: [1,1,1,1], heraScore: 10, heraScorePrev: 0, heraRank: 7,},
    { firstName: 'Diana', lastName: 'Young', issues: [1,1,1,1,1,1,1,], kudos: [1,1,1], heraScore: 0, heraScorePrev: 0, heraRank: 8,},
    { firstName: 'Bianca', lastName: 'Gidanian', issues: [1,1,1,1,1,1,1,1,1,1], kudos: [1], heraScore: -34, heraScorePrev: 0, heraRank: 9,},
    { firstName: 'Anneliese', lastName: 'Gomez', issues: [1,1,1,1,1,1,1,1,1,1,1,1,], kudos: [1], heraScore: -75, heraScorePrev: 0, heraRank: 10,}
]

const settingsMap = {
  ScorecardFICOScore: "Fico",
  ScorecardDCR: "Dcr",
  ScorecardDAR: "Dar",
  ScorecardDSB: "Dsb",
  ScorecardDNR: "Dnr",
  ScorecardSWCPOD: "SwcPod",
  ScorecardSWCCC: "SwcCc",
  ScorecardSWCSC: "SwcSc",
  ScorecardSWCAD: "SwcAd",
  ScorecardSeatbeltOffRate: "SeatbeltOff",
  ScorecardSpeedingEventRate: "SpeedingEvent",
  ScorecardDistractionsRate: "DistractionsRate",
  ScorecardFollowingDistanceRate: "FollowingDistanceRate",
  ScorecardSignSignalViolationRate: "SignSignalViolationsRate",
  ScorecardConsecutiveTier: "ConsecutiveTier",
  ScorecardOverallTier: "OverallTier",
  ScorecardHarshBrakingRate: 'HarshBrakingRate',
  ScorecardHarshCorneringRate: 'HarshCorneringRate',
  CXPositiveFeedback: "PositiveFeedback",
  CXCdfScore: "CdfScore",
  CXDaTier: "DaTier",
  MentorDailyFICO: "DailyFico",
  MentorSeatbeltRate: "Seatbelt",
  MentorSse: "Sse",
  MentorTrainingsRemaining: "TraningRemaining",
  NetradyneDriverStar: "DriverStar",
  NetradyneCameraObstruction: "CameraObstruction",
  NetradyneDriverDistraction: "DriverDistraction",
  NetradyneDriverDrowsiness: "DriverDrowsiness",
  NetradyneDriverInitiated: "DriverInitiated",
  NetradyneFaceMaskCompliance: "FaceMaskCompliance",
  NetradyneFollowingDistance: "FollowingDistance",
  NetradyneHardAcceleration: "HardAcceleration",
  NetradyneHardBraking: "HardBraking",
  NetradyneHardTurn: "HardTurn",
  NetradyneHighG: "HighG",
  NetradyneLowImpact: "LowImpact",
  NetradyneSeatbeltCompliance: "SeatbeltCompliance",
  NetradyneSignViolations: "SignViolations",
  NetradyneSpeedingViolations: "SpeedingViolations",
  NetradyneTrafficLightViolation: "TrafficLightViolation",
  NetradyneUturn: "UTurn",
  NetradyneWeaving: "Weaving",
  EOCDailyComplianceRate: "DailyComplianceRate",
  CDFDPMO: 'CdfDpmo',
};


async function getTenantData(){
    const tenantId = { 
        id: store.state.userInfo.tenant.id
    }
    let tenant = {}
    try{
        const { data } = await mixinMethods.api(getTenant, tenantId)
        tenant = data.getTenant
    } catch(error){
        console.error(error)
    } finally {
        return tenant
    }
}

async function getListData(queryName, parameters){
    let list = []
    let input = {
        ...parameters
    }
  
    const querieSelected = {
        'infractionsByGroupAndDate': infractionsByGroupAndDate,
        'kudosByGroupAndDate': kudosByGroupAndDate,
        'staffsByGroupStatus': staffsByGroupStatus
    }
    const query = querieSelected[queryName]
    try{
        list = await mixinMethods.gLoadListAll(query, input, queryName)
    } catch(error){
        console.error(error)
    } finally {
        return list
    }
}

export async function loadDaPerformanceData(){
    let tableData = []
    let tenant = null
    let lowestScore = null
    let topScore = null
    let avgScore = null
    if(!store.getters.hasPerformanceCoaching){
        tableData = sampleData
        lowestScore = 75
        topScore = 314
        avgScore = 203.8
        tenant = {
            coachingDriverRankRange: 30
        }
        return { tenant, tableData, lowestScore, topScore, avgScore }
    }

    tenant = store.getters.getTenantCoachingInfo
    if(!Object.keys(tenant).length){
        tenant = await getTenantData()
    }
   
    const coachingDriverRankRange = parseInt(tenant.coachingDriverRankRange)
    
    try{
        const { input, inputPrev } = getInputs(coachingDriverRankRange)
        const [ daIssues, daKudos, daIssuesPrev, daKudosPrev ] = await Promise.all([
            getListData('infractionsByGroupAndDate', input),
            getListData('kudosByGroupAndDate', input ),
            getListData('infractionsByGroupAndDate', inputPrev),
            getListData('kudosByGroupAndDate', inputPrev)
        ])

        const associateList = store.getters["subscriptionStore/getAssociates"].filter(associate => associate.status?.toLowerCase() === 'active')
        let das = associateList.map(({id, firstName, lastName, heraScore, heraRank, prevHeraRank}) => ({
            id,
            firstName,
            lastName,
            heraScore,
            heraRank,
            prevHeraRank
        }));
        if(!das.length){
            const input = {
                group: store.state.userInfo.tenant.group,
                status: { eq: "Active" }
            }
            das = await getListData('staffsByGroupStatus', input)
        }

        const issueList = daIssuesPrev.concat(daIssues)
        const kudoList = daKudosPrev.concat(daKudos)
        //infractionList is listName -> store.state.subscriptionStore.infractionList
        store.commit("subscriptionStore/setList", { list: issueList, listName: "infractionList" })
        //kudoList is listName -> store.state.subscriptionStore.kudoList
        store.commit("subscriptionStore/setList", { list: kudoList, listName: "kudoList" })
        
        const result =  await calculateDaPerformanceData(tenant, das, issueList, kudoList);

        tableData = result.tableData
        lowestScore = result.lowestScore
        topScore = result.topScore
        avgScore = result.avgScore
        store.commit('setTenantCoachingInfo', tenant)
        store.commit('setDaPerformanceData', tableData)
        store.commit('setDaScore', { 
            topScore: topScore,
            lowestScore: lowestScore,
            avgScore: avgScore
        })
        return { tenant, tableData, lowestScore, topScore, avgScore }
    }catch(e){
        console.error(e)
    }
}

function getInputs(coachingDriverRankRange){
    const currentDate = moment();
    
    const pastDate = moment(currentDate).subtract(coachingDriverRankRange, 'days').format('YYYY-MM-DD');
    const dayAhead = moment(currentDate).add(2, 'days').format('YYYY-MM-DD');
    
    const pastDatePrev = moment(currentDate).subtract(coachingDriverRankRange * 2, 'days').format('YYYY-MM-DD');
    const dayAheadPrev = moment(currentDate).subtract(coachingDriverRankRange + 1, 'days').format('YYYY-MM-DD');
    
    return {
        input: {
            date: { between: [pastDate, dayAhead] },
            group: store.state.userInfo.tenant.group,
            sortDirection: "DESC"
        },
        inputPrev: {
            date: {between: [pastDatePrev, dayAheadPrev]},
            group: store.state.userInfo.tenant.group,
            sortDirection: "DESC"
        }
    }
}


export async function recalculateDaPerformanceData(reload=false){
   
    if(!store.getters.hasPerformanceCoaching) return
    if(reload){
        await loadDaPerformanceData()
        EventBus.$emit('reload-da-performance-report')
        return 
    }

    let tenant = store.getters.getTenantCoachingInfo
    let associateList = store.getters["subscriptionStore/getAssociates"].filter(associate => associate.status?.toLowerCase() === 'active')
    let das = associateList.map(({id, firstName, lastName, heraScore, heraRank, prevHeraRank}) => ({
        id,
        firstName,
        lastName,
        heraScore,
        heraRank,
        prevHeraRank
    }));
    
    const issueList = store.getters["subscriptionStore/getInfractionList"]
    const kudoList = store.getters["subscriptionStore/getKudoList"]
    const result =  await calculateDaPerformanceData(tenant, das, issueList, kudoList);

    store.commit('setTenantCoachingInfo', tenant)
    store.commit('setDaPerformanceData', result.tableData)
    store.commit('setDaScore', { 
        topScore: result.topScore,
        lowestScore: result.lowestScore, 
        avgScore: result.avgScore
    })
    EventBus.$emit('reload-da-performance-report')
}


async function filterDataByDate(data=[], dateStart, dateEnd){
    return data.filter(item => {
        const date = moment(item.date).format('YYYY-MM-DD')
        return moment(date).isBetween(dateStart, dateEnd, undefined, '[]')
    })
}

export async function calculateDaPerformanceData(tenant, das, issueList, kudoList){
    let activeDas = {}
    let tableData = []
    let lowestScore = null
    let topScore = null
    let avgScore = null

    const coachingDriverRankRange = parseInt(tenant.coachingDriverRankRange);
    const currentDate = moment();
    let dayAhead = moment(currentDate).add(2, 'days').format('YYYY-MM-DD');
    let pastDate = moment(currentDate).subtract(coachingDriverRankRange, 'days').format('YYYY-MM-DD');
    const daIssues = await filterDataByDate(issueList, pastDate, dayAhead)
    const daKudos = await filterDataByDate(kudoList, pastDate, dayAhead)

    dayAhead = moment(currentDate).subtract(coachingDriverRankRange + 1, 'days').format('YYYY-MM-DD');
    pastDate = moment(currentDate).subtract(coachingDriverRankRange * 2, 'days').format('YYYY-MM-DD');
    const daIssuesPrev = await filterDataByDate(issueList, pastDate, dayAhead)
    const daKudosPrev = await filterDataByDate(kudoList, pastDate, dayAhead)
    
    das.forEach(da =>{
        activeDas[da.id] = da
    })
    daIssues.forEach(issue =>{
        if(activeDas[issue.staffId]){
            if(!activeDas[issue.staffId].issues) activeDas[issue.staffId].issues = [issue]
            else activeDas[issue.staffId].issues.push(issue)
        }
    })
    daKudos.forEach(kudo =>{
        if(activeDas[kudo.staffId]){
            if(!activeDas[kudo.staffId].kudos) activeDas[kudo.staffId].kudos = [kudo]
            else activeDas[kudo.staffId].kudos.push(kudo)
        }
    })

    daIssuesPrev.forEach(issue =>{
        if(activeDas[issue.staffId]){
            if(!activeDas[issue.staffId].issuesPrev) activeDas[issue.staffId].issuesPrev = [issue]
            else activeDas[issue.staffId].issuesPrev.push(issue)
        }
    })
    daKudosPrev.forEach(kudo =>{
        if(activeDas[kudo.staffId]){
            if(!activeDas[kudo.staffId].kudosPrev) activeDas[kudo.staffId].kudosPrev = [kudo]
            else activeDas[kudo.staffId].kudosPrev.push(kudo)
        }
    })

    let unsorted = Object.values(activeDas)
  
    // determine hera score for each da
    let maxScore = 0
    let minScore = 999999999999999
    let maxScorePrev = 0
    let minScorePrev = 999999999999999

    const issueTypeList = store.getters.issueTypeList
    const kudoTypeList = store.getters.kudoTypeList
    const issueTypeObject = store.getters.issueTypeObject
    const kudoTypeObject = store.getters.kudoTypeObject

    let activeIssueValues = issueTypeList.filter(item =>{
        let key = item.option.replace(/ /g, '').replace(/-/g, '').replace(/\//g, '').replace(/®/g, '')
        let settingName = settingsMap[key]
        return tenant['coaching' + settingName + 'Issue'] || !item.isHiddenForSections?.length
    })
    
    let activeKudoValues = kudoTypeList.filter(item =>{
        let key = item.option.replace(/ /g, '').replace(/-/g, '').replace(/\//g, '').replace(/®/g, '')
        let settingName = settingsMap[key]
        return tenant['coaching' + settingName + 'Kudo'] || !item.isHiddenForSections?.length
    })
    let sumIssueValues = 0
    activeIssueValues.forEach(item => { sumIssueValues += item.driverReportSetting })

    let sumKudoValues = 0
    activeKudoValues.forEach(item => { sumKudoValues += item.driverReportSetting })
    let sumValues = sumIssueValues + sumKudoValues
    
    unsorted.forEach(da =>{
        let score = 0
        if(da.issues){
            da.issues.forEach(issue =>{
                const issueName = issue.type ? issue.type.option : parseType(issue.infractionType)
                let issueType = issueTypeObject[issueName]
                let issueWeight = issueType ? issueType.driverReportSetting / sumValues : 0
                score -= issueWeight
            })
        }
        if(da.kudos){
            da.kudos.forEach(kudo =>{
                const kudoName = kudo.type ? kudo.type.option : parseType(kudo.kudoType)
                let kudoType = kudoTypeObject[kudoName]
                let kudoWeight = kudoType ? kudoType.driverReportSetting / sumValues : 0
                score += kudoWeight
            })
        }
        da.heraScore = score
        if(score > maxScore) maxScore = score
        if(score < minScore) minScore = score

        let scorePrev = 0
        if(da.issuesPrev){
            da.issuesPrev.forEach(issue =>{
                const issueName = issue.type ? issue.type.option : parseType(issue.infractionType)
                let issueType = issueTypeObject[issueName]
                let issueWeight = issueType ? issueType.driverReportSetting / sumValues : 0
                scorePrev -= issueWeight
            })
        }
        if(da.kudosPrev){
            da.kudosPrev.forEach(kudo =>{
                const kudoName = kudo.type ? kudo.type.option : parseType(kudo.kudoType)
                let kudoType = kudoTypeObject[kudoName]
                let kudoWeight = kudoType ? kudoType.driverReportSetting / sumValues : 0
                scorePrev += kudoWeight
            })
        }
        da.heraScorePrev = scorePrev
        if(scorePrev > maxScorePrev) maxScorePrev = scorePrev
        if(scorePrev < minScorePrev) minScorePrev = scorePrev
    })

    unsorted.forEach(da =>{
        da.heraScore = parseFloat((da.heraScore * 1000).toFixed(0))
        da.heraScorePrev = parseFloat((da.heraScorePrev * 1000).toFixed(0))
    })

    topScore = parseFloat((maxScore * 1000).toFixed(0))
    lowestScore = parseFloat((minScore * 1000).toFixed(0))
    let sorted = unsorted.sort((a,b) => { return b.heraScore - a.heraScore })
    var prevScore = -1
    var currRank = 0
    var totalScore = 0
    for(var [i, da] of sorted.entries()){
        if(prevScore != da.heraScore) currRank++
        da.heraRank = currRank
        prevScore = da.heraScore
        totalScore += da.heraScore
    }
    avgScore = parseFloat((totalScore / sorted.length).toFixed(2))
    tableData = unsorted

    return { tableData, lowestScore, topScore, avgScore }
}
