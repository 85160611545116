<template>
  <div>
    <div>     
      <transition name="fade"  mode="out-in">  
        <component v-if="isLoaded" v-bind:is="layout"></component>
        <div v-else class="flex justify-center h-screen"><img src="@/assets/svg/loading.svg" class="w-20"/></div>
      </transition>      
    </div>    
  </div>
</template>

<script>
import { Auth, Storage } from 'aws-amplify'
import { mapMutations, mapActions } from 'vuex'
import { getUser } from '@/api/queries'
import loadValuelists from '@/scripts/loadValueLists'
import loadHistoryPlanTenant from '@/scripts/loadHistoryPlanTenant'
import DefaultLayout from '@/layouts/DefaultLayout'
import AuthLayout from '@/layouts/AuthLayout'
import BareLayout from '@/layouts/BareLayout'
import PrintLayout from '@/layouts/PrintLayout'
import { nanoid } from "nanoid";
import { previewDownloadedHeicImage } from '@/utilities/heicUtility.js'

export default {
  components:{DefaultLayout, AuthLayout, BareLayout, PrintLayout},
  data(){
    return {   
      isLoaded: false   
    }
  },
  mounted(){    
    this.checkAuthState()
  },
  computed:{
    layout(){
      return this.$route.meta.layout || 'DefaultLayout'
    }
  },
  
  watch:{
    '$route.query.error'(){
      this.checkForErrors()
    }
  },


  methods:{
    ...mapMutations([
      'setSessionInfo',
      'setUserInfo',
      'setCompanyLogo'
    ]),
    ...mapActions([
      'loadLabelTypes',
      'loadSystemList'
    ]),

    checkForErrors(){
      var error = this.$route.query.error;
      if(error){
        this.$notify.error({
          title: "Error",
          message: error
        })
      }
    },
    async checkAuthState(){
      try{
        //Set session info
        console.log("check auth")
        var session = await Auth.currentUserPoolUser()
        
        const userInstance = nanoid(36)
        sessionStorage.setItem("userInstance", userInstance)

        this.setSessionInfo(session)

        //Set user data
        const userId = session.attributes['custom:user_id'];
        const supportAdministrator = localStorage.getItem('supportAdministrator')
        this.$store.commit('setSupportAdministrator', supportAdministrator ? true : false)
        const user = await this.api(getUser, {id: supportAdministrator ? supportAdministrator : userId})
        this.setUserInfo(user.data.getUser)

        //Update intercom
        // let intercomBoot = {
        //   app_id: process.env.VUE_APP_ID_INTERCOM,
        //   user_id: userId,
        //   email: user.data.getUser.email,
        //   name: user.data.getUser.firstName + ' ' + user.data.getUser.lastName,
        //   role: user.data.getUser.role,
        //   phone: user.data.getUser.phone,
        //   company:{
        //     company_id: user.data.getUser.tenant.id,
        //     name: user.data.getUser.tenant.companyName
        //   }
        // };
        // window.Intercom('boot', intercomBoot );
        
        //Load value lists
        await loadValuelists(this, user.data.getUser.tenant.id)

        //Load Custom List Values
        await this.loadCustomList()

        //Update company logo
        const companyLogoS3Key = user.data.getUser.tenant.logo
        if(companyLogoS3Key){
          let imageUrl = null
          if(/\.heic$/i.test(companyLogoS3Key)){
            const file = await Storage.get(companyLogoS3Key, {download: true})
            const blob = await previewDownloadedHeicImage(file)
            imageUrl = URL.createObjectURL(blob)
          }else{
            imageUrl = await Storage.get(companyLogoS3Key)
          }
          this.setCompanyLogo(imageUrl)
        }

        //load History Plan for read-only access
        await loadHistoryPlanTenant(user.data.getUser.tenant.group)
        //Load labels
        await this.loadLabelTypes()
        //Load System List 
        this.loadSystemList()

        
      }catch(e){
        this.setSessionInfo(null)
        this.setUserInfo(null)
      }finally{
        this.isLoaded = true
      }
    }
  }
}
</script>

<style lang="scss">

</style>