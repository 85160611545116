import moment from 'moment-timezone';
var generateTemplate = function (invoice, lineItems, customer) {
  var daysInMonth = [31, 28, 31, 30, 31, 30, 31, 31, 30, 31, 30, 31];
  var isLeapYear = invoice.year % 100 === 0 ? invoice.year % 400 === 0 : invoice.year % 4 === 0;
  if (isLeapYear) daysInMonth[1] = 29;

  // get grand totals
  var grandTotals = lineItems.reduce((accumulator, item) => ({
    standardCostExt: accumulator.standardCostExt + item.standardCostExt,
    bundleCostExt: accumulator.bundleCostExt + item.bundleCostExt,
    performanceCostExt: accumulator.performanceCostExt + item.performanceCostExt,
    rosteringCostExt: accumulator.rosteringCostExt + item.rosteringCostExt,
    staffCostExt: accumulator.staffCostExt + item.staffCostExt,
    vehiclesCostExt: accumulator.vehiclesCostExt + item.vehiclesCostExt,
  }));
  // determine if tenant still has an active trial
  const d = getTenantTime(customer.timeZone,-24)
  let todayMidnightUnixTime = new Date(d.dt).setHours(0,0,0,0)
  let trialExpMidnightUnixTime = new Date(customer.trialExpDate).setHours(0,0,0,0)
  let isTrialExpired =  trialExpMidnightUnixTime < todayMidnightUnixTime
  const isInTrial = !isTrialExpired || customer.accountPremiumStatus.includes('trial');
  if(isInTrial) tenantFlatMonthlyBillingAmount = 0;
  
  var tenantFlatMonthlyBillingAmount = invoice?.flatMonthlyBillingAmount && !isInTrial ? invoice?.flatMonthlyBillingAmount : 0;
  var tenantFlatMonthlyBillingLabel = invoice?.flatMonthlyBillingLabel ? invoice?.flatMonthlyBillingLabel : 'Flat Total'
   
   

    var amoutCharged = invoice?.paidByCustomerAmount ? invoice?.paidByCustomerAmount : 0;
  grandTotals.variableTotal =
    grandTotals.standardCostExt +
    grandTotals.bundleCostExt +
    grandTotals.performanceCostExt +
    grandTotals.rosteringCostExt +
    grandTotals.staffCostExt +
    grandTotals.vehiclesCostExt;

  grandTotals.invoiceSubTotal = grandTotals.variableTotal + tenantFlatMonthlyBillingAmount;

  var fixedDiscount = invoice.discountFixed && !isInTrial ? invoice.discountFixed : 0;
  var percentDiscount = invoice.discountPercent ? (invoice.discountPercent / 100) * (grandTotals.invoiceSubTotal - fixedDiscount) : 0;
  var invoiceTotal = grandTotals.invoiceSubTotal - fixedDiscount - percentDiscount;
  grandTotals.invoiceTotal = invoiceTotal.toFixed(2);

  if (!invoice.discountFixedLabel) {
    invoice.discountFixedLabel = '';
  } else {
    invoice.discountFixedLabel = '('+ invoice.discountFixedLabel +') '
  }

  if (!invoice.discountPercentLabel) {
    invoice.discountPercentLabel = '';
  } else {
    invoice.discountPercentLabel = '('+ invoice.discountPercentLabel + ' - ' + invoice.discountPercent+'%) '
  }

  var html = `
        <div id="container-invoice">
        <div style="width: 100%" >
            <div style="text-align: right;  display: inline-block;">
                <img style="height: 90px;" src='https://irp.cdn-website.com/dda4e527/dms3rep/multi/HeraLogoVertical.png'> 
            </div>
            <div style="margin-left: 1rem; display: inline-block;">
                <h1>Hera Solutions, Inc</h1>
                <p>4062 Peachtree Rd NE</p>
                <p>STE A556</p>
                <p>Atlanta, GA 30319</p>
                <p><a class="link" href="https://www.hera.app/" target="_blank">www.hera.app</a></p>
            </div>
        </div>
        <div style="width: 50%; margint-top: 2rem;">
            <div style="margin: 2rem 0 2rem 0;">
                <p style="font-weight: bold;  width: 100%">Bill To</p>
                <hr style="background-color: black; height: 1px; border: none; margin-top: 8px;" />
                <p>${customer.companyName ? customer.companyName : ''} - ${
    customer.shortCode ? customer.shortCode : ''
  }</p>
                <p>${customer.addressLine1 ? customer.addressLine1 : ''}</p>
                <p>${customer.addressLine2 ? customer.addressLine2 : ''}</p>
                <p>${customer.addressCity ? customer.addressCity : ''}${
    customer.addressCity && customer.addressState ? ',' : ''
  } ${customer.addressState ? customer.addressState : ''} ${
    customer.addressZip ? customer.addressZip : ''
  }</p>
                <p>${customer.stripeBillingEmail ? customer.stripeBillingEmail : ''}</p>
            </div>
            <p><span style="font-weight: bold;">Billing period:</span> ${invoice.year}-${
    parseInt(invoice.month) + 1
  }</p>
            <p style="margin-bottom: 1rem;"><span style="font-weight: bold;">Charged to:</span> ${
              invoice.cardLastFourCharged ? ' ****' + invoice.cardLastFourCharged : '—'
            }</p>
        </div>
        <table>
        <tr>
            <th style="text-align: left; width: 140px;">Day</th>
            <th style="text-align: center; width: 210px;">Active Staff</th>
            <th style="text-align: right; width: 180px;">Standard</th>
            <th style="text-align: right; width: 180px;">Bundle</th>
            <th style="text-align: right; width: 210px;">Performance</th>
            <th style="text-align: right; width: 180px;">Rostering</th>
            <th style="text-align: right; width: 180px;">Staff</th>
            <th style="text-align: right; width: 180px;">Vehicles</th>
            <th style="text-align: center; width: 100px;"></th>
            <th style="text-align: right; width: 200px;">Variable Total</th>
        </tr>
    `;

  var i = 0;
  for (const item of lineItems) {
    var month = String(parseInt(item.month) + 1).padStart(2, '0');
    var day = String(item.day).padStart(2, '0');
    var htmlRow = `<tr style="${i % 2 == 1 ? 'background-color: #f3f4f6;' : ''}">
            <td style="text-align: left; width: 140px;">${month}/${day}</td>
            <td style="text-align: center; width: 210px;">${item.activeStaff}</td>
            <td style="text-align: right; width: 180px;">$${item.standardCostExt.toFixed(2)}</td>
            <td style="text-align: right; width: 180px;">$${item.bundleCostExt.toFixed(2)}</td>
            <td style="text-align: right; width: 210px;">$${item.performanceCostExt.toFixed(2)}</td>
            <td style="text-align: right; width: 180px;">$${item.rosteringCostExt.toFixed(2)}</td>
            <td style="text-align: right; width: 180px;">$${item.staffCostExt.toFixed(2)}</td>
            <td style="text-align: right; width: 180px;">$${item.vehiclesCostExt.toFixed(2)}</td>
            <td style="text-align: center; width: 100px; color: #0268BC;">${
              item.isTrial ? 'Trial' : ''
            }</td>
            <td style="text-align: right; width: 180px;">$${item.variableTotal.toFixed(2)}</td>
        </tr>`;
    html += htmlRow;
    i = item.day;
  }
  i++;
  for (i; i <= daysInMonth[invoice.month]; i++) {
    var month = String(parseInt(invoice.month) + 1).padStart(2, '0');
    var day = String(i).padStart(2, '0');
    var htmlRow = `<tr style="${i % 2 == 1 ? 'background-color: #f3f4f6;' : ''}">
            <td style="text-align: left; width: 140px;">${month}/${day}</td>
            <td style="color: lightgray; text-align: center; width: 210px;}">—</td>
            <td style="color: lightgray; text-align: right; width: 180px;}">—</td>
            <td style="color: lightgray; text-align: right; width: 180px;}">—</td>
            <td style="color: lightgray; text-align: right; width: 210px;}">—</td>
            <td style="color: lightgray; text-align: right; width: 180px;}">—</td>
            <td style="color: lightgray; text-align: right; width: 180px;}">—</td>
            <td style="color: lightgray; text-align: right; width: 180px;}">—</td>
            <td style="color: lightgray; text-align: center; width: 100px;} color: #0268BC;"></td>
            <td style="color: lightgray; text-align: right; width: 180px;}">—</td>
        </tr>`;
    html += htmlRow;
  }

  html = `
        ${html}
        <tr style="border-top: 1px solid black;">
            <td style="text-align: left; width: 350px; font-style: italic;" colspan=2></td>
            <td style="text-align: right; width: 180px; font-style: italic;">$${grandTotals.standardCostExt.toFixed(
              2
            )}</td>
            <td style="text-align: right; width: 180px; font-style: italic;">$${grandTotals.bundleCostExt.toFixed(
              2
            )}</td>
            <td style="text-align: right; width: 210px; font-style: italic;">$${grandTotals.performanceCostExt.toFixed(
              2
            )}</td>
            <td style="text-align: right; width: 180px; font-style: italic;">$${grandTotals.rosteringCostExt.toFixed(
              2
            )}</td>
            <td style="text-align: right; width: 180px; font-style: italic;">$${grandTotals.staffCostExt.toFixed(
              2
            )}</td>
            <td style="text-align: right; width: 180px; font-style: italic;">$${grandTotals.vehiclesCostExt.toFixed(
              2
            )}</td>
            <td style="text-align: left; width: 100px; font-style: italic;"></td>
            <td style="text-align: right; width: 180px; font-style: italic;">$${grandTotals.variableTotal.toFixed(
              2
            )}</td>
        </tr>
        <tr><td colspan="10" style="height: 2rem;"></td></tr>
    `;
  html += `
    <tr>
        <td colspan="8" style="font-weight: bold; text-align: right;">Variable Total:</td>
        <td colspan="2"style="text-align: right;">$${grandTotals.variableTotal.toFixed(
          2
        )}</td>
    </tr>
  `
  if (tenantFlatMonthlyBillingAmount > 0) {
    html += `
        <tr>
            <td colspan="8" style="font-weight: bold; text-align: right;">${tenantFlatMonthlyBillingLabel}:</td>
            <td colspan="2"style="text-align: right;">$${tenantFlatMonthlyBillingAmount.toFixed(
              2
            )}</td>
        </tr>
    `;
  }
  html += `
    <tr>
        <td colspan="8" style="font-weight: bold; text-align: right;">Invoice Sub Total:</td>
        <td colspan="2"style="text-align: right;">$${grandTotals.invoiceSubTotal.toFixed(
          2
        )}</td>
    </tr>
  `
  if (fixedDiscount) {
    html += `
            <tr>
                <td colspan="7" style="font-style: italic; text-align: right;">${invoice.discountFixedLabel}</td>
                <td colspan="1" style="font-weight: bold; text-align: right;">Discount:</td>
                <td colspan="2"style="text-align: right;">$${fixedDiscount}</td>
            </tr>
        `;
  }

  if (percentDiscount) {
    html += `
            <tr>
                <td colspan="7" style="font-style: italic; text-align: right;">${invoice.discountPercentLabel}</td>
                <td colspan="1" style="font-weight: bold; text-align: right;">Discount:</td>
                <td colspan="2"style="text-align: right;">$${percentDiscount.toFixed(2)}</td>
            </tr>
        `;
  }


  html += `
            <tr>
                <td colspan="8" style="font-weight: bold; text-align: right;">Invoice Total:</td>
                <td colspan="2"style="text-align: right;">$${grandTotals.invoiceTotal}</td>
            </tr>`

  if (invoice.status=='Paid'){
      html +=           
            `<tr>
                <td colspan="8" style="font-weight: bold; text-align: right;">Amount Charged:</td>
                <td colspan="2"style="text-align: right;">$${amoutCharged.toFixed(2)}</td>
            </tr>
            <tr>
                <td colspan="8" style="font-weight: bold; text-align: right;">Balance:</td>
                <td colspan="2"style="text-align: right;">$0.00</td>
            </tr>`
  }

  if (invoice.status=='Pending' || invoice.status=='Payment Error' || invoice.status=='Finalized'){
    html +=           
            `<tr>
                <td colspan="8" style="font-weight: bold; text-align: right;">Amount Charged:</td>
                <td colspan="2"style="text-align: right;">$0.00</td>
            </tr>
            <tr>
                <td colspan="8" style="font-weight: bold; text-align: right;">Balance:</td>
                <td colspan="2"style="text-align: right;">$${grandTotals.invoiceTotal}</td>
            </tr>`
  }
html +=           
            `
        </table>
        
        <div style="text-align: right; margin-top: 1.5rem;">
            
        </div>
        </div>
    `;

  return html;
};

function getTenantTime(timeZone, offsetHours=0){
  console.log("Tenant Timezone:", timeZone);
  if (!!moment.tz.zone(timeZone)){
    const utc = offsetHours >= 0 ? moment.utc().add(Math.abs(offsetHours), 'hours'):moment.utc().subtract(Math.abs(offsetHours), 'hours');
    const isoUtcDate = utc.format("YYYY-MM-DDTHH:mm:ssZZ");
    console.log("Utc    Time: ",isoUtcDate);
    const dt = utc.clone().tz(timeZone);
    const isoDate = dt.format("YYYY-MM-DDTHH:mm:ssZZ");
    console.log("Tenant Time: ",isoDate);
    const year = dt.year()
    const month = dt.month()
    const day = dt.date()
    //Month is 0 index but this function is 1 index, so adding 1 to month
    const numOfDays = new Date(year, month + 1, 0).getDate();
    return {dt:dt, day:day, month:month, year:year, numOfDays: numOfDays, isoDate:isoDate, utcDt: utc, isoUtcDate: isoUtcDate};
  }
  else{
    console.log("Tenant has invalid timezone value");
    return null;
  }
}

export default generateTemplate;
