/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "us-east-2",
    "aws_cloud_logic_custom": [
        {
            "name": "AdminQueries",
            "endpoint": "https://3xtnte5jde.execute-api.us-east-2.amazonaws.com/mqa",
            "region": "us-east-2"
        },
        {
            "name": "ForceLogoutAndRefresh",
            "endpoint": "https://mit71qvkyl.execute-api.us-east-2.amazonaws.com/mqa",
            "region": "us-east-2"
        },
        {
            "name": "ManageNotificationPreferences",
            "endpoint": "https://6ynvfnqazc.execute-api.us-east-2.amazonaws.com/mqa",
            "region": "us-east-2"
        },
        {
            "name": "ProcessPerformanceData",
            "endpoint": "https://ah12asoh6b.execute-api.us-east-2.amazonaws.com/mqa",
            "region": "us-east-2"
        },
        {
            "name": "ScheduleNotifications",
            "endpoint": "https://21w2rmypef.execute-api.us-east-2.amazonaws.com/mqa",
            "region": "us-east-2"
        },
        {
            "name": "SendInternalMessage",
            "endpoint": "https://w6h6lywm0g.execute-api.us-east-2.amazonaws.com/mqa",
            "region": "us-east-2"
        },
        {
            "name": "Signup",
            "endpoint": "https://dmnp6mw3xk.execute-api.us-east-2.amazonaws.com/mqa",
            "region": "us-east-2"
        },
        {
            "name": "bandwidth",
            "endpoint": "https://xh62ff7tsi.execute-api.us-east-2.amazonaws.com/mqa",
            "region": "us-east-2"
        },
        {
            "name": "csvDataExport",
            "endpoint": "https://df8ox0a6n8.execute-api.us-east-2.amazonaws.com/mqa",
            "region": "us-east-2"
        },
        {
            "name": "downloadFiles",
            "endpoint": "https://q6yq511uu7.execute-api.us-east-2.amazonaws.com/mqa",
            "region": "us-east-2"
        },
        {
            "name": "generateCounselingFilesApi",
            "endpoint": "https://ie8fa317ul.execute-api.us-east-2.amazonaws.com/mqa",
            "region": "us-east-2"
        },
        {
            "name": "handleTelnyxResponse",
            "endpoint": "https://csp47szsna.execute-api.us-east-2.amazonaws.com/mqa",
            "region": "us-east-2"
        },
        {
            "name": "heraPublicApi",
            "endpoint": "https://u0xj9ppfnc.execute-api.us-east-2.amazonaws.com/mqa",
            "region": "us-east-2"
        },
        {
            "name": "intercomUpdateCompanies",
            "endpoint": "https://3q6s6f66zb.execute-api.us-east-2.amazonaws.com/mqa",
            "region": "us-east-2"
        },
        {
            "name": "invoice",
            "endpoint": "https://i7hai6ijbj.execute-api.us-east-2.amazonaws.com/mqa",
            "region": "us-east-2"
        },
        {
            "name": "rds",
            "endpoint": "https://lfgepfdxg0.execute-api.us-east-2.amazonaws.com/mqa",
            "region": "us-east-2"
        },
        {
            "name": "s3downloadlink",
            "endpoint": "https://vsrs0f9r3l.execute-api.us-east-2.amazonaws.com/mqa",
            "region": "us-east-2"
        },
        {
            "name": "sendMessage",
            "endpoint": "https://3fg7fs9gec.execute-api.us-east-2.amazonaws.com/mqa",
            "region": "us-east-2"
        },
        {
            "name": "sendMessages",
            "endpoint": "https://zydjgbltnk.execute-api.us-east-2.amazonaws.com/mqa",
            "region": "us-east-2"
        },
        {
            "name": "sendPendingCoachingMessage",
            "endpoint": "https://vsjn0mxbrj.execute-api.us-east-2.amazonaws.com/mqa",
            "region": "us-east-2"
        },
        {
            "name": "stripeCharge",
            "endpoint": "https://njqsfo1fda.execute-api.us-east-2.amazonaws.com/mqa",
            "region": "us-east-2"
        },
        {
            "name": "stripeSetup",
            "endpoint": "https://4nfpq42sab.execute-api.us-east-2.amazonaws.com/mqa",
            "region": "us-east-2"
        },
        {
            "name": "tenant",
            "endpoint": "https://iul5s4rr1a.execute-api.us-east-2.amazonaws.com/mqa",
            "region": "us-east-2"
        },
        {
            "name": "trackAuditLog",
            "endpoint": "https://88xz5v8hke.execute-api.us-east-2.amazonaws.com/mqa",
            "region": "us-east-2"
        },
        {
            "name": "zoho",
            "endpoint": "https://ph6njk6nn7.execute-api.us-east-2.amazonaws.com/mqa",
            "region": "us-east-2"
        }
    ],
    "aws_appsync_graphqlEndpoint": "https://k67ggdissbfv7jwwikyhyjxrce.appsync-api.us-east-2.amazonaws.com/graphql",
    "aws_appsync_region": "us-east-2",
    "aws_appsync_authenticationType": "AMAZON_COGNITO_USER_POOLS",
    "aws_appsync_apiKey": "da2-s26fepp7ffhahkx6koo54ylpma",
    "aws_cognito_identity_pool_id": "us-east-2:2ab7d803-fb3c-45c0-94d7-9d337c7bd9e3",
    "aws_cognito_region": "us-east-2",
    "aws_user_pools_id": "us-east-2_RlXeA6vPW",
    "aws_user_pools_web_client_id": "61lderorbhbekj3p596prgfb89",
    "oauth": {},
    "aws_cognito_username_attributes": [
        "EMAIL"
    ],
    "aws_cognito_social_providers": [],
    "aws_cognito_signup_attributes": [
        "EMAIL"
    ],
    "aws_cognito_mfa_configuration": "OFF",
    "aws_cognito_mfa_types": [
        "SMS"
    ],
    "aws_cognito_password_protection_settings": {
        "passwordPolicyMinLength": 8,
        "passwordPolicyCharacters": []
    },
    "aws_cognito_verification_mechanisms": [
        "EMAIL"
    ],
    "aws_user_files_s3_bucket": "hera2009fb1ba5504c8da9ba5f1fe34db7380c3e0-mqa",
    "aws_user_files_s3_bucket_region": "us-east-2"
};


export default awsmobile;
