<template>
    <el-card class="form-card relative" style="height: 100%; display: flex; flex-direction: column; margin: 0;">
      <div class="card-header">
        <h3>{{ title }}</h3>
        <!-- Edit Icon Button (always visible) -->
        <i 
          v-if="!isEditing && !isLoading"
          @click="toggleEdit"
          class="icon-button uil uil-pen"
          style="cursor: pointer; font-size: 1.5em;"
          data-cy="edit-icon-btn"
        />
      </div>
      
      <!-- Form with slot content and validation rules -->
      <el-form :model="formData" :rules="formRules" ref="form" label-position="top" class="form-content">
        <!-- Pass isEditing to slot using v-bind -->
        <slot :is-editing="isEditing"></slot>
      </el-form>
  
      <!-- Save and Cancel buttons (only in editing mode) -->
      <div v-if="isEditing || isLoading" class="p-2 flex justify-end">
        <el-button :disabled="isLoading" class="btn-sm" size="small" @click="handleCancel">
          Cancel
        </el-button>
        <el-button :disabled="isLoading" class="btn-sm" size="small" type="primary" @click="handleSave">
          Save
        </el-button>
      </div>
    </el-card>
  </template>
  
  <script>
  export default {
    props: {
      title: {
        type: String,
        required: true,
      },
      formData: {
        type: Object
      },
      formRules: {
        type: Object,
        required: true, // Now we expect validation rules to be passed in from the parent component
      },
      isLoading: {
        type: Boolean
      },
      idx: {
        type: Number
      }
    },
    data() {
      return {
        isEditing: false, // Controls whether fields are editable
        originalData: null // Store original data
      };
    },
    methods: {
      toggleEdit() {
        this.isEditing = true; // Enable editing mode
        if (!this.originalData) this.originalData = JSON.parse(JSON.stringify(this.formData))
        this.$emit('cardEditIdx', this.idx); // Emit the index of the card to be edited
      },
      handleCancel() {
        // Reset the form to its original state
        this.$refs.form.resetFields(); // Reset validation if needed
        // Reset form data to original values
        this.$emit('cancel', JSON.parse(JSON.stringify(this.originalData))); 
        this.isEditing = false; // Exit editing without saving
      },
      handleSave() {
        this.$refs.form.validate((valid) => {
          if (valid) {
            this.isEditing = false; // Exit editing mode
            this.originalData = null
            this.$emit('save', this.formData); // Emit save event with the formData
          } else {
            console.log("Form validation failed!");
          }
        });
      },
    },
  };
  </script>  
  
  <style scoped>
  .form-card {
    margin-bottom: 20px !important;
  }
  
  .card-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  
  .form-content {
    margin-top: 20px;
  }
  
  .p-2 {
    padding: 10px;
  }
  
  .flex {
    display: flex;
  }
  
  .justify-end {
    justify-content: flex-end;
  }
  
  .btn-sm {
    margin-left: 10px;
  }
  </style>
  