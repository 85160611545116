export const createStaffScoreCard = /* GraphQL */ `
  mutation CreateStaffScoreCard(
    $input: CreateStaffScoreCardInput!
    $condition: ModelStaffScoreCardConditionInput
  ) {
    createStaffScoreCard(input: $input, condition: $condition) {
      id
      createdAt
      updatedAt
    }
  }
`;

export const updateStaffScoreCard = /* GraphQL */ `
  mutation UpdateStaffScoreCard(
    $input: UpdateStaffScoreCardInput!
    $condition: ModelStaffScoreCardConditionInput
  ) {
    updateStaffScoreCard(input: $input, condition: $condition) {
      id
      createdAt
      updatedAt
    }
  }
`;
