const  { parsePhoneNumber } =  require('libphonenumber-js');
const { API } = require('aws-amplify');

const phoneNumberFormatUS = (phone_number) => {
  if (phone_number == null || phone_number == '') {
    return '';
  }

  try {
    const phoneNumber = parsePhoneNumber(phone_number, 'US')
    if(phoneNumber) {
      if (phoneNumber.isValid()) {
       
        const nationalNumber = phoneNumber.nationalNumber;
        const phoneFormatUS = `(${ nationalNumber.substring(0, 3) }) ${ nationalNumber.substring(3, 6) }-${ nationalNumber.slice(-4)}`;
        return phoneFormatUS;
      }
    }
  } catch (e) {
    return '';
  }
}

const phoneNumberMask = (phone_number) => {

    if (phone_number == null || phone_number == '') {
      return '';
    }

    try {
      const phoneNumber = parsePhoneNumber(phone_number, 'US')
      if (phoneNumber.isValid()) {
        const cleanPhone = phone_number.replace(/[^\d]+/g, "");
        const lenPhone = cleanPhone.length;

        if (lenPhone == 10) return `(${ cleanPhone.substring(0, 3) }) ${ cleanPhone.substring(3, 6) }-${ cleanPhone.slice(-4)}`;

        if (lenPhone == 11) return `+${ cleanPhone.substring(0, 1) } (${ cleanPhone.substring(1, 4) }) ${ cleanPhone.substring(4, 7) }-${ cleanPhone.slice(-4)}`;
      }
    }
    catch (e) {
      return '';
    }
}

const cleanPhoneNumber = (phone_number,country_code) => {

  if (phone_number == null || phone_number == '') {
    return '';
  }
  try {
    const phoneNumber = parsePhoneNumber(phone_number, country_code)
    if(phoneNumber) {
      if (phoneNumber.isValid()) { 
        return phoneNumber.number;
      }
    }
  } catch(e) {
    return '';
  }
  return '';
  
}

const validatePhoneNumberByTelnyxSingle = async ( phone_number ) => {
  let apiName = 'handleTelnyxResponse'
  let path = `/validator/${phone_number}`
  let myInit = {
    headers: {
      'Content-Type' : 'application/json'
    } 
  }
  let telnyxValidation = await API.get(apiName, path, myInit)
  return telnyxValidation;
}


const validatePhoneNumberByTelnyx = async ( records ) => {
  try {
    const apiName = "handleTelnyxResponse";
    const path = "/validator/telnyx";
    const post = {
      body: { records }
    };
    const { result } = await API.post(apiName, path, post);
    return result || [];
  } catch (error) {
    console.error(error);
    return [];
  }
}

const validatePhoneNumberFormat = async  (records) => {
  const mapRecords = records.map((item) => {
    const validated = validatedRecordPhone(item.phone);
    let record = {
      ...item,
      phone : getPhoneNumber(validated,item.phone),
      validated
    }
    return record;
  })
  const validationPhoneByTelnyx = await validatePhoneNumberByTelnyx( mapRecords );
  return validationPhoneByTelnyx;
}
const validateDevicePhoneNumberFormat = async  (records) => {
  const mapRecords = records.map((item) => {
    const validated = validatedRecordPhone(item.phoneNumber);
    let record = {
      ...item,
      phone : getPhoneNumber(validated,item.phoneNumber),
      validated
    }
    return record;
  })
  const validationPhoneByTelnyx = await validatePhoneNumberByTelnyx( mapRecords );
  return validationPhoneByTelnyx;
}

const validatePhoneNumberFormatSingle = async  (phone_number) => {
  if (phone_number == null) return true;
  if (phone_number == '') return true;
  const onlyNumbers = phone_number.replace(/[^\d]+/g, "");
  if (onlyNumbers.length != 10) return false;
  const validatePhoneNumber = parsePhoneNumber(phone_number, 'US');
  if( validatePhoneNumber ) {
    if (!validatePhoneNumber.isValid()) { 
      return false;
    }
    else {
      phone_number = validatePhoneNumber.number
    }
  } else {
    return false
  }
  
  const validationPhoneByTelnyx = await validatePhoneNumberByTelnyxSingle( phone_number );
  return validationPhoneByTelnyx;
}

function validatedRecordPhone(phone){
  if (!phone || phone == '') return true;
  const onlyNumbers = phone.replace(/[^\d]+/g, "");
  if (onlyNumbers.length != 10) return false;
  const validatePhoneNumber = parsePhoneNumber(phone, 'US');
  return validatePhoneNumber.isValid()
}

function getPhoneNumber(validated, phone){
  if(phone && phone !== '' && validated){
    const validatePhoneNumber = parsePhoneNumber(phone, 'US');
    return validatePhoneNumber.number
  }
  return phone
}

module.exports = {
    validatePhoneNumberFormat,
    validatePhoneNumberFormatSingle,
    cleanPhoneNumber,
    phoneNumberFormatUS,
    phoneNumberMask,
    validatedRecordPhone,
    validateDevicePhoneNumberFormat,
}