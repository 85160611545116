<template>
    <div class=" h-96">
        <!-- <div v-show="selected.bundle">
            <span class="text-sm font-semibold text-gray-700">🎉 Includes {{bundleSavings | currencyUS}}/mo bundle savings 🎉</span>
        </div> -->
        <div v-show="selected.standard" class="flex mt-4">
            <span class="flex-1 font-semibold text-gray-700">Base Plan</span><span class="flex-initial">{{costPerDriver.standard | currencyUS}}</span><span class="text-sm text-gray-600">/{{$t('label.associate')}}/mo</span>
        </div>
        <div v-show="selected.bundle" class="flex mt-4">
          <!-- <span class="flex-1 font-semibold text-gray-700">Premium Bundle</span><span class="flex-initial">{{bundleCostPerDriver | currencyUS}}</span><span class="text-sm text-gray-600">/{{$t('label.associate')}}/mo</span> -->
          <span class="flex-1 font-semibold text-gray-700">Hera Subscription</span><span class="flex-initial">{{bundleCostPerDriver | currencyUS}}</span><span class="text-sm text-gray-600">/{{$t('label.associate')}}/mo</span>
        </div>
        <div v-show="selected.performance" class="flex mt-4">
            <span class="flex-1 font-semibold text-gray-700">Premium Performance &amp; Coaching</span><span class="flex-initial">{{costPerDriver.performance | currencyUS}}</span><span class="text-sm text-gray-600">/{{$t('label.associate')}}/mo</span>
        </div>
        <div v-show="selected.rostering" class="flex mt-4">
            <span class="flex-1 font-semibold text-gray-700">Premium Rostering</span><span class="flex-initial">{{costPerDriver.rostering | currencyUS}}</span><span class="text-sm text-gray-600">/{{$t('label.associate')}}/mo</span>
        </div>
        <div v-show="selected.staff" class="flex mt-4">
            <span class="flex-1 font-semibold text-gray-700">Premium {{$t('label.associate')}} Management</span><span class="flex-initial">{{costPerDriver.staff | currencyUS}}</span><span class="text-sm text-gray-600">/{{$t('label.associate')}}/mo</span>
        </div>
        <div v-show="selected.vehicles" class="flex mt-4">
            <span class="flex-1 font-semibold text-gray-700">Premium Vehicle Management</span><span class="flex-initial">{{costPerDriver.vehicles | currencyUS}}</span><span class="text-sm text-gray-600">/{{$t('label.associate')}}/mo</span>
        </div>

        <div class="absolute bottom-0 mb-40 w-full"><div class="border-t mr-8"></div></div>
        <div class="w-full absolute bottom-0 mb-16 pr-8">
            <div class="flex" v-show="premiumTotalCostSelected">
                <span class="flex-1 font-semibold text-gray-700">Total</span>
                <span class="flex-initial">{{premiumTotalCostSelectedPerDriver | currencyUS }}</span><span class="text-sm text-gray-600">/{{$t('label.associate')}}/mo</span>
            </div>
            <div class="flex">
            <span class="flex-1 font-semibold text-gray-700">Currently Active {{$t('label.associates')}}</span><span class="flex-initial">{{numActiveStaff}}</span>
            </div>
            <div class="flex text-2xl">
                <span class="flex-1">Est. Monthly Total</span>
                <span class="flex-initial">{{premiumTotalCostSelected | currencyUS }}</span><span class="text-sm text-gray-600">/mo</span>
            </div>
        </div>
    </div>
</template>

<script>

export default {
  name: 'EstimatedTotal',
  props: ['selected', 'costPerDriver', 'numActiveStaff'],
  data(){
    return{
      loading: false,
    }
  },

  computed: {
    standardCost(){
      return this.numActiveStaff * this.costPerDriver.standard
    },
    bundleCost(){
      return this.numActiveStaff * this.bundleCostPerDriver
    },
    bundleSavings(){
      return this.premiumTotalCost - this.bundleCost
    },
    performanceCost(){
      return this.numActiveStaff * this.costPerDriver.performance
    },
    rosteringCost(){
      return this.numActiveStaff * this.costPerDriver.rostering
    },
    staffCost(){
      return this.numActiveStaff * this.costPerDriver.staff
    },
    vehiclesCost(){
      return this.numActiveStaff * this.costPerDriver.vehicles
    },

    bundleCostPerDriver(){
      return this.costPerDriver.standard + 
      this.costPerDriver.performance + 
      this.costPerDriver.rostering + 
      this.costPerDriver.staff + 
      this.costPerDriver.vehicles - 1 ;
    },
    premiumTotalCostSelected(){
      return this.standardCost * this.selected.standard + 
        this.bundleCost * this.selected.bundle + 
        this.performanceCost * this.selected.performance  + 
        this.rosteringCost * this.selected.rostering  + 
        this.staffCost * this.selected.staff  + 
        this.vehiclesCost * this.selected.vehicles  ;
    },
    premiumTotalCostSelectedPerDriver(){
      return (this.standardCost * this.selected.standard+ 
        this.bundleCost * this.selected.bundle + 
        this.performanceCost * this.selected.performance  + 
        this.rosteringCost * this.selected.rostering  + 
        this.staffCost * this.selected.staff  + 
        this.vehiclesCost * this.selected.vehicles ) / this.numActiveStaff ;
    },
    premiumTotalCost(){
      return this.standardCost + 
        this.performanceCost + 
        this.rosteringCost + 
        this.staffCost + 
        this.vehiclesCost ;
    }
  }

}
</script>