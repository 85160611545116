import moment from 'moment-timezone';

/**
 * Creates a function to use as callback for sort operation, makes the comparisson
 * on object String field
 * @param {String} propName: A string representing Object properties, could be nested
 * props if needed.
 * ie: { name: 'Jonh', address: { country: 'United States', city: 'New York' } }
 * to access country, we could pass 'address.country' as argument
 * @returns {Function} callback to use for sort operation
 */
export function sortByStringProp(propName='', order='ascending') {
  const props = propName.split('.');

  return (a, b) => {
    let valueA = props.reduce((obj, prop) => obj? obj[prop]: obj, a);
    let valueB = props.reduce((obj, prop) => obj? obj[prop]: obj, b);

    if (!valueA && !valueB) return 0;
    if (!valueA) return -1;
    if (!valueB) return 1;

    valueA = valueA.toString().toLowerCase();
    valueB = valueB.toString().toLowerCase();
    if(order === 'descending') return valueB?.localeCompare(valueA);
    return valueA?.localeCompare(valueB);
  }
}

/**
 * Creates a function to use as callback for sort operation, makes the comparisson
 * on object Number field
 * @param {String} propName: A string representing Object properties, could be nested
 * props if needed.
 * ie: { name: 'Jonh', address: { country: 'United States', city: 'New York' } }
 * to access country, we could pass 'address.country' as argument
 * @returns {Function} callback to use for sort operation
 */
export function sortByNumberProp(propName='',order = 'ascending') {
  const props = propName.split('.');

  return (a, b) => {
    let valueA = props.reduce((obj, prop) => (obj ? obj[prop] : obj), a);
    let valueB = props.reduce((obj, prop) => (obj ? obj[prop] : obj), b);

    valueA = Number(valueA);
    valueB = Number(valueB);

    if (isNaN(valueA) && isNaN(valueB)) return 0;
    if (isNaN(valueA)) return -1;
    if (isNaN(valueB)) return 1;

    if (order === 'descending') {
      return valueB - valueA;
    } else {
      return valueA - valueB;
    }
  };
}

/**
 * Creates a function to use as callback for sort operation, makes the comparisson
 * on object Number field
 * @param {String} propName: A string representing Object properties, could be nested
 * props if needed.
 * ie: { name: 'Jonh', address: { country: 'United States', city: 'New York' } }
 * to access country, we could pass 'address.country' as argument
 * @returns {Function} callback to use for sort operation
 */
export function sortByPercentProp(propName='',order = 'ascending') {
  const props = propName.split('.');

  return (a, b) => {
    let valueA = props.reduce((obj, prop) => (obj ? obj[prop] : obj), a);
    let valueB = props.reduce((obj, prop) => (obj ? obj[prop] : obj), b);

    valueA = parseFloat(valueA);
    valueB = parseFloat(valueB);

    if (isNaN(valueA) && isNaN(valueB)) return 0;
    if (isNaN(valueA)) return -1;
    if (isNaN(valueB)) return 1;

    if (order === 'descending') {
      return valueB - valueA;
    } else {
      return valueA - valueB;
    }
  };
}

/**
 * Creates a function to use as callback for sort operation, makes the comparisson
 * on object timestamp field
 * @param {String} propName: A string representing Object properties, could be nested
 * props if needed.
 * ie: { name: 'Jonh', address: { country: 'United States', city: 'New York' } }
 * to access country, we could pass 'address.country' as argument
 * @returns {Function} callback to use for sort operation
 */
export function sortByDateProp(propName='',order = 'ascending') {
  const props = propName.split('.');

  return (a, b) => {
    const timestampA = props.reduce((obj, prop) => (obj ? obj[prop] : obj), a);
    const timestampB = props.reduce((obj, prop) => (obj ? obj[prop] : obj), b);

    if (timestampA === null && timestampB === null) return 0;
    if (timestampA === null) return order === 'ascending' ? -1 : 1;
    if (timestampB === null) return order === 'ascending' ? 1 : -1;

    const dateA = moment(timestampA);
    const dateB = moment(timestampB);

    if (order === 'descending') {
      if (dateB.isBefore(dateA)) return -1;
      if (dateB.isAfter(dateA)) return 1;
      return 0;
    } else {
      if (dateA.isBefore(dateB)) return -1;
      if (dateA.isAfter(dateB)) return 1;
      return 0;
    }
  };
}
