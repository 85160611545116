import { mixinMethods } from '@/main'
import {
    onCreateVehicleByGroup,
    onUpdateVehicleByGroup,
    onDeleteVehicleByGroup
} from '../../subscriptions';
import store from '@/store'
import { vehiclesByGroup } from '../../queries'
import { initializeSubscription, disconnectSubscription } from '../helper'

"use strict";
let subscriptionVehicles = null

export const loadVehicles = async function(){
    if(!store.state.userInfo?.tenant?.group) return
    const input = {
        group: store.state.userInfo.tenant.group
    }
    const vehicles = await mixinMethods.gLoadListAll(vehiclesByGroup, input, 'vehiclesByGroup')
    store.dispatch("subscriptionStore/setVehicleList", vehicles)
}

export const vehicleSubscribe = function(instance){
    const queries = {
        onCreateVehicleByGroup,
        onUpdateVehicleByGroup,
        onDeleteVehicleByGroup
    }
    if(!store.state.userInfo?.tenant?.group) return
    const input = {
        group: store.state.userInfo.tenant.group
    }
    initializeSubscription(subscriptionVehicles, queries, input, "subscriptionStore/mutateVehicle", instance)
}

export const config = function(subscriptions){
    const subscription = {}
    subscriptionVehicles = subscriptions['vehicle'] = {
        loadList: loadVehicles,
        subscribe: vehicleSubscribe,
        unsubscribe: (instance) => disconnectSubscription(subscription, instance),
        callback: vehicleEventCallback,
        subscription
    }
}

export const vehicleEventCallback = function(subscriptionState, payload){
    //
}