export const getLabel = /* GraphQL */ `
  query GetLabel($id: ID!) {
    getLabel(id: $id) {
      id
      group
      name
      status
      typeList {
        items {
          id
          group
          type {
            name
          }
          label {
            id
            name
            status
          }
          createdAt
          updatedAt
        }
        nextToken
      }
      items {
        items {
          id
          group
          labelId
          staffId
          vehicleId
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;

export const labelTypeByNameAndGroup = /* GraphQL */ `
  query LabelTypeByNameAndGroup(
    $group: String
    $name: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelLabelTypeFilterInput
    $limit: Int
    $nextToken: String
  ) {
    labelTypeByNameAndGroup(
      group: $group
      name: $name
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        group
        name
        labelList {
          items {
            id
            label{
              id
              name
              group
              status
            }
          }
          nextToken
        }
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;

export const labelsByGroupAndName = /* GraphQL */ `
  query LabelsByGroupAndName(
    $group: String
    $name: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelLabelFilterInput
    $limit: Int
    $nextToken: String
  ) {
    labelsByGroupAndName(
      group: $group
      name: $name
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        group
        name
        status
        createdAt
        updatedAt
        typeList {
          items{
            id
            type {
              id
              name
            }
          }
          nextToken
        }
        items {
          items{
            id
            staff{
              id
              firstName
              group
            }
            vehicle{
              id
              name
              group
            }
          }
          nextToken
        }
      }
      nextToken
    }
  }
`;

// gsi - subscriptions queries

export const labelSubscriptionByStaffLabel = /* GraphQL */ `
  query LabelSubscriptionByStaffLabel(
    $staffId: ID
    $sortDirection: ModelSortDirection
    $filter: ModelLabelSubscriptionFilterInput
    $limit: Int
    $nextToken: String
  ) {
    labelSubscriptionByStaffLabel(
      staffId: $staffId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        group
        labelId
        label {
          id
          name
          status
        }
        staffId
        vehicleId
        createdAt
        updatedAt
        staff {
          id
          group
          firstName
          lastName
          phone
          email
          status
        }
      }
      nextToken
    }
  }
`;
export const labelSubscriptionByVehicleLabel = /* GraphQL */ `
  query LabelSubscriptionByVehicleLabel(
    $vehicleId: ID
    $sortDirection: ModelSortDirection
    $filter: ModelLabelSubscriptionFilterInput
    $limit: Int
    $nextToken: String
  ) {
    labelSubscriptionByVehicleLabel(
      vehicleId: $vehicleId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        group
        labelId
        label {
          id
          name
          status
        }
        staffId
        vehicleId
        createdAt
        updatedAt
        vehicle {
          id
          group
          name
          type
        }
      }
      nextToken
    }
  }
`;
export const labelSubscriptionByLabelId = /* GraphQL */ `
  query LabelSubscriptionByLabelId(
    $labelId: ID
    $sortDirection: ModelSortDirection
    $filter: ModelLabelSubscriptionFilterInput
    $limit: Int
    $nextToken: String
  ) {
    labelSubscriptionByLabelId(
      labelId: $labelId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        group
        labelId
        staffId
        vehicleId
        createdAt
        updatedAt
        label {
          id
          group
          name
          status
          createdAt
          updatedAt
        }
        staff {
          id
          group
          transporterId
          firstName
          lastName
          alternateNames
          status
          phone
          email
          photo
          receiveTextMessages
          receiveEmailMessages
          preferredDaysOff
          createdAt
          updatedAt
        }
        vehicle {
          id
          group
          name
          vehicle
          type
          state
          licensePlateExp
          dateStart
          dateEnd
          createdAt
          updatedAt
        }
      }
      nextToken
    }
  }
`;
export const createLabelSubscription = /* GraphQL */ `
  mutation CreateLabelSubscription(
    $input: CreateLabelSubscriptionInput!
    $condition: ModelLabelSubscriptionConditionInput
  ) {
    createLabelSubscription(input: $input, condition: $condition) {
      id
      group
      labelId
      label {
        id
        group
        name
        status
        typeList {
          nextToken
        }
        items {
          nextToken
        }
        createdAt
        updatedAt
      }
      staffId
      vehicleId
      createdAt
      updatedAt
    }
  }
`;