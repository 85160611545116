import { API, graphqlOperation } from 'aws-amplify';
import { createNotification, createCompanyScoreCard, updateCompanyScoreCard, updateTextractJob, updateStaff } from '@/graphql/mutations.js'
import { createStaffScoreCard, updateStaffScoreCard } from './scorecard-mutations'
import { getStaffTransporterIds, getTenantCoachingMessageSettings } from '@/api/queries.js'
import createPendingMessageRecord from '@/scripts/createPendingMessage'
import createIssuesAndKudos from '@/scripts/createIssuesAndKudos'
import store from '@/store/index'

function cleanTransporterId(transporterId){
    // var clean = transporterId.replace(/o|O/g, '0')
    // clean = clean.replace(/i|l/g, '1')
    // clean = clean.replace(/Q/g, '0')
    var clean = transporterId.replace(/\s+/g, '')
    return clean
}

async function process(job){
    // Throw error to user
    if(job.jobStatus == "ERROR"){
        //Mark textract job as processed
        let input = { 
            id: job.id, 
            isProcessed: true,
            isProcessedS: 'true', // need the string version since booleans can't be sort fields
            group: store.state.userInfo.tenant.group
        }
        await API.graphql(graphqlOperation(updateTextractJob, {input} ));
        
         //Create notification
        input = {
            title: "Unable to process Scorecard. Please try again.",
            description: JSON.parse(job.results).filename ,
            isReadS: "false",
            group: store.state.userInfo.tenant.group,
            clickAction: "dismiss",
            payload: "",
            expirationTTL: Math.floor((new Date().getTime() + 90 * 24 * 60 * 60 * 1000) / 1000)
        }
        await API.graphql(graphqlOperation(createNotification, {input} ))
        return
    }

    var jobId = job.id
    var results = JSON.parse(job.results)
    var fields = results.fields
    fields.week = fields.week.padStart(2, '0')
    var tables = results.tables
    let companyScoreCardId = store.state.userInfo.tenant.id + fields.year + fields.week
    var yearWeek = fields.year+fields.week
    var today = new Date()
    var currentWeek = today.getWeekNumber() - 1
    var currentYear = today.getFullYear()
    
    // deal with week 53 / year switch over
    if(currentWeek === 0){
        currentWeek = 53
        currentYear--
    }
    var scorecardWeek = fields.week
    var scorecardYear = fields.year

    //Create Company Scorecard Record
    let input = {
        id: companyScoreCardId,
        tenantId: store.state.userInfo.tenant.id,
        yearWeek: yearWeek,
        scorecardPdf: job.key,
        group: store.state.userInfo.tenant.group,
        ...fields       
    }
    try{
        //Create new company scorecard record
        await API.graphql(graphqlOperation(createCompanyScoreCard, {input} ));
    }catch(e){
        try{
            //If fails, update record, if that fails cry
            await API.graphql(graphqlOperation(updateCompanyScoreCard, {input} ));
        }catch(e){
            console.error(e)
        }
    }


    //Create Staff Lookup Table
    input = {
        group: store.state.userInfo.tenant.group,
        limit: 500
    }
    var staff = await API.graphql(graphqlOperation(getStaffTransporterIds, input));
    var staffRecords = staff.data.staffsByGroup.items
    var staffLookup = {}
    staffRecords.forEach((staff) => {
        if(staff.transporterId){
            let key = cleanTransporterId(staff.transporterId)
            staffLookup[key] = {id: staff.id, latestScorecard: staff.latestScorecard, keyFocusArea: staff.keyFocusArea}
        }
    })

    // retrieve tenant coaching information to create messages
    input = {
        id: store.state.userInfo.tenant.id
    }
    var tenant = await API.graphql(graphqlOperation(getTenantCoachingMessageSettings, input));
    tenant = tenant.data.getTenant

    //Create StaffScoreCard records
    var table = tables[0]
    var noMatchCount = 0;
    // console.log(table);
    // table.table_data.forEach(async (row) => {        
    await Promise.all(table.table_data.map(async (row) => {        
        // skip results that don't have transporter ID and name, week 7 2021 started having issues with header row
        if(row.transporter_id && row.name && row.delivered){
            let scoreCardId = store.state.userInfo.tenant.id + row.transporter_id + fields.year + fields.week

            //Format transporter id for better lookup results
            if( row.transporter_id ){
                var transporterId = cleanTransporterId(row.transporter_id)
            }else{
                var transporterId = null
            }

            //Get Staff ID info
            if(staffLookup[transporterId]){
                var staffId = staffLookup[transporterId].id
                var staffKeyFocusArea = staffLookup[transporterId].keyFocusArea
                var staffLatestScorecard = staffLookup[transporterId].latestScorecard
            }else{
                var staffId = null
            }
            let isMatched = staffId != null

            
            //Track how many have no staff id
            if(!isMatched){
                noMatchCount++
            }

            var scoreCardInput = {
                input: {
                    id: scoreCardId,
                    group: store.state.userInfo.tenant.group,
                    matched: isMatched,
                    matchedS: isMatched ? 'true' : 'false',
                    week: fields.week,
                    year: fields.year,
                    name: row.name,
                    messageHasBeenSent: false,
                    transporterId: row.transporter_id,
                    overallTier: row.overal_tier,
                    delivered: row.delivered,
                    keyFocusArea: row.key_focus_area,
                    ficoScore: row.fico_score,
                    seatbeltOffRate: row.seatbelt_off_rate,
                    dcr: row.dcr,
                    dar: row.dar,
                    swcPod: row.swc_pod,
                    swcCc: row.swc_cc,
                    swcSc: row.swc_sc,
                    swcAd: row.swc_ad,
                    dnrs: row.dnrs,
                    podOpps: row.pod_opps,
                    ccOpps: row.cc_opps,
                    speedingEventRate: row.speeding_event_rate,
                    distractionsRate: row.distractions_rate,
                    followingDistanceRate: row.following_distance_rate,
                    signSignalViolationsRate: row.sign_signal_violations_rate,
                    harshBrakingRate: row.harsh_braking, 
                    harshCorneringRate: row.harsh_cornering
                }
            }

            // console.log(scoreCardInput)

            //Add staffid to input if available
            if( staffId ){
                scoreCardInput.input.staffScoreCardStaffId = staffId
            }

            try{
                //Create new staff scorecard record
                var result = await API.graphql(graphqlOperation(createStaffScoreCard, scoreCardInput ));
            }catch(e){
                try{
                    delete scoreCardInput.input.messageHasBeenSent // dont update if a message has been sent
                    //If fails, update record, if that fails cry
                    var result = await API.graphql(graphqlOperation(updateStaffScoreCard, scoreCardInput ));
                }catch(e){
                    console.error(e)
                }
            }

            // Update staff with latest scorecard yearweek
            if( isMatched && staffLatestScorecard <= yearWeek){
                    var input = {
                        id: staffId,
                        latestScorecard: yearWeek,
                        keyFocusArea: row.key_focus_area,
                        keyFocusAreaCompleted: false
                    }
                try{
                    await API.graphql(graphqlOperation(updateStaff, {input} ));
                }catch(e){
                    console.error(e)
                }

            }

            // create or update pending coaching message with latest scorecard if the scorecard
            // is from the current week
            if( isMatched && (currentWeek == scorecardWeek) && (currentYear == scorecardYear)){
                await createPendingMessageRecord(scoreCardInput, null, null, tenant)
            }

            //create da issues and kudos
            if(isMatched){
                await createIssuesAndKudos(scoreCardInput, null, null, tenant, null)
            }
        }
    }))


    //Mark textract job as processed
    input = { 
        id: jobId, 
        isProcessed: true,
        isProcessedS: 'true', // need the string version since booleans can't be sort fields
        group: store.state.userInfo.tenant.group
     }
    await API.graphql(graphqlOperation(updateTextractJob, {input} ));


    //Create notification
    if(noMatchCount == 0){
        input = {
            title: "Scorecard Week " + fields.week + "-" + fields.year + " parsing completed",
            description: "Click here to dismiss",
            isReadS: "false",
            group: store.state.userInfo.tenant.group,
            clickAction: "dismiss",
            payload: "",
            expirationTTL: Math.floor((new Date().getTime() + 90 * 24 * 60 * 60 * 1000) / 1000)
        }
    }else{
        input = {
            title: "Scorecard Week " + fields.week + "-" + fields.year + " parsing completed with " + noMatchCount + " errors",
            description: "Click here to manually fix issues",
            isReadS: "false",
            group: store.state.userInfo.tenant.group,
            clickAction: "navigate",
            payload: JSON.stringify({name: 'ScoreCardIndex'}),
            expirationTTL: Math.floor((new Date().getTime() + 90 * 24 * 60 * 60 * 1000) / 1000)
        }
    }
    await API.graphql(graphqlOperation(createNotification, {input} ))
}

Date.prototype.getWeekNumber = function(){
    var d = new Date(Date.UTC(this.getFullYear(), this.getMonth(), this.getDate()));
    var dayNum = d.getUTCDay() || 7;
    d.setUTCDate(d.getUTCDate() + 4 - dayNum);
    var yearStart = new Date(Date.UTC(d.getUTCFullYear(),0,1));
    return Math.ceil((((d - yearStart) / 86400000) + 1)/7)
};


export default process