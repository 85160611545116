import { API, graphqlOperation } from 'aws-amplify';
import { getTenant } from "@/scripts/queries/queries.js";

export const getTenantData = async function (tenant) {

    const { id }  = tenant
    const input = { id }
    let result;

    try {
        result = await API.graphql(graphqlOperation(getTenant, input))
    } catch (error) {
        console.error(error)
        result = {}
    }

    return result.data?.getTenant || {}
}

export default {
    getTenantData
}