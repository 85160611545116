var render = function render(){var _vm=this,_c=_vm._self._c;return _c('el-table',{staticStyle:{"width":"100%"},attrs:{"data":_vm.data,"show-header":_vm.showHeader}},[_c('el-table-column',{attrs:{"prop":"option","width":"240","label":"OPTION"},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [_c('span',{staticClass:"ml-8 text-sm"},[_vm._v(_vm._s(scope.row.option))])]}}])}),_c('el-table-column',{attrs:{"label":"DEFAULT","width":"50"}}),_c('el-table-column',{attrs:{"prop":"usedFor","width":"200","label":"USED FOR"},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [(_vm.type == 'incident')?_c('div',[_vm._v(" "+_vm._s(`${scope.row.accidents.items.length} ${_vm.pluralize(scope.row.accidents.items.length, 'Incident', 'Incidents')}`)+" ")]):(_vm.type == 'dock-door' || _vm.type == 'route-type')?_c('div',[_vm._v(" "+_vm._s(`${scope.row.usedFor} ${_vm.pluralize(scope.row.usedFor, 'Route', 'Routes')} • ${scope.row.daysCount} ${_vm.pluralize(scope.row.usedFor, 'Day', 'Days')}`)+" ")]):_vm._e()]}}])}),_c('el-table-column',{attrs:{"prop":"action","label":"ACTION"},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [(scope.row.usedFor > 0 && scope.row.canBeEdited)?_c('i',{staticClass:"uil uil-repeat text-xl flex-auto self-center cursor-pointer",on:{"click":function($event){return _vm.repeat(
            _vm.type,
            scope.row.option,
            scope.row.usedFor,
            scope.row.daysCount,
            scope.row.accidents
          )}}}):_vm._e()]}}])})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }