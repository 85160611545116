<template>
  <div class="month-picker-filter mx-2">
    <span class="text-sm mr-2">Month</span>
    <el-date-picker
      v-model="filter"
      :type="type"
      :placeholder="placeholder"
      @change="handleDate"
      :picker-options="options"
    />
  </div>
</template>
<script>
export default {
  name: "MonthPickerFilter",
  props: {
    type: {
      type: String,
      required: true,
      default: 'month'
    },
    placeholder: {
      type: String,
      required: false,
      default: ''
    },
    default: {
      type: Date,
      required: false,
      default: null
    },
    param: {
      type: String,
      required: true,
      default: 'month'
    },
    options: {
      type: Object,
      required: false,
      default: null
    }
  },
  data() {
    return {
      filter: null,  
    }
  },
  watch:{
    default( newValue ){
      this.filter = newValue
    }
  },
  created() {
    this.filter = this.default;
  },
  methods: {
    handleDate (e) {
      const param = this.param
      this.$emit( 'update-filters', { 
        filter: e,
        param
      })
    },
    disabled(date) {
      return true;
    }
  }
}
</script>
<style lang="scss" scoped>

  .month-picker-filter {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }

</style>