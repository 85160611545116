<template>
  <el-table :data="data" style="width: 100%" :show-header="showHeader">
    <el-table-column prop="option" width="240" label="OPTION">
      <template slot-scope="scope">
        <span class="ml-8 text-sm">{{ scope.row.option }}</span>
      </template>
    </el-table-column>
    <el-table-column label="DEFAULT" width="50"></el-table-column>
    <el-table-column prop="usedFor" width="200" label="USED FOR">
      <template slot-scope="scope">
        <div v-if="type == 'incident'">
          {{ `${scope.row.accidents.items.length} ${pluralize(scope.row.accidents.items.length, 'Incident', 'Incidents')}` }}
        </div>
        <div v-else-if="type == 'dock-door' || type == 'route-type'">
          {{ `${scope.row.usedFor} ${pluralize(scope.row.usedFor, 'Route', 'Routes')} • ${scope.row.daysCount} ${pluralize(scope.row.usedFor, 'Day', 'Days')}` }}
        </div>
      </template>
    </el-table-column>
    <el-table-column prop="action" label="ACTION">
      <template slot-scope="scope">
        <i
          v-if="scope.row.usedFor > 0 && scope.row.canBeEdited"
          class="uil uil-repeat text-xl flex-auto self-center cursor-pointer"
          @click="
            repeat(
              type,
              scope.row.option,
              scope.row.usedFor,
              scope.row.daysCount,
              scope.row.accidents
            )
          "
        ></i>
      </template>
    </el-table-column>
  </el-table>
</template>

<script>
export default {
  props: {
    data: {
      type: Array,
      default: () => [],
    },
    type: {
      type: String,
      default: "",
    },
    showHeader: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {};
  },
  methods: {
    repeat(type, name, userFor, daysCount, accidents) {
      let typeName = userFor == 1 ? "Incident" : "Incidents";
      if (type == "incident") {
        let content = `${accidents.items.length} ${typeName}`;
      }
      this.$emit("visibilityTableFixed", true);
    },
  },
};
</script>
