export const listTenants = /* GraphQL */ `
  query ListTenants(
    $filter: ModelTenantFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listTenants(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        group
        createdAt
        companyName
        trialExpDate
        accountPremiumStatus
        users {
          items {
            id
          }
        }
        premiumStatusHistory(sortDirection: DESC) {
          items {
            id
            accountPremiumStatus
            createdAt
            updatedAt
          }
        }
      }
      nextToken
    }
  }
`;

export const listInvoices = /* GraphQL */ `
  query ListInvoices(
    $filter: ModelInvoiceFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listInvoices(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        group
        month
        year
        invoiceTotal
      }
      nextToken
    }
  }
`;

export const invoicesByStatus = /* GraphQL */ `
  query InvoicesByStatus(
    $status: String
    $yearMonth: ModelInvoiceByStatusCompositeKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelInvoiceFilterInput
    $limit: Int
    $nextToken: String
  ) {
    invoicesByStatus(
      status: $status
      yearMonth: $yearMonth
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        createdAt
        group
        month
        year
        invoiceTotal
        tenant {
            id
            companyName
        }
        status
      }
      nextToken
    }
  }
`;

export const messagesByGroup = /* GraphQL */ `
  query MessagesByGroup(
    $group: String
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelMessageFilterInput
    $limit: Int
    $nextToken: String
  ) {
    messagesByGroup(
        group: $group
        createdAt: $createdAt
        sortDirection: $sortDirection
        filter: $filter
        limit: $limit
        nextToken: $nextToken
    ) {
        items {
            id
            channelType
            createdAt
            updatedAt
            messageType
        }
        nextToken
    }
  }
`;
