<template>
  <div>
    <div class="flex items-center justify-between">
      <h1>Billing Settings</h1> 
    </div>
    <div class="mt-4 grid grid-cols-8 grid-flow-row gap-4">

      <div class="col-span-4 p-4 relative border-2 rounded-lg shadow">
        <div class="float-left">
          <p class="inline-block text-gray-600 text-base font-bold">COST DEFAULTS</p>
        </div>
        <div class="flex space-x-4 justify-end">
          <i 
            @click="handleEdit()"
            class="icon-button hover:text-brand uil uil-pen"/>
        </div>
        <el-form label-position="top" size="small">
          <el-form-item label="Standard" prop="defaultCostStandard" class="non-edit-field" :class="{'placeholder': !system.defaultCostStandard}">
            {{ system.defaultCostStandard | text }}
          </el-form-item>
          <el-form-item label="Bundle Discount" prop="defaultBundleDiscount" class="non-edit-field" :class="{'placeholder': !system.defaultCostStandard}">
            {{ system.defaultCostBundle | text }}
          </el-form-item>
          <el-form-item label="Premium Performance" prop="defaultCostPerformance" class="non-edit-field" :class="{'placeholder': !system.defaultCostPerformance}">
            {{ system.defaultCostPerformance | text }}
          </el-form-item>
          <el-form-item label="Premium Rostering" prop="defaultCostRostering" class="non-edit-field" :class="{'placeholder': !system.defaultCostRostering}">
            {{ system.defaultCostRostering | text }}
          </el-form-item>
          <el-form-item :label="`Premium ${$t('label.associate')} Management`" prop="defaultCostStaff" class="non-edit-field" :class="{'placeholder': !system.defaultCostStaff}">
            {{ system.defaultCostStaff | text }}
          </el-form-item>
          <el-form-item label="Premium Vehicles" prop="defaultCostVehicles" class="non-edit-field" :class="{'placeholder': !system.defaultCostVehicles}">
            {{ system.defaultCostVehicles | text }}
          </el-form-item>
        </el-form>
      </div>

    </div>

    <!-- FORM -->
    <el-dialog
      title="Edit Cost Defaults"
      :visible.sync="modalOpen"
      width="30%"
      :close-on-click-modal="false"
      >

      <BillingForm :formFields="editSystem"/>

      <span slot="footer" class="dialog-footer">
        <el-button @click="modalOpen = false">Cancel</el-button>
        <el-button type="primary" @click="updateSystem">Update</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>


import {API} from 'aws-amplify'
import { listSystems } from './queries'
import { updateSystem, createSystem } from '@/graphql/mutations'
import BillingForm from './components/BillingForm'

export default {
  name: 'Billing',
  components: {
    BillingForm
  },

  data() {
    return {
      loading: false,
      modalOpen: false,
      system: {},
      editSystem:{},
    }
  },
  mounted(){
    this.loadData()
  },

  methods: {
    /**
     * Load data using the listTenants custom GraphQL query
     */
    async loadData(){
      this.loading = true
      try{
        var system = await this.api(listSystems)
        system = system.data.listSystems.items[0]
        if(!system) system = {}
        this.system = system
        this.loading = false
      }catch(e){
        console.error(e)
        this.loading = false
        this.displayUserError(e)
      }
    },

    handleEdit(){
      let system = {...this.system}
      this.editSystem = system     
      this.modalOpen = true        
    },

    async updateSystem(){
      try {
        let input = {
          ...this.editSystem,
          group: 'system'
        }
        let result
        if(input.id != null && input.id != undefined){
          result = await this.api(updateSystem, {input})
        }
        else{
          result = await this.api(createSystem, {input})
        }

        this.modalOpen = false
        this.loadData()

        this.$message({
          type: 'success',
          message: 'System Updated'
        });


      } catch(e) {
        console.error(e)
        this.displayUserError(e)         
      }
    }
  }
}
</script>