import {
    onCreateOptionsCustomListsStaffByGroup,
    onDeleteOptionsCustomListsStaffByGroup
} from '../../subscriptions';
import store from '@/store'
import { initializeSubscription, disconnectSubscription } from '../helper'

"use strict";
let subscriptionOptionsCustomListsStaff = null

export const optionsCustomListsStaffSubscribe = function(instance){
    const queries = {
        onCreateOptionsCustomListsStaffByGroup,
        onDeleteOptionsCustomListsStaffByGroup
    }
    if(!store.state.userInfo?.tenant?.group) return
    const input = {
        group: store.state.userInfo.tenant.group
    }
    initializeSubscription(subscriptionOptionsCustomListsStaff, queries, input, "subscriptionStore/handleOptionsCustomListsStaff", instance)
}

export const config = function(subscriptions){
    const subscription = {}
    subscriptionOptionsCustomListsStaff = subscriptions['optionsCustomListsStaff'] = {
        loadList: null,
        subscribe: optionsCustomListsStaffSubscribe,
        unsubscribe: (instance) => disconnectSubscription(subscription, instance),
        callback: optionsCustomListsStaffEventCallback,
        subscription
    }
}

const findAssociate = (list, staffId) => list.find(associate => associate.id === staffId)

const addAuthorizeToDrive = (optionsCustomlistsStaff, eventName, storedAssociate) => {

    if(!storedAssociate) return
    !storedAssociate.authorizedToDrive && (storedAssociate.authorizedToDrive = { items: [] })
    const optionsCustomListsStaffs = storedAssociate.authorizedToDrive.items

    switch(eventName){
        case 'onCreate':{
            const exists = optionsCustomListsStaffs.some(ocls => ocls.id === optionsCustomlistsStaff.id)
            if(!exists){
                optionsCustomListsStaffs.push(optionsCustomlistsStaff)
            }
            break
        }
        case 'onDelete':{
            const index = optionsCustomListsStaffs.findIndex(ocls => ocls.id === optionsCustomlistsStaff.id)
            if(index > -1){
                const deleted = optionsCustomListsStaffs.splice(index, 1)
            }
            break
        }
    }
}

export const optionsCustomListsStaffEventCallback = function(subscriptionState, {data: optionsCustomlistsStaff, eventName}){
    let storedAssociate = findAssociate(subscriptionState.associateList, optionsCustomlistsStaff.staff.id)
    if(storedAssociate){
        addAuthorizeToDrive(optionsCustomlistsStaff, eventName, storedAssociate)
    }else{
        setTimeout(()=>{
            storedAssociate = findAssociate(subscriptionState.associateList, optionsCustomlistsStaff.staff.id)
            addAuthorizeToDrive(optionsCustomlistsStaff, eventName, storedAssociate)
        }, 1000)
    }
}