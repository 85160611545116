<template>
  <div class="mb-4" v-loading="loading">
    <h3 class="mb-1">{{header}}</h3>

    <p v-if="vlKey =='service-location'" class="text-xs text-gray-600 italic mb-2">EX: Jiffy Lube - 123 Main Street</p>
      <el-tag
        :key="tag.id"
        type="info"
        effect="plain"
        v-for="tag in defaultItems"
        :disable-transitions="false"
        class="cursor-text"
        >
        {{tag.value}}
      </el-tag>

      <el-tag
        :key="tag.id"
        v-for="tag in customItems"
        :disable-transitions="false"
        @click="handleClick(tag)"
        >
        {{tag.value}}
      </el-tag>

    
      <el-input
        class="input-new-tag"
        v-if="inputVisible"
        v-model="inputValue"
        ref="saveTagInput"
        size="mini"
        placeholder="Add Item"
        @keyup.enter.native="handleInputConfirm"
        @blur="handleInputConfirm"
      >
      </el-input>
      <el-button v-else-if="vlKey != 'roster-status' && vlKey != 'staff-status' && vlKey != 'maintenance-status' && vlKey != 'device-status' && vlKey != 'vehicle-status'" class="button-new-tag" size="small" @click="showInput">+ New Dropdown Item</el-button>
          
    

    <el-dialog
      title="Edit"
      :visible.sync="dialogVisible"
      width="30%"
      :close-on-click-modal="false"
      :before-close="closeDialog"
      >
      <div v-loading="loading" class="text-right">
        <el-input type="text" v-model="currentTag.value"/>
        <div slot="footer" class="dialog-footer mt-6">
          <el-button class="float-left" type="danger" @click="deleteItem">Delete</el-button>
          <el-button @click="closeDialog">Cancel</el-button>
          <el-button type="primary" @click="updateItem">Save</el-button>
        </div>
      </div>
    </el-dialog>

  </div>
</template>


<script>

import { getValueListItemUpdatedTimestamp } from '@/utilities/timestampQueries'
import { createValueListItem, deleteValueListItem, updateValueListItem } from '@/graphql/mutations'
import loadValuelists from '@/scripts/loadValueLists'
import { mapState } from 'vuex'

  export default {
    props:['header', 'valueListId', 'items', 'vlKey'],
    data() {
      return {
        currentTag:{},
        inputVisible: false,
        inputValue: '',
        dialogVisible: false,
        adding: false,
        loading: false
      };
    },
    computed:{
      ...mapState(['userInfo']),
      /** Items array filtered to only show custom items */
      customItems(){
        return this.items.filter(item=>item.custom == true && !item.deleted)
      },

      defaultItems(){
        return this.items.filter(item=>item.custom == false && !item.deleted && !item.hidden)
      }
    },
    methods: {
      handleClick(tag){
        this.currentTag = {...tag}
        this.dialogVisible = true
      },

      showInput() {
        this.inputVisible = true;
        this.$nextTick(_ => {
          this.$refs.saveTagInput.$refs.input.focus();
        });
      },

      async handleInputConfirm() {
        this.loading = true;
        try{
          if(this.adding || !this.inputValue){
            this.inputVisible = false
            return
          }
          this.adding = true
          this.inputVisible = false
          var value = this.toTitleCase(this.inputValue).trim()
          var isUnique = await this.isUniqueInArray(value, 'value', this.valueListActivesOnly(this.items))
          if(!isUnique){
            this.displayUserError('Item already exists')
            this.adding = false
            this.inputValue = ''
            return
          }
          let input = {
            value: value, 
            valueListItemValueListId: this.valueListId, 
            custom: true,
            group: this.$store.state.userInfo.tenant.group
          }
          await this.api(createValueListItem, {input})
          loadValuelists(this, this.userInfo.tenant.id)
        }catch(e){
          console.error(e)
          this.adding = false
          this.loading = false;
          this.displayUserError(e)
        }finally{
          this.adding = false
          this.inputValue = ''
          this.loading = false;
        }
      },
      async deleteItem(){
        try{
          //const result = await this.api(deleteValueListItem, {input:{ id: this.currentTag.id }} )
          var input = {...this.currentTag}
          input.deleted = true
          const result = await this.api(updateValueListItem, {input: input}, getValueListItemUpdatedTimestamp, 'getValueListItem')
          this.currentTag = {}
          this.dialogVisible = false
          loadValuelists(this, this.userInfo.tenant.id)
        }catch(e){
          console.error(e)
          this.displayUserError(e)
        }
      },
      async updateItem(){
        this.loading = true;
        try{
          let input = this.currentTag;
          const result = await this.api(updateValueListItem, {input}, getValueListItemUpdatedTimestamp, 'getValueListItem')
          this.currentTag = {};
          loadValuelists(this, this.userInfo.tenant.id);
        }catch(e){
          console.error(e)
          this.displayUserError(e);
          this.loading = false;
        }finally{
          this.dialogVisible = false;
          this.loading = false;
        }
      },
      closeDialog(){
        this.dialogVisible = false
      }
    }
  }
</script>

<style scoped>
  span.el-tag {
      margin-right: 8px;
      margin-bottom: 8px;
      cursor: pointer;
  }
   
  .input-new-tag{
    display: inline-block;
    width: 300px;
  }
</style>