import { Auth, API } from 'aws-amplify';

var sendInternalMessage = async function({to, subject, bodyText, bodyHtml} ) {


    let apiName = 'SendInternalMessage';
    let path = '/sendinternalmessage';

    let post = {

        body: {
          to: to,
          subject: subject,
          bodyHtml: bodyHtml,
          bodyText: bodyText
        }
      }

      API.post(apiName, path, post)

}

export default sendInternalMessage