<template>
    <div class="text-center">
        <p class="text-center break-normal mb-4">
            To {{ action }} you will need {{ text }} the premium {{ premiumFeature }} add-on. 
            <!-- Click <button @click="goToAccountDetails" class="text-primary">here</button> to upgrade your account. -->
        </p>
        <div class="flex">
            <el-button v-if="modal" @click="closeUpgradeDialog" type="default" plain class="flex-1 rounded-lg shadow" size="small">
                Cancel
            </el-button>
            <el-button @click="goToAccountDetails" type="primary" class="flex-1 rounded-lg shadow" size="small">
                Upgrade
            </el-button>
        </div>
    </div>
</template>
<script>
export default {
    props: ['action', 'premiumFeature', 'modal', 'text'],
    methods:{
        goToAccountDetails(){
            this.$emit('go-to-account-details');
            this.$router.push({name: 'SettingsAccountDetails', params:{navigateToAddOnSelection: true}})
        },
        closeUpgradeDialog(){
            this.$emit('close-dialog')
        }
    }
}
</script>