<template>
  <div>
    <!-- This selector is for testing purposes only
    <el-select v-model="list.value" v-for="(list, name) in valuelists" :vlKey="name" :placeholder="name">
        <el-option
            v-for="item in valueListActivesOnly(list.items)"
            :vlKey="item.value"
            :label="item.value"
            :value="item.value">
        </el-option>
    </el-select> -->
    <h1 class="mb-4">Drop-Down Lists</h1>

    <!-- STAFF -->
    <el-collapse v-model="collapseActive">
        <el-collapse-item :title="$t('label.associates')" name="Staff">
          <!-- <DropDownEditor header="Amazon Profile" :valueListId="valuelists['amazon-profile'].id" :items="valuelists['amazon-profile'].items"/> -->
          <DropDownEditor header="Genders" vlKey="genders"  :valueListId="valuelists['genders'].id" :items="valuelists['genders'].items"/>
          <DropDownEditor header="Hourly Status" vlKey="hourly-status"  :valueListId="valuelists['hourly-status'].id" :items="valuelists['hourly-status'].items"/>
          <DropDownEditor header="Pronouns" vlKey="pronouns"  :valueListId="valuelists['pronouns'].id" :items="valuelists['pronouns'].items"/>
          <!--
          #####
          IF YOU CHANGE THE ROSTER STATUS "ON TIME" TO ANOTHER VALUE YOU WILL HAVE TO UPDATE ReplaceByForm.vue AND Routes.vue AS WELL.
          #####
          -->
          <DropDownEditor :header="`${$t('label.associate')} Status`" vlKey="staff-status" :valueListId="valuelists['staff-status'].id" :items="valuelists['staff-status'].items"/>
          <DropDownEditor header="Uniform Types" vlKey="uniform-type"  :valueListId="valuelists['uniform-type'].id" :items="valuelists['uniform-type'].items"/>
        </el-collapse-item>

    <!-- VEHICLE -->
        <el-collapse-item title="Vehicles" name="Vehicle">
          <!-- <DropDownEditor header="Accident Types" :valueListId="valuelists['accident-types'].id" :items="valuelists['accident-types'].items"/> -->
          <!-- <DropDownEditor header="Vehicle Providers" :valueListId="valuelists['vehicle-providers'].id" :items="valuelists['vehicle-providers'].items"/> -->
          <DropDownEditor header="Vehicle Status" vlKey="vehicle-status" :valueListId="valuelists['vehicle-status'].id" :items="valuelists['vehicle-status'].items"/>
        </el-collapse-item>  

    <!-- VEHICLE MAINTENANCE -->
        <el-collapse-item title="Vehicle Maintenance" name="VehicleMaintenance">
          <!-- <DropDownEditor header="Accident Types" :valueListId="valuelists['accident-types'].id" :items="valuelists['accident-types'].items"/> -->
          <DropDownEditor header="Maintenance Services" vlKey="vehicle-maintenance-services" :valueListId="valuelists['vehicle-maintenance-services'].id" :items="valuelists['vehicle-maintenance-services'].items"/>
          <DropDownEditor header="Maintenance Status" vlKey="maintenance-status" :valueListId="valuelists['maintenance-status'].id" :items="valuelists['maintenance-status'].items"/>
          <!-- <DropDownEditor header="Vehicle Providers" :valueListId="valuelists['vehicle-providers'].id" :items="valuelists['vehicle-providers'].items"/> -->
          <DropDownEditor header="Service Locations" vlKey="service-location" :valueListId="valuelists['service-location'].id" :items="valuelists['service-location'].items"/>
        </el-collapse-item>

    <!-- DEVICE -->
        <el-collapse-item title="Devices" name="Device">
          <DropDownEditor header="Device Carrier" vlKey="device-carrier" :valueListId="valuelists['device-carrier'].id" :items="valuelists['device-carrier'].items"/>
          <DropDownEditor header="Device Status" vlKey="device-status" :valueListId="valuelists['device-status'].id" :items="valuelists['device-status'].items"/>
        </el-collapse-item>


    </el-collapse>

  </div>
</template>


<script>
import { mapState } from 'vuex'

import DropDownEditor from './components/DropDownEditor'

  export default {
    components: {DropDownEditor},
    computed: {
      ...mapState(['valuelists'])
    },
    data(){
      return{
        collapseActive: ['Staff', 'Vehicle', 'Device', 'Coaching', 'VehicleMaintenance' ]
      }
    },
    created(){
      var params = this.$route.params
      if( params.list == 'coaching'){
        this.collapseActive = ['Coaching']
      }
      else if( params.list == 'staff'){
        this.collapseActive = ['Staff']
      }
    }
  }
</script>
