<template>
    <el-tooltip class="item" effect="dark" placement="top-start" v-if="tooltipHasVisible" popper-class="custom-tooltip-higher-z-index">
        <template slot="content">
            <slot name="tooltip" :expandedValue="value" :contractedValue="contractedValue">
                {{ value | text}}
            </slot>
        </template>
        <slot :textValue="value">
            <span v-if="kind && (kind === 'float' || kind === 'percent')">
                {{ value | float({kind}) }}
            </span>
            
            <span v-else>{{ value | text }}</span>
        </slot>
    </el-tooltip>
    <span v-else>
        <slot :textValue="value">
            <span v-if="kind && (kind === 'float' || kind === 'percent')">
                {{ value | float({kind}) }}
            </span>

            <span v-else>
                {{ value | text }}
            </span>
        </slot>
    </span>
</template>
<script>
export default {
    model:{
        prop: 'value',
        event: 'input'
    },
    props: [
        'value',
        'maxLength',
        'forceVisible',
        'kind',
    ],
    computed: {
        safeMaxLength(){
            return this.maxLength || 20
        },
        contractedValue(){
            if(this.forceVisible && this.value.length <= this.safeMaxLength )
                return this.value
            return this.value.substr(0, this.safeMaxLength)+"…"
        },
        tooltipHasVisible(){
            if(this.forceVisible)
                return true
            if(!this.value)
                return false
            if (this.value.length > this.safeMaxLength) {
                return true
            }
            return false
        }
    },
}
</script>