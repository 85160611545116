<template>
  <div v-loading="loading">
    <h1 class="mb-4">Daily Rostering</h1>
    <options-displayed-card :title="rosteredRoutesCardTitle" :description="rosteredRoutesCardDescription"
                            @update-column="updateRosteredRouteColumn" @change-index="changeIndex"
                            @open-drawer-custom-list="openDrawerCustomList"
                            :loading="loading"
                            :columns-not-being-displayed="rosteredRoutesColumnsNotBeingDisplayed"
                            :columns-being-displayed="rosteredRoutesColumnsBeingDisplayed" type="for-routes"/>

    <options-displayed-card :title="rosteredVehiclesCardTitle" :description="rosteredVehiclesCardDescription"
                            @update-column="updateRosteredRouteColumn" @change-index="changeIndex"
                            @open-drawer-custom-list="openDrawerCustomList"
                            :loading="loading"
                            :columns-not-being-displayed="rosteredVehiclesColumnsNotBeingDisplayed"
                            :columns-being-displayed="rosteredVehiclesColumnsBeingDisplayed" type="for-vehicles"/>

    <options-displayed-card :title="rosteredAssociatesCardTitle" :description="rosteredAssociatesCardDescription"
                            @update-column="updateRosteredRouteColumn" @change-index="changeIndex"
                            @open-drawer-custom-list="openDrawerCustomList"
                            :loading="loading"
                            :columns-not-being-displayed="rosteredAssociatesColumnsNotBeingDisplayed"
                            :columns-being-displayed="rosteredAssociatesColumnsBeingDisplayed"
                            type="for-associates"/>

    <div class="border p-4 my-4 rounded-md shadow">
      <p class="text-gray-600 text-base font-medium mr-2">Assigning Vehicles to Routes</p>
      <div class="my-4">
        <small>When assigning a vehicle to route on the Daily Roster, you can optionally specify the role the vehicle is playing on that route. These roles can be customized here.</small>
      </div>
      <div>
        <el-button type="primary" size="small" @click="openDrawerCustomList('vehicle-role-for-route')" data-cy="vehicle-role-for-route">Edit Vehicle Roles for Routes</el-button>
      </div>
    </div>

    <DrawerCustomList 
      :drawerVisibility="drawerVisibility"
      :titleDrawer="titleDrawer"
      :tableDataSelected="tableDataSelected"
      @drawerHide="drawerHide"
      @reloadCustomList="reloadCustomList"
    />
  </div>
</template>

<script>
import {mapState} from 'vuex'
import { dailyRostersColumnsByGroup } from '@/graphql/queries'
import * as dataCards from './dataCards'
import OptionsDisplayedCard from "@/views/Settings/DailyRoster/OptionsDisplayedCard";
import DrawerCustomList from "@/views/Settings/CustomLists/components/DrawerCustomList";

export default {
  name: 'SettingsDailyRoster',
  components: {
    OptionsDisplayedCard,
    DrawerCustomList,
  },
  data() {
    return {
      loading: false,
      isDragging: false,
      delayedDragging: false,
      rosterColumnsCheck: false,
      rosterColumns: [],
      rosteredRoutesCardTitle: dataCards.rosteredRoutesCardTitle,
      rosteredRoutesCardDescription: dataCards.rosteredRoutesCardDescription,
      rosteredVehiclesCardTitle: dataCards.rosteredVehiclesCardTitle,
      rosteredVehiclesCardDescription: dataCards.rosteredVehiclesCardDescription,
      rosteredAssociatesCardTitle: dataCards.rosteredAssociatesCardTitle,
      rosteredAssociatesCardDescription: dataCards.rosteredAssociatesCardDescription,
      dailyRosterColumns: [],
      titleDrawer: "Dropdown options",
      drawerVisibility: false,
      tableDataSelected: {}
    }
  },
  async mounted() {
    await this.getRosteredColumns()
    await this.loadCustomList()
  },
  computed: {
    ...mapState(['userInfo', 'customList']),
    rosteredRoutesColumnsBeingDisplayed() {
      return this.dailyRosterColumns.filter(item => item.isDisplayed && item.displayedFor === 'for-routes').sort((a, b) => a.order - b.order)
    },
    rosteredRoutesColumnsNotBeingDisplayed() {
      return this.dailyRosterColumns.filter(item => !item.isDisplayed && item.displayedFor === 'for-routes')
    },
    rosteredVehiclesColumnsBeingDisplayed() {
      return this.dailyRosterColumns.filter(item => item.isDisplayed && item.displayedFor === 'for-vehicles').sort((a, b) => a.order - b.order)
    },
    rosteredVehiclesColumnsNotBeingDisplayed() {
      return this.dailyRosterColumns.filter(item => !item.isDisplayed && item.displayedFor === 'for-vehicles')
    },
    rosteredAssociatesColumnsBeingDisplayed() {
      return this.dailyRosterColumns.filter(item => item.isDisplayed && item.displayedFor === 'for-associates').sort((a, b) => a.order - b.order)
    },
    rosteredAssociatesColumnsNotBeingDisplayed() {
      return this.dailyRosterColumns.filter(item => !item.isDisplayed && item.displayedFor === 'for-associates')
    },
  },
  methods: {
    async getRosteredColumns() {
      this.loading = true
      try {
        let input = {
          group: this.$store.state.userInfo.tenant.group
        }
        this.dailyRosterColumns = await this.gLoadListAll(dailyRostersColumnsByGroup, input, 'dailyRostersColumnsByGroup')
      } catch (error) {
        console.error("error: ", error)
      } finally {
        this.loading = false
      }
    },
    async updateRosteredRouteColumn(item) {

      let params = ''
      let body = ''
      let input = {}
      let inputCount = 1

      let initIndexFromAfterOptionChanged = item.itemIndex
      this.loading = true

      this.dailyRosterColumns = this.dailyRosterColumns.map(obj => {
        if (obj.id === item.id) {
          switch (item.from) {
            case 'displayed':

              obj.isDisplayed = false 
              obj.order = null 

              params += `$input${inputCount}: UpdateDailyRostersColumnsInput!\n$condition${inputCount}: ModelDailyRostersColumnsConditionInput\n`
              body += `mutation${inputCount}: updateDailyRostersColumns(input: $input${inputCount}, condition: $condition${inputCount}){
                  id
                  group
                  order
                  label
                  isFixed
                  isDisplayed
                  displayedFor
                  btnEditCustomList
                  customListType
                  isCustomizable
                  createdAt
                  updatedAt
              }
              `
              input['input' + inputCount] = {id: obj.id, order: obj.order, isDisplayed: obj.isDisplayed}
              inputCount++
              break
            case 'not-displayed':
              obj.isDisplayed = true 
              obj.order = this.dailyRosterColumns.filter(obj => obj.displayedFor === item.displayedFor && obj.isDisplayed).length - 1 

              params += `$input${inputCount}: UpdateDailyRostersColumnsInput!\n$condition${inputCount}: ModelDailyRostersColumnsConditionInput\n`
              body += `mutation${inputCount}: updateDailyRostersColumns(input: $input${inputCount}, condition: $condition${inputCount}){
                  id
                  group
                  order
                  label
                  isFixed
                  isDisplayed
                  displayedFor
                  btnEditCustomList
                  customListType
                  isCustomizable
                  createdAt
                  updatedAt
              }
              `
              input['input' + inputCount] = {id: obj.id, order: obj.order, isDisplayed: obj.isDisplayed}
              inputCount++
              break
          }
        }
        return obj
      })

      if (initIndexFromAfterOptionChanged !== null) {
        console.log("initIndexFromAfterOptionChanged", initIndexFromAfterOptionChanged);
        this.dailyRosterColumns.filter(obj => obj.displayedFor === item.displayedFor && obj.isDisplayed).sort((a, b) => a.order - b.order).map(obj => {
          if (obj.order >= initIndexFromAfterOptionChanged) {
            obj.order = initIndexFromAfterOptionChanged 
            params += `$input${inputCount}: UpdateDailyRostersColumnsInput!\n$condition${inputCount}: ModelDailyRostersColumnsConditionInput\n`
            body += `mutation${inputCount}: updateDailyRostersColumns(input: $input${inputCount}, condition: $condition${inputCount}){
                  id
                  group
                  order
                  label
                  isFixed
                  isDisplayed
                  displayedFor
                  btnEditCustomList
                  customListType
                  isCustomizable
                  createdAt
                  updatedAt
              }
              `
            input['input' + inputCount] = {id: obj.id, order: obj.order, isDisplayed: obj.isDisplayed}
            inputCount++
            initIndexFromAfterOptionChanged++
          }
          return obj
        })
      }

      // Save all options on DB
      this.saveMultipleOptions(params, body, input)
    },
    async changeIndex(event) {
      let params = ''
      let body = ''
      let input = {}
      let inputCount = 1

      let initIndexFromNewIndexOption = event.moved.newIndex
      let initIndexFromOldIndexOption = 0
      let optionChanged = this.dailyRosterColumns.find(obj => obj.id === event.moved.element.id)
      this.loading = true

      this.dailyRosterColumns.filter(obj => obj.displayedFor === optionChanged.displayedFor && obj.isDisplayed).sort((a, b) => a.order - b.order).map(obj => {
        if (obj.id === optionChanged.id) {
          obj.order = event.moved.newIndex 
          params += `$input${inputCount}: UpdateDailyRostersColumnsInput!\n$condition${inputCount}: ModelDailyRostersColumnsConditionInput\n`
          body += `mutation${inputCount}: updateDailyRostersColumns(input: $input${inputCount}, condition: $condition${inputCount}){
                  id
                  group
                  order
                  label
                  isFixed
                  isDisplayed
                  displayedFor
                  btnEditCustomList
                  customListType
                  isCustomizable
                  createdAt
                  updatedAt
              }
              `
          input['input' + inputCount] = {id: obj.id, order: obj.order, isDisplayed: obj.isDisplayed}
          inputCount++
        }

        if (event.moved.newIndex > event.moved.oldIndex) {
          if (obj.order <= event.moved.newIndex && obj.id !== optionChanged.id) {
            obj.order = initIndexFromOldIndexOption 
            params += `$input${inputCount}: UpdateDailyRostersColumnsInput!\n$condition${inputCount}: ModelDailyRostersColumnsConditionInput\n`
            body += `mutation${inputCount}: updateDailyRostersColumns(input: $input${inputCount}, condition: $condition${inputCount}){
                  id
                  group
                  order
                  label
                  isFixed
                  isDisplayed
                  displayedFor
                  btnEditCustomList
                  customListType
                  isCustomizable
                  createdAt
                  updatedAt
              }
              `
            input['input' + inputCount] = {id: obj.id, order: obj.order, isDisplayed: obj.isDisplayed}
            inputCount++
            initIndexFromOldIndexOption++
          }
        } else {
          if (obj.order >= event.moved.newIndex && obj.id !== optionChanged.id) {
            obj.order = initIndexFromNewIndexOption + 1 
            params += `$input${inputCount}: UpdateDailyRostersColumnsInput!\n$condition${inputCount}: ModelDailyRostersColumnsConditionInput\n`
            body += `mutation${inputCount}: updateDailyRostersColumns(input: $input${inputCount}, condition: $condition${inputCount}){
                  id
                  group
                  order
                  label
                  isFixed
                  isDisplayed
                  displayedFor
                  btnEditCustomList
                  customListType
                  isCustomizable
                  createdAt
                  updatedAt
              }
              `
            input['input' + inputCount] = {id: obj.id, order: obj.order, isDisplayed: obj.isDisplayed}
            inputCount++
            initIndexFromNewIndexOption++
          }
        }
        return obj
      })

      // Save all options on DB
      this.saveMultipleOptions(params, body, input)
    },
    async saveMultipleOptions(params, body, input) {
      try {
        let mutations = `mutation (
        ${params}
          ){
              ${body}
          }`
        await this.api(mutations, input)
      } catch (error) {
        this.displayUserError(error)
      } finally {
        this.loading = false
      }
    },
    async drawerHide(event) {
      this.drawerVisibility = false;
    },
    async openDrawerCustomList(customListType) {
      this.loading = true
      this.tableDataSelected = this.customList.find(obj => obj.type === customListType)
      this.drawerVisibility = true;
      this.loading = false;
    },
    async reloadCustomList() {
      await this.loadCustomList()
      this.reloadTableDataSelected()
    },
    reloadTableDataSelected() {
      this.tableDataSelected = this.customList.find(obj => obj.type === this.tableDataSelected.type)
    },
  },
}
</script>

<style lang="scss" scoped>
.flip-list-move {
  transition: transform 0.5s;
}

.no-move {
  transition: transform 0s;
}

.ghost {
  opacity: 0.5;
  background: #c8ebfb;
}

@media (max-width: 767px) {
  .checkbox-label {
    font-size: 12px;
  }
}

@media(min-width: 768px) and (max-width: 1023px) {
  .checkbox-label {
    font-size: 8px;
  }
}

@media only screen and (min-width: 1024px) and (max-width: 1279px) {
  .checkbox-label {
    font-size: 10px;
  }
}
</style>

<style lang="scss">
.el-loading-mask {
  z-index: 9 !important;
}

.el-drawer {
  width: 32% !important;
}

@media (max-width: 1900px) {
  .el-drawer {
    width: 40% !important;
  }
}

@media (max-width: 1500px) {
  .el-drawer {
    width: 50% !important;
  }
}

@media (max-width: 1200px) {
  .el-drawer {
    width: 60% !important;
  }
}

@media (max-width: 1000px) {
  .el-drawer {
    width: 80% !important;
  }
}

@media (max-width: 750px) {
  .el-drawer {
    width: 90% !important;
  }
}
</style>
