import { mixinMethods } from '@/main'
import {
    onCreateStaffByGroup,
    onUpdateStaffByGroup,
    onDeleteStaffByGroup
} from '../../subscriptions';
import store from '@/store'
import { staffsByGroupStatusWithoutConexions, staffsByGroupStatusWithConexions } from '../../queries'
import { initializeSubscription, disconnectSubscription, updateElement} from '../helper'
import { recalculateDaPerformanceData } from '@/views/DaPerformanceReport/calculateDaPerformanceReport'
import { messengerLoadNotifications } from '../nested/messengerSubscriptions'
import { updateAssociateMessengerPhotoUrl } from '@/views/Messenger/countUnreadMessages';
import pako from 'pako';
import { API } from "aws-amplify";

"use strict";
let subscriptionAssociates = null

export const loadAssociates = async function(){
    if(!store.state.userInfo?.tenant?.group) return
    const input = {
        group: store.state.userInfo.tenant.group,
        //limit:100 no require limit(staffsByGroupStatusWithoutConexions query has no connections)
    }
    const associates = await mixinMethods.gLoadListAll(staffsByGroupStatusWithoutConexions, input, 'staffsByGroupStatus')
    store.dispatch("subscriptionStore/setAssociateList", associates)
    afterLoadAssociates()
    messengerLoadNotifications()
}


export const afterLoadAssociates = async function(){
    if(!store.state.userInfo?.tenant?.group) return
    try {

        let apiName = "heraPublicApi";
        let path = "/executeMutation";
        let post = {
            body: {
                type: "associate",
                mutation: "load-all",
                compress: true,
                group: store.state.userInfo.tenant.group,
                owner: store.state.userInfo.cognitoSub,
                items: {},
            },
        };

        store.state.authorizedToDriveValidationTemporarilyDisabled = true
        const result = await safeFunction(API.post)(apiName, path, post);   
        const compressedData = atob(result);
        const compressedArray = new Uint8Array(compressedData.split('').map(char => char.charCodeAt(0)));
        const decompressed = pako.ungzip(compressedArray, { to: 'string' });
        const associates = JSON.parse(decompressed);
        const associateLookupMap = store.getters["subscriptionStore/getAssociateLookupMap"]

        associates.forEach(associate => {
            const result = associateLookupMap[associate.id]
            if(result) updateElement(associate, result, true)
        })
        
        store.dispatch("subscriptionStore/setAssociateList", associates)
    } catch (e) {
        console.error(e)
    } finally {
        store.state.authorizedToDriveValidationTemporarilyDisabled = false
    }
}

export const associateSubscribe = function(instance){
    const queries = {
        onCreateStaffByGroup,
        onUpdateStaffByGroup,
        onDeleteStaffByGroup
    }
    if(!store.state.userInfo?.tenant?.group) return
    const input = {
        group: store.state.userInfo.tenant.group
    }
    initializeSubscription(subscriptionAssociates, queries, input, "subscriptionStore/mutateAssociate", instance)
}

export const config = function(subscriptions){
    const subscription = {}
    subscriptionAssociates = subscriptions['associate'] = {
        loadList: loadAssociates,
        subscribe: associateSubscribe,
        unsubscribe: (instance) => disconnectSubscription(subscription, instance),
        callback: associateEventCallback,
        subscription
    }
}

export const associateEventCallback = async function(subscriptionState, {data: associate, eventName}){
    switch(eventName){
        case 'onUpdate':{
            updateAssociateMessengerPhotoUrl(associate)
            break
        }
    }
    await recalculateDaPerformanceData(false)
}