<template>
  <div>
    <div 
      class="flex border-2 p-4 rounded-lg shadow col-span-1 relative h-24 cursor-pointer hover:border-gray-600" 
      :class="{'border-brand': this.selected || this.bundleSelected}"
      @click="choose(slug)">
      
      <p class="flex-1 text-gray-600 text-lg font-bold mt-2">
        {{name}}
        <el-tooltip class="item" effect="dark" placement="bottom">
          <div slot="content" class="p-2">
            <ul class="list-disc">
              <li v-for="feature in features" :key="feature" class="text-sm ml-4">{{feature}}</li>
            </ul>
          </div>
          <i class="uil uil-question-circle text-base"></i>
        </el-tooltip>
      </p>

      <div class="flex-initial text-center">
        <p v-show="!bundleSelected" class="font-bold text-gray-600 text-2xl">+${{costPerDriver}}</p>
        <p v-show="bundleSelected" class="font-bold text-gray-600 text-2xl">Included</p>
        <p v-show="!bundleSelected" class="text-xs mb-2 text-gray-600">per {{$t('label.associate')}} per month<sup>3,4</sup></p>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  name: 'PremiumFeaturesChoice',
  props: ['name', 'slug', 'selected', 'features', 'costPerDriver', 'bundleSelected'],
  data(){
    return{
    }
  },
  methods: {
    choose(feature){
        this.$emit('choose', feature)
    }
  }
}
</script>

<style scoped>

</style>
