import Vue from 'vue'
import VueRouter from 'vue-router'
import store from '@/store/index.js'

import Settings from '@/views/Settings/Settings'
import settingRoutes from './settingRoutes.js'

import System from '@/views/System/System'
import systemRoutes from './systemRoutes.js'
import {date} from '@/utilities/getDate'

import { HeraManagementRouteClass } from './managementRouteMessenger/heraManagementRouteClass.js'
import { ShortenIdMiddleware, ValidateAndUpdateStatusShortenUrl } from '@/middlewares/ShortenIdMiddleware.js'
import SearchIndex from '@/views/Search/SearchIndex'

var USER_PERMISSIONS =  process.env.VUE_APP_USER_PERMISSIONS === "1";

Vue.use(VueRouter)

const maintenanceMode = process.env.VUE_APP_MAINTENANCE_MODE || false;

  const routes = [
  { path: '/performance', redirect: '/performance-and-coaching' },
  { path: '/coaching/daily-performance-data-import', redirect: '/performance-and-coaching/daily-performance-data-import' },
  { path: '/coaching/netradyne-alerts', redirect: '/performance-and-coaching/netradyne-alerts' },
  { path: '/coaching/da-performance-report', redirect: '/performance-and-coaching/da-performance-report' },
  { path: '/accidents-and-damage', redirect: '/reports/incident-records' },
  { path: '/accident/:accidentID', redirect: '/vehicle-management/incident/:accidentID' },
  { path: '/da-management/driver-issue-detail/:infractionID', redirect: '/performance-and-coaching/driver-issue-detail/:infractionID' },
  { path: '/da-management/da-issues', redirect: '/performance-and-coaching/da-issues' },
  { path: '/da-management/da-kudos', redirect: '/performance-and-coaching/da-kudos' },
  { path: '/da-management/driver-issue-edit/:infractionID?', redirect: '/performance-and-coaching/driver-issue-edit/:infractionID?' },
  { path: '/coaching/counseling/new', redirect: '/performance-and-coaching/counseling/new' },
  { path: '/coaching/counseling/edit/:counselingId', redirect: '/performance-and-coaching/counseling/edit/:counselingId' },
  { path: '/coaching/counseling/:counselingId', redirect: '/performance-and-coaching/counseling/:counselingId' },
  { path: '/coaching/counselings', redirect: '/performance-and-coaching/counselings' },
  { path: '/injuries', redirect: '/da-management/injuries' },
  { path: '/injury/new/', redirect: '/da-management/injury/new/' },
  { path: '/injury/:injuryID', redirect: '/da-management/injury/:injuryID' },
  { path: '/staff/daOptedOut', redirect: '/reports/daOptedOut' },
  { path: '/tasks', redirect: '/reports/tasks' },
  { path: '/coaching', redirect: '/performance-and-coaching' },
  { path: '/staff/milestones', redirect: '/reports/milestones' },

  {
    path: '/',
    name: 'Login',
    meta: { layout: 'AuthLayout'},
    component: () => import(/* webpackChunkName: "login" */ '../views/Auth/Login.vue')
  },
  {
    path: '/upgrade',
    name: 'VueUpgradeTest',
    meta: { layout: 'AuthLayout'},
    component: () => import(/* webpackChunkName: "login" */ '../views/VueUpgradeTest.vue')
  },
  {
    path: '/signup',
    name: 'SignUp',
    meta: { layout: 'BareLayout'},
    component: () => import(/* webpackChunkName: "signup" */ '../views/Auth/SignUp.vue')
  },
  {
    path: '/signup/confirm/:code?',
    name: 'SignUpConfirm',
    meta: { layout: 'BareLayout'},
    component: () => import(/* webpackChunkName: "signupconfirm" */ '../views/Auth/SignUpConfirm.vue')
  },
  {
    path: '/reset',
    name: 'Reset',
    meta: { layout: 'AuthLayout'},
    component: () => import(/* webpackChunkName: "reset" */ '../views/Auth/Reset.vue')
  },
  {
    path: '/new-password',
    name: 'NewPassword',
    props: true,
    meta: { layout: 'AuthLayout'},
    component: () => import(/* webpackChunkName: "newpassword" */ '../views/Auth/NewPassword.vue')
  },
  {
    path: '/reset/confirm',
    name: 'ResetConfirm',
    props: true,
    meta: { layout: 'AuthLayout'},
    component: () => import(/* webpackChunkName: "resetConfirm" */ '../views/Auth/ResetConfirm.vue')
  },
  {
    path: '/performance-and-coaching',
    name: 'Dashboard',
    beforeEnter: requireAuth,
    component: () => import(/* webpackChunkName: "dashboard" */ '../views/Performance&Coaching/PerformanceCoachingIndex.vue'),
    redirect: {name: 'PerformanceIndex'},
    children:[
      {
        path: 'performance',
        meta: { crumbTitle: 'Performance Tracking'},
        component: () => import(/* webpackChunkName: "PerformanceIndex" */ '../views/Performance&Coaching/Performance/PerformanceIndex.vue'),
        name: 'PerformanceIndex'
      },
      {
        path: 'coaching',
        meta: { crumbTitle: 'Automated Coaching'},
        props: true,
        component: () => import(/* webpackChunkName: "CoachingIndex" */ '../views/Performance&Coaching/Coaching/CoachingIndex.vue'),
        name: 'CoachingIndex'
      },
      {
        path: 'counselings',
        meta: { crumbTitle: 'Counselings'},
        component: () => import(/* webpackChunkName: "counselingindex" */ '../views/Counseling/CounselingIndex.vue'),
        name: 'CounselingIndex'
      },
      {
        path: 'da-issues',
        name: 'DaIssueIndex',
        meta: { crumbTitle: 'Associate Issues'},
        props: true, 
        component: ()=> import(/* webpackChunkName: "daIssueIndex" */ '../views/DaIssues/components/DaIssueList.vue')
      },
      {
        path: 'da-kudos',
        name: 'DaKudoIndex',
        meta: { crumbTitle: 'Associate Kudos'},
        props: true, 
        beforeEnter: requireAuth,
        component: ()=> import(/* webpackChunkName: "daKudoIndex" */ '../views/Staff/DaKudoIndex.vue')
      }
    ]
  },
  {
    path: '/da-management',
    props: true,
    name: 'StaffIndex',
    beforeEnter: requireAuth,
    component: () => import(/* webpackChunkName: "staffindex" */ '../views/Staff/StaffIndex.vue'),
    redirect: {name: 'dashboard'},
    children:[
      { 
        path: 'dashboard',
        meta: { crumbTitle: 'Dashboard'},
        component: () => import(/* webpackChunkName: "staffDashboard" */ '../views/Staff/components/Radios/Dashboard.vue'),
        name: 'dashboard'
      },
      { 
        path: 'associate-list',
        meta: { crumbTitle: 'Associate List'},
        name: 'associate-list',
        component: () => import(/* webpackChunkName: "staffList" */ '../views/Staff/components/Radios/AssociateList.vue'),
      },
      { 
        path: 'onboarding',
        meta: { crumbTitle: 'Onboarding'},
        component: () => import(/* webpackChunkName: "staffOnboarding" */ '../views/Staff/components/Radios/Onboarding.vue'),
        name: 'onboarding'
      },
      { 
        path: 'bulk-updates',
        meta: { crumbTitle: 'Bulk Updates'},
        component: () => import(/* webpackChunkName: "staffBulkUpdates" */ '../views/Staff/components/Radios/BulksUpdates.vue'),
        name: 'bulk-updates'
      },
    ]
  },
  {
    path: '/vehicles',
    props: true,
    name: 'VehicleIndex',
    beforeEnter: requireAuth,
    component: () => import(/* webpackChunkName: "vehicleindex" */ '../views/Vehicles/VehicleIndex.vue'),
    redirect: {name: 'vehicle-dashboard'},
    children:[
      { 
        path: 'dashboard',
        meta: { crumbTitle: 'Dashboard'},
        name: 'vehicle-dashboard',
        component: () => import(/* webpackChunkName: "vehicleList" */ '../views/Vehicles/components/Radios/Dashboard.vue'),
      },
      { 
        path: 'vehicle-list',
        meta: { crumbTitle: 'Vehicle List'},
        name: 'vehicle-list',
        component: () => import(/* webpackChunkName: "vehicleList" */ '../views/Vehicles/components/Radios/VehicleList.vue'),
      },
      { 
        path: 'bulk-updates',
        meta: { crumbTitle: 'Bulk Updates'},
        name: 'vehicle-bulk-updates',
        component: () => import(/* webpackChunkName: "vehicleBulkUpdates" */ '../views/Vehicles/components/Radios/BulksUpdates.vue')
      }
    ]
  },
  {
    path: '/u/vpl/:token',
    name: 'VehicleDailyLogPublic',
    meta: { layout: 'BareLayout'},
    beforeEnter: async (to, from, next) => {
      const token = to.params.token;
      await ValidateAndUpdateStatusShortenUrl(token);
      next();
    },
    component: () => import(/* webpackChunkName: "vehicledailylogpublic" */ '../views/Vehicles/components/VehicleDailyLog/VehicleDailyLogPublic.vue')
  },
  {
    path: '/daily-rostering/:dateId/csv-import/:importId?',
    name: 'CustomCSVImport',
    props: true,
    beforeEnter: requireAuth,
    component: () => import(/* webpackChunkName: "customcsvimport" */ '../modules/dataImports/csvImport/CustomCSVImport.vue')
  },
  {
    path: '/daily-rostering',
    name: 'DailyRosterIndex',
    beforeEnter: requireAuth,
    component: () => import(/* webpackChunkName: "dailyrostering" */ '../views/DailyRoster/DailyRosteringIndex.vue'),
    redirect: {
      name: 'DailyRoster',
      params: { dateId: date(true) },
    },
    params: { dateId: date(true) },
    props: true,
    children: [
      {
        path: ':dateId/roster',
        name: 'DailyRoster',
        props: true,
        beforeEnter: requireAuth,
        component: () => import(/* webpackChunkName: "dailyroster" */ '../views/DailyRoster/DailyRoster.vue')
      },
      {
        path: ':dateId/report',
        name: 'DailyReport',
        props: true,
        beforeEnter: requireAuth,
        component: () => import(/* webpackChunkName: "dailyreport" */ '../views/DailyRoster/DailyReport.vue')
      }
    ]
  },
  {
    path: '/daily-rostering/:dateId/amazon-import/:importId?',
    name: 'DailyAmazonImport',
    props: true,
    beforeEnter: requireAuth,
    component: () => import(/* webpackChunkName: "DailyAmazonImport" */ '../modules/dataImports/amazon/DailyAmazonImport.vue')
  },
  {
    path: '/daily-roster-import/:importId?',
    name: 'DailyRosterImport',
    props: true,
    redirect: {
      name: 'DailyAmazonImport',
      params: { dateId: date(true) }
    }
  },
  {
    path: '/performance-and-coaching/daily-performance-data-import/:importId?',
    name: 'DailyDataImport',
    props: true,
    beforeEnter: requireAuth,
    component: () => import(/* webpackChunkName: "DailyDataImport" */ '../views/Performance&Coaching/Coaching/DailyDataImport.vue')
  },
  {
    path: '/performance-and-coaching/weekly-performance-data-import/:importId?',
    name: 'WeeklyDataImport',
    props: true,
    beforeEnter: requireAuth,
    component: () => import(/* webpackChunkName: "weeklydataimport" */ '../views/Performance&Coaching/Coaching/WeeklyDataImport.vue')
  },
  {
    path: '/performance-and-coaching/netradyne-alerts',
    name: 'NetradyneIndex',
    beforeEnter: requireAuth,
    props: true,
    component: () => import(/* webpackChunkName: "netradyneindex" */ '../views/NetradyneAlert/NetradyneIndex.vue')
  },
  {
    path: '/performance-and-coaching/fantastics-by-staff',
    name: 'FantasticsByStaff',
    beforeEnter: (to, from, next)=> {
      if(process.env.VUE_APP_USE_REPORT_BLITZ_745 !== "true"){
        console.log("Skipping...")
        return
      }
      requireAuth(to, from, next)
    },
    props: true,
    component: () => import(/* webpackChunkName: "fantasticsByStaff" */ '../views/Performance&Coaching/Performance/FantasticsByStaff.vue')
  },
  {
    path: '/performance-and-coaching/da-performance-report',
    name: 'DaPerformanceReport',
    beforeEnter: requireAuth,
    props: true,
    component: () => import(/* webpackChunkName: "daperformancereport" */ '../views/DaPerformanceReport/DaPerformanceReportIndex.vue')
  },
  {
    path: '/print/accident/:accidentID',
    name: 'PrintAccident',
    props: true,
    meta: { layout: 'PrintLayout'},
    beforeEnter: requirePermissionAccidents,
    component: () => import(/* webpackChunkName: "accidentprint" */ '../views/Accident/AccidentPrint.vue')
  },
  {
    path: '/reports/incident-records',
    name: 'AccidentIndex',
    props: true,
    beforeEnter: requirePermissionAccidents,
    component: () => import(/* webpackChunkName: "accidentindex" */ '../views/Accident/AccidentIndex.vue')
  },
  {
    path: '/reports/vehicle-damage',
    name: 'VehicleDamageIndex',
    props: true,
    beforeEnter: requireAuth,
    component: () => import(/* webpackChunkName: "vehicledamageindex" */ '../views/Accident/AccidentVehicleDamageIndex.vue')
  },
  {
    path: '/reports/recent-vehicle-damage',
    name: 'RecentVehicleDamage',
    props: true,
    beforeEnter: requireAuth,
    component: () => import(/* webpackChunkName: "recentvehicledamageindex" */ '../views/Accident/AccidentVehicleDamageIndex.vue')
  },
  {
    path: '/vehicle-management/incident/new',
    name: 'IncidentNew',
    props: true,
    beforeEnter: requirePermissionAccidents,
    component: () => import(/* webpackChunkName: "incidentnew" */ '../views/Accident/AccidentForm.vue')
  },
  {
    path: '/vehicle-management/incident/:accidentID',
    name: 'IncidentEdit',
    props: true,
    beforeEnter: requirePermissionAccidents,
    component: () => import(/* webpackChunkName: "incidentedit" */ '../views/Accident/AccidentForm.vue')
  },
  {
    path: '/print/vehicle/:accidentID',
    name: 'PrintVehicleDamage',
    props: true,
    meta: { layout: 'PrintLayout'},
    beforeEnter: requirePermissionAccidents,
    component: () => import(/* webpackChunkName: "accidentprint" */ '../views/Vehicles/components/VehicleDamage/VehicleDamagePrint.vue')
  },
  {
    path: '/print/vehicle-vin/:vehicleId',
    name: 'PrintVehicleVIN',
    props: true,
    meta: { layout: 'PrintLayout'},
    beforeEnter: requireAuth,
    component: () => import(/* webpackChunkName: "vehiclevinprint" */ '../views/Vehicles/components/VehicleVinPrint.vue')
  },
  {
    path: '/performance-and-coaching/driver-issue-detail/:infractionID',
    name: 'DaIssueDetail',
    props: true, 
    beforeEnter: requireAuth,
    component: ()=> import(/* webpackChunkName: "daIssueDetail" */ '../views/DaIssues/DaIssueDetail.vue')
  },
  {
    path: '/reports/recent-da-issues',
    name: 'RecentDaIssuesReport',
    props: true, 
    beforeEnter: requireAuth,
    component: ()=> import(/* webpackChunkName: "daIssueIndex" */ '../views/DaIssues/DaIssueIndex.vue')
  },
  {
    path: '/performance-and-coaching/recent-da-kudos',
    name: 'RecentDaKudosReport',
    props: true, 
    beforeEnter: requireAuth,
    component: ()=> import(/* webpackChunkName: "daKudoIndex" */ '../views/Staff/DaKudoIndex.vue')
  },
  {
    path: '/performance-and-coaching/driver-issue-edit/:infractionID',
    name: 'DaIssueEdit',
    props: true, 
    beforeEnter: requireAuth,
    component: ()=> import(/* webpackChunkName: "daIssueEdit" */ '../views/DaIssues/DaIssueEdit.vue')
  },
  {
    path: '/performance-and-coaching/driver-issue-new',
    name: 'DaIssueNew',
    props: true, 
    beforeEnter: requireAuth,
    component: ()=> import(/* webpackChunkName: "daIssueNew" */ '../views/DaIssues/DaIssueEdit.vue')
  },
  
  {
    path: '/performance-and-coaching/driver-issue-new/:infractionType',
    name: 'DaIssueEditInfractionType',
    props: true, 
    beforeEnter: requireAuth,
    component: ()=> import(/* webpackChunkName: "daIssueEdit" */ '../views/DaIssues/DaIssueEdit.vue')
  },
  {
    path: '/performance-and-coaching/counseling/new',
    name: 'CounselingNew',
    props: true,
    beforeEnter: requirePermissionManageCounselings,
    component: ()=> import(/* webpackChunkName: "counselingnew" */ '../views/Counseling/CounselingNew.vue')
  },
  {
    path: '/performance-and-coaching/counseling/edit/:counselingId',
    name: 'CounselingEdit',
    props: true,
    beforeEnter: requirePermissionManageCounselings,
    component: ()=> import(/* webpackChunkName: "counselingedit" */ '../views/Counseling/CounselingNew.vue')
  },
  {
    path: '/performance-and-coaching/counseling/:counselingId',
    name: 'CounselingDetail',
    props: true,
    beforeEnter: requirePermissionManageCounselings,
    component: ()=> import(/* webpackChunkName: "counselingDetail" */ '../views/Counseling/CounselingDetail.vue')
  },
  {
    path: '/u/cs/:counselingId',
    name: 'RedirectCounselingSignature',
    prop: true,
    redirect: { name: 'CounselingSignature' }
  },
  {
    path: '/cs/:counselingId',
    name: 'RedirectCounselingSignatureOld',
    prop: true,
    redirect: { name: 'CounselingSignature' }
  },
  {
    path: '/u/counseling-signature/:counselingId',
    name: 'CounselingSignature',
    props: true,
    meta: { layout: 'BareLayout'},
    component: ()=> import(/* webpackChunkName: "counselingSignature" */ '../views/Counseling/CounselingSignature.vue')
  },
  {
    path: '/counseling-signature/complete',
    name: 'CounselingSignatureComplete',
    props: true,
    meta: { layout: 'BareLayout'},
    component: ()=> import(/* webpackChunkName: "counselingSignatureComplete" */ '../views/Counseling/CounselingSignatureComplete.vue')
  },
  {
    path: '/print/counseling/:counselingId',
    name: 'CounselingPrint',
    props: true, 
    meta: { layout: 'PrintLayout'},
    beforeEnter: requirePermissionManageCounselings, 
    component: ()=> import(/* webpackChunkName: "counselingPrint" */ '../views/Counseling/CounselingPrint.vue')
  },
  {
    path: '/reports/pending-counselings',
    name: 'PendingCounselingsReport',
    beforeEnter: requireAuth,
    component: () => import(/* webpackChunkName: "counselingindex" */ '../views/Counseling/CounselingIndex.vue')
  },
  {
    path: '/da-management/injuries',
    name: 'InjuryIndex',
    beforeEnter: requirePermissionInjuries,
    component: () => import(/* webpackChunkName: "injuriesindex" */ '../views/Staff/components/Injuries/InjuryIndex.vue')
  },
  {
    path: '/da-management/injury/new/',
    name: 'InjuryNew',
    props: true,
    beforeEnter: requirePermissionInjuries,
    component: () => import(/* webpackChunkName: "injurynew" */ '../views/Staff/components/Injuries/InjuryForm.vue' )
  },
  {
    path: '/da-management/injury/:injuryID',
    name: 'InjuryEdit',
    props: true,
    beforeEnter: requirePermissionInjuries,
    component: () => import(/* webpackChunkName: "injuryedit" */ '../views/Staff/components/Injuries/InjuryForm.vue')
  },
  {
    path: '/print/injury/:injuryID',
    name: 'PrintInjury',
    props: true,
    beforeEnter: requirePermissionInjuries,
    meta: { layout: 'PrintLayout'},
    component: () => import(/* webpackChunkName: "printinjury" */ '../views/Staff/components/Injuries/InjuryPrint.vue')
  },
  // {
  //   path: '/notifications',
  //   name: 'NotificationIndex',
  //   beforeEnter: requireAuth,
  //   component: () => import(/* webpackChunkName: "notificationindex" */ '../views/Notifications/NotificationIndex.vue')
  // },
  {
    path: '/notifications/:optionID',
    name: 'NotificationIndex',
    props: true,
    beforeEnter: requireAuth,
    component: () => import(/* webpackChunkName: "notificationindex" */ '../views/Notifications/NotificationIndex.vue')
  },
  {
    path: '/reports/da-driver-licenses',
    name: 'DriverLicensesReport',
    beforeEnter: requireAuth,
    component: () => import(/* webpackChunkName: "DriverLicensesReport" */ '../views/Staff/DriverLicensesReport.vue')
  },
  {
    path: '/reports/issued-uniforms',
    name: 'IssuedUniformsReport',
    beforeEnter: requireAuth,
    component: () => import(/* webpackChunkName: "IssuedUniformsReport" */ '../views/Staff/IssuedUniformsReport/IssuedUniformsReport.vue')
  },
  {
    path: '/reports/daOptedOut',
    name: 'StaffOptedOut',
    beforeEnter: requireAuth,
    component: () => import(/* webpackChunkName: "staffoptedout" */ '../views/Staff/StaffOptedOut.vue')
  },
  {
    path: '/reports/daWhoRescuedOthers',
    name: 'StaffsWhoRescuedOthers',
    beforeEnter: (to, from, next)=> {
      if(process.env.VUE_APP_USE_REPORT_BLITZ_760 !== "true"){
        console.log("skiping...")
        return
      }
      requireAuth(to, from, next)
    },
    component: () => import(/* webpackChunkName: "staffsWhoRescuedOthers" */ '../views/DailyRoster/reports/StaffsWhoRescuedOthers.vue')
  },
  {
    path: '/reports/daWhoWereRescued',
    name: 'StaffsWhoWereRescued',
    beforeEnter: (to, from, next)=> {
      if(process.env.VUE_APP_USE_REPORT_BLITZ_279 !== "true"){
        console.log("Skipping...")
        return
      }
      requireAuth(to, from, next)
    },
    component: () => import(/* webpackChunkName: "staffsWhoWereRescued" */ '../views/DailyRoster/reports/StaffsWhoWereRescued.vue')
  },
  {
    path: '/reports/daAttendanceStatistics',
    name: 'StaffAttendanceStatistics',
    beforeEnter: (to, from, next)=> {
      if(process.env.VUE_APP_USE_REPORT_BLITZ_809 !== "true"){
        console.log("Skipping...")
        return
      }
      requireAuth(to, from, next)
    },
    component: () => import(/* webpackChunkName: "staffAttendanceStatistics" */ '../views/DailyRoster/reports/StaffAttendanceStatistics.vue')
  },
  {
    path: '/reports/daily-roster-standbys',
    name: 'DailyRosterStandbys',
    beforeEnter: (to, from, next)=> {
      if(process.env.VUE_APP_USE_REPORT_BLITZ_442 !== "true"){
        console.log("Skipping...")
        return
      }
      requireAuth(to, from, next)
    },
    component: () => import(/* webpackChunkName: "dailyRosterStandbys" */ '../views/DailyRoster/reports/DailyRosterStandbys.vue')
  },
  {
    path: '/da-management/import',
    name: 'StaffImport',
    beforeEnter: requirePermissionDAManagement,
    component: () => import(/* webpackChunkName: "staffimport" */ '../views/Staff/StaffImport.vue')
  },
  {
    path: '/da-management/:id',
    name: 'StaffDetail',
    props: true,
    beforeEnter: requirePermissionDAManagement,
    component: () => import(/* webpackChunkName: "staffdetail" */ '../views/Staff/StaffDetail.vue')
  },
  {
    path: '/reports/tasks/:taskID?',
    name: 'Task',
    props: true,
    beforeEnter: requireAuth,
    component: ()=> import(/* webpackChunkName: "task" */ '../views/Tasks/TaskIndex.vue')
  },
  {
    path: '/vehicles/reminders',
    name: 'ReminderIndex',
    beforeEnter: requireAuth,
    component: () => import(/* webpackChunkName: "reminderindex" */ '../views/Vehicles/ReminderIndex.vue')
  },
  {
    path: '/reports/upcoming-reminders',
    name: 'UpcomingMaintenanceReminders',
    beforeEnter: requireAuth,
    component: () => import(/* webpackChunkName: "reminderindex" */ '../views/Vehicles/ReminderIndex.vue')
  },
  {
    path: '/reports/vehicle-maintenance-records',
    alias: '/reports/vehicle-maintenance-records/*',
    name: 'VehicleMaintenanceRecords',
    prop: true,
    beforeEnter: requireAuth,
    component: () => import(/* webpackChunkName: "vehicleindex" */ '../views/Vehicles/VehicleMaintenance.vue')
  },
  {
  path: '/vehicles/import',
  name: 'VehicleImport',
  beforeEnter: requireAuth,
  component: () => import(/* webpackChunkName: "vehicleimport" */ '../views/Vehicles/VehicleImport.vue')
  },
  {
    path: '/vehicles/new',
    name: 'VehicleNew',
    beforeEnter: requireAuth,
    component: () => import(/* webpackChunkName: "vehiclenew" */ '../views/Vehicles/VehicleNew.vue')
  },
  {
    path: '/vehicles/:id',
    name: 'VehicleDetail',
    props: true,
    beforeEnter: requireAuth,
    component: () => import(/* webpackChunkName: "vehicledetail" */ '../views/Vehicles/VehicleDetail.vue'),
    // children: [
    //   {
    //     // UserProfile will be rendered inside User's <router-view>
    //     // when /user/:id/profile is matched
    //     // path: 'history',
    //     path: '',
    //     name: 'VehicleHistory',
    //     // component: VehicleHistory,
    //     component: () => import(/* webpackChunkName: "vehicledetail" */ '../views/Vehicles/HistoryVehicleDetail.vue'),
    //   },
    // ]
  },
  {
    path: '/vehicles/upload-image',
    name: 'VehicleUploadImage',
    props: true,
    beforeEnter: requireAuth,
    // component: () => import(/* webpackChunkName: "vehicledetail" */ '../views/VehicleDetail.vue')
  },
  {
    path: '/messenger/:staffId?',
    name: 'Messenger',
    meta: { disableRouteKey: true},
    beforeEnter: requirePermissionMessenger,
    component: () => import(/* webpackChunkName: "messenger" */ '../views/Messenger/MessengerMasterDetail.vue')
  },
  {
    path: '/messenger/upload-image',
    name: 'MessengerUploadImage',
    props: true,
    beforeEnter: requireAuth,
    // component: () => import(/* webpackChunkName: "vehicledetail" */ '../views/VehicleDetail.vue')
  },
  {
    path: '/u/f/:attachmentId',
    name: 'RedirectMessageAttachment',
    prop: true,
    redirect: { name: 'MessageAttachment' }
  },
  {
    path: '/f/:attachmentId',
    name: 'RedirectMessageAttachmentOld',
    prop: true,
    redirect: { name: 'MessageAttachment' }
  },
  {
    path: '/u/f/:attachmentId',
    name: 'MessageAttachment',
    meta: { layout: 'BareLayout'},
    component: () => import(/* webpackChunkName: "messageattachment" */ '../views/Messenger/MessageAttachment.vue')
  },
  {
    path: '/m/messenger/:loc?',
    name: 'MobileMessengerList',
    props: { loc: true, mobile: true},
    // meta: { disableRouteKey: true},
    beforeEnter: requirePermissionMessenger,
    component: () => import(/* webpackChunkName: "mobilemessengerlist" */ '../views/Messenger/MessengerList.vue')
  },
  {
    path: '/m/messenger/:staffId',
    props: true,
    name: 'MobileMessengerDetail',
    // meta: { disableRouteKey: true},
    beforeEnter: requirePermissionMessenger,
    component: () => import(/* webpackChunkName: "mobilemessengerdetail" */ '../views/Messenger/MessengerDetail.vue')
  },
  {
    path: '/change-password',
    name: 'ChangePassword',
    beforeEnter: requireAuth,
    props: true,
    component: () => import(/* webpackChunkName: "changepassword" */ '../views/Auth/ChangePassword.vue')
  },
  {
    path: '/notification-preferences/:staffId',
    name: 'StaffNotificationPreferences',
    props: true,
    meta: { layout: 'BareLayout'},
    component: () => import(/* webpackChunkName: "staffnotificationpreferences" */ '../views/Staff/StaffNotificationPreferences.vue')
  },
  {
    path: '/reports',
    name: "ReportView",
    props: true,
    beforeEnter: requireAuth,
    component: () => import(/* webpackChunkName: "reportsIndex" */ '../views/Reports/ReportView.vue'),
    redirect: {name: 'ReportsIndex'},
    children: [
      {
        path: '',
        name: "ReportsIndex",
        beforeEnter: requireAuth,
        component: () => import(/* webpackChunkName: "reportsIndex" */ '../views/Reports/ReportsIndex.vue')
      },
      {
        path: 'vehicle-type',
        name: 'VehicleByType',
        beforeEnter: requireAuth,
        component: () => import(/* webpackChunkName: "VehicleByTypeReport" */ '../views/Vehicles/components/VehicleByTypeReport/VehicleByTypeReport.vue')
      },
      {
        path: 'missing-phone-number',
        name: 'MissingPhoneNumberReport',
        beforeEnter: requireAuth,
        component: () => import(/* webpackChunkName: "MissingPhoneNumberReport" */ '../views/Staff/MissingPhoneNumberReport.vue')
      },
      {
        path: 'imported-performance-data',
        name: 'DaPerformanceImportedData',
        beforeEnter: requireAuth,
        props: true,
        component: () => import(/* webpackChunkName: "DspPerformanceReport" */ '../views/Performance&Coaching/Performance/components/CompanyScoreCardList.vue')
      },
      {
        path: 'da-issues-trend',
        name: 'DaIssueTrendReport',
        beforeEnter: requireAuth,
        component: ()=> import(/* webpackChunkName: "daIssueIndex" */ '../views/DaIssues/DaIssueTrendReport.vue')
      },
      {
        path: 'da-issues-trend-graphs',
        name: 'DaIssueTrendGraphsReport',
        beforeEnter: requireAuth,
        component: ()=> import(/* webpackChunkName: "daIssueTrendGraphs" */ '../views/DaIssues/DaIssueTrendGraphsReport.vue')
      },
      {
        path: 'eoc-metrics',
        name: 'EOCMetricsReport',
        beforeEnter: requireAuth,
        component: ()=> import(/* webpackChunkName: "eocMetricsReport" */ '../views/Eoc/EOCMetricsReport.vue')
      },
      {
        path: 'repeat-offenders',
        name: 'RepeatOffendersReport',
        beforeEnter: requireAuth,
        component: () => import(/* webpackChunkName: "repeatOffendersReport" */ '../views/DaIssues/reports/RepeatOffendersReport.vue')
      },
      {
        path: 'pps-metrics',
        name: 'PPSMetricsReport',
        beforeEnter: requireAuth,
        component: () => import(/* webpackChunkName: "pPSMetricsReport" */ '../views/Pps/PPSMetricsReport.vue')
      },
      {
        path: 'expiring-license-plates',
        name: 'VehicleLicensePlate',
        prop: true,
        beforeEnter: requireAuth,
        component: () => import(/* webpackChunkName: "vehicleList" */ '../views/Vehicles/components/Radios/VehicleList.vue'),
      },
      {
        path: 'week-old-odometer-readings',
        name: 'VehicleWeekOldOdometerReadings',
        prop: true,
        beforeEnter: requireAuth,
        component: () => import(/* webpackChunkName: "vehicleList" */ '../views/Vehicles/components/Radios/VehicleList.vue'),
      },
      {
        path: 'two-week-old-odometer-readings',
        name: 'VehicleTwoWeekOldOdometerReadings',
        prop: true,
        beforeEnter: requireAuth,
        component: () => import(/* webpackChunkName: "vehicleList" */ '../views/Vehicles/components/Radios/VehicleList.vue'),
      },
      {
        path: 'high-mileage-odometer-readings',
        name: 'VehicleHighMileageOdometerReadings',
        prop: true,
        beforeEnter: requireAuth,
        component: () => import(/* webpackChunkName: "vehicleList" */ '../views/Vehicles/components/Radios/VehicleList.vue'),
      },
      {
        path: 'vehicle-statistics',
        name: 'VehicleStatistics',
        beforeEnter: requireAuth,
        component: () => import(/* webpackChunkName: "vehiclestatistics" */ '../views/Vehicles/components/VehicleStatistics/VehicleStatisticsReport.vue')
      },
    ]
  },
  {
    path: '/settings',
    beforeEnter: requireAuth,
    component: Settings,
    children: settingRoutes 
  },
  {
    path: '/system',
    beforeEnter: requireAuth,
    component: System,
    children: systemRoutes 
  },
  {
    name: 'Search',
    path: '/search',
    component: SearchIndex,
    beforeEnter: requirePermissionSearch
  },
  {
    path: '/reports/milestones',
    props: true,
    name: 'AnniversariesSummary',
    beforeEnter: requireAuth,
    component: () => import(/* webpackChunkName: "milestonesdashboard" */ '../views/Staff/components/AnniversariesSummary.vue')
  },
  {
    path: '/devices',
    props: true,
    name: 'Devices',
    beforeEnter: requireAuth,
    component: () => import(/* webpackChunkName: "milestonesdashboard" */ '../views/Devices/DeviceIndex.vue')
  },
  {
    path: '/devices/import',
    props: true,
    name: 'DevicesImport',
    beforeEnter: requireAuth,
    component: () => import(/* webpackChunkName: "milestonesdashboard" */ '../views/Devices/DeviceImport.vue')
  },
  {
    path: '/s3/:token',
    name: 'DownloadLink',
    beforeEnter: requireAuth,
    meta: { layout: 'BareLayout'},
    component: () => import(/* webpackChunkName: "downloadlink" */ '../views/S3/DownloadLink.vue')
  },
  {
    path: '/u/:shortenId',
    name: 'RedirectShortenId',
    beforeEnter: ShortenIdMiddleware
  },
  {
    path: '/error/shorten-url',
    name: 'Error404',
    meta: { layout: 'BareLayout'},
    component: () => import(/* webpackChunkName: "error404" */ '../views/System/Error404.vue')
  },
  {
    path: '*',
    component: () => import('../views/System/NotFound.vue'),
    beforeEnter: require404Page
  },
]

const maintenanceRoute = [
  {
    path: '*',
    name: 'Maintenance',
    meta: { layout: 'BareLayout' },
    component: () => import(/* webpackChunkName: "maintenance" */ '../views/System/Maintenance.vue')
  }
]

let selectedRoutes = (maintenanceMode === 'true') ? maintenanceRoute : routes

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes: selectedRoutes
})

function requireAuth(to, from, next){
  window.scrollTo(0, 0);
  if(!store.getters.isLoggedIn ){
    next({
      path: '/',
      query: { redirect: to.fullPath }
    })
  }else{
    // window.Intercom('update')
    next()
  }
}

function requireAuthForV2(to, from, next) {
  window.scrollTo(0, 0);
  if(!store.getters.isLoggedIn ){
    next({
      path: '/',
      query: { redirect: to.fullPath }
    })
  }
  else if(store.getters.isLoggedIn && process.env.VUE_APP_HERA_ENV !== 'development'){
    next({
      path: from.path,
      query: { error: "You Don't Have Access To This Area" }
    })
  }
  else{
    // window.Intercom('update')
    next()
  }
}

function requirePermissionAccidents(to, from, next){
    window.scrollTo(0, 0);
    if(!store.getters.isLoggedIn){
        next({
            path: '/',
            query: { redirect: to.fullPath }
        })
    }
    else if(USER_PERMISSIONS && !store.state.userInfo.permissionAccidents){
      next({
        path: from.path,
        query: { error: "You Don't Have Access To This Area" }
      })
    }else{
      next()
    }
}

function requirePermissionInjuries(to, from, next){
    window.scrollTo(0, 0);
    if(!store.getters.isLoggedIn){
        next({
            path: '/',
            query: { redirect: to.fullPath }
        })
    }
    else if(USER_PERMISSIONS && !store.state.userInfo.permissionInjuries){
      next({
        path: from.path,
        query: { error: "You Don't Have Access To This Area" }
      })
    }else{
      next()
    }
}

function requirePermissionManageCounselings(to, from, next){
    window.scrollTo(0, 0);
    if(!store.getters.isLoggedIn){
        next({
            path: '/',
            query: { redirect: to.fullPath }
        })
    }
    else if (store.getters.hasLogguedSupportAdministrator) {
      next();
    }
    else if((USER_PERMISSIONS && !store.state.userInfo.permissionManageCounselings) ){
      next({
        path: from.path,
        query: { error: "You Don't Have Access To This Area" }
      })
    }else{
      next()
    }
}

function requirePermissionMessenger(to, from, next){
    window.scrollTo(0, 0);
    if(!store.getters.isLoggedIn){
      next({
        path: '/',
        query: { redirect: to.fullPath }
      });
    } else if (store.getters.isLoggedIn && !store.getters.hasMessengerManagement) {
      next();
    }
    else {
      // window.Intercom('update')
      new HeraManagementRouteClass(to,next).management()
    }
}

function requirePermissionDAManagement(to, from, next){
    window.scrollTo(0, 0);
    if(!store.getters.isLoggedIn){
      next({
        path: '/',
        query: { redirect: to.fullPath }
      });
    } else if(store.getters.isLoggedIn && !store.getters.hasDAManagement){
        next({
            path: '/da-management'
        })
    }
    else {
      next()
    }
}

function requirePermissionSearch(to, from, next){
  if(store.getters.isLoggedIn && !store.getters.hasDAManagement && !store.getters.hasVehicleManagementPermission){
    next({
      path: '/',
      query: { redirect: to.fullPath }
    });

  }
  else {
    next()
  }
}

function require404Page(to, from, next) {
    window.scrollTo(0, 0);
    if(!store.getters.isLoggedIn){
      next({
        path: '/',
        query: { redirect: to.fullPath }
      });
    }
    else {
      next()
    }
}

 router.beforeEach((to, from, next) => {
  if (to.name === 'VehicleDetail' || to.name === 'StaffDetail') {
    next();
  } else {
    store.dispatch('clearVehicleListFiltersForRDS');
    store.dispatch('clearAssociateListFiltersForRDS');
    next();
  }
});


export default router
