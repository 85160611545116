import { API, graphqlOperation } from 'aws-amplify';
import { premiumStatusHistoryByGroup } from "@/scripts/queries/queries.js";

/**
 * @params {tenant} this object contains the tenant properties
 * @params {subscriptions} define subscriptions
 * @example 
 * const tenant = this.userInfo.tenant
   const suscriptions = ['rostering','trial','bundle']
   validateIsPremium(tenant,suscriptions)
 * @description It is validated that the tenant has included the subscriptions, also by default the bundle and non-expired trial are included
 */

export const validateIsPremium = function (tenant, subscriptions = []) {
    if (!Object.entries(tenant).length > 0) return
    if (!subscriptions.length > 0) return
    let result = true
    let accountIsTrial = false
    let accountHasNotTrial = false
    let accountPremiumStatus = tenant?.accountPremiumStatus
    let trialExpDate = new Date(tenant.trialExpDate).getTime();
    if (!accountPremiumStatus) accountPremiumStatus = []
    accountPremiumStatus = JSON.parse(JSON.stringify(accountPremiumStatus))
    accountIsTrial = subscriptions.filter((e) => {
        return accountPremiumStatus.includes(e) && e === 'trial'
    }).length > 0
    accountHasNotTrial = subscriptions.filter((e) => accountPremiumStatus.includes(e) && e !== 'trial').length > 0
    // skip iteration if account doesn't have premium or an active trial
    if ((accountIsTrial && trialExpDate < new Date().getTime()) || (!accountIsTrial && !accountHasNotTrial)) {
        result = false
    }
    return result
}

export const validateHadPremium =  async function (tenant, subscriptions = []) {

    if (!Object.entries(tenant).length > 0) return
    if (!subscriptions.length > 0) return
    let result = false
    let countIsPremium = 0 
    let listPremiumStatusHistory = await getPremiumStatusHistory(tenant)
    if (!listPremiumStatusHistory) listPremiumStatusHistory = []
    if (listPremiumStatusHistory.length > 0){
        listPremiumStatusHistory.forEach(e => {
            e?.accountPremiumStatus.forEach(f => {
                if(subscriptions.includes(f)) countIsPremium++
            }) 
        });
    }else{
        console.log(`The Company ${tenant.companyName} does not have a premium history`)
    }

    if(countIsPremium > 0) result = true
    return result
}

export const getPremiumStatusHistory = async function (tenant) {
    var input = {
        group: tenant.group,
        sortDirection: "ASC"
    }
    var historyPremiumStatus = []
    try {
        historyPremiumStatus = await API.graphql(graphqlOperation(premiumStatusHistoryByGroup, input))
    } catch (error) {
        console.error(error)
        const data = error.data.premiumStatusHistoryByGroup.items
        return data
    }

    return historyPremiumStatus.data?.premiumStatusHistoryByGroup?.items || []
}

export default {
    validateIsPremium,
    validateHadPremium,
    getPremiumStatusHistory
}