import { Auth, API } from 'aws-amplify';


export async function updateZohoAccountTenant(operationType, payload, displayUserError){
    try{
        let apiName = 'zoho'
        let path = '/zoho/tenant'
        let method;

        switch (operationType) {
            case 'create':
                method = safeFunction(API.post);
                break;
            case 'update':
                method = safeFunction(API.put);
                break;
            case 'delete':
                method = safeFunction(API.del);
                break;
            default:
                throw new Error('Invalid operation type');
        }

        let post = {
            body: {
            data:[payload]
            },
            headers: {
                'Content-Type' : 'application/json',
                Authorization: `${(await Auth.currentSession()).getAccessToken().getJwtToken()}`
            }
        }
        const result = await method(apiName, path, post)
        return result
    }catch(e){
        console.error('error updating tenant',e)
        displayUserError(e)
    }
}


export async function updateZohoAccountUser(operationType, payload, displayUserError){
    try{
        let apiName = 'zoho'
        let path = '/zoho/user'
        let method;

        switch (operationType) {
            case 'create':
                method = safeFunction(API.post);
                break;
            case 'update':
                method = safeFunction(API.put);
                break;
            case 'delete':
                method = safeFunction(API.del);
                break;
            default:
                throw new Error('Invalid operation type');
        }

        let post = {
            body: {
            data:[payload]
            },
            headers: {
                'Content-Type' : 'application/json',
                Authorization: `${(await Auth.currentSession()).getAccessToken().getJwtToken()}`
            }
        }
        const result = await method(apiName, path, post)
        return result
    }catch(e){
        console.error('error updating tenant',e)
        displayUserError(e)
    }
}