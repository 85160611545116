import { 
	createOptionsCustomListsStaff,
	deleteOptionsCustomListsStaff,
	createOptionsCustomLists,
	updateStaff
}from '@/graphql/mutations'
import { mapState, mapActions } from 'vuex'

export default {

	data() {
		return {
			customListTypeError:{
				['parking-space'] : 'Parking Spaces',
				['vehicle-type'] : 'Vehicle Types',
				['authorize-to-drive'] : 'Authorize to Drive'
			}
		}
	},
	created() {
	},
	computed: {
		...mapState([
			'userInfo'
		]),
	},
	methods: {
		...mapActions([
			'setCustomListOption'
		]),

		/**
		 * create new Custom List Options
		 * @param {Object} items objects with items with the info requiered to create Vehicle Type
		 * @param {String} type requiered to created the correct Custom List option
		 * @returns 
		 */

    async createCustomListOptions(items) {

      if(!items || !items.length) return
      
      await Promise.all(items.map(async item => {
        this.cleanOptionCustomListInput(item)
        try {
          const input = {
            ...item
          }
          await this.api(createOptionsCustomLists, { input });
        }catch (e) {
          this.printUserError(e)
          this.displayUserError(e)
        }
      }))
    },

		/**
		 * create new Custom List Options
		 * @param {String} type requiered to created the correct List Option
		 * @returns 
		 */
    getCustomListMessageErrorByType(type){
      return `One or more of the names used for ${this.customListTypeError[type]} in your file is longer than 30 characters. Please ensure all ${this.customListTypeError[type]}${type == 'authorize-to-drive' ? ' names': ''} are 30 characters or less and re-upload your file.`
    }
	},
}
