<template>
  <TwemojiTextarea
    ref="refTwemojiTextarea"
    :style="minimumHeight"
    :class="[!showEmojiPicker ? 'showEmojiPicker' : '', 'flex-grow']"
    :placeholder="placeholder"
    :emojiData="emojiDataAll"
    :emojiGroups="emojiGroups"
    :searchEmojisFeat="true"
    :skinsSelection="true"
    :twemojiPath="pathsTwemoji.assets"
    pickerWidth="#twemoji-textarea-outer"
    :initialContent="initialContent"
    @contentChanged="onContentChanged"
    @enterKey="onEnterKey"
    @contentChangedHtml="onContentChangedHtml"
  >
    <template v-slot:twemoji-picker-button>
      <slot name="prepend"></slot>
      <slot name="emoji-button">
        <button type="button" id="btn-emoji-default">
          <div class="fade-in">
            <img
              class="emoji"
              draggable="false"
              alt="😃"
              :src="pathsTwemoji.img"
            />
          </div>
        </button>
      </slot>
      <slot name="append"></slot>
    </template>
  </TwemojiTextarea>
</template>

<script>
import { TwemojiTextarea } from "@kevinfaguiar/vue-twemoji-picker";
import EmojiAllData from "@kevinfaguiar/vue-twemoji-picker/emoji-data/en/emoji-all-groups.json";
import EmojiGroups from "@kevinfaguiar/vue-twemoji-picker/emoji-data/emoji-groups.json";
import {
  PATH_ASSETS_TWEMOJI,
  PATH_IMG_TWEMOJI,
} from "@/utilities/constants/messages";

export default {
  name: "EmojiTextArea",
  components: { TwemojiTextarea },
  props: {
    placeholder: {
      type: String,
      default: "",
    },
    initialContent: {
      type: String,
      default: "",
    },
    minHeight: {
      type: Number,
      default: 68,
    },
  },
  data() {
    return {
      pathsTwemoji: {
        assets: "",
        img: "",
      },
    };
  },
  beforeMount() {
    this.pathsTwemoji.assets = PATH_ASSETS_TWEMOJI;
    this.pathsTwemoji.img = PATH_IMG_TWEMOJI;
  },
  computed: {
    showEmojiPicker() {
      return !this.isIos() && !this.isAndroid();
    },

    emojiDataAll() {
      return EmojiAllData;
    },

    emojiGroups() {
      return EmojiGroups;
    },

    minimumHeight() {
      return `min-height: ${this.minHeight}px;`;
    },
  },
  methods: {
    onContentChangedHtml(event) {
      this.$emit("content-changed-html", event);
    },

    onEnterKey(event) {
      // need double \n\n on end of string to go to new line with single enter press
      const textlength = this.$refs.refTwemojiTextarea.twemojiTextarea.innerHTML.length;
      if (!textlength) return;
      const cursorPosition = this.getCaretCharacterOffsetWithin(this.$refs.refTwemojiTextarea.twemojiTextarea);
      if ( textlength - cursorPosition == 0) {
        if(this.$refs.refTwemojiTextarea.twemojiTextarea.innerHTML.substr(textlength) == ''){
            this.addText("\n\n");
        }
      }else{
        this.addText("\n");
      }
      
    },

    onContentChanged(event) {
      this.$emit("content-changed", event);
    },

    addText(message) {
      this.$refs.refTwemojiTextarea.addText(message);
    },

    reset() {
      this.$refs.refTwemojiTextarea.cleanText();
    },

    setText(message) {
      this.$refs.refTwemojiTextarea.cleanText();
      this.$refs.refTwemojiTextarea.addText(message);
    },

    // reference https://stackoverflow.com/questions/4811822/get-a-ranges-start-and-end-offsets-relative-to-its-parent-container/4812022#4812022
    getCaretCharacterOffsetWithin(element) {
      var caretOffset = 0;
      var doc = element.ownerDocument || element.document;
      var win = doc.defaultView || doc.parentWindow;
      var sel;
      if (typeof win.getSelection != "undefined") {
        sel = win.getSelection();
        if (sel.rangeCount > 0) {
          var range = win.getSelection().getRangeAt(0);
          var preCaretRange = range.cloneRange();
          preCaretRange.selectNodeContents(element);
          preCaretRange.setEnd(range.endContainer, range.endOffset);
          caretOffset = preCaretRange.toString().length;
        }
      } else if ((sel = doc.selection) && sel.type != "Control") {
        var textRange = sel.createRange();
        var preCaretTextRange = doc.body.createTextRange();
        preCaretTextRange.moveToElementText(element);
        preCaretTextRange.setEndPoint("EndToEnd", textRange);
        caretOffset = preCaretTextRange.text.length;
      }
      return caretOffset;
    },
  },
};
</script>

<style lang="scss" scoped></style>