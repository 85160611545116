<template>
  <div class="rounded-md border shadow p-2 flex flex-auto whitespace-normal break-all justify-between">
    <el-checkbox
        v-model="handleModel"
        :disabled="disabled"
        @change="updateColumn">
      <div class="grid grid-cols-12 grid-flow-row gap-2 flex">
        <div class="col-span-10 flex flex-auto item-label">
          {{ item.label }}
        </div>
      </div>
    </el-checkbox>
    <div class="flex justify-end justify-items-center content-icons text-gray-600">
      <slot name="icons"/>
    </div>
  </div>
</template>

<script>
export default {
  name: 'CheckboxPill',
  props: {
    item: {
      type: Object,
      required: true,
      default: null
    },
    model: {
      type: Boolean,
      required: true,
      default: null
    },
    disabled: {
      type: Boolean,
      required: false,
      default: null
    },
  },
  data() {
    return {
      checkboxModel: null
    }
  },
  computed: {
    handleModel: {
      get() {
        return this.model
      },
      set(value) {
        this.checkboxModel = value
      }
    }
  },
  methods: {
    updateColumn() {
      this.$emit('update-checkbox', {...this.item, newValue: this.checkboxModel})
    }
  }
}

</script>

<style lang="scss" scoped>
.item-label {
  font-size: 13px !important;
}
</style>