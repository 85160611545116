<template>
  <div v-loading="loading">
    <div class="flex justify-between mb-4">
        <h2>Customer plan changes in the last 30 days</h2>
        <el-dropdown  
            trigger="click" 
            @command="handleTopLevelCommand">
            <i class="icon-button uil uil-cog hover:text-blue-500"/>
            <el-dropdown-menu slot="dropdown" id="menu">
                <el-dropdown-item :command="{ action: 'charge' }">Charge All Payment Error Invoices</el-dropdown-item>
            </el-dropdown-menu>
        </el-dropdown>
    </div>

    <table-card tablecard-class="rounded-lg">
      <template v-slot:table-filters>
        <container-filter inTable>
          <template v-slot:title>
            <span>CUSTOMER PLAN CHANGES</span>
          </template>
          <template v-slot:filter-count>
            <counter-filter :counter="getResumeFilters" />
          </template>
          <template v-slot:filters>
            <MultiSelectFilter
              type="typeOfChange"
              title="Type of Change"
              :options="statusItems"
              :selected="statusChecked"
              @update-filters="updateFilters"
            />
          </template>
        </container-filter>
      </template>
      <template v-slot:table-searchbox>
        <search-box inTable @search-data="searchData"></search-box>
      </template>
      <custom-table
        inTable
        ref="table"
        v-loading="loading"
        :columns="cols"
        :data="filteredTableData"
        emptyText="No Data"
        footerTable="total Tenants" 
        :filtersTotal="getResumeFilters"
        :totalRecords="tableCount"  
        :totalFilteredRecords="filteredTableData.length"
        :optionsRows="optionsContextRow"
        @click-menu="handleCommand"
        class="premiums-tatus-class with-fixed"
        withTableMenu
        @export-data="onExportData"
        showDefaultExportOptions
      >

        <template #companyName="row">
          <span class="pl-2 inline-block">{{ row.companyName }}</span>
        </template>

        <template #createdAt="row">
          <span @click="handleRowClick(row)">{{ row.createdAt | moment('M/D/YYYY') }}</span>
        </template>

        <template #trial="row">
          <p v-if="row.trial === VALUE_MINUS"><i class="text-2xl el-icon-minus"></i></p>
          <p v-else-if="row.trial === VALUE_REMOVED"><i class="text-red-700 text-2xl el-icon-remove-outline"></i></p>
          <p v-else-if="row.trial === VALUE_PLUS"><i class="text-blue-700 text-2xl el-icon-circle-plus-outline"></i></p>
          <p v-else-if="row.trial === VALUE_CHECK"><i class="text-blue-700 text-2xl el-icon-circle-check"></i></p>
        </template>

        <template #trialExpDate="row">
          <p v-if="row.trial === VALUE_MINUS"><i class="text-2xl el-icon-minus"></i></p>
          <p v-else>{{ row.trialExpDate | moment('M/D/YYYY') }}</p>
        </template>

        <template #bundle="row">
          <p v-if="row.bundle === VALUE_MINUS"><i class="text-2xl el-icon-minus"></i></p>
          <p v-else-if="row.bundle === VALUE_REMOVED"><i class="text-red-700 text-2xl el-icon-remove-outline"></i></p>
          <p v-else-if="row.bundle === VALUE_PLUS"><i class="text-blue-700 text-2xl el-icon-circle-plus-outline"></i></p>
          <p v-else-if="row.bundle === VALUE_CHECK"><i class="text-blue-700 text-2xl el-icon-circle-check"></i></p>
        </template>

        <template #performance="row">
          <p v-if="row.performance === VALUE_MINUS"><i class="text-2xl el-icon-minus"></i></p>
          <p v-else-if="row.performance === VALUE_REMOVED"><i class="text-red-700 text-2xl el-icon-remove-outline"></i></p>
          <p v-else-if="row.performance === VALUE_PLUS"><i class="text-blue-700 text-2xl el-icon-circle-plus-outline"></i></p>
          <p v-else-if="row.performance === VALUE_CHECK"><i class="text-blue-700 text-2xl el-icon-circle-check"></i></p>
        </template>

        <template #rostering="row">
          <p v-if="row.rostering === VALUE_MINUS"><i class="text-2xl el-icon-minus"></i></p>
          <p v-else-if="row.rostering === VALUE_REMOVED"><i class="text-red-700 text-2xl el-icon-remove-outline"></i></p>
          <p v-else-if="row.rostering === VALUE_PLUS"><i class="text-blue-700 text-2xl el-icon-circle-plus-outline"></i></p>
          <p v-else-if="row.rostering === VALUE_CHECK"><i class="text-blue-700 text-2xl el-icon-circle-check"></i></p>
        </template>

        <template #staff="row">
          <p v-if="row.staff === VALUE_MINUS"><i class="text-2xl el-icon-minus"></i></p>
          <p v-else-if="row.staff === VALUE_REMOVED"><i class="text-red-700 text-2xl el-icon-remove-outline"></i></p>
          <p v-else-if="row.staff === VALUE_PLUS"><i class="text-blue-700 text-2xl el-icon-circle-plus-outline"></i></p>
          <p v-else-if="row.staff === VALUE_CHECK"><i class="text-blue-700 text-2xl el-icon-circle-check"></i></p>
        </template>

        <template #vehicles="row">
          <p v-if="row.vehicles === VALUE_MINUS"><i class="text-2xl el-icon-minus"></i></p>
          <p v-else-if="row.vehicles === VALUE_REMOVED"><i class="text-red-700 text-2xl el-icon-remove-outline"></i></p>
          <p v-else-if="row.vehicles === VALUE_PLUS"><i class="text-blue-700 text-2xl el-icon-circle-plus-outline"></i></p>
          <p v-else-if="row.vehicles === VALUE_CHECK"><i class="text-blue-700 text-2xl el-icon-circle-check"></i></p>
        </template>

        <template #churn="row">
          <p v-if="row.churn === VALUE_MINUS"><i class="text-2xl el-icon-minus"></i></p>
          <p v-else>{{ row.churn | moment('M/D/YYYY') }}</p>
        </template>
        
      </custom-table>
    </table-card>
    <div v-if="isMat">
      <el-collapse class="mt-4">
        <el-collapse-item title="Total Amount Invoiced">
          <TotalAmountInvoiced />
        </el-collapse-item>
      </el-collapse>
    </div>

    <!-- EXPORT DIALOG -->
    <el-dialog 
      title="Export Customer Plan Changes" 
      :show-close="true" 
      :before-close="handleClose" 
      :center="true"
      :visible.sync="showExportDialog" 
      width="390px">
      <ExportDialog 
        :modal="true" 
        :statusChecked="getStatusChecked()" 
        :number="getResultTotal" 
        exportField="customer plan change"
        exportFields="customer plan change" 
        @export-action="handleExportData" 
        @close-dialog="handleClose" 
        :hideFilters="!isFiltered">
      </ExportDialog>
    </el-dialog>
  </div>
</template>

<script>
import { listTenants, invoicesByStatus } from './queries';
import TotalAmountInvoiced from './components/TotalAmountInvoiced.vue';
import TableCard from "@/components/TableCard/TableCard";
import CustomTable from "@/components/TableCard/CustomTable";
import ContainerFilter from "@/components/TableCard/ContainerFilter";
import ExportDialog from '@/components/ExportDialog.vue';
import SearchBox from "@/components/TableCard/SearchBox";
import CounterFilter from "@/components/CounterFilters";
import MultiSelectFilter from "@/components/MultiSelectFilter";
import { API, graphqlOperation } from 'aws-amplify';
import { multipleDefaultFilter } from '@/components/filtersFunctions';
const getTenant = /* GraphQL */ `
  query GetTenant($id: ID!) {
    getTenant(id: $id) {
      id
      group
    }
  }
`

export default {
  components: { 
    TotalAmountInvoiced,
    TableCard,
    CustomTable,
    ContainerFilter,
    ExportDialog,
    CounterFilter,
    MultiSelectFilter,
    SearchBox,
  },
  data() {
    return {
      VALUE_CHECK: 'check',
      VALUE_PLUS: 'plus',
      VALUE_REMOVED: 'removed',
      VALUE_MINUS: 'minus',
      loading: false,
      showExportDialog: false,
      isFiltered: false,
      tableData: [],
      search: '',
      limit: 100,
      statusItems: [
        'Added',
        'Removed',
        'Bundle',
        'Removed Bundle',
        'Trial',
        'Converted',
        'Churned',
      ],
      checkAllStatus: true,
      isMat: false,
      optionsContextRow: [
        { label: "View DSP", action: 'view', divided: false }
      ],
      cols: [
        { name: "Company Name", col: "companyName", fixed: true, width: "180", sortable: true, sortMethod: this.sortCompanyName },
        { name: "Signup Date", col: "createdAt", fixed: false, width: "130", sortable: true,  sortMethod:this.sortCreatedAt},
        { name: "Active Users", col: "activeUsers", fixed: false, width: "150", sortable: true, sortMethod: this.sortActiveUsers},
        { name: "Trial", col: "trial", fixed: false, width: "120", sortable: true, sortMethod: this.sortTrial },
        { name: "Trial End", col: "trialExpDate", fixed: false, width: "150", sortable: true, sortMethod: this.sortTrialExpDate },
        { name: "Bundle", col: "bundle", fixed: false, width: "100", sortable: true, sortMethod: this.sortBundle },
        { name: "Performance & Coaching", col: "performance", fixed: false, width: "240", sortable: true, sortMethod: this.sortPerformance },
        { name: "Daily Rostering", col: "rostering", fixed: false, width: "200", sortable: true, sortMethod: this.sortRostering },
        { name: `${ this.$t('label.associate') } Management`, col: "staff", fixed: false, width: "240", sortable: true, sortMethod: this.sortStaff },
        { name: "Vehicle Management", col: "vehicles", fixed: false, width: "240", sortable: true, sortMethod: this.sortVehicles },
        { name: "Churn", col: "churn", fixed: false, width: "100", sortable: true, sortMethod: this.sortChurn },
      ],
      tableCount: 0
    }
  },

  mounted() {
    this.loadData();
    this.setIsMat();
  },

  computed: {
    currentDateFormatted: function () {
      return this.$moment().format('YYYY-MM-DD')
    },

    statusChecked: {
      get(){
        const type = "typeOfChange"
        const query = this.$route.query[type]
        const filtersList = this.statusItems
        let defaultFilter = this.statusItems
        
        return multipleDefaultFilter(query, defaultFilter, filtersList)
      },
      set(items){},
    },

    filterStatusDisplay: function () {
      let joinNames = '',
        str = this.statusChecked.join(', ')

      if (this.statusChecked.length === 1) {
        joinNames = this.statusChecked[0]
      } else if (this.statusChecked.length === 2) {
        joinNames = `${this.statusChecked[0]}, ${this.statusChecked[1]}`
      } else if (this.statusChecked.length >= 3) {
        joinNames = `${str.slice(0, 30)}... + ${this.statusChecked.length - 2}`
      }

      return this.statusChecked.length ? joinNames : 'Filter'
    },

    filteredTableData() {
      return this.filterArray(this.filteredData, this.search);
    },

    filteredData: function () {

      if (!this.statusChecked.length) {
        return [];
      }

      if (this.checkAllStatus) {
        return this.tableData;
      }

      let filteredTable = []

      if (this.statusChecked.includes('Added')) {
        let filter = this.tableData.filter((tenant) => {
          return Object.values(tenant).includes(this.VALUE_PLUS)
        })
        filteredTable = [...filteredTable, ...filter]
      }

      if (this.statusChecked.includes('Removed')) {
        let filter = this.tableData.filter((tenant) => {
          return Object.values(tenant).includes(this.VALUE_REMOVED)
        })
        filteredTable = [...filteredTable, ...filter]
      }

      if (this.statusChecked.includes('Bundle')) {
        let filter = this.tableData.filter(
          (tenant) => tenant.bundle === this.VALUE_PLUS
        )
        filteredTable = [...filteredTable, ...filter]
      }

      if (this.statusChecked.includes('Removed Bundle')) {
        let filter = this.tableData.filter(
          (tenant) => tenant.bundle === this.VALUE_REMOVED
        )
        filteredTable = [...filteredTable, ...filter]
      }

      if (this.statusChecked.includes('Trial')) {
        let filter = this.tableData.filter(
          (tenant) => tenant.trial === this.VALUE_PLUS
        )
        filteredTable = [...filteredTable, ...filter]
      }

      if (this.statusChecked.includes('Converted')) {
        let filter = this.tableData.filter((tenant) => tenant.converted)
        filteredTable = [...filteredTable, ...filter]
      }

      if (this.statusChecked.includes('Churned')) {
        let filter = this.tableData.filter(
          (tenant) => tenant.churn !== this.VALUE_MINUS
        )
        filteredTable = [...filteredTable, ...filter]
      }

      return [...new Set(filteredTable)]
    },

    getResumeFilters() {
      return this.statusChecked.length === this.statusItems.length ? 0 : 1;
    },

    getResultTotal() {
      return this.filteredTableData.length || this.tableCount
    },
  },

  methods: {

    setIsMat: function() {
      const email = this.$store.state.userInfo.email;
      if (email == process.env.VUE_APP_EMAIL_MAT) {
        this.isMat = true;
      }
    },
    getActiveUsers: function (tenant) {
      return tenant.users.items.filter((user) => user.permissionLogin).length
    },

    getChurn: function (tenant) {
      if (!tenant.premiumStatusHistory.items.length) {
        return this.VALUE_MINUS
      }

      let values = Object.values(tenant)

      if (
        values.includes(this.VALUE_REMOVED) &&
        !values.includes(this.VALUE_PLUS) &&
        !values.includes(this.VALUE_CHECK)
      ) {
        return this.sortDesc(tenant.premiumStatusHistory.items)[0].createdAt
      }

      return this.VALUE_MINUS
    },

    getConverted: function (tenant) {
      return (
        tenant.trial === this.VALUE_REMOVED &&
        Object.values(tenant).includes(this.VALUE_PLUS)
      )
    },

    handleCheckAllChange: function (val) {
      this.statusChecked = val ? this.statusItems : []
    },

    handleCheckedStatusChange: function (value) {
      let checkedCount = value.length
      this.checkAllStatus = checkedCount === this.statusItems.length
      this.filterStatus = value[value.length - 1]
    },

    handleCommand: function (payload) {
      let row = payload.row
      let action = payload.action

      if (action == 'view') {
        this.$router.push({
          name: 'SystemTenantDetail',
          params: { id: row.id },
        })
      }
    },

    handleTopLevelCommand: async function (payload){
        let action = payload.action

        if (action == 'charge') {
            
            const today = new Date()
            let year = today.getFullYear()
            let month = today.getMonth()
            const months = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December']
            // deal with new year
            if(month == 0){
                month = 11
                year--
            }
            else{
                month--
            }

            // find all invoices/tenants with payment failed as status
            let input = {
                status: "Payment Error", 
                yearMonth: {eq: {
                  month: month, year: year
                }}
            }
            
            let paymentErrorInvoices = await this.gLoadListAll(invoicesByStatus, input, 'invoicesByStatus')
            
            // loop through found tenants and retry payment capture
            if(paymentErrorInvoices.length){
                this.$confirm('This will charge all invoices for tenants that have the status of "Payment Error" for the month of ' + months[month] + ' ' + year + '. Continue?', 'Warning', {
                    confirmButtonText: 'Charge Invoices',
                    cancelButtonText: 'Cancel',
                    type: 'warning'
                }).then( async () => {
                    let apiName = 'stripeSetup'
                    let post = {body: {}}
                    paymentErrorInvoices.forEach(async (invoice) => {
                        let tenantId = invoice.tenant.id
                        
                        // first make sure tenant still exists
                        let tenant
                        try{
                          tenant = await this.api(getTenant, { id: tenantId })
                        }catch(e){
                          return this.displayUserError(`Error finding tenant with ID: ${tenantId}.`)
                        }

                        if(!tenant.data?.getTenant){
                          return this.displayUserError(`Error finding tenant with ID: ${tenantId}.`)
                        }
                        
                        let path = '/payment-intent/' + tenantId
                        let result

                        try{
                          result = await API.post(apiName, path, post)
                        }catch(e){
                          return this.displayUserError(`Error with the tenant's payment info. Tenant ID: ${tenantId}. Invoice ID: ${invoice.id}`)
                        }
                        
                        let allInvoicesPaid = result?.allInvoicesPaid
                        // check if all invoices were charged successfully
                        if(allInvoicesPaid){
                            this.displayUserNotification({
                                title: 'Success',
                                type: "success",
                                message: `All outstanding invoices charged successfully for ${invoice.tenant.companyName}.`,
                                duration: 5000,
                            })
                        }
                        else{
                            // display error that not all invoices were charged successfully
                            this.displayUserError(`All outstanding invoices were not charged successfully for ${invoice.tenant.companyName}.`)
                            
                        }
                    })
                }).catch((e) => {
                    console.error(e)
                    console.error("canceled charge")         
                });
            }else{
              this.$alert('No invoices with payment errors were found', 'No Results', { confirmButtonText: 'OK' })
            }
        }
    },

    makeComparison: function (tenant, feature) {
      let currentStatus = tenant.accountPremiumStatus
      let statusHistory = tenant.premiumStatusHistory.items

      if (!statusHistory.length) {
        if (currentStatus?.includes(feature)) {
          if (
            feature === 'trial' &&
            tenant.trialExpDate < this.currentDateFormatted
          ) {
            return this.VALUE_REMOVED
          }
          return this.VALUE_PLUS
        }
        return this.VALUE_MINUS
      }

      let lastStatus = this.sortDesc(statusHistory)[0].accountPremiumStatus

      if (currentStatus.includes(feature) && lastStatus.includes(feature)) {
        return this.VALUE_CHECK
      }

      if (currentStatus.includes(feature) && !lastStatus.includes(feature)) {
        return this.VALUE_PLUS
      }

      if (!currentStatus.includes(feature) && lastStatus.includes(feature)) {
        return this.VALUE_REMOVED
      }

      if (!currentStatus.includes(feature) && !lastStatus.includes(feature)) {
        return this.VALUE_MINUS
      }
    },

    loadData: async function () {
      this.loading = true
      let input = {}

      try {
        let allTenants = await this.gLoadListAll(
          listTenants,
          input,
          'listTenants'
        )

        this.tableData = this.parseTable(allTenants)
        this.tableData = this.makeCalculation(this.tableData)
        this.tableCount = this.tableData.length;

        this.loading = false
      } catch (e) {
        this.loading = false
        this.displayUserError(e)
      }
    },

    async handleExportData() {
      this.showExportDialog = false;
      this.displayUserNotification({
        title: "Export",
        type: "info",
        message: `Exported CSV file is being generated. Notification will be sent with download link once ready.`,
      });
      let input = {}

      let body = {
        type: 'dynamo',
        query: listTenants,
        variable: input,
        owner: this.$store.state.userInfo.cognitoSub,
        group: this.$store.state.userInfo.tenant.group,
        isFormattingRequired: true,
        report: 'customerPlanChanges',
        titleNotification: 'Customer Plan Changes'
      }

      if(this.isFiltered) {
        body.filters = {
          statusChecked: this.statusChecked,
          checkAllStatus: this.checkAllStatus,
          search: this.search
        }
      }

      let req = {
        body: body,
      }

      try {
        await API.post('csvDataExport', '/data-export', req);
      } catch (e) {
        this.displayUserError(e)
      } finally {
        this.isFiltered = false;
      }
    },
    handleClose() {
      this.showExportDialog = false;
      this.isFiltered = false;
    },
    onExportData(type) {
      this.showExportDialog = true;
      this.isFiltered = type == 'filtered' ? true : false;
    },
    getStatusChecked() {
      return [
        {
          filter: 'Type of Change',
          values: this.statusChecked
        }
      ]
    },

    makeCalculation: function (tenants) {
      return tenants.map((tenant) => {
        return {
          ...tenant,
          churn: this.getChurn(tenant),
          converted: this.getConverted(tenant),
        }
      })
    },

    parseTable: function (tenants) {
      return tenants.map((tenant) => {
        return {
          ...tenant,
          activeUsers: this.getActiveUsers(tenant),
          trial: this.makeComparison(tenant, 'trial'),
          bundle: this.makeComparison(tenant, 'bundle'),
          performance: this.makeComparison(tenant, 'performance'),
          rostering: this.makeComparison(tenant, 'rostering'),
          staff: this.makeComparison(tenant, 'staff'),
          vehicles: this.makeComparison(tenant, 'vehicles'),
        }
      })
    },

    sortDesc: function (array) {
      return array.sort(function (a, b) {
        return new Date(b.createdAt) - new Date(a.createdAt)
      })
    },

    updateFilters (e){
      const { filters } = e;
      this.statusChecked = filters
      this.checkAllStatus = this.statusChecked.length === this.statusItems.length;
    },

    searchData(e) {
      this.search = e;
    },

    sortCompanyName(a, b) {
      return a.companyName.toLowerCase().localeCompare(b.companyName.toLowerCase());
    },
    
    sortCreatedAt(a, b) {
      return new Date(a.createdAt) - new Date(b.createdAt);
    },
    
    sortActiveUsers(a,b) {
      return a.activeUsers - b.activeUsers;
    },
    
    sortTrial(a, b) {
      return a.trial.toLowerCase().localeCompare(b.trial.toLowerCase());
    },
    
    sortTrialExpDate(a, b) {
      if(a.trial === 'minus') {
        a.trialExpDate = '1900-01-01'
      }
      if(b.trial === 'minus') {
        b.trialExpDate = '1900-01-01'
      }
      return new Date(a.trialExpDate) - new Date(b.trialExpDate);
    },
    
    sortBundle(a, b) {
      return a.bundle.toLowerCase().localeCompare(b.bundle.toLowerCase());
    },
    
    sortPerformance(a, b) {
      return a.performance.toLowerCase().localeCompare(b.performance.toLowerCase());
    },
    
    sortRostering(a, b) {
      return a.rostering.toLowerCase().localeCompare(b.rostering.toLowerCase());
    },
    
    sortStaff(a, b) {
      return a.staff.toLowerCase().localeCompare(b.staff.toLowerCase());
    },
    
    sortVehicles(a, b) {
      return a.vehicles.toLowerCase().localeCompare(b.vehicles.toLowerCase());
    },

    sortChurn(a, b) {
      if(a.churn === 'minus') {
        a.churn = '1900-01-01'
      }
      if(b.churn === 'minus') {
        b.churn = '1900-01-01'
      }
      return new Date(a.churn) - new Date(b.churn);
    },
  },
}
</script>

<style>
.filter-btn {
  width: 200px;
  height: 32.5px;
  border-radius: 0 !important;
  border-radius: 0 0.5rem 0.5rem 0 !important;
  box-shadow: none !important;

  @media (min-width: 400px) {
    width: 230px;
  }

  @media (min-width: 768px) {
    width: 270px;
  }
}

.filter-badge {
  width: 150px;
  height: 32.5px;
  font-size: 13px;
  color: #606266;
  padding-top: 6px;
  border: 1px solid #dcdfe6;
  border-radius: 0.5rem 0 0 0.5rem;
}

.with-fixed div.left-0   {
  margin-left: 11.2rem;
}

.premiums-tatus-class .el-table th.el-table__cell > .cell {
  display: flex !important;
  align-items: center;
  vertical-align: middle;
  text-align: center;
}

.premiums-tatus-class .el-table th:first-child.el-table__cell > .cell {
  padding-left: 18px;
}
</style>