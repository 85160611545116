import { mixinMethods } from "@/main";

export class HeraPathClass {
    to = null

    constructor(to) {
        this.to = to
    }

    getValidatedPath() {
        return this.isMobile() ? '/m/messenger' : '/messenger'
    }

    isMobile() {
        return mixinMethods.isIos() || mixinMethods.isAndroid()
    }

    isPathCorrect() {
        return this.isPathInDesktopCorrect() || this.isPathInMobileCorrect()
    }

    isPathInMobileCorrect() {
        return this.isMobile() && this.to.path.includes('/m/')
    }

    isPathInDesktopCorrect() {
        return this.isDesktop() && !this.to.path.includes('/m/')
    }

    isDesktop() {
        return !this.isMobile()
    }
}
