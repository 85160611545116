export const COUNSELING_STATUS_SIGNED = 'SIGNED';
export const COUNSELING_STATUS_NOT_SENT = 'NOT_SENT';
export const COUNSELING_STATUS_REFUSED_TO_SIGN_BY_ASSOCIATE = 'REFUSED_TO_SIGN_BY_ASSOCIATE';
export const COUNSELING_STATUS_REFUSED_TO_SIGN_BY_HERA_USER = 'REFUSED_TO_SIGN_BY_HERA_USER';
export const COUNSELING_STATUS_PENDING_SIGNATURE = 'PENDING_SIGNATURE';

export const COUNSELING_TYPE_INFORMAL_WARNING = 'INFORMAL_WARNING';
export const COUNSELING_TYPE_FORMAL_VERBAL_WARNING = 'FORMAL_VERBAL_WARNING';
export const COUNSELING_TYPE_FINAL_WARNING = 'FINAL_WARNING';
export const COUNSELING_TYPE_DISMISSAL = 'DISMISSAL';

