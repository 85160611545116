<template>
  <div id="tabs-container">
    <el-tabs type="card" @tab-click="handleTab" v-model="activeTab" :class="{'special-tab-pane': customTab}">
      <el-tab-pane v-for="{ label, path, key } in normalTabs" :name="path" :label="label" :key="key">
        <slot :name="`tabpane-${key}`"></slot>
      </el-tab-pane>
      <template v-if="customTab" >
        <el-tab-pane :name="customTab.path" :key="customTab.key">
          <span slot="label">{{customTab.label}}</span>
          <slot name="CUSTOM">
            Content Custom Pane
          </slot>
        </el-tab-pane>
      </template>
    </el-tabs>
  </div>
</template>
<script>
export default {
  name: "TabCard",
  props: {
    // data prop
    tabs: {
      type: Array,
      required: true,
      default: () => {
        return [ 
          { label: "Tab 1", path: "tab_1" }, 
          { label: "Tab 2", path: "tab_2" }, 
          { label: "Tab 3", path: "tab_3" }
        ]
      }
    },
    currentTab: {
      type: String,
      require: false,
      default: ""
    },
  },
  created() {
    if ( this.veirifyTabs ) {
      const currentTab = this.activeTab;
      const { fullPath: currentPath } = this.$router.currentRoute;
      const [baseURLPath, urlTab] = currentPath.split("/tab/");
      if (urlTab) {
        this.changeUrlPath( baseURLPath, urlTab );
        this.activeTab = urlTab;
      } else {
        this.changeUrlPath( baseURLPath, currentTab );
      }
    }
  },
  data() {
    return {
      activeTab: this.currentTab
    }
  },
  computed:{
    normalTabs(){
      return this.tabs.filter(tab => tab.key !== "CUSTOM")
    },
    customTab(){
      return this.tabs.find(tab => tab.key === "CUSTOM")
    }
  },
  methods: {
    handleTab( tab ) {
      const { name: currentTab } = tab;
      const { fullPath: currentPath } = this.$router.currentRoute;
      const [baseURLPath, urlTab] = currentPath.split("/tab/");
      if (urlTab) {
        this.changeUrlPath( baseURLPath, currentTab );
      } else {
        this.changeUrlPath( currentPath, currentTab );
      }
    },
    changeUrlPath( currentPath, currentTab ) {
      history.pushState(
        {},
        null,
        `${currentPath}/tab/${currentTab}`.replace(/\/\//g,'/')
      )
    },
    veirifyTabs() {
      return this.tabs.length > 0;
    }
  }
}
</script>
<style>
#tabs-container .el-tabs__nav {
  width: 100% !important;
  border-top: 0;
  border-right: 0;
  border-top-left-radius: .75rem;
}
#tabs-container .el-tabs__nav .el-tabs__item {
  border-top: 1px solid #e2e8f0;
}
#tabs-container .el-tabs__nav .el-tabs__item:first-child {
  border-top-left-radius: .75rem;
}
#tabs-container .special-tab-pane .el-tabs__nav .el-tabs__item:nth-last-child(2) {
  border-right: 1px solid #e2e8f0;
  border-top-right-radius: .75rem;
}
#tabs-container .el-tabs__nav .el-tabs__item:last-child {
  border-right: 1px solid #e2e8f0;
  border-radius: .75rem;
}
#tabs-container .el-tabs__nav .el-tabs__item:last-child{
  border-top-left-radius: unset;
}
#tabs-container .special-tab-pane .el-tabs__nav .el-tabs__item:last-child{
  right: 0;
  position: absolute;
  border-top-left-radius: .75rem;
}
#tabs-container .el-tabs__nav-wrap {
  height: 40px;
}
#tabs-container .el-tabs__header{
  margin-bottom: 0px !important;
}
</style>