import { API, graphqlOperation } from 'aws-amplify';
import store from '@/store'
"use strict";

const TIME_TO_RETRY_SUBSCRIPTION = 5000
const MAX_ATTEMPTS_PER_SUBSCRIPTION = 3

export const subscriptions = {}

export const configureSubscriptions = function(array){
    array.forEach(config => config(subscriptions))
}

const resetSubs = function(sub){
    sub.socket.unsubscribe()
    delete sub.socket
    delete sub.instance
} 

export const initializeSubscription = function(pubs, queries, input, storeMutacion, queryName){
    const subs = pubs.subscription
    let instances = queryName ? [queryName] : Object.keys(queries)
    try {
        instances.forEach(instance => {
            (!subs[instance]) && (subs[instance] = {}) 
            const event = subs[instance]
            !event.instance && (event.instance = API.graphql(
                graphqlOperation(queries[instance], input)
            ))
            !event.socket && (event.socket = event.instance.subscribe({
                next: (payload) => store.dispatch(storeMutacion, payload),
                error: handleSubscriptionError(instance, pubs)
            }))
            console.log(`listening %c${instance}`, 'color:green;font-weight: bold;')
        })
    } catch (subscriptionSetupError) {
        handleSubscriptionError("setup", pubs)(subscriptionSetupError)
    }
}

export const disconnectSubscription = function(subscription, specificInstance){
    if(specificInstance){
        resetSubs(subscription[specificInstance])
    }else{
        const events = Object.keys(subscription)
        events.forEach(evt => resetSubs(subscription[evt]))
    }
}

export const loadList = function(pubs){
    if(!pubs.loadList) return
    const lastUpdated = pubs.lastUpdated
    if(lastUpdated){
        const timeToReload = lastUpdated + (TIME_TO_RETRY_SUBSCRIPTION * MAX_ATTEMPTS_PER_SUBSCRIPTION)
        const now = +(new Date)
        if(timeToReload <= now){
            pubs.loadList()
        }
    }else{
        pubs.loadList()
    }
    pubs.lastUpdated = +(new Date)
}

export const handleSubscriptionError = function(instance, pubs){
    return async function(error){
        const errors = error.error?.errors
        if(instance === 'setup' || error.message === 'No current user' || errors?.some(e=>e.message === 'No current user')){
            pubs.unsubscribe()
        }else{
            const evt = pubs.subscription[instance]
            pubs.unsubscribe(instance)
            !evt.attempts && (evt.attempts = 0)
            if(evt.attempts <= MAX_ATTEMPTS_PER_SUBSCRIPTION){
                evt.attempts++
                setTimeout(function(){
                    loadList(pubs)
                    pubs.subscribe(instance)
                }, TIME_TO_RETRY_SUBSCRIPTION)
            }else{
                console.log("Max retry limit reached", {instance, error})
            }
        }
    }
}

export const setList = function(state, { list = [], listName, logger }){
    const elementList = state[listName]
    elementList.length = 0
    elementList.push(...list)
    updateRenderKey(elementList)
    logger && console.log(`${listName} Stored.`)
}

export const performMutation = function(list, element, eventName){
    if(!element?.id) return
    let results
    switch(eventName){
        case 'onCreate':{
            const exists = list.some(item => item.id === element.id)
            //To prevent duplicates
            if(!exists){
                list.push(results = element)
            }
            break
        }
        case 'onUpdate':{
            results = list.find(item => item.id === element.id)
            updateElement(results, element, true)
            break
        }
        case 'onDelete':{
            const index = list.findIndex(item => item.id === element.id)
            //To make sure the item to remove exists in the list
            if(index > -1){
                results = list.splice(index, 1)
            }
            break
        }
        default:{
            throw {message: "performMutation", list, element}
        }
    }
    updateRenderKey(list)
    return results
}

export const handleElement = async function(subscriptionState, payload, mutationHandler){
    const { entity, subscriptionPayload } = payload
    const mutationsEvents = subscriptionPayload.value.data
    const pubs = subscriptions[entity]
    const keys = Object.keys(mutationsEvents)
    for(const queryName of keys){
        const element = mutationsEvents[queryName]
        let gsi
        if(['ByGroup', 'ByTenant'].some(suff => queryName.includes(suff))){
            gsi = /(?<eventName>(onCreate|onUpdate|onDelete))(?<tableName>[\w\d]+)(ByGroup|ByTenant|ByOwner)/g.exec(queryName)?.groups
        }else{
            gsi = /(?<eventName>(onCreate|onUpdate|onDelete))(?<tableName>[\w\d]+)/g.exec(queryName)?.groups
        }
        const {eventName, tableName} = gsi || {}
        const data = (await mutationHandler?.(element, eventName, payload)) || element
        pubs.callback?.(subscriptionState, { data, eventName, tableName, queryName, subscription: subscriptionPayload})
    }
    pubs.lastUpdated = +(new Date)
}

export const mutateElement = async function(subscriptionState, payload){
    await handleElement(subscriptionState, payload, (element, eventName, { listName }) => {
        return performMutation(subscriptionState[listName], element, eventName)
    })
}

export const configureActionsForLists = function (payload){
    return payload.reduce((map, { setList, mutateElement, listName, entity })=>{
        map[setList] = (context, list) => context.commit("setList", {list, listName, logger: true})
        map[mutateElement] = (context, subscriptionPayload) => context.commit("mutateElement", {subscriptionPayload, listName, entity})
        return map
    }, {})
}

export const configureActionsForNestedRecords = function (payload){
    return payload.reduce((map, { handleElement, entity })=>{
        map[handleElement] = (context, subscriptionPayload) => context.commit("handleElement", {subscriptionPayload, entity})
        return map
    }, {})
}

export const updateElement = function(oldItem, newItem, updateNested){
    if(!oldItem) return newItem
    if(!newItem) return oldItem
    Object.entries(newItem).forEach(([key, value])=> {
        if(typeof value === 'object' && value !== null){
            if(!value &&( typeof oldItem[key] === 'object')){
                oldItem[key] = value
            }
            else if(!Array.isArray(value)){
                if(!oldItem[key] || oldItem[key].__ob__){
                    delete oldItem[key]
                    oldItem[key] = value
                }else{
                    updateElement(oldItem[key], value, updateNested)
                }
            }
            else if(updateNested){
                !oldItem[key] && (oldItem[key] = [])
                const oldArray = oldItem[key]
                const newArray = value
                const updatedArray = newArray.map(n=>{
                    const match = oldArray.find(o => n.id === o.id)
                    if(typeof match === 'object')
                        return updateElement(match, n, updateNested)
                    else{
                        return n
                    }
                })  
                oldItem[key].length = 0
                oldItem[key].push(...updatedArray)
            }
        }else{
            oldItem[key] = value
        }
    })
    return oldItem
}

export const updateRenderKey = function(obj, renderKey = 'lastRenderKey'){
    obj[renderKey] = +(new Date)
}