<template>
    <router-link class="nav-button h-12 md:h-30 rounded-none md:pt-3 md:pb-3" :to="to">
        <i class="py-0 text-3xl md:text-3md block h-5 md:h-10 mb-2" :class="icon"></i>
        <span class="text-2sm leading-1 hidden md:block nav-title leading-5" v-html="title"></span>
        <slot name="default"></slot>
    </router-link>
</template>

<script>
export default {
    props:['title', 'to', 'icon']
}
</script>

<style lang="scss" scoped>

[class*=" el-icon-"], [class^=el-icon-] {
    line-height: inherit !important;
    display: block !important;
}

</style>

