<template>
  <div class="multiselect-filter mx-2">
    <el-dropdown :trigger="dropdownTrigger" :hide-on-click="false" :disabled="isDisabled" ref="parentMenu">
      <span class="el-dropdown-link flex items-baseline">
        <span :class="{'text-sm font-bold': isFilterActive}">{{ title }}</span>
        <span class="ml-1 py-0.5 px-1 rounded-lg bg-gray-100" >
          {{ !showPillFilter && options.length > 0 ? filterWordBeforeTitle : filters.length }}
        </span>
        <i class="el-icon-arrow-down el-icon--right"></i>
      </span>
      <el-dropdown-menu slot="dropdown">
        <div class="h-2">
          <i @click="closeSubNav" class="el-icon-close toggle-close-icon active absolute right-1 top-1 p-1 text-lg opacity-50"></i>
        </div>
        <el-dropdown-item key="all">
          <el-checkbox v-if="options.length > 1" @change="selectAll" v-model="isCheckedAll" :checked="checkAll" :disabled="options.length <= 1">{{filterWordBeforeTitle}} {{ filterAllTitle || title }}</el-checkbox>
        </el-dropdown-item>
        <el-dropdown-item v-if="options.length > 1" divided />
        <el-checkbox-group :min="1" class="flex flex-col"  v-model="filters" @change="handleChecked" :disabled="checkAll">
          <el-dropdown-item v-for="(item, i) in options" :label="item" :key="i" class="flex content-between row-filter "> 
            
            <el-checkbox :label="item" class="w-full relative" :ref="item" :value="true">
              {{ item }}
              <el-tooltip class="item leading-10 z-40 " effect="dark" placement="bottom" v-if="!showOnly( item )">
                <div slot="content"> 
                    <p class="font-bold mb-1 text-baseline">You cannot uncheck this option because you must have at least one option checked.</p>
                </div>
                <span class="tooltip"></span>
              </el-tooltip>
            </el-checkbox>
            <span v-if="!checkAll">
              <span v-if="showOnly( item )" class="text-xs pl-2" :ref="`ref-${item}`"   @click="handleOnly( item )">only</span>
            </span>
          </el-dropdown-item>
        </el-checkbox-group>
      </el-dropdown-menu>
    </el-dropdown>
  </div>
</template>

<script>

export default {
  name: "MultiSelectFilter",

  props: {
    title: {
      type: String,
      required: true,
      default: "MultiSelect Filter"
    },

    filterAllTitle: {
      type: String,
      default: ''
    },

    filterWordBeforeTitle: {
      type: String,
      required: false,
      default: 'All'
    },
    
    type: {
      type: String,
      required: true,
      default: "default"
    },

    options: {
      type: Array,
      required: true,
      default: () => []
    },

    selected: {
      type: Array,
      required: false,
      default: () => []
    },

    isDisabled: {
      type: Boolean,
      required: false,
      default: false
    }
  },

  computed: {
        
    computedStyleAll() {
      return this.checkAll;
    },

    computedStyleNone() {
      return this.uncheckAll;
    },

    showPillFilter() {
      return this.filters.length !== this.filterOptions.length;
    },

    isFilterActive() {
      return this.filters.length !== this.filterOptions.length;
    },

      },
  mounted() {
    this.filters = this.selected
    this.filterOptions = this.options
    this.isCheckedAll = this.areEqualsFiltersAndOptions( this.filters, this.options )
    this.checkAll = this.areEqualsFiltersAndOptions( this.filters, this.options )
  
    if(this.options.length != 1){
      this.checkMinSelect( [...this.filters] )
    }
  },
  beforeMount(){
    if( !this.checkAll && this.filters.length === 1 ){
      this.uniqueChecked = [...this.filters]
    } 
    if(window.innerWidth < 769){ 
      this.dropdownTrigger = 'click'
    }
  },
  beforeUpdate(){
    if(this.options.length != 1){
      this.checkMinSelect( [...this.filters] )
    } 
  },
  data() {
    return {
      filters: [],
      filterOptions: [],
      checkAll: false,
      isCheckedAll: false,
      uncheckAll: false,
      dropdownTrigger: 'hover',
      uniqueChecked: []
    }
  },
  watch:{
    selected( newValue ){
      this.filters = newValue
      if(newValue.length != 1){
        this.checkMinSelect( [...newValue] )
      } 
    },
    options( newValue, oldValue ){
      this.filterOptions = newValue
      this.isCheckedAll = this.areEqualsFiltersAndOptions( this.filters, newValue )
      this.checkAll = this.areEqualsFiltersAndOptions( this.filters, newValue )
      if(newValue.length != 1){
        this.checkMinSelect( [...this.filters] )
      } 
      
    }
  },

  methods: {
    closeSubNav(){
      const mp = this.$refs.parentMenu
      mp.hide() 
    },
    handleChecked( value ) {
      if( this.areEqualsFiltersAndOptions(value, this.filterOptions) ){
        this.checkAll = true
        this.isCheckedAll = true
      }
      this.checkMinSelect( value )      
      this.updateFiltersApplied();
    },
    handleOnly( item ){
      
      this.uniqueChecked = [item]
      this.unselectAll()
      this.filters = [item] 

      this.checkMinSelect( this.filters )
      this.updateFiltersApplied();
    },

    selectAll() {
      this.checkAll = !this.checkAll;
      this.uncheckAll = false;
      this.filters = this.options;
      if( this.uniqueChecked.length ){
        this.restoreClassUniqueChecked()
      }
      
      if( !this.checkAll ){
        this.checkMinSelect( this.filters )

      }

      // only loads when the user will select "all options"
      // turn off when the filter is "unselect"
      if(this.filters.length && this.selected.length == this.filters.length) return
      this.updateFiltersApplied();
    },

    unselectAll() {
      this.uncheckAll = true;
      this.checkAll = false;
      this.filters = [];     
    },

    areListsEqual(listA, listB) {
      // Check if the lengths are equal
      if (listA.length !== listB.length) {
        return false;
      }

      // Check if both arrays have the same elements, regardless of order
      return listA.every(element => listB.includes(element));
    },
    updateFiltersApplied() {
      let type = this.type;
      const filters = this.filters;
      
      

      const listsAreEqual = this.areListsEqual(filters, this.options);

      let indexes = [];
      if (listsAreEqual) indexes = [-1]
      else indexes = filters.map(item => this.options.indexOf(item));

      const isSameRoute = this.$route.query[type] === indexes.join(',');
      if (!isSameRoute) {
      // Update the URL with the new filters
        this.$router.replace({
          query: {
            ...this.$route.query,
            [type]: indexes.join(','),
          },
        });

        this.$emit('update-filters', {
          filters,
          type
        })
      }

    },

    checkMinSelect( value  ){
      if( value.length === 1 ){
        setTimeout( () => {
          // We let the DOM (checkbox input) load before removing a class.
          this.uniqueChecked = [...value]
          const [item] = value
          const [refCheckBox] = this.$refs[item]

          const initialInput = 0
          const nodeCheckBox = refCheckBox?.$el.childNodes[initialInput]
          if(nodeCheckBox)
            nodeCheckBox.classList.remove('is-disabled')
        }, 150)

      }
    },

    restoreClassUniqueChecked(){
      const [item] = [...this.uniqueChecked]

      const [refCheckBox] = this.$refs[item]

      const initialInput = 0
      const nodeCheckBox = refCheckBox.$el.childNodes[initialInput]
      
      nodeCheckBox.classList.add('is-disabled')
    },
    
    showOnly( item ){
      const filterChecked = [...this.filters]
      return !this.areEqualsFiltersAndOptions( filterChecked, [item] )
    },

    areEqualsFiltersAndOptions( a, b ) {
      return this.isSameLength(a, b) && this.itsIncluded( a, b );
    },

    itsIncluded( a, b ) {
      return a.every( ( element ) => b.includes( element ) )
    },

    isSameLength( a, b ) {
      return a.length === b.length;
    }
    
  }
}
</script>

<style lang="scss" scoped>

.select-custom {
  text-decoration: underline;
  color: #0268BC;
  font-weight: 500;
}

.unselect-custom {
  text-decoration: none;
  color: inherit;
  font-weight: normal;
}


.multiselect-filter {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  flex-wrap: nowrap;
}
.row-filter > span{
  display: none;
}

.row-filter:hover > span{
  display: block;
}

.tooltip{
  position: absolute;
  bottom: 10px;
  left: 1px;
  width: 16px;
  height: 16px;
}

.el-dropdown-menu{
  max-height: 90vh;
  overflow-y: auto;
}

</style>