import {
    onCreateInfractionByGroup,
    onUpdateInfractionByGroup,
    onDeleteInfractionByGroup
} from '../../subscriptions';
import { recalculateDaPerformanceData } from '@/views/DaPerformanceReport/calculateDaPerformanceReport'
import store from '@/store'
import { initializeSubscription, disconnectSubscription, updateElement } from '../helper'

"use strict";
let subscriptionInfractions = null

export const infractionSubscribe = function(instance){
    const queries = {
        onCreateInfractionByGroup,
        onUpdateInfractionByGroup,
        onDeleteInfractionByGroup
    }
    if(!store.state.userInfo?.tenant?.group) return
    const input = {
        group: store.state.userInfo.tenant.group
    }
    initializeSubscription(subscriptionInfractions, queries, input, "subscriptionStore/handleInfraction", instance)
}

export const config = function(subscriptions){
    const subscription = {}
    subscriptionInfractions = subscriptions['infraction'] = {
        loadList: null,
        subscribe: infractionSubscribe,
        unsubscribe: (instance) => disconnectSubscription(subscription, instance),
        callback: infractionEventCallback,
        subscription
    }
}

export const infractionEventCallback = async function(state, { data: element, eventName }){

    const eventNames = {
        'onCreate': () => {
            const exists = state.infractionList.some(item => item.id === element.id)
            if(!exists){
                state.infractionList.push(element)
            }
        },

        'onUpdate': () => {
            const match = state.infractionList.find(item => item.id === element.id)
            if(match){
                updateElement(match, element)
            }else{
                state.infractionList.push(element)
            }
        },

        'onDelete': () => {
            const index = state.infractionList.findIndex(item => item.id === element.id)
            if(index > -1){
                state.infractionList.splice(index, 1)
            }
        }
    }
    eventNames[eventName]()
    await recalculateDaPerformanceData(false)
}