<template>
  <div data-cy="settings-vehicle-photo-logs-form">
    <h1 class="mb-4">Vehicle Photo Logs</h1>

    <div class="card" v-loading="loading.uploadType">
      <div class="card-title">SETTINGS FOR ASSOCIATES</div>
      <el-form ref="form" :model="tenant" label-position="top" class="-mb-4">
        <el-form-item>
          <template slot="label">
            Allow Photo Uploads
            <el-tooltip class="item" effect="dark" :placement="isDeviceMobileOnly() ? 'bottom' : 'right-start'">
              <div slot="content" class="w-80">
                By default, {{ $t("label.associates") }} are only allowed to
                take new photos with their device camera when you send them a
                Vehicle Photo Log creation link from Hera. With this setting,
                you can allow them to upload existing photos from their photo
                library instead. Note: Hera Users will always be able to upload
                photos from their library; this setting only applies to
                {{ $t("label.associates") }} who are not logged into Hera.
              </div>
              <i class="uil uil-question-circle text-base"></i>
            </el-tooltip>
          </template>

          <el-select
            data-cy="sc-upload-sel"
            class="mr-3 w-50"
            v-model="tenant.allowLibraryUpload"
            @change="updateTenant()"
          >
            <el-option
              v-for="item in uploadTypeOptions"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </el-form-item>
      </el-form>
    </div>
  </div>
</template>

<script>
import { updateTenant } from "./shared/mutation";
import { mapState } from 'vuex';
import moment from "moment-timezone";

export default {
  name: "SettingsVehiclePhotoLog",
  components: {},
  data() {
    return {
      tenant: {},
      loading: {
        uploadType: false,
      },
    };
  },

  created() {

    this.tenant = this.userInfo.tenant;

  },

  computed: {

    ...mapState(['userInfo']),  

    today() {
      return moment.tz(this.timeZone).format();
    },

    timeZone() {
      return this.getTenantTimeZone();
    },

    uploadTypeOptions() {

      const options = [
        { value: false, label: "No, allow camera only" },
        { value: true, label: "Yes, allow camera and library" },
      ];

      return options;

    },

  },

  methods: {

    /** Update the tenant in the DB */
    async updateTenant() {

      this.loading.uploadType = true

      try {

        const { id, group, allowLibraryUpload } = this.tenant
        const updatedAt = this.today

        const input = {
            id,
            group,
            allowLibraryUpload,
            updatedAt
        };

       await this.api(updateTenant, { input });

        this.$message({
          message: "Vehicle Photo Log upload type updated!",
          type: "success",
          customClass: "success-message",
        });

      } catch (e) {

        this.printUserError(e)

      } finally {

        this.loading.uploadType = false;

      }
    },

  },
};
</script>
