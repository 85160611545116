<template>
   <!-- v-if="labelsDrawerIsActive" -->
<Drawer
  v-if="this.openDrawer"
  :title="customTitle"
  :visibility="this.openDrawer"
  @close-drawer="handleClose"
>             
  <template #drawerContent>
    <div class="p-5">
      <LabelDrawerForm
      ref="labelDrawerForm"
      :openMode="openMode"
      :updateLabelName="updateLabelName"
      :defaultLabelType="labelTypeOptions"
      ></LabelDrawerForm>
    </div>
  </template>
  <template #drawerfooter>
    <DrawerFooter
    labelButton="Save"
    :buttonLoading="submitLoading"
    @cancel="handleClose"
    @cbk-button="handleCommandSubmit">
    </DrawerFooter>
  </template>
</Drawer>
</template>
<script>
import Drawer from "@/components/shared/Drawer/Drawer"
import DrawerFooter from "@/components/shared/Drawer/DrawerFooter"
import { updateLabel } from "@/graphql/mutations"
import LabelDrawerForm from "./LabelDrawerForm"
import labelsMixin from "@/mixins/labelsMixin"
import store from "@/store/index";


export default {
  name: "LabelDrawer",
  props: ['openDrawer', 'openMode', 'labelData', 'defaultLabelType'],
  components: {
    Drawer,
    LabelDrawerForm,
    DrawerFooter
  },
  mixins: [
    labelsMixin,
  ],
  data() {
    return {
      customTitle: "",
      updateLabelName: "",
      labelTypeOptions: [],
      submitLoading: false,
      labelsDrawerIsActive: this.openDrawer,
      group: this.$store.state.userInfo.tenant.group,
    }
  },
  created() {

  },
  mounted() {
    this.loadDrawer()
  },
  methods:{
    // load data
    async loadDrawer(){
      if ( this.openMode === "update" ) {
        this.customTitle = `Edit label "${this.labelData.labelName}"`
        this.updateLabelName = this.labelData.labelName
        this.labelTypeOptions = this.labelData.usedFor
      } else if( this.openMode === "create" ) {
        this.customTitle = `Create a New label`
        this.labelTypeOptions = this.defaultLabelType 
      }
    },

    async handleCommandSubmit(){
        try{
            this.submitLoading = true;
            const labelName = this.$refs.labelDrawerForm.labelForm.labelName
            const type = this.$refs.labelDrawerForm.labelForm.usedForSelection
            let result = true
            // Check form
            if( !labelName && type.length == 0 ){
              this.displayUserError("Please fill out the fields correctly")
              return
            } else if( !labelName ){
              this.displayUserError("Please write a label name")
              return
            } else if( labelName.includes(';') ){
              this.displayUserError("The label name must not contain a semicolon")
              return
            }else if( type.length == 0){
              this.displayUserError("Please select one option of label type")
              return
            }
            //create mode
            if( this.openMode == "create") result = await this.labelsModule_createLabel(labelName, type)
            //update mode
            if( this.openMode == "update") result = await this.labelsModule_updateLabel(labelName, type, this.labelData)
            
            // if the user want to continue editing, result == false
            if (result != false) {
              await store.dispatch("loadLabelTypes")
              this.$emit('cbk-finally', this.openMode, result) //reload table if is create different in profileLabels
              this.handleClose() //close drawer
            }
        } catch( e ){
            console.error(e)
            this.displayUserError(e)
        } finally {
          this.submitLoading = false;
        }
    },

    //for footer
    restoreForm(){
      this.loading = false
      this.visible = false
      this.form = {}
    },
    handleClose(){
        this.restoreForm()
        this.$emit('cbk-visible', this.visible)
    },
  },
}

</script>
