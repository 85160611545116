import { API } from 'aws-amplify'

export const checkClockSync = async (browserTime) => {
    let isTimeSynchronized = true
    try {
        const apiName = 'heraPublicApi';
        const path = '/time-sync-check';
        const post = {
        body: { browserTime }
        }
        const { response } = await API.post(apiName, path, post);
        isTimeSynchronized = response
    } catch (error) {
        this.printUserError(error)
        this.displayUserNotification({
            title: "Error while synchronising time",
            type: "error",
            message: error.message
        });
    }
    return isTimeSynchronized
}