<template>
  <div v-bind="$attrs">
    <slot
      :setDisabled="setDisabled"
      :disabled="disabled"
    >
    </slot>
  </div>
</template>

<script>
export default {
  name: 'ButtonGroupWrapper',

  data() {
    return {
      disabled: false,
    };
  },

  methods: {
    async setDisabled(value) {
      this.disabled = !!value;
    },
  },
};
</script>
