export const valueMapping = {
    'Average Daily Compliance': 'EOC Daily Compliance Rate',
    'DA tier': 'CX Da Tier',
    'CdfScore': 'CX Cdf Score',
    'Overall Tier': 'Scorecard Overall Tier',
    'Consecutive Tier': 'Scorecard Consecutive Tier',
    'Scorecard Delivery Success Behaviors': 'Scorecard DSB'
}

//This value map is used to update old issues and kudos records
export const idMappings = {
    '-s-': 'scorecard',
    '-cx-': 'customerFeedback',
    '-m-': 'mentor',
    '-n-': 'netradyne',
    '-e-': 'eoc'
}