<template>
    <section id="referral-banner" class="pt-3 pb-7 text-center" :class="bg">            
        <a class="text-white block container mx-auto columns-2 gap-3 pb-1" href="https://grow.surf/csff71" target="_blank">
            <div class="w-15 inline-block">
                <i class="inline-flex relative top-1 w-14 mr-2 icon-button uil text-3xl text-white" :class="icon"></i>
            </div>                
            <div class="inline-block text-lg pb-1" v-html="copy">
            </div>
        </a>        
    </section>
</template>

<script>
export default {
    name: 'Banner',
    props:['bg', 'icon', 'copy']
}
</script>

<style lang="scss" scoped>
</style>