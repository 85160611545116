import { queriesData } from "./queriesData"

export class HeraQueriesClass {
    queries = {}

    constructor(to) {
        this.queries = to.query
    }

    getValidatedQueries() {
        const queries = {}
        for (let i = 0; i < queriesData.length; i++) {
            const query = queriesData[i];

            queries[query.key] = query.default

            if (this.isQueryCorrect(query)) {
                queries[query.key] = this.queries[query.key]
            }
        }

        return queries
    }

    isQueryCorrect({ key }) {
        if (this.existQuery(key)) {
            const value = this.queries[key]

            if (this.isValidValue(key, value)) {
                return true
            }

            return false
        }

        return false
    }

    existQuery(key) {
        return Object.prototype.hasOwnProperty.call(this.queries, key)
    }

    isValidValue(key, value) {
        const query = queriesData.find((e) => e.key === key)

        return query.values.includes(value)
    }

    areQueriesCorrect() {
        for (let i = 0; i < queriesData.length; i++) {
            const query = queriesData[i];

            if (!this.isQueryCorrect(query)) {
                return false
            }
        }

        return true
    }

}
