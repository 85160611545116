<template>
  <div>Company Time: {{ companyTime }}</div>
</template>
<script>
import moment from 'moment-timezone';
export default {
  data() {
    return {
      companyTime: "",
    };
  },
  mounted() {
    // Start the timer when the component is mounted
    this.startTimer();
  },
  beforeDestroy() {
    // Stop the timer before the component is destroyed
    this.stopTimer();
  },
  methods: {
    startTimer() {
      // Update the component every second
      this.updateCompanyTime();
      this.timer = setInterval(this.updateCompanyTime, 1000);
    },
    stopTimer() {
      // Stop the timer
      clearInterval(this.timer);
    },
    updateCompanyTime() {
      // Get the current date and time based on the tenant's timezone
      const tenantTime = moment().tz(this.getTenantTimeZone());

      // Format the date and time in the desired format
      this.companyTime = tenantTime.format("MMM DD, h:mm A");
    },
  },
};
</script>
