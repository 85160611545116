<template>
  <div class="md:max-w-5xl">
    <el-table
      ref="table"
      class="data-table"
      empty-text="No Data"
      :data="tableData"
      header-cell-class-name="table-header-cell"
      style="width: 100%"
      :summary-method="getSummaries"
      show-summary
    >
      <el-table-column label="Month" prop="month"></el-table-column>
      <el-table-column v-for="year in years" :prop="year" :label="year"  :key="year"> </el-table-column>
    </el-table>
   </div> 
</template>
<script>
import { listInvoices } from '../queries'
export default {
  data() {
    return {
      tableData: [],
      years: []
    }
  },
  mounted() {
    this.loadData()
  },
  methods: {
    loadData: async function () {
      try {
        let input = {}
        let allInvoices = await this.gLoadListAll(listInvoices, input, 'listInvoices')
        this.tableData = this.generateTable(allInvoices)
        this.years = this.getYears(allInvoices);
      } catch (e) {
        this.displayUserError(e)
      }
    },
    generateTable: function (invoices) {
      const months = [
                        {number: "1", name: 'January'},
                        {number:"2", name:'February'},
                        {number:"3", name:'March'},
                        {number:"4", name:'April'},
                        {number:"5", name:'May'},
                        {number:"6", name:'June'},
                        {number:"7", name:'July'},
                        {number:"8", name:'August'},
                        {number:"9", name:'September'},
                        {number:"10", name:'October'},
                        {number:"11", name:'November'},
                        {number:"12", name:'December'}
                    ];
      const years = this.getYears(invoices);
      const dataTable = [];
      for (const month of months) {
        const monthByYear = {};
        monthByYear['month'] = month.name
        for (const year of years) {
            let sumTotalInvoices = 0;
            for (const item of invoices) {
                if (item.month == month.number && item.year == year) {
                    sumTotalInvoices += item.invoiceTotal;
                }
            }
            monthByYear[year] = `$ ${ Math.round(sumTotalInvoices * 100) / 100 }`;
        }
        dataTable.push(monthByYear);
      }
      return dataTable;
    },
    getYears: function (invoice) {
        const years = [];
        for (const item of invoice) {
            if (!years.includes(item.year)) {
                years.push(item.year);
            }
        }

        return years.sort();
     },

     getSummaries: function(param) {
        const { columns, data } = param;
        const sums = [];
        columns.forEach((column, index) => {
          if (index === 0) {
            sums[index] = 'Total';
            return;
          }
          const values = data.map(item => Number(item[column.property].substring(2,20)));
          if (!values.every(value => isNaN(value))) {
            sums[index] = '$ ' + values.reduce((prev, curr) => {
              const value = Number(curr);
              if (!isNaN(value)) {
                return prev + curr;
              } else {
                return prev;
              }
            }, 0);
          } else {
            sums[index] = 'N/A';
          }
        });

        return sums;
      }
  },
}
</script>