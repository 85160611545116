<template>
  <div>
    <div class="flex items-center justify-between mb-4">
      <h1>Integrations</h1>
    </div>
    <p class="text-gray-600 text-base font-bold">Zoho CRM</p>
    <div class="grid grid-flow-row grid-cols-12 w-full py-3 gap-4">
      <div class="col-span-12 md:col-span-6 lg:col-span-4">
        <div>Disable Zoho CRM</div>
        <el-switch
          v-model="zohoCrmDisabled"
          :value="isZohoCrmDisabled"
          active-color="#13ce66"
          @change="toggleZohoCrm"
        >
        </el-switch>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";

export default {
  name: "Integrations",
  data() {
    return {
      zohoCrmDisabled: this.isZohoCrmDisabled,
    };
  },
  computed: {
    ...mapGetters(["isZohoCrmDisabled"]),
  },
  mounted() {
    this.zohoCrmDisabled = this.isZohoCrmDisabled;
  },
  methods: {
    ...mapActions(["updateSystemList"]),

    async toggleZohoCrm() {
      this.updateSystemList({ isZohoCrmDisabled: this.zohoCrmDisabled })
        .then((data) => {
          this.$message({
            type: "success",
            message: `Zoho CRM ${
              this.zohoCrmDisabled ? "Disabled" : "Enabled"
            }`,
          });
        })
        .catch((e) => {
          this.displayUserNotification({
            title: "Error",
            type: "error",
            message: e.errors[0].message,
          });
        });
    },
  },
};
</script>

<style lang="scss" scoped></style>
