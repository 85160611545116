import { createLabelType } from '@/graphql/mutations'

export const createDefaultTypes = async ( groupTenantId, api ) => {
    const defaultTypes = ['Associates', 'Vehicles']
    
    try{
        for( const type of defaultTypes ){
            const input = {
                group: groupTenantId,
                name: type
            }
            await api( createLabelType, {input} )
        }
    } catch( e ){
        console.error( e )
    }
}