export const listTenants = /* GraphQL */ `
  query ListTenants(
    $filter: ModelTenantFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listTenants(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        group
        createdAt
        companyName
        trialExpDate
        accountPremiumStatus
        customerSubType
        users {
          items {
            id
          }
        }
        premiumStatusHistory(sortDirection: DESC) {
          items {
            id
            accountPremiumStatus
            createdAt
            updatedAt
          }
        }
      }
      nextToken
    }
  }
`;

export const messagesByGroup = /* GraphQL */ `
  query MessagesByGroup(
    $group: String
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelMessageFilterInput
    $limit: Int
    $nextToken: String
  ) {
    messagesByGroup(
      group: $group
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        channelType
        createdAt
        updatedAt
        messageType
      }
      nextToken
    }
  }
`;
