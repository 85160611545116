<template>
    <el-form label-position="left" label-width="165px" >
        <el-form-item label="Standard">
            <el-input size="mini" v-model.number="formFields.defaultCostStandard"><template slot="prepend">$</template></el-input>
        </el-form-item>
        <el-form-item label="Bundle Discount">
            <el-input size="mini" v-model.number="formFields.defaultCostBundle"><template slot="prepend">$</template></el-input>
        </el-form-item>
        <el-form-item label="Premium Peformance">
            <el-input size="mini" v-model.number="formFields.defaultCostPerformance"><template slot="prepend">$</template></el-input>
        </el-form-item>
        <el-form-item label="Premium Rostering">
            <el-input size="mini" v-model.number="formFields.defaultCostRostering"><template slot="prepend">$</template></el-input>
        </el-form-item>
        <el-form-item :label="`Premium ${$t('label.associate')} Management`">
            <el-input size="mini" v-model.number="formFields.defaultCostStaff"><template slot="prepend">$</template></el-input>
        </el-form-item>
        <el-form-item label="Premium Vehicles">
            <el-input size="mini" v-model.number="formFields.defaultCostVehicles"><template slot="prepend">$</template></el-input>
        </el-form-item>
    </el-form>
</template>

<script>
export default {
    props:['formFields']
}
</script>