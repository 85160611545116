<template>
  <div
    id="emojies"
    ref="twemojiTextarea"
    :class="$attrs.class"
  ></div>
</template>

<script>
import Twemoji from "twemoji";
import { PATH_ASSETS_TWEMOJI } from "@/utilities/constants/messages";

export default {
  name: "MessageWithEmojie",

  props: ["bodyText"],

  computed: {
    twemojiTextarea() {
      return this.$refs.twemojiTextarea;
    },
  },

  watch: {
    bodyText: function(newVlaue, oldValue) {
      this.parseIcons();
    },
  },

  mounted() {
    this.parseIcons();
  },

  methods: {
    parseIcons: function() {
      this.twemojiTextarea.innerHTML = this.bodyText;
      Twemoji.parse(this.twemojiTextarea, { base: PATH_ASSETS_TWEMOJI });
    },
  },
};
</script>

<style>
img.emoji {
  height: 1em;
  width: 1em;
  margin: 0 0.05em 0.2em 0.1em;
  display: inline;
}
</style>
