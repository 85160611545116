<template>
    <div class="flex results-11">
        
        <el-autocomplete
            :placeholder="isMobile ? 'Search...' : placeholder"
            prefix-icon="el-icon-search"
            class="ml-1 w-full"
            :debounce="700"
            v-model="search" 
            :fetch-suggestions="loadData"
            :trigger-on-focus="false"
            @select="handleClick"
            :popper-class="isMobile ? 'results-wrap-mobile' : 'results-wrap'"
            ref="search-input"
            @focus="focus"
        >     
            <template slot-scope="{item}" class="sda">
                <div class="flex flex-col py-2" v-if="item.name!='view all' && item.link!='no-results'">
                    <div class="flex w-full">
                        <span>
                            <i class="uil mr-2" v-bind:class="{ 'uil-truck text-green-400': item.isVehicle, 'uil-user text-blue-400': !item.isVehicle }"></i>
                            {{  item.name }} 
                        </span>
                        <span 
                            class="ml-auto font-bold rounded-full bg-opacity-10 px-3 text-xs"
                            :class="setClassStatus(item.status)"    
                        >
                            {{ item.status }}
                        </span>
                    </div>
                    <small class="matching-fields pl-8" 
                    v-for="field, i in item.fields" :key="i"
                    >
                        {{ field }}: {{ item.record[mapField[field]] }}
                    </small>
                </div>
                <div v-else-if="item.link!=='no-results'" id="view-all-results" class="py-2 text-blue-600">
                    <i class="el-icon-search mr-2"></i>
                    View All {{ totalResults }} results

                </div>
                <div v-else-if="item.link=='no-results'" class="w-full no-results leading-5 pt-4 whitespace-normal">
                    {{item.name}}
                </div>
                <div v-else-if="item.name=='View Associates'" class="w-full">
                    <i class="uil mr-2" v-bind:class="{ 'uil-truck text-green-400': item.isVehicle, 'uil-user text-blue-400': !item.isVehicle }"></i>
                    {{ item.name }}
                </div>
                <div v-else-if="item.name=='View Vehicles'" class="w-full">
                    <i class="uil mr-2" v-bind:class="{ 'uil-truck text-green-400': item.isVehicle, 'uil-user text-blue-400': !item.isVehicle }"></i>
                    {{ item.name }}
                </div>
            </template>
            <template v-slot:suffix>
                <i v-if="search" @click="close" class="el-icon-close"></i>
            </template>
        </el-autocomplete>
    </div>
</template>

<script>
import { API } from 'aws-amplify'
import { mapGetters } from "vuex";

export default {
    props: {
        user: {
            type: Object,
            default: null,
            required: false
        },
        query: {
            type: Object,
            default: () => { 
                return { 
                    "Staff": ['id', 'firstName', 'lastName', 'transporterId'],
                    "Vehicle": ['id', 'name'] 
                }
            }
        },
        isMobile: {
            type:Boolean,
            default: false,
            required: false
        }
    },
    
    data(){
        return{
            search: '',
            placeholder: '',
            totalResults: 0,
            noResults: [
                { name: 'No matching Associates or Vehicles could be found.', link: 'no-results', type: null },
                { name: 'View Associate List', link: '/da-management/associate-list', isVehicle: false },
                { name: 'View Vehicle List', link: '/vehicles/vehicle-list', isVehicle: true }
            ],
            fieldMap: { "id": "ID", "firstName": "First Name", "lastName": "Last Name", "status": "Status", "transporterId": "Transporter ID", "email": "Email", "name": "Name", "vin": "VIN", "licensePlate": "License Plate", "phone": "Phone" },
            mapField: { "ID":"id", "First Name": "firstName", "Last Name":"lastName", "Status": "status", "Transporter ID":"transporterId", "Email": "email", "Name": "name", "VIN": "vin", "License Plate": "licensePlate", "Phone": "phone" },
            searchTypes: ["Vehicle", "Staff"]
        }
    },

    computed:{
    ...mapGetters([
      'hasDAManagement',
      'hasVehicleManagementPermission'
    ]),
    },

    mounted(){
        this.placeholder = 'Type to search Associates and Vehicles...'

        // set placeholder and searchable types based on permissions.
        // this component won't load if they don't have either of the two
        if(!this.hasDAManagement ){ 
            this.placeholder = 'Type to search Vehicles...'
            this.searchTypes = this.searchTypes.filter(t=>t!='Staff')
            this.noResults = this.noResults.filter(r=>r.name!='View Associate List')
            this.noResults[0]['name'] = 'No matching Vehicles could be found.'
        }
        if(!this.hasVehicleManagementPermission ){ 
            this.placeholder = 'Type to search Associates...'
            this.searchTypes = this.searchTypes.filter(t=>t!='Vehicle')
            this.noResults = this.noResults.filter(r=>r.name!='View Vehicle List')
            this.noResults[0]['name'] = 'No matching Associates could be found.'
        }

        let input = this.$refs['search-input']        
        input.$refs.input.$el.addEventListener('keyup', this.onEnterKey, false)
    },

    methods:{

        onEnterKey(e) {
            e.preventDefault()
            if(e.key === 'Enter'){
                this.close(this.search, true)
            }
        },
        close(e, searchfromEnter){
            
            this.search = ''
            //if(this.isMobile){
            if(searchfromEnter){
                this.$router.push(`/search?q=${e}`).catch(e => {})
            }
            this.$refs['search-input'].close()
            this.$refs['search-input'].$refs['input'].blur()
            //}
        },

        focus() {
            // clear previous results
            this.search = ''
            this.$refs['search-input'].suggestions = []
        },

        renderFields(fields) {  
          if(!fields) return 
            return fields.split(',').filter(f=>!!f && f.toLowerCase().indexOf('name')<0).map(field=>this.fieldMap[field])
        },
        
        async loadData(search, cb, e) {
            let results = []
            //if(this.search.length < 3) return cb([])
            let body = { 
                group: this.user.tenant.group, 
                types:this.searchTypes,
                search: this.search.replace(/\s+/g, ' '),
                matchingFields: true,
                sortField: 'rank',
                sortDirection: 'asc',
                limit: 5,
                offset: 0
            }
            try {
                
                results = await API.post('rds', '/query/Search', { body })
                this.totalResults = results.total
                if(results?.data?.length){
                    results = results.data.map(r => {
                        return {
                            type: r.type,
                            isVehicle: r.type == 'Vehicle',
                            name: r.name,
                            record: r, 
                            status: r.status,
                            fields: this.renderFields(r.fields),
                            link: r.type == 'Associate' ? `/da-management/${r.id}` : `/vehicles/${r.id}`
                        }
                    })
                   
                    if(this.totalResults > 5){
                        results.push({ name: 'view all', link: `/search?q=${this.search}`})
                    }
                    document.body.classList.remove('no-results')
                    
                } else {
                    results = this.noResults
                    this.totalResults = 0
                    document.body.classList.add('no-results')
                }

                return cb(results)
                
            } catch (e) {
                results = this.noResults
                this.totalResults = 0
            } 
            return cb(results)
        },

        async handleClick(data,e) {
            if(data.link=='no-results'){ 
                return false
            }
            this.$router.push(data.link).catch(e => {})
            setTimeout(()=>this.search='',200)
        }
    }

}
</script>
<style>
.results-wrap .el-autocomplete-suggestion .el-scrollbar__thumb,
.results-wrap-mobile .el-autocomplete-suggestion .el-scrollbar__thumb{ 
    display: none;
}
.results-wrap .el-scrollbar .el-autocomplete-suggestion__list li:nth-child(6),
.results-wrap-mobile .el-scrollbar .el-autocomplete-suggestion__list li:nth-child(6){
    border-top: 1px solid #ccc;
}
.results-wrap .el-scrollbar__wrap--hidden-default,
.results-wrap-mobile .el-scrollbar__wrap--hidden-default {
    scrollbar-width: none;
    min-height: 320px;
}
.results-wrap .el-scrollbar .el-scrollbar__wrap,
.results-wrap-mobile .el-scrollbar .el-scrollbar__wrap{
    min-height: 320px;
    display: contents;
}

#mobile-search {
    opacity: 0.0;
    transform: translateY(-30px);
    box-shadow: rgba(0,0,0,.04) 6px 6px 6px;
    border-bottom: 1px solid #eee;
}
#mobile-search.active {
    transform: translateY(0);
    opacity: 1.0 !important;
}

#mobile-search input.el-input__inner {
    border: 0;
    background: transparent;
}
.el-autocomplete-suggestion.el-popper.results-wrap-mobile {
    left: 7% !important;
    width: 86% !important;
}
.el-autocomplete-suggestion.el-popper.results-wrap-mobile .popper__arrow{
    left: 50% !important;
}
@media only screen and (max-width: 768px) {
    .el-autocomplete-suggestion.el-popper.results-wrap {
        left: 7% !important;
        width: 86% !important;
    }
    .el-autocomplete-suggestion.el-popper.results-wrap .popper__arrow{
        left: 50% !important;
    }
}
.search .el-input__suffix .el-icon-close{
    font-size: 22px;
    margin-top: 7px;
    border-radius: 15px;
    padding: 2px;
}
.el-autocomplete-suggestion .matching-fields {
    line-height: 20px;
}
.no-results .el-autocomplete-suggestion li:first-child {
    pointer-events: none;
}
/* iOS double tap issue */
.results-wrap-mobile .el-scrollbar__bar {
    opacity: 1!important;
}
</style>