<template>
    <div>
        <p class="text-xs my-2 text-gray-700">{{documentName}}</p>
        <el-progress :text-inside="true" :stroke-width="26" :percentage="percentage" :status="status"></el-progress>
    </div>
</template>

<script>
import scorecardProcess from '@/components/parse/scorecard-processor.js'


export default {
    props: ['job'],
    name: 'JobStatusIndicator',
    data(){
        return{
            percentage: 0,
            timer: null
        }
    },
    created(){
        this.processJob()
    },
    watch:{
        job: function(){
            this.processJob()
        }
    },
    computed:{
        documentName(){
            var pathArray = this.job.key.split('/')
            var pathLength = pathArray.length
            return pathArray[pathLength-1]
        },
        status(){
            if( this.job.jobStatus == "SUCCEEDED"){
                return "success"
            }
            else if(this.job.jobStatus == "ERROR"){
                return "exception"
            }
            else{
                return null
            }
        }
    },
    methods:{
        processJob(){
            var jobStatus = this.job.jobStatus
            if( jobStatus == "SUCCEEDED" || jobStatus == "ERROR"){
                clearInterval(this.timer)
                this.percentage = 100
                this.importData()
            }else{
                this.fakeProgress()
            }
        },
        async importData(){
            if(this.job.type == "scorecard"){
                
            }
        },
        fakeProgress(){
            if(this.timer){
                clearInterval(this.timer)
            }
            this.timer = setInterval(()=>{
                this.percentage += 3
                if( this.percentage >= 80){
                    clearInterval(this.timer)
                }
            }, 500)
        },
        test(){
            scorecardProcess(this.job)
        }
    }
}
</script>