//Newer versions of template should be at top
export default {
    versionLookup: [
        {
            key: "week",
            instance: 1,
            relativePos: 1,
            minPage: 1,
            transform: "cleanNumber(value)",
            pattern: "WORD::Week"  
        }
    ],
    versions:[
        {
            start: 202101,        
            fields: [
                {
                    key: "week",
                    instance: 1,
                    relativePos: 1,
                    minPage: 1,
                    transform: "cleanNumber(value)",
                    pattern: "WORD::Week"            
                }
            ],
            tables: [
                {
                    table_pattern:[
                        "employee_name",
                        "transporter_id"
                    ],
                    table_match_percent: .7,
                    table_data:[],
                    min_data: 8,
                    columns: [
                        { 
                            slug: "employee_name",
                            left: 0,
                            right: 0.07
                        },
                        { 
                            slug: "transporter_id",
                            left: 0.07,
                            right: 0.141
                        },
                        { 
                            slug: "opportunities",
                            left: 0.141,
                            right: 0.207,
                        },
                        { 
                            slug: "success",
                            left: 0.207,
                            right: 0.273,
                        },
                        { 
                            slug: "bypass",
                            left: 0.273,
                            right: 0.339,
                        },
                        { 
                            slug: "rejects",
                            left: 0.339,
                            right: 0.404,
                        },
                        { 
                            slug: "blurry_photo",
                            left: 0.404,
                            right: 0.470,
                        },
                        { 
                            slug: "human_in_the_picture",
                            left: 0.470,
                            right: 0.536,
                        },
                        { 
                            slug: "no_package_detected",
                            left: 0.536,
                            right: 0.602,
                        },
                        { 
                            slug: "package_in_car",
                            left: 0.602,
                            right: 0.668,
                        },
                        { 
                            slug: "package_in_hand",
                            left: 0.668,
                            right: 0.733,
                        },
                        { 
                            slug: "package_not_clearly_visible",
                            left: 0.733,
                            right: 0.799,
                        },
                        { 
                            slug: "package_too_close",
                            left: 0.799,
                            right: 0.865,
                        },
                        { 
                            slug: "photo_too_dark",
                            left: 0.865,
                            right: 0.931,
                        },
                        { 
                            slug: "other",
                            left: 0.931,
                            right: 1.0,
                        },
                    ]
                },
                
            ]
        },
        {
            start: 202023,        
            fields: [
                {
                    key: "week",
                    instance: 1,
                    relativePos: 1,
                    minPage: 1,
                    transform: "cleanNumber(value)",
                    pattern: "WORD::Week"            
                }
            ],
            tables: [
                {
                    table_pattern:[
                        "employee_name",
                        "transporter_id"
                    ],
                    table_match_percent: .7,
                    table_data:[],
                    min_data: 8,
                    columns: [
                        { 
                            slug: "employee_name",
                            left: 0,
                            right: 0.065968236
                        },
                        { 
                            slug: "transporter_id",
                            left: 0.075487945,
                            right: 0.134141929
                        },
                        { 
                            slug: "opportunities",
                            left: 0.134141929,
                            right: 0.208360183,
                        },
                        { 
                            slug: "success",
                            left: 0.208360183,
                            right: 0.278011924,
                        },
                        { 
                            slug: "bypass",
                            left: 0.278011924,
                            right: 0.344094454,
                        },
                        { 
                            slug: "rejects",
                            left: 0.344094454,
                            right: 0.411960253,
                        },
                        { 
                            slug: "blurry_photo",
                            left: 0.411960253,
                            right: 0.479826052,
                        },
                        { 
                            slug: "human_in_the_picture",
                            left: 0.479826052,
                            right: 0.547691852,
                        },
                        { 
                            slug: "no_package_detected",
                            left: 0.547691852,
                            right: 0.615557651,
                        },
                        { 
                            slug: "package_in_car",
                            left: 0.615557651,
                            right: 0.68342345,
                        },
                        { 
                            slug: "package_in_hand",
                            left: 0.68342345,
                            right: 0.751289249,
                        },
                        { 
                            slug: "package_not_clearly_visible",
                            left: 0.751289249,
                            right: 0.819155048,
                        },
                        { 
                            slug: "package_too_close",
                            left: 0.819155048,
                            right: 0.887020848,
                        },
                        { 
                            slug: "photo_too_dark",
                            left: 0.887020848,
                            right: 0.954886647,
                        },
                        { 
                            slug: "other",
                            left: 0.954886647,
                            right: 1.0,
                        },
                    ]
                },
                
            ]
        },
        {
            start: 202021,        
            fields: [
                {
                    key: "week",
                    instance: 1,
                    relativePos: 1,
                    minPage: 1,
                    transform: "cleanNumber(value)",
                    pattern: "WORD::Week"            
                }
            ],
            tables: [
                {
                    table_pattern:[
                        "employee_name",
                        "transporter_id"
                    ],
                    table_match_percent: .7,
                    table_data:[],
                    min_data: 8,
                    columns: [
                        { 
                            slug: "employee_name",
                            left: 0,
                            right: 0.106833363
                        },
                        { 
                            slug: "transporter_id",
                            left: 0.106833363,
                            right: 0.189118643
                        },
                        { 
                            slug: "opportunities",
                            left: 0.189118643,
                            right: 0.261558071,
                        },
                        { 
                            slug: "success",
                            left: 0.261558071,
                            right: 0.326912828,
                        },
                        { 
                            slug: "bypass",
                            left: 0.326912828,
                            right: 0.38727193,
                        },
                        { 
                            slug: "rejects",
                            left: 0.38727193,
                            right: 0.450099155,
                        },
                        { 
                            slug: "blurry_photo",
                            left: 0.450099155,
                            right: 0.51292638,
                        },
                        { 
                            slug: "human_in_the_picture",
                            left: 0.51292638,
                            right: 0.575753605,
                        },
                        { 
                            slug: "no_package_detected",
                            left: 0.575753605,
                            right: 0.63858083,
                        },
                        { 
                            slug: "package_in_car",
                            left: 0.63858083,
                            right: 0.701408055,
                        },
                        { 
                            slug: "package_in_hand",
                            left: 0.701408055,
                            right: 0.76423528,
                        },
                        { 
                            slug: "mail_slot",
                            left: 0.76423528,
                            right: 0.827062506,
                        },
                        { 
                            slug: "package_too_close",
                            left: 0.827062506,
                            right: 0.889889731,
                        },
                        { 
                            slug: "photo_too_dark",
                            left: 0.889889731,
                            right: 0.952716956,
                        },
                        { 
                            slug: "other",
                            left: 0.952716956,
                            right: 1.0,
                        },
                    ]
                },
                
            ]
        },
        {
            start: 202020,        
            fields: [
                {
                    key: "week",
                    instance: 1,
                    relativePos: 1,
                    minPage: 1,
                    transform: "cleanNumber(value)",
                    pattern: "WORD::Week"            
                }
            ],
            tables: [
                {
                    table_pattern:[
                        "employee_name",
                        "transporter_id"
                    ],
                    table_match_percent: .7,
                    table_data:[],
                    min_data: 8,
                    columns: [
                        { 
                            slug: "employee_name",
                            left: 0,
                            right: 0.110898936
                        },
                        { 
                            slug: "transporter_id",
                            left: 0.110898936,
                            right: 0.269732296
                        },
                        { 
                            slug: "blurry_photo",
                            left: 0.269732296,
                            right: 0.349378262,
                        },
                        { 
                            slug: "explicit",
                            left: 0.349378262,
                            right: 0.431159767,
                        },
                        { 
                            slug: "mail_slot",
                            left: 0.431159767,
                            right: 0.512941271,
                        },
                        { 
                            slug: "no_package",
                            left: 0.512941271,
                            right: 0.594722776,
                        },
                        { 
                            slug: "package_too_close",
                            left: 0.594722776,
                            right: 0.67650428,
                        },
                        { 
                            slug: "person_in_photo",
                            left: 0.67650428,
                            right: 0.758285784,
                        },
                        { 
                            slug: "taken_from_car",
                            left: 0.758285784,
                            right: 0.838688949,
                        },
                        { 
                            slug: "other",
                            left: 0.838688949,
                            right: 0.922238273,
                        },
                        { 
                            slug: "grand_total",
                            left: 0.922238273,
                            right: 1.0,
                        }
                    ]
                },
                
            ]
        },
        {
            start: 200001,        
            fields: [
                {
                    key: "week",
                    instance: 1,
                    relativePos: 1,
                    minPage: 1,
                    transform: "cleanNumber(value)",
                    pattern: "WORD::Week"            
                }
            ],
            tables: [
                {
                    table_pattern:[
                        "employee_name",
                        "transporter_id"
                    ],
                    table_match_percent: .7,
                    table_data:[],
                    min_data: 8,
                    columns: [
                        { 
                            slug: "employee_name",
                            left: 0,
                            right: 0.110898936
                        },
                        { 
                            slug: "transporter_id",
                            left: 0.110898936,
                            right: 0.269732296
                        },
                        { 
                            slug: "blurry_photo",
                            left: 0.269732296,
                            right: 0.349378262,
                        },
                        { 
                            slug: "explicit",
                            left: 0.349378262,
                            right: 0.431159767,
                        },
                        { 
                            slug: "mail_slot",
                            left: 0.431159767,
                            right: 0.512941271,
                        },
                        { 
                            slug: "no_package",
                            left: 0.512941271,
                            right: 0.594722776,
                        },
                        { 
                            slug: "other",
                            left: 0.594722776,
                            right: 0.67650428,
                        },
                        { 
                            slug: "package_too_close",
                            left: 0.67650428,
                            right: 0.758285784,
                        },
                        { 
                            slug: "person_in_photo",
                            left: 0.758285784,
                            right: 0.838688949,
                        },
                        { 
                            slug: "taken_from_car",
                            left: 0.838688949,
                            right: 0.922238273,
                        },
                        { 
                            slug: "grand_total",
                            left: 0.922238273,
                            right: 1.0,
                        }
                    ]
                },
                
            ]
        },
    ]
}