<template >
  <div data-cy="company-details-card" v-loading="loading">
    <div class="flex justify-between">
      <h1>Company Details</h1>   
     
        <el-dialog
          title="Edit Company Details"
          :visible.sync="modalOpen"
          width="30%"
          :close-on-click-modal="false"
          >
            <CompanyForm :formFields="editTenantData" :rules="rules" ref="companyForm" v-loading="loading"/>
            <span slot="footer" class="dialog-footer">
              <ButtonGroupWrapper>
                <template slot-scope="{disabled, setDisabled}">
                  <!-- span to trigger reactivity inside slot scope -->
                  <span hidden>{{ loading }}</span>
                  <el-button
                    @click="modalOpen = false"
                    :disabled="disabled || loading"
                  >
                    Cancel
                  </el-button>
                  <ButtonWrapper
                    type="primary"
                    :clickHandler="updateTenant"
                    :disabled="disabled || loading"
                    @cb-start="setDisabled(true)"
                    @cb-end="setDisabled(false)"
                  >
                    Update
                  </ButtonWrapper>
                </template>
              </ButtonGroupWrapper>
            </span>
        </el-dialog>
    </div>
    <div class="flex mb-4 justify-between">
      <ul class="flex space-x-4 text-brand underline">
        <li class="pt-2"><router-link :to="{name: 'StaffImport'}">Import {{$t('label.associates')}}</router-link></li>
        <li class="pt-2"><router-link :to="{name: 'VehicleImport'}">Import Vehicles</router-link></li>
        <li class="pt-2"><router-link :to="{name: 'DevicesImport'}">Import Devices</router-link></li>
      </ul> 

      <div class="flex justify-end" v-if="isSystemAdmin">
        <el-dropdown @command="handleCommand">
        <i 
          title="Account Options"
          class="icon-button hover:text-brand uil uil-cog"
        />
        <el-dropdown-menu slot="dropdown" >
          <el-dropdown-item command="DeleteStaff">Delete All {{$t('label.associates')}}</el-dropdown-item>
          <el-dropdown-item command="DeleteVehicle">Delete All Vehicles</el-dropdown-item>
          <el-dropdown-item command="DeleteDevice">Delete All Devices</el-dropdown-item>
        </el-dropdown-menu>
        </el-dropdown>
      </div>
    </div>

    <div class="grid grid-cols-6 grid-flow-row gap-4">
      <div class="col-span-6 md:col-span-3 lg:col-span-2 border p-4 rounded-md shadow">
        <div class="float-right space-x-4 justify-end">
          <i 
            data-cy="cd-edit-btn"
            @click="openEditTenantModal"
            class="icon-button hover:text-brand uil uil-pen"/>
        </div>
        <el-form label-position="top" size="small" >
          <el-form-item label="Company Name" prop="companyName" class="non-edit-field break-word" :class="{'placeholder': !tenantData.companyName}">
            <div class="flex">
              {{ tenantData.companyName | text }}
              <el-tooltip class="item flex" effect="dark" :placement="isDeviceMobileOnly() ? 'bottom' : 'right-start'" popper-class="custom-tooltip-lowest-z-index">
                  <div slot="content" class="custom-tooltip-content-overflow">
                      <p class="font-bold mb-1">Company Name Change Log</p>
                      <p v-html="tenantData.nameLog"></p>
                  </div>
                  <p class="text-gray-600"><i class="uil uil-question-circle text-base"></i></p>
              </el-tooltip>
            </div>
          </el-form-item>
          <el-form-item label="Short Code" prop="shortCode" class="non-edit-field break-word" :class="{'placeholder': !tenantData.shortCode}">
            {{ tenantData.shortCode | text }}
          </el-form-item>
          <el-form-item label="Dispatch Phone Number" prop="dispatchPhoneNumber" class="non-edit-field break-word" :class="{'placeholder': !tenantData.dispatchNumber}">
            {{ tenantData.dispatchNumber | text }}
          </el-form-item>
         
          <el-form-item prop="billingEmail" class="non-edit-field break-word" :class="{'placeholder': !tenantData.stripeBillingEmail}">
                <template slot="label">
                  Billing Email
                  <el-tooltip class="item" effect="dark" content="This email will receive payment notifications and any billing related issues." :placement="isDeviceMobileOnly() ? 'bottom' : 'top-start'" popper-class="custom-tooltip-lowest-z-index">
                      <i class="text-gray-700  uil uil-question-circle text-base "></i>
                  </el-tooltip>
                </template>
                {{ tenantData.stripeBillingEmail | text }}
          </el-form-item>
          
          <el-form-item prop="address" class="non-edit-field break-word" :class="{'placeholder': !tenantData.addressLine1}">
            <template slot="label">
              Station Address
              <el-tooltip v-if="!$store.getters.isSystemAdmin" class="item" effect="dark" content="Please contact Hera Support to change your station address." :placement="isDeviceMobileOnly() ? 'bottom' : 'top-start'" popper-class="custom-tooltip-lowest-z-index">
                <i class="text-gray-700 uil uil-question-circle text-base"></i>
              </el-tooltip>
            </template>
            {{ tenantData.addressLine1 | text }}
          </el-form-item>
          <el-form-item v-if="tenantData.addressLine2" prop="addressLine2" class="non-edit-field -mt-4 break-word">
            {{ tenantData.addressLine2 | text }}
          </el-form-item>
          <el-form-item v-if="addressLine3" prop="addressLine3" class="non-edit-field -mt-4 break-word">
            {{ addressLine3 | text }}
          </el-form-item>
        </el-form>
      </div>

      <div class="col-span-6 md:col-span-3 lg:col-span-2 border p-4 rounded-md shadow">
        <el-form label-position="top" size="small" >
          <el-form-item label="Company Logo" prop="companyLogo" v-loading="imageUrlLoading">
            <div v-if="imageUrl">
              <img :src="imageUrl"  class="mb-4 w-72" >
              <div class="float-left">
                <label @click="removePhoto" class="inline-block el-button--text align-middle text-sm cursor-pointer underline">Delete</label>
                <label class="ml-4 align-middle inline-block el-button--text text-sm cursor-pointer underline">
                  <div>Replace</div>
                  <input type="file"  @change="uploadImage" class="hidden">
                </label> 
              </div> 
            </div>
            <label v-else class="fileContainer h-28 mb-4 rounded-lg bg-opacity-50 text-center">
              <div class="uil-image text-5xl"></div>
              <div class="break-normal">Drop image here or click to upload</div>
              <input data-cy="cd-company-logo-file" type="file" @change="uploadImage" class="align-middle">
            </label>  
          </el-form-item>
          <el-form-item label="Time Zone" prop="timezone">
            <template #label>
                <span>Time Zone</span>
                <el-tooltip v-if="!$store.getters.isSystemAdmin" class="item" effect="dark" placement="top-start" >
                  <div slot="content" class="w-52">
                    To edit this field, please reach out to Hera Support.
                  </div>
                  <i class="uil uil-question-circle text-base"></i>
                </el-tooltip>
            </template>
            <TimeZoneSelect data-cy="cd-time-zone-auto-sw" :disabled="!$store.getters.isSystemAdmin" v-model="tenantData.timeZone" class="mt-2" @update-tenant="updateTenant()"/>
          </el-form-item>
          <el-form-item label="Outbound Message Number" prop="originationNumber" class="non-edit-field" :class="{'placeholder': !tenantData.companyName}">
            {{ tenantData.originationNumber | phone }}
          </el-form-item>
        </el-form>
      </div>

        <div class="col-span-6 md:col-span-3 lg:col-span-2 border p-4 rounded-md shadow">
          <el-form label-position="top" size="small">
            <el-form-item label="Accident Record Form" prop="accidentReportForm">
              <div v-if="tenantData.accidentReport">
                <el-tag 
                  type="info"
                  effect="plain"
                  class="block w-full form-file-uploaded ellipsis">
                  {{accidentReportName}}
                </el-tag>
                <div>
                  <label @click="removeDocument(commitRemoveDocument, tenantData.accidentReport, 'accidentReport')" class="inline-block el-button--text align-middle text-sm cursor-pointer underline">Delete</label>
                  <label @click="downloadByKey(tenantData.accidentReport, 'accidentReport.pdf')" class="ml-4 align-middle inline-block el-button--text text-sm cursor-pointer underline"><div>Downoad</div></label> 
                </div> 
              </div>
              <label v-else class="fileContainer h-28 w-full mb-4 rounded-lg bg-opacity-50 text-center">
                <div class="uil-file text-5xl"></div>
                <div class="break-normal">Drop file here or click to upload</div>
                <input data-cy="cd-accident-report-file" type="file" @change="uploadDocument($event, '/forms/accidentReport.', 'accidentReport')" class="align-middle">
              </label>  
            </el-form-item>
          </el-form>

          <el-form label-position="top" size="small">
            <el-form-item label="Workmans Comp Form" prop="workmansCompForm">
              <div v-if="tenantData.workmansComp">
                <el-tag 
                  type="info"
                  effect="plain"
                  class="block w-full form-file-uploaded ellipsis">
                  {{workmansCompName}}
                </el-tag>
                <div>
                  <label @click="removeDocument(commitRemoveDocument, tenantData.workmansComp, 'workmansComp')" class="inline-block el-button--text align-middle text-sm cursor-pointer underline">Delete</label>
                  <label @click="downloadByKey(tenantData.workmansComp, 'workmansComp.pdf')" class="ml-4 align-middle inline-block el-button--text text-sm cursor-pointer underline"><div>Downoad</div></label> 
                </div> 
              </div>
              <label v-else class="fileContainer h-28 mb-4 w-full rounded-lg bg-opacity-50 text-center">
                <div class="uil-file text-5xl"></div>
                <div class="break-normal">Drop file here or click to upload</div>
                <input data-cy="cd-workmans-comp-file" type="file" @change="uploadDocument($event, '/forms/workmansComp.', 'workmansComp')" class="align-middle">
              </label>  
            </el-form-item>
          </el-form>
        </div>

      
    </div>

    <!-- DELETION DIALOG -->
    <el-dialog
      title="Are you sure?"
      :visible.sync="deleteAccountModalOpen"
      width="30%"
      :close-on-click-modal="false"
    >
    <DeleteDialog 
      :deletionType="deletionType" 
      :deletionMessage="deletionMessage"
      :group="$store.state.userInfo.tenant.group"
      :tenantID="$store.state.userInfo.tenant.id"
      @close-deletion-modal="closeModal"
      @show-loading-screen="startLoading"
      @stop-loading="stopLoading"/>
    </el-dialog>


  </div>
</template>

<script>
import { Auth, API } from 'aws-amplify';
import CompanyForm from './components/CompanyForm'
import TimeZoneSelect from './components/TimeZoneSelect'
import DeleteDialog from '@/components/DeleteDialog'
import {Storage} from 'aws-amplify'
import {mapState, mapMutations, mapGetters} from 'vuex'
import { getTenant } from './query.js'
import { getTenantUpdatedTimestamp } from '@/utilities/timestampQueries'
import { updateTenant } from '@/graphql/mutations'
import moment from 'moment-timezone'
import Validator from '@/utilities/FormValidator'
import EventBus from '@/eventBus'
import ButtonWrapper from '@/components/shared/ButtonWrapper/ButtonWrapper';
import ButtonGroupWrapper from '@/components/shared/ButtonGroupWrapper/ButtonGroupWrapper';
import { previewDownloadedHeicImage } from '@/utilities/heicUtility.js';

export default {
  components:{
    CompanyForm,
    TimeZoneSelect,
    DeleteDialog,
    ButtonWrapper,
    ButtonGroupWrapper,
  },

  data(){
    return{
      imageUrl: null,
      imageUrlLoading: false,
      objectURLs: [],
      accidentReportUrl: null,
      loading: false,
      disabled: true,
      modalOpen: false,
      tenantData:{},
      editTenantData: {},
      prevTimeZone: '',
      deleteAccountModalOpen: false,
      deleteConfirmation:'',
      deletionType: '',
      deletionMessage: '',
      rules: {
        stripeBillingEmail: [
          {message: 'Please enter a valid email', trigger: 'blur', validator: Validator.email },
        ],
        companyName: [
          {required: true, message: "Please introduce a company name", trigger: 'blur'}
        ],
      }
        
    }
  },
  mounted(){
    this.loadData()
  },

  watch: {
    timeZone(newVal, oldVal){
      this.prevTimeZone = oldVal
    }
  },

  computed:{
    ...mapState(['userInfo']),   
    
    ...mapGetters(['isSystemAdmin']),

    addressLine3(){
      var city = this.tenantData.addressCity
      var state = this.tenantData.addressState
      var zip = this.tenantData.addressZip
      if(!city && !state && !zip) return ''
      return `${city}, ${state} ${zip}`
    },

    accidentReportName(){
      var split =  this.tenantData.accidentReport.split('/')
      return split[split.length - 1]
    },

    timeZone(){
      return this.getTenantTimeZone()
    },

    workmansCompName(){
      var split =  this.tenantData.workmansComp.split('/')
      return split[split.length - 1]
    }
  },
  methods:{
    ...mapMutations(['setCompanyLogo', 'setUserInfo']),

    handleCommand(command){
      if(command == "DeleteStaff"){
        this.openDeletionModal('Staff', `Are you sure you want to delete all ${this.$t('label.associate')} data?`);
        return
      }
      else if (command == "DeleteVehicle"){
        this.openDeletionModal('Vehicle', 'Are you sure you want to delete all vehicle data?');
        return
      }
      else if (command == "DeleteDevice"){
        this.openDeletionModal('Device', 'Are you sure you want to delete all device data?');
        return
      }
    },
    /**
     * Load data for the current tenant
     */
    async loadData(){
      this.loading = true;
      try{
        const tenant = await this.api(getTenant, { id: this.userInfo.tenant.id })
        this.tenantData = tenant.data.getTenant
        let tData = {...this.tenantData}
        delete tData.users
        this.editTenantData = tData  
        if( this.tenantData.logo ){
          if(/\.heic$/i.test(this.tenantData.logo)){
            this.imageUrlLoading = true
            this.clearObjectURLs(this.objectURLs) //clean old ObjectURLs
            const file = await Storage.get(this.tenantData.logo, {download: true})
            const blob = await previewDownloadedHeicImage(file)
            const url = URL.createObjectURL(blob)
            this.objectURLs.push(url)
            this.imageUrl = url
            this.imageUrlLoading = false
          }else{
            this.imageUrl = await Storage.get(this.tenantData.logo)
          }
          //Update ImageURL
          this.setCompanyLogo(this.imageUrl)
        }
        
      }catch(e){
        console.error(e)
        this.displayUserError(e)
      }
      finally{
        this.loading = false;
      }
    },

    /** Copy tenant data to editTenantData and open modal */
    openEditTenantModal(){
      this.modalOpen = true        
      let tenant = {...this.tenantData}
      delete tenant.users
      this.editTenantData = tenant        
    },

    async updateStripeCustomer(){
      try{
          let apiName = 'stripeSetup'
          let path = '/customer/' + this.tenantData.stripeCustomerId
          let post = {
            body: {
              name: this.editTenantData.companyName,
              email: this.editTenantData.stripeBillingEmail,
              address:{
                city: this.editTenantData.adddressCity,
                line1: this.editTenantData.addressLine1,
                line2: this.editTenantData.addressLine2,
                postal_code: this.editTenantData.addressZip,
                state: this.editTenantData.addressState
              }
            }
          }

          if(this.editTenantData.stripeBillingEmail) {
            var result = await API.put(apiName, path, post)
            return result.customer
          }
      }catch(e){
          console.error(e)
          this.displayUserError(e)
      }finally{
          this.isLoading = false
      }
    },

    async updateTenantNameInCognitoUserAttributes(group, tenantName){
      let apiName = 'AdminQueries';
      let path = '/updateTenantName';
      let post = {
        body: {
          "group": group,
          "tenantName": tenantName 
        }, 
        headers: {
          'Content-Type' : 'application/json',
          Authorization: `${(await Auth.currentSession()).getAccessToken().getJwtToken()}`
        } 
      }
      safeFunction(API.post)(apiName, path, post);
    },

    /** Update the tenant in the DB */
    async updateTenant(){
      try {
        this.loading = true
        // validate form
        if(this.$refs.companyForm){
          this.$refs.companyForm.$refs.form.validate((valid) => {
            if (!valid) {
              throw{
                errors: [
                  { message: "Please fill all required form fields."}
                ]
              }
            }
          })
        }

        //validate station address
        if(!this.$store.getters.isSystemAdmin && (
          this.tenantData.addressLine1 != this.editTenantData.addressLine1 ||
          this.tenantData.addressLine2 != this.editTenantData.addressLine2 ||
          this.tenantData.addressCity != this.editTenantData.addressCity || 
          this.tenantData.addressState != this.editTenantData.addressState || 
          this.tenantData.addressZip != this.editTenantData.addressZip
        )){
          this.displayUserError('Please contact Hera Support to change your station address.')
          return
        }

        // update Dynamo
        let newnameLog = this.tenantData.nameLog;
        if (this.tenantData.companyName != this.editTenantData.companyName){
          //name changed
          if(this.$store.getters.isSystemAdmin){
            var timestamp = this.getTenantTimeCustomFormat();
            var oldLog = this.tenantData.nameLog ? this.tenantData.nameLog : ''
            newnameLog = oldLog + 'Name changed from "' + this.tenantData.companyName + '" to "' + this.editTenantData.companyName + '" at ' + timestamp + '<br/>'
          }else {
            this.editTenantData.companyName = this.tenantData.companyName;
          }
          //Update Tenant Name in Cognito user attributes
          await this.updateTenantNameInCognitoUserAttributes(this.userInfo.tenant.group, this.editTenantData.companyName)
        }
        
        const input = {
          ...this.editTenantData,
          id: this.tenantData.id,
          nameLog: newnameLog,
          timeZone: this.tenantData.timeZone,
          updatedAt: this.tenantData.updatedAt
        }
        const validateCompanyForm =  this.validateForm()
        if(validateCompanyForm){
          const result = await this.api(updateTenant, {input: input}, getTenantUpdatedTimestamp, 'getTenant')

          // update Stripe
          if(this.tenantData.stripeCustomerId) this.updateStripeCustomer()

          if(this.tenantData.timeZone) {
            EventBus.$emit('tenant-has-timezone', {})
            this.$store.commit('setTimeZone', this.tenantData.timeZone)
          }

          this.modalOpen = false
          this.loadData()
          this.$store.commit('setTenantName', input.companyName)
          this.$message({
            type: 'success',
            message: 'Company Updated'
          });
        }
        
      } catch(e) {
        console.error(e)
        this.displayUserError(e)   
      }
      finally{
        this.loading = false
      }
    }, 

    validateForm(){
      if (!this.editTenantData.companyName) throw 'Please fill company name'
      return true
    },
    /**
     * Uploads image to S3 and updates DB
     * @param {object} e - native js event object
     */
    async uploadImage(e){
      try{
        this.imageUrlLoading = true
        //Check timestamp of tenant
        var timestampResult = await this.api(getTenantUpdatedTimestamp, {id: this.userInfo.tenant.id})
        var timestamp = timestampResult.data.getTenant.updatedAt

        if(timestamp != this.tenantData.updatedAt){
          throw "Record was updated by another user. Please copy any changes and refresh the page."
        }

        //Upload Image to S3
        var files = e.target.files || e.dataTransfer.files;
        var file = files[0]
        var fileType = file.type

        if (!fileType && /\.heic$/i.test(file.name)) {
          fileType = 'image/heic'
        }

        // Only accept images
        if(!fileType.includes("image")) {
          throw {
            errors: [
              { message: "Selected file is not an image" }
            ]
          }
        }

        fileType = file.name.split(".").slice(-1)[0]
        var object = await Storage.put(this.userInfo.tenant.group + '/company-logo/logo.'+ fileType, file)

        //Update user info
        var userInfo = {...this.userInfo}
        userInfo.tenant.logo = object.key
        this.setUserInfo(userInfo)

        //Update tenant with logo url
        const input = {
          id: this.userInfo.tenant.id,
          logo: object.key,
          updatedAt: this.tenantData.updatedAt
        }        
        const result = await this.api(updateTenant, {input: input}, getTenantUpdatedTimestamp, 'getTenant')
        //important
        this.loadData()
      }catch(e){
        console.error(e)
        this.displayUserError(e)
      }finally{
        this.imageUrlLoading = false
      }
    },

    /** Confirm user really wants to remove logo */
    removePhoto(){
      this.$confirm('This will permanently delete the file. Continue?', 'Warning', {
          confirmButtonText: 'OK',
          cancelButtonText: 'Cancel',
          type: 'warning'
        }).then(()=>{
          this.commitRemovePhoto()
        }).catch(()=>{
          console.error("user cancelled remove")
        })
    },

    /** Remove photo from storage, db, and store */
    async commitRemovePhoto(){
      try{
        //Check timestamp of tenant
        var timestampResult = await this.api(getTenantUpdatedTimestamp, {id: this.userInfo.tenant.id})
        var timestamp = timestampResult.data.getTenant.updatedAt
        if(timestamp != this.tenantData.updatedAt){
          throw "Record was updated by another user. Please copy any changes and refresh the page."
        }
        //Remove From Storage
        var key = this.userInfo.tenant.logo
        await Storage.remove(key)

        //Remove from db
        const input = {
          id: this.userInfo.tenant.id,
          logo: null,
          updatedAt: this.tenantData.updatedAt
        }  
        const result = await this.api(updateTenant, {input: input}, getTenantUpdatedTimestamp, 'getTenant')
        //Update store
        this.tenantData.logo = null
        this.imageUrl = null
        var userInfo = {...this.userInfo}
        userInfo.tenant.logo = key
        this.setCompanyLogo(null)
        this.setUserInfo(userInfo)
        this.tenantData.updatedAt = result.data.updateTenant.updatedAt
        this.clearObjectURLs(this.objectURLs)
      }catch(e){
        console.error(e)
        this.displayUserError(e)
      }
    },
        /**
     * Uploads document to S3 and updates DB
     * @param {object} e - native js event object
     */
    async uploadDocument(e, filePath, docKey){
      try{

        //Check timestamp of tenant
        var timestampResult = await this.api(getTenantUpdatedTimestamp, {id: this.userInfo.tenant.id})
        var timestamp = timestampResult.data.getTenant.updatedAt

        if(timestamp != this.tenantData.updatedAt){
          throw "Record was updated by another user. Please copy any changes and refresh the page."
        }

        //Upload document to S3
        var files = e.target.files || e.dataTransfer.files;
        var file = files[0]
        var fileType = file.name.split(".").slice(-1)[0]
        var object = await Storage.put(this.userInfo.tenant.group + filePath + fileType, file)

        //Update tenant with document url
        const input = {
          id: this.userInfo.tenant.id,
          updatedAt: this.tenantData.updatedAt
        }
        input[docKey] = object.key
        const result = await this.api(updateTenant, {input: input}, getTenantUpdatedTimestamp, 'getTenant')
        this.loadData()

      }catch(e){
        console.error(e)
        this.displayUserError(e) 
      }
    },

    /** Confirm user really wants to remove document */
    async removeDocument(callback, document, docKey){
      this.$confirm('This will permanently delete the file. Continue?', 'Warning', {
          confirmButtonText: 'OK',
          cancelButtonText: 'Cancel',
          type: 'warning'
        }).then(()=>{
          callback(document, docKey)
        }).catch(()=>{
          console.error("user cancelled remove")
        })
    },

    /** Remove document from storage and database */
    async commitRemoveDocument(document, docKey){
      try{

        //Check timestamp of tenant
        var timestampResult = await this.api(getTenantUpdatedTimestamp, {id: this.userInfo.tenant.id})
        var timestamp = timestampResult.data.getTenant.updatedAt

        if(timestamp != this.tenantData.updatedAt){
          throw "Record was updated by another user. Please copy any changes and refresh the page."
        }

        //Remove From Storage
        var key = document
        await Storage.remove(key)

        //Remove from db
        const input = {
          id: this.tenantData.id,
          group: this.userInfo.tenant.group,
          updatedAt: this.tenantData.updatedAt
        }        
        input[docKey] = null
        const result = await this.api(updateTenant, {input: input}, getTenantUpdatedTimestamp, 'getTenant')
        this.loadData()

      }catch(e){
        console.error(e)
        this.displayUserError(e)
      }
    },
    /** download document from storage to local client */
    async downloadByKey(key, name){
      var documentUrl = await Storage.get(key)
      var element = document.createElement('a');
      element.setAttribute('href', documentUrl );
      element.setAttribute('download', name );
      element.style.display = 'none';
      document.body.appendChild(element);
      element.click();
      document.body.removeChild(element);
    },

      /**
   * Close modal and clear out deletionConfirmation
   */
  closeModal(){
    this.deleteAccountModalOpen = false;
  },


  /**
   * Set loading to true
   */
  startLoading(){
    this.loading = true;
  },


  /**
   * Set loading to false
   */
  stopLoading(){
    this.loading = false;
  },

  /**
   * Opens deletion dialog and sets parameters up for deletion functions
   */
  openDeletionModal: function(type, message){
    this.deletionType = type;
    this.deletionMessage = message;
    this.deleteAccountModalOpen = true;
  },

  },

}
</script>

<style scoped>
  .fileContainer{
    @apply  text-gray-600 bg-gray-100 pt-4 border-gray-600 border-dashed border-2 text-base !important;
  }
  .el-select{
        width: 100%;
    }
</style>
