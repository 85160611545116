

<template>

    <div>
        <div class="el-input el-input-group el-input-group--prepend">
            <div class="el-input-group__prepend"><img class="flag" src="@/assets/images/united-states.png" alt="flag"/> <div class="text-flag">+1</div> </div>
            <input v-mask="'(###) ###-####'"  type="text"  :value="value" @input="handleInput($event.target.value)" class="el-input__inner" @blur="onBlur"/>
        </div>
    </div>
</template>

<script>
export default {
    props:['value'],
  data() {
    return {
      select: ''
    }
  },
  methods:{
    handleInput (e) {
      this.$emit('input', e)
    },
    onBlur() {
      this.$emit('blur');
    },
  },

}
</script>

<style>

 .el-input-group__append, .el-input-group__prepend {
    background-color: #fff !important;
}

  .flag {
    width: 20px;
    height: 20px;
    float: left;
    margin-right: 5px;
    margin-left: 5px;
  }

  .text-flag{
    padding-right:37px; 
  }
</style>