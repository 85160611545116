<template>
    <div class="custom-drawer__footer p-4 flex justify-end">
        <el-button  @click="cancel">Cancel</el-button>
        <el-button type="primary" @click="handleButton" :loading="this.buttonLoading">{{labelButton}}</el-button>
    </div>
</template>
<script>
export default {
    name: "Drawer",
    props: ['open', 'labelButton', 'buttonLoading'],
    data(){
        return {
            visible: false,
        }
    },
    methods:{
        cancel(){
            this.$emit('cancel')
        },
        handleButton(){
            this.$emit('cbk-button')
        }
    }
}
</script>
<style>
</style>