<template v-loading="loading">
    <div>
        <p class="padding-bottom-1 warning-message">{{ deletionMessage }}</p>
        <p class="text-red-500 font-bold">There is no undo for this action.</p>
        <el-form label-position="top" ref="delete" :rules="rules" :model="deleteConfirmation" :hide-required-asterisk="true"> 
            <el-form-item prop="delete" label="Type the word delete to confirm">
                <el-input v-model="deleteConfirmation.delete"></el-input>
            </el-form-item>
        </el-form>
        <div class="flex justify-end">
            <el-button type="default" class="cancel-button" @click="closeModal">Cancel</el-button>
            <el-button type="primary" @click="deleteAccount">Confirm</el-button>
        </div>
    </div>
</template>

<script>
import {Auth, API, Storage} from 'aws-amplify'
import {mapState, mapMutations} from 'vuex'
import { listTelnyxs } from '@/graphql/queries'
import { cardsByGroup } from '@/views/Settings/Accounts/queries'
import { updateZohoAccountTenant } from '../components/Zoho/zoho'
import { deleteCard, updateTelnyx } from '@/graphql/mutations'
import Validator from '@/utilities/FormValidator'

export default {
    props: ['deletionType', 'deletionMessage', 'group', 'tenantID', 'stripeCustomerId', 'zohoAccountID'],
    data(){
        return{
            loading: false,
            deleteConfirmation: {
                delete: ''
            },
            tableArray: [
                'Accident',
                'Card',
                'CompanyScoreCard',
                'Counseling',
                'DailyRoster',
                'Device',
                'Document',
                'DrugTest',
                'Infraction',
                'Injury',
                'Message',
                'Notification',
                'OnBoard',
                'PendingMessage',
                'Physical',
                'PodQuality',
                'Route',
                'Staff',
                'StaffCxFeedback',
                'StaffMentor',
                'StaffScoreCard',
                'StaffStatus',
                'Task',
                'TextractJob',
                'Uniform',
                'ValueList',
                'ValueListItem',
                'Vehicle',
                'Tenant',
            ],
            rules: {
                delete: [
                    { required: true, message: 'Please enter the correct phrase to confirm', validator: Validator.delete },
                ]
            }
        }
    },

    computed:{
        ...mapState(['userInfo']),    
    },

    mounted(){
        this.$refs.delete.clearValidate()
    },

    methods:{
        ...mapMutations([ 'setSessionInfo', 'setUserInfo']),


        /**
         * Close modal and clear out deletionConfirmation
         */
        closeModal(){
            this.deleteConfirmation.delete = '';
            this.$refs.delete.clearValidate()
            this.$emit("close-deletion-modal")
        },

        /**
         * Emit closing the modal and showing loading screen
         */
        showLoad(){
            this.$emit("show-loading-screen");
        },

        /**
         * Emit stopping the loading screen
         */
        stopLoading(){
            this.$emit("stop-loading");
        },


        /**
         * To Delete the account and delete all records associated with it
         */
        async deleteAccount(){
            // validate form
            try{
                this.$refs.delete.validate((valid) => {
                    if (!valid) {
                    throw{
                        errors: [
                            { message: "Error: Incorrect phrase"}
                        ]
                    }
                    }
                })
                
                this.showLoad();
                if(this.deletionType == 'Account'){
                    await this.deleteAccountConfirmation();
                } else if (this.deletionType == 'Staff'){
                    try{
                        let apiName = 'heraPublicApi';
                        let path = '/executeMutation';
                        let post = {
                            body: {
                                "group": this.group,
                                "type": 'associate',
                                "mutation": 'delete',
                                "cascadeMutation": true,
                                "owner": this.userInfo.cognitoSub,
                            }
                        }

                        this.displayUserNotification({
                            title: 'Processing...',
                            type: "info",
                            duration: 5000,
                            message: 'Hera is currently deleting all Associates.'
                        })

                        safeFunction(API.post)(apiName, path, post);
                    }catch(e){
                        this.displayUserError(e, 'Cannot delete all associates. ')
                    }finally{
                        this.stopLoading();
                        this.closeModal();
                    }
                } else {
                    try{
                        await this.findDataByGroup(this.deletionType);
                        // this.$message.success(this.deletionType + " successfully deleted.");
                        this.displayUserNotification({
                            title: "Success",
                            message: this.successMessage,
                            type: 'success'
                        })

                    }catch(e){
                        this.displayUserError(e, 'Cannot delete account. ')
                    }finally{
                        this.stopLoading();
                        this.closeModal();
                    }
                }
            }
            catch(e){
                this.displayUserError(e)          
                return
            }
            // finally{
            //     this.stopLoading();
            //     this.closeModal();               
            // }
        },


        /**
         * Loops through all tables and run the deleteData script
         */
        deleteAccountConfirmation: async function(){

            try{
                for (const table of this.tableArray){
                    if(table == 'Card'){
                        // delete card from Stripe
                        await this.deleteCardsFromStripe()
                    }
                    else if(table != 'Tenant'){
                        //DELETE TABLE DATA
                        await this.findDataByGroup(table);
                    }
                    else{
                        //DELETE USERS THROUGH TENANT TABLE
                        await this.findTenantByID();
                    }
                }

                //Free telnyx number
                const telnyxNumbers = await this.gLoadListAll(listTelnyxs, {}, 'listTelnyxs')
                console.log('telnyxNumbers', JSON.stringify(telnyxNumbers))
                const telnyxRow = telnyxNumbers.find(item => item.group == this.group)
                
                if(telnyxRow) {
                    let input = {
                        id: telnyxRow.id,
                        group: 'null'
                    }

                    await this.api(updateTelnyx, { input })
                }
                
                
                //DELETE S3 DOCUMENT
                await this.lists3documents(this.group);

                //DELETE TENANT
                await this.runMutation('Tenant', this.tenantID);

                // DISABLE ZOHO ACCOUNT
                await this.updateZohoAccount()
                
                this.loading = false;

                //EMIT DELETION COMPLETED
                this.$emit("finish-deletion")

            }catch(e){
                this.displayUserError(e, 'Cannot delete account. ')
            }
        },


        /**
         * To delete a given S3 document.
         * @param {string} docKey s3 key of document
         */
        deletes3document: async function(docKey){
            try{
                var result = await Storage.remove(docKey)
            }catch(e){
                this.displayUserError(e, 'Cannot delete account. ')
            }
        },

        async deleteCardsFromStripe(){
            try{
                // load details from Stripe
                if(this.stripeCustomerId){
                    var paymentMethods = await this.getStripeCustomerPaymentMethods(this.stripeCustomerId)
                }

                if(paymentMethods) {
                    // load card from Dynamo
                    await Promise.all(paymentMethods.data.map(async(card)=>{
                        let input = {
                        group: this.group,
                        filter: {
                            stripePaymentMethodId: {
                            eq: card.id
                            }
                        }
                        }
                        let dbCard = await this.gLoadListAll(cardsByGroup, input, 'cardsByGroup')
                        card.dbId = dbCard[0].id
                    }))

                    // delete cards from Stripe and Dynamo
                    paymentMethods.data.forEach( card => {
                         this.deleteStripeCustomerPaymentMethod(card)

                        let input = {
                            id: card.dbId,
                        }
                         this.api(deleteCard, {input})
                    })
                }
            }catch(e){
                this.displayUserError(e)
            }
        },

        async getStripeCustomerPaymentMethods(customerId){
            try{
                let apiName = 'stripeSetup'
                let path = '/customer/' + customerId
                var result = await safeFunction(API.get)(apiName, path)
                return result.paymentMethods
            }catch(e){
                this.printUserError(e)
            }
        },

        async deleteStripeCustomerPaymentMethod(card){
            try{
                let apiName = 'stripeSetup'
                let path = '/customer/' + card.customer + '/' + card.id
                var result = await safeFunction(API.del)(apiName, path)
                return result.paymentMethods
            }catch(e){
                this.printUserError(e)
            }
        },


        /**
         * Delete User from DynamoDB and Cognito
         * @param {object} user object containing the user's username and ID
         */
        deleteUser: async function(user){
            try{
                this.runMutation('User', user.id);
                let apiName = 'AdminQueries';
                let path = '/DeleteUser';
                let myInit = {
                    body: {
                    "username" : user.email,
                    }, 
                    headers: {
                    'Content-Type' : 'application/json',
                    Authorization: `${(await Auth.currentSession()).getAccessToken().getJwtToken()}`
                    } 
                }
                var result = await safeFunction(API.post)(apiName, path, myInit);
            }catch(e){
                this.displayUserError(e, 'Cannot delete account. ')
            }
        },


        /**
         * Retrieves all data from a table and proceeds to delete it
         * @param {string} tableName Name of the table to delete data from
         */
        findDataByGroup: async function(tableName){
            const tableNameSingular = tableName === 'Injury' ? 'Injurie' : tableName;
            const tableNamePlural = tableNameSingular + "s";
            const tableNamePluralByGroup = tableNamePlural + "ByGroup";
            const queryNameByGroup = tableNamePluralByGroup.charAt(0).toLowerCase() + tableNamePluralByGroup.slice(1);

            //Changes query based on if table is User or not
            const groupQuery = /* GraphQL */`
                query ${tableNamePluralByGroup}(
                    $group: String
                    $limit: Int
                    $nextToken: String
                ){
                    ${queryNameByGroup}(
                        group: $group
                        limit: $limit
                        nextToken: $nextToken
                    ){
                        items{
                        id
                        }
                        nextToken
                    }
                }
            `;

            var input = {
                group: this.group
            }
            try{
                const items = await this.gLoadListAll(groupQuery, input, queryNameByGroup);
                items.forEach(async item =>{
                    await this.runMutation(tableName, item.id);
                })
            }catch(e){
                this.displayUserError(e, 'Cannot delete account. ')
            }
        },

        /**
         * Retrieves all data from a table and proceeds to delete it
         * @param {string} tableName Name of the table to delete data from
         */
        findTenantByID: async function(tableName){
            const getTableName = "Get" + tableName;
            const getQueryName = "get" + tableName;
            const input = {
                id: this.tenantID
            }
            const getQuery = /* GraphQL */`
            query GetTenant($id: ID!){
                getTenant(id: $id){
                id
                users{
                    items{
                    id
                    email
                    }
                }
                }
            }`;

            try{
                const results = await this.api(getQuery, input, 'getTenant');
                if(!!results.data.getTenant && !!results.data.getTenant.users && !!results.data.getTenant.users.items.length){
                    const items = results.data.getTenant.users.items;
                    items.forEach(async item =>{
                        await this.deleteUser(item);
                    })
                }
            }catch(e){
                this.displayUserError(e, 'Cannot delete account. ')
            }

        },

        /**
         * Logs out of the app and takes you back to the login screen
         */
        async forceLogout(){
            try{
                var logout = await Auth.signOut()
                this.setSessionInfo(null)
                this.setUserInfo(null)
                this.$router.replace({name: 'Login'})
            }catch(e){
                this.printUserError(e)
                this.setSessionInfo(null)
                this.setUserInfo(null)
            }
            this.displayUserNotification({
                title: "Success",
                message: 'Deleted Account successfully',
                type: 'success'
            })
        },

        /**
         * Handles commands passed from the dropdown
         * @param {string} command - The name of the route you want to go to
         */
        handleCommand(command){
            if(command == "DeleteAccount"){
            this.deleteAccountModalOpen = true
            return
            }
        },



        /**
         * To gather the documents for a tenant.
         * @param {string} groupName name of the tenant's group which is also their directory
         */
        lists3documents: async function(groupName){
            try{
                var listResult = await Storage.list(groupName)
            }catch(e){
                this.displayUserError(e, 'Cannot delete account. ')
            }
            
            listResult.forEach(async doc =>{
                await this.deletes3document(doc.key); 
            })
        },

        /**
         * Takes the muation data run deletion 
         * @param {string} mutation mutation command
         * @param {object} input input to find specific data
         */
        runMutation: async function(tableName, itemID){
            const deleteTableName = "delete" + tableName;
            const deleteName = "Delete" + tableName;
            const input = {
                input: {
                id: itemID
                }
            };
            const mutation = /* GraphQL */ `
            mutation ${deleteName}(
                $input: ${deleteName}Input!
                $condition: Model${tableName}ConditionInput
                ){
                ${deleteTableName}(
                input: $input, 
                condition: $condition
                ){
                id
                }
            }`;
            try{
                var response = await this.api(mutation, input);
            }catch(e){
                this.displayUserError(e, 'Cannot delete account. ')
            }
        },
        async updateZohoAccount(){
            let _payload = {
                id: this.zohoAccountID,
                Customer_Status:"Deleted From Hera"               
            }
            if(!this.$store.getters.isZohoCrmDisabled && this.zohoAccountID)
            {
            try {
             await updateZohoAccountTenant('delete', _payload, this.displayUserError)
             } catch (error) {
             console.error('error updating zoho account', error)
            }
            }
        },

        async disableZohoAccountTenant(){
            try{
                let apiName = 'zoho'
                let path = '/zoho/tenant'
                let post = {
                    body: {
                    data:[{
                        id: this.zohoAccountID,
                        Customer_Status:"Deleted From Hera",
                    }]
                    },
                    headers: {
                        'Content-Type' : 'application/json',
                        Authorization: `${(await Auth.currentSession()).getAccessToken().getJwtToken()}`
                    }
                }
                const result = await safeFunction(API.put)(apiName, path, post)
                return result
            } catch(e){
                console.error('error updating tenant', e)
                this.displayUserError(e)
            }
        },
    }

}
</script>
<style scoped>

</style>