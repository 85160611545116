export const ROSTER_STATUS_OPTION_CALLED_OUT_EXCUSED = "Called Out, Excused"
export const ROSTER_STATUS_OPTION_CALLED_OUT_NOT_EXCUSED = "Called Out, Not Excused"
export const ROSTER_STATUS_OPTION_NO_SHOW_NO_CALL = "No Show, No Call"
export const ROSTER_STATUS_OPTION_VTO = "VTO"

export const statusTypes = [
    'Active (Positive)',
    'Active (Neutral)',
    'Active (Negative)',
    'Inactive (Positive)',
    'Inactive (Neutral)',
    'Inactive (Negative)'
]

export const pillColors = [
    'blue',
    'green',
    'yellow',
    'orange',
    'red',
    'purple',
    'gray',
]

export const recordTemplates = {
    'counseling-type' : [
        {
            label: 'Statement of The Problem',
            prop: 'counselingNotes',
            type: 'textarea'
        },
        {
            label: 'Prior Discussion Summary',
            prop: 'priorDiscussionSummary',
            type: 'textarea'
        },
        {
            label: 'Summary of Corrective Action Discussed',
            prop: 'correctiveActionSummary',
            type: 'textarea'
        },
        {
            label: 'Consequences of Failure to Improve Performance Or Corrective Behavior',
            prop: 'consequencesOfFailure',
            type: 'textarea'
        },
    ]
}