<template>
  <div class="border p-4 mb-8 rounded-md shadow">
    <div class="col-span-12 mb-4" v-if="title || description">
      <p class="text-gray-600 text-base font-medium mr-2">{{ title }}</p>
      <div>
        <small class="text-gray-600">
          {{ description }}
        </small>
      </div>
    </div>
    <div class="grid grid-cols-2 xl:grid-cols-3 gap-4">
      <div class="col-span-1 xl:col-span-1">
        <p class="inline-block text-gray-600 text-base font-medium mr-2">
          Columns being display
        </p>
        <Draggabable
            class="mt-4"
            v-model="draggableList"
            v-bind="dragOptions"
            :move="onMove"
            @change="changeIndex">
          <transition-group type="transition" :name="'flip-list'">
            <div class="col-span-12" v-for="item in draggableList" :key="item.id">
              <CheckboxPill :model="item.isDisplayed"
                            :item="itemData(item, 'displayed', draggableList.findIndex(obj => obj.id === item.id))"
                            :disabled="item.isFixed"
                            class="m-2"
                            @update-checkbox="updateColumn">
                <template v-slot:icons>
                  <template v-if="item.btnEditCustomList">
                    <i v-if="item.isCustomizable" class="option-icon uil uil-pen" :data-cy="item.customListType" @click="openDrawerCustomList(item)"></i>  
                    <i v-else class="option-icon uil uil-info-circle" :data-cy="item.customListType" @click="openDrawerCustomList(item)"></i>  
                  </template>
                  <i class="uil uil-bars" :class="!item.isFixed ? 'handle option-grab' : 'option-not-allowed'"></i>
                </template>
              </CheckboxPill>
            </div>
          </transition-group>
        </Draggabable>
      </div>
      <div class="col-span-1 xl:col-span-2">
        <p class="inline-block text-gray-600 text-base font-medium mr-2">
          Columns not being displayed
        </p>
        <div class="grid grid-cols-1 xl:grid-cols-2 gap-4 mt-4 flex">
          <div v-for="item in columnsNotBeingDisplayed" :key="item.id" class="col-span-1 flex-auto flex">
            <CheckboxPill :model="item.isDisplayed"
                          :item="itemData(item, 'not-displayed')"
                          @update-checkbox="updateColumn">
                <template v-slot:icons>
                  <template v-if="item.btnEditCustomList">
                    <i v-if="item.isCustomizable" class="option-icon uil uil-pen" :data-cy="item.customListType" @click="openDrawerCustomList(item)"></i>  
                    <i v-else class="option-icon uil uil-info-circle" :data-cy="item.customListType" @click="openDrawerCustomList(item)"></i>  
                  </template>
                </template>
            </CheckboxPill>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Draggabable from 'vuedraggable'
import CheckboxPill from '@/components/shared/CheckboxPill'

export default {
  name: 'OptionsDisplayedCard',
  components: {Draggabable, CheckboxPill},
  props: {
    title: {
      type: String,
      required: false,
      default: null
    },
    description: {
      type: String,
      required: false,
      default: null
    },
    type: {
      type: String,
      required: true,
      default: null
    },
    columnsBeingDisplayed: {
      type: Array,
      required: true,
      default: []
    },
    columnsNotBeingDisplayed: {
      type: Array,
      required: true,
      default: []
    }
  },
  data() {
    return {
      newOrderColumnsBeingDisplayed: []
    }
  },
  computed: {
    dragOptions() {
      return {
        animation: 0,
        group: {name: 'label', pull: 'clone', put: false},
        ghostClass: "ghost",
        handle: '.handle'
      };
    },
    draggableList: {
      get() {
        return this.columnsBeingDisplayed
      },
      set(data) {
        this.newOrderColumnsBeingDisplayed = data
      }
    }
  },
  methods: {
    onMove({relatedContext, draggedContext}) {
      const relatedElement = relatedContext.element;
      const draggedElement = draggedContext.element;
      return (
          (!relatedElement || !relatedElement.isFixed) && !draggedElement.isFixed
      );
    },
    itemData(item, from, itemIndex = null) {
      return {...item, from, itemIndex};
    },
    updateColumn(item) {
      this.$emit('update-column', item)
    },
    openDrawerCustomList(item) {
      this.$emit('open-drawer-custom-list', item.customListType)
    },
    changeIndex(event) {
      this.$emit('change-index', event)
    }
  }
}
</script>

<style scoped>
.content-icons {
  align-items: center;
}

.option-icon {
  cursor: pointer;
}

.option-not-allowed {
  cursor: not-allowed;
}

.option-grab {
  cursor: grab;
  cursor: -moz-grab;
  cursor: -webkit-grab;
}

.option-grab:active {
  cursor: grabbing;
  cursor: -moz-grabbing;
  cursor: -webkit-grabbing;
}

.option-grabbing {
  cursor: grabbing;
}
</style>