<template>
  <div>    
    <div class="block lg:flex lg:space-x-6">

    <div class="-mt-2 -ml-6">
      <el-menu
        data-cy="system-admin-settings"
        :default-active="activePath"
        :router="true"
        >
        <el-menu-item index="/system/tenants" v-if="isSystemAdmin">
          <i class="el-icon-school"></i>
          <span>Tenants</span>
        </el-menu-item>
        <el-menu-item index="/system/performance-imports" v-if="isSystemAdmin" class="uil-icons-style">
          <i class="uil-chart-line"></i>
          <span>Performance Imports</span>
        </el-menu-item>
        <el-menu-item index="/system/integrations" v-if="isSystemAdmin" class="uil-icons-style">
          <i class="el-icon-setting"></i>
          <span>Integrations</span>
        </el-menu-item>
        <el-menu-item index="/system/expiring-trials" v-if="isSystemAdmin">
          <i class="el-icon-date"></i>
          <span>Expiring Trials</span>
        </el-menu-item>
        <el-menu-item index="/system/billing" v-if="isSystemAdmin">
          <i class="el-icon-bank-card"></i>
          <span>Billing</span>
        </el-menu-item>
        <el-menu-item index="/system/premium-status" v-if="isSystemAdmin">
          <i class="el-icon-trophy"></i>
          <span>Customer Changes</span>
        </el-menu-item>
        <el-menu-item index="/system/telnyx" v-if="isSystemAdmin">
          <i class="el-icon-phone"></i>
          <span>Telnyx</span>
        </el-menu-item>
      </el-menu>
    </div>


      
      <div class="flex-1 grid grid-cols-1">
        <transition name="fade"  mode="out-in">   
          <router-view name='settings'/>
        </transition>
      </div>
    </div>
  </div>
</template>

<script>

import { mapGetters } from 'vuex'

export default {
  computed:{
    ...mapGetters(['isSystemAdmin']),
    activePath(){
      var pathArray = this.$route.path.split("/")
      var basePath = `/${pathArray[1]}/${pathArray[2]}`
      return basePath
    }
  }
}
</script>

<style lang="scss" scoped>
.router-link-active{
  @apply text-brand;
}
.uil-icons-style{
  &.el-menu-item i{
    font-size: 17px;
  }
  [class^="uil-"]:before, [class*=" uil-"]:before {
    margin-right: 8px;
  }
}
</style>
