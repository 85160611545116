<template>
  <div data-cy="ad-subscription-plan-card" v-loading="loading">
    <div class="flex justify-between">
      <h1>Account Details</h1>
      <!-- <div class="flex space-x-2" v-show="!showPlanOverview && !showPurchaseOptions && !showOrderSummary && !showCheckout">
        <el-dropdown @command="handleCommand">
          <i 
            title="Account Options"
            class="icon-button hover:text-primary uil uil-cog"
          />
          <el-dropdown-menu slot="dropdown" >
          <el-dropdown-item command="DeleteAccount">Delete Entire Account</el-dropdown-item>
          </el-dropdown-menu>
        </el-dropdown>
      </div> -->
    </div>

    <!-- UPGRADE CARDS -->
    <transition :name="transitionName" mode="out-in">
      <!-- <keep-alive > -->
        <PlanOverview
          class="child-view"
          v-if="showPlanOverview"
          key="planOverivew"
          :tenant="tenant"
          :numActiveStaff="numActiveStaff"
          :costPerDriver="costPerDriver"
          :selected="selected"
          :loadTrigger="loadTrigger"
          @load-tenant="loadData"
          @edit-purchase-options="transitionEditPurchaseOptions"/>
        <OrderSelect
          class="child-view"
          v-else-if="showPurchaseOptions"
          key="orderSelect"
          :tenant="tenant"
          :numActiveStaff="numActiveStaff"
          :costPerDriver="costPerDriver"
          :editOptions="editOptions"
          :prevSelection="selected"
          @cancel-edit="transitionCancelEdit"
          @show-order-summary="transitionOrderSummary"/>
        <OrderSummary
          class="child-view"
          ref="orderSummary"
          v-else-if="showOrderSummary"
          key="orderSummary"
          :tenant="tenant"
          :numActiveStaff="numActiveStaff"
          :costPerDriver="costPerDriver"
          :selected="selected"
          @complete-order="trasitionCompleteOrder"
          @checkout="transitionCheckout"
          @show-purchase-options="transitionPurchaseOptions"/>
        <Checkout
          class="child-view"
          v-else-if="showCheckout"
          key="checkout"
          :loadTrigger="checkoutLoadTrigger"
          :tenant="tenant"
          :numActiveStaff="numActiveStaff"
          :costPerDriver="costPerDriver"
          :selected="selected"
          @complete-order="updateTenant"
          @show-order-summary="transitionCheckoutBack"/>
      <!-- </keep-alive> -->
    </transition>

    <!-- DELETE BUTTONS --> 
    <!-- <div class="mt-8" v-show="showPlanOverview || isTrial">
        <el-button size="small" type="danger" @click="">Delete Entire Account</el-button>
    </div> -->

    <!-- DELETE ACCOUNT MODAL -->
    <!-- <el-dialog
      title="Are you sure?"
      :visible.sync="deleteAccountModalOpen"
      width="30%"
      @close="closeModal"
    >
    <p class="padding-bottom-1 warning-message">{{ deletionMessage }}</p>
    <p class="red-text">There is no undo for this action</p>
    <el-form label-position="top" >
      <el-form-item label="Type the word delete">
      <el-input v-model="deleteConfirmation"></el-input>
    </el-form-item>
    </el-form>
    <span slot="footer">
    <el-button @click="closeModal">Cancel</el-button>
    <el-button type="primary" @click="deleteAccount" :disabled="loading">Confirm</el-button>
    </span>
  </el-dialog> -->

  <!-- <el-dialog
    title="Are you sure?"
    :visible.sync="deleteAccountModalOpen"
    width="30%"
  >
  <DeleteDialog 
    :deletionType="deletionType" 
    :deletionMessage="deletionMessage"
    :group="$store.state.userInfo.tenant.group"
    @close-deletion-modal="closeModal"
    @show-loading-screen="startLoading"
    @stop-loading="stopLoading"/>
  </el-dialog> -->



  </div>
</template>

<script>
import {Auth, API, Storage} from 'aws-amplify'
import sendInternalMessage from '@/scripts/sendInternalMessage'
import {mapState, mapMutations} from 'vuex'
import OrderSelect from './components/OrderSelect'
import OrderSummary from './components/OrderSummary'
import PlanOverview from './components/PlanOverview'
import Checkout from './Checkout'
import DeleteDialog from '@/components/DeleteDialog'
import { createPremiumStatusHistory, updateTenant } from '@/graphql/mutations'
import { updateZohoAccountTenant } from '../../../components/Zoho/zoho'
import { getTenant, staffsByGroup } from './queries'
import { getTenantUpdatedTimestamp } from '@/utilities/timestampQueries'
import { getUser } from '@/api/queries'
import { telnyxAddNumber } from '../../System/Tenants/telnyx'
import { getPremiumStatusHistory } from '@/utilities/premiumValidator';

export default {
  name: 'AccountDetails',
  components: {
    OrderSelect,
    OrderSummary,
    PlanOverview,
    Checkout,
    DeleteDialog
  },
  data(){
    
    return{
      transitionName: 'slide-right',
      tenant: {},
      loading: false,
      checkoutLoadTrigger: false,
      // showPurchaseOptions: false,
      editOptions: false,
      showOrderSummary: false,
      showCheckout: false,
      deleteAccountModalOpen: false,
      deleteConfirmation:'',
      deletionType: '',
      deletionMessage: '',
      userArray: [],
      costPerDriver: {},
      selected: {
        bundle: false,
        messaging: false,
        performance: false,
        rostering: false,
        staff: false,
        standard: false,
        vehicles: false
      },
      numActiveStaff: null,
      deleteTest: true,
      loadTrigger: false
    }
  },

  computed:{
    ...mapState(['userInfo']),    

    showPlanOverview(){
      if(!this.tenant.accountPremiumStatus ) return false
      return (!this.showPurchaseOptions && !this.showOrderSummary && !this.showCheckout)
    },
    showPurchaseOptions(){
      if(!this.tenant.accountPremiumStatus ) return false
      return ((this.tenant.accountPremiumStatus.includes('trial') || this.editOptions) && !this.showOrderSummary  && !this.showCheckout)
    },
    isTrial(){
      if(!this.tenant.accountPremiumStatus) return false
      return this.tenant.accountPremiumStatus.includes('trial')
    },
    bundleCostPerDriver(){
      return this.costPerDriver.standard + 
      this.costPerDriver.performance + 
      this.costPerDriver.rostering + 
      this.costPerDriver.staff + 
      this.costPerDriver.vehicles - 1 ;
    },
  },

  mounted(){
    this.loadData()
  },

  methods:{
    ...mapMutations([ 'setSessionInfo', 'setUserInfo']),


    /**
     * Close modal and clear out deletionConfirmation
     */
    closeModal(){
      this.deleteAccountModalOpen = false;
    },


    /**
     * Set loading to true
     */
    startLoading(){
      this.loading = true;
    },


    /**
     * Set loading to false
     */
    stopLoading(){
      this.loading = false;
    },


    /**
     * Handles commands passed from the dropdown
     * @param {string} command - The name of the route you want to go to
     */
    handleCommand(command){
        if(command == "DeleteAccount"){
          this.openDeletionModal('Account', 'Are you sure you want to delete your account and all associated data?')
          return
        }
    },

    /**
     * Opens deletion dialog and sets parameters up for deletion functions
     */
    openDeletionModal: function(type, message){
      this.deletionType = type;
      this.deletionMessage = message;
      this.deleteAccountModalOpen = true;
    },


    async loadData(){
      /** Load user data */
      this.loading = true
      try{
        var tenantId = this.$store.state.userInfo.tenant.id;
        const tenant = await this.api(getTenant, { id: tenantId })
        this.tenant  = tenant.data.getTenant

        // set plan options
        this.selected =  {
          bundle: false,
          messaging: false,
          performance: false,
          rostering: false,
          staff: false,
          standard: false,
          vehicles: false
        }
        this.tenant.accountPremiumStatus.forEach(type =>{
          if(type != 'trial') this.selected[type] = true
        })
        this.costPerDriver.standard = this.tenant.costStandard
        this.costPerDriver.bundle = this.tenant.costBundle
        this.costPerDriver.performance = this.tenant.costPerformance
        this.costPerDriver.rostering = this.tenant.costRostering
        this.costPerDriver.staff = this.tenant.costStaff
        this.costPerDriver.vehicles = this.tenant.costVehicles
        this.costPerDriver.messaging = this.tenant.costMessaging

        var input = {
          group: this.$store.state.userInfo.tenant.group
        }
        var staff = await this.gLoadListAll(staffsByGroup, input, 'staffsByGroup')
        var numActiveStaff = staff.filter(employee =>{
          return employee.status == 'Active'
        }).length
        this.numActiveStaff = numActiveStaff


        this.loading = false

        var params = this.$route.params
        if(params.navigateToAddOnSelection){
          this.transitionEditPurchaseOptions()
        }
      }
      catch(e){
        console.error("Error AccountDetails: ", e)
        this.loading = false
        this.displayUserError(e)
      }
    },

    async updateTenant(){
      try {
        this.loading = true
        let tenantUpdatedAt = this.tenant.updatedAt
        if(this.tenant.messageServiceProvider == 'None') {
          const updatedTenant = await telnyxAddNumber(this.tenant)
          tenantUpdatedAt = updatedTenant.data.updateTenant.updatedAt
        }

        var selectedFeatures = []
        Object.keys(this.selected).forEach(key => {
          if(this.selected[key]) selectedFeatures.push(key)
        })
        var expDate
        var d = new Date();
        var year = d.getFullYear();
        var month = d.getMonth();
        var day = d.getDate();
        if(selectedFeatures.includes('bundle')){
          expDate = new Date(year + 1, month, day);
        }
        else{
          expDate = new Date(d.getTime() + (60 * 60 * 24 * 1000))
        }
    
        if(selectedFeatures.includes('None')){
          selectedFeatures.splice(selectedFeatures.indexOf('None'),1)
        }

        var input = {
          id: this.tenant.id,
          accountPremiumStatus: selectedFeatures,
          costStandard: this.costPerDriver.standard,
          costBundle: this.bundleCostPerDriver,
          costPerformance: this.costPerDriver.performance,
          costRostering: this.costPerDriver.rostering,
          costStaff: this.costPerDriver.staff,
          costVehicles: this.costPerDriver.vehicles,
          updatedAt: tenantUpdatedAt,
          customerStatus: this.calculateCustomerStatus(selectedFeatures, this.tenant.trialExpDate)
        }

        if(selectedFeatures.includes('bundle') && !this.tenant.accountPremiumStatus.includes('bundle')) input.bundleDeactivationAllowedAt = expDate
        if(selectedFeatures.includes('performance') && !this.tenant.accountPremiumStatus.includes('performance')) input.performanceDeactivationAllowedAt = expDate
        if(selectedFeatures.includes('rostering') && !this.tenant.accountPremiumStatus.includes('rostering')) input.rosteringDeactivationAllowedAt = expDate
        if(selectedFeatures.includes('staff') && !this.tenant.accountPremiumStatus.includes('staff')) input.staffDeactivationAllowedAt = expDate
        if(selectedFeatures.includes('vehicles') && !this.tenant.accountPremiumStatus.includes('vehicles')) input.vehiclesDeactivationAllowedAt = expDate

        if(this.tenant.accountPremiumStatus.includes('None')){
          input.accountCanceledReason = ''          
        }

        let premiumStatusInput = {
          group: this.tenant.group,
          accountPremiumStatus: this.tenant.accountPremiumStatus,
          premiumStatusHistoryTenantId: this.tenant.id,
        }
        
        await this.api(createPremiumStatusHistory, { input: premiumStatusInput })

        if(!this.tenant.firstConvertedToPaidDateTime){
          const historyStatusTenant = await getPremiumStatusHistory(this.$store.state.userInfo.tenant)
          const firstConvertedToPaidDateTime = historyStatusTenant.find((history) => !history?.accountPremiumStatus.includes('trial') && !history?.accountPremiumStatus.includes('None')) || {};
          input.firstConvertedToPaidDateTime = firstConvertedToPaidDateTime?.createdAt || new Date().toISOString();
        }

        const result = await this.api(updateTenant, {input: input}, getTenantUpdatedTimestamp, 'getTenant')
        // update zoho account tenant  
        try{
          if(!this.$store.getters.isZohoCrmDisabled){
            let _id = this.tenant && this.tenant.zohoCrmAccountRecordId ? this.tenant.zohoCrmAccountRecordId : null;
            let _payload = {
              id: _id,
              Customer_Status:input.customerStatus             
            }
            try {
              await updateZohoAccountTenant('update', _payload, this.displayUserError)
            } catch (error) {
            console.error('error updating zoho account', error)
            }
          }
          }catch(e){
            this.displayUserNotification({
                title: "Error",
                type: "error",
                message: e
          })
        }
        // trigger admin email notification of plan change
        let newPlan = selectedFeatures.join(', ')
        let oldPlan = this.tenant.accountPremiumStatus.join(', ')        
        let messageBody = `Tenant ${this.tenant.companyName} (group name: ${this.tenant.group}) has switched to a new plan: ${newPlan}. The previous plan was ${oldPlan}.`
        await sendInternalMessage({
          to: 'admin+planchange@herasolutions.info', 
          subject: 'Tenant plan change', 
          bodyText: messageBody,
          bodyHtml: messageBody 
        })

        this.displayUserNotification({
          type: 'success',
          title: "Success",
          message: 'Account Updated'
        });

        // update store user data so getters work properly
        const userId = this.$store.state.userInfo.id
        const user = await this.api(getUser, {id: userId, group: 'system_admin'})
        this.setUserInfo(user.data.getUser)

        // update intercom
        // var intercomUserData = {
        //   app_id: process.env.VUE_APP_ID_INTERCOM,
        //   user_id: this.$store.state.userInfo.id,
        //   company:{
        //     company_id: this.$store.state.userInfo.tenant.id,
        //     plan: this.toTitleCase(selectedFeatures.join(' ')).replace(/ /g, '-'),
        //     // you can only buy plans here
        //     // but intercom doesn't seem to let you
        //     // remove old fields (here we would remove canceled reason)
        //     // https://github.com/intercom/intercom-node/issues/144
        //     cancelation_reason: 'NOT canceled' 
        //   }
        // };
        // window.Intercom('update', intercomUserData)
        
        await this.$nextTick()
        this.trasitionCompleteOrder()

        
      } 
      catch(e) {
        console.error(e)
        this.displayUserError(e)        
      }
      finally{
        this.loading = false
      }
    },

    transitionOrderSummary(payload){
      this.transitionName = 'slide-left'
      this.showOrderSummary = true
      this.costPerDriver = payload.costPerDriver
      this.selected = payload.selected
    },

    trasitionCompleteOrder(){
      this.transitionName = 'slide-left'
      this.showOrderSummary = false
      this.showCheckout = false
      this.editOptions = false
      this.loadData()
      this.loadTrigger = !this.loadTrigger
      // reload window to updae top nav display
            location.reload()
    },

    transitionPurchaseOptions(){
      this.transitionName = 'slide-right'
      this.showOrderSummary = false
      this.showCheckout = false
      this.editOptions = true
    },

    async transitionEditPurchaseOptions(){
      try{
        var result = null
        var input = {
          ...this.tenant
        }
        this.cleanTenantInput(input)
        
        result = await this.api(updateTenant, {input: input}, getTenantUpdatedTimestamp, 'getTenant')
        this.tenant.updatedAt = result.data.updateTenant.updatedAt
        this.transitionName = 'slide-left'
        this.showOrderSummary = false
        this.editOptions = true
      }
      catch(e){
        console.error(e)
        this.displayUserError(e)
      }
    },


    transitionCancelEdit(){
      this.transitionName = 'slide-right'
      this.showOrderSummary = false
      this.editOptions = false
      this.loadData()
    },

    transitionCheckout(){
      this.transitionName = 'slide-left'
      this.showOrderSummary = false
      this.showCheckout = true
      this.editOptions = false
      this.checkoutLoadTrigger = !this.checkoutLoadTrigger
    },

    transitionCheckoutBack(){
      this.transitionName = 'slide-right'
      this.showOrderSummary = true
    },

  },
}
</script>

<style scoped>

  .el-select{
        width: 100%;
  }
  .red-text{
    @apply text-red-600;
  }

  .padding-bottom-1{
    @apply pb-3
  }

.child-view {
  transition: all .5s cubic-bezier(.55,0,.1,1);
}
.slide-left-enter, .slide-right-leave-active {
  opacity: 0;
  -webkit-transform: translate(30px, 0);
  transform: translate(30px, 0);
}
.slide-left-leave-active, .slide-right-enter {
  opacity: 0;
  -webkit-transform: translate(-30px, 0);
  transform: translate(-30px, 0);
}

.warning-message{
    word-break:break-word;
}
</style>
