export const listSystems = /* GraphQL */ `
  query ListSystems(
    $filter: ModelSystemFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listSystems(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        importDisabled
        importPodDisabled
        importCxDisabled
        importPpsDisabled
        importMentorDisabled
        importNetradyneDisabled
        importEocDisabled
      }
      nextToken
    }
  }
`;
