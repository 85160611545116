export const listSystems = /* GraphQL */ `
  query ListSystems(
    $filter: ModelSystemFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listSystems(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        defaultCostStandard
        defaultCostBundle
        defaultCostPerformance
        defaultCostRostering
        defaultCostStaff
        defaultCostVehicles
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;