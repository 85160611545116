import { getUser } from '@/api/queries'
import store from '@/store/index.js'
import router from '@/router/index'
import { associateSelectOptionsDefault, vehicleSelectOptionsDefault } from '@/utilities/constants/filterOptions/selectOptionsDefault'
import { Auth, API, graphqlOperation } from 'aws-amplify'
import { createUser, updateUser, deleteUser, createPremiumStatusHistory, updateTenant } from '@/graphql/mutations' 
import { updateTenantPlans, getTenant } from './queries'
import { getTenantUpdatedTimestamp } from '@/utilities/timestampQueries'
import { telnyxRemoveNumber, api } from './telnyx'
import sendInternalMessage from '@/scripts/sendInternalMessage'

export async function setGroup(tenant) {
    //return console.log('tenn', tenant)
    //let fulltenant = await API.get(getTenant, { id: tenant.id })
    tenant = await API.graphql(graphqlOperation(getTenant, { id: tenant.id }))
    //return console.log('full tenant users', tenant.data.getTenant.users)
    //this.allUsers = [...tenant.data.getTenant.users.items];
    tenant = tenant.data.getTenant
    let userId = 0
    let targetUser = tenant.users.items.filter(item => item.email.includes('sysadmin'))
    
    if (targetUser.length > 0) {
        console.log('target user', JSON.stringify(targetUser[0]))
        userId = targetUser[0].id
    } else { 
        console.log('target user NOT found')
        // Create default sysadmin user
        let email = tenant.group
        let formattedEmail = `sysadmin-${email}@herasolutions.info`

        let userInput = {
            firstName: 'Support',
            email: formattedEmail,
            lastName: 'Administrator',
            // phone omitted intentionally
            role: '',
            permissionLogin: true,
            permissionFullAccess: true,
            permissionDocuments: true,
            permissionCounselings: true,
            permissionManageCounselings: true,
            permissionAccidents: true,
            permissionInjuries: true,
            permissionDrugTests: true,
            receiveSmsTaskReminders: true,
            receiveEmailTaskReminders: true,
            receiveSmsTaskAssignments: true,
            receiveEmailTaskAssignments: true,
            userTenantId: tenant.id
        }

        let createCognito
        try {
            
            let newuser = await api(createUser, { input: userInput })
            userId = newuser.data.createUser.id
            
            // create cognito user
            let cognitoUser = null
            try{
                createCognito  = await createCognitoUser(formattedEmail, userId)
                
                // Add user to group
                await addUserToCognitoGroup(formattedEmail, tenant.group)
                await addUserToCognitoGroup(formattedEmail, 'admin')

            }catch(e){
                console.error('error creating cognito user', e.message, cognitoUser)
                throw e
            }

            cognitoUser = await getCognitoUser(formattedEmail)

            var sub = cognitoUser.UserAttributes.find((attr) => {
                return attr.Name == "sub"
            }).Value

            let input = {
                id: userId,
                cognitoSub: sub,
                group: [
                    tenant.group,
                    'system_admin'
                ]
            }
            await api(updateUser, { input: input })
        }
        catch (e) {
            if(createCognito){
                var deleteCognitoResults = await deleteCognitoUser(formattedEmail)
            }
            if (userId) {
                var deleteUserresults = await api(deleteUser, { input: { id: userId } })
            }
            return false
        }
    }

    const user = await api(getUser, { id: userId })
    
    // Persistence in localStorage
    localStorage.setItem('superadminId', store.state.userInfo.id)
    localStorage.setItem('supportAdministrator', userId)
    if(store.state.lastUserSession !== user.data.getUser.id){
        //Associate Filters Mutations
        associateSelectOptionsDefault.map((associateSelectOption) => {
            store.commit('setFilterAssociateList', { filter: associateSelectOption.filter, value: associateSelectOption.value})
        })
        //Vehicle Filters Mutations
        vehicleSelectOptionsDefault.map((vehicleSelectOption) => {
            store.commit('setVehicleListFilters', { filter: vehicleSelectOption.filter, value: vehicleSelectOption.value })
        })
        store.commit('setLastUserSession', user.data.getUser.id)
    }

    store.commit('setUserInfo', user.data.getUser)

    router.push({ name: 'Dashboard' })
    window.location.reload()
}


export async function changePlanToNone(payloadCanceled, invoicesToCharge, invoicesToWrittenOff, tenant, updateZoho) {

    try{
        let res = await API.post('tenant', '/change-plan-to-none', {
            body: { 'tenant': tenant, 'invoicesToCharge': invoicesToCharge,'invoicesToWrittenOff': invoicesToWrittenOff, 'payloadCanceled':payloadCanceled, 'updateZoho': updateZoho},
            headers: {
                'Content-Type' : 'application/json'
            } 
        })
        console.log('Response of Change Plan to None Api:', res)
        return res;

    }catch(e){
        console.log('Error calling Change Plan to None', e)
        return { message: 'An Api error occurred. Unable to change the plan to "None".', status: 'Failed'};
    }
}

// export async function updateIntercom(tenant, $store) {
//     if(tenant.users.items.length < 1) {
//         return
//     }
//     let intercomUserData = {
//         app_id: process.env.VUE_APP_ID_INTERCOM,
//         user_id: tenant.users.items[0].id,
//         email: tenant.users.items[0].email,
//         name: tenant.users.items[0].firstName + " " + tenant.users.items[0].lastName,
//         role: tenant.users.items[0].role,
//         phone: tenant.users.items[0].phone,
//         company:{
//             company_id: tenant.id,
//             name: tenant.companyName,
//             trial_expiration_date: tenant.trialExpDate?.toString().split('T')[0]                  
//         }
//     }

//     if(!!tenant.accountCanceledReason){
//         intercomUserData.company.cancelation_reason = tenant.accountCanceledReason                  
//     }
//     if(!!tenant.notes){
//         intercomUserData.company.notes = tenant.notes                 
//     }

//     //UPDATE COMPANY IN INTERCOM
//     await window.Intercom('update', intercomUserData)

//     //CLOSE INTERCOM
//     await window.Intercom('shutdown')


//     //REBOOT INTERCOM TO SHOW ORIGINAL USER
//     let intercomBoot = {
//         app_id: process.env.VUE_APP_ID_INTERCOM,
//         user_id: $store.state.userInfo.id,
//         email: $store.state.userInfo.email,
//         name: $store.state.userInfo.firstName + ' ' + $store.state.userInfo.lastName,
//         role: $store.state.userInfo.role,
//         phone: $store.state.userInfo.phone,
//         company:{
//             company_id: $store.state.userInfo.tenant.id,
//             name: $store.state.userInfo.tenant.companyName
//         }

//     }
//     await window.Intercom('boot', intercomBoot );
//     return true
// }

export async function createCognitoUser(username, userId, resend = false){
    let apiName = 'AdminQueries';
    let path = '/createUser';
    let post = {
        body: {
            "username": username,
            "userId": userId,
            "resend": resend,
            "tenantName": store.state.userInfo.tenant.companyName
        },
        headers: {
            'Content-Type': 'application/json',
            Authorization: `${(await Auth.currentSession()).getAccessToken().getJwtToken()}`
        }
    }
    var result = await API.post(apiName, path, post);
    return result
}


/**
 * Adds the user to a group
 * @param {string} username
 * @param {string} groupname
 */
export async function addUserToCognitoGroup(username, groupname) {
    let apiName = 'AdminQueries';
    let path = '/addUserToGroup';
    let myInit = {
        body: {
            "username": username,
            "groupname": groupname
        },
        headers: {
            'Content-Type': 'application/json',
            Authorization: `${(await Auth.currentSession()).getAccessToken().getJwtToken()}`
        }
    }
    var result = await API.post(apiName, path, myInit);
}


/**
 * Delete the user
 * @param {string} username
 */
export async function deleteCognitoUser(username){
    let apiName = 'AdminQueries';
    let path = '/deleteUser';
    let post = {
        body: {
            "username": username
        },
        headers: {
            'Content-Type': 'application/json',
            Authorization: `${(await Auth.currentSession()).getAccessToken().getJwtToken()}`
        }
    }
    var result = await API.post(apiName, path, post);
    return result
}


/** 
 * Gets a single cognito user
 * @param {string} username 
 * */
export async function getCognitoUser(username){
    let apiName = 'AdminQueries';
    let path = '/getUser';
    let myInit = {
        queryStringParameters: {
            "username": username,
        },
        headers: {
            'Content-Type': 'application/json',
            Authorization: `${(await Auth.currentSession()).getAccessToken().getJwtToken()}`
        }
    }
    return await API.get(apiName, path, myInit);
}