<!-- DEACTIVATE MODAL -->
<template>
    <el-dialog
        :close-on-click-modal="false"
        title="Deactivate status"
        :visible.sync="deactivateModalOpen"
        width="50%"
        >
        <div v-loading="deactivateTenantLoading" v-html="deactivateTenantMessage"></div>
        <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="$emit('close')" :disabled="deactivateTenantLoading">Close</el-button>
        </span>
    </el-dialog>
</template>

<script>
export default {
    props: [
        'deactivateModalOpen', 'deactivateTenantLoading', 'deactivateTenantMessage'
    ]
}
</script>

