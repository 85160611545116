<template>
    <div data-cy="settings-coaching-form">

    <h1 class="mb-4">Coaching</h1>

    <el-collapse v-model="collapseActive" class="text-sm">
        <div class="card"  v-loading="loading.coachingMessages">
            <div class="card-title">Coaching Messages</div>
            <el-form ref="form" :model="tenant"  label-position="top" class="-mb-4">
                <el-form-item prop="automatedCoachingSendTime">
                    <template slot="label">
                        Time to send out pending messages
                        <el-tooltip class="item" effect="dark" :placement="isDeviceMobileOnly() ? 'bottom' : 'right-start'" popper-class="custom-tooltip-lowest-z-index">
                            <div slot="content" class="w-52">
                            Pending messages are created from scorecard, customer feedback, Mentor, and Netradyne data imports. They will be automatically sent on the day {{$t('label.an_associate')}} is next rostered. They can also be edited and sent manually from the Coaching screen.
                            </div>
                            <i class="uil uil-question-circle text-base"></i>
                        </el-tooltip>
                    </template>

                    <el-select
                        data-cy="sc-time-sel" class="mr-3 w-32"
                        v-model="tenant.automatedCoachingSendTime"
                        placeholder="Select time"
                        @change="updateTenant(true, 'SendTime')"
                        clearable 
                        filterable
                        @clear="clearCouchingTime">
                        <el-option
                            v-for="item in timeOptions"
                            :key="item.time"
                            :label="item.label"
                            :value="item.time">
                        </el-option>
                    </el-select>

                    <span class="text-gray-600">
                        {{timeZone | timeZone}}
                        <el-tooltip class="item" effect="dark" :placement="isDeviceMobileOnly() ? 'bottom' : 'top-start'" popper-class="custom-tooltip-lowest-z-index">
                            <div slot="content">
                            Automatic time zone settings can be changed from Company Details
                            </div>
                            <i class="uil uil-question-circle text-base"></i>
                        </el-tooltip>
                    </span>
                </el-form-item>
            </el-form>
        </div>

        <div class="card relative" v-loading="loading.driverRank">
            <div class="card-title">{{$t('label.associate')}} Performance Report</div>
            <div class="space-x-2 font-bold align-middle text-sm">
                <span>{{$t('label.associates_')}} Hera Scores should take into account the last</span>
                <el-select data-cy="sc-rank-range-sel" v-model="tenant.coachingDriverRankRange" size="small" placeholder="..." @change="updateTenant(true, 'DriverRankRange')" class="w-20">
                    <el-option
                        v-for="option in driverRankRangeOptions"
                        :key="option"
                        :label="option"
                        :value="option">
                    </el-option>
                </el-select>
                <span>days</span>
            </div>
            <div class="italic">
                The longer time frame you pick, the less your {{$t('label.associates_')}} Hera Scores will change. The recommended range is 15 - 30 days.
            </div>
            <div class="font-bold mt-4">
                You can configure how important each {{$t('label.associate')}} Issue and Kudo is to your {{$t('label.associates_')}} Hera Scores. Disabled {{$t('label.associate')}} Issues and Kudos will not be taken into consideration when determining a {{$t('label.associate_s')}} Hera Score.
            </div>
            <div 
                @click="$router.push({name: 'SettingsAccountDetails', params:{navigateToAddOnSelection: true}})"
                class="premium-warning-container"
                v-if="!$store.getters.hasPerformanceCoaching">
                <div class="premium-warning-triangle">
                    <p class="transform-text text-sm text-white">Requires Premium</p>
                </div>
            </div>
        </div>

        <div class="mb-4 mt-4">
            <div class="mt-4">
                <el-input
                    data-cy="df-settings-search-in"
                    v-model="search"
                    prefix-icon="uil uil-search"
                    clearable
                    :placeholder="`Filter ${$t('label.associate')} Issue and Kudo types...`"/>
            </div>
        </div>

        <!-- WEEKLY PERFORMANCE DATA -->
        <el-collapse-item  title="Weekly Performance Data" name="weeklyPerformanceData" class="relative overflow-hidden" v-loading="loading.weeklyPerformanceData">
            <section v-if="showWeekly" class="overflow-x-auto">
                <div class="flex mt-2">
                    <div class="w-54 font-bold flex-none"></div>
                </div>
                <!-- HEADER WEEKLY -->
                <div class="flex -mb-11 font-bold">
                    <div class="w-54 flex-none"></div>
                    <div class="flex-col">
                        <div class="header-title">
                            <div class="w-96 border-red-500 border-t-2 rounded-lg text-center text-red-500 ">
                                <span class="px-4 -top-4 text-red-500 text-sm relative bg-white font-bold">Improve Poor Performance</span>
                            </div>
                        </div>
                        <div class="flex">
                            <div class="w-49 pl-2">
                                Create {{$t('label.associate')}} Issues
                                <el-tooltip class="item" effect="dark" :placement="isDeviceMobileOnly() ? 'bottom' : 'right-start'" popper-class="custom-tooltip-lowest-z-index">
                                    <div slot="content" class="w-80 custom-tooltip-content-overflow">
                                        <div class="mb-4">{{$t('label.associate')}} Issues can be used to track the performance of your {{$t('label.associates')}}. Based on your settings below, they influence each {{$t('label.associate_s')}} Hera Score and Hera Rank, which show in your {{$t('label.associate')}} Performance report.</div>
                                        <div class="mb-4">{{$t('label.associate')}} Issues can also optionally be added to Counselings, which are more formal write-ups, to hold the {{$t('label.associate')}} accountable, and that can be shared with your {{$t('label.associates')}} for their signature.</div>
                                        <div class="mb-4">{{$t('label.associate')}} Issues can be created manually at any time, but the settings below also allow you to create them automatically whenever you import your performance data. </div>
                                        <div>Unless you attach them to Counselings, {{$t('label.associate')}} Issues do not get sent out to {{$t('label.associates')}} and cannot be seen by anyone without a Hera login.</div>
                                    </div>
                                    <i class="uil uil-question-circle text-base"></i>
                                </el-tooltip>
                            </div>

                            <div class="w-49 pl-2">
                                Coaching Opportunities
                                <el-tooltip class="item" effect="dark" :placement="isDeviceMobileOnly() ? 'bottom' : 'right-start'" popper-class="custom-tooltip-lowest-z-index">
                                    <div slot="content" class="w-80">
                                    <div class="mb-4">Hera can automate your {{$t('label.associate')}} coaching by sending text messages and/or emails whenever a coaching opportunity is identified. Based on the settings below, Hera will create these messages automatically when you import your performance data.</div>
                                    <div>Thresholds allow you to customize exactly when these coaching opportunities get created. Messages get sent daily at the time specified by you at the top of this page.</div>
                                    </div>
                                    <i class="uil uil-question-circle text-base"></i>
                                </el-tooltip>
                            </div>
                        </div>
                    </div>
                    <div class="flex-col">
                        <div class="header-title">
                            <div class="w-90 border-great border-t-2 rounded-lg text-center text-great ml-2">
                                <span class="px-4 -top-4 text-great text-sm relative bg-white font-bold">Strengthen Great Performance</span>
                            </div>
                        </div>
                        <div class="flex">
                            <div class="w-49 pl-4">
                                Positive Reinforcement
                                <el-tooltip class="item" effect="dark" :placement="isDeviceMobileOnly() ? 'bottom' : 'right-start'" popper-class="custom-tooltip-lowest-z-index">
                                    <div slot="content" class="w-80">
                                    <div class="mb-4">Similar to Coaching Opportunities, Hera will use your settings below to automatically create messages for your {{$t('label.associates')}}, congratulating them for a job well done. Coupled with Coaching Opportunities, these messages are key to motivating your staff to achieve high ratings.</div>
                                    <div>Thresholds allow you to customize exactly when these messages get created. Messages get sent daily at the time specified by you at the top of this page.</div>
                                    </div>
                                    <i class="uil uil-question-circle text-base"></i>
                                </el-tooltip>
                            </div>
                            <div class="w-49 pl-4">
                                Create {{$t('label.associate')}} Kudos
                                <el-tooltip class="item" effect="dark" :placement="isDeviceMobileOnly() ? 'bottom' : 'right-start'" popper-class="custom-tooltip-lowest-z-index">
                                    <div slot="content" class="w-80">
                                    <div class="mb-4">You can optionally use {{$t('label.associate')}} Kudos to influence each {{$t('label.associate_s')}} Hera Score and Hera Rank in positive ways. This allows you to see your top performers at your DSP and celebrate them accordingly.</div>
                                    <div>{{$t('label.associate')}} Kudos do not get sent out to {{$t('label.associates')}} and can only be seen by users who log into Hera.</div>
                                    </div>
                                    <i class="uil uil-question-circle text-base"></i>
                                </el-tooltip>
                            </div>
                        </div>
                    </div>
                </div>
                

                <!-- WEEKLY METRICS -->
                <div class="fit-content-metrices" v-for="metric in weeklyPerformanceMetrics" :key="metric.name">
                    <div v-if="metric.header" class="font-bold pt-4 pb-1 flex text-base ">
                        <div class="w-max">
                            {{metric.header}}
                            <el-dropdown data-cy="df-km-opt-sel" @command="handleDropdownCommand" class="ml-2">
                                <i class="context-menu uil uil-ellipsis-h"></i>
                                <el-dropdown-menu slot="dropdown">
                                    <el-dropdown-item :command="'enable-' + metric.header">Enable All</el-dropdown-item>
                                    <el-dropdown-item :command="'disable-' + metric.header">Disable All</el-dropdown-item>
                                    <el-dropdown-item :command="'default-' + metric.header">Reset Defaults</el-dropdown-item>
                                </el-dropdown-menu>
                            </el-dropdown>
                        </div>
                    </div>
                    <div v-show="noScorecardResults && metric.src == 'Scorecard' && metric.header" class="text-center text-base mt-2 text-gray-600">
                        <i class="uil uil-search"></i>
                        <span>No matching Scorecard metrics. <span class="link" @click="search = ''">Show All</span></span>
                    </div>
                    <div v-show="noCxResults && metric.src == 'CXFeedback' && metric.header" class="text-center text-base mt-2 text-gray-600">
                        <i class="uil uil-search"></i>
                        <span>No matching CX metric. <span class="link" @click="search = ''">Show All</span></span>
                    </div>
                    <div v-show="noPpsResults && metric.src == 'ProperParkingSequence(PPS)' && metric.header" class="text-center text-base mt-2 text-gray-600">
                        <i class="uil uil-search"></i>
                        <span>No matching PPS metric. <span class="link" @click="search = ''">Show All</span></span>
                    </div>

                    <!-- CONSECUTIVE FANTASTICS -->
                    <div class="flex rounded-lg bg-gray-100 border my-5" v-show="( metric.name === 'ConsecutiveTier' || metric.name === 'OverallTier' ) && filteredIssueTypes[metric.sliderField]">
                        <div class="w-54 py-2 flex-none pl-2">
                            <div class="font-bold">
                                {{metric.label}}
                                <el-tooltip v-if="metric.tooltip" class="item" effect="dark" :placement="isDeviceMobileOnly() ? 'bottom' : 'top'" popper-class="custom-tooltip-lowest-z-index">
                                <div slot="content">
                                    {{metric.tooltip}}
                                </div>
                                <i class="uil uil-question-circle text-base"></i>
                                </el-tooltip>
                            </div>
                            <div :class="{'mt-12': metric.name === 'ConsecutiveTier', 'mt-5': metric.name != 'ConsecutiveTier'}">
                                {{$t('label.associate')}} Performance Report Setting
                            </div>
                        </div>
                    <div>
                    <div class="flex">
                        <!-- ISSUE -->
                        <div class="w-98 text-center justify-center inline-flex pl-2 pr-6 py-2">
                            <div class="mr-2">Create {{$t('label.associate')}} Issues</div>
                            <p class="w-12 text-center"><el-switch v-model="tenant['coaching'+ metric.name + 'Issue']" @change="updateTenant(false, metric.name + 'Issue')"></el-switch></p>
                            <div>
                            <div class="flex mb-2">
                                <el-select data-cy="da-issues-render-sel" v-model="tenant['coaching' + metric.name + 'RatingIssue']" placeholder="Select" size="mini" class="w-28 ml-1" @change="updateTenant(false, metric.name + 'RatingIssue')">
                                <el-option
                                    v-for="item in valueListActivesOnly($store.state.valuelists['scorecard-tiers'].items)"
                                    :key="item.value"
                                    :label="item.value"
                                    :value="item.value">
                                </el-option>
                                </el-select>
                                <p class="w-12 my-auto text-left text-gray-800 text-xxxs ml-1 font-bold">Or worse</p>
                            </div>
                            <div class="flex" v-if="metric.name === 'ConsecutiveTier'">
                                <p class="w-12 my-auto text-right text-gray-800 text-xxxs mr-1 font-bold">More than</p>
                                <el-input-number data-cy="da-issues-render-in" class="coachingThresholdInput" :controls="false" size="mini" :min="1" v-model.number="tenant['coaching' + metric.name + 'ThresholdIssue']" @change="updateTenant(false, metric.name + 'ThresholdIssue')"></el-input-number>
                                <p class="w-12 my-auto text-left text-gray-800 text-xxxs ml-1 font-bold">weeks</p>
                            </div>
                            </div>
                        </div>
                        <!-- KUDO -->
                        <div class="w-98 text-center inline-flex justify-center pl-2 pr-6 py-2">
                            <div class="mr-2">Create {{$t('label.associate')}} Kudos</div>
                            <p class="w-12 text-center"><el-switch v-model="tenant['coaching'+ metric.name + 'Kudo']" @change="updateTenant(false, metric.name + 'Kudo')"></el-switch></p>
                            <div>
                            <div class="flex mb-2">
                                <el-select data-cy="da-kudo-render-sel" v-model="tenant['coaching' + metric.name + 'RatingKudo']" placeholder="Select" size="mini" class="w-28 ml-1" @change="updateTenant(false, metric.name + 'RatingKudo')">
                                <el-option
                                    v-for="item in valueListActivesOnly($store.state.valuelists['scorecard-tiers'].items)"
                                    :key="item.value"
                                    :label="item.value"
                                    :value="item.value">
                                </el-option>
                                </el-select>
                                <p class="w-12 my-auto text-left text-gray-800 text-xxxs ml-1 font-bold">Or better</p>
                            </div>
                            <div class="flex" v-if="metric.name === 'ConsecutiveTier'">
                                <p class="w-12 my-auto text-right text-gray-800 text-xxxs mr-1 font-bold">More than</p>
                                <el-input-number data-cy="da-kudo-render-in" class="coachingThresholdInput" :controls="false" size="mini" :min="1" v-model.number="tenant['coaching' + metric.name + 'ThresholdKudo']" @change="updateTenant(false, 'ConsecutiveTierThresholdKudo')"></el-input-number>
                                <p class="w-12 my-auto text-left text-gray-800 text-xxxs ml-1 font-bold">weeks</p>
                            </div>
                            </div>
                        </div>
                    </div>
                    <div>
                        <div class="pl-2 pr-8 inline-block w-1/2">
                            <el-slider
                            data-cy="da-issue-type-render-slider"
                            v-if="filteredIssueTypes[metric.sliderField]"
                            v-model="filteredIssueTypes[metric.sliderField].driverReportSetting"
                            @change="saveSlider(filteredIssueTypes[metric.sliderField], 'weeklyPerformanceData', 'filteredIssueTypes', metric.sliderField)"
                            input-size="mini"
                            :disabled="!tenant['coaching' + metric.name + 'Issue']"
                            class="performanceSlider issueSlider"
                            :show-input-controls="false"
                            tooltip-class="custom-tooltip-lowest-z-index"
                            show-input></el-slider>
                            <div  
                                v-if="filteredIssueTypes[metric.sliderField]" 
                                :class="{'text-gray-500': !tenant['coaching' + metric.name + 'Issue']}"
                                class="-mt-2 mb-2 pr-16 w-full text-center text-xxs font-bold">
                                    {{sliderLabel(filteredIssueTypes[metric.sliderField].driverReportSetting)}}
                            </div>
                        </div>
                        <div class="pl-2 pr-8 inline-block w-1/2">
                            <el-slider
                                data-cy="da-kudo-type-render-slider"
                                v-if="filteredKudoTypes[metric.sliderField]"
                                v-model="filteredKudoTypes[metric.sliderField].driverReportSetting"
                                @change="saveSlider(filteredKudoTypes[metric.sliderField], 'weeklyPerformanceData', 'filteredKudoTypes', metric.sliderField)"
                                input-size="mini"
                                :disabled="!tenant['coaching' + metric.name + 'Kudo']"
                                class="performanceSlider kudoSlider"
                                :show-input-controls="false"
                                tooltip-class="custom-tooltip-lowest-z-index"
                                show-input></el-slider>
                                <div 
                                    v-if="filteredKudoTypes[metric.sliderField]" 
                                    :class="{'text-gray-500': !tenant['coaching' + metric.name + 'Kudo']}"
                                    class="-mt-2 mb-2 pr-16 w-full text-center text-xxs font-bold">
                                        {{sliderLabel(filteredKudoTypes[metric.sliderField].driverReportSetting)}}
                                </div>
                            </div> 
                        </div>
                    </div>
                </div>

                <!-- SCORECARD AND CX METRICS -->
                <div class="flex rounded-lg bg-gray-100 border border-gray-400 my-5" v-show="( metric.name != 'ConsecutiveTier' && metric.name != 'OverallTier' && (metric.name != 'HarshBrakingRate' && metric.name != 'HarshCorneringRate') && metric.name != 'DaTier' && metric.name != 'CdfScore' ) && filteredIssueTypes[metric.sliderField]">
                    <div class="w-54 py-2 flex-none pl-2">
                        <div class="font-bold" :class="{'-mb-1': metric.tooltip}">
                            {{metric.label}}
                            <el-tooltip v-if="metric.tooltip" class="item" effect="dark"  :placement="isDeviceMobileOnly() ? 'bottom' : 'top'" popper-class="custom-tooltip-lowest-z-index">
                                <div slot="content">
                                    {{metric.tooltip}}
                                </div>
                                <i class="uil uil-question-circle text-base"></i>
                            </el-tooltip>
                        </div>
                        <div class="mt-4">
                            {{$t('label.associate')}} Performance Report Setting
                        </div>
                    </div>
                    <div>
                        <div class="flex">
                            <!-- ISSUE -->
                            <div class="text-center inline-flex pl-2 pr-6 py-2">
                                <p class="w-12 text-center"><el-switch data-cy="toggle-issue-sw" v-model="tenant['coaching'+ metric.name + 'Issue']" @change="updateTenant(false, metric.name + 'Issue')"></el-switch></p>
                                <p class="font-bold w-12 my-auto text-right text-gray-800 text-xxxs mr-1">{{metric.negativeToggleOnly ? '' : metric.polarity > 0 ? 'Less than' : 'More than'}}</p>
                                <el-input-number 
                                    data-cy="toggle-issue-in"
                                    v-if="!metric.negativeToggleOnly" 
                                    class="coachingThresholdInput" 
                                    :controls="false" 
                                    size="mini" 
                                    :max="!metric?.thresholdIssueMax ? scorecardAndCxMax.thresholdIssueMax : metric.thresholdIssueMax" 
                                    :min="0" 
                                    v-model.number="tenant['coaching'+ metric.name + 'ThresholdIssue']" 
                                    @change="updateTenant(false, metric.name + 'ThresholdIssue')" 
                                    :class="{'invalid-threshold': tenant['coaching'+ metric.name + 'ThresholdIssue'] * metric.polarity > tenant['coaching'+ metric.name + 'ThresholdPR'] * metric.polarity}">
                                </el-input-number>
                                <div class="w-16" v-else></div>
                                <el-tooltip v-if="tenant['coaching'+ metric.name + 'ThresholdIssue'] * metric.polarity > tenant['coaching'+ metric.name + 'ThresholdPR'] * metric.polarity" class="item -mr-6" effect="dark" :placement="isDeviceMobileOnly() ? 'bottom' : 'top'" popper-class="custom-tooltip-lowest-z-index">
                                    <div slot="content" class="w-52">
                                        {{$t('label.associate')}} Issue threshold should not be {{ metric.polarity > 0 ? 'greater' : 'less'}} than the Positive Reinforcement threshold
                                    </div>
                                    <i class="uil uil-info-circle text-base text-red-500 rotate-180"></i>
                                </el-tooltip>
                            </div>
                            <!-- CO -->
                            <div class="text-center inline-flex pr-8 py-2">
                                <p class="w-12 text-center"><el-switch data-cy="toggle-co-sw" v-model="tenant['coaching'+ metric.name + 'CO']" @change="updateTenant(false, metric.name + 'CO')"></el-switch></p>
                                <p class="font-bold w-12 my-auto text-right text-gray-800 text-xxxs mr-1">{{metric.negativeToggleOnly ? '' : metric.polarity > 0 ? 'Less than' : 'More than'}}</p>
                                <el-input-number 
                                    data-cy="toggle-co-in"
                                    v-if="!metric.negativeToggleOnly" 
                                    class="coachingThresholdInput" 
                                    :controls="false" 
                                    size="mini" 
                                    :max="!metric?.thresholdMax ? scorecardAndCxMax.thresholdMax : metric.thresholdMax"  
                                    :min="0" 
                                    v-model.number="tenant['coaching'+ metric.name + 'Threshold']" 
                                    @change="updateTenant(false, metric.name + 'Threshold')" 
                                    :class="{'invalid-threshold': isInvalidScorecardInput(metric)}">
                                </el-input-number>
                                <div class="w-16" v-else></div>
                                <div v-if="isInvalidScorecardInput(metric)">
                                    <el-tooltip class="item -mr-6" effect="dark" :placement="isDeviceMobileOnly() ? 'bottom' : 'top'" popper-class="custom-tooltip-lowest-z-index">
                                        <div slot="content" class="w-52">
                                            Coaching Oppurtunity threshold should not be {{ metric.polarity > 0 ? 'greater' : 'less'}}  than the Positive Reinforcement threshold
                                        </div>
                                        <i class="uil uil-info-circle text-base text-red-500 rotate-180"></i>
                                    </el-tooltip>
                                </div>
                            </div>
                            <!-- PR -->
                            <div class="text-center inline-flex pl-2 pr-6 py-2">
                                <p class="w-12 text-center"><el-switch data-cy="df-pr-swch" v-if="!metric.negativeToggleOnly" v-model="tenant['coaching'+ metric.name + 'PR']" @change="updateTenant(false, metric.name + 'PR')"></el-switch></p>
                                <p class="font-bold w-12 text-right text-gray-800 text-xxxs mr-1">{{metric.negativeToggleOnly ? '' : metric.polarity > 0 ? 'More than or equal to' : 'Less than or equal to'}}</p>   
                                <el-input-number 
                                    data-cy="df-pr-in" 
                                    v-if="!metric.negativeToggleOnly" 
                                    class="coachingThresholdInput" 
                                    :controls="false" 
                                    size="mini" 
                                    :max="!metric?.thresholdPRMax ? scorecardAndCxMax.thresholdPRMax : metric.thresholdPRMax"  
                                    :min="0" 
                                    v-model.number="tenant['coaching'+ metric.name + 'ThresholdPR']" 
                                    @change="updateTenant(false, metric.name + 'ThresholdPR')" >
                                </el-input-number>
                                <div class="w-16" v-else></div>
                            </div>        
                            <!-- KUDO -->
                            <div class="text-center inline-flex pl-3 pr-8 py-2">
                                <p class="w-12 text-center"><el-switch data-cy="df-kudo-swch" v-if="!metric.negativeToggleOnly" v-model="tenant['coaching'+ metric.name + 'Kudo']" @change="updateTenant(false, metric.name + 'Kudo')"></el-switch></p>
                                <p class="font-bold w-12 text-right text-gray-800 text-xxxs mr-1">{{metric.negativeToggleOnly ? '' : metric.polarity > 0 ? 'More than or equal to' : 'Less than or equal to'}}</p>   
                                <el-input-number 
                                    data-cy="df-kudo-in" 
                                    v-if="!metric.negativeToggleOnly" 
                                    class="coachingThresholdInput" 
                                    :controls="false" 
                                    size="mini" 
                                    :max="!metric?.thresholdKudoMax ? scorecardAndCxMax.thresholdKudoMax : metric.thresholdKudoMax"
                                    :min="0" 
                                    v-model.number="tenant['coaching'+ metric.name + 'ThresholdKudo']" 
                                    @change="updateTenant(false, metric.name + 'ThresholdKudo')">
                                </el-input-number>
                                <div class="w-16" v-else></div>
                            </div>   
                        </div>
                        
                        <div>
                            <div class="pl-2 pr-8 inline-block w-1/2">
                                <el-slider
                                    data-cy="df-issues-slider"
                                    v-if="filteredIssueTypes[metric.sliderField]"
                                    v-model="filteredIssueTypes[metric.sliderField].driverReportSetting"
                                    @change="saveSlider(filteredIssueTypes[metric.sliderField], 'weeklyPerformanceData', 'filteredIssueTypes', metric.sliderField)"
                                    input-size="mini"
                                    :disabled="!tenant['coaching'+ metric.name + 'Issue']"
                                    class="performanceSlider issueSlider"
                                    :show-input-controls="false"
                                    tooltip-class="custom-tooltip-lowest-z-index"
                                    show-input></el-slider>
                                <div  
                                    v-if="filteredIssueTypes[metric.sliderField]" 
                                    :class="{'text-gray-500': !tenant['coaching'+ metric.name + 'Issue']}"
                                    class="-mt-2 mb-2 pr-16 w-full text-center text-xxs font-bold">
                                        {{sliderLabel(filteredIssueTypes[metric.sliderField].driverReportSetting)}}
                                </div>
                            </div>
                            <div class="pl-2 pr-8 inline-block w-1/2">
                                <el-slider
                                    data-cy="df-kudo-slider"
                                    v-if="filteredKudoTypes[metric.sliderField] && !metric.negativeToggleOnly"
                                    v-model="filteredKudoTypes[metric.sliderField].driverReportSetting"
                                    @change="saveSlider(filteredKudoTypes[metric.sliderField], 'weeklyPerformanceData', 'filteredKudoTypes', metric.sliderField)"
                                    input-size="mini"
                                    :disabled="!tenant['coaching'+ metric.name + 'Kudo']"
                                    class="performanceSlider kudoSlider"
                                    :show-input-controls="false"
                                    tooltip-class="custom-tooltip-lowest-z-index"
                                    show-input></el-slider>
                                <div 
                                    v-if="filteredKudoTypes[metric.sliderField] && !metric.negativeToggleOnly" 
                                    :class="{'text-gray-500': !tenant['coaching'+ metric.name + 'Kudo']}"
                                    class="-mt-2 mb-2 pr-16 w-full text-center text-xxs font-bold">
                                        {{sliderLabel(filteredKudoTypes[metric.sliderField].driverReportSetting)}}
                                </div>
                            </div> 
                        </div>
                    </div>
                </div>
                <!-- </div> -->
                <!-- SCORECARD AND CX METRICS 2 -->
                <div class="flex rounded-lg bg-gray-100 border border-gray-400 my-5" v-show="metric.name != 'ConsecutiveTier' && metric.name != 'OverallTier' && (metric.name === 'HarshBrakingRate' || metric.name === 'HarshCorneringRate') && filteredIssueTypes[metric.sliderField] && showHarsh">
                    <div class="w-54 py-2 flex-none pl-2">
                        <div class="font-bold" :class="{'-mb-1': metric.tooltip}">
                            {{metric.label}}
                            <el-tooltip v-if="metric.tooltip" class="item" effect="dark" :placement="isDeviceMobileOnly() ? 'bottom' : 'top'" popper-class="custom-tooltip-lowest-z-index">
                                <div slot="content">
                                    {{metric.tooltip}}
                                </div>
                                <i class="uil uil-question-circle text-base"></i>
                            </el-tooltip>
                        </div>
                        <div class="mt-4">
                            {{$t('label.associate')}} Performance Report Setting
                        </div>
                    </div>
                    <div>
                        <div class="flex">
                            <!-- ISSUE -->
                            <div class="text-center inline-flex pl-2 pr-6 py-2">
                                <p class="w-12 text-center"><el-switch data-cy="sc-cx-issue-sw" v-model="tenant['coaching'+ metric.name + 'Issue']" @change="updateTenant(false, metric.name + 'Issue')"></el-switch></p>
                                <p class="font-bold w-12 my-auto text-right text-gray-800 text-xxxs mr-1">{{metric.negativeToggleOnly ? '' : metric.polarity > 0 ? 'Less than' : 'More than'}}</p>
                                <el-input-number 
                                    data-cy="sc-cx-issue-in"
                                    v-if="!metric.negativeToggleOnly" 
                                    class="coachingThresholdInput" 
                                    :controls="false" 
                                    size="mini" 
                                    :max="!metric?.thresholdIssueMax ? scorecardAndCxMax.thresholdIssueMax : metric.thresholdIssueMax" 
                                    :min="0" 
                                    v-model.number="tenant['coaching'+ metric.name + 'ThresholdIssue']" 
                                    @change="updateTenant(false, metric.name + 'ThresholdIssue')" 
                                    :class="{'invalid-threshold': tenant['coaching'+ metric.name + 'ThresholdIssue'] * metric.polarity > tenant['coaching'+ metric.name + 'ThresholdPR'] * metric.polarity}">
                                </el-input-number>
                                <div class="w-16" v-else></div>
                                <el-tooltip v-if="tenant['coaching'+ metric.name + 'ThresholdIssue'] * metric.polarity > tenant['coaching'+ metric.name + 'ThresholdPR'] * metric.polarity" class="item -mr-6" effect="dark" :placement="isDeviceMobileOnly() ? 'bottom' : 'top'" popper-class="custom-tooltip-lowest-z-index">
                                    <div slot="content" class="w-52">
                                        {{$t('label.associate')}} Issue threshold should not be {{ metric.polarity > 0 ? 'greater' : 'less'}} than the Positive Reinforcement threshold
                                    </div>
                                    <i class="uil uil-info-circle text-base text-red-500 rotate-180"></i>
                                </el-tooltip>
                            </div>
                            <!-- CO -->
                            <div class="text-center inline-flex pr-8 py-2">
                                <p class="w-12 text-center"><el-switch data-cy="sc-cx-co-sw" v-model="tenant['coaching'+ metric.name + 'CO']" @change="updateTenant(false, metric.name + 'CO')"></el-switch></p>
                                <p class="font-bold w-12 my-auto text-right text-gray-800 text-xxxs mr-1">{{metric.negativeToggleOnly ? '' : metric.polarity > 0 ? 'Less than' : 'More than'}}</p>
                                <el-input-number 
                                    data-cy="sc-cx-co-in"
                                    v-if="!metric.negativeToggleOnly" 
                                    class="coachingThresholdInput" 
                                    :controls="false" 
                                    size="mini" 
                                    :max="!metric?.thresholdMax ? scorecardAndCxMax.thresholdMax : metric.thresholdMax"
                                    :min="0" 
                                    v-model.number="tenant['coaching'+ metric.name + 'Threshold']" 
                                    @change="updateTenant(false, metric.name + 'Threshold')" 
                                    :class="{'invalid-threshold': tenant['coaching'+ metric.name + 'Threshold'] > tenant['coaching'+ metric.name + 'ThresholdPR'] && metric.polarity}">
                                </el-input-number>
                                <div class="w-16" v-else></div>
                                <el-tooltip v-if="tenant['coaching'+ metric.name + 'Threshold'] > tenant['coaching'+ metric.name + 'ThresholdPR'] && metric.polarity" class="item -mr-6" effect="dark" :placement="isDeviceMobileOnly() ? 'bottom' : 'top'" popper-class="custom-tooltip-lowest-z-index">
                                    <div slot="content" class="w-52">
                                        Coaching Oppurtunity threshold should not be {{ metric.polarity > 0 ? 'greater' : 'less'}}  than the Positive Reinforcement threshold
                                    </div>
                                    <i class="uil uil-info-circle text-base text-red-500 rotate-180"></i>
                                </el-tooltip>
                            </div>
                            <!-- PR -->
                            <div class="text-center inline-flex pl-2 pr-6 py-2">
                                <p class="w-12 text-center"><el-switch data-cy="sc-cx-pr-sw" v-if="!metric.negativeToggleOnly" v-model="tenant['coaching'+ metric.name + 'PR']" @change="updateTenant(false, metric.name + 'PR')"></el-switch></p>
                                <p class="font-bold w-12 text-right text-gray-800 text-xxxs mr-1">{{metric.negativeToggleOnly ? '' : metric.polarity > 0 ? 'More than or equal to' : 'Less than or equal to'}}</p>   
                                <el-input-number 
                                    data-cy="sc-cx-pr-in" 
                                    v-if="!metric.negativeToggleOnly" 
                                    class="coachingThresholdInput" 
                                    :controls="false" 
                                    size="mini" 
                                    :max="!metric?.thresholdPRMax ? scorecardAndCxMax.thresholdPRMax : metric.thresholdPRMax"
                                    :min="0" 
                                    v-model.number="tenant['coaching'+ metric.name + 'ThresholdPR']" @change="updateTenant(false, metric.name + 'ThresholdPR')" >
                                </el-input-number>
                                <div class="w-16" v-else></div>
                            </div>        
                            <!-- KUDO -->
                            <div class="text-center inline-flex pr-8 py-2">
                                <p class="w-12 text-center"><el-switch data-cy="sc-cx-kudo-sw" v-if="!metric.negativeToggleOnly" v-model="tenant['coaching'+ metric.name + 'Kudo']" @change="updateTenant(false, metric.name + 'Kudo')"></el-switch></p>
                                <p class="font-bold w-12 text-right text-gray-800 text-xxxs mr-1">{{metric.negativeToggleOnly ? '' : metric.polarity > 0 ? 'More than or equal to' : 'Less than or equal to'}}</p>   
                                <el-input-number 
                                    data-cy="sc-cx-kudo-in" 
                                    v-if="!metric.negativeToggleOnly" 
                                    class="coachingThresholdInput" 
                                    :controls="false" 
                                    size="mini" 
                                    :max="!metric?.thresholdKudoMax ? scorecardAndCxMax.thresholdKudoMax : metric.thresholdKudoMax" 
                                    :min="0" 
                                    v-model.number="tenant['coaching'+ metric.name + 'ThresholdKudo']" 
                                    @change="updateTenant(false, metric.name + 'ThresholdKudo')">
                                </el-input-number>
                                <div class="w-16" v-else></div>
                            </div>   
                        </div>
                        
                        <div>
                            <div class="pl-2 pr-8 inline-block w-1/2">
                                <el-slider
                                    data-cy="sc-cx-issue-slider"
                                    v-if="filteredIssueTypes[metric.sliderField]"
                                    v-model="filteredIssueTypes[metric.sliderField].driverReportSetting"
                                    @change="saveSlider(filteredIssueTypes[metric.sliderField], 'weeklyPerformanceData', 'filteredIssueTypes', metric.sliderField)"
                                    input-size="mini"
                                    :disabled="!tenant['coaching'+ metric.name + 'Issue']"
                                    class="performanceSlider issueSlider"
                                    :show-input-controls="false"
                                    tooltip-class="custom-tooltip-lowest-z-index"
                                    show-input></el-slider>
                                <div  
                                    v-if="filteredIssueTypes[metric.sliderField]" 
                                    :class="{'text-gray-500': !tenant['coaching'+ metric.name + 'Issue']}"
                                    class="-mt-2 mb-2 pr-16 w-full text-center text-xxs font-bold">
                                        {{sliderLabel(filteredIssueTypes[metric.sliderField].driverReportSetting)}}
                                </div>
                            </div>
                            <div class="pl-2 pr-8 inline-block w-1/2">
                                <el-slider
                                    data-cy="sc-cx-kudo-slider"
                                    v-if="filteredKudoTypes[metric.sliderField] && !metric.negativeToggleOnly"
                                    v-model="filteredKudoTypes[metric.sliderField].driverReportSetting"
                                    @change="saveSlider(filteredKudoTypes[metric.sliderField], 'weeklyPerformanceData', 'filteredKudoTypes', metric.sliderField)"
                                    input-size="mini"
                                    :disabled="!tenant['coaching'+ metric.name + 'Kudo']"
                                    class="performanceSlider kudoSlider"
                                    :show-input-controls="false"
                                    tooltip-class="custom-tooltip-lowest-z-index"
                                    show-input></el-slider>
                                <div 
                                    v-if="filteredKudoTypes[metric.sliderField] && !metric.negativeToggleOnly" 
                                    :class="{'text-gray-500': !tenant['coaching'+ metric.name + 'Kudo']}"
                                    class="-mt-2 mb-2 pr-16 w-full text-center text-xxs font-bold">
                                        {{sliderLabel(filteredKudoTypes[metric.sliderField].driverReportSetting)}}
                                </div>
                            </div> 
                        </div>
                    </div>
                </div>

                <!-- DA Tier -->
                <div class="flex rounded-lg bg-gray-100 border my-5" v-show="( metric.name === 'DaTier' ) && filteredIssueTypes[metric.sliderField]">
                    <div class="w-54 py-2 flex-none pl-2">
                        <div class="font-bold">
                            {{metric.label}}
                            <el-tooltip v-if="metric.tooltip" class="item" effect="dark" :placement="isDeviceMobileOnly() ? 'bottom' : 'top'" popper-class="custom-tooltip-lowest-z-index">
                                <div slot="content">
                                    {{metric.tooltip}}
                                </div>
                                <i class="uil uil-question-circle text-base"></i>
                            </el-tooltip>
                        </div>
                        <div class="mt-5">
                            {{$t('label.associate')}} Performance Report Setting
                        </div>
                    </div>
                    <div>
                        <div>
                            <!-- ISSUE -->
                            <div class="w-98 text-center inline-flex pl-4 pr-4 py-2">
                                <p class="w-12 text-center">
                                    <el-switch v-model="tenant['coaching'+ metric.name + 'Issue']" @change="updateTenant(false, metric.name + 'Issue')"></el-switch>
                                </p>
                                <div class="flex mb-2">
                                    <el-select data-cy="da-issues-render-sel" v-model="tenant['coaching' + metric.name + 'RatingIssue']" placeholder="Select" size="mini" class="w-24 ml-1" @change="updateTenant(false, metric.name + 'RatingIssue')">
                                        <el-option v-for="item in valueListActivesOnly($store.state.valuelists['scorecard-tiers'].items)" :key="item.value" :label="item.value" :value="item.value">
                                        </el-option>
                                    </el-select>
                                    <p class="w-12 my-auto text-left text-gray-800 text-xxxs ml-1 font-bold">Or worse</p>
                                </div>
                                <p class="w-12 text-center">
                                    <el-switch v-model="tenant['coaching'+ metric.name + 'CO']" @change="updateTenant(false, metric.name + 'CO')"></el-switch>
                                </p>
                                <div class="flex mb-2">
                                    <el-select data-cy="da-issues-render-sel" v-model="tenant['coaching' + metric.name + 'RatingCO']" placeholder="Select" size="mini" class="w-24 ml-1" @change="updateTenant(false, metric.name + 'RatingCO')">
                                        <el-option v-for="item in valueListActivesOnly($store.state.valuelists['scorecard-tiers'].items)" :key="item.value" :label="item.value" :value="item.value">
                                        </el-option>
                                    </el-select>
                                    <p class="w-12 my-auto text-left text-gray-800 text-xxxs ml-1 font-bold">Or worse</p>
                                </div>
                            </div>
                            <!-- KUDO -->
                            <div class="w-98 text-center inline-flex justify-center pl-4 pr-4 py-2">
                                <p class="w-12 text-center">
                                    <el-switch v-model="tenant['coaching'+ metric.name + 'PR']" @change="updateTenant(false, metric.name + 'PR')"></el-switch>
                                </p>
                                <div class="flex mb-2">
                                    <el-select data-cy="da-kudo-render-sel" v-model="tenant['coaching' + metric.name + 'RatingKudo']" placeholder="Select" size="mini" class="w-24 ml-1" @change="updateTenant(false, metric.name + 'RatingKudo')">
                                        <el-option v-for="item in valueListActivesOnly($store.state.valuelists['scorecard-tiers'].items)" :key="item.value" :label="item.value" :value="item.value">
                                        </el-option>
                                    </el-select>
                                    <p class="w-12 my-auto text-left text-gray-800 text-xxxs ml-1 font-bold">Or better</p>
                                </div>
                                <p class="w-12 text-center">
                                    <el-switch v-model="tenant['coaching'+ metric.name + 'Kudo']" @change="updateTenant(false, metric.name + 'Kudo')"></el-switch>
                                </p>
                                <div class="flex mb-2">
                                    <el-select data-cy="da-kudo-render-sel" v-model="tenant['coaching' + metric.name + 'RatingPR']" placeholder="Select" size="mini" class="w-24 ml-1" @change="updateTenant(false, metric.name + 'RatingPR')">
                                        <el-option v-for="item in valueListActivesOnly($store.state.valuelists['scorecard-tiers'].items)" :key="item.value" :label="item.value" :value="item.value">
                                        </el-option>
                                    </el-select>
                                    <p class="w-12 my-auto text-left text-gray-800 text-xxxs ml-1 font-bold">Or better</p>
                                </div>
                            </div>
                        </div>
                        <div>
                            <div class="pl-5 pr-4 inline-block w-1/2">
                                <el-slider data-cy="da-issue-type-render-slider" v-if="filteredIssueTypes[metric.sliderField]" v-model="filteredIssueTypes[metric.sliderField].driverReportSetting" @change="saveSlider(filteredIssueTypes[metric.sliderField], 'weeklyPerformanceData', 'filteredIssueTypes', metric.sliderField)" input-size="mini" :disabled="!tenant['coaching' + metric.name + 'Issue']" class="performanceSlider issueSlider" :show-input-controls="false" show-input tooltip-class="custom-tooltip-lowest-z-index"></el-slider>
                                <div v-if="filteredIssueTypes[metric.sliderField]" :class="{'text-gray-500': !tenant['coaching' + metric.name + 'Issue']}" class="-mt-2 mb-2 pr-16 w-full text-center text-xxs font-bold">
                                    {{sliderLabel(filteredIssueTypes[metric.sliderField].driverReportSetting)}}
                                </div>
                            </div>
                            <div class="pl-5 pr-4 inline-block w-1/2">
                                <el-slider data-cy="da-kudo-type-render-slider" v-if="filteredKudoTypes[metric.sliderField]" v-model="filteredKudoTypes[metric.sliderField].driverReportSetting" @change="saveSlider(filteredKudoTypes[metric.sliderField], 'weeklyPerformanceData', 'filteredKudoTypes', metric.sliderField)" input-size="mini" :disabled="!tenant['coaching' + metric.name + 'Kudo']" class="performanceSlider kudoSlider" :show-input-controls="false" show-input tooltip-class="custom-tooltip-lowest-z-index"></el-slider>
                                <div v-if="filteredKudoTypes[metric.sliderField]" :class="{'text-gray-500': !tenant['coaching' + metric.name + 'Kudo']}" class="-mt-2 mb-2 pr-16 w-full text-center text-xxs font-bold">
                                    {{sliderLabel(filteredKudoTypes[metric.sliderField].driverReportSetting)}}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <!-- CDF Score -->
                <div class="flex rounded-lg bg-gray-100 border border-gray-400 my-5" v-show="( metric.name == 'CdfScore' ) && filteredIssueTypes[metric.sliderField]">
                    <div class="w-54 py-2 flex-none pl-2">
                        <div class="font-bold" :class="{'-mb-1': metric.tooltip}">
                            {{metric.label}}
                            <el-tooltip v-if="metric.tooltip" class="item" effect="dark" :placement="isDeviceMobileOnly() ? 'bottom' : 'top'" popper-class="custom-tooltip-lowest-z-index">
                                <div slot="content">
                                    {{metric.tooltip}}
                                </div>
                                <i class="uil uil-question-circle text-base"></i>
                            </el-tooltip>
                        </div>
                        <div class="mt-4">
                            {{$t('label.associate')}} Performance Report Setting
                        </div>
                    </div>
                    <div>
                        <div class="flex">
                            <!-- ISSUE -->
                            <div class="text-center inline-flex pl-2 pr-6 py-2">
                                <p class="w-12 text-center">
                                    <el-switch data-cy="toggle-issue-sw" v-model="tenant['coaching'+ metric.name + 'Issue']" @change="updateTenant(false, metric.name + 'Issue')"></el-switch>
                                </p>
                                <p class="font-bold w-12 my-auto text-right text-gray-800 text-xxxs mr-1">{{metric.negativeToggleOnly ? '' : metric.polarity > 0 ? 'Less than' : 'More than'}}</p>
                                <el-input-number 
                                    data-cy="toggle-issue-in" 
                                    v-if="!metric.negativeToggleOnly" 
                                    class="coachingThresholdInput" 
                                    :controls="false" 
                                    size="mini" 
                                    :max="!metric?.thresholdIssueMax ? scorecardAndCxMax.thresholdIssueMax : metric.thresholdIssueMax" 
                                    :min="0" 
                                    v-model.number="tenant['coaching'+ metric.name + 'ThresholdIssue']" 
                                    @change="updateTenant(false, metric.name + 'ThresholdIssue')" 
                                    :class="{'invalid-threshold': tenant['coaching'+ metric.name + 'ThresholdIssue'] * metric.polarity > tenant['coaching'+ metric.name + 'ThresholdPR'] * metric.polarity}">
                                </el-input-number>
                                <div class="w-16" v-else></div>
                                <el-tooltip v-if="tenant['coaching'+ metric.name + 'ThresholdIssue'] * metric.polarity > tenant['coaching'+ metric.name + 'ThresholdPR'] * metric.polarity" class="item -mr-6" effect="dark" :placement="isDeviceMobileOnly() ? 'bottom' : 'top'" popper-class="custom-tooltip-lowest-z-index">
                                    <div slot="content" class="w-52">
                                        {{$t('label.associate')}} Issue threshold should not be {{ metric.polarity > 0 ? 'greater' : 'less'}} than the Positive Reinforcement threshold
                                    </div>
                                    <i class="uil uil-info-circle text-base text-red-500 rotate-180"></i>
                                </el-tooltip>
                            </div>
                            <!-- CO -->
                            <div class="text-center inline-flex pr-8 py-2">
                                <p class="w-12 text-center">
                                <el-switch data-cy="toggle-co-sw" v-model="tenant['coaching'+ metric.name + 'CO']" @change="updateTenant(false, metric.name + 'CO')"></el-switch>
                                </p>
                                <p class="font-bold w-12 my-auto text-right text-gray-800 text-xxxs mr-1">{{metric.negativeToggleOnly ? '' : metric.polarity > 0 ? 'Less than' : 'More than'}}</p>
                                <el-input-number 
                                    data-cy="toggle-co-in" 
                                    v-if="!metric.negativeToggleOnly" 
                                    class="coachingThresholdInput" 
                                    :controls="false" 
                                    size="mini" 
                                    :max="!metric?.thresholdMax ? scorecardAndCxMax.thresholdMax : metric.thresholdMax" 
                                    :min="0" 
                                    v-model.number="tenant['coaching'+ metric.name + 'Threshold']" 
                                    @change="updateTenant(false, metric.name + 'Threshold')" 
                                    :class="{'invalid-threshold': tenant['coaching'+ metric.name + 'Threshold'] > tenant['coaching'+ metric.name + 'ThresholdPR'] && metric.polarity}">
                                </el-input-number>
                                <div class="w-16" v-else></div>
                                <el-tooltip v-if="tenant['coaching'+ metric.name + 'Threshold'] > tenant['coaching'+ metric.name + 'ThresholdPR'] && metric.polarity" class="item -mr-6" effect="dark" :placement="isDeviceMobileOnly() ? 'bottom' : 'top'" popper-class="custom-tooltip-lowest-z-index">
                                    <div slot="content" class="w-52">
                                        Coaching Oppurtunity threshold should not be {{ metric.polarity > 0 ? 'greater' : 'less'}} than the Positive Reinforcement threshold
                                    </div>
                                    <i class="uil uil-info-circle text-base text-red-500 rotate-180"></i>
                                </el-tooltip>
                            </div>
                            <!-- PR -->
                            <div class="text-center inline-flex pl-2 pr-6 py-2">
                                <p class="w-12 text-center">
                                    <el-switch data-cy="df-pr-swch" v-if="!metric.negativeToggleOnly" v-model="tenant['coaching'+ metric.name + 'PR']" @change="updateTenant(false, metric.name + 'PR')"></el-switch>
                                </p>
                                <p class="font-bold w-12 text-right text-gray-800 text-xxxs mr-1">{{metric.negativeToggleOnly ? '' : metric.polarity > 0 ? 'More than or equal to' : 'Less than or equal to'}}</p>
                                <el-input-number 
                                    data-cy="df-pr-in" 
                                    v-if="!metric.negativeToggleOnly" 
                                    class="coachingThresholdInput" 
                                    :controls="false" 
                                    size="mini" 
                                    :max="!metric?.thresholdPRMax ? scorecardAndCxMax.thresholdPRMax : metric.thresholdPRMax"
                                    :min="0" 
                                    v-model.number="tenant['coaching'+ metric.name + 'ThresholdPR']" 
                                    @change="updateTenant(false, metric.name + 'ThresholdPR')">
                                </el-input-number>
                                <div class="w-16" v-else></div>
                            </div>
                            <!-- KUDO -->
                            <div class="text-center inline-flex pr-8 py-2">
                                <p class="w-12 text-center">
                                    <el-switch data-cy="df-kudo-swch" v-if="!metric.negativeToggleOnly" v-model="tenant['coaching'+ metric.name + 'Kudo']" @change="updateTenant(false, metric.name + 'Kudo')"></el-switch>
                                </p>
                                <p class="font-bold w-12 text-right text-gray-800 text-xxxs mr-1">{{metric.negativeToggleOnly ? '' : metric.polarity > 0 ? 'More than or equal to' : 'Less than or equal to'}}</p>
                                <el-input-number 
                                    data-cy="df-kudo-in" 
                                    v-if="!metric.negativeToggleOnly" 
                                    class="coachingThresholdInput" 
                                    :controls="false" 
                                    size="mini" 
                                    :max="!metric?.thresholdKudoMax ? scorecardAndCxMax.thresholdKudoMax : metric.thresholdKudoMax" 
                                    :min="0" 
                                    v-model.number="tenant['coaching'+ metric.name + 'ThresholdKudo']" 
                                    @change="updateTenant(false, metric.name + 'ThresholdKudo')">
                                </el-input-number>
                                <div class="w-16" v-else></div>
                            </div>
                        </div>

                        <div>
                            <div class="pl-2 pr-8 inline-block w-1/2">
                                <el-slider data-cy="df-issues-slider" v-if="filteredIssueTypes[metric.sliderField]" v-model="filteredIssueTypes[metric.sliderField].driverReportSetting" @change="saveSlider(filteredIssueTypes[metric.sliderField], 'weeklyPerformanceData', 'filteredIssueTypes', metric.sliderField)" input-size="mini" :disabled="!tenant['coaching'+ metric.name + 'Issue']" class="performanceSlider issueSlider" :show-input-controls="false" show-input tooltip-class="custom-tooltip-lowest-z-index"></el-slider>
                                <div v-if="filteredIssueTypes[metric.sliderField]" :class="{'text-gray-500': !tenant['coaching'+ metric.name + 'Issue']}" class="-mt-2 mb-2 pr-16 w-full text-center text-xxs font-bold">
                                    {{sliderLabel(filteredIssueTypes[metric.sliderField].driverReportSetting)}}
                                </div>
                            </div>
                                <div class="pl-2 pr-8 inline-block w-1/2">
                                    <el-slider data-cy="df-kudo-slider" v-if="filteredKudoTypes[metric.sliderField] && !metric.negativeToggleOnly" v-model="filteredKudoTypes[metric.sliderField].driverReportSetting" @change="saveSlider(filteredKudoTypes[metric.sliderField], 'weeklyPerformanceData', 'filteredKudoTypes', metric.sliderField)" input-size="mini" :disabled="!tenant['coaching'+ metric.name + 'Kudo']" class="performanceSlider kudoSlider" :show-input-controls="false" show-input tooltip-class="custom-tooltip-lowest-z-index"></el-slider>
                                    <div v-if="filteredKudoTypes[metric.sliderField] && !metric.negativeToggleOnly" :class="{'text-gray-500': !tenant['coaching'+ metric.name + 'Kudo']}" class="-mt-2 mb-2 pr-16 w-full text-center text-xxs font-bold">
                                        {{sliderLabel(filteredKudoTypes[metric.sliderField].driverReportSetting)}}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </el-collapse-item>


        <!-- DAILY PERFORMANCE DATA -->
        <el-collapse-item title="Daily Performance Data" name="dailyPerformanceData" class="relative overflow-hidden" v-loading="loading.dailyPerformanceData">
            <section v-if="showDaily" class="overflow-x-auto">
                <div class="flex mt-2">
                    <div class="w-54 font-bold flex-none"></div>
                </div>
                <div class="flex -mb-11 font-bold">
                    <div class="w-54 flex-none"></div>
                    <div class="flex-col">
                        <div class="header-title">
                            <div class="w-96 border-red-500 border-t-2 rounded-lg text-center text-red-500 ">
                                <span class="px-4 -top-4 text-red-500 text-sm relative bg-white font-bold">Improve Poor Performance</span>
                            </div>
                        </div>
                        <div class="flex">
                            <div class="w-49 pl-2">
                                Create {{$t('label.associate')}} Issues
                                <el-tooltip class="item" effect="dark" :placement="isDeviceMobileOnly() ? 'bottom' : 'right-start'" popper-class="custom-tooltip-lowest-z-index">
                                    <div slot="content" class="w-80">
                                        <div class="mb-4">{{$t('label.associate')}} Issues can be used to track the performance of your {{$t('label.associates')}}. Based on your settings below, they influence each {{$t('label.associate_s')}} Hera Score and Hera Rank, which show in your {{$t('label.associate')}} Performance report.</div>
                                        <div class="mb-4">{{$t('label.associate')}} Issues can also optionally be added to Counselings, which are more formal write-ups, to hold the {{$t('label.associate')}} accountable, and that can be shared with your {{$t('label.associates')}} for their signature.</div>
                                        <div class="mb-4">{{$t('label.associate')}} Issues can be created manually at any time, but the settings below also allow you to create them automatically whenever you import your performance data. </div>
                                        <div>Unless you attach them to Counselings, {{$t('label.associate')}} Issues do not get sent out to {{$t('label.associates')}} and cannot be seen by anyone without a Hera login.</div>
                                    </div>
                                    <i class="uil uil-question-circle text-base"></i>
                                </el-tooltip>
                            </div>
                            <div class="w-49 pl-2">
                                Coaching Opportunities
                                <el-tooltip class="item" effect="dark" :placement="isDeviceMobileOnly() ? 'bottom' : 'right-start'" popper-class="custom-tooltip-lowest-z-index">
                                    <div slot="content" class="w-80">
                                        <div class="mb-4">Hera can automate your {{$t('label.associate')}} coaching by sending text messages and/or emails whenever a coaching opportunity is identified. Based on the settings below, Hera will create these messages automatically when you import your performance data.</div>
                                        <div>Thresholds allow you to customize exactly when these coaching opportunities get created. Messages get sent daily at the time specified by you at the top of this page.</div>
                                    </div>
                                    <i class="uil uil-question-circle text-base"></i>
                                </el-tooltip>
                            </div>
                        </div>
                    </div>
                    <div class="flex-col">
                        <div class="header-title">
                            <div class="w-90 border-great border-t-2 rounded-lg text-center text-great ml-2">
                                <span class="px-4 -top-4 text-great text-sm relative bg-white font-bold">Strengthen Great Performance</span>
                            </div>
                        </div>
                        <div class="flex">
                            <div class="w-49 pl-4">
                                Positive Reinforcement
                                <el-tooltip class="item" effect="dark" :placement="isDeviceMobileOnly() ? 'bottom' : 'right-start'" popper-class="custom-tooltip-lowest-z-index">
                                    <div slot="content" class="w-80">
                                        <div class="mb-4">Similar to Coaching Opportunities, Hera will use your settings below to automatically create messages for your {{$t('label.associates')}}, congratulating them for a job well done. Coupled with Coaching Opportunities, these messages are key to motivating your staff to achieve high ratings.</div>
                                        <div>Thresholds allow you to customize exactly when these messages get created. Messages get sent daily at the time specified by you at the top of this page.</div>
                                    </div>
                                    <i class="uil uil-question-circle text-base"></i>
                                </el-tooltip>
                            </div>
                            <div class="w-49 pl-4">
                                Create {{$t('label.associate')}} Kudos
                                <el-tooltip class="item" effect="dark" :placement="isDeviceMobileOnly() ? 'bottom' : 'right-start'" popper-class="custom-tooltip-lowest-z-index">
                                    <div slot="content" class="w-80">
                                    <div class="mb-4">You can optionally use {{$t('label.associate')}} Kudos to influence each {{$t('label.associate_s')}} Hera Score and Hera Rank in positive ways. This allows you to see your top performers at your DSP and celebrate them accordingly.</div>
                                    <div>{{$t('label.associate')}} Kudos do not get sent out to {{$t('label.associates')}} and can only be seen by users who log into Hera.</div>
                                    </div>
                                    <i class="uil uil-question-circle text-base"></i>
                                </el-tooltip>
                            </div>
                        </div>
                    </div>
                </div>
                

                <!-- DAILY METRICS -->
                <div v-for="metric in dailyPerformanceMetrics" :key="metric.name" class="fit-content-metrices">
                    <div v-if="metric.header" class="font-bold text-base pt-4 pb-1 flex">
                        <div class="w-54">
                            {{metric.header}}
                            <el-dropdown data-cy="df-dm-opt-sel" @command="handleDropdownCommand" class="ml-2">
                                <i class="context-menu uil uil-ellipsis-h"></i>
                                <el-dropdown-menu slot="dropdown">
                                    <el-dropdown-item :command="'enable-' + metric.header">Enable All</el-dropdown-item>
                                    <el-dropdown-item :command="'disable-' + metric.header">Disable All</el-dropdown-item>
                                    <el-dropdown-item :command="'default-' + metric.header">Reset Defaults</el-dropdown-item>
                                </el-dropdown-menu>
                            </el-dropdown>
                        </div>
                    </div>
                    <div v-show="noMentorResults && metric.src == 'Mentor' && metric.header" class="text-center text-base mt-2 text-gray-600">
                        <i class="uil uil-search"></i>
                        <span>No matching Mentor metrics. <span class="link" @click="search = ''">Show All</span></span>
                    </div>
                    <div v-show="noNetradyneResults && metric.src == 'Netradyne' && metric.header" class="text-center text-base mt-2 text-gray-600">
                        <i class="uil uil-search"></i>
                        <span>No matching Netradyne metrics. <span class="link" @click="search = ''">Show All</span></span>
                    </div>
                    <div v-show="noEOCResults && metric.src == 'EOC' && metric.header" class="text-center text-base mt-2 text-gray-600">
                        <i class="uil uil-search"></i>
                        <span>No matching EOC metrics. <span class="link" @click="search = ''">Show All</span></span>
                    </div>

                    <!-- MENTOR, NETRADYNE AND EOC METRICS -->
                    <div class="flex rounded-lg bg-gray-100 border my-5" v-show="filteredIssueTypes[metric.sliderField] || filteredKudoTypes[metric.sliderField]">
                    <div class="w-54 py-2 flex-none pl-2">
                            <div class="font-bold" :class="{'-mb-1': metric.tooltip}">
                                {{metric.label}}
                                <el-tooltip v-if="metric.tooltip" class="item" effect="dark" :placement="isDeviceMobileOnly() ? 'bottom' : 'top'" popper-class="custom-tooltip-lowest-z-index">
                                    <div slot="content">
                                        {{metric.tooltip}}
                                    </div>
                                    <i class="uil uil-question-circle text-base"></i>
                                </el-tooltip>
                            </div>
                            <div class="mt-4">
                                {{$t('label.associate')}} Performance Report Setting
                            </div>
                    </div>
                    <div>
                            <div  class="flex">
                                <!-- ISSUE -->
                                <div class="text-center inline-flex pl-2 pr-6 py-2">
                                    <p class="w-12 text-center"><el-switch data-cy="df-mnm-issue-sw" v-if="metric.name != 'DriverStar'" v-model="tenant['coaching'+ metric.name + 'Issue']" @change="updateTenant(false, metric.name + 'Issue')"></el-switch></p>
                                    <p class="font-bold w-12 my-auto text-right text-gray-800 text-xxxs mr-1">{{(metric.negativeToggleOnly || metric.name === 'DriverStar') && metric.src != 'Netradyne' || metric.positiveToggleOnly ? '' : metric.polarity > 0 ? 'Less than' : 'More than'}}</p>
                                    <el-input-number data-cy="df-mnm-issue-in" v-if="(!metric.negativeToggleOnly && !metric.positiveToggleOnly) || (metric.src === 'Netradyne' && metric.name != 'DriverStar')" class="coachingThresholdInput" :controls="false" size="mini" :max="850" :min="0" v-model.number="tenant['coaching'+ metric.name + 'ThresholdIssue']" @change="updateTenant(false, metric.name + 'ThresholdIssue')"></el-input-number>
                                    <div class="w-16" v-else></div>
                                    <el-tooltip v-if="tenant['coaching'+ metric.name + 'ThresholdIssue'] * metric.polarity > tenant['coaching'+ metric.name + 'ThresholdPR'] * metric.polarity" class="item -mr-6" effect="dark" :placement="isDeviceMobileOnly() ? 'bottom' : 'top-start'" popper-class="custom-tooltip-lowest-z-index">
                                        <div slot="content" class="w-52">
                                            {{$t('label.associate')}} Issue threshold should not be {{ metric.polarity > 0 ? 'greater' : 'less'}} than the Positive Reinforcement threshold
                                        </div>
                                        <i class="uil uil-info-circle text-base text-red-500 rotate-180"></i>
                                    </el-tooltip>
                                </div>
                                <!-- CO -->
                                <div class="text-center inline-flex pr-8 py-2">
                                    <p class="w-12 text-center"><el-switch data-cy="df-mnm-co-sw" v-if="metric.name != 'DriverStar'" v-model="tenant['coaching'+ metric.name + 'CO']" @change="updateTenant(false, metric.name + 'CO')"></el-switch></p>
                                    <p class="font-bold w-12 my-auto text-right text-gray-800 text-xxxs mr-1">{{(metric.negativeToggleOnly || metric.name === 'DriverStar') && metric.src != 'Netradyne' || metric.positiveToggleOnly ? '' : metric.polarity > 0 ? 'Less than' : 'More than'}}</p>
                                    <el-input-number data-cy="df-mnm-co-in" v-if="(!metric.negativeToggleOnly && !metric.positiveToggleOnly) || (metric.src === 'Netradyne' && metric.name != 'DriverStar')" class="coachingThresholdInput" :controls="false" size="mini" :max="850" :min="0" v-model.number="tenant['coaching'+ metric.name + 'Threshold']" @change="updateTenant(false, metric.name + 'Threshold')"></el-input-number>
                                    <div class="w-16" v-else></div>
                                    <div v-if="mentorMetrics.includes(metric.name)">
                                        <el-tooltip v-if="tenant['coaching'+ metric.name + 'Threshold'] < tenant['coaching'+ metric.name + 'ThresholdPR'] && metric.polarity" class="item -mr-6" effect="dark" :placement="isDeviceMobileOnly() ? 'bottom' : 'top'" popper-class="custom-tooltip-lowest-z-index">
                                            <div slot="content" class="w-52">
                                                Coaching Oppurtunity threshold should not be {{ metric.polarity > 0 ? 'greater' : 'less'}}  than the Positive Reinforcement threshold
                                            </div>
                                            <i class="uil uil-info-circle text-base text-red-500 rotate-180"></i>
                                        </el-tooltip>
                                    </div>
                                    <div v-else>
                                        <el-tooltip v-if="tenant['coaching'+ metric.name + 'Threshold'] > tenant['coaching'+ metric.name + 'ThresholdPR'] && metric.polarity" class="item -mr-6" effect="dark" :placement="isDeviceMobileOnly() ? 'bottom' : 'top'" popper-class="custom-tooltip-lowest-z-index">
                                            <div slot="content" class="w-52">
                                                Coaching Oppurtunity threshold should not be {{ metric.polarity > 0 ? 'greater' : 'less'}}  than the Positive Reinforcement threshold
                                            </div>
                                            <i class="uil uil-info-circle text-base text-red-500 rotate-180"></i>
                                        </el-tooltip>
                                    </div>
                                </div>
                                <!-- PR -->
                                <div class="text-center inline-flex pl-2 pr-6 py-2">
                                    <p class="w-12 text-center"><el-switch data-cy="df-mnm-pr-sw" v-if="!metric.negativeToggleOnly" v-model="tenant['coaching'+ metric.name + 'PR']" @change="updateTenant(false, metric.name + 'PR')"></el-switch></p>
                                    <p class="font-bold w-12 text-right text-gray-800 text-xxxs mr-1">{{metric.negativeToggleOnly ? '' : metric.polarity > 0 ? 'More than or equal to' : 'Less than or equal to'}}</p>   
                                    <el-input-number data-cy="df-mnm-pr-in" v-if="!metric.negativeToggleOnly" class="coachingThresholdInput" :controls="false" size="mini" :max="850" :min="0" v-model.number="tenant['coaching'+ metric.name + 'ThresholdPR']" @change="updateTenant(false, metric.name + 'ThresholdPR')"></el-input-number>
                                    <div class="w-16" v-else></div>
                                </div>        
                                <!-- KUDO -->
                                <div class="text-center inline-flex pr-8 py-2">
                                    <p class="w-12 text-center"><el-switch data-cy="df-mnm-kudo-sw" v-if="!metric.negativeToggleOnly" v-model="tenant['coaching'+ metric.name + 'Kudo']" @change="updateTenant(false, metric.name + 'Kudo')"></el-switch></p>
                                    <p class="font-bold w-12 text-right text-gray-800 text-xxxs mr-1">{{metric.negativeToggleOnly ? '' : metric.polarity > 0 ? 'More than or equal to' : 'Less than or equal to'}}</p>   
                                    <el-input-number data-cy="df-mnm-kudo-in" v-if="!metric.negativeToggleOnly" class="coachingThresholdInput" :controls="false" size="mini" :max="850" :min="0" v-model.number="tenant['coaching'+ metric.name + 'ThresholdKudo']" @change="updateTenant(false, metric.name + 'ThresholdKudo')"></el-input-number>
                                    <div class="w-16" v-else></div>
                                </div>        
                            </div>
                            <div>
                                <div class="pl-2 pr-8 inline-block w-1/2">
                                    <el-slider
                                        data-cy="df-mnm-issue-slider"
                                        v-if="filteredIssueTypes[metric.sliderField]"
                                        v-model="filteredIssueTypes[metric.sliderField].driverReportSetting"
                                        @change="saveSlider(filteredIssueTypes[metric.sliderField], 'dailyPerformanceData', 'filteredIssueTypes', metric.sliderField)"
                                        input-size="mini"
                                        :disabled="!tenant['coaching'+ metric.name + 'Issue']"
                                        class="performanceSlider issueSlider"
                                        :show-input-controls="false"
                                        tooltip-class="custom-tooltip-lowest-z-index"
                                        show-input></el-slider>
                                    <div  
                                        v-if="filteredIssueTypes[metric.sliderField]" 
                                        :class="{'text-gray-500': !tenant['coaching'+ metric.name + 'Issue']}"
                                        class="-mt-2 mb-2 pr-16 w-full text-center text-xxs font-bold">
                                            {{sliderLabel(filteredIssueTypes[metric.sliderField].driverReportSetting)}}
                                    </div>
                                </div>
                                <div class="pl-2 pr-8 inline-block w-1/2">
                                    <el-slider
                                        data-cy="df-mnm-kudo-slider"
                                        v-if="filteredKudoTypes[metric.sliderField] && !metric.negativeToggleOnly"
                                        v-model="filteredKudoTypes[metric.sliderField].driverReportSetting"
                                        @change="saveSlider(filteredKudoTypes[metric.sliderField], 'dailyPerformanceData', 'filteredKudoTypes', metric.sliderField)"
                                        input-size="mini"
                                        :disabled="!tenant['coaching'+ metric.name + 'Kudo']"
                                        class="performanceSlider kudoSlider"
                                        :show-input-controls="false"
                                        tooltip-class="custom-tooltip-lowest-z-index"
                                        show-input></el-slider>
                                    <div 
                                        v-if="filteredKudoTypes[metric.sliderField] && !metric.negativeToggleOnly" 
                                        :class="{'text-gray-500': !tenant['coaching'+ metric.name + 'Kudo']}"
                                        class="-mt-2 mb-2 pr-16 w-full text-center text-xxs font-bold">
                                            {{sliderLabel(filteredKudoTypes[metric.sliderField].driverReportSetting)}}
                                    </div>
                                </div> 
                            </div>

                        </div>
                    </div>
                    
                </div>
            </section>
        </el-collapse-item>

        <!-- OTHER ASSOCIATE ISSUES AND KUDOS -->
        <el-collapse-item name="custom" class="relative overflow-hidden" v-loading="loading.custom">
            <template slot="title">
                <p class="title-collapse">OTHER {{$t('label.ASSOCIATE')}} ISSUES AND KUDOS</p>
            </template>
            <section v-if="showCustom" class="overflow-x-auto">

                <div class="fit-content-metrices" >
                    <div class="text-sm">
                        Custom {{$t('label.associate')}} Issues and Kudos can be added or removed on the <router-link :to="{name: 'SettingsCustomLists'}" class="link">Custom Lists settings page.</router-link>
                    </div>
                    <div class="flex justify-start mt-4">
                        <div class="font-bold text-lg">{{$t('label.associate')}} Issues</div>
                    </div>
                    <div v-for="issueType of customFilteredIssueTypes" :key="issueType.id">
                        <div class="py-1 px-2 flex space-x-4 justify-start rounded-lg bg-gray-100 border border-gray-400 my-5">
                            <div class="flex-initial max-w-56 w-full font-bold my-auto">{{issueType.option}}</div>
                            <div class="flex-auto ">
                                <el-slider
                                    data-cy="df-custom-issue-slider"
                                    class="performanceSlider issueSlider"
                                    v-model="issueType.driverReportSetting"
                                    @change="saveSlider(issueType, 'custom', 'customFilteredIssueTypes', issueType.option)"
                                    :show-input-controls="false"
                                    tooltip-class="custom-tooltip-lowest-z-index"
                                    show-input/>
                                <div class="-mt-2 mb-2 pr-40 w-full text-center text-xxs font-bold">
                                    {{sliderLabel(issueType.driverReportSetting)}}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div v-show="customFilteredIssueTypes.length === 0" class="text-center text-base mt-2 text-gray-600">
                        <i class="uil uil-search"></i>
                        <span>No matching custom {{$t('label.associate')}} Issues. <span class="link" @click="search = ''">Show All</span></span>
                    </div>
                </div>
                <div class="mt-6">
                    <div class="flex justify-start">
                        <div class="font-bold text-lg">{{$t('label.associate')}} Kudos</div>
                    </div>
                    <div v-for="kudoType in customFilteredKudoTypes" :key="kudoType.id">
                        <div class="py-1 px-2 flex space-x-4 justify-start rounded-lg bg-gray-100 border border-gray-400 my-5">
                            <div class="flex-initial max-w-56 w-full font-bold my-auto">{{kudoType.option}}</div>
                            <div class="flex-auto ">
                                <el-slider
                                    data-cy="df-custom-kudo-slider"
                                    class="performanceSlider kudoSlider"
                                    v-model="kudoType.driverReportSetting"
                                    @change="saveSlider(kudoType, 'custom', 'customFilteredKudoTypes', kudoType.option)"
                                    :show-input-controls="false"
                                    tooltip-class="custom-tooltip-lowest-z-index"
                                    show-input/>
                                <div class="-mt-2 mb-2 pr-40 w-full text-center text-xxs font-bold">
                                    {{sliderLabel(kudoType.driverReportSetting)}}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div v-show="customFilteredKudoTypes.length === 0" class="text-center text-base mt-2 text-gray-600">
                        <i class="uil uil-search"></i>
                        <span>No matching custom {{$t('label.associate')}} Kudos. <span class="link" @click="search = ''">Show All</span></span>
                    </div>
                </div>
            </section>
        </el-collapse-item>
        

    </el-collapse>
  </div>
</template>

<script>
import { getTenant } from './query.js'
import { getTenantUpdatedTimestamp, getOptionsCustomListsUpdatedTimestamp } from '@/utilities/timestampQueries'
import { updateTenant, updateOptionsCustomLists } from './mutations'
import moment from 'moment-timezone'
import { weeklyPerformanceMetrics, dailyPerformanceMetrics, scorecardAndCxMetricsMaxInputValue } from '@/utilities/defaultCoachingSettings'
import { mapGetters } from 'vuex'
import { recalculateDaPerformanceData } from '@/views/DaPerformanceReport/calculateDaPerformanceReport'
import { CUSTOMER_SUB_TYPE_XL } from '@/views/System/Tenants/constants'

export default {
    name: "DriverFeedback",
    components:{},
    data(){
        return{
            showWeekly: false,
            showDaily: false,
            showCustom: false,
            collapseActive: [], 
            loading: {
                coachingMessages: true,
                driverRank: true,
                weeklyPerformanceData: false,
                dailyPerformanceData: false,
                custom: false
            },
            driverRankRangeOptions: [7,15,30,45,60,75,90,105,120,135,150,165,180],
            tenant: {},
            issueTypes: {},
            originalTenant: {},
            search: '',
            showUpgradeDialog: false,
            weeklyPerformanceMetrics: [],
            dailyPerformanceMetrics: [],
            customerSubType: null,
            moreThanScorecardMetrics: ['Dsb', 'Dnr', 'SeatbeltOff', 'SpeedingEvent', 'DistractionsRate', 'FollowingDistanceRate', 'SignSignalViolationsRate'],
            mentorMetrics: ["Seatbelt", "Sse", ],
            scorecardAndCxMax: scorecardAndCxMetricsMaxInputValue
        }
    },

    created(){
        this.weeklyPerformanceMetrics = weeklyPerformanceMetrics.filter(item => item.name != 'SwcSc')
        this.dailyPerformanceMetrics = dailyPerformanceMetrics
        this.loadData()
    },

  computed: {

    ...mapGetters([
        'issueTypeList',
        'kudoTypeList'
    ]),

    showHarsh() {
        return this.customerSubType === CUSTOMER_SUB_TYPE_XL;
    },
    noScorecardResults(){
        return this.weeklyPerformanceMetrics.filter(metric =>{
            let searchable = metric.label
            return searchable.toLowerCase().includes(this.search.toLowerCase()) && metric.src == "Scorecard"
        }).length === 0
    },

    noCxResults(){
        return this.weeklyPerformanceMetrics.filter(metric =>{
            let searchable = metric.label
            return searchable.toLowerCase().includes(this.search.toLowerCase()) && metric.src == "CXFeedback"
        }).length === 0
    },

    noPpsResults(){
        return this.weeklyPerformanceMetrics.filter(metric =>{
            let searchable = metric.label
            return searchable.toLowerCase().includes(this.search.toLowerCase()) && metric.src == "ProperParkingSequence(PPS)"
        }).length === 0
    },

    noMentorResults(){
        return this.dailyPerformanceMetrics.filter(metric =>{
            let searchable = metric.label
            return searchable.toLowerCase().includes(this.search.toLowerCase()) && metric.src == "Mentor"
        }).length === 0
    },

    noNetradyneResults(){
        return this.dailyPerformanceMetrics.filter(metric =>{
            let searchable = metric.label
            return searchable.toLowerCase().includes(this.search.toLowerCase()) && metric.src == "Netradyne"
        }).length === 0
    },

    noEOCResults(){
        return this.dailyPerformanceMetrics.filter(metric =>{
            let searchable = metric.label
            return searchable.toLowerCase().includes(this.search.toLowerCase()) && metric.src == "EOC"
        }).length === 0
    },

    builtInMetrics(){
        let metrics = {}
        this.weeklyPerformanceMetrics.forEach(metric =>{
            metrics[metric.sliderField] = true
        })
        this.dailyPerformanceMetrics.forEach(metric =>{
            metrics[metric.sliderField] = true
        })
        // added below so Driver Initiated and Face mask compliance are hidden in custom metrics section
        metrics['NetradyneDriverInitiated'] = true
        metrics['NetradyneFaceMaskCompliance'] = true
        return metrics
    },

    customFilteredKudoTypes(){
        const filteredKudoTypes = this.kudoTypeList.filter(item => {
            const search = item.option.toLowerCase().includes(this.search.toLowerCase())
            const key = item.option.replace(/ /g, '').replace(/-/g, '').replace(/\//g, '').replace(/®/g, '')
            const coachingDefault = !this.builtInMetrics[key] || item.isCustom 
            return search && coachingDefault
        })
        return filteredKudoTypes
    },

    customFilteredIssueTypes(){
        const filteredIssueTypes = this.issueTypeList.filter(item => {
            const search = item.option.toLowerCase().includes(this.search.toLowerCase())
            const key = item.option.replace(/ /g, '').replace(/-/g, '').replace(/\//g, '').replace(/®/g, '')
            const coachingDefault = !this.builtInMetrics[key] || item.isCustom 
            return search && coachingDefault
        })
        return filteredIssueTypes
    },

    filteredKudoTypes(){
        const kudoTypes = this.kudoTypeList.filter(item => item.option.toLowerCase().includes(this.search.toLowerCase()))
        let objectKudoTypes = {}
        kudoTypes.forEach(item => {
            const key = item.option.replace(/ /g, '').replace(/-/g, '').replace(/\//g, '').replace(/®/g, '').replace(/,/g, '')
            objectKudoTypes[key] = item
        })
        return objectKudoTypes
    },
    
    filteredIssueTypes(){
        const issueTypes = this.issueTypeList.filter(item => item.option.toLowerCase().includes(this.search.toLowerCase()))
        let objectIssueTypes = {}
        issueTypes.forEach(item => {
            const key = item.option.replace(/ /g, '').replace(/-/g, '').replace(/\//g, '').replace(/®/g, '').replace(/,/g, '')
            objectIssueTypes[key] = item
        })
        return objectIssueTypes
    },

    today(){
      return moment.tz(this.timeZone).format()
    },

    timeZone(){
      return this.getTenantTimeZone()
    },

    automatedCoachingSendTime(){
      return this.tenant.automatedCoachingSendTime ?  this.convert24to12Time(this.tenant.automatedCoachingSendTime) : null
    },

    automatedCoachingSendTimeTo24(){
      return this.tenant.automatedCoachingSendTime ?  this.convert12to24Time(this.tenant.automatedCoachingSendTime): null
    },

    timeOptions(){
        var options = []
        const hours   = ['00','01','02','03','04','05','06','07','08','09','10','11','12','13','14','15','16','17','18','19','20','21','22','23']
        const minutes = ['00','15','30','45']
        for(const hour of hours){
            for(const minute of minutes){
                let twelveTime = parseInt(hour) % 12
                if(twelveTime === 0) twelveTime = 12
                const formattedHour = twelveTime.toString().padStart(2,0)
                const amPm = hour > 11 ? 'pm': 'am'
                options.push({ time: `${formattedHour}:${minute} ${amPm}`, label: `${formattedHour}:${minute} ${amPm}`})
            }
        }
        return options
    },

    showPremiumWarnings(){
      if((!this.$store.getters.hasPerformanceCoaching && !this.$store.getters.isTrialAccount) || (this.$store.getters.isTrialAccount && this.$store.getters.isTrialExpired)){
        return true;
      }
      else{
        return false;
      }
    }
  },

  methods:{
      sliderLabel(value){
          if(value == 0) return 'Not included in ranking'
          else if(value < 40) return 'Low importance'
          else if(value < 60) return 'Balanced'
          else if(value <= 99) return 'High importance'
          else return 'Very high importance'
      },

    async saveSlider(item, loadingContext, metricsArray, sliderField){
        this.loading[loadingContext] = true
        try{
            let input = {
                id: item.id,
                driverReportSetting: item.driverReportSetting,
                updatedAt: item.updatedAt
            }
            const result = await this.api(updateOptionsCustomLists, {input}, getOptionsCustomListsUpdatedTimestamp, 'getOptionsCustomLists')
           
            this.$message({
                message: "Saved!",
                type: "success",
                customClass: 'success-message'
            })    
            if(loadingContext == "custom"){
                const index = this[metricsArray].findIndex(customItem =>{
                    return customItem.option == sliderField
                })
                this[metricsArray][index].updatedAt = result.data.updateOptionsCustomLists.updatedAt
            }else{
                this[metricsArray][sliderField].updatedAt = result.data.updateOptionsCustomLists.updatedAt
            }
            await recalculateDaPerformanceData(false)
        } catch(e) {
            this.displayUserError(e)
        } finally {
            this.loading[loadingContext] = false
        }
    },

    
    handleDropdownCommand(command){
        let splitCommand = command.split('-')
        let type = this.toTitleCase(splitCommand[0])
        let source = splitCommand[1]
        if(source.toLowerCase().includes('engine off compliance')){
            source = "EOC"
        }
        this.$confirm(`This will ${type.toLowerCase()} all ${source} metrics. Continue?`, 'Warning', {
          confirmButtonText: type,
          closeOnClickModal: false,
          cancelButtonText: 'Cancel',
          type: 'warning'
        }).then(() => {
          this.resetDefaults(source.replaceAll(' ', ''), type)       
        }).catch(() => {
          console.error("canceled delete")         
        });
        
    },

    /** 
     * Load tenant data
     * */
    async loadData(loadingContext){
      try{
            if(loadingContext){
                this.loading[loadingContext] = true
            }
            else{
                this.loading.coachingMessages = true
                this.loading.driverRank = true
                this.loading.weeklyPerformanceData = true
                this.loading.dailyPerformanceData = true
                this.loading.custom = true
            }
        const tenant = await this.api(getTenant, { id: this.$store.state.userInfo.tenant.id })
        //console.log('---tenant', JSON.stringify(tenant))
        this.tenant = tenant.data.getTenant
        this.originalTenant = {...tenant.data.getTenant}
        const tz = this.getTenantTimeZone();
        this.timezone = tz

        this.tenant.automatedCoachingSendTime = this.automatedCoachingSendTime;
        
      }catch(e){
        console.error(e)
        this.displayUserError(e)
      }
      finally{
        this.customerSubType = this.$store.state.userInfo.tenant?.customerSubType || null;
        await this.$nextTick()
        if(loadingContext){
            this.loading[loadingContext] = false
        }
        else{
            this.loading.coachingMessages = false
            this.loading.driverRank = false
            this.showWeekly = true
            this.loading.weeklyPerformanceData = false   
            this.showDaily = true
            this.loading.dailyPerformanceData = false  
            this.showCustom = true
            this.loading.custom = false                        
        }
      }
    },

    replaceDate(timestamp, date){
      var re = /\d+-\d+-\d+/g
      return timestamp.replace(re, date)
    },
    
    replaceTime(timestamp, time){
      var re = /\d+:\d+:\d+/g
      return timestamp.replace(re, time)
    },

    async resetDefaults(source, type){
        try{        
            //check timestamp
            var timestampResult = await this.api(getTenantUpdatedTimestamp, {id: this.tenant.id})
            if(!timestampResult.data.getTenant){
                throw "Account is missing or deleted by admin"
            }

            var timestamp = timestampResult.data.getTenant.updatedAt;

            if(timestamp != this.tenant.updatedAt){
                throw "Record was updated by another user. Please copy any changes and refresh the page."
            }

            var all = null
            var weeklyOrDaily = source == 'Scorecard' || source == 'CXFeedback' || source === 'ProperParkingSequence(PPS)' ? 'weekly' : 'daily'
            this.loading[weeklyOrDaily + 'PerformanceData'] = true

            if(type == 'Disable' || type == 'Enable'){
                let enableOrDisable = {}
                this[weeklyOrDaily + 'PerformanceMetrics'].forEach(metric =>{
                    if(metric.src == source){
                        if(metric.defaults.issue != null && metric.defaults.issue != undefined) enableOrDisable['coaching' + metric.name + 'Issue'] = type == 'Disable' ? false : true
                        if(metric.defaults.co != null && metric.defaults.co != undefined) enableOrDisable['coaching' + metric.name + 'CO'] = type == 'Disable' ? false : true
                        if(metric.defaults.pr != null && metric.defaults.pr != undefined) enableOrDisable['coaching' + metric.name + 'PR'] = type == 'Disable' ? false : true
                        if(metric.defaults.kudo != null && metric.defaults.kudo != undefined) enableOrDisable['coaching' + metric.name + 'Kudo'] = type == 'Disable' ? false : true
                    }
                })

                all = type
                type = 'All'

                // update enables
                var input = {
                    id: this.tenant.id,
                    group: this.tenant.group,
                    ...enableOrDisable,
                    updatedAt: this.tenant.updatedAt
                }

                await this.api(updateTenant, {input: input}, getTenantUpdatedTimestamp, 'getTenant')
            }

            if(type == 'Default'){
                // build default objects
                let sliderDefaults = this[weeklyOrDaily + 'PerformanceMetrics'].map(metric =>{
                    return {defaults: metric.defaults, sliderField: metric.sliderField, src: metric.src}
                }).filter(item => {return item.src == source})

                let defaultThresholds = {}
                this[weeklyOrDaily + 'PerformanceMetrics'].forEach(metric =>{
                    if(metric.src == source){
                        if(metric.defaults.thresholdIssue ) defaultThresholds['coaching' + metric.name + 'ThresholdIssue'] = metric.defaults.thresholdIssue
                        if(metric.defaults.threshold ) defaultThresholds['coaching' + metric.name + 'Threshold'] = metric.defaults.threshold
                        if(metric.defaults.thresholdPR ) defaultThresholds['coaching' + metric.name + 'ThresholdPR'] = metric.defaults.thresholdPR
                        if(metric.defaults.thresholdKudo ) defaultThresholds['coaching' + metric.name + 'ThresholdKudo'] = metric.defaults.thresholdKudo
                        if(metric.defaults.ratingIssue ) defaultThresholds['coaching' + metric.name + 'RatingIssue'] = metric.defaults.ratingIssue
                        if(metric.defaults.thresholdKudo ) defaultThresholds['coaching' + metric.name + 'RatingKudo'] = metric.defaults.ratingKudo
                    }

                    if(metric.src == 'CXFeedback') {
                        if(metric.defaults.ratingCO ) defaultThresholds['coaching' + metric.name + 'RatingCO'] = metric.defaults.ratingCO
                        if(metric.defaults.ratingPR ) defaultThresholds['coaching' + metric.name + 'RatingPR'] = metric.defaults.ratingPR
                    }

                })

                const objectSplit1 = Object.entries(defaultThresholds).slice(0, 40)
                const objectSplit2 = Object.entries(defaultThresholds).slice(40)

                const defaultThresholds1 = objectSplit1.reduce(function(acc, obj) {
                    acc[obj[0]] = obj[1];
                    return acc;
                }, {});

                const defaultThresholds2 = objectSplit2.reduce(function(acc, obj) {
                    acc[obj[0]] = obj[1];
                    return acc;
                }, {});

                // update thresholds part 1
                var input = {
                    id: this.tenant.id,
                    group: this.tenant.group,
                    ...defaultThresholds1,
                    updatedAt: this.tenant.updatedAt
                }
                var result = await this.api(updateTenant, {input: input}, getTenantUpdatedTimestamp, 'getTenant')
                this.tenant.updatedAt = result.data.updateTenant.updatedAt

                if(objectSplit2.length > 0) {
                    // update thresholds part 2
                    // Second update is needed to avoid "Invalid UpdateExpression: Expression size has exceeded the maximum allowed size
                    var input = {
                        id: this.tenant.id,
                        group: this.tenant.group,
                        ...defaultThresholds2,
                        updatedAt: this.tenant.updatedAt
                    }
                    var result = await this.api(updateTenant, {input: input}, getTenantUpdatedTimestamp, 'getTenant')
                    this.tenant.updatedAt = result.data.updateTenant.updatedAt
                }

                let defaultEnable = {}
                this[weeklyOrDaily + 'PerformanceMetrics'].forEach(metric =>{
                    if(metric.src == source){
                        if(metric.defaults.issue != null && metric.defaults.issue != undefined) defaultEnable['coaching' + metric.name + 'Issue'] = metric.defaults.issue
                        if(metric.defaults.co != null && metric.defaults.co != undefined) defaultEnable['coaching' + metric.name + 'CO'] = metric.defaults.co
                        if(metric.defaults.pr != null && metric.defaults.pr != undefined) defaultEnable['coaching' + metric.name + 'PR'] = metric.defaults.pr
                        if(metric.defaults.kudo != null && metric.defaults.kudo != undefined) defaultEnable['coaching' + metric.name + 'Kudo'] = metric.defaults.kudo
                    }
                })

                // update enables
                input = {
                    id: this.tenant.id,
                    group: this.tenant.group,
                    ...defaultEnable,
                    updatedAt: this.tenant.updatedAt
                }
                var result = await this.api(updateTenant, {input: input}, getTenantUpdatedTimestamp, 'getTenant')
                this.tenant.updatedAt = result.data.updateTenant.updatedAt

                //update sliders
                sliderDefaults.forEach(async item =>{
                    if(this.filteredKudoTypes[item.sliderField]){
                        let input = {
                            id: this.filteredKudoTypes[item.sliderField].id,
                            group: this.tenant.group,
                            driverReportSetting: item.defaults.kudoSlider,
                            updatedAt: this.filteredKudoTypes[item.sliderField].updatedAt
                        }
                        await this.api(updateOptionsCustomLists, {input}, getOptionsCustomListsUpdatedTimestamp, 'getOptionsCustomLists')
                        this.filteredKudoTypes[item.sliderField].driverReportSetting = item.defaults.kudoSlider
                    }
                    if(this.filteredIssueTypes[item.sliderField]){
                        let input = {
                            id: this.filteredIssueTypes[item.sliderField].id,
                            group: this.tenant.group,
                            driverReportSetting: item.defaults.issueSlider,
                            updatedAt: this.filteredIssueTypes[item.sliderField].updatedAt
                        }
                        await this.api(updateOptionsCustomLists, {input}, getOptionsCustomListsUpdatedTimestamp, 'getOptionsCustomLists')
                        this.filteredIssueTypes[item.sliderField].driverReportSetting = item.defaults.issueSlider
                    }
                })
            }

            this.loadData(weeklyOrDaily + 'PerformanceData')
            const header = this[weeklyOrDaily + 'PerformanceMetrics'].find(item => item.src === source && item?.header)?.header
            var message
            if(type == 'Default') message = `${this.toTitleCase(header)} default settings reset`
            else if(type == 'All') message = `All ${header} settings ${all.toLowerCase()}d`
        
            this.displayUserNotification({
                message: message,
                type: "success",
                title: "Settings Updated"
            })    
        }
        catch(e){
            console.error(e)
            this.displayUserError(e)   
            this.loading = false
        }

    },

    /** Update the tenant in the DB */
    async updateTenant(showLoad, field){
        if(showLoad){
            if(field === 'SendTime') this.loading.coachingMessages = true
            else this.loading.driverRank = true
        } else {
            this.loading.weeklyPerformanceData = true
            this.loading.dailyPerformanceData = true
        }

      try {
        if(field && field == 'SendTime'){
            var input = {
                id: this.tenant.id,
                group: this.tenant.group,
                timeZone: this.timeZone,
                automatedCoachingSendTime: this.automatedCoachingSendTimeTo24,
                updatedAt: this.tenant.updatedAt
            }
        }
        else if(field){
          var input = {
            id: this.tenant.id,
            group: this.tenant.group,
            updatedAt: this.tenant.updatedAt
          }
          input['coaching'+ field] = this.tenant['coaching'+ field]
        }
        else{
          var input = {
            ...this.tenant,
            timeZone: this.timeZone,
            automatedCoachingSendTime: this.automatedCoachingSendTimeTo24,
          }
        }

        const result = await this.api(updateTenant, { input }, getTenantUpdatedTimestamp, 'getTenant')
        this.tenant.updatedAt = result.data.updateTenant.updatedAt
        if(showLoad){
            this.loading.coachingMessages = false 
            this.loading.driverRank = false
        } else {
            this.loading.weeklyPerformanceData = false
            this.loading.dailyPerformanceData = false
        }
        this.$message({
          message: "Saved!",
          type: "success",
          customClass: 'success-message'
        })    
 
      } 
      catch(e) {
        console.error(e)
        this.displayUserError(e)   
        if(showLoad){
            this.loading.coachingMessages = false   
            this.loading.driverRank = false
        } else {
            this.loading.weeklyPerformanceData = false
            this.loading.dailyPerformanceData = false
        }
      }
    },

    isInvalidScorecardInput(metric){
        if(this.moreThanScorecardMetrics.includes(metric.name)) {
            return (this.tenant['coaching'+ metric.name + 'Threshold'] < this.tenant['coaching'+ metric.name + 'ThresholdPR'] && metric.polarity) ? true : false;
        }else{
            return (this.tenant['coaching'+ metric.name + 'Threshold'] > this.tenant['coaching'+ metric.name + 'ThresholdPR'] && metric.polarity) ? true : false;
        }
    },
    clearCouchingTime(){
        this.tenant.automatedCoachingSendTime = null
    },
  }

}
</script>
<style scoped>
.coachingThresholdInput{
  @apply w-16 !important;
}

.triangle-bottomright {
    width: 0;
    height: 0;
    border-bottom: 100px solid #42c47d;
    border-left: 100px solid transparent;
  }

  .transform-text{
    transform: rotate(-45deg);
    text-align: center;
    top: 61px;
    left: -54px;
    position: relative;
  }

  .performance-chart{
    position: relative;
  }

  .fit-content-metrices{
    width:fit-content;
  }
  
</style>
<style>
.performanceSlider .el-slider__input{
    @apply w-16 !important;
}
.performanceSlider {
    @apply w-full !important;
}
.performanceSlider .el-slider__runway {
    @apply w-3/4 !important;
}
.issueSlider .el-slider__bar{
    @apply bg-red-500 ;
}   
.issueSlider .el-slider__button{
    @apply border-red-500 ;
}
.kudoSlider .el-slider__bar{
    @apply bg-great ;
}   
.kudoSlider .el-slider__button{
    @apply border-great ;
}
.issueSlider .el-slider__button-wrapper{
    @apply z-0;
}
.kudoSlider .el-slider__button-wrapper{
    @apply z-0;
}
.coachingThresholdInput input{
  @apply px-0 !important;
}
.invalid-threshold input{
    @apply border-red-500 !important;
}
@media (max-width: 400px) {
    .title-collapse {
        font-size: .93rem;
    }
}
</style>