import { COUNSELING_STATUS_REFUSED_TO_SIGN_BY_ASSOCIATE, COUNSELING_STATUS_REFUSED_TO_SIGN_BY_HERA_USER, COUNSELING_STATUS_NOT_SENT, COUNSELING_STATUS_PENDING_SIGNATURE, COUNSELING_STATUS_SIGNED } from '@/utilities/constants/counseling';
 
/**
 * Parses a given counseling value in the form 'COUNSELING_VALUE'
 * to a capitalized string representation like 'Counseling Value'
 * @param {String} statusValue
 * @returns String
 */
export function parseCounselingStatusValueToLabel(statusValue='') {
  if (!statusValue) return;
  const toCapital = word => word[0].toUpperCase() + word.substr(1).toLowerCase();
  return statusValue.split('_').map(toCapital).join(' ');
}

/**
 * Parses a given counseling label in the form 'Counseling Value'
 * to it's value representation like 'COUNSELING_VALUE'
 * @param {String} statusLabel
 * @returns String
 */
export function parseCounselingStatusLabelToValue(statusLabel='') {
  const toUpper = word => word.toUpperCase();
  return statusLabel.split(' ').map(toUpper).join('_');
}

/**
 * Evaluate the status, if is refused, the value of the label will change
 * @param {object} formdData
 * @returns String
 */
export function setSignatureLabel(formData) {
  return (formData.status == COUNSELING_STATUS_REFUSED_TO_SIGN_BY_ASSOCIATE || formData.status == COUNSELING_STATUS_REFUSED_TO_SIGN_BY_HERA_USER) ? 'Marked as Refused By' : 'Signature'
}

/**
 * Evaluate the date, if is refused, the value of the label will change
 * @param {object} formdData
 * @returns String
 */
export function setDateSignedLabel(formData){
  return (formData.status == COUNSELING_STATUS_REFUSED_TO_SIGN_BY_ASSOCIATE || formData.status == COUNSELING_STATUS_REFUSED_TO_SIGN_BY_HERA_USER) ? 'Date Refused to Sign' : 'Date Signed'
}

/**
 * Evaluate the time, if is refused, the value of the label will change
 * @param {object} formdData
 * @returns String
 */
export function setTimeSignedLabel(formData){
  return (formData.status == COUNSELING_STATUS_REFUSED_TO_SIGN_BY_ASSOCIATE || formData.status == COUNSELING_STATUS_REFUSED_TO_SIGN_BY_HERA_USER) ? 'Time Refused to Sign' : 'Time Signed'
}


/**
 * Verify if the  refused counseling if from the same user.
 * @param {object} formdData
 * @returns String
 */
export function setCounselingRefusedName(formData, userList) {
  if ( [ COUNSELING_STATUS_NOT_SENT, COUNSELING_STATUS_PENDING_SIGNATURE ].includes( formData.status ) ) {
    return null;
  } else if( [ COUNSELING_STATUS_SIGNED, COUNSELING_STATUS_REFUSED_TO_SIGN_BY_ASSOCIATE].includes(formData.status) && formData.signature ) {
    return formData.signature;
  } else if (COUNSELING_STATUS_REFUSED_TO_SIGN_BY_HERA_USER === formData.status && formData.refusedByHeraUserId) {
    const user = userList.find(user => user.id === formData.refusedByHeraUserId)
    return user.firstName + ' ' + user.lastName + ' (Hera User)';
  } else {
    return formData.user.firstName + ' ' + formData.user.lastName + ' (Hera User)';
  }
}
