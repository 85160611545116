<template>
  <div>
    <div class="text-center text-gray-600">
      <i class="uil uil-cog lg-cog"></i>
      <h1 class="-mt-10 mb-10">Select an Option</h1>
    </div>
  </div>
</template>

<script>


export default {

}
</script>

<style lang="scss" scoped>
.lg-cog{
    font-size: 15rem;
    opacity: 0.3;
}
</style>
