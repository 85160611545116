<template>
    <div>
        <!--Main Content-->
        <div class="bg-gray-100">
            <div class="container mt-4 mx-auto w-full md:2/3 lg:w-1/2 px-4">
                <div class="bg-white rounded shadow p-8">
                    <transition name="fade"  mode="out-in">                           
                        <router-view :key="$route.fullPath" />                           
                    </transition>
                </div>
            </div>            
        </div>      
        <Banner v-if="$route.name==='CounselingSignature'"
            bg="bg-brand" icon="uil-usd-circle" :class="bannerStyle" 
            :copy="referralBannerText" 
        />
        <!--Footer--> 
        <footer class="bg-gray-100 mt-8 text-center pb-4 text-sm text-brand">
            <div class="text-xs mt-1">&copy; {{new Date().getFullYear()}} | All Rights Reserved | Hera Solutions, Inc</div>
            <div class="text-xs">Hera is not affiliated with Amazon or its subsidiaries.</div>
            <div class="text-xs">Version {{$store.state.version}}</div>
        </footer>    

    </div>      
</template>

<script>
import Banner from '@/components/shared/Banner.vue';
import { referralBannerText } from '@/utilities/constants/referralBannerText.js';
export default {
    components:{
        Banner
    },
    computed: {
        referralBannerText(){
            return referralBannerText
        },
        bannerStyle(){
            return (this.$route.name == "CounselingSignature") ? "" : "display-banner"
        }
    }
}
</script>

<style scoped>
.display-banner{
    @apply pb-3
}
</style>