import store from '@/store/index.js';
import { staffsByGroupStatus, getStaff } from './queries';
import { messageReadStatusbyGroupAndUser } from '@/graphql/queries';
import moment from 'moment-timezone';
import { isAfterDateTime } from '@/utilities/getDate'
import { mixinMethods } from '@/main'
import { Storage } from 'aws-amplify'

const START_DATE = moment( new Date(2022, 10, 15, 0, 0, 0) );

let loading = false

export async function loadMessageReadByGroup() {
  const { userInfo } = store.state;
  const userID = userInfo.id;
  const input = { 
    group: userInfo.tenant.group,
    userID: { eq: userID }
  };

  let listRecords = await mixinMethods.gLoadListAll(messageReadStatusbyGroupAndUser, input, 'messageReadStatusbyGroupAndUser')
  return listRecords;
}

export async function loadStaff() {
  if(loading) return
  loading = true
  const { userInfo, subscriptionStore } = store.state;
  subscriptionStore.messengerList.lastRenderKey = 0 //to show spinloader in messenger list
  if(!userInfo?.tenant?.group) return
  let input = {
    group: userInfo.tenant.group,
    sortDirectionMessage: "DESC",
    filterMessage: {
      messageType: { eq: "messenger" },
      channelType: { eq: "RESPONSE" },
      senderId: { attributeExists: false }
    },
    limit: 100
  }
  let listStaff = await mixinMethods.gLoadListAll(staffsByGroupStatus, input, 'staffsByGroupStatus');
  return listStaff;
}

export function isVisitedChat( staff ) {
  const { userInfo } = store.state;
  const { items = [] } = staff?.messageReadStatus || {};
  const recordVisit = items.find( recordVisit => recordVisit.userID === userInfo.id );
  return recordVisit;
}

export function haveMessagesSend( staff ) {
  const { items } = staff?.messages || {};
  return items?.length > 0;
}

export function compareLastVisitChatByUser( staff, staffChatVisited ) {
  const lastDateMessageSendByStaff = staff.messages.items[0].createdAt;
  const lastDateVisitByUser = staffChatVisited.readUpToDateTime;
  const readChat = isVisitChatAfterLastMessage( lastDateVisitByUser, lastDateMessageSendByStaff );
  return readChat;
}

export function isVisitChatAfterLastMessage( lastDateVisitByUser, lastDateMessageSendByStaff ) {
  return lastDateVisitByUser === lastDateMessageSendByStaff || isAfterDateTime(lastDateVisitByUser, lastDateMessageSendByStaff)
}

export function countMessagesUnread( staff, lastVisit ) {
  const messages = haveMessagesSend( staff ) ? staff.messages.items : [];
  const newMessages = messages.filter( ({ createdAt }) => !isVisitChatAfterLastMessage( lastVisit , createdAt ) )
  return newMessages.length;
}

const getLastMomentReceived = (chat)=>{
  const prev = chat.lastMomentReceived
  const current = chat.smsLastMessageTime ? moment(chat.smsLastMessageTime).fromNow(true) : ' '
  if(prev !== current){
    chat.lastMomentReceived = current
  }
}

const getUpdateLastMomentReceivedFunction = function(chat){
  return async ()=> await new Promise(resolve => {
    if(chat.linkedComponent?.$nextTick){
      chat.linkedComponent?.$nextTick(()=>{
        resolve(getLastMomentReceived(chat))
      })
    }else{
      resolve(getLastMomentReceived(chat))
    }
  })
}

const getUpdateSearchKeyFunction = function(chat){
  return ()=>{
    const { firstName = '', lastName = '', smsLastMessage = '' } = chat.staff || {}
    chat.searchKey = (lastName + ' ' + firstName + ' ' + lastName + ' ' + smsLastMessage).toLowerCase().replace(/\s+/,' ')
  }
}

const getUpdateSmsLastMessageFunction = function(chat){
  return (message)=>{
    const associate = chat.staff
    if(message){
      if(message.bodyText){
        associate.smsLastMessage = message.bodyText
        chat.updateSearchKey()
      }else if(message.attachment?.id){
        const timestamp = message.createdAt
        new Promise(async resolve =>{
          const input = {
            id: chat.staff.id
          }
          chat.timeout = setTimeout(async () => {
            if(chat.staff.smsLastMessageTimestamp !== timestamp){
              resolve(null)
            }else{
              resolve(await mixinMethods.api(getStaff, input))
            }
          }, 2000) //sleep
        }).then( response =>{
          if(response?.data?.getStaff){
            associate.smsLastMessage = response.data.getStaff.smsLastMessage
            chat.updateSearchKey()
          }
        })
      }
      if(message.createdAt){
        associate.smsLastMessageTimestamp = message.createdAt
      }
    }
    chat.smsLastMessageTime = associate.smsLastMessageTimestamp ? new Date(associate.smsLastMessageTimestamp).getTime() : null
    chat.updateLastMomentReceived()
    !message && chat.updateSearchKey()
  }
}

export const updateAssociateMessengerPhotoUrl = function(associate = {}){
  const messengerLookupMap = store.getters["subscriptionStore/getMessengerLookupMap"]
  const chat = messengerLookupMap[associate.id]
  if(chat){
    if(chat.lastAssociatePhoto === associate.photo) return
    chat.lastAssociatePhoto = associate.photo
    Storage.get(associate.photo).then(url=>{
      chat.photoUrl = url
    }).catch(mixinMethods.printUserError)
  }
}

export function getMessengerAssociateList( staffs ) {
  const messenger = []
  const associateLookupMap = store.getters["subscriptionStore/getAssociateLookupMap"]
  const messengerLookupMap = store.getters["subscriptionStore/getMessengerLookupMap"]

  staffs.forEach( staff => {
    const staffVisited = isVisitedChat( staff );
    const haveMessages = haveMessagesSend( staff );
    let messageUnread = 0;
    let readChat = false;
    if ( staffVisited ) {
      messageUnread = countMessagesUnread( staff, staffVisited.readUpToDateTime );
      readChat = haveMessages ? compareLastVisitChatByUser( staff, staffVisited ) : true;
    } else{
      messageUnread = staff.messages.items?.length || 0;
      readChat = messageUnread > 0 ? false : true;
    }

    const associate = associateLookupMap[staff.id]
    const existingChat = messengerLookupMap[staff.id]

    associate.smsLastMessageTimestamp = staff.smsLastMessageTimestamp
    associate.smsLastMessage = staff.smsLastMessage

    let chat
    if(existingChat){
      chat = existingChat
      chat.readChat = readChat,
      chat.messageUnread = messageUnread
    }else{
      chat = {
        staffId: staff.id,
        readChat,
        messageUnread,
        staff: associate,
        smsLastMessageTime: 0,
        lastMomentReceived: ' ',
        searchKey: ' ',
        photoUrl: ''
      }
      chat.updateLastMomentReceived = getUpdateLastMomentReceivedFunction(chat)
      chat.updateSearchKey = getUpdateSearchKeyFunction(chat)
      chat.updateSmsLastMessage = getUpdateSmsLastMessageFunction(chat)
      
      chat.updateSmsLastMessage()
    }

    messenger.push(chat)
  })
  loading = false
  return messenger
}

function isMessageSentFromStartDate( message, startDate ) {
  const { createdAt: messageCreateAt } = message;
  const status = isVisitChatAfterLastMessage( messageCreateAt, startDate);
  return status;
}

export function messageSentLastMonth( listMessages ) {
  return listMessages.filter( message => isMessageSentFromStartDate( message, START_DATE ) );
}

function accumulatorUnreadMessages( prev, next) {
  const { messageUnread, readChat } = next;
  let sumUnreadMessages = readChat ? 0 : prev + messageUnread;
  return sumUnreadMessages;
}

export function totalUnreadMessages( staffList ) {
  const staffs = staffList.filter( ({ readChat }) => !readChat );
  const numberUnreadMessages = staffs.reduce( accumulatorUnreadMessages,  0);
  return numberUnreadMessages;
}