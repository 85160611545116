export const updateCard = /* GraphQL */ `
    mutation UpdateCard(
        $input: UpdateCardInput!
        $condition: ModelCardConditionInput
    ) {
        updateCard(input: $input, condition: $condition) {
            id
            group
            stripeSetupIntent
            stripeCustomerId
            stripePaymentMethodId
            active
            createdAt
            chargeError
            updatedAt
            tenant {
                id
                createdAt
                companyName
                nameLog
                numberOfSeats
                logo
                accidentReport
                workmansComp
                dispatchNumber
                addressLine1
                addressLine2
                addressCity
                addressState
                addressZip
                group
                zohoCrmAccountRecordId
                originationNumber
                timeZone
                trialExpDate
                firstInterestDateTime
                firstStartedTrialDateTime
                firstConvertedToPaidDateTime
                firstChurnedDateTime
                accountPremiumStatus
                accountCanceledNotes
                accountCanceledReason
                notes
                costStandard
                costBundle
                costPerformance
                costRostering
                costStaff
                costVehicles
                costMessaging
                discountPercent
                discountFixed
                discountPercentLabel
                discountFixedLabel
                payOutstandingByDate
                lastPaidPositiveInvoiceLineItemDateTime
                totalNumberOfMonthsPaidByTenant
                lifetimePaymentTotal
                averageMonthlyInvoiceTotal
                isTestingAccount
                calculatedOutstandingBalance
                bundleDeactivationAllowedAt
                performanceDeactivationAllowedAt
                rosteringDeactivationAllowedAt
                staffDeactivationAllowedAt
                vehiclesDeactivationAllowedAt
                automatedCoachingSendTime
                allowLibraryUpload
                coachingCustomMessage
                messageServiceProvider
                OriginationNumberOrderId
                OriginationNumberStatus
                customerStatus
                coachingFicoIssue
                coachingFicoCO
                coachingFicoPR
                coachingFicoKudo
                coachingDcrThresholdIssue
                coachingDcrThreshold
                coachingDcrThresholdPR
                coachingDcrThresholdKudo
                coachingDcrIssue
                coachingDcrCO
                coachingDcrPR
                coachingDcrKudo
                coachingDarThresholdIssue
                coachingDarThreshold
                coachingDarThresholdPR
                coachingDarThresholdKudo
                coachingDarIssue
                coachingDarCO
                coachingDarPR
                coachingDarKudo
                coachingDnrThresholdIssue
                coachingDnrThreshold
                coachingDnrThresholdPR
                coachingDnrThresholdKudo
                coachingDnrIssue
                coachingDnrCO
                coachingDnrPR
                coachingDnrKudo
                coachingDsbThresholdIssue
                coachingDsbThreshold
                coachingDsbThresholdPR
                coachingDsbThresholdKudo
                coachingDsbIssue
                coachingDsbCO
                coachingDsbPR
                coachingDsbKudo
                coachingSwcPodThresholdIssue
                coachingSwcPodThreshold
                coachingSwcPodThresholdKudo
                coachingSwcPodThresholdPR
                coachingSwcPodIssue
                coachingSwcPodCO
                coachingSwcPodPR
                coachingSwcPodKudo
                coachingSwcCcThresholdIssue
                coachingSwcCcThreshold
                coachingSwcCcThresholdKudo
                coachingSwcCcThresholdPR
                coachingSwcCcIssue
                coachingSwcCcCO
                coachingSwcCcPR
                coachingSwcCcKudo
                coachingSwcScThresholdIssue
                coachingSwcScThreshold
                coachingSwcScThresholdKudo
                coachingSwcScThresholdPR
                coachingSwcScIssue
                coachingSwcScCO
                coachingSwcScPR
                coachingSwcScKudo
                coachingSwcAdThresholdIssue
                coachingSwcAdThreshold
                coachingSwcAdThresholdKudo
                coachingSwcAdThresholdPR
                coachingSwcAdIssue
                coachingSwcAdCO
                coachingSwcAdPR
                coachingSwcAdKudo
                coachingSeatbeltOffThresholdIssue
                coachingSeatbeltOffThreshold
                coachingSeatbeltOffThresholdPR
                coachingSeatbeltOffThresholdKudo
                coachingSeatbeltOffIssue
                coachingSeatbeltOffCO
                coachingSeatbeltOffPR
                coachingSeatbeltOffKudo
                coachingSpeedingEventThresholdIssue
                coachingSpeedingEventThreshold
                coachingSpeedingEventThresholdPR
                coachingSpeedingEventThresholdKudo
                coachingSpeedingEventIssue
                coachingSpeedingEventCO
                coachingSpeedingEventPR
                coachingSpeedingEventKudo
                coachingDistractionsRateThresholdIssue
                coachingDistractionsRateThreshold
                coachingDistractionsRateThresholdPR
                coachingDistractionsRateThresholdKudo
                coachingDistractionsRateIssue
                coachingDistractionsRateCO
                coachingDistractionsRatePR
                coachingDistractionsRateKudo
                coachingFollowingDistanceRateThresholdIssue
                coachingFollowingDistanceRateThreshold
                coachingFollowingDistanceRateThresholdPR
                coachingFollowingDistanceRateThresholdKudo
                coachingFollowingDistanceRateIssue
                coachingFollowingDistanceRateCO
                coachingFollowingDistanceRatePR
                coachingFollowingDistanceRateKudo
                coachingSignSignalViolationsRateThresholdIssue
                coachingSignSignalViolationsRateThreshold
                coachingSignSignalViolationsRateThresholdPR
                coachingSignSignalViolationsRateThresholdKudo
                coachingSignSignalViolationsRateIssue
                coachingSignSignalViolationsRateCO
                coachingSignSignalViolationsRatePR
                coachingSignSignalViolationsRateKudo
                coachingOverallTierThresholdIssue
                coachingOverallTierThresholdKudo
                coachingOverallTierIssue
                coachingOverallTierKudo
                coachingOverallTierRatingIssue
                coachingOverallTierRatingKudo
                coachingConsecutiveTierThresholdIssue
                coachingConsecutiveTierThresholdKudo
                coachingConsecutiveTierIssue
                coachingConsecutiveTierKudo
                coachingConsecutiveTierRatingIssue
                coachingConsecutiveTierRatingKudo
                coachingPositiveFeedbackThresholdIssue
                coachingPositiveFeedbackThreshold
                coachingPositiveFeedbackThresholdPR
                coachingPositiveFeedbackThresholdKudo
                coachingPositiveFeedbackIssue
                coachingPositiveFeedbackCO
                coachingPositiveFeedbackPR
                coachingPositiveFeedbackKudo
                coachingDailyFicoThresholdIssue
                coachingDailyFicoThreshold
                coachingDailyFicoThresholdPR
                coachingDailyFicoThresholdKudo
                coachingDailyFicoIssue
                coachingDailyFicoCO
                coachingDailyFicoPR
                coachingDailyFicoKudo
                coachingSeatbeltThresholdIssue
                coachingSeatbeltThreshold
                coachingSeatbeltThresholdPR
                coachingSeatbeltThresholdKudo
                coachingSeatbeltIssue
                coachingSeatbeltCO
                coachingSeatbeltPR
                coachingSeatbeltKudo
                coachingSseIssue
                coachingSseCO
                coachingSsePR
                coachingSseKudo
                coachingSseThresholdIssue
                coachingSseThreshold
                coachingSseThresholdPR
                coachingSseThresholdKudo
                coachingDvcrsIssue
                coachingDvcrsCO
                coachingDvcrsPR
                coachingHarshBrakingRateThresholdIssue
                coachingHarshBrakingRateThreshold
                coachingHarshBrakingRateThresholdPR
                coachingHarshBrakingRateThresholdKudo
                coachingHarshBrakingRateIssue
                coachingHarshBrakingRateCO
                coachingHarshBrakingRatePR
                coachingHarshBrakingRateKudo
                coachingDaTierThresholdIssue
                coachingDaTierThresholdKudo
                coachingDaTierIssue
                coachingDaTierKudo
                coachingDaTierPR
                coachingDaTierCO
                coachingDaTierRatingIssue
                coachingDaTierRatingCO
                coachingDaTierRatingPR
                coachingDaTierRatingKudo
                coachingCdfScoreThresholdIssue
                coachingCdfScoreThreshold
                coachingCdfScoreThresholdPR
                coachingCdfScoreThresholdKudo
                coachingCdfScoreIssue
                coachingCdfScoreKudo
                coachingCdfScoreRatingIssue
                coachingCdfScoreCO
                coachingCdfScorePR
                coachingCdfScoreRatingKudo
                coachingHarshCorneringRateThresholdIssue
                coachingHarshCorneringRateThreshold
                coachingHarshCorneringRateThresholdPR
                coachingHarshCorneringRateThresholdKudo
                coachingHarshCorneringRateIssue
                coachingHarshCorneringRateCO
                coachingHarshCorneringRatePR
                coachingHarshCorneringRateKudo
                coachingPpsCompliancePercentThresholdIssue
                coachingPpsCompliancePercentThreshold
                coachingPpsCompliancePercentThresholdPR
                coachingPpsCompliancePercentThresholdKudo
                coachingPpsCompliancePercentIssue
                coachingPpsCompliancePercentCO
                coachingPpsCompliancePercentPR
                coachingPpsCompliancePercentKudo
                coachingPpsBreachesThresholdIssue
                coachingPpsBreachesThreshold
                coachingPpsBreachesThresholdPR
                coachingPpsBreachesThresholdKudo
                coachingPpsBreachesIssue
                coachingPpsBreachesCO
                coachingPpsBreachesPR
                coachingPpsBreachesKudo
                coachingDailyComplianceRateThresholdIssue
                coachingDailyComplianceRateThreshold
                coachingDailyComplianceRateThresholdPR
                coachingDailyComplianceRateThresholdKudo
                coachingDailyComplianceRateIssue
                coachingDailyComplianceRateCO
                coachingDailyComplianceRatePR
                coachingDailyComplianceRateKudo
                coachingTraningRemainingIssue
                coachingTraningRemainingCO
                coachingTraningRemainingPR
                coachingCameraObstructionIssue
                coachingCameraObstructionCO
                coachingCameraObstructionThresholdIssue
                coachingCameraObstructionThreshold
                coachingDriverDistractionIssue
                coachingDriverDistractionCO
                coachingDriverDistractionThresholdIssue
                coachingDriverDistractionThreshold
                coachingDriverDrowsinessIssue
                coachingDriverDrowsinessCO
                coachingDriverDrowsinessThresholdIssue
                coachingDriverDrowsinessThreshold
                coachingDriverInitiatedIssue
                coachingDriverInitiatedCO
                coachingDriverInitiatedThresholdIssue
                coachingDriverInitiatedThreshold
                coachingDriverStarPR
                coachingDriverStarKudo
                coachingDriverStarThresholdPR
                coachingDriverStarThresholdKudo
                coachingFaceMaskComplianceIssue
                coachingFaceMaskComplianceCO
                coachingFaceMaskComplianceThresholdIssue
                coachingFaceMaskComplianceThreshold
                coachingFollowingDistanceIssue
                coachingFollowingDistanceCO
                coachingFollowingDistanceThresholdIssue
                coachingFollowingDistanceThreshold
                coachingHardAccelerationIssue
                coachingHardAccelerationCO
                coachingHardAccelerationThresholdIssue
                coachingHardAccelerationThreshold
                coachingHardBrakingIssue
                coachingHardBrakingCO
                coachingHardBrakingThresholdIssue
                coachingHardBrakingThreshold
                coachingHardTurnIssue
                coachingHardTurnCO
                coachingHardTurnThresholdIssue
                coachingHardTurnThreshold
                coachingHighGIssue
                coachingHighGCO
                coachingHighGThresholdIssue
                coachingHighGThreshold
                coachingLowImpactIssue
                coachingLowImpactCO
                coachingLowImpactThresholdIssue
                coachingLowImpactThreshold
                coachingSeatbeltComplianceIssue
                coachingSeatbeltComplianceCO
                coachingSeatbeltComplianceThresholdIssue
                coachingSeatbeltComplianceThreshold
                coachingSignViolationsIssue
                coachingSignViolationsCO
                coachingSignViolationsThresholdIssue
                coachingSignViolationsThreshold
                coachingSpeedingViolationsIssue
                coachingSpeedingViolationsCO
                coachingSpeedingViolationsThresholdIssue
                coachingSpeedingViolationsThreshold
                coachingTrafficLightViolationIssue
                coachingTrafficLightViolationCO
                coachingTrafficLightViolationThresholdIssue
                coachingTrafficLightViolationThreshold
                coachingUTurnIssue
                coachingUTurnCO
                coachingUTurnThresholdIssue
                coachingUTurnThreshold
                coachingWeavingIssue
                coachingWeavingCO
                coachingWeavingThresholdIssue
                coachingWeavingThreshold
                coachingDriverRankRange
                usesXLcoaching
                shortCode
                growSurfParticipantId
                growSurfReferrerEmail
                growSurfReferrerFullName
                growSurfReferredEmail
                growSurfReferredFullName
                growSurfReferralComplete
                growSurfReferredByEmailBackup
                growSurfReferredByFullNameBackup
                growSurfParticipantEmail
                growSurfParticipantFullName
                growSurfParticipantReferralComplete
                stripeCustomerId
                stripeBillingEmail
                driverReportDayRange
                accountType
                customerType
                customerSubType
                accountCountry
                isTemporaryAccount
                allowParentData
                parentAccountId
                cards {
                    nextToken
                }
                companyScoreCardsByYearWeek {
                    nextToken
                }
                textractjobs {
                    nextToken
                }
                waves {
                    nextToken
                }
                recurringMessage {
                    nextToken
                }
                updatedAt
                dailyRoster {
                    nextToken
                }
                invoices {
                    nextToken
                }
                messages {
                    nextToken
                }
                parentAccount {
                    id
                    createdAt
                    companyName
                    nameLog
                    numberOfSeats
                    logo
                    accidentReport
                    workmansComp
                    dispatchNumber
                    addressLine1
                    addressLine2
                    addressCity
                    addressState
                    addressZip
                    group
                    zohoCrmAccountRecordId
                    originationNumber
                    timeZone
                    trialExpDate
                    firstInterestDateTime
                    firstStartedTrialDateTime
                    firstConvertedToPaidDateTime
                    firstChurnedDateTime
                    accountPremiumStatus
                    accountCanceledNotes
                    accountCanceledReason
                    notes
                    costStandard
                    costBundle
                    costPerformance
                    costRostering
                    costStaff
                    costVehicles
                    costMessaging
                    discountPercent
                    discountFixed
                    discountPercentLabel
                    discountFixedLabel
                    payOutstandingByDate
                    lastPaidPositiveInvoiceLineItemDateTime
                    totalNumberOfMonthsPaidByTenant
                    lifetimePaymentTotal
                    averageMonthlyInvoiceTotal
                    isTestingAccount
                    calculatedOutstandingBalance
                    bundleDeactivationAllowedAt
                    performanceDeactivationAllowedAt
                    rosteringDeactivationAllowedAt
                    staffDeactivationAllowedAt
                    vehiclesDeactivationAllowedAt
                    automatedCoachingSendTime
                    allowLibraryUpload
                    coachingCustomMessage
                    messageServiceProvider
                    OriginationNumberOrderId
                    OriginationNumberStatus
                    customerStatus
                    coachingFicoThresholdIssue
                    coachingFicoThreshold
                    coachingFicoThresholdPR
                    coachingFicoThresholdKudo
                    coachingFicoIssue
                    coachingFicoCO
                    coachingFicoPR
                    coachingFicoKudo
                    coachingDcrThresholdIssue
                    coachingDcrThreshold
                    coachingDcrThresholdPR
                    coachingDcrThresholdKudo
                    coachingDcrIssue
                    coachingDcrCO
                    coachingDcrPR
                    coachingDcrKudo
                    coachingDarThresholdIssue
                    coachingDarThreshold
                    coachingDarThresholdPR
                    coachingDarThresholdKudo
                    coachingDarIssue
                    coachingDarCO
                    coachingDarPR
                    coachingDarKudo
                    coachingDnrThresholdIssue
                    coachingDnrThreshold
                    coachingDnrThresholdPR
                    coachingDnrThresholdKudo
                    coachingDnrIssue
                    coachingDnrCO
                    coachingDnrPR
                    coachingDnrKudo
                    coachingDsbThresholdIssue
                    coachingDsbThreshold
                    coachingDsbThresholdPR
                    coachingDsbThresholdKudo
                    coachingDsbIssue
                    coachingDsbCO
                    coachingDsbPR
                    coachingDsbKudo
                    coachingSwcPodThresholdIssue
                    coachingSwcPodThreshold
                    coachingSwcPodThresholdKudo
                    coachingSwcPodThresholdPR
                    coachingSwcPodIssue
                    coachingSwcPodCO
                    coachingSwcPodPR
                    coachingSwcPodKudo
                    coachingSwcCcThresholdIssue
                    coachingSwcCcThreshold
                    coachingSwcCcThresholdKudo
                    coachingSwcCcThresholdPR
                    coachingSwcCcIssue
                    coachingSwcCcCO
                    coachingSwcCcPR
                    coachingSwcCcKudo
                    coachingSwcScThresholdIssue
                    coachingSwcScThreshold
                    coachingSwcScThresholdKudo
                    coachingSwcScThresholdPR
                    coachingSwcScIssue
                    coachingSwcScCO
                    coachingSwcScPR
                    coachingSwcScKudo
                    coachingSwcAdThresholdIssue
                    coachingSwcAdThreshold
                    coachingSwcAdThresholdKudo
                    coachingSwcAdThresholdPR
                    coachingSwcAdIssue
                    coachingSwcAdCO
                    coachingSwcAdPR
                    coachingSwcAdKudo
                    coachingSeatbeltOffThresholdIssue
                    coachingSeatbeltOffThreshold
                    coachingSeatbeltOffThresholdPR
                    coachingSeatbeltOffThresholdKudo
                    coachingSeatbeltOffIssue
                    coachingSeatbeltOffCO
                    coachingSeatbeltOffPR
                    coachingSeatbeltOffKudo
                    coachingSpeedingEventThresholdIssue
                    coachingSpeedingEventThreshold
                    coachingSpeedingEventThresholdPR
                    coachingSpeedingEventThresholdKudo
                    coachingSpeedingEventIssue
                    coachingSpeedingEventCO
                    coachingSpeedingEventPR
                    coachingSpeedingEventKudo
                    coachingDistractionsRateThresholdIssue
                    coachingDistractionsRateThreshold
                    coachingDistractionsRateThresholdPR
                    coachingDistractionsRateThresholdKudo
                    coachingDistractionsRateIssue
                    coachingDistractionsRateCO
                    coachingDistractionsRatePR
                    coachingDistractionsRateKudo
                    coachingFollowingDistanceRateThresholdIssue
                    coachingFollowingDistanceRateThreshold
                    coachingFollowingDistanceRateThresholdPR
                    coachingFollowingDistanceRateThresholdKudo
                    coachingFollowingDistanceRateIssue
                    coachingFollowingDistanceRateCO
                    coachingFollowingDistanceRatePR
                    coachingFollowingDistanceRateKudo
                    coachingSignSignalViolationsRateThresholdIssue
                    coachingSignSignalViolationsRateThreshold
                    coachingSignSignalViolationsRateThresholdPR
                    coachingSignSignalViolationsRateThresholdKudo
                    coachingSignSignalViolationsRateIssue
                    coachingSignSignalViolationsRateCO
                    coachingSignSignalViolationsRatePR
                    coachingSignSignalViolationsRateKudo
                    coachingOverallTierThresholdIssue
                    coachingOverallTierThresholdKudo
                    coachingOverallTierIssue
                    coachingOverallTierKudo
                    coachingOverallTierRatingIssue
                    coachingOverallTierRatingKudo
                    coachingConsecutiveTierThresholdIssue
                    coachingConsecutiveTierThresholdKudo
                    coachingConsecutiveTierIssue
                    coachingConsecutiveTierKudo
                    coachingConsecutiveTierRatingIssue
                    coachingConsecutiveTierRatingKudo
                    coachingPositiveFeedbackThresholdIssue
                    coachingPositiveFeedbackThreshold
                    coachingPositiveFeedbackThresholdPR
                    coachingPositiveFeedbackThresholdKudo
                    coachingPositiveFeedbackIssue
                    coachingPositiveFeedbackCO
                    coachingPositiveFeedbackPR
                    coachingPositiveFeedbackKudo
                    coachingDailyFicoThresholdIssue
                    coachingDailyFicoThreshold
                    coachingDailyFicoThresholdPR
                    coachingDailyFicoThresholdKudo
                    coachingDailyFicoIssue
                    coachingDailyFicoCO
                    coachingDailyFicoPR
                    coachingDailyFicoKudo
                    coachingSeatbeltThresholdIssue
                    coachingSeatbeltThreshold
                    coachingSeatbeltThresholdPR
                    coachingSeatbeltThresholdKudo
                    coachingSeatbeltIssue
                    coachingSeatbeltCO
                    coachingSeatbeltPR
                    coachingSeatbeltKudo
                    coachingSseIssue
                    coachingSseCO
                    coachingSsePR
                    coachingSseKudo
                    coachingSseThresholdIssue
                    coachingSseThreshold
                    coachingSseThresholdPR
                    coachingSseThresholdKudo
                    coachingDvcrsIssue
                    coachingDvcrsCO
                    coachingDvcrsPR
                    coachingHarshBrakingRateThresholdIssue
                    coachingHarshBrakingRateThreshold
                    coachingHarshBrakingRateThresholdPR
                    coachingHarshBrakingRateThresholdKudo
                    coachingHarshBrakingRateIssue
                    coachingHarshBrakingRateCO
                    coachingHarshBrakingRatePR
                    coachingHarshBrakingRateKudo
                    coachingDaTierThresholdIssue
                    coachingDaTierThresholdKudo
                    coachingDaTierIssue
                    coachingDaTierKudo
                    coachingDaTierPR
                    coachingDaTierCO
                    coachingDaTierRatingIssue
                    coachingDaTierRatingCO
                    coachingDaTierRatingPR
                    coachingDaTierRatingKudo
                    coachingCdfScoreThresholdIssue
                    coachingCdfScoreThreshold
                    coachingCdfScoreThresholdPR
                    coachingCdfScoreThresholdKudo
                    coachingCdfScoreIssue
                    coachingCdfScoreKudo
                    coachingCdfScoreRatingIssue
                    coachingCdfScoreCO
                    coachingCdfScorePR
                    coachingCdfScoreRatingKudo
                    coachingHarshCorneringRateThresholdIssue
                    coachingHarshCorneringRateThreshold
                    coachingHarshCorneringRateThresholdPR
                    coachingHarshCorneringRateThresholdKudo
                    coachingHarshCorneringRateIssue
                    coachingHarshCorneringRateCO
                    coachingHarshCorneringRatePR
                    coachingHarshCorneringRateKudo
                    coachingPpsCompliancePercentThresholdIssue
                    coachingPpsCompliancePercentThreshold
                    coachingPpsCompliancePercentThresholdPR
                    coachingPpsCompliancePercentThresholdKudo
                    coachingPpsCompliancePercentIssue
                    coachingPpsCompliancePercentCO
                    coachingPpsCompliancePercentPR
                    coachingPpsCompliancePercentKudo
                    coachingPpsBreachesThresholdIssue
                    coachingPpsBreachesThreshold
                    coachingPpsBreachesThresholdPR
                    coachingPpsBreachesThresholdKudo
                    coachingPpsBreachesIssue
                    coachingPpsBreachesCO
                    coachingPpsBreachesPR
                    coachingPpsBreachesKudo
                    coachingDailyComplianceRateThresholdIssue
                    coachingDailyComplianceRateThreshold
                    coachingDailyComplianceRateThresholdPR
                    coachingDailyComplianceRateThresholdKudo
                    coachingDailyComplianceRateIssue
                    coachingDailyComplianceRateCO
                    coachingDailyComplianceRatePR
                    coachingDailyComplianceRateKudo
                    coachingTraningRemainingIssue
                    coachingTraningRemainingCO
                    coachingTraningRemainingPR
                    coachingCameraObstructionIssue
                    coachingCameraObstructionCO
                    coachingCameraObstructionThresholdIssue
                    coachingCameraObstructionThreshold
                    coachingDriverDistractionIssue
                    coachingDriverDistractionCO
                    coachingDriverDistractionThresholdIssue
                    coachingDriverDistractionThreshold
                    coachingDriverDrowsinessIssue
                    coachingDriverDrowsinessCO
                    coachingDriverDrowsinessThresholdIssue
                    coachingDriverDrowsinessThreshold
                    coachingDriverInitiatedIssue
                    coachingDriverInitiatedCO
                    coachingDriverInitiatedThresholdIssue
                    coachingDriverInitiatedThreshold
                    coachingDriverStarPR
                    coachingDriverStarKudo
                    coachingDriverStarThresholdPR
                    coachingDriverStarThresholdKudo
                    coachingFaceMaskComplianceIssue
                    coachingFaceMaskComplianceCO
                    coachingFaceMaskComplianceThresholdIssue
                    coachingFaceMaskComplianceThreshold
                    coachingFollowingDistanceIssue
                    coachingFollowingDistanceCO
                    coachingFollowingDistanceThresholdIssue
                    coachingFollowingDistanceThreshold
                    coachingHardAccelerationIssue
                    coachingHardAccelerationCO
                    coachingHardAccelerationThresholdIssue
                    coachingHardAccelerationThreshold
                    coachingHardBrakingIssue
                    coachingHardBrakingCO
                    coachingHardBrakingThresholdIssue
                    coachingHardBrakingThreshold
                    coachingHardTurnIssue
                    coachingHardTurnCO
                    coachingHardTurnThresholdIssue
                    coachingHardTurnThreshold
                    coachingHighGIssue
                    coachingHighGCO
                    coachingHighGThresholdIssue
                    coachingHighGThreshold
                    coachingLowImpactIssue
                    coachingLowImpactCO
                    coachingLowImpactThresholdIssue
                    coachingLowImpactThreshold
                    coachingSeatbeltComplianceIssue
                    coachingSeatbeltComplianceCO
                    coachingSeatbeltComplianceThresholdIssue
                    coachingSeatbeltComplianceThreshold
                    coachingSignViolationsIssue
                    coachingSignViolationsCO
                    coachingSignViolationsThresholdIssue
                    coachingSignViolationsThreshold
                    coachingSpeedingViolationsIssue
                    coachingSpeedingViolationsCO
                    coachingSpeedingViolationsThresholdIssue
                    coachingSpeedingViolationsThreshold
                    coachingTrafficLightViolationIssue
                    coachingTrafficLightViolationCO
                    coachingTrafficLightViolationThresholdIssue
                    coachingTrafficLightViolationThreshold
                    coachingUTurnIssue
                    coachingUTurnCO
                    coachingUTurnThresholdIssue
                    coachingUTurnThreshold
                    coachingWeavingIssue
                    coachingWeavingCO
                    coachingWeavingThresholdIssue
                    coachingWeavingThreshold
                    coachingDriverRankRange
                    usesXLcoaching
                    shortCode
                    growSurfParticipantId
                    growSurfReferrerEmail
                    growSurfReferrerFullName
                    growSurfReferredEmail
                    growSurfReferredFullName
                    growSurfReferralComplete
                    growSurfReferredByEmailBackup
                    growSurfReferredByFullNameBackup
                    growSurfParticipantEmail
                    growSurfParticipantFullName
                    growSurfParticipantReferralComplete
                    stripeCustomerId
                    stripeBillingEmail
                    driverReportDayRange
                    accountType
                    customerType
                    customerSubType
                    accountCountry
                    isTemporaryAccount
                    allowParentData
                    parentAccountId
                    updatedAt
                }
                users {
                    nextToken
                }
                valueLists {
                    nextToken
                }
                premiumStatusHistory {
                    nextToken
                }
                messageServiceProviderHistory {
                    nextToken
                }
            }
        }
    }
`;

export const deleteCard = /* GraphQL */ `
    mutation DeleteCard(
        $input: DeleteCardInput!
        $condition: ModelCardConditionInput
    ) {
        deleteCard(input: $input, condition: $condition) {
            id
            group
            stripeSetupIntent
            stripeCustomerId
            stripePaymentMethodId
            active
            createdAt
            chargeError
            updatedAt
            tenant {
                id
                createdAt
                companyName
                nameLog
                numberOfSeats
                logo
                accidentReport
                workmansComp
                dispatchNumber
                addressLine1
                addressLine2
                addressCity
                addressState
                addressZip
                group
                zohoCrmAccountRecordId
                originationNumber
                timeZone
                trialExpDate
                firstInterestDateTime
                firstStartedTrialDateTime
                firstConvertedToPaidDateTime
                firstChurnedDateTime
                accountPremiumStatus
                accountCanceledNotes
                accountCanceledReason
                notes
                costStandard
                costBundle
                costPerformance
                costRostering
                costStaff
                costVehicles
                costMessaging
                discountPercent
                discountFixed
                discountPercentLabel
                discountFixedLabel
                payOutstandingByDate
                lastPaidPositiveInvoiceLineItemDateTime
                totalNumberOfMonthsPaidByTenant
                lifetimePaymentTotal
                averageMonthlyInvoiceTotal
                isTestingAccount
                calculatedOutstandingBalance
                bundleDeactivationAllowedAt
                performanceDeactivationAllowedAt
                rosteringDeactivationAllowedAt
                staffDeactivationAllowedAt
                vehiclesDeactivationAllowedAt
                automatedCoachingSendTime
                allowLibraryUpload
                coachingCustomMessage
                messageServiceProvider
                OriginationNumberOrderId
                OriginationNumberStatus
                customerStatus
                coachingFicoThresholdIssue
                coachingFicoThreshold
                coachingFicoThresholdPR
                coachingFicoThresholdKudo
                coachingFicoIssue
                coachingFicoCO
                coachingFicoPR
                coachingFicoKudo
                coachingDcrThresholdIssue
                coachingDcrThreshold
                coachingDcrThresholdPR
                coachingDcrThresholdKudo
                coachingDcrIssue
                coachingDcrCO
                coachingDcrPR
                coachingDcrKudo
                coachingDarThresholdIssue
                coachingDarThreshold
                coachingDarThresholdPR
                coachingDarThresholdKudo
                coachingDarIssue
                coachingDarCO
                coachingDarPR
                coachingDarKudo
                coachingDnrThresholdIssue
                coachingDnrThreshold
                coachingDnrThresholdPR
                coachingDnrThresholdKudo
                coachingDnrIssue
                coachingDnrCO
                coachingDnrPR
                coachingDnrKudo
                coachingDsbThresholdIssue
                coachingDsbThreshold
                coachingDsbThresholdPR
                coachingDsbThresholdKudo
                coachingDsbIssue
                coachingDsbCO
                coachingDsbPR
                coachingDsbKudo
                coachingSwcPodThresholdIssue
                coachingSwcPodThreshold
                coachingSwcPodThresholdKudo
                coachingSwcPodThresholdPR
                coachingSwcPodIssue
                coachingSwcPodCO
                coachingSwcPodPR
                coachingSwcPodKudo
                coachingSwcCcThresholdIssue
                coachingSwcCcThreshold
                coachingSwcCcThresholdKudo
                coachingSwcCcThresholdPR
                coachingSwcCcIssue
                coachingSwcCcCO
                coachingSwcCcPR
                coachingSwcCcKudo
                coachingSwcScThresholdIssue
                coachingSwcScThreshold
                coachingSwcScThresholdKudo
                coachingSwcScThresholdPR
                coachingSwcScIssue
                coachingSwcScCO
                coachingSwcScPR
                coachingSwcScKudo
                coachingSwcAdThresholdIssue
                coachingSwcAdThreshold
                coachingSwcAdThresholdKudo
                coachingSwcAdThresholdPR
                coachingSwcAdIssue
                coachingSwcAdCO
                coachingSwcAdPR
                coachingSwcAdKudo
                coachingSeatbeltOffThresholdIssue
                coachingSeatbeltOffThreshold
                coachingSeatbeltOffThresholdPR
                coachingSeatbeltOffThresholdKudo
                coachingSeatbeltOffIssue
                coachingSeatbeltOffCO
                coachingSeatbeltOffPR
                coachingSeatbeltOffKudo
                coachingSpeedingEventThresholdIssue
                coachingSpeedingEventThreshold
                coachingSpeedingEventThresholdPR
                coachingSpeedingEventThresholdKudo
                coachingSpeedingEventIssue
                coachingSpeedingEventCO
                coachingSpeedingEventPR
                coachingSpeedingEventKudo
                coachingDistractionsRateThresholdIssue
                coachingDistractionsRateThreshold
                coachingDistractionsRateThresholdPR
                coachingDistractionsRateThresholdKudo
                coachingDistractionsRateIssue
                coachingDistractionsRateCO
                coachingDistractionsRatePR
                coachingDistractionsRateKudo
                coachingFollowingDistanceRateThresholdIssue
                coachingFollowingDistanceRateThreshold
                coachingFollowingDistanceRateThresholdPR
                coachingFollowingDistanceRateThresholdKudo
                coachingFollowingDistanceRateIssue
                coachingFollowingDistanceRateCO
                coachingFollowingDistanceRatePR
                coachingFollowingDistanceRateKudo
                coachingSignSignalViolationsRateThresholdIssue
                coachingSignSignalViolationsRateThreshold
                coachingSignSignalViolationsRateThresholdPR
                coachingSignSignalViolationsRateThresholdKudo
                coachingSignSignalViolationsRateIssue
                coachingSignSignalViolationsRateCO
                coachingSignSignalViolationsRatePR
                coachingSignSignalViolationsRateKudo
                coachingOverallTierThresholdIssue
                coachingOverallTierThresholdKudo
                coachingOverallTierIssue
                coachingOverallTierKudo
                coachingOverallTierRatingIssue
                coachingOverallTierRatingKudo
                coachingConsecutiveTierThresholdIssue
                coachingConsecutiveTierThresholdKudo
                coachingConsecutiveTierIssue
                coachingConsecutiveTierKudo
                coachingConsecutiveTierRatingIssue
                coachingConsecutiveTierRatingKudo
                coachingPositiveFeedbackThresholdIssue
                coachingPositiveFeedbackThreshold
                coachingPositiveFeedbackThresholdPR
                coachingPositiveFeedbackThresholdKudo
                coachingPositiveFeedbackIssue
                coachingPositiveFeedbackCO
                coachingPositiveFeedbackPR
                coachingPositiveFeedbackKudo
                coachingDailyFicoThresholdIssue
                coachingDailyFicoThreshold
                coachingDailyFicoThresholdPR
                coachingDailyFicoThresholdKudo
                coachingDailyFicoIssue
                coachingDailyFicoCO
                coachingDailyFicoPR
                coachingDailyFicoKudo
                coachingSeatbeltThresholdIssue
                coachingSeatbeltThreshold
                coachingSeatbeltThresholdPR
                coachingSeatbeltThresholdKudo
                coachingSeatbeltIssue
                coachingSeatbeltCO
                coachingSeatbeltPR
                coachingSeatbeltKudo
                coachingSseIssue
                coachingSseCO
                coachingSsePR
                coachingSseKudo
                coachingSseThresholdIssue
                coachingSseThreshold
                coachingSseThresholdPR
                coachingSseThresholdKudo
                coachingDvcrsIssue
                coachingDvcrsCO
                coachingDvcrsPR
                coachingHarshBrakingRateThresholdIssue
                coachingHarshBrakingRateThreshold
                coachingHarshBrakingRateThresholdPR
                coachingHarshBrakingRateThresholdKudo
                coachingHarshBrakingRateIssue
                coachingHarshBrakingRateCO
                coachingHarshBrakingRatePR
                coachingHarshBrakingRateKudo
                coachingDaTierThresholdIssue
                coachingDaTierThresholdKudo
                coachingDaTierIssue
                coachingDaTierKudo
                coachingDaTierPR
                coachingDaTierCO
                coachingDaTierRatingIssue
                coachingDaTierRatingCO
                coachingDaTierRatingPR
                coachingDaTierRatingKudo
                coachingCdfScoreThresholdIssue
                coachingCdfScoreThreshold
                coachingCdfScoreThresholdPR
                coachingCdfScoreThresholdKudo
                coachingCdfScoreIssue
                coachingCdfScoreKudo
                coachingCdfScoreRatingIssue
                coachingCdfScoreCO
                coachingCdfScorePR
                coachingCdfScoreRatingKudo
                coachingHarshCorneringRateThresholdIssue
                coachingHarshCorneringRateThreshold
                coachingHarshCorneringRateThresholdPR
                coachingHarshCorneringRateThresholdKudo
                coachingHarshCorneringRateIssue
                coachingHarshCorneringRateCO
                coachingHarshCorneringRatePR
                coachingHarshCorneringRateKudo
                coachingPpsCompliancePercentThresholdIssue
                coachingPpsCompliancePercentThreshold
                coachingPpsCompliancePercentThresholdPR
                coachingPpsCompliancePercentThresholdKudo
                coachingPpsCompliancePercentIssue
                coachingPpsCompliancePercentCO
                coachingPpsCompliancePercentPR
                coachingPpsCompliancePercentKudo
                coachingPpsBreachesThresholdIssue
                coachingPpsBreachesThreshold
                coachingPpsBreachesThresholdPR
                coachingPpsBreachesThresholdKudo
                coachingPpsBreachesIssue
                coachingPpsBreachesCO
                coachingPpsBreachesPR
                coachingPpsBreachesKudo
                coachingDailyComplianceRateThresholdIssue
                coachingDailyComplianceRateThreshold
                coachingDailyComplianceRateThresholdPR
                coachingDailyComplianceRateThresholdKudo
                coachingDailyComplianceRateIssue
                coachingDailyComplianceRateCO
                coachingDailyComplianceRatePR
                coachingDailyComplianceRateKudo
                coachingTraningRemainingIssue
                coachingTraningRemainingCO
                coachingTraningRemainingPR
                coachingCameraObstructionIssue
                coachingCameraObstructionCO
                coachingCameraObstructionThresholdIssue
                coachingCameraObstructionThreshold
                coachingDriverDistractionIssue
                coachingDriverDistractionCO
                coachingDriverDistractionThresholdIssue
                coachingDriverDistractionThreshold
                coachingDriverDrowsinessIssue
                coachingDriverDrowsinessCO
                coachingDriverDrowsinessThresholdIssue
                coachingDriverDrowsinessThreshold
                coachingDriverInitiatedIssue
                coachingDriverInitiatedCO
                coachingDriverInitiatedThresholdIssue
                coachingDriverInitiatedThreshold
                coachingDriverStarPR
                coachingDriverStarKudo
                coachingDriverStarThresholdPR
                coachingDriverStarThresholdKudo
                coachingFaceMaskComplianceIssue
                coachingFaceMaskComplianceCO
                coachingFaceMaskComplianceThresholdIssue
                coachingFaceMaskComplianceThreshold
                coachingFollowingDistanceIssue
                coachingFollowingDistanceCO
                coachingFollowingDistanceThresholdIssue
                coachingFollowingDistanceThreshold
                coachingHardAccelerationIssue
                coachingHardAccelerationCO
                coachingHardAccelerationThresholdIssue
                coachingHardAccelerationThreshold
                coachingHardBrakingIssue
                coachingHardBrakingCO
                coachingHardBrakingThresholdIssue
                coachingHardBrakingThreshold
                coachingHardTurnIssue
                coachingHardTurnCO
                coachingHardTurnThresholdIssue
                coachingHardTurnThreshold
                coachingHighGIssue
                coachingHighGCO
                coachingHighGThresholdIssue
                coachingHighGThreshold
                coachingLowImpactIssue
                coachingLowImpactCO
                coachingLowImpactThresholdIssue
                coachingLowImpactThreshold
                coachingSeatbeltComplianceIssue
                coachingSeatbeltComplianceCO
                coachingSeatbeltComplianceThresholdIssue
                coachingSeatbeltComplianceThreshold
                coachingSignViolationsIssue
                coachingSignViolationsCO
                coachingSignViolationsThresholdIssue
                coachingSignViolationsThreshold
                coachingSpeedingViolationsIssue
                coachingSpeedingViolationsCO
                coachingSpeedingViolationsThresholdIssue
                coachingSpeedingViolationsThreshold
                coachingTrafficLightViolationIssue
                coachingTrafficLightViolationCO
                coachingTrafficLightViolationThresholdIssue
                coachingTrafficLightViolationThreshold
                coachingUTurnIssue
                coachingUTurnCO
                coachingUTurnThresholdIssue
                coachingUTurnThreshold
                coachingWeavingIssue
                coachingWeavingCO
                coachingWeavingThresholdIssue
                coachingWeavingThreshold
                coachingDriverRankRange
                usesXLcoaching
                shortCode
                growSurfParticipantId
                growSurfReferrerEmail
                growSurfReferrerFullName
                growSurfReferredEmail
                growSurfReferredFullName
                growSurfReferralComplete
                growSurfReferredByEmailBackup
                growSurfReferredByFullNameBackup
                growSurfParticipantEmail
                growSurfParticipantFullName
                growSurfParticipantReferralComplete
                stripeCustomerId
                stripeBillingEmail
                driverReportDayRange
                accountType
                customerType
                customerSubType
                accountCountry
                isTemporaryAccount
                allowParentData
                parentAccountId
                cards {
                    nextToken
                }
                companyScoreCardsByYearWeek {
                    nextToken
                }
                textractjobs {
                    nextToken
                }
                waves {
                    nextToken
                }
                recurringMessage {
                    nextToken
                }
                updatedAt
                dailyRoster {
                    nextToken
                }
                invoices {
                    nextToken
                }
                messages {
                    nextToken
                }
                parentAccount {
                    id
                    createdAt
                    companyName
                    nameLog
                    numberOfSeats
                    logo
                    accidentReport
                    workmansComp
                    dispatchNumber
                    addressLine1
                    addressLine2
                    addressCity
                    addressState
                    addressZip
                    group
                    zohoCrmAccountRecordId
                    originationNumber
                    timeZone
                    trialExpDate
                    firstInterestDateTime
                    firstStartedTrialDateTime
                    firstConvertedToPaidDateTime
                    firstChurnedDateTime
                    accountPremiumStatus
                    accountCanceledNotes
                    accountCanceledReason
                    notes
                    costStandard
                    costBundle
                    costPerformance
                    costRostering
                    costStaff
                    costVehicles
                    costMessaging
                    discountPercent
                    discountFixed
                    discountPercentLabel
                    discountFixedLabel
                    payOutstandingByDate
                    lastPaidPositiveInvoiceLineItemDateTime
                    totalNumberOfMonthsPaidByTenant
                    lifetimePaymentTotal
                    averageMonthlyInvoiceTotal
                    isTestingAccount
                    calculatedOutstandingBalance
                    bundleDeactivationAllowedAt
                    performanceDeactivationAllowedAt
                    rosteringDeactivationAllowedAt
                    staffDeactivationAllowedAt
                    vehiclesDeactivationAllowedAt
                    automatedCoachingSendTime
                    allowLibraryUpload
                    coachingCustomMessage
                    messageServiceProvider
                    OriginationNumberOrderId
                    OriginationNumberStatus
                    customerStatus
                    coachingFicoThresholdIssue
                    coachingFicoThreshold
                    coachingFicoThresholdPR
                    coachingFicoThresholdKudo
                    coachingFicoIssue
                    coachingFicoCO
                    coachingFicoPR
                    coachingFicoKudo
                    coachingDcrThresholdIssue
                    coachingDcrThreshold
                    coachingDcrThresholdPR
                    coachingDcrThresholdKudo
                    coachingDcrIssue
                    coachingDcrCO
                    coachingDcrPR
                    coachingDcrKudo
                    coachingDarThresholdIssue
                    coachingDarThreshold
                    coachingDarThresholdPR
                    coachingDarThresholdKudo
                    coachingDarIssue
                    coachingDarCO
                    coachingDarPR
                    coachingDarKudo
                    coachingDnrThresholdIssue
                    coachingDnrThreshold
                    coachingDnrThresholdPR
                    coachingDnrThresholdKudo
                    coachingDnrIssue
                    coachingDnrCO
                    coachingDnrPR
                    coachingDnrKudo
                    coachingDsbThresholdIssue
                    coachingDsbThreshold
                    coachingDsbThresholdPR
                    coachingDsbThresholdKudo
                    coachingDsbIssue
                    coachingDsbCO
                    coachingDsbPR
                    coachingDsbKudo
                    coachingSwcPodThresholdIssue
                    coachingSwcPodThreshold
                    coachingSwcPodThresholdKudo
                    coachingSwcPodThresholdPR
                    coachingSwcPodIssue
                    coachingSwcPodCO
                    coachingSwcPodPR
                    coachingSwcPodKudo
                    coachingSwcCcThresholdIssue
                    coachingSwcCcThreshold
                    coachingSwcCcThresholdKudo
                    coachingSwcCcThresholdPR
                    coachingSwcCcIssue
                    coachingSwcCcCO
                    coachingSwcCcPR
                    coachingSwcCcKudo
                    coachingSwcScThresholdIssue
                    coachingSwcScThreshold
                    coachingSwcScThresholdKudo
                    coachingSwcScThresholdPR
                    coachingSwcScIssue
                    coachingSwcScCO
                    coachingSwcScPR
                    coachingSwcScKudo
                    coachingSwcAdThresholdIssue
                    coachingSwcAdThreshold
                    coachingSwcAdThresholdKudo
                    coachingSwcAdThresholdPR
                    coachingSwcAdIssue
                    coachingSwcAdCO
                    coachingSwcAdPR
                    coachingSwcAdKudo
                    coachingSeatbeltOffThresholdIssue
                    coachingSeatbeltOffThreshold
                    coachingSeatbeltOffThresholdPR
                    coachingSeatbeltOffThresholdKudo
                    coachingSeatbeltOffIssue
                    coachingSeatbeltOffCO
                    coachingSeatbeltOffPR
                    coachingSeatbeltOffKudo
                    coachingSpeedingEventThresholdIssue
                    coachingSpeedingEventThreshold
                    coachingSpeedingEventThresholdPR
                    coachingSpeedingEventThresholdKudo
                    coachingSpeedingEventIssue
                    coachingSpeedingEventCO
                    coachingSpeedingEventPR
                    coachingSpeedingEventKudo
                    coachingDistractionsRateThresholdIssue
                    coachingDistractionsRateThreshold
                    coachingDistractionsRateThresholdPR
                    coachingDistractionsRateThresholdKudo
                    coachingDistractionsRateIssue
                    coachingDistractionsRateCO
                    coachingDistractionsRatePR
                    coachingDistractionsRateKudo
                    coachingFollowingDistanceRateThresholdIssue
                    coachingFollowingDistanceRateThreshold
                    coachingFollowingDistanceRateThresholdPR
                    coachingFollowingDistanceRateThresholdKudo
                    coachingFollowingDistanceRateIssue
                    coachingFollowingDistanceRateCO
                    coachingFollowingDistanceRatePR
                    coachingFollowingDistanceRateKudo
                    coachingSignSignalViolationsRateThresholdIssue
                    coachingSignSignalViolationsRateThreshold
                    coachingSignSignalViolationsRateThresholdPR
                    coachingSignSignalViolationsRateThresholdKudo
                    coachingSignSignalViolationsRateIssue
                    coachingSignSignalViolationsRateCO
                    coachingSignSignalViolationsRatePR
                    coachingSignSignalViolationsRateKudo
                    coachingOverallTierThresholdIssue
                    coachingOverallTierThresholdKudo
                    coachingOverallTierIssue
                    coachingOverallTierKudo
                    coachingOverallTierRatingIssue
                    coachingOverallTierRatingKudo
                    coachingConsecutiveTierThresholdIssue
                    coachingConsecutiveTierThresholdKudo
                    coachingConsecutiveTierIssue
                    coachingConsecutiveTierKudo
                    coachingConsecutiveTierRatingIssue
                    coachingConsecutiveTierRatingKudo
                    coachingPositiveFeedbackThresholdIssue
                    coachingPositiveFeedbackThreshold
                    coachingPositiveFeedbackThresholdPR
                    coachingPositiveFeedbackThresholdKudo
                    coachingPositiveFeedbackIssue
                    coachingPositiveFeedbackCO
                    coachingPositiveFeedbackPR
                    coachingPositiveFeedbackKudo
                    coachingDailyFicoThresholdIssue
                    coachingDailyFicoThreshold
                    coachingDailyFicoThresholdPR
                    coachingDailyFicoThresholdKudo
                    coachingDailyFicoIssue
                    coachingDailyFicoCO
                    coachingDailyFicoPR
                    coachingDailyFicoKudo
                    coachingSeatbeltThresholdIssue
                    coachingSeatbeltThreshold
                    coachingSeatbeltThresholdPR
                    coachingSeatbeltThresholdKudo
                    coachingSeatbeltIssue
                    coachingSeatbeltCO
                    coachingSeatbeltPR
                    coachingSeatbeltKudo
                    coachingSseIssue
                    coachingSseCO
                    coachingSsePR
                    coachingSseKudo
                    coachingSseThresholdIssue
                    coachingSseThreshold
                    coachingSseThresholdPR
                    coachingSseThresholdKudo
                    coachingDvcrsIssue
                    coachingDvcrsCO
                    coachingDvcrsPR
                    coachingHarshBrakingRateThresholdIssue
                    coachingHarshBrakingRateThreshold
                    coachingHarshBrakingRateThresholdPR
                    coachingHarshBrakingRateThresholdKudo
                    coachingHarshBrakingRateIssue
                    coachingHarshBrakingRateCO
                    coachingHarshBrakingRatePR
                    coachingHarshBrakingRateKudo
                    coachingDaTierThresholdIssue
                    coachingDaTierThresholdKudo
                    coachingDaTierIssue
                    coachingDaTierKudo
                    coachingDaTierPR
                    coachingDaTierCO
                    coachingDaTierRatingIssue
                    coachingDaTierRatingCO
                    coachingDaTierRatingPR
                    coachingDaTierRatingKudo
                    coachingCdfScoreThresholdIssue
                    coachingCdfScoreThreshold
                    coachingCdfScoreThresholdPR
                    coachingCdfScoreThresholdKudo
                    coachingCdfScoreIssue
                    coachingCdfScoreKudo
                    coachingCdfScoreRatingIssue
                    coachingCdfScoreCO
                    coachingCdfScorePR
                    coachingCdfScoreRatingKudo
                    coachingHarshCorneringRateThresholdIssue
                    coachingHarshCorneringRateThreshold
                    coachingHarshCorneringRateThresholdPR
                    coachingHarshCorneringRateThresholdKudo
                    coachingHarshCorneringRateIssue
                    coachingHarshCorneringRateCO
                    coachingHarshCorneringRatePR
                    coachingHarshCorneringRateKudo
                    coachingPpsCompliancePercentThresholdIssue
                    coachingPpsCompliancePercentThreshold
                    coachingPpsCompliancePercentThresholdPR
                    coachingPpsCompliancePercentThresholdKudo
                    coachingPpsCompliancePercentIssue
                    coachingPpsCompliancePercentCO
                    coachingPpsCompliancePercentPR
                    coachingPpsCompliancePercentKudo
                    coachingPpsBreachesThresholdIssue
                    coachingPpsBreachesThreshold
                    coachingPpsBreachesThresholdPR
                    coachingPpsBreachesThresholdKudo
                    coachingPpsBreachesIssue
                    coachingPpsBreachesCO
                    coachingPpsBreachesPR
                    coachingPpsBreachesKudo
                    coachingDailyComplianceRateThresholdIssue
                    coachingDailyComplianceRateThreshold
                    coachingDailyComplianceRateThresholdPR
                    coachingDailyComplianceRateThresholdKudo
                    coachingDailyComplianceRateIssue
                    coachingDailyComplianceRateCO
                    coachingDailyComplianceRatePR
                    coachingDailyComplianceRateKudo
                    coachingTraningRemainingIssue
                    coachingTraningRemainingCO
                    coachingTraningRemainingPR
                    coachingCameraObstructionIssue
                    coachingCameraObstructionCO
                    coachingCameraObstructionThresholdIssue
                    coachingCameraObstructionThreshold
                    coachingDriverDistractionIssue
                    coachingDriverDistractionCO
                    coachingDriverDistractionThresholdIssue
                    coachingDriverDistractionThreshold
                    coachingDriverDrowsinessIssue
                    coachingDriverDrowsinessCO
                    coachingDriverDrowsinessThresholdIssue
                    coachingDriverDrowsinessThreshold
                    coachingDriverInitiatedIssue
                    coachingDriverInitiatedCO
                    coachingDriverInitiatedThresholdIssue
                    coachingDriverInitiatedThreshold
                    coachingDriverStarPR
                    coachingDriverStarKudo
                    coachingDriverStarThresholdPR
                    coachingDriverStarThresholdKudo
                    coachingFaceMaskComplianceIssue
                    coachingFaceMaskComplianceCO
                    coachingFaceMaskComplianceThresholdIssue
                    coachingFaceMaskComplianceThreshold
                    coachingFollowingDistanceIssue
                    coachingFollowingDistanceCO
                    coachingFollowingDistanceThresholdIssue
                    coachingFollowingDistanceThreshold
                    coachingHardAccelerationIssue
                    coachingHardAccelerationCO
                    coachingHardAccelerationThresholdIssue
                    coachingHardAccelerationThreshold
                    coachingHardBrakingIssue
                    coachingHardBrakingCO
                    coachingHardBrakingThresholdIssue
                    coachingHardBrakingThreshold
                    coachingHardTurnIssue
                    coachingHardTurnCO
                    coachingHardTurnThresholdIssue
                    coachingHardTurnThreshold
                    coachingHighGIssue
                    coachingHighGCO
                    coachingHighGThresholdIssue
                    coachingHighGThreshold
                    coachingLowImpactIssue
                    coachingLowImpactCO
                    coachingLowImpactThresholdIssue
                    coachingLowImpactThreshold
                    coachingSeatbeltComplianceIssue
                    coachingSeatbeltComplianceCO
                    coachingSeatbeltComplianceThresholdIssue
                    coachingSeatbeltComplianceThreshold
                    coachingSignViolationsIssue
                    coachingSignViolationsCO
                    coachingSignViolationsThresholdIssue
                    coachingSignViolationsThreshold
                    coachingSpeedingViolationsIssue
                    coachingSpeedingViolationsCO
                    coachingSpeedingViolationsThresholdIssue
                    coachingSpeedingViolationsThreshold
                    coachingTrafficLightViolationIssue
                    coachingTrafficLightViolationCO
                    coachingTrafficLightViolationThresholdIssue
                    coachingTrafficLightViolationThreshold
                    coachingUTurnIssue
                    coachingUTurnCO
                    coachingUTurnThresholdIssue
                    coachingUTurnThreshold
                    coachingWeavingIssue
                    coachingWeavingCO
                    coachingWeavingThresholdIssue
                    coachingWeavingThreshold
                    coachingDriverRankRange
                    usesXLcoaching
                    shortCode
                    growSurfParticipantId
                    growSurfReferrerEmail
                    growSurfReferrerFullName
                    growSurfReferredEmail
                    growSurfReferredFullName
                    growSurfReferralComplete
                    growSurfReferredByEmailBackup
                    growSurfReferredByFullNameBackup
                    growSurfParticipantEmail
                    growSurfParticipantFullName
                    growSurfParticipantReferralComplete
                    stripeCustomerId
                    stripeBillingEmail
                    driverReportDayRange
                    accountType
                    customerType
                    customerSubType
                    accountCountry
                    isTemporaryAccount
                    allowParentData
                    parentAccountId
                    updatedAt
                }
                users {
                    nextToken
                }
                valueLists {
                    nextToken
                }
                premiumStatusHistory {
                    nextToken
                }
                messageServiceProviderHistory {
                    nextToken
                }
            }
        }
    }
`;