<template>
    <Drawer
        v-if="form.visible"
        :title="form.title" 
        :subtitle="form.subtitle"
        :visibility="form.visible" 
        :appendToBody="true"
        @close-drawer="$emit('close-drawer')">
        <template v-slot:drawerContent>
            <div class="p-5">
                <el-form
                    ref="refFormTemplate"
                    :model="form"
                    size="small">
                    <el-form-item
                        v-for="(template, index) in form.templates"
                        :key="index"
                        :label="template.label"
                        :prop="template.prop">
                        <el-input
                            :data-cy="`record-templates-${index}`"
                            v-model="template.value"
                            :type="template.type"
                            :rows="3"
                            :autosize="{ minRows: 3, maxRows: 7}"
                            placeholder="Aa">
                        </el-input>
                    </el-form-item>
                </el-form>
            </div>
        </template>
        <template v-slot:drawerfooter-end>
            <ButtonGroupWrapper>
                <template slot-scope="{disabled, setDisabled}">
                    <el-button @click="form.visible = false"
                        :disabled="disabled">
                        Cancel
                    </el-button>
                    <el-button v-if="form.recordTemplateJson"
                        @click="handleDelete"
                        type="danger"
                        :disabled="disabled">
                        Delete
                    </el-button>
                    <ButtonWrapper :clickHandler="handleSave"
                        type="primary"
                        data-cy="drawer-save-btn"
                        :disabled="disabled"
                        @cb-start="setDisabled(true)"
                        @cb-end="setDisabled(false)">
                            <template v-if="!form.recordTemplateJson">Create</template>
                            <template v-else>Update</template>
                    </ButtonWrapper>
                </template>
            </ButtonGroupWrapper>
        </template>
    </Drawer>
</template>

<script>
import Drawer from "@/components/shared/Drawer/Drawer";
import ButtonWrapper from "@/components/shared/ButtonWrapper/ButtonWrapper";
import ButtonGroupWrapper from "@/components/shared/ButtonGroupWrapper/ButtonGroupWrapper";

export default {
    data() {
        return {
        }
    },
    props: {
        form: {
            type: [Object, Array],
            default: () => ({})
        },
    },
    components: {
        Drawer,
        ButtonWrapper,
        ButtonGroupWrapper,
    },
    methods: {
        handleSave() {
            const templateToSave = this.form.templates.reduce((acc, item) => {
                acc[item.prop] = item.value;
                return acc;
            }, {})
            const validRecordTemplates = Object.values(templateToSave).some(template => !!template)
            const recordTemplate = validRecordTemplates ? JSON.stringify(templateToSave) : null;
            this.$emit('update-record-template', recordTemplate)
        },
        handleDelete() {
            this.$emit('update-record-template')
        }
    },
}
</script>