<template>
  <div id="container" class="py-4 px-3 table-head" :class="[inTable ? 'header-personalized-table' : 'header-personalized', filtersOnNewLine ? 'filters-on-new-line' : '']">
  
    <section ref="containerTitle" class="text-sm font-bold px-2 text-center color-title uppercase title-container">
      <slot name="title">Title Table</slot>
    </section>
    <div class="flex" ref="containerAllFilters">
      <section ref="containerFiltersDisabled" v-if="disableFilters" class="filters flex flex-row items-center mr-3">
        <el-tooltip  manual 
          placement="top"
          :value="showToolTip" class="item" effect="dark" 
          content="Filters are locked while making row selections. Click the padlock to deselect all rows and unlock the filters."
          >
          <i v-if="disableFilters" @click="$emit('deselect-all')" 
          class="text-red-400 danger el-icon-lock cursor-pointer"
          @mouseenter="toggleDeselectTooltip" @mouseleave="toggleDeselectTooltip"></i>
        </el-tooltip>
      </section>
      <!-- quick filters -->
      <section ref="containerFiltersQuick" v-if="quickFilters" class="filters flex flex-row mr-5 items-baseline " :class="[{'mobile-container ':mobileNavClassQuickFilter}]">
        <div v-if="$slots['filter-quick-filter-count']" class="filter-count-mobile" @click="toggleNavQuickFilter">
          <slot name="filter-quick-filter-count"></slot>
          <i class="toggle-icon el-icon-arrow-down el-icon--right" :class="!mobileNavActiveQuickFilter ? 'active' : ''"></i>
          <i class="toggle-icon el-icon-arrow-up el-icon--right" :class="mobileNavActiveQuickFilter ? 'active' : ''"></i>
        </div>
        <span v-if="!mobileNavClassQuickFilter && $slots['filter-quick-filter-count']" class="pl-4 pr-2 content-center">|</span>
        
        <div ref="containerOnlyQuickFilters" class="container-quick-filter container-filter" :class="mobileNavActiveQuickFilter ? 'active' : 'hide-filters'">
          <i class="el-icon-close toggle-close-icon" @click="toggleNavQuickFilter" :class="mobileNavActiveQuickFilter ? 'active' : ''"></i>
          <section @mouseenter="toggleDeselectTooltip" @mouseleave="toggleDeselectTooltip" class="pointer-events-auto	">
            <slot name="quick-filters"></slot>
          </section>
        </div>
      </section>
      <!-- filters -->
      <section ref="containerFilters" class="filters flex flex-row items-baseline" :class="mobileNavClass ? 'mobile-container ' : ''">
        <div ref="containerCounter" v-if="$slots['filter-count']" class="filter-count-mobile" @click="toggleNav">
          <slot name="filter-count">Count</slot>
          <i class="toggle-icon el-icon-arrow-down el-icon--right" :class="!mobileNavActive ? 'active' : ''"></i>
          <i class="toggle-icon el-icon-arrow-up el-icon--right" :class="mobileNavActive ? 'active' : ''"></i>
        </div>
        <span v-if="!mobileNavClass && $slots['filter-count']" class="pl-4 pr-2 content-center">|</span>
        <div ref="containerOnlyFilters" class="container-filter" :class="mobileNavActive ? 'active' : 'hide-filters'">
          <i class="el-icon-close toggle-close-icon" @click="toggleNav" :class="mobileNavActive ? 'active' : ''"></i>
          <section @mouseenter="toggleDeselectTooltip" @mouseleave="toggleDeselectTooltip" class="flex" style="pointer-events: all;">
          	<slot name="filters"></slot>
		      </section>
        </div>
      </section>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
export default {

  name: "ContainerFilter",
  data() {
    return {
      mobileNavActive: false,
      showToolTip: false,
      mobileNavClass: false,
      mobileNavActiveQuickFilter: false,
      mobileNavClassQuickFilter: false,
      filtersOnNewLine: false,
      fullWidthNormalFilters: null,
      fullWidthQuickFilters: null,
    }
  },
  mounted() {
    this.$nextTick(() => {
      window.addEventListener('resize', this.calcOverflow);
      // Check for initial window size, in case it's already above the threshold
      this.calcOverflow();
    });
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.calcOverflow);
  },
  computed: {
    ...mapGetters(['getContainerData'])
  },
  watch: {
    getContainerData() {
      this.calcOverflow()
    },
  },
  methods: {

    calcOverflow() {
      if (this.quickFilters) {
        this.bothQuickFiltersAndFiltersCalcOverflow()
        return
      }
      this.filtersCalcOverflow()
      return
    },
    bothQuickFiltersAndFiltersCalcOverflow() {
      // For mobiles newline
      const innerWidth = window.innerWidth
      if (innerWidth <= 480) {
        this.filtersOnNewLine = true
        this.mobileNavClass = true
        this.mobileNavClassQuickFilter = true
        return
      }
      this.filtersOnNewLine = false

      let containerTitlePosition = this.$refs.containerTitle.getBoundingClientRect();
      let containerAllFilterCounterPosition = this.$refs.containerAllFilters.getBoundingClientRect();

      let distanceBetweenTitleAndFilter = containerAllFilterCounterPosition.left - (containerTitlePosition.right + 20)  //20 for disabled lock icon
      const fullWidthNormalFilters = this.getDivWidthAndRestoreClass(this.$refs.containerFilters)
      const fullWidthQuickFilters = this.getDivWidthAndRestoreClass(this.$refs.containerFiltersQuick)
      
      if(distanceBetweenTitleAndFilter<=0 && this.mobileNavClassQuickFilter==false && this.mobileNavClass==false){
        this.mobileNavClassQuickFilter=true
        this.$nextTick(() => {
          if(distanceBetweenTitleAndFilter<=0 && this.mobileNavClassQuickFilter==true){
            this.mobileNavClass=true
          }
        });
      } else if (distanceBetweenTitleAndFilter<=0 && this.mobileNavClassQuickFilter==true && this.mobileNavClass==false){
        this.mobileNavClass=true
      } 
      if(distanceBetweenTitleAndFilter>fullWidthNormalFilters && this.mobileNavClassQuickFilter==true && this.mobileNavClass==true){
        this.mobileNavClass=false
      } else if (distanceBetweenTitleAndFilter>fullWidthQuickFilters && this.mobileNavClassQuickFilter==true && this.mobileNavClass==false){
        this.mobileNavClassQuickFilter=false
      } 
    },

    filtersCalcOverflow() {
      // For mobiles newline
      const innerWidth = window.innerWidth
      if (innerWidth <= 480) {
        this.filtersOnNewLine = true
        this.mobileNavClass = true
        this.mobileNavClassQuickFilter = true
        return
      }
      this.filtersOnNewLine = false
      let containerTitlePosition = this.$refs.containerTitle.getBoundingClientRect();
      let containerAllFilterCounterPosition = this.$refs.containerAllFilters.getBoundingClientRect();

      let distanceBetweenTitleAndFilter = containerAllFilterCounterPosition.left - (containerTitlePosition.right + 20)  //20 for disabled lock icon
      const fullWidthNormalFilters = this.getDivWidthAndRestoreClass(this.$refs.containerFilters)
      
      if(distanceBetweenTitleAndFilter <= 0 && this.mobileNavClass==false){
        this.mobileNavClass=true
      }
      if(distanceBetweenTitleAndFilter > fullWidthNormalFilters  && this.mobileNavClass==true){
        this.mobileNavClass=false
      }
    },

    toggleNav() {
      this.mobileNavActive = !this.mobileNavActive
    },
    toggleDeselectTooltip() {
      this.showToolTip = !this.showToolTip
    },
    toggleNavQuickFilter() {
      this.mobileNavActiveQuickFilter = !this.mobileNavActiveQuickFilter
    },
    getDivWidthAndRestoreClass(divId) {
      const myDiv = divId; // Select the div by ID
      if (myDiv) {
        // Check if the div exists
        let width = null; // Initialize width variable to handle potential errors

        if (myDiv.classList.contains('mobile-container')) {
          myDiv.classList.remove('mobile-container'); // Remove the class
          width = myDiv.clientWidth; // Store width before modification
          myDiv.classList.add('mobile-container'); // Add the class back
          return width;
        }
        width = myDiv.clientWidth;
        return width;
      } else {
        console.error(`Div with ID "${divId}" not found.`);
        return null; // Or return a default value if desired
      }
    },
  },
  props: {
    counter: 1,
    inTable: {
      type: Boolean,
      required: false,
      default: false
    },
    disableFilters: {
      type: Boolean,
      required:false,
      default: false
    },
    quickFilters: {
      type: Boolean,
      required:false,
      default: false
    }
  }

}
</script>

<style lang="scss" scoped>

.header-personalized {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  border: 1px solid rgba(226, 232, 240, 1);
}

.header-personalized-table {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid rgba(226, 232, 240, 1);
}
.filter-count{ 
  cursor: pointer; 
}
.toggle-close-icon, .toggle-icon{ 
  display: none !important;
}
.container-filter {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.color-title {
  color: #606266;
}

.filters-on-new-line{
  &.table-head.header-personalized-table, .table-head {
    display: flex;
    flex-direction: column;
  } 
}
.mobile-container {
  position: relative;
  .filter-count-mobile{
    display: flex !important;
    align-items: baseline;
    cursor: pointer;
  }
  .filter-count-mobile .el-badge.item:after{

  }
  .filter-count-mobile .indicator-filter{
    display: flex;
    align-items: center;
  }
  .filter-count-mobile .toggle-icon{ 
    font-size: .8em;
    color: #444;
  }
  .filter-count-mobile .toggle-icon.active{ 
    display: inline-block !important
  }
  .container-filter {
    position: absolute;
    display: block;
    background: #fff;
    padding: 1em 0.5em;
    overflow: hidden;
    transition: transform .2s ease, opacity .2s ease;
    z-index: 6;
    opacity: 0;
    transform: scaleY(.5);
    transform: translateY(-20px);
    box-shadow: 2px 2px 6px rgb(0 0 0 / 10%);
    border-radius: 5px;
    pointer-events: none;
    min-width: max-content;
    right: -11px;
    top: 32px;
  }
  .container-filter > *{
    display: flex;
    flex-direction: column;
    gap: 10px;
  }
  .container-quick-filter div{
    display: flex !important;
    gap: 12px;
    flex-direction: column;
    margin-left: .75rem;
  }
  .container-filter .el-icon-close{
      position: absolute;
      top: 0px;
      right: 0px;
      padding: 10px;
      cursor: pointer;
      margin: 0;
      font-size: 1.2em;
      color: #888;
  }
  .container-filter.active{
    transition: transform .2s ease, opacity .2s ease;
    opacity: 1 !important;
    transform: scaleY(1) !important;
    transform: translateY(10px) !important;
    pointer-events: all;
  }
  .hide-filters{
    display: none !important;
  }
}

</style>