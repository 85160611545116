module.exports.shortenUrlsByShortenId = /* GraphQL */ `
  query ShortenUrlsByShortenId(
    $shortenId: ID
    $sortDirection: ModelSortDirection
    $filter: ModelShortenUrlFilterInput
    $limit: Int
    $nextToken: String
  ) {
    shortenUrlsByShortenId(
      shortenId: $shortenId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        isOpenLink
      }
      nextToken
    }
  }
`;
