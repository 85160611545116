<template>
    <ProductFruits
        :project-code="projectCodeId"
        language="en"
        :username="userInfo.id"
        :email="userInfo.email"
        :firstname="userInfo.firstName"
        :lastname="`${userInfo.lastName}  (${userInfo.tenant.companyName})`"
        :sign-up-at="userInfo.createdAt"
        :role="hasLogguedSupportAdministrator ? 'admin' : 'user'"
        :props="userCustomProperties"
    />
</template>

<script>
import { mapGetters, mapState } from 'vuex'
import ProductFruits from 'vue2-product-fruits'

export default {
    name: "ProductFruitsComponent",
    components: {
        ProductFruits
    },
    data(){
        return {
            projectCodeId: process.env.VUE_APP_PRODUCT_FRUIT_PROJECT_CODE,
        }
    },
    computed: {
        ...mapState(['userInfo']),

        ...mapGetters(['hasLogguedSupportAdministrator']),

        userCustomProperties(){
            let environment =  process.env.VUE_APP_HERA_ENV
            if(environment === 'development') {
                const url = window.location.origin
                environment = url.includes("rc") ||  url.includes("devqa")  ? 'development' : url.includes("mqa") ? 'test' : 'development'
            }
            return {
                tenantId: this.userInfo.tenant.id, 
                tenantName: this.userInfo.tenant.companyName,
                tenantCustomerStatus: this.userInfo.tenant.customerStatus,
                environment: environment
            }
        },
    }
}
</script>
