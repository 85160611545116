export const lookupMapByKey = (list, key) => {
    return list.reduce((map, entity) => {
      const value = entity[key];
      if (value != null) {
        const cleanedValue = typeof value === 'string' ? value.replace(/\s+/g, '') : value;
        if (value === cleanedValue) {
          map[cleanedValue] = entity;
        } else {
          map[cleanedValue] = Object.assign({}, entity, { [key]: cleanedValue });
        }
      }
      return map;
    }, {});
};

export const lookupMapById = function(list){
    return lookupMapByKey(list, 'id')
}

export const lookupItem = function(lookupMap, itemA, keyOrCallback){
    //match by Id
    const match = lookupMap[itemA.id] || itemA
    if(typeof keyOrCallback === 'string'){
        return match[keyOrCallback]
    }
    if(typeof keyOrCallback === 'function'){
        return keyOrCallback(match)
    }
    return match
}