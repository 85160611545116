<template>
  <el-dropdown 
    :key="dropdownKey" 
    :placement="placement" 
    class="mt-1"
    data-cy="three_dot_button" 
    trigger="click"
    v-model="menuItems"
  >
    <slot name="trigger"></slot>
    <el-dropdown-menu slot="dropdown" id="menu" class="menuWrapper" :style="styleObject">
      <slot name="title"></slot>
      <slot v-for="option in menuItems" :option="option" />
    </el-dropdown-menu>
  </el-dropdown>
</template>

<script>
export default {
  name: "ContextMenuSelect",

  props: {
    options: {
      type: Array,
      require: true,
      default: () => []
    },
    styleObject: {
      type: Object,
      required: false,
      default: (() => { width: "300px" })
    },
    placement: {
      type: String,
      required: false,
      default: "bottom"
    }
  },

  computed: {

    menuItems() {
      return this.items;
    }

  },
  
  data() {
    return {
      items: this.options,
      dropdownKey: 0,
      optionSelected: "",
    }
  },
}
</script>

<style>

</style>