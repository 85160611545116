export const en = {
    label: {
        an_associate: "an Associate",
        An_associate: "An Associate",
        associate: "Associate",
        associates: "Associates",
        associate_s: "Associate’s",
        associates_: "Associates’",
        ASSOCIATE: "ASSOCIATE",
        ASSOCIATES: "ASSOCIATES",
        ASSOCIATE_S: "ASSOCIATE’S",
        ASSOCIATES_: "ASSOCIATES’",
    }
}