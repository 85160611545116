import heicConvert from 'heic-convert/browser';

/**
 * 
 * @param {*} imageDownloaded 
 * @returns blob
 */
export async function previewDownloadedHeicImage(imageDownloaded) {
    let blob = null
    try {
        const arrayBuffer = await imageDownloaded.Body.arrayBuffer(); 
        var inputBuffer = Buffer.from(arrayBuffer); 
        const outputBuffer = await heicConvert({ 
            buffer: inputBuffer, // Buffer containing HEIC file 
            format: 'JPEG', // Output format 
            quality: 1 // Quality scale: 0 (lowest) to 1 (highest) 
        });
                    
        blob = new Blob([outputBuffer], { type: 'image/jpeg' }); 
    } catch (error) {
        blob = new Blob([inputBuffer]);
        console.error("Error converting HEIC image:", error);
    }
    return blob
}



/**
 * 
 * @param {*} file 
 * @returns blob
 */
export async function previewHeicImage(file){
    let blob = null
    try {
        const arrayBuffer = await file.arrayBuffer()
        var inputBuffer = Buffer.from(arrayBuffer); 
        const outputBuffer = await heicConvert({ 
            buffer: inputBuffer, // Buffer containing HEIC file 
            format: 'JPEG', // Output format 
            quality: 1 // Quality scale: 0 (lowest) to 1 (highest) 
        });
        blob = new Blob([outputBuffer], { type: 'image/jpeg' });
      
    } catch (error) {
        blob = new Blob([inputBuffer]);
        console.error("Error converting HEIC image:", error);
    }
    return blob;
}

