import {
  infractionFragment,
  kudoFragment,
  tenantFragment
} from '@/store/fragments'

export const infractionsByGroupAndDate = infractionFragment + /* GraphQL */ `
  query InfractionsByGroupAndDate(
    $group: String
    $date: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelInfractionFilterInput
    $limit: Int
    $nextToken: String
  ) {
    infractionsByGroupAndDate(
      group: $group
      date: $date
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        ...infractionFragment
      }
      nextToken
    }
  }
`;

export const kudosByGroupAndDate = kudoFragment + /* GraphQL */ `
  query KudosByGroupAndDate(
    $group: String
    $date: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelKudoFilterInput
    $limit: Int
    $nextToken: String
  ) {
    kudosByGroupAndDate(
      group: $group
      date: $date
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        ...kudoFragment
      }
      nextToken
    }
  }
`;

export const staffsByGroupStatus = /* GraphQL */ `
  query StaffsByGroupStatus(
    $group: String
    $status: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelStaffFilterInput
    $limit: Int
    $nextToken: String
  ) {
    staffsByGroupStatus(
      group: $group
      status: $status
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        firstName
        lastName
        heraScore
        heraRank
        prevHeraRank
      }
      nextToken
    }
  }
`;

export const infractionsByStaff = /* GraphQL */ `
  query InfractionsByStaff(
    $staffId: String
    $date: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelInfractionFilterInput
    $limit: Int
    $nextToken: String
  ) {
    infractionsByStaff(
      staffId: $staffId
      date: $date
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        group
        infractionType
        comment
        date
        time
        infractionTier
        infractionDescription
        type {
          id
          option
        }
      }
      nextToken
    }
  }
`;

export const kudosByStaff = /* GraphQL */ `
  query KudosByStaff(
    $staffId: String
    $date: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelKudoFilterInput
    $limit: Int
    $nextToken: String
  ) {
    kudosByStaff(
      staffId: $staffId
      date: $date
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        group
        kudoType
        notes
        date
        type {
          id
          option
        }
      }
      nextToken
    }
  }
`;

export const getTenant = tenantFragment + /* GraphQL */ `
  query GetTenant($id: ID!) {
    getTenant(id: $id) {
      ...tenantFragment
    }
  }
`;
