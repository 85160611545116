import { render, staticRenderFns } from "./PerformanceImports.vue?vue&type=template&id=6093e767&scoped=true&"
import script from "./PerformanceImports.vue?vue&type=script&lang=js&"
export * from "./PerformanceImports.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6093e767",
  null
  
)

export default component.exports