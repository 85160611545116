<template>
  <div v-loading="loading">

    <!--HEADER-->
    <div class="flex justify-between">
      <h1>Monthly Invoices</h1>
    </div>

    <el-table
      ref="table"
      empty-text="No Invoices"
      row-key="id"
      :default-sort = "{prop: 'month', order: 'descending'}"
      style="width: 100%"
      @row-click="handleRowClick"
      row-class-name="table-row"
      :data="tableData">
        
        <el-table-column
          label="Month"
          sortable
          :sort-method="dateSort"
          prop="month">
          <template  slot-scope="scope">
            <span class=" inline-block">{{ scope.row.year }} {{scope.row.month | month}}</span>
          </template>
        </el-table-column>

        <el-table-column
          label="Average Active Staff"
          align="left"
          prop="averageActiveDriverCount">
            <template  slot-scope="scope">
              <div>{{ scope.row.averageActiveDriverCount.toFixed(2) }}</div>
            </template>
        </el-table-column>

        <el-table-column
          label="Invoice Status"
          align="left"
          prop="status">
            <template  slot-scope="scope">
              <div>{{ scope.row.status }}</div>
            </template>
        </el-table-column>

        <el-table-column
          label="Total"
          align="right"
          prop="total">
            <template  slot-scope="scope">
              <div>{{ generateTotal(scope.row) | currencyUS }}</div>
            </template>
        </el-table-column>
    </el-table>

    <el-dialog   
      class="invoice-modal"
      :visible.sync="htmlPreviewOpen"
      width="850px"
      :close-on-click-modal="false"
      >
      <div class="relative">
        <el-button size="small" class="absolute right-20 cursor-pointer z-20" @click="downloadInvoice" type="primary">Download</el-button>
        <div v-html="invoiceTableHtml"></div>
      </div>
    </el-dialog>

  
  </div>

</template>

<script>
import { invoicesByGroup, getInvoice, getTenant} from './queries'
import generateTemplate from '@/scripts/invoiceTemplate.js';
import html2pdf from "html2pdf.js";
import logo from "@/assets/images/logo-no-stroke.png";
import moment from 'moment-timezone';

  export default {
    name: 'InvoiceIndex',
    props: [],
    data() {
      return {
        loading: false,
        tableData: [],
        htmlPreviewOpen: false,
        invoiceTableHtml: '',
        tenant: {},
        sufixFileName: null,
        urlLogo: null,
      }
    },

    created() {
      this.toDataURL(logo)
      .then(dataUrl => {
        this.urlLogo = dataUrl;
      })
    },

    mounted(){
        this.loadData()
    },

    methods: {
        dateSort(a,b){
            return new Date(a.createdAt) - new Date(b.createdAt);
        },

        async handleRowClick(row, column, event){
            // load invoice line items
            this.loading = true
            try{
                var input = {
                    id: row.id
                }
                var invoice = await this.api(getInvoice, input)
                var invoiceLineItems = invoice.data.getInvoice.invoiceLineItems.items
                this.sufixFileName = `${Number(invoice.data.getInvoice.month) + 1} - ${invoice.data.getInvoice.year}`;
                invoice = invoice.data.getInvoice
                if(invoiceLineItems.length){
                    invoiceLineItems = invoiceLineItems.map(item =>{
                        var total = item.standardCostExt +
                        item.bundleCostExt +
                        item.performanceCostExt +
                        item.rosteringCostExt +
                        item.staffCostExt +
                        item.vehiclesCostExt +
                        item.messagingCostExt;
                        item.variableTotal = total;
                        return item
                    })
                    // sort line items by date
                    invoiceLineItems.sort(function (a,b){
                        return new Date(a.date) - new Date(b.date);
                    })

                    // generate html
                    let html = generateTemplate(invoice, invoiceLineItems, this.tenant)
                    html = html.replace(`<img style="height: 90px;" src='https://irp.cdn-website.com/dda4e527/dms3rep/multi/HeraLogoVertical.png'>`, `<img style="height: 90px;" src='${this.urlLogo}'>`);
                    this.invoiceTableHtml = html
                    this.htmlPreviewOpen = true
                }
                this.loading = false

            }catch(e){
                console.error(e)
                this.loading = false
                this.displayUserError(e)
            }
            // open card window with html invoice
        },

        generateTotal(invoice){
          // determine if tenant still has an active trial
          const d = this.getTenantTime(this.tenant.timeZone,-24)
          let todayMidnightUnixTime = new Date(d.dt).setHours(0,0,0,0)
          let trialExpMidnightUnixTime = new Date(this.tenant.trialExpDate).setHours(0,0,0,0)
          let isTrialExpired =  trialExpMidnightUnixTime < todayMidnightUnixTime
          const isInTrial = !isTrialExpired || this.tenant.accountPremiumStatus.includes('trial');
          var invoiceTotal = (0).toFixed(2);
          if(!isInTrial){
            let tenantFlatMonthlyBillingAmount = invoice?.flatMonthlyBillingAmount || 0
            var invoiceSubTotal = invoice.variableTotal + tenantFlatMonthlyBillingAmount
            var taxRate = 0
            var fixedDiscount = invoice.discountFixed ? invoice.discountFixed : 0
            var percentDiscount = invoice.discountPercent ? invoice.discountPercent / 100 * (invoiceSubTotal - fixedDiscount) : 0
            var invoiceTotalWOTax = invoiceSubTotal - fixedDiscount - percentDiscount
            invoiceTotal = (invoiceTotalWOTax + invoiceTotalWOTax * taxRate).toFixed(2)
          }
          
          return invoiceTotal
        },

        async loadData(){
            this.loading = true
            try{
                input = {
                    id: this.$store.state.userInfo.tenant.id
                }
                var tenant = await this.api(getTenant, input)
                this.tenant = tenant.data.getTenant

                var input = {
                    group: this.$store.state.userInfo.tenant.group
                }
                this.tableData = await this.gLoadListAll(invoicesByGroup, input, 'invoicesByGroup')

                this.loading = false

            }catch(e){
                console.error(e)
                this.loading = false
                this.displayUserError(e)
            }
        },

        downloadInvoice() {
          const opt = {
            margin: 0.4,
            filename: `Hera Invoice - ${this.sufixFileName}.pdf`,
            image: { type: 'png' },
            enableLinks: true,
            html2canvas: { scale: 2, dpi: 300, letterRendering: true },
            jsPDF: { unit: 'in', format: 'letter', orientation: 'portrait' }
          };
          const styleToRemove = /background-color: #f3f4f6;/gi
          let invoicePDFToDownload = this.invoiceTableHtml.replace(styleToRemove, "");
          html2pdf(invoicePDFToDownload, opt);
        },

        toDataURL (url){ 
          return fetch(url)
            .then(response => response.blob())
            .then(blob => new Promise((resolve, reject) => {
              const reader = new FileReader()
              reader.onloadend = () => resolve(reader.result)
              reader.onerror = reject
              reader.readAsDataURL(blob)
            }))
        },
        getTenantTime(timeZone, offsetHours=0){
          if (!!moment.tz.zone(timeZone)){
            const utc = offsetHours >= 0 ? moment.utc().add(Math.abs(offsetHours), 'hours'):moment.utc().subtract(Math.abs(offsetHours), 'hours');
            const isoUtcDate = utc.format("YYYY-MM-DDTHH:mm:ssZZ");
            const dt = utc.clone().tz(timeZone);
            const isoDate = dt.format("YYYY-MM-DDTHH:mm:ssZZ");
            const year = dt.year()
            const month = dt.month()
            const day = dt.date()
            //Month is 0 index but this function is 1 index, so adding 1 to month
            const numOfDays = new Date(year, month + 1, 0).getDate();
            return {dt:dt, day:day, month:month, year:year, numOfDays: numOfDays, isoDate:isoDate, utcDt: utc, isoUtcDate: isoUtcDate};
          }
          else{
            console.log("Tenant has invalid timezone value");
            return null;
          }
        }
    },
  }
</script>
<style>
.invoice-modal .el-dialog{
  max-width: 92%;
  overflow: scroll;
}
.invoice-modal .el-dialog .el-dialog__body{
  width: 100%;
  overflow: scroll;
}
.invoice-modal .el-dialog .el-dialog__body > div{
  min-width: 850px;
}
</style>