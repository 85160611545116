<template>
  <div>
    <TableCard>
      <template v-slot:table-filters>
        <ContainerFilter inTable>
          <template v-slot:title>
            <label class="tablecard-title">AUTOMATED RECURRING MESSAGES</label>
            <span class="text-xs ml-1" data-cy="messenger-tooltip">
              <el-tooltip class="item" effect="dark" :placement="isDeviceMobileOnly() ? 'bottom' : 'left-start'" popper-class="custom-tooltip-higher-z-index">
                <div slot="content">
                  You can schedule messages to be sent out automatically to all
                  Rostered Associates every day. Note: Recurring Messages are
                  only sent to Rostered Associates who have no Roster Status or
                  a Roster Status of ”On Time”, ”Late - With Call”, or ”Late -
                  No Call”.
                </div>
                <i class="uil uil-question-circle text-base"></i>
              </el-tooltip>
            </span>
          </template>
        </ContainerFilter>
      </template>

      <template v-slot:table-searchbox>
        <SearchBox inTable @search-data="handleSearch" />
      </template>
      <template>
        <div class="relative">
          <CustomTable
          v-if="!loading"
          :columns="columns"
          :data="filteredData"
          :total-records="tableData.length"
          :options-rows="optionsRow"
          :options-header="optionsHeader"
          :withTableMenu="true"
          empty-text="No Recurring Messages"
          @click-menu="handleCommand"
          inTable
          footer-table=""
          :expandCollapseColumn="true"
        />
        <div 
          class="premium-warning-container" 
          @click="$router.push({name: 'SettingsAccountDetails', params:{navigateToAddOnSelection: true}})" 
          v-if="!activePermission.all || (!activePermission.all && activePermission.onlyRead)">
          <div v-if="showSampleData" class="sample-pill">Sample Data</div>
          <div class="premium-warning-triangle">
            <p class="transform-text text-sm text-white">Requires Premium</p>
          </div>
        </div>
      </div>
      </template>
    </TableCard>

    <div v-if="drawerData.visible">
      <Drawer
        v-if="drawerData.visible"
        :title="drawerData.title"
        :visibility="drawerData.visible"
        @close-drawer="closeDrawer"
      >
        <template v-slot:drawerContent>
          <div class="p-5">
            <el-form
              :model="recurringMessagesForm"
              size="small"
              ref="recurringMessagesForm"
              :rules="recurringMessagesFormRules"
            >
              <el-form-item label="Message Name" prop="messageName">
                <el-input
                  v-model="recurringMessagesForm.messageName"
                  :maxlength="50"
                  type="text"
                  data-cy="template-name-in"
                />
              </el-form-item>
              <div
                class="compose-message w-full"
              >
                <div class="compose-message">
                  <EmojiTextArea
                    ref="newMessageRef"
                    placeholder="Message Content"
                    @content-changed='updateNewMessage'
                  ></EmojiTextArea>
                </div>
                <div>
                  <div class="text-xs text-right">
                    <span :class="charsColor" style="font-weight: bold;"
                      >{{ chars }}/{{ maxChars }}</span
                    >
                    <span class="text-xs ml-1" data-cy="messenger-tooltip">
                      <el-tooltip
                        class="item"
                        effect="dark"
                        :placement="isDeviceMobileOnly() ? 'bottom' : 'left-start'"
                      >
                        <div slot="content">
                          Unfortunately, cell phone carriers limit the length of
                          text messages.<br /><br />If you need to send a long
                          message, there are two options: <br />
                          1. You can break up the message and send it in
                          multiple parts, and <br />
                          2. if it is very long, you may want to turn it into a
                          newsletter, save it <br />as a PDF and attach it to
                          the message. <br /><br />
                          A PDF newsletter will be easier for the
                          {{ $t("label.associate") }} to read and digest all of
                          <br />the information.
                        </div>
                        <i class="uil uil-question-circle text-base"></i>
                      </el-tooltip>
                    </span>
                  </div>
                </div>
              </div>
              <el-row class="pt-2">
                <el-col :xs="24" :sm="12" :md="12" :lg="12" :xl="12">
                  <el-form-item label="Daily Send Time" prop="sendTime">
                    <el-col :span="18">
                      <el-select
                      data-cy="sc-time-sel"
                      class="mr-1 field-sendtime"
                      v-model="recurringMessagesForm.sendTime"
                    >
                      <el-option
                        v-for="item in timeOptions"
                        :key="item.time"
                        :label="item.label"
                        :value="item.time"
                      >
                      </el-option>
                    </el-select>
                    </el-col>
                    <el-col :span="6" class="md:text-left icon-align-xs">
                      <span class="text-xs ml-1" data-cy="messenger-tooltip">
                        <el-tooltip class="item" effect="dark" :placement="isDeviceMobileOnly() ? 'bottom' : 'left-start'">
                          <div slot="content">
                              Recurring Messages will be sent at the time listed
                              below in your company's chosen timezone, which is
                              {{ timeZone | timeZone }}.
                          </div>
                          <i class="uil uil-question-circle text-base"></i>
                        </el-tooltip>
                      </span>
                    </el-col>
                  </el-form-item>
                </el-col>
                <el-col :xs="24" :sm="12" :md="12" :lg="12" :xl="12">
                  <el-form-item label="Send Via" prop="channelType">
                    <el-select
                      data-cy="sc-time-sel"
                      class="mr-1 w-full"
                      v-model="recurringMessagesForm.channelType"
                    >
                      <el-option
                        v-for="item in channelType"
                        :key="item.value"
                        :label="item.label"
                        :value="item.value"
                      >
                      </el-option>
                    </el-select>
                  </el-form-item>
                </el-col>
              </el-row>
            </el-form>
          </div>
        </template>
        <template v-slot:drawerfooter>
          <ButtonGroupWrapper class="custom-drawer__footer p-4 flex justify-end">
            <template slot-scope="{disabled, setDisabled}">
              <el-button
                data-cy="drawer-cancel-btn"
                @click="closeDrawer"
                :disabled="disabled"
              >
                Cancel
                </el-button
              >
              <ButtonWrapper
                type="primary"
                data-cy="drawer-save-btn"
                :clickHandler="handleSave"
                :disabled="
                  disabled ||
                  !messageValidator ||
                  !recurringMessagesForm.messageName.length ||
                  recurringMessagesNameExists ||
                  loading
                "
                @cb-start="setDisabled(true)"
                @cb-end="setDisabled(false)"
              >
                <template v-if="drawerData.type === 'create'">Create</template>
                <template v-else-if="drawerData.type === 'edit'">Save</template>
              </ButtonWrapper>
            </template>
          </ButtonGroupWrapper>
        </template>
      </Drawer>
    </div>
  </div>
</template>
<script>
import TableCard from "@/components/TableCard/TableCard";
import ContainerFilter from "@/components/TableCard/ContainerFilter";
import CustomTable from "@/components/TableCard/CustomTable";
import SearchBox from "@/components/TableCard/SearchBox";
import Drawer from "@/components/shared/Drawer/Drawer";
import { mapState } from "vuex";
import { recurringMessageByGroup } from "@/graphql/queries";
import {
  createRecurringMessages,
  updateRecurringMessages,
  deleteRecurringMessages,
} from "@/graphql/mutations";
import ButtonWrapper from "@/components/shared/ButtonWrapper/ButtonWrapper";
import ButtonGroupWrapper from "@/components/shared/ButtonGroupWrapper/ButtonGroupWrapper";
import EmojiTextArea from '@/components/shared/Messenger/EmojiTextArea';
import { calculateBytes } from "@/utilities/countCharacters";

export default {
  name: "RecurringMessages",
  components: {
    SearchBox,
    CustomTable,
    ContainerFilter,
    TableCard,
    Drawer,
    ButtonWrapper,
    ButtonGroupWrapper,
    EmojiTextArea,
  },
  props: ["activePermission"],
  data() {
    return {
      loading: false,
      tableData: [],
      recurringMessages: [],
      search: "",
      columns: [
        {
          name: "Message Name",
          col: "messageName",
          fixed: false,
          sortable: false,
        },
        {
          name: "Daily Send Time",
          col: "sendTime",
          fixed: false,
          sortable: false,
        },
        { name: "Send Via", col: "channelType", fixed: false, sortable: false },
        {
          name: "Date Created",
          col: "createdAt",
          fixed: false,
          sortable: false,
        },
        {
          name: "Date Updated",
          col: "updatedAt",
          fixed: false,
          sortable: false,
        },
      ],
      optionsRow: [
        { label: "View & Edit Recurring Message", action: "edit" },
        { label: "Delete Recurring Message", action: "delete" },
      ],
      optionsHeader: [{ label: "Create a new Recurring Message", action: "create" }],
      drawerData: {},
      messageValidator: false,
      recurringMessagesForm: {
        messageName: "",
        messageBody: "",
        sendTime: "8:00AM",
        channelType: "SMS/EMAIL",
      },
      channelType: [
        {
          value: "SMS/EMAIL",
          label: "SMS & Email",
        },
        { value: "SMS", label: "SMS Only" },
        {
          value: "EMAIL",
          label: "Email Only",
        },
      ],
      tableDataExample:[
        {messageName:'Lunchbreak is mandatory',messageBody:'test',sendTime:'1:00PM',channelType:'SMS/EMAIL',createdAt:'2/20/23 9:00 AM',updatedAt:'2/30/23 2:00 PM'},
        {messageName:'Send report',messageBody:'test',sendTime:'4:00PM',channelType:'SMS/EMAIL',createdAt:'2/21/23 10:30 AM',updatedAt:'2/29/23 4:45 PM'},
        {messageName:'Dial out',messageBody:'test',sendTime:'6:00PM',channelType:'SMS/EMAIL',createdAt:'2/22/23 1:00 PM',updatedAt:'2/28/23 5:00 PM'}
      ],
      recurringMessagesFormRules: {
        messageName: [
          {
            required: true,
            trigger: "change",
            message: "The field is required",
          },
          {
            max: 50,
            trigger: "change",
            message: "Maximum length 50 characters",
          },
          {
            required: true,
            validator: (rule, _, callback) => {
              if (this.recurringMessagesNameExists)
                callback(new Error("Recurring Messsage name already exists"));
              else callback();
            },
            trigger: "change",
          },
        ],
        messageBody: [
          {
            required: true,
            trigger: "change",
            message: "The field is required",
          },
          {
            max: 160,
            trigger: " change",
            message: "Maximum length 160 characters",
          },
        ],
        sendTime: [
          {
            required: true,
            trigger: "change",
            message: "The field is required",
          },
        ],
        channelType: [
          {
            required: true,
            trigger: "change",
            message: "The field is required",
          },
        ],
      },
      chars: 0,
      maxChars: 160,
      showSampleData:false
    };
  },
  mounted() {
    this.loadData();
  },
  computed: {
    ...mapState(["userInfo"]),

    filteredData() {
      return this.filterArray(this.tableData, this.search);
    },

    recurringMessagesNameExists() {
      const id = this.recurringMessagesForm?.id;
      const recurringMessagesName = this.recurringMessagesForm?.messageName;
      if (!recurringMessagesName) {
        return false;
      }

      let predicate;
      if (this.drawerData.type === "create")
        predicate = (row) =>
          row.messageName.toLowerCase().trim() ===
          recurringMessagesName.toLowerCase().trim();
      if (this.drawerData.type === "edit")
        predicate = (row) =>
          row.messageName.toLowerCase().trim() ===
            recurringMessagesName.toLowerCase().trim() && row.id !== id;
      return this.tableData.some(predicate);
    },
    timeOptions() {
      var options = [];
      const hours = [
        "00",
        "01",
        "02",
        "03",
        "04",
        "05",
        "06",
        "07",
        "08",
        "09",
        "10",
        "11",
        "12",
        "13",
        "14",
        "15",
        "16",
        "17",
        "18",
        "19",
        "20",
        "21",
        "22",
        "23",
      ];
      const minutes = ["00", "15", "30", "45"];
      for (const hour of hours) {
        for (const minute of minutes) {
          const twelveTime = this.$moment(`${hour}:${minute}`, 'HH:mm').format('h:mmA')
          const twelveTimeLabel = this.$moment(`${hour}:${minute}`, 'HH:mm').format('h:mm A')
          options.push({
            time: twelveTime,
            label: twelveTimeLabel,
          });
        }
      }
      return options;
    },
    timeZone() {
      return this.getTenantTimeZone();
    },
    charsColor() {
      if (this.chars >= this.maxChars) {
        return "text-red-600";
      }
      return "text-black-600";
    },
  },

  methods: {
    async loadData() {
      try {
        this.loading = true;
        let input = {
          group: this.userInfo.tenant.group,
        };
        this.tableData = await this.gLoadListAll(
          recurringMessageByGroup,
          input,
          "recurringMessageByGroup"
        );
        this.tableData = this.tableData.map((obj) => {
          return {
            id: obj.id,
            group: obj.group,
            messageName: obj.messageName,
            messageBody: obj.messageBody,
            sendTime: obj.sendTime,
            channelType: obj.channelType,
            createdAt: this.$moment(obj.createdAt).format("M/D/YYYY h:mm A"),
            updatedAt: this.$moment(obj.updatedAt).format("M/D/YYYY h:mm A"),
          };
        });

        this.tableData = this.tableData.sort((a, b) => {
          if (a.messageName.toLowerCase() > b.messageName.toLowerCase()) {
            return 1;
          }
          if (b.messageName.toLowerCase() > a.messageName.toLowerCase()) {
            return -1;
          }
          return 0;
        });
      } catch (error) {
        this.displayUserError(error);
      } finally {
        this.loading = false;
      }
      if(!this.activePermission.all && !this.activePermission.onlyRead){
        this.tableData = this.tableDataExample
        this.showSampleData = true
      }
    },
    handleCommand({ action, row }) {
      switch (action) {
        case "create":
          this.drawerData = {
            type: "create",
            visible: true,
            title: "New Recurring Message",
          };
          this.cleanForm()
          this.recurringMessagesForm.sendTime =  "8:00AM"
          this.recurringMessagesForm.channelType = "SMS/EMAIL"
          this.messageValidator = false;
          break;
        case "edit":
          this.drawerData = {
            type: "edit",
            visible: true,
            title: "Edit Recurring Message",
          };
          this.chars = calculateBytes(row.messageBody.trim());
          this.messageValidator = true;
          this.recurringMessagesForm = { ...row };
          this.loadMessageInTemplateForm()
          break;
        case "delete":
          this.$confirm(
            "Would you like to delete this recurring message?",
            "Warning",
            {
              confirmButtonText: "Delete",
              cancelButtonText: "Cancel",
              type: "warning",
            }
          ).then(() => {
            this.recurringMessagesForm = { ...row };
            this.handleDelete();
          });
          break;
      }
    },
    async handleSearch(word) {
      this.search = word;
    },

    async handleSave() {
      this.loading = true;
      try {
        await this.$refs.recurringMessagesForm.validate();
        try {
          if (!this.messageValidator) {
            throw new Error("Error to create/update Recurring Message.");
          }

          const input = {
            ...this.recurringMessagesForm,
          };

          input.group = this.userInfo.tenant.group;
          input.recurringMessagesTenantId = this.userInfo.tenant.id;
          input.senderId = this.userInfo.id;
          input.senderName = `${this.userInfo.firstName} ${this.userInfo.lastName}`;

          switch (this.drawerData.type) {
            case "create":
              delete input.id;
              await this.api(createRecurringMessages, { input });
              this.closeDrawer()
              this.$message({
                type: "success",
                message: "Recurring Message Created.",
              });

              break;
            case "edit":
              delete input.createdAt;
              delete input.updatedAt;
              await this.api(updateRecurringMessages, { input });
              this.closeDrawer()
              this.$message({
                type: "success",
                message: "Recurring Message Updated.",
              });
              break;
          }
        } catch (error) {
          this.displayUserError(error);
        } finally {
          this.cleanForm();
          this.loadData();
        }
      }
      catch (error) {
        this.displayUserError(error);
      }
    },
    async handleDelete() {
      this.loading = true;
      try {
        const input = {
          id: this.recurringMessagesForm.id,
        };
        await this.api(deleteRecurringMessages, { input });
        this.$message({
          type: "success",
          message: "Recurring Message Deleted",
        });
      } catch (error) {
        this.displayUserError(error);
      } finally {
        this.cleanForm();
        this.loadData();
      }
    },
    async updateNewMessage(text) {
      if (text.trim().includes("<br>")) {
        text = text.replace("<br>", "");
      }
      this.chars = calculateBytes(text.trim());
      this.chars > 160
        ? (document.getElementById("twemoji-textarea").style.color = "red")
        : (document.getElementById("twemoji-textarea").style.color = "black");

      if (this.chars === 0 || this.chars > this.maxChars ) {
        this.recurringMessagesForm.messageBody = text;
        this.messageValidator = false;
      } else {
        this.recurringMessagesForm.messageBody = text;
        this.messageValidator = true;
      }
    },
    loadMessageInTemplateForm(){
      this.$nextTick(()=>{
        this.$refs.newMessageRef.addText(this.recurringMessagesForm.messageBody)
      });
    },
    cleanForm() {
      this.recurringMessagesForm.messageName = "";
      this.recurringMessagesForm.messageBody = "";
      this.chars = 0;
    },
    closeDrawer(){
      this.drawerData.visible = false;
    }
  },
};
</script>
<style scoped>
@media (max-width: 476px) {
  .field-sendtime {
    width: 100%;
  } 
  .tablecard-title{
    @apply text-xs;
  }
  .icon-align-xs{
    @apply text-center;
  }
} 
</style>
