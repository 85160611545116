import {onCreateMessageByGroup, onUpdateMessageByGroup} from '@/store/subscriptions';
import store from '@/store'
import EventBus from '@/eventBus'
import { loadStaff, totalUnreadMessages, getMessengerAssociateList } from '@/views/Messenger/countUnreadMessages';
import { initializeSubscription, disconnectSubscription } from '../helper'

"use strict";
let subscriptionMessage = null
let timeout = null

const subscription = {}

export const messengerLoadNotifications = function(forceReload){
    clearTimeout(timeout)
    timeout = setTimeout(async()=>{
        const { messengerList: {lastRenderKey} } = store.state.subscriptionStore
        if(forceReload){
            if(!lastRenderKey || (new Date) - lastRenderKey < 600000){
                return
            }
        }
        const listStaff = await loadStaff();
        if(!listStaff) return
        const messengerList = getMessengerAssociateList( listStaff )
        store.dispatch("subscriptionStore/setMessengerList", messengerList)
        const notificationsUnreadMessages = totalUnreadMessages( messengerList );
        store.commit('setMessengerNotifications', notificationsUnreadMessages)
    }, 1000)
}

export const messengerSubscribe = function(instance){
    const queries = {
        onCreateMessageByGroup,
        onUpdateMessageByGroup,
    }
    if(!store.state.userInfo?.tenant?.group) return
    const input = {
        group: store.state.userInfo.tenant.group
    }
    initializeSubscription(subscriptionMessage, queries, input, "subscriptionStore/handleMessenger", instance)
}

export const messengerUnsubscribe = function (instance) {
    disconnectSubscription(subscription, instance)
}

export const config = function(subscriptions){
    subscriptionMessage = subscriptions['messenger'] = {
        loadList: null,
        subscribe: messengerSubscribe,
        unsubscribe: messengerUnsubscribe,
        callback: messengerEventCallback,
        subscription
    }
}

export const messengerEventCallback = async function(subscriptionState, { eventName, data: message }){
    if(!message) return
    const associateLookupMap = store.getters["subscriptionStore/getAssociateLookupMap"]
    const associate = associateLookupMap[message.staffId || message.staff?.id]
    const messengerLookupMap = store.getters["subscriptionStore/getMessengerLookupMap"]
    if(associate){
        if(associate.smsLastMessageTimestamp !== message.createdAt || associate.smsLastMessage !== message.bodyText){
            const chat = messengerLookupMap[associate?.id]
            if(chat){
                chat.updateSmsLastMessage(message)
                //Only create notification if type is message
                if( message.channelType === "RESPONSE" && message.senderName === null ){
                    if(!chat.messageUnread){
                        chat.messageUnread = 1
                    }else{
                        chat.messageUnread++
                    }
                    chat.readChat = false
                    store.dispatch( "marNewkMessageRecieved" );
                }
            }
        }
    }
    switch(eventName){
        case 'onCreate':{
            EventBus.$emit('messenger-new-message-conversation', message )
            break
        }
        case 'onUpdate':{
            EventBus.$emit('messenger-update-message-conversation', message )
            break
        }
    }

}