<template>
    <div class="settings-counselings" data-cy="counseling-templates">
        <div class="flex justify-between">
            <h1>Counselings</h1>
        </div>
        <div class="child-view">
            <div class="mt-4 grid grid-cols-8 grid-flow-row gap-4">
                <div class="col-span-12 relative">
                    <div class="text-gray-600">
                        Counseling Templates are now part of the "Counseling Type" Custom List.
                    </div>
                    <div class="text-gray-600">
                        Please open
                        <router-link
                            to="/settings/custom-lists"
                            tag="button"
                            class="font-bold text-brand">
                            Custom Lists
                        </router-link>
                        to make changes to your templates.
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    name: 'SettingsCounseling'
}
</script>
<style>
</style>