import {
    onCreateAccidentByGroup,
    onDeleteAccidentByGroup
} from '@/store/subscriptions';
import store from '@/store'
import {
    initializeSubscription,
    disconnectSubscription
} from '../helper'
import EventBus from '@/eventBus'

"use strict";
let subscriptionAccident = null

export const accidentSubscribe = function(instance){
    const queries = {
        onCreateAccidentByGroup,
        onDeleteAccidentByGroup
    }
    if(!store.state.userInfo?.tenant?.group) return
    const input = {
        group: store.state.userInfo.tenant.group
    }
    initializeSubscription(subscriptionAccident, queries, input, "subscriptionStore/handleAccident", instance)
}

export const config = function(subscriptions){
    const subscription = {}
    subscriptionAccident = subscriptions['accident'] = {
        loadList: null,
        subscribe: accidentSubscribe,
        unsubscribe: (instance) => disconnectSubscription(subscription, instance),
        callback: accidentEventCallback,
        subscription
    }
}

export const accidentEventCallback = async function(subscriptionState, { data: accident, eventName }){
    if(!accident) return
    
    const { dailyRosterCache } = subscriptionState

    let matchedRoute,
        matchedReplaceByRoute,
        routeItems

    if(accident.odometerReadingRoute){
        dailyRosterCache.some(dr => {
            return dr.route.items.some((r, i, rItems) => {
                const match = (r.id === accident.odometerReadingRoute.id)
                if(match){
                    matchedRoute = r
                    routeItems = rItems
                    return true
                }
            })
        })

    }else if(accident.replaceOdometerReadingRoute){
        dailyRosterCache.some(dr => {
            return dr.route.items.some((r, i1, rItems) => {
                return r.replaceByRoute?.items.some( rb => {
                    const match = (rb.id === accident.replaceOdometerReadingRoute.id)
                    if(match){
                        matchedReplaceByRoute = rb
                        matchedRoute = r
                        routeItems = rItems
                        return true
                    }
                })
            })
        })
    }

    const accidentRef = { id: accident.id }

    const array = matchedReplaceByRoute?.odometerReadings?.items || matchedRoute?.odometerReadings?.items

    switch(eventName){
        case 'onCreate':{
            if(array){
                array.push(accidentRef)
            }
            break
        }
        case 'onDelete':{
            if(array){
                const index = array.findIndex(odometer => odometer.id === accidentRef.id)
                if(index > -1){
                    const deleted = array.splice(index, 1)
                }
            }
            break
        }
    }
    if(matchedRoute){
        EventBus.$emit('update-route-render', {
            target: "table-data-odmeterReading",
            routeItems
        })
    }
}